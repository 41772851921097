import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import Common from "../utils/common";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      // redirectSignIn: "http://localhost:3000/",
      // redirectSignOut: "http://localhost:3000/",
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});
function Setting() {
  const [user, setUser] = useState("");
  const [success, setSuccess] = useState(false);
  const logout = () => {
     Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUser(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUser(null);
      });
  }, []);
  function onSubmit() {
    setSuccess(true);
  }
  return (
    <div>
      <div className="container-login100 align-items-start justify-content-center">
        <div className="wrap-login100 ">
          <h1 style={{ marginBottom: "20px" }}>SETTINGS</h1>
          <div className="container-login100-form-btn m-b-20">
            <Link to="/portal/changepassword">
              <button
                className="login100-form-btn"
                style={{ marginLeft: "-70px" }}
              >
                Change Password
              </button>
            </Link>
          </div>
          {user ? (
            <>
              <button onClick={onSubmit}>Log Out</button>
            </>
          ) : (
            <button onClick={onSubmit} style={{ marginLeft: "50px" }}>
              Logout
            </button>
          )}
        </div>
      </div>
      {success ? (
        <>
          <div className="mdl-open">
            <div>
              <div className="content2">
                <p>Are you sure you want to logout?</p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <Link to="/portal/dashboard"> */}
                  {user ? (
                    <button
                      className="modal-btn"
                      onClick={() => {
                        Auth.signOut()
                         Common.logout();
                      }}
                    >
                      YES
                    </button>
                  ) : (
                    <button className="modal-btn" onClick={logout}>
                      YES
                    </button>
                  )}

                  {/* </Link> */}
                  <button
                    className="modal-btn2"
                    onClick={() => setSuccess(false)}
                  >
                    NO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Setting;
