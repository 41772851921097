import React, { useEffect, useState } from "react";
import axios from "axios";
import xalogo from "../../images/xakallogo.png";
import xalogos from "../../images/xakal-logo-min.png";

import styled from "styled-components";
import "./trans.css";
const PayStatus = styled.span`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border-radius: 8px;
  padding: 5px 10px;
`;

const BankTransactionPdf = ({ id, account }) => {
  const [bank, setBank] = useState();
  const getOneBooking = async(id) => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${id}`)
        setBank(response.data.bookingSlot);
      }
      catch(err){console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    if (id) {
      getOneBooking(id);
    }
  }, [id]);
  const serviceName = bank?.serviceName ? bank?.serviceName : "-";
  const timeStamp = bank?.timestamps?.createdAt
    ? bank?.timestamps?.createdAt
    : "-";
  const interviewCodeId = bank?.interviewCodeId ? bank?.interviewCodeId : "-";
  const slotBookingId = bank?.slotBookingId ? bank?.slotBookingId : "-";
  const transactionCodeId = bank?.transactionCodeId
    ? bank?.transactionCodeId
    : "-";
  const bankname = account?.bankname ? account?.bankname : "-";
  const accountnumber = account?.accnumber ? account?.accnumber : "-";
  const totalamount = bank?.totalAmount ? bank?.totalAmount : "-";
  const paid = bank?.paid === "paid" ? "Completed" : "Failed";
  const paymentStatusColor = paid === "Completed" ? "#28a745" : "#dc3545";
  const paymentStatusBack = paid === "Completed" ? "#8ae8a0" : "#f3b2b9";
  return (
    <div className="">
      <div className="header-logo">
        <img src={xalogo} alt="img" />
      </div>
      <div className="header">Payment transaction details</div>
      <div className="contents">
        <div className="data">
          <div>Interview Title</div>
          <div className="datas">{serviceName}</div>
        </div>
        <div className="data">
          Transaction ID
          <span className="datas">{transactionCodeId}</span>
        </div>
        <div className="data">
          Slot Booking ID
          <span className="datas">{slotBookingId}</span>
        </div>
        <div className="data">
          Date & Time
          <span className="datas">{timeStamp}</span>
        </div>
        <div className="data">
          Interview ID
          <span className="datas">{interviewCodeId}</span>
        </div>
        <div className="data">
          Bank Name
          <span className="datas">{bankname}</span>
        </div>
        <div className="data">
          Account Number
          <span className="datas">{accountnumber}</span>
        </div>
        <hr />
        <div className="data">
          Amount
          <span className="datas">{totalamount}</span>
        </div>
        <div className="data">
          <span className="pay-head">Payment Status</span>
          <PayStatus background={paymentStatusBack} color={paymentStatusColor}>
            {paid}
          </PayStatus>
        </div>
      </div>
      <div className="foot-logo-wrap">
        <img src={xalogos} alt="img" className="foot-logo" />
      </div>
      <div className="footer">
        Have questions about this transactions?
        <div className="get-help">
          <a href="mailto:support@thexakal.com" >
            Get help
          </a>
        </div>
      </div>
    </div>
  );
};

export default BankTransactionPdf;
