import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import StudentsPortal from "./components/students-portal";
import * as serviceWorker from "./serviceWorker";
import LandingPage from "./components/landingPage";
import Pricing from "./components/pricing";
import Privacy from "./components/privacy";
import Terms from "./components/TermsConditions";
import Contact from "./components/contact";
import Refund from "./components/refund";
import SignUp from "./components/signup";
import onBoarding from "./components/onBoarding";
import Forgot from "./components/forgotpassword";
import NewPassword from "./components/newpassword";
import NewPasswords from "./components/newpasswords";
import Settings from "./components/setting";
import ChangePassword from "./components/changePassword";
import OtpVerification from "./components/otpVerification";
import Login from "./components/login2"
import axios from "axios";
import "./fonts/Inter/Inter-Bold.ttf";
import "./fonts/Inter/Inter-Medium.ttf";
import "./fonts/Inter/Inter-Regular.ttf";

import MyProfile from "./components/MyProfile/myProfile";
import Common from "./utils/common";
import ClickOrUpload from "./components/camera-or-upload/clickOrUploadPicture";
import OtpLogin from "./components/otpLogin";
import ShareJob from "./components/Jobs/sharejob";
import SharedInterview from "./components/interview/share-interview";
import MobileVerification from "./components/mobileVerification";
axios.interceptors.response.use(
  function (response) {
    if (
      response.data &&
      response.data.error &&
      response.data.error === "Please Login to access this resource"
    ) {
      Common.logout();
    }
    return response;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);
const routing = (
  <Router>
    <div>
      <Route exact path="/" component={App} />
      <Route exact path="/linkedin" component={LinkedInCallback} />
      <Route path="/portal" component={StudentsPortal} />
      <Route path="/landingPage" component={LandingPage} />
      <Route path="/login" component={Login} />
      <Route path="/pricing" component={Pricing} />
      <Route path="/signup" component={SignUp} />
      <Route path="/TermsAndConditions" component={Terms} />
      <Route path="/contact" component={Contact} />
      <Route path="/privacy-policy" component={Privacy} />
      <Route path="/refund-policy" component={Refund} />
      <Route path="/forgot-password" component={Forgot} />
      <Route path="/new-otp" component={OtpLogin} />
      <Route path="/otp-verification" component={OtpVerification} />
      <Route path="/new-password" component={NewPassword} />
      <Route path="/new-passwords" component={NewPasswords} />
      <Route path="/setting" component={Settings}></Route>
      <Route path="/changepassword" component={ChangePassword}></Route>
      <Route path="/my-profile" component={MyProfile}></Route>
      <Route path="/camera" component={ClickOrUpload}></Route>
      <Route path="/on-boarding" component={onBoarding}></Route>
      <Route path="/share-job" component={ShareJob}></Route> 
      <Route path="/share-interview" component={SharedInterview} />
      <Route path="/mobile-verify"component={MobileVerification}/>
    </div>
  </Router>
);
if (process.env.NODE_ENV === "production") {
  console.warn = () => {};
  console.log = () => {};
  console.info = () => {};
  console.error = () => {};
  console.debug = () => {};
}
ReactDOM.render(routing, document.getElementById("root"));

serviceWorker.unregister();
