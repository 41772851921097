export const Colors = {
    primary: "#D44B9C",
    greyText: "#9d9d9d",
    labelText: "#202020",
    inputBg: "#F3F3F3",
    inputText: "#bcbcbc",
    primaryText: "#D04C8D",
    inputHighlightText: "#DA4B9D",
    light: "#fff",
    dark: "#000",
    formlabelcolor: "#979797",
    lightWhite: "#FDFDFD",
    lightGray: "#878787",
    
    pink: "#D04C8D",
    line: "#737373",
    blogTextBox: "#F3F3F3",
    successText: "#01C037",
    errorText: "#FF0000",
  
    green: "#42C1A6",
    inkBlue: "#6373FF",
    blue:"#63BDFF",
    pink1: "#FF63C1",
  };