import React, { useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../images/landing/logo.svg";
import WhatXakal from "../images/landing/WIX.svg";
import Video from "../images/landing/Xakal Intro.mp4";
import Xw01 from "../images/landing/xw-s01.png";
import Xw02 from "../images/landing/xw-s02.png";
import ProText from "../images/landing/professionals-text.png";
import HiringText from "../images/landing/hiring-text.png";
import JobText from "../images/landing/job-seekers-text.png";
import Xf01 from "../images/landing/For_Jobseekers.svg";
import Xakal2 from "../images/landing/Staff.svg";
import Xakal1 from "../images/landing/Professional.svg";
import Botline from "../images/landing/Vector 1.svg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Background from "../images/landing/LANDING PAGE BOTTOM.png";
import Ellipse from "../images/landing/Ellipse.png";
import Ellipse2 from "../images/landing/Ellipse95.png";

import "../styles/lp-style.css";
const LandingPage = () => {
  useEffect(() => {
    var w = window;
    var d = document;
    w.CollectId = w.CollectId = "65fa7a4fc3db31250ba1a43c";
    var h = d.head || d.getElementsByTagName("head")[0];
    var s = d.createElement("script");
    s.setAttribute("type", "text/javascript");
    s.async = true;
    s.setAttribute("src", "https://collectcdn.com/launcher.js");
    h.appendChild(s);
  }, [])
  function scrollFunction() {
    if (document.body.scrollTop > 170 || document.documentElement.scrollTop > 170) {
      document.querySelector(".navbar").classList.add("scrolled");
    } else {
      document.querySelector(".navbar").classList.remove("scrolled");
    }
  }
  window.onscroll = function () {
    scrollFunction();
  };
  
  const pageClick = () => {
    window.open("/login", "_blank");
  };
  const videoRef = useRef(null);

  const calendarlyClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="lp-wrapper">
      <div className="lp-banner">
      
        <Navbar expand="lg" className="bg-body-tertiary ip-navbar">
          <Container className="flex-start">
            <Navbar.Brand href="/">
              <img src={Logo} className="lp-m-logo" alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="navbar-lan">
              <Nav>
                {/* <Nav.Link href="/">Product</Nav.Link> */}
                {/* <Nav.Link href="/pricing" >Pricing</Nav.Link> */}
                <Nav.Link onClick={() => calendarlyClick("https://calendly.com/support-c7gb/support-call")}>
                  Contact Us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <div className="gt-btn-container">
              <button
                type="button"
                className="btn-t-primarie-head"
                onClick={pageClick}
              >
                Get started
              </button>
            </div>
          </Container>
        </Navbar>
        <div className="container banner-text-container">
          <div className=" text-center">
            <div className="mb-3" data-aos="fade-up" data-aos-duration="2000">
              <h1 className="banner-title">Powerful tool to ease</h1>
              <h1 className="banner-title">all your job needs.</h1>
            </div>

            <p>
              Get leading assistance on your job recruitment needs like mock{" "}
              <br />
              interviews, candidate filtering and much more
            </p>
            <button type="button" className="btn-t-primarie" onClick={pageClick}>
              Get started
            </button>
          </div>
          
        </div>
      </div>
      <div className="jc-wrapper">
        <div className="container jc-container">
          <div className="jcount-row">
            <div className="">
              <div className="jc-title">
                Seasoned <br /> Professionals
              </div>
              <div className="jc-number">1,000+</div>
            </div>
            <div className="jc-divider"></div>
            <div className="">
              <div className="jc-title">
                Job <br /> Vacancy
              </div>
              <div className="jc-number">1,00,000</div>
            </div>
            <div className="jc-divider"></div>
            <div className="">
              <div className="jc-title">
                Candidates <br /> Placed
              </div>
              <div className="jc-number">2,000+</div>
            </div>
          </div>
        
          <div className="xw-container text-center video-container" data-aos="fade-up">
          <video controls poster={WhatXakal} ref={videoRef} className="video" controlsList="nodownload">
              <source src={Video} type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          </div>
          
          <div className="xw-container xw-container-wrapper">
            <h1 className="xw-main-title" data-aos="fade-up" data-aos-duration="2000">How does Xakal works?</h1>
            <div className="xw-row">
              <div data-aos="fade-right" data-aos-duration="2000">
                <div className="xw-short-card">
                  <h3 className="xw-title">Candidate</h3>
                  <p className="xw-content">
                    Apply for jobs and improve your interview skills with all
                    the mock interviews available from top professionals.
                  </p>
                  <img src={Xw01} className="img-fluid" alt="logo" />
                </div>
                <div className="xw-short-card">
                  <h3 className="xw-title">HR Recruiter</h3>
                  <p className="xw-content">
                    Verifies and validates candidates with various provided
                    metrics from the mock interviews
                  </p>
                  <img src={Xw01} className="img-fluid" alt="logo" />
                </div>
              </div>
              <div data-aos="fade-left" data-aos-duration="2000">
                <div className="xw-long-card">
                  <div className="xw-c">
                    <h3 className="xw-title">Interviewer</h3>
                    <p className="xw-content mb-0">
                      Create various interactive mock interviews and get
                      rewarded for your time here in xakal.
                    </p>
                  </div>
                  <div className="xw-i">
                    <img src={Xw02} className="img-fluid" alt="logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ellipse-wrapper">
          <img src={Ellipse} alt="img" className="ellipse" />
        </div>
        
      </div>
        <div className="container">
            <div className="xf-row">
              <div className="xf-c">
                <div className="xf-title">Xakal for</div>
                <img src={ProText} className="xf-i-text img-fluid" alt="title" />
                <p className="xw-content mb-0">
                  <div data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="0">
                  As a professional, you can assist candidates by conducting mock interviews in identifying areas for improvement and monetize your expertise. 
                  </div>
                </p>
                <div className="explore" onClick={pageClick}>Explore Now</div>
              </div>
        
              <div className="xf-i">
              <div data-aos="fade-up" data-aos-duration="2000" >
                <img src={Xakal1} className="img-fluid xf-i2" alt="logo" />
              </div>
              </div>
            </div>
        </div>
        <div className="ellipse-wrapper2">
          <img src={Ellipse2} alt="img" className="ellipse2" />
        </div>

        <div className="tenX">
          <div className="container">
            <div className="xf-row ">
              <div className="xf-c">
                <div className="xf-title">Xakal for</div>
                <img
                  src={HiringText}
                  className="xf-i-text img-fluid"
                  alt="title"
                />
                <p className="xw-content mb-0">
                  <div data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="0">
                  With Xakal, recruitment is now {" "}
                  <span className="text-white font-weight-bold">
                    {" "}
                    10x faster{" "}
                  </span>{" "}
                  , allowing you to quickly review mock interviews and make hiring decisions sooner.
                  </div>
                </p>
                <div className="explore-pro" onClick={pageClick}>Check Profiles</div>
              </div>
              <div className="xf-i ">
              <div data-aos="fade-up" data-aos-duration="2000" >
                <img src={Xakal2} className="img-fluid xf-i2" alt="logo" />
              </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="ellipse-wrapper2">
        <img src={Ellipse2} alt="img" className="ellipse2" />
        </div>
      
        <div className="container">
          <div className="xf-row">
            <div className="xf-c">
              <div className="xf-title">Xakal for</div>
              <img src={JobText} className="xf-i-text img-fluid" alt="title" />
              <p className="xw-content mb-0" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="0">
              Mock interviews with Xakal refine your interview skills, boost your confidence, and improve your performance, ultimately helping you secure
              <span className="text-white font-weight-bold">
                  {" "}
                  your ideal job.{" "}
                </span>{" "}
              </p>
              <div className="explore" onClick={pageClick}>Explore Now</div>
              </div>
            
            <div className="xf-i" >
              <div data-aos="fade-up" data-aos-duration="2000">
              <img src={Xf01} className="img-fluid xf-i2" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      
        <div className="ellipse-wrapper2">
          <img src={Ellipse2} alt="img" className="ellipse2" />
        </div>
      {/* </div> */}
      {/* <div className="xakal-arc2"> */}

      {/* FAQ */}
      <div className="xw-container">
        <h1 className="xw-main-title xw-faq" data-aos="fade-up" data-aos-duration="2000">Frequently asked questions about Xakal</h1>

        <div className="faq-wrapper" data-aos="fade-up" data-aos-duration="2000">
          <Accordion className="xakal-Faq">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="xakal-Faqs"
            >
              I didn't get OTP while sign up. What should I do?
            </AccordionSummary>

            <AccordionDetails className="xakal-Faq-ans">
              <div className="">
                If you didn't receive the OTP during sign up, please check your spam folder. If it's not there, click on the "Resend OTP" option to receive it again.
              </div>
              <img src={Botline} alt="img" />
            </AccordionDetails>

          </Accordion>
        </div>

        <div className="faq-wrapper"  data-aos="fade-up" data-aos-duration="2000">
          <Accordion className="xakal-Faq">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="xakal-Faqs"
            >
              I didn't receive OTP for Mobile number verification. What should I do?
            </AccordionSummary>
            <AccordionDetails className="xakal-Faq-ans">
              <div >
                If you haven't received the OTP for mobile number verification, please ensure that the entered mobile number is correct. You can also try clicking on the "Resend OTP" option to receive it again.
              </div>
              <img src={Botline} alt="img" />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="faq-wrapper" data-aos="fade-up" data-aos-duration="2000">
          <Accordion className="xakal-Faq">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="xakal-Faqs"
            >
              How to create a company page?
            </AccordionSummary>
            <AccordionDetails className="xakal-Faq-ans">
              <div>
                To create a company page, follow these steps:
                <div className="mt-3">1. Sign in to your account.</div>
                <div className="mt-2">2. Access the "Organization" section from the Navigation bar.</div>
                <div className="mt-2">3. On the "Organization" page, click on the "Create New Company" button.</div>
                <div className="mt-2">4. Fill in the details of your organization in the provided "Create Company" form.</div>
                <div className="mt-2">5. Click on the "Create Company" button to finalize the creation of your company page.</div>
              </div>
              <img src={Botline} alt="img" />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="faq-wrapper" data-aos="fade-up" data-aos-duration="2000">
          <Accordion className="xakal-Faq">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="xakal-Faqs"
            >
              How to Create an Interview?
            </AccordionSummary>
            <AccordionDetails className="xakal-Faq-ans">
              <div>
                To create an Interview page, follow these steps:
                <div className="mt-3">1. Sign in to your account on the platform.</div>
                <div className="mt-3">2. Access the "Interviews" section from the Navigation bar.</div>
                <div className="mt-3">3. Click on the "Create an Interview" button located on the "Explore Interviews" page.</div>
                <div className="mt-3">4. Fill in your availability details in the "Manage Availability" popup.</div>
                <div className="mt-3">5. Proceed to the "Create an Interview" popup.</div>
                <div className="mt-3">6. Enter your interview details.</div>
                <div className="mt-3">7. Click on the option to create the interview.</div>


                Alternatively,
                <div className="mt-3 mx-3">1. Sign in to your account and navigate to the "My Profile" section from the Navigation bar.</div>
                <div className="mt-3 mx-3">2. Access the "Interviews" tab on the "About" page within "My Profile."</div>
                <div className="mt-3 mx-3">3. Click on the "Create an Interview" button to open the "Manage Availability" popup.</div>
                <div className="mt-3 mx-3">4. Provide your availability details in the popup.</div>
                <div className="mt-3 mx-3">5. Proceed to the "Create an Interview" popup to fill in your interview details and create the interview.</div>
              </div>
              <img src={Botline} alt="img" />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="faq-wrapper" data-aos="fade-up" data-aos-duration="2000">
          <Accordion className="xakal-Faq">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="xakal-Faqs"
            >
              I’m facing issue with payment. What should I do?
            </AccordionSummary>
            <AccordionDetails className="xakal-Faq-ans">
              <div>
                <div className="mt-3">If you encounter any payment issues, please contact our support team immediately for assistance.</div>
                <div className="mt-3">You can reach us at support@thexakal.com or by calling +91 93634 63195. </div>
                <div className="mt-3">Be sure to provide relevant details such as the transaction ID and the payment method used. </div>
                <div className="mt-3">We'll work to resolve the issue promptly.</div>
              </div>
              <img src={Botline} alt="img" />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="faq-wrapper" data-aos="fade-up" data-aos-duration="2000">
          <Accordion className="xakal-Faq">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="xakal-Faqs"
            >
              When will I get my refund?
            </AccordionSummary>
            <AccordionDetails className="xakal-Faq-ans">
              <div>
                You will receive your refund within 5 – 7 business days from the date you cancel the booking. The reimbursement will be processed using the same payment method you used for the booking, and you will not incur any fees for the reimbursement.
              </div>
              <img src={Botline} alt="img" />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="faq-wrapper" data-aos="fade-up" data-aos-duration="2000">
          <Accordion className="xakal-Faq">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="xakal-Faqs"
            >
              How do I post a job?
            </AccordionSummary>
            <AccordionDetails className="xakal-Faq-ans">
              <div>
                To post a job, follow these steps:
                <div className="mt-3">1. Sign in to your account.</div>
                <div className="mt-3">
                  2. Navigate to the "Jobs" section in the Navigation bar.
                </div>
                <div className="mt-3">
                  3. Click on the "Post a Job" button on the "Explore Jobs"
                  page.
                </div>
                <div className="mt-3">
                  4. An "Add a new Job" popup will appear.
                </div>
                <div className="mt-3">
                  5. Fill in the details of the job requirements.
                </div>
                <div className="mt-3">6. Post the job.</div>
              </div>
              <img src={Botline} alt="img" />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="faq-wrapper" data-aos="fade-up" data-aos-duration="2000">
          <Accordion className="xakal-Faq">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="xakal-Faqs"
            >
              Interview Video call has been disconnected due to technical issue. Will I get a refund?
            </AccordionSummary>
            <AccordionDetails className="xakal-Faq-ans">
              <div>
                If your interview video call was disconnected due to a technical issue, please reach out to our support team with details of the incident. Depending on the circumstances, you may be eligible for a refund.
              </div>
              <img src={Botline} alt="img" />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className="btns-wrapper">
        <img src={Background} alt="img" />
      </div>
      <div className="text-center">
        <button type="button" className="btn-t-primary btns" onClick={pageClick}>
          Get started
        </button>

      </div>


      {/* </div> */}
      <div className="text-center copyright">
        <img src={Logo} className="lp-m-logo" alt="logo" />
        {/* <p className="my-3 ">© Copyright 2024, Xakal Inc.</p> */}
      </div>

    </div>
  );
};

export default LandingPage;
