import React, { useState } from 'react'
import TopHeader from '../MyProfile/TopHeader'
import Select from "react-select"
import "./booking.css"
import styled from "styled-components"
import image from "../../images/image3.png"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const MainBook = styled.div`
display:flex;
justify-content:center;
margin-top:50px;
gap:40px
`
const Card = styled.div`
  max-width: 800px; /* Set your desired max-width */
  width: 100%;
  background: white;
  margin-top: 20px; /* Adjust margin as needed */
  padding: 20px; /* Add padding as needed */
  position: relative;
  display: flex;
  margin-left: 20rem;
  border-radius:30px
`;

const Booking = () => {
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const history = useHistory()
  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen);
  };
  const handleSubmit = () => {
    history.push("/students-portal/booking-detail")
  }
  return (
    <div>
      <ToastContainer />
      <TopHeader data={Booking} />
      <MainBook>
        <div>
          <label>From</label>
          <Select />
        </div>
        <div>
          <label>To</label>
          <Select />
        </div>
        <div>
          <label>From Date</label>
          <Select />
        </div>
        <div>
          <label>To Date</label>
          <Select />
        </div>
        <div>
          <label>Class</label>
          <Select />
        </div>
        <div style={{ display: "flex", marginTop: "20px" }}>
          <button className='button2'>Search</button>
        </div>
      </MainBook>
      <Card>
        <div style={{ display: "flex", gap: "50px" }}>
          <div>
            <img src={image} alt='text' className='spiceLogo' />
          </div>
          <div>
            <p style={{ textAlign: "center" }}>Spice Jet</p>
          </div>
          <div>
            <p style={{ textAlign: "center" }}>14:30</p>
            <p>Chennai</p>
          </div>
          <div>
            <p style={{ textAlign: "center" }}>24hrs</p>
            <hr></hr>
            1 stop via ooty
          </div>
          <div>
            <p style={{ textAlign: "center" }}>14:40</p>
            <p >Bangalore</p>
          </div>
          <div>
            <p>9865</p>
            <p>Price</p>
          </div>
          <div>
            <button className='button3' onClick={toggleAccordion}>
              Pricing
            </button>

          </div>

        </div>

        <div style={{
          position: "absolute",
          top: "130px", background: "white",
          padding: "19px",
          borderRadius: "20px"
        }}>
          {isAccordionOpen && <div style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            gap: "60px"
          }}>
            <div>
              <label>Fare</label>
              <p>Saver</p>
            </div>
            <div>
              <label>Cabin Bag</label>
              <p>7kgs/Adult</p>
            </div>
            <div>
              <label>Check-In</label>
              <p>15kgs/Adult</p>
            </div>
            <div>
              <label>Cancellation</label>
              <p>Cancellation Fee Starting 3600</p>
            </div>
            <div>
              <label>Date Change</label>
              <p>Cancellation Fee Starting 3600</p>
            </div>
            <div>
              <label>Seat</label>
              <p>Chargeable</p>
            </div>
            <div>
              <label>Meal</label>
              <p>Chargeable</p>
            </div>
            <div>
              <label>9960</label>
              <button className='button3' onClick={handleSubmit}>Book Now</button>
            </div>
          </div>}
        </div>


        {/* Content to show/hide when accordion is open */}
        {/* Add your pricing details here */}

      </Card>

    </div>
  )
}

export default Booking