import React, { useState, useEffect } from "react";
import axios from "axios";
import { Calendar } from "react-multi-date-picker";

const AddVacation = ({ id, onClose }) => {
  const [userId, setUserId] = useState({});
  const [values, setValues] = useState([]);
  // eslint-disable-next-line no-unused-vars
  let [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  let [inputValue2, setInput2Value] = useState("");

  const handleChange = (newValues) => {
    const dates = newValues.map((value) => new Date(value));
    setValues(dates);
  };

  const getUser = async() => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
        setUserId(response.data?.userId);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    getUser();
  }, []);
  const formatDate = (date) => {
    const modifiedDate = new Date(date);
    modifiedDate.setDate(modifiedDate.getDate() - 1);

    const year = modifiedDate.getFullYear();
    const month = String(modifiedDate.getMonth() + 1).padStart(2, "0");
    const day = String(modifiedDate.getDate()).padStart(2, "0");
    const hours = "18";
    const minutes = "30";
    const seconds = "00";
    const milliseconds = "000";

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  };


  const handleCreate = async() => {

    const vacationPayload = values.map((date) => formatDate(date));


    const payload = {
      vacationUserId: userId,
      vacation: vacationPayload,

    };

    try{
    const response = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/create-vaccation`, payload)
        console.log(response, "responseVacation");
        onClose();
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };

  const handleUpdate = async() => {
    const vacationPayload = values.map((date) => formatDate(date));



    // Create the payload object to send to the backend
    const payload = {
      vacationUserId: userId,
      vacation: vacationPayload,

    };
    try{
    const response = await axios
      .put(`${process.env.PUBLIC_URL}/xakal/getOne-vaccation/${id}`, payload)
        console.log(response, "responseVaccation");
        onClose();
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  console.log(id);
  const getOneUpdate = async() => {
    try{
    const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/getAllOneVaccation/${id}`)
        const existingVacation = response.data.data;
        const dates = existingVacation.vacation.map((date) => new Date(date));
        setValues(dates);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  console.log(values, "values")
  useEffect(() => {
    if (id) {
      getOneUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          margin: "10px 25px",
        }}
      >
        <Calendar
          multiple
          onlyShowInRangeDates={true}
          value={values}
          onChange={handleChange}
          minDate={inputValue}
          maxDate={inputValue2}
        />
      </div>

      <div
        className="modalFooter"
        style={{ position: "absolute", bottom: "0", width: "100%" }}
      >
        <button
          className="button5"
          style={{ cursor: "pointer", height: "43px" }}
          onClick={onClose}
        >
          Back
        </button>
        {id ? (
          <button
            className="button3"
            style={{ fontSize: "16px", fontweight: "normal", height: "43px" }}
            onClick={handleUpdate}
          >
            Update
          </button>
        ) : (
          <button
            className="button3"
            style={{ fontSize: "16px", fontweight: "normal", height: "43px" }}
            onClick={handleCreate}
          >
            Save
          </button>
        )}
      </div>
    </>
  );
};

export default AddVacation;
