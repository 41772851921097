import React, { useEffect, useState } from 'react'
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import styled from 'styled-components';
import Select from "react-select"
import { Row, Col } from "react-bootstrap";
import axios from "axios"
const Label = styled.div`
font-size: 14px;
color: #4F4953;
font-family:Inter-Medium,sans-serif;
font-weight: normal;
margin: 30px 0 10px 0;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;
const ClassPopup = ({ save, id }) => {
  const [year, setYear] = useState("")
  const [departmentName, setDepartmentName] = useState("")
  const [degreeName, setDegreeName] = useState("")
  const [branch, setBranch] = useState("")
  const [regulation, setRegulation] = useState("")
  const [classNo, setClassNo] = useState("")
  const [sectionNo, setSectionNo] = useState("")
  const [batch, setBatch] = useState("")
  const [yearError, setYearError] = useState('');
  const [departmentError, setDepartmentError] = useState('');
  const [batchError, setBatchError] = useState('');

  const handleEstablishChange = (selectedOption) => {
    if (selectedOption) {
      setYear(String(selectedOption.value)); // Convert the value to a string and set it in the state
    } else {
      setYear(''); // Handle the case where no option is selected
    }
  };
  const startYear = 1900; // The starting year for your dropdown
  const currentYear = new Date().getFullYear(); // Get the current year
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const optionYear = years.map((year) => ({
    value: year,
    label: year,
  }))
  console.log(id, "insitituteiD")

  const validateYear = () => {
    if (year.trim() === '') {
      setYearError('Year is required');
    } else {
      setYearError('');
    }
  };

  const validateDepartment = () => {
    if (departmentName.trim() === '') {
      setDepartmentError('Department Name is required');
    } else {
      setDepartmentError('');
    }
  };

  const validateBatch = () => {
    if (batch.trim() === '') {
      setBatchError('Batch is required');
    } else {
      setBatchError('');
    }
  };
  const handleYearBlur = () => {
    validateYear();
  };

  const handleDepartmentBlur = () => {
    validateDepartment();
  };

  const handleBatchBlur = () => {
    validateBatch();
  };

  const handleDepartment = (e) => {
    setDepartmentName(e.target.value)
  }
  const handleBranch = (e) => {
    setBranch(e.target.value)
  }
  const handleDegree = (e) => {
    setDegreeName(e.target.value)
  }
  const handleBatch = (e) => {
    setBatch(e.target.value)
  }
  const handleRegulation = (e) => {
    setRegulation(e.target.value)
  }
  const handleClassNo = (e) => {
    setClassNo(e.target.value)
  }
  const handleSection = (e) => {
    setSectionNo(e.target.value)
  }
  const getCompanyDetails = async () => {
    return await axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details-classses/${id}`)
      .then((response) => {
        console.log("company details by id", response.data);

      });
  };
  const handleSubmit = async () => {


    // Validate the form fields
    await validateDepartment();
    await validateBatch();
    await validateYear();

    if (departmentError || yearError || batchError) {
      // If there are errors, prevent the form submission
      return;
    } else {
      const payload = {
        instituteId: id,
        departmentName: departmentName,
        degreeName: degreeName,
        branch: branch,
        year: year,
        regulation: regulation,
        classNo: classNo,
        sectionNo: sectionNo,
        batch: batch,
      };

      try {
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/class-create-institute/${id}`,
          payload
        );

        if (response.data.success) {
          console.log(response);
          getCompanyDetails();

        }
        save();
      } catch (err) {
        console.error(err);
        // Handle the error if the Axios request fails
        // You might choose not to close the popup in case of an error
      }
    }
  };




  useEffect(() => {
    getCompanyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div>

      <Label>Enter Class Details</Label>
      <Row>
        <Col lg={4}>
          <Label>Department Name<span className="text-danger">*</span></Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter Department Name"
              aria-label="Department Name"
              name="departmentName"
              value={departmentName}
              onChange={handleDepartment}
              onBlur={handleDepartmentBlur}
              style={{
                borderRadius: '5px',
                background: 'rgba(54, 42, 65, 0.04)',
              }}
            />
          </InputGroup>
          {departmentError && <div style={{ color: 'red' }}>{departmentError}</div>}

        </Col>
        <Col lg={4}>
          <Label>Degree</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter Your Company Pages Name"
              aria-label="Job Title"
              name="degreeName"
              //   onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleDegree}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>
        </Col>
        <Col lg={4}>
          <Label>Branch</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter Batch"
              aria-label="Batch"
              name="batch"
              value={branch}
              onChange={handleBranch}

              style={{
                borderRadius: '5px',
                background: 'rgba(54, 42, 65, 0.04)',
              }}
            />
          </InputGroup>

        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Label>Batch<span className="text-danger">*</span></Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter Your Company Pages Name"
              aria-label="Job Title"
              name="batch"
              //   onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleBatch}
              onBlur={handleBatchBlur}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>
          {batchError && <div style={{ color: 'red' }}>{batchError}</div>}
        </Col>
        <Col lg={4}>
          <Label>Year<span className="text-danger">*</span></Label>
          <InputGroup className="mb-3">
            <Select
              className="footer-header mb-3"
              style={{
                width: '100%',
                height: '40px',
                borderRadius: '5px',
                background: 'rgba(54, 42, 65, 0.04)',
              }}
              name="year"
              value={optionYear.find((option) => option.value === year)}
              onChange={handleEstablishChange}
              isSearchable={true}
              options={optionYear}
              onBlur={handleYearBlur}
              placeholder="Enter the Establishment year"
            />
          </InputGroup>
          {yearError && <div style={{ color: 'red' }}>{yearError}</div>}
        </Col>
        <Col lg={4}>
          <Label>Regulation</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter Your Company Pages Name"
              aria-label="Job Title"
              name="regulation"
              //   onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleRegulation}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Label>Class No</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter Your Company Pages Name"
              aria-label="Job Title"
              name="classNo"
              //   onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleClassNo}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>
        </Col>
        <Col lg={4}>
          <Label>Section</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter Your Company Pages Name"
              aria-label="Job Title"
              name="sectionNo"

              aria-describedby="basic-addon1"
              onChange={handleSection}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>
        </Col>

      </Row>
      <DateWrapper>
        <button onClick={handleSubmit} className="button2" >
          Save
        </button>
      </DateWrapper>

    </div>
  )
}

export default ClassPopup