import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions, DialogTitle } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineCloseCircle, AiOutlinePlus } from "react-icons/ai";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { BsTrash } from "react-icons/bs";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import TopHeader from "../MyProfile/TopHeader";
import avatar1 from "../../images/company.png";
import Admin from "../../images/domains.png";
import Domain from "../../images/setting.png";
import Employee from "../../images/team-management.png";
import Share from "../../images/Share.png";

const Description = styled.div`
color: #A8A1AC;
font-family: General Sans,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: normal;
line-height: normal;
text-transform: capitalize;
margin:20px;
display:flex;
gap:10px;
`
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  position: relative;
  top:20px;
  display: flex;
  justify-content: center;
  padding:20px;
  width: 100%;
  border:0px !important;
  // left: 20%;
  margin: 1rem 1rem 1rem 0xrem;
  
  .css-kzyyzb-MuiDataGrid-root{
    border-style:none;
    color: #362A41;
  font-family: Inter-Medium,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  }
  .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel{
    margin-top:15px;
  }
  .css-levciy-MuiTablePagination-displayedRows{
    margin-top:15px;
  }
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const ButtonLink = styled.div`
  width: auto;
  height: 48px;
  top: 7px;
  border-radius: 8px;
  background-color: rgba(54, 42, 65, 1);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px;
`;

const Label = styled.div`
  font-size: 20px;
  font-family: Inter-Medium,sans-serif;
  color: #362a41;
  font-weight: normal;
  margin: 30px 0 10px 0;
`;
const PageWrapperUp = styled.div`
  background: white;
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const Button3 = styled.div`
  background: #36a47c;
  border-radius: 10px;
  font-family: Inter-Medium;
  font-weight: normal;
  font-size: 15px;
  color: white;
  padding: 7px 10px;
  /* margin: 15px 0; */
  cursor: pointer;
  // &:hover {
  //   background: white;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Button5 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  font-family: Inter-Medium;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  color: #362a41;
  border: 1px solid grey;
  padding: 7px 10px;
  /* margin: 15px 0; */
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Header = styled.div`
color: #362A41;
font-family: Inter-Medium,sans-serif;
font-size: 24px;
font-style: normal;
font-weight: normal;
line-height: normal;
margin-top:15px;
`
const InstituteDomain = () => {
  const columns = [
    { field: "domainName", headerName: "Domain Name", width: 200 },
    {
      field: "type",
      headerName: "Type",
      width: 200,
      renderCell: (params) => {
        const value = params.value;
        let displayValue = "";

        if (value === "primary") {
          displayValue = "Primary";
          return <span style={{ color: "#362A41" }}>{displayValue}</span>;
        } else if (value === "secondary") {
          displayValue = "Secondary";
          return <span style={{ color: "purple" }}>{displayValue}</span>;
        } else {
          return <span>{displayValue}</span>;
        }
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        if (params.row.type === "secondary") {
          return (
            <span>
              <BsTrash
                size={20}
                onClick={() => {
                  setSelectedDomainId(params.row._id); // Set the selected domain
                  setPopupOpen3(true); // Open the confirmation dialog
                }}
              />
              {/* Assuming you have the Bootstrap Icons library included */}
            </span>
          );
        }
      },
    },
  ];
  const { id } = useParams();
  const [domainName, setDomainName] = useState("");
  const [domain, setDomain] = useState();
  const [companyIds, setCompanyId] = useState();
  const [data, setData] = useState();
  const domainSubmit = () => {
    const data = {
      instituteId: companyIds,
      domainName: domainName,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/add-institute-domain/${companyIds}`, data)
      .then((response) => {
        console.log(response);
        setPopupOpen(false);
        getAllDomain(id);
        getOneDetail(id)
        setDomainName("");
      })
      .catch((err) => err.error);
  };

  const getOneDetail = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details/${id}`)
      .then((response) => {
        console.log(response.data.data);
        setCompanyId(response.data.data.instituteId);
        getAllDomain(response.data.data.website);
        setData(response.data.data);
      })
      .catch((err) => {
        console.log(err.error);
      });
  };
  console.log(companyIds, "companyIds");
  const getAllDomain = (website) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-institute-domain/${id}`)
      .then((response) => {
        const a = {
          _id: -1,
          type: "primary",
          domainName: website
            .replace(/\//g, "")
            .replace("www.", "")
            .replace("https:", "")
            .replace("http:", ""),
        };
        console.log(a, website);

        const b = response.data.manageDomain;

        if (b !== undefined) {
          const c = [a].concat(b);
          setDomain(c);
        } else {
          console.error("Response data is undefined or empty");
        }
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getOneDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  const [open, setPopupOpen] = useState(false);
  const [open2, setPopupOpen2] = useState(false);
  const [openPage, setOpenPage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [success, setSuccess] = useState(false);
  const [isListVisible, setListVisible] = useState(false);
  const toggleList = () => {
    setListVisible(!isListVisible);
    setOpenPage(!openPage);
  };
  const handleSubmitInstituteTwo = (comId) => {
    history.push(`/portal/institute-domain/${comId}`);
  };

  const handleOpen = () => {
    setSuccess(true);
  };
  const handleSubmit = () => {
    setPopupOpen(true);
  };
  const [selectedDomainId, setSelectedDomainId] = useState(null);
  const getRowId = (row) => row._id;
  const deleteDomain = () => {
    if (selectedDomainId) {
      axios
        .delete(
          `${process.env.PUBLIC_URL}/xakal/delete-institute-domain/${selectedDomainId}`
        )
        .then((response) => {
          console.log(response, "delete");
          setPopupOpen3(false);
          getAllDomain(id); // Refresh the domain list
          setSelectedDomainId(null);
          toast.success(response.data.message);
        })
        .catch((err) => console.error(err));
    }
  };
  const [open3, setPopupOpen3] = useState(false);
  return (
    <>
      <ToastContainer />
      <TopHeader />
      <MainLayout>
        <div className="Myprofile-container" style={{ margin: "0" }}>
          <div className="Myprofile-topcontainer">
            <div className="myp-avatar">
              {data && data.companyLogo ? (
                <>
                  <div>
                    <img
                      src={data && data.companyLogo}
                      alt="img"
                      style={{
                        width: "190px",
                        height: "190px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <img
                    src={avatar1}
                    alt="img"
                    style={{
                      width: "160px",
                      height: "160px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="myp-wrapper" style={{ display: "block" }}>
            <div className="company" onClick={() => history.push(`/portal/editview-institute/${data && data.instituteId}`)}>{data && data.collegeName}</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Description style={{ marginLeft: "40px" }}>{data && data.companyDescription}</Description>
                <Description style={{ marginLeft: "40px" }}>{data && data.companyTagline}</Description>
                <Description style={{ marginLeft: "40px" }}>
                  <div style={{ margin: "3px 0" }}>{data && data.organisationType}</div>|
                  <div>
                    {data && data.additionalAddress.map((x) => {
                      return (
                        <div>
                          <p >
                            {x.city}, {x.state}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </Description>
              </div>
              <img src={Share} alt="img" style={{ width: "40px", height: "40px", cursor: "pointer" }} onClick={() => toggleList(data && data.companyId)} />
            </div>
            {openPage ? (
              <div className="DeActWrapper">
                <div></div>
                <div className="DeActPage" onClick={() => handleOpen(data && data._id)}>Deactivate Page</div>
              </div>
            ) : ""}
            <div className="Edu-data-wrapper3 social-container">
              {/* <div className="social-wrapper">
                  <div className="social-btn"><img src={Edit} alt="img" className="social-img"/></div>
                  <div className="social-btn" onClick={() =>history.push(`/portal/edit-company/${data && data.companyId}`)}>Edit Page</div>
                  </div> */}
              <div className="social-wrapper">
                <div className="social-btn"><img src={Admin} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => history.push({
                  pathname: "/portal/institute-admin-domain",
                  state: { instituteId: data && data.instituteId, website: data && data.website, }
                })}>Manage Admin</div>

              </div>
              <div className="social-wrapper">
                <div className="social-btn"><img src={Domain} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => handleSubmitInstituteTwo(data && data.instituteId)}>Manage Domains</div>

              </div>
              <div className="social-wrapper">
                <div className="social-btn"><img src={Employee} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => history.push({ pathname: "/portal/institute-employees", state: { instituteId: data && data.instituteId, website: data && data.website }, })}>Manage Employees</div>

              </div>
            </div>
          </div>
        </div>
      </MainLayout>
      <MainLayout>
        {/* <PageWrapperTwo> */}
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Header>Manage Domain</Header>
          <ButtonLink onClick={handleSubmit}>
            <AiOutlinePlus size={20} style={{ marginRight: "10px" }} />
            Add Domain
          </ButtonLink>
        </div>
        {/* </PageWrapperTwo> */}
        <PageWrapper>
          {domain && domain.length > 0 ? (
            <DataGrid
              rows={domain}
              columns={columns}
              getRowId={getRowId}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
          ) : (
            <p>Loading data...</p>
          )}
        </PageWrapper>
        <Dialog open={open} fullWidth maxWidth="lg">
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "18px",
              fontweight: "normal",
              color: "#362A41"
            }}
          >
            <span>Add New Domain</span>
            <AiOutlineCloseCircle onClick={() => setPopupOpen(false)} />
          </DialogTitle>
          <DialogContent>
            <PageWrapperUp>
              <div>
                <Label>Domain Name</Label>
                <InputGroup>
                  <Form.Control
                    aria-label="Job Title"
                    name="name"
                    aria-describedby="basic-addon1"
                    style={{
                      borderRadius: "5px",
                      background: "rgba(54, 42, 65, 0.04)",
                    }}
                    value={domainName}
                    placeholder="Add a new domain name"
                    onChange={(e) => setDomainName(e.target.value)}
                  />
                </InputGroup>
                {/* <Label>Function</Label>

              <div
                style={{
                  position: "relative",
                  display: "inline-flex",
                  gap: "10px",
                }}
              >
                <input type="checkbox" />
                <p style={{ marginTop: "16px" }}>Post Job</p>
              </div> */}
                <p style={{ textAlign: "center", color: "#4F4953", fontweight: "normal", fontStyle: "Inter-Medium,sans-serif", marginTop: "10px" }}>
                  (People with this Domain will be able to post job for your
                  company)
                </p>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    gap: "12px",
                  }}
                >
                  <button variant="outline" className="button5" onClick={() => setPopupOpen(false)}>
                    Cancel
                  </button>
                  <button className="button3" onClick={domainSubmit}>Save</button>
                </div>
              </div>
            </PageWrapperUp>
          </DialogContent>
        </Dialog>
        <Dialog open={open2}>
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              fontFamily: "Inter-Medium",
            }}
          >
            <AiOutlineCloseCircle onClick={() => setPopupOpen2(false)} />
          </DialogTitle>
          <DialogContent>
            <Label>Are you Sure you want to cancel ? </Label>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <Button5
                onClick={() => setPopupOpen2(false)}
                style={{ cursor: "pointer" }}
              >
                Yes
              </Button5>
              <Button3 onClick={() => setPopupOpen2(false)}>No</Button3>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog open={open3}>
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              fontFamily: "Inter-Medium",
            }}
          >
            <AiOutlineCloseCircle onClick={() => setPopupOpen3(false)} />
          </DialogTitle>
          <DialogContent>
            <Label>Are you Sure you want to delete ? </Label>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <Button5 onClick={deleteDomain} style={{ cursor: "pointer" }}>
                Yes
              </Button5>
              <Button3 onClick={() => setPopupOpen3(false)}>No</Button3>
            </div>
          </DialogActions>
        </Dialog>
      </MainLayout>
    </>
  );
};

export default InstituteDomain;
