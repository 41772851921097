import React from "react";
import styled from "styled-components";
import Image from "../../images/profile.png";

import { FaRupeeSign } from "react-icons/fa";
import "../Jobs/buttonStyle.css";
import Suit from "../../images/suitcase.svg";
import Clock from "../../images/clock-circle.svg";
import Location from "../../images/AboutLanding/solar_map-point-bold-duotone.svg";
import Home from "../../images/solar_home-bold-duotone.png";
import Salary from "../../images/solar_hand-money-bold-duotone.png";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContentWrapper = styled.div`
  display: flex;
  // justify-content: space-between;
  // gap: 20px;
  padding: 5px;
`;
const JobDetailIcon = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  left: 5px;
  margin-top: 20px;
  @media (max-width: 576px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;
const JobTitleIcon = styled.div``;

const Wrapper = styled.div`
  margin-top: 10px;
  // position: absolute;
  // margin-left: 15%;
`;

const JobTitle = styled.div`
  width: auto;
  // height: 22px;
  top: 24px;
  left: auto;
  font-family: Inter-Medium,sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #362A41;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;

const Preference = styled.div`
  font-style:
    Inter-Medium,
    sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  color: #362a41;
`;

const PreferenceWrapper = styled.div`
  display: flex;
  flex-wrap:wrap;
  position: relative;
  // height: auto;
  margin: 10px 0;
  background-color: white;
  gap: 10px;
  padding: 12px 6px;
  border-radius: 8px;
`;

const JobPreference = styled.div`
  // color: black;
  background: #f4e8ff;
  padding: 10px;
  border-radius: 5px;
  font-family: Inter-Medium;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
`;


const PageWrapper = styled.div`
  background: #f7f7f8;
  border-radius: 10px;
  padding: 15px;
  // margin: 1rem 0 0rem 0;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 1rem 0;
  }
`;

const CompanyJob = ({ jobData }) => {
  return (
    <>
      <ToastContainer />

      <>
        {" "}

        <PageWrapper>
          <>
            <ContentWrapper className="job-content-wrapper">

              <img src={Image} alt="img" style={{ width: "80px" }} />
              <Wrapper>
                <JobTitle
                  style={{ cursor: "pointer", }}
                  key={jobData._id}
                >
                  {jobData.jobTitle}
                </JobTitle>
                <div style={{ marginTop: "10px" }}
                >
                  {jobData.companyName && jobData.companyName.value}
                </div>
              </Wrapper>
            </ContentWrapper>
            <JobDetailIcon className="job-details">
              <JobTitleIcon className="icon-title">
                {/* <PiSuitcaseSimpleLight size={20} className="jt-icon" /> */}
                <img
                  src={Suit}
                  alt="img"
                  style={{ marginTop: "-5px", width: "20px", height: "20px" }}
                />
                <span>
                  Exp{" "}
                  {jobData.experienceRequired2
                    ? `${jobData.experienceRequired} - ${jobData.experienceRequired2} years`
                    : jobData.experienceRequired
                      ? `${jobData.experienceRequired} years`
                      : ""}
                </span>
              </JobTitleIcon>
              <JobTitleIcon className="icon-title">
                {/* <GiReceiveMoney size={20} className="jt-icon" />{" "} */}
                <img
                  src={Salary}
                  alt="img"
                  style={{ width: "20px", height: "20px" }}
                />
                <FaRupeeSign size={10} color="grey" />
                <span>{`${jobData.salaryFrom}L - ${jobData.salaryTo}L`}</span>
              </JobTitleIcon>
              <JobTitleIcon className="icon-title">
                {" "}
                {/* <HiLocationMarker size={20} className="jt-icon" /> */}
                <img
                  src={Location}
                  alt="img"
                  style={{ width: "20px", height: "20px" }}
                />
                <span>{jobData.location}</span>
              </JobTitleIcon>
              {/* <JobTitle>{i.preference} |</JobTitle> */}
              <JobTitleIcon className="icon-title">
                {" "}
                {/* <TbHome2 size={20} className="jt-icon" /> */}
                <img
                  src={Home}
                  alt="img"
                  style={{ marginTop: "-5px", width: "20px", height: "20px" }}
                />
                <span> {jobData.workMode}</span>
              </JobTitleIcon>
              <JobTitleIcon className="icon-title">
                {" "}
                {/* <BiTime size={20} className="jt-icon" /> */}
                <img
                  src={Clock}
                  alt="img"
                  style={{ width: "20px", height: "20px" }}
                />
                <span> {jobData.employeeType} </span>
              </JobTitleIcon>
            </JobDetailIcon>
            <Preference>Preferred :</Preference>
            <PreferenceWrapper>
              {jobData.preference
                ? jobData.preference.map((i) => {
                  return <JobPreference>{i}</JobPreference>;
                })
                : []}
            </PreferenceWrapper>
          </>
        </PageWrapper>
      </>
    </>
  );
};

export default CompanyJob;
