import React, { useState } from 'react'
import TopHeader from '../MyProfile/TopHeader'
import "./booking.css"
import styled from 'styled-components'
import image from "../../images/image3.png"
import { FaShoppingBag } from 'react-icons/fa'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const MainBook = styled.div`
margin-top:50px;
gap:40px;
background:white;
max-width:800px;
margin-left:25rem;
border-radius:30px
`
const Flex = styled.div`
display:flex;
gap:30px;
margin-top:20px
`
const ButtonConfirm = styled.div`
position: relative;
display: flex;
justify-content: center;
gap: 30px;
margin-top: 20px;
`
const MainDiv = styled.div`
margin-top:50px;
background:white;
border-radius:30px
`
const BookingDetail = () => {
  const [open, setOpen] = useState(false)
  const handleBook = () => {
    setOpen(true)
  }
  const handleConfirm = () => {
    setOpen(false);
    // Delay the toast message until after the component has unmounted
    setTimeout(() => {
      toast.success('Your ticket is booked Successfully and the Detail are shared to Email Id');
      //   history.push('/students-portal/booking');
    }, 0);
  };
  return (
    <div>
      <ToastContainer />
      <TopHeader data={BookingDetail} />
      <div>
        <div style={{ display: "flex", gap: "30px" }}>
          <MainBook>
            <div style={{ padding: "20px" }}>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px"
              }}>
                <p>New-delhi --- Bangalore</p>
                <p>CANCELLATION FEES APPLY</p>
              </div>
              <div style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                <div style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "20px"
                }}>
                  <p>Wednesday, Dec 20</p> <p>Non Stop 3hr 0</p> </div>
                <div>
                  <p>View Fare Rules</p>

                </div>

              </div>
              <div style={{
                position: "relative",
                display: "flex",
                justifyContent: "space-between"
              }}>
                <Flex>
                  <img src={image} alt='imgj' className='spiceLogo' />
                  <p>Indigo CE 6577</p>
                  <p>Air bus</p>
                </Flex>
                <div style={{ marginTop: "20px" }}>
                  <p>Economy -- Save</p>
                </div>
              </div>

              <div>
                <p>14:00
                  New Delhi . Indira Gandhi International Airport, Terminal 2</p>
                <p>17:00
                  Bengaluru . Bengaluru International Airport, Terminal 1</p>
              </div>
              <hr />
              <div style={{
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                gap: "5px"
              }}>
                <FaShoppingBag /><div>Cabin Baggage:7 Kgs (1 piece only) / Adult</div>       {""} <FaShoppingBag /><div>Check-In Baggage:15 Kgs (1 piece only) / Adult</div>
              </div>
            </div>
            <div style={{
              marginTop: "5px",
              padding: "10px",
              display: "flex",
              justifyContent: "flex-end"
            }}>
              <button className='button3' onClick={handleBook}>Book Now</button>

            </div>
          </MainBook>
          <MainDiv>
            <div style={{ padding: "23px" }}>
              <p >Fare Summary</p>
              <div style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                <p>Basic Fare</p>
                <p>15000</p>
              </div>
              <div style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                <p>Tax Fare</p>
                <p>1300</p>
              </div>
              <hr />
              <div style={{
                display: "flex",
                justifyContent: "space-between", gap: "20px"
              }}>
                <p>Total Amount</p>
                <p>18000</p>
              </div>
            </div>
          </MainDiv>
        </div>
      </div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <label>Are you sure you want to Book this flight Indigo CE 6577?</label>

          <ButtonConfirm>
            <button className='button3' onClick={handleConfirm}>Confirm</button>
            <button className='button3' onClick={() => setOpen(false)}>Cancel</button>
          </ButtonConfirm>
        </DialogContent>
      </Dialog>
    </div>

  )
}

export default BookingDetail