import  Select  from "react-select";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

const allFilters = ({
  location,
  setLocation,
  handleSelect,
  toggleDropdown,
  isDropdownOpen,
  toggleDropdowns,
  isDropdownOpens,
  is0To2YearsChecked,
  setIs0To2YearsChecked,
  is2To4YearsChecked,
  setIs2To4YearsChecked,
  is4To6YearsChecked,
  setIs4To6YearsChecked,
  is6To8YearsChecked,
  setIs6To8YearsChecked,
  is8To10YearsChecked,
  setIs8To10YearsChecked,
  setIs10PlusYearsChecked,
  is10PlusYearsChecked,
  designaSkill,
  handleDesignationChange,
  companyData,
  handleCompanyChange,
  optionsTwo,
  handleUniversityChange,
  skillData,
  handleSkillChange,
  languageOptions,
  handleLanguageChange,
  isMaleChecked,
  setIsMaleChecked,
  isFemaleChecked,
  setIsFemaleChecked,
  isOtherChecked,
  setIsOtherChecked,
  setSelectedDesignations,
  setSelectedLocations,
  handleGetResults,
  selectedLocations,
  removeLocation,
  handleGetResetResults
}) => {
  return (
    <div
      style={{
        position: "absolute",
        background: "white",
        zIndex: 9999,
        marginTop: "50px",
        padding: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        left: "353px",
        transform: "translateX(0%)",
        width: "50%",
      }}
    >
      <h4>Filter All Profifle by</h4>
      Location:
      <div style={{ margin: "1rem 0" }}>
        <div>
          <PlacesAutocomplete
            value={location}
            onChange={setLocation}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: "Enter your  location",
                    className: "location-search-input",
                  })}
                  style={{ width: "100%", paddingLeft: "10px" }}
                  className="footer-header font"
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      width: "100%",
                      height: "48px",
                      backgroundColor: "#F4F4F6",
                      border: "1px solid #F4F4F6",
                      borderRadius: "8px",
                      marginBottom: "5px",
                    }),
                  }}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions?.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";

                    const style = suggestion.active
                      ? {
                          backgroundColor: "#fff",
                          cursor: "pointer",
                          marginTop: "10px",
                          marginBottom: "10px",
                          fontSize: "12px",
                          color: "#FFF",
                          borderRadius: "0px",
                        }
                      : {
                          backgroundColor: "#ffffff",
                          height: "fit-content",
                          marginTop: "10px",
                          cursor: "pointer",
                          fontSize: "12px",
                          color: "#362A41",
                        };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span
                          style={{
                            color: "#362A41",
                            padding: "5px",
                            background: "#C6C6C6",
                            borderRadius: "8px",
                            width: "calc(100% - 6px)",
                            height: "fit-content",
                            margin: "0px 0px 0px 0px",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {suggestion.description}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              gap: "10p",
            }}
          >
            {selectedLocations.map((loc, index) => (
              <div
                key={index}
                style={{
                  margin: "5px 0",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <strong>{loc.value}</strong>
                <button
                  onClick={() => removeLocation(index)}
                  style={{
                    marginLeft: "10px",
                    background: "none",
                    border: "none",
                    color: "red",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  X
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      Total Experience:
      <div style={{ margin: "1rem 0" }}>
        <div>
          <span onClick={toggleDropdown} style={{cursor:"pointer"}}>
            {" "}
            Select Experience {isDropdownOpen ? "▲" : "▼"}
          </span>
          {isDropdownOpen && (
            <fieldset
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                marginTop: "10px",
              }}
            >
              <legend
                style={{
                  fontWeight: "bold",
                  display: "inline-block",
                }}
              >
                Experience Levels:
              </legend>

              <label>
                <input
                  type="checkbox"
                  checked={is0To2YearsChecked}
                  onChange={() => setIs0To2YearsChecked(!is0To2YearsChecked)}
                />{" "}
                0 - 2 years
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={is2To4YearsChecked}
                  onChange={() => setIs2To4YearsChecked(!is2To4YearsChecked)}
                />{" "}
                2 - 4 years
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={is4To6YearsChecked}
                  onChange={() => setIs4To6YearsChecked(!is4To6YearsChecked)}
                />{" "}
                4 - 6 years
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={is6To8YearsChecked}
                  onChange={() => setIs6To8YearsChecked(!is6To8YearsChecked)}
                />{" "}
                6 - 8 years
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={is8To10YearsChecked}
                  onChange={() => setIs8To10YearsChecked(!is8To10YearsChecked)}
                />{" "}
                8 - 10 years
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={is10PlusYearsChecked}
                  onChange={() =>
                    setIs10PlusYearsChecked(!is10PlusYearsChecked)
                  }
                />{" "}
                10 + years
              </label>
            </fieldset>
          )}
        </div>
      </div>
      Designation:
      <div className="drop-select">
        <Select
          isMulti
          options={designaSkill}
          onChange={handleDesignationChange}
          style={{cursor:"pointer"}}
        />
      </div>
      company:
      <div className="drop-select">
        <Select isMulti options={companyData} onChange={handleCompanyChange} />
      </div>
      skill:
      <div>
        <Select isMulti options={skillData} onChange={handleSkillChange} />
      </div>
      Language:
      <div>
        <Select
          isMulti
          options={languageOptions}
          onChange={handleLanguageChange}
          placeholder="Select languages..."
        />
      </div>
      University:
      <div>
        <Select
          isMulti
          options={optionsTwo}
          onChange={handleUniversityChange}
          placeholder="Select University..."
        />
      </div>
      Gender:
      <div style={{ margin: "1rem 0" }}>
        <div>
          <span onClick={toggleDropdowns}>
            {" "}
            Select Geneder {isDropdownOpens ? "▲" : "▼"}
          </span>
          {isDropdownOpens && (
            <fieldset
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                marginTop: "10px",
              }}
            >
              <legend
                style={{
                  fontWeight: "bold",
                  display: "inline-block",
                }}
              >
                Gender:
              </legend>

              <label>
                <input
                  type="checkbox"
                  checked={isMaleChecked}
                  onChange={() => setIsMaleChecked(!isMaleChecked)}
                />{" "}
                Male
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={isFemaleChecked}
                  onChange={() => setIsFemaleChecked(!isFemaleChecked)}
                />{" "}
                Female
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={isOtherChecked}
                  onChange={() => setIsOtherChecked(!isOtherChecked)}
                />{" "}
                Other
              </label>
              <br />

              <br />
            </fieldset>
          )}
        </div>
      </div>
      <div
        className="modelFooter"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          style={{ marginRight: "10px" }}
          onClick={handleGetResetResults}
        >
          Reset
        </button>
        <button onClick={handleGetResults}>Get Result</button>
      </div>
    </div>
  );
};

export default allFilters;
