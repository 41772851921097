import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../MyProfile/service.Popup.css";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import xalogo from "../../images/xakallogo.png";
import Alaram from "../../images/Alarms.svg";
import iPlay from "../../images/icons/play-transparent.svg";
import Common from "../../utils/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "../Jobs/shared.css";
import I from "../../images/Danger Circle.svg";
import Salary from "../../images/solar_hand-money-bold-duotone.png";
import Profile from "../../images/company.png";

const Preference = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
  color: #362a41;
  position: relative;
  top: 10px;
`;

const Description = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  margin-top: 25px;
`;
const Designation = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #a8a1ac;
  margin-top: 5px;
`;
const DesignationList = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  background: #f4e8ff;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  margin-left: 10px;
  gap: 10px;
  flex-wrap: wrap;
`;
const PreferenceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  margin: 10px 0 0 0;

  span {
    position: relative;
    top: 0px;
    cursor: pointer;
  }
`;
const ImageWrapper = styled.div`
  img {
    width: 200px;
    height: 180px;
  }
  width: 40%;
  text-align: center;
  background: none;
  @media (min-width: 1025px) {
    margin-top: -100px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
`;
const Content = styled.div`
  font-style: Inter-Medium, sans-serif;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  color: #4f4953;
  display: flex;
  gap: 10px;
  text-align: left;
  img {
    width: 24px;
    height: 24px;
  }
`;

const MainFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SharedInterview = () => {
  const availableSkills = [
    "Web Development",
    "Wireless Communications and Networks",
    "Java Script",
    "Machine Learning",
    "Marketing",
    "MERN",
    "Negotiation",
    "NLP",
    "NumPy",
    "Object-Oriented Programming (OOP)",
    "PCB Design",
    "Persuasion",
    "Postgre Sql",
    "Prompt tuning",
    "Python",
    "Sales",
    "Scaled Agile",
    "Scrum",
    "SEO",
    "snowflake",
    "Sql",
    "UI Design",
    "UX Design",
    "Accounting",
    "Adobe",
    "Agile Methodologies",
    "AI",
    "AWS",
    "Azure DevOps",
    "Circuit Design",
    "Circuit Designer Entry Level",
    "Coaching",
    "Communication",
    "Design",
    "Docker Products and Business Requirements",
    "Electronic Circuit Design",
    "Electronics Hardware Design",
    "Embedded Systems",
    "ETL",
    "Figma",
    "finance",
    "Graphic Designing",
    "Hardware Development",
    "Hardware Product Development",
    "Internet of Things",
  ];

  const [data, setData] = useState();
  const getJobIdFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("jobId");
  };
  const history = useHistory();
  const [id, setId] = useState(null);
  const [aboutDetail, setAboutDetail] = useState();
  const [userOneId, setUserOneId] = useState();
  const getOneCompany = async() => {
    try{
      const response = await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-one-interview/${getJobIdFromURL()}`
      )
        const data = response.data.interview;
        setData(response.data.interview);
        setId(response.data.interview._id);
        setUserOneId(data?.createdBy.userId);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    getOneCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
  };
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = (index) => {
    const video = document.querySelector(`#video-${index}`);
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <div>{timeAgo}</div>;
  };
  const getOneDetail = async() => {
    try{
      await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };

  const clickHome = () => {
    history.push(`/portal/shares-apply-interview/${id}`);
  };
  useEffect(() => {
    var match = document.cookie.match(
      // eslint-disable-next-line no-useless-concat
      new RegExp("(^| )" + "alreadyLoggedIn" + "=([^;]+)")
    );
    if (match && match[2]) {
      getOneDetail();
    }
  }, []);
  const routeDirect = () => {
    history.push("/portal/interview/exploreInterview");
  };
  const loginRedirect = () => {
    history.push({
      pathname: "/login",
      state: { data: id },
    });
  };
  useEffect(() => {
    document.title = "Share Interview | Xakal";
  }, []);
  const getAllUserDetail = async(ids) => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${ids}`)
        setAboutDetail(response.data.aboutOne);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    getAllUserDetail(userOneId);
  }, [userOneId]);
  return (
    <div>
      <div className="main-share-apply">
        <div>
          <img src={xalogo} alt="share" className="sb-main-logo" />
        </div>
        {Common.loggedIn() ? (
          ""
        ) : (
          <div className="job-btn-wrapper">
            <button className="job-btn" onClick={loginRedirect}>
              Sign In
            </button>
          </div>
        )}
      </div>
      <div className="login-wrappersShare">
        <div className="shared-part">
          <div className="shared-part-inner">
            <div className="share-tit-wrap">
              <Preference>{data?.interviewTitle} Interview</Preference>
              <div className="time-ago">
                {" "}
                <img
                  src={aboutDetail?.avatar ? aboutDetail?.avatar : Profile}
                  alt="img"
                />
                <div className="share-by">
                  {aboutDetail?.firstName}
                  <TimeAgo
                    timestamp={data?.timestamps.createdAt}
                    className="share-by"
                  />
                </div>
              </div>
            </div>

            <MainFlex>
              <div className="modalBody">
                {data && data.image && data.image.length > 0 ? (
                  <Slider {...settings}>
                    {data?.image.length ? (
                      data?.image.map((item, index) => (
                        <div key={index}>
                          {item.fileType && (
                            <>
                              {item.fileType.startsWith("image") ? (
                                <img
                                  src={item.url}
                                  className="int-slider-images"
                                  alt={`Images ${index}`}
                                />
                              ) : item.fileType.startsWith("video") ? (
                                <div className="int-video-container">
                                  <div
                                    className="video-poster"
                                    style={{
                                      backgroundImage: {
                                        xalogo,
                                      },
                                      display: isPlaying ? "none" : "flex",
                                      cursor: "pointer",
                                    }}
                                    onClick={handlePlayClick}
                                  >
                                    <img
                                      src={iPlay}
                                      className="play-icon"
                                      alt="icon"
                                    />
                                  </div>
                                  <video
                                    id={`video-${index}`}
                                    src={item.url}
                                    className="int-slider-video"
                                    onPlay={() => setIsPlaying(true)}
                                    onPause={() => setIsPlaying(false)}
                                    onEnded={() => setIsPlaying(false)}
                                    alt={`Video ${index}`}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      ))
                    ) : data?.skill && data?.skill.length ? (
                      data?.skill.map((skill, skillIndex) =>
                        availableSkills.includes(skill.label) ? (
                          <div key={skillIndex}>
                            <img
                              src={`${"https://imagesjob.s3.ap-south-1.amazonaws.com/"}${skill.label}${".svg"}`}
                              className="int-slider-images"
                              alt={`Images ${skillIndex}`}
                            />
                          </div>
                        ) : (
                          <img
                            src={xalogo}
                            className="int-slider-images"
                            alt="images"
                          />
                        )
                      )
                    ) : (
                      <img
                        src={xalogo}
                        className="int-slider-image"
                        alt="images"
                      />
                    )}
                  </Slider>
                ) : (
                  <img
                    src={xalogo}
                    alt="interview-logo"
                    style={{ width: "100%", height: "280px" }}
                  />
                )}

                <div>
                  <Description> {data && data?.description}</Description>
                </div>
                <div>
                  <PreferenceWrapper>
                    <Designation>Designation </Designation>
                    {data?.designation.map((item, index) => (
                      <DesignationList key={index}>{item}</DesignationList>
                    ))}
                  </PreferenceWrapper>
                </div>
                <div>
                  <PreferenceWrapper>
                    <Designation>Skills that'll be evaluated </Designation>
                    {data?.skill.map((item, index) => (
                      <DesignationList key={index}>
                        {item.label}
                      </DesignationList>
                    ))}
                  </PreferenceWrapper>
                </div>
              </div>
            </MainFlex>

            <div className="modalFooters">
              <div className="duration-ser">
                <div className="duration-wrap">
                  <img src={Alaram} alt="img" />
                  {data?.duration} |
                </div>
                <div className="duration-wrap">
                  <img src={Salary} alt="img" />
                  {data?.inrService ? (
                    <div>Free</div>
                  ) : (
                    <div>
                      {data &&
                        new Intl.NumberFormat("en-IN", {}).format(data?.inr)}
                    </div>
                  )}
                </div>
              </div>
              {Common.loggedIn() ? (
                <div>
                  <button className="job-btn share-book" onClick={clickHome}>
                    Book Slot
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    className="job-btn share-book"
                    onClick={loginRedirect}
                  >
                    Book Slot
                  </button>
                </div>
              )}
            </div>
          </div>

          <ImageWrapper>
            <img
              src="https://xakalprod.s3.ap-south-1.amazonaws.com/share-int.svg"
              alt="img"
            />

            <Content>
              Get Interviewd By SME's, Undergo skill assessments by industry
              experts. Showcase feedback to recruiters, and stand out in job
              applications.
            </Content>
            <ButtonWrapper>
              {Common.loggedIn() ? (
                <button className="button07" onClick={routeDirect}>
                  Explore other interviews
                </button>
              ) : (
                <button className="button07" onClick={loginRedirect}>
                  Explore other interviews
                </button>
              )}
            </ButtonWrapper>
            <Content className="int-content">
              <img src={I} alt="img" />
              If this interview doesn't match your skillset, explore other
              interviews that suits to your skillset.
            </Content>
          </ImageWrapper>
        </div>
      </div>

      <div className="login-signupphrase o-desk terms_and_link">
        <div className="shared-links">
          Copyright 2024 Xakal. All rights reserved.
        </div>
        <span
          className="shared-link"
          onClick={() => window.open("/TermsAndConditions", "_blank")}
        >
          Terms and Conditions |
        </span>
        <span
          className="shared-link"
          onClick={() => window.open("/refund-policy", "_blank")}
        >
          Refund Policy |
        </span>
        <span
          className="shared-link"
          onClick={() => window.open("/privacy-policy", "_blank")}
        >
          Privacy Policy |
        </span>
        <span
          className="shared-link"
          onClick={() => window.open("/contact", "_blank")}
        >
          Contact |
        </span>
      </div>
    </div>
  );
};

export default SharedInterview;
