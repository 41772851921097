import React, { useState } from "react";
import styled from 'styled-components';

const ModalWrapper = styled.div`
background:#f3f3f3;
opacity:0.5; 
width:100vw;
height:100vh;
padding:15% 20%;
z-index: 2;
overflow: auto;
position:absolute;
top:0px;
// margin-left:-300px;
`
const PageWrapper2 = styled.div`
  background: white;
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0 0rem 0;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

function Questions() {
  const [inputList, setInputList] = useState([{ firstName: "", lastName: "" }]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { firstName: "", lastName: "" }]);
  };

  return (
    <ModalWrapper>
      <PageWrapper2>
      {inputList.map((x, i) => {
        return (
          <div className="">
            <input
              name="firstName"
              placeholder="Enter first name"
              value={x.firstName}
              onChange={e => handleInputChange(e, i)}
            />
            <input
              className="ml10"
              name="lastName"
              placeholder="Enter last name"
              value={x.lastName}
              onChange={e => handleInputChange(e, i)}
            />
            <div className="">
              {inputList.length !== 1 && <button
                className="mr10"
                onClick={() => handleRemoveClick(i)}>Remove</button>}
              {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
            </div>
          </div>
        );
      })}
      <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div>
    </PageWrapper2>
    </ModalWrapper>
  );
}

export default Questions;
