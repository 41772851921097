import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import "../company/company.css";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import Edit from "../../images/Pen 2.svg";
import WebsiteImg from "../../images/Users Group Two Rounded.png";
import ColName from "../../images/Buildings.png";
import EmpCount from "../../images/Globals.png";
import Email from "../../images/AboutLanding/Letter.svg";
import Phone from "../../images/Phone Rounded.png";
import { Link } from "react-scroll";
import styled from "styled-components";
import AdmissionPost from "../../images/AdmissionPost.png";
import { BsEye, BsFillPencilFill } from "react-icons/bs";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineCloseCircle } from "react-icons/ai";
import AboutPopupInstitute from "./aboutInsititutePopup"
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import CompanyJob from "../company/companyJobs";
import AboutInstitutePopupModal from "./aboutInstituteContactPopup"
import ClassPopup from "./ClassPopup";
import TopHeader from "../MyProfile/TopHeader";
import Select from "react-select";
import avatar1 from "../../images/company.png";
import Admin from "../../images/domains.png";
import Domain from "../../images/setting.png";
import Employee from "../../images/team-management.png";
import Share from "../../images/Share.png";
import size from "../company/organiType.json";
import { ToastContainer, toast } from "react-toastify";

const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  // margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  // margin-top: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
`;
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  //   padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const AboutDiv = styled.div`
  position: relative;
  display: flex;
  padding:20px 20px 20px 0;
  justify-content: space-between;
  margin: 1rem 1rem 1rem 3rem;
  color: #000;
  font-family: Inter-Medium,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;
const Label = styled.div`
color: #8653B6;
font-family: Inter-Medium,sans-serif;
font-size: 18px;
font-style: normal;
font-weight: normal;
line-height: normal;
text-transform: capitalize;

  margin: 30px 0 10px 0;
`;
const Wrapper = styled.div`
  display:flex;
  gap:20px;
  margin:20px 0;
`
const Wrappers = styled.div`
background:#F4F4F6;
border-radius:8px;
padding:10px 20px;
`
const Website = styled.div`
color: #4F4953;
font-family: Inter-Medium,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: normal;
line-height: normal;
text-transform: lowercase;
display:flex;
gap:10px;
`
const WebsiteContent = styled.div`
margin-top:3px;
`
const AboutSubDiv = styled.div`
  position: relative;
  margin: 1rem 1rem 1rem 3rem;
  top: -27px;
`;
const Description = styled.div`
color: #A8A1AC;
font-family: General Sans,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: normal;
line-height: normal;
text-transform: capitalize;
margin:20px;
display:flex;
gap:10px;
`

const JobTitle = styled.h3`
  position: relative;
  font-family: Inter-Medium;
  font-size: 25px;
  color: black;
  display: flex;
  justify-content: space-between;
`;
const CompanyTitle = styled.p`
  position: relative;
  font-family: Inter-Medium;
  font-size: 15px;
  color: black;
`;
const PostedBy = styled.p`
  position: relative;
  font-family: Inter-Medium;
  font-size: 15px;
  color: black;
`;

const EditViewInstitute = ({ onClose, match }) => {
  const [isListVisible, setListVisible] = useState(false);
  const [active, setActive] = useState(true);
  const [active2, setActive2] = useState(false);
  const [onlyAdmission, setOnlyAdmission] = useState(false);
  const [manageAdmission, setManageAdmission] = useState(false);
  const [admissionProfile, setAdmissionProfile] = useState(false);
  const [admissionLeads, setAdmissionLeads] = useState(false);
  const [openAdmission, setOpenAdmission] = useState(false);
  const [openManageAdmission, setOpenManageAdmission] = useState(false);
  const [openLeadAdmission, setOpenLeadAdmission] = useState(false);
  const [openProfileAdmission, setOpenProfileAdmission] = useState(false);

  const { id } = useParams();
  const toggleList = () => {
    setOpenPage(!openPage);
    setListVisible(!isListVisible);
  };
  const [open, setPopupOpen] = useState(false);
  const [openTwo, setPopupOpenTwo] = useState(false);
  const optionsSize = size.size.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const [successOne, setSuccessOne] = useState(false);

  const history = useHistory();
  const GetAllInstitute = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuserinstitute`)
      .then((response) => {
        console.log(JSON.stringify(response), "company value");
        // setInstitute(response.data.company);
      })
      .catch((err) => err.error);
  };
  const deactivateInstitute = () => {
    const checkbox = document.getElementById('confirmationCheckbox');
    if (!checkbox.checked) {

      toast.error("Please confirm deactivation by checking the checkbox");

      return;
    }
    console.log("Before axios request");
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/deactivate-institute/${instituteId}`)
      .then((response) => {
        console.log(response, "deactivated");
        setSuccessOne(false);
        GetAllInstitute();
        toast.success("Company is Successfully Deactivated")
      })
      .catch((err) => {
        console.error(err); // Log any errors to the console
      });
    console.log("After axios request");
  };
  const openPopup = (id) => {
    setPopupOpen(true);
    setSelectedId(id);
  };

  const [selectedId, setSelectedId] = useState(null);
  const [data, setData] = useState();

  const getOneCompanydetail = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details/${id}`)
      .then((response) => {
        console.log(response, "companyOneDetail");
        setData(response.data.data);
        setSelectedId(response.data.data.instituteId);
      })
      .catch((err) => err.error);
  };

  useEffect(() => {
    getOneCompanydetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openClose = () => {
    setPopupOpen(false);
    getOneCompanydetail();
  };
  const openCloseDates = () => {
    setPopupOpenTwo(false);
    getOneCompanydetail();
  };
  const [admissionSize, setAdmissionSize] = useState("")
  const [manageAdmissionSize, setManageAdmissionSize] = useState("")
  const [manageProfileAdmissionSize, setManageProfileAdmissionSize] = useState("")
  const [manageLeadAdmissionSize, setManageLeadAdmissionSize] = useState("")

  const [openJob, setOpenJob] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState(null);
  const [openPage, setOpenPage] = useState();
  // eslint-disable-next-line no-unused-vars
  const [instituteId, setInstituteId] = useState();

  const handleOpen = (jobData) => {
    setOpenJob(true);
    setSelectedJobData(jobData);
  };
  const handleAdmissionSize = async () => {
    const payload = {
      admissionSize: admissionSize,
    }
    await axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-admission/${id}`, payload)
      .then((response) => {
        console.log("company update", response);
        setManageAdmission("")
        getOneCompanydetail()
        setOpenAdmission(false)
      })
      .catch((err) => err.error);
  };
  const handleManageAdmissionSize = async () => {
    const payload = {
      manageAdmissionSize: manageAdmissionSize,
    }
    await axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-admission/${id}`, payload)
      .then((response) => {
        console.log("company update", response);
        setManageAdmissionSize("")
        getOneCompanydetail()
        setOpenManageAdmission(false)
      })
      .catch((err) => err.error);
  }

  const handleProfileAdmissionSize = async () => {
    const payload = {
      manageProfileAdmissionSize: manageProfileAdmissionSize,
    }
    await axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-admission/${id}`, payload)
      .then((response) => {
        console.log("company update", response);
        setManageProfileAdmissionSize("")
        getOneCompanydetail()
        setOpenProfileAdmission(false)
      })
      .catch((err) => err.error);
  }

  const handleLeadAdmissionSize = async () => {
    const payload = {
      manageLeadAdmissionSize: manageLeadAdmissionSize,
    }
    await axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-admission/${id}`, payload)
      .then((response) => {
        console.log("company update", response);
        setManageLeadAdmissionSize("")
        getOneCompanydetail()
        setOpenLeadAdmission(false)
      })
      .catch((err) => err.error);
  }

  const [createId, setCreateId] = useState(null)
  const [classes, setClasses] = useState([])
  console.log(classes);
  const getCompanyDetails = async () => {
    return await axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details-classses/` + match.params.id)
      .then((response) => {
        console.log("company details by id", response.data);
        setCreateId(response.data.data.instituteId)
        setClasses(response.data.data.classes)
      });
  };

  useEffect(() => {
    console.log(classes);
    getCompanyDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [opens, setOpen] = useState(false)
  const openForm = () => {
    setOpen(true)
  }
  const [editingData, setEditingData] = useState({
    id: "",
    // Add other fields here that need to be edited
    // Initialize them with default values
    departmentName: '',
    degreeName: '',
    branch: '',
    batch: '',
    year: '',
    regulation: '',
    classNo: '',
    sectionNo: '',
  });
  const handleSave = (data) => {
    setOpen(false); // Close the dialog if needed
    getCompanyDetails();
  };

  const [editableRowId, setEditableRowId] = useState(null); // Track the ID of the row being edited

  // Function to set the ID of the row to be edited
  const handleRowEditClick = (id) => {
    setEditableRowId(id);

    // Find the data of the row being edited based on its ID and set it in editingData
    const rowToEdit = classes.find((x) => x._id === id);
    if (rowToEdit) {
      setEditingData({
        id: rowToEdit._id,
        departmentName: rowToEdit.departmentName,
        degreeName: rowToEdit.degreeName,
        branch: rowToEdit.branch,
        batch: rowToEdit.batch,
        year: rowToEdit.year,
        regulation: rowToEdit.regulation,
        classNo: rowToEdit.classNo,
        sectionNo: rowToEdit.sectionNo,
      });
    }
  };

  // Function to handle changes in input fields while editing
  const handleInputChange = (event, fieldName) => {
    setEditingData({
      ...editingData,
      [fieldName]: event.target.value,
    });
  };


  const handleSubmitInstituteTwo = (comId) => {
    history.push(`/portal/institute-domain/${comId}`);
  };
  const [jobInstitutre, setJobInstitute] = useState([])
  const getOnedetailInstitute = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute-job/${id}`)
      .then((response) => {
        console.log(response, "companyOneDetailfAFSDFASFDSA");
        setJobInstitute(response.data.data);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getOnedetailInstitute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleEdit = () => {

    axios.put(`${process.env.PUBLIC_URL}/xakal/institute/update/${editingData.id}`, editingData).then((response) => {
      console.log(response)
      getCompanyDetails()
    }).catch((err) => {
      console.log(err)
    })
    setEditableRowId(null);
  }

  const handledelete = (id) => {
    axios.delete(`${process.env.PUBLIC_URL}/xakal/institute-delete/${id}`)
      .then((response) => {

        getCompanyDetails()
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAbout = () => {
    setActive(true);
    setActive2(false);
    setManageAdmission(false);
    setAdmissionLeads(false);
    setAdmissionProfile(false);
    setOnlyAdmission(false)
  }
  const handleAdmission = () => {
    setActive(false);
    setActive2(true);
    setOnlyAdmission(true);
    setAdmissionLeads(false);
    setAdmissionProfile(false);
    setManageAdmission(false);
  }
  const handleManageAdmission = () => {
    setManageAdmission(true);
    setAdmissionLeads(false);
    setAdmissionProfile(false);
    setOnlyAdmission(false)
    // setActive2(false);
  }
  const handleAdmissionProfile = () => {
    setManageAdmission(false);
    setAdmissionLeads(false);
    setAdmissionProfile(true);
    setOnlyAdmission(false)

    // setActive2(false);
  }
  const handleAdmissionLeads = () => {
    setManageAdmission(false);
    setAdmissionLeads(true);
    setAdmissionProfile(false);
    setOnlyAdmission(false)

    // setActive2(false);
  }
  const handleOpens = (jobData) => {
    setOpenJob(true);
    setSelectedJobData(jobData);
  };
  const handleAdmissionChange = (event) => {
    const selectedValue = event.value;

    setAdmissionSize(selectedValue);
  };
  const handleAdmissionManageChange = (event) => {
    const selectedValue = event.value;

    setManageAdmissionSize(selectedValue);
  };
  const handleLeadChange = (event) => {
    const selectedValue = event.value;

    setManageLeadAdmissionSize(selectedValue);
  };
  const handleProfileChange = (event) => {
    const selectedValue = event.value;

    setManageProfileAdmissionSize(selectedValue);
  };
  return (
    <div>
      <ToastContainer />
      <TopHeader />

      <MainLayout>
        <div className="Myprofile-container" style={{ margin: "0" }}>
          <div className="Myprofile-topcontainer">
            <div className="myp-avatar">
              {data && data.companyLogo ? (
                <>
                  <div>
                    <img
                      src={data && data.companyLogo}
                      alt="img"
                      style={{
                        width: "190px",
                        height: "190px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <img
                    src={avatar1}
                    alt="img"
                    style={{
                      width: "160px",
                      height: "160px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="myp-wrapper" style={{ display: "block" }}>
            <div className="company" onClick={() => history.push(`/portal/editview-institute/${data && data.instituteId}`)}>{data && data.collegeName}</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Description style={{ marginLeft: "40px" }}>{data && data.companyDescription}</Description>
                <Description style={{ marginLeft: "40px" }}>{data && data.companyTagline}</Description>
                <Description style={{ marginLeft: "40px" }}>
                  <div style={{ margin: "3px 0" }}>{data && data.organisationType}</div>|
                  <div>{data && data.additionalAddress.map((x) => {
                    return (
                      <p >
                        {x.city}, {x.state}
                      </p>
                    );
                  })}
                  </div>
                </Description>
              </div>
              <img src={Share} alt="img" style={{ width: "40px", height: "40px", cursor: "pointer" }} onClick={() => toggleList(data && data.companyId)} />
            </div>
            {openPage ? (
              <div className="DeActWrapper">
                <div></div>
                <div className="DeActPage" onClick={() => handleOpen(data && data._id)}>Deactivate Page</div>
              </div>
            ) : ""}
            <div className="Edu-data-wrapper3 social-container">
              {/* <div className="social-wrapper">
                  <div className="social-btn"><img src={Edit} alt="img" className="social-img"/></div>
                  <div className="social-btn" onClick={() =>history.push(`/portal/edit-company/${data && data.companyId}`)}>Edit Page</div>
                  </div> */}
              <div className="social-wrapper">
                <div className="social-btn"><img src={Admin} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => history.push({
                  pathname: "/portal/institute-admin-domain",
                  state: { instituteId: data && data.instituteId, website: data && data.website }
                })}>Manage Admin</div>

              </div>
              <div className="social-wrapper">
                <div className="social-btn"><img src={Domain} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => handleSubmitInstituteTwo(data && data.instituteId)}>Manage Domains</div>

              </div>
              <div className="social-wrapper">
                <div className="social-btn"><img src={Employee} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => history.push({ pathname: "/portal/institute-employees", state: { instituteId: data && data.instituteId, website: data && data.website } })}>Manage Employees</div>

              </div>
            </div>
          </div>
        </div>
      </MainLayout>
      <MainLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        {/* <div className={!active && !active2 ? "about-sec" : "about-sec-deact"}>
        <div style={{display:"flex",gap:"20px",justifyContent:"center"}}>
            <div style={{display:"flex",gap:"20px",background:"#FFF",borderRadius:"8px",width:"200px",padding:"10px"}}>
            <div onClick={handleAbout} className="about-heading">About</div>
            <div onClick={handleAdmission} style={{marginTop:"10px"}}>Admission</div>
            </div>
        </div>
        </div> */}
        <div className={active ? "head-link" : "head-link-deact"}>
          <div className="about-links">
            <div>
              <Link to="abouts" spy={true} smooth={true} offset={-180} duration={1000} className="about-heading">About</Link>
            </div>
            <div>
              <Link to="contact" spy={true} smooth={true} offset={-180} duration={1000} className="about-links">Contact Details</Link>
            </div>
            <div>
              <Link to="jobPost" spy={true} smooth={true} offset={-180} duration={1000} className="about-links">Job Posts</Link>
            </div>
            <div>
              <Link to="manageClass" spy={true} smooth={true} offset={-180} duration={1000} className="about-links">Manage Classes</Link>
            </div>
            <div>
              <Link onClick={handleAdmission}>Admission</Link>
            </div>
          </div>
        </div>
        <div className={active2 ? "head-link" : "head-link-deact"}>
          <div className="about-links">
            <div >
              <Link onClick={handleAbout}>About</Link>
            </div>
            <div>
              <Link to="abouts" spy={true} smooth={true} offset={-180} duration={1000} onClick={handleManageAdmission}>Manage Admission</Link>
            </div>
            <div>
              <Link to="contact" spy={true} smooth={true} offset={-180} duration={1000} onClick={handleAdmissionProfile}>Admission Profile</Link>
            </div>
            <div>
              <Link to="jobPost" spy={true} smooth={true} offset={-180} duration={1000} onClick={handleAdmissionLeads}>Admission Leads</Link>
            </div>
            <div>
              <Link to="jobPost" spy={true} smooth={true} offset={-180} duration={1000} className="about-heading" onClick={handleAdmission}>Admission</Link>
            </div>

          </div>
        </div>
        <div className={active ? "about-sec" : "about-sec-deact"}>
          <PageWrapper>
            <div id="about">
              <AboutDiv>
                <h1>About</h1>
                <img src={Edit} alt="img" onClick={() => openPopup(selectedId)} style={{ cursor: "pointer" }} />
                {/* <FaRegEdit
                size={30}
                color="black"
                style={{ position: "relative", marginTop: "30px" }}
                onClick={() => openPopup(selectedId)}
              /> */}
              </AboutDiv>
              <AboutSubDiv>
                {/* <Label>Name</Label> */}
                <Label>{data && data.collegeName}</Label>
                {/* <Label>Website</Label> */}
                <Website>{data && data.website}</Website>
                {/* <Label>Industry</Label> */}
                <Wrapper>
                  <Website><img src={EmpCount} alt="img" /><WebsiteContent>{data && data.servicesProvided}</WebsiteContent></Website>
                  {/* <Label>Organisation Size</Label> */}
                  <Website><img src={ColName} alt="img" /><WebsiteContent>{data && data.organisationSize}</WebsiteContent></Website>
                  {/* <Label>Organisation Type</Label> */}
                  <Website><img src={WebsiteImg} alt="img" /><WebsiteContent>{data && data.organisationType}</WebsiteContent></Website>
                </Wrapper>
                <Wrappers>
                  <Website>
                    {/* <Label>Tag Line</Label> */}
                    {data && data.companyTagline ? (
                      <>{data && data.companyTagline}</>
                    ) : (
                      "-"
                    )}
                  </Website>
                </Wrappers>
              </AboutSubDiv>
            </div>
          </PageWrapper>
          <PageWrapper>
            <div id="contact">
              <AboutDiv>
                <h1>Contact Details</h1>
                <img src={Edit} alt="img" onClick={() => setPopupOpenTwo(selectedId)} style={{ cursor: "pointer" }} />
                {/* <FaRegEdit
                size={30}
                color="black"
                style={{ position: "relative", marginTop: "30px" }}
                onClick={() => openPopupTwo(selectedId)}
              /> */}
              </AboutDiv>
              <AboutSubDiv>
                {/* <Label>ContactNumber</Label> */}
                <Wrapper>
                  <Website>
                    <img src={Phone} alt="img" />
                    <WebsiteContent>
                      {data && data.contactNumber ? (
                        <>{data && `${data.contactNumber}`}</>
                      ) : (
                        "-"
                      )}
                    </WebsiteContent>
                  </Website>
                  {/* <Label>Contact Mail Id</Label> */}
                  <Website>
                    <img src={Email} alt="img" />
                    <WebsiteContent>
                      {data && data.contactEmailId}
                    </WebsiteContent>
                  </Website>
                </Wrapper>
                <Wrappers>
                  {/* <Label>Location</Label> */}
                  <Website>
                    {data && data?.primaryAddress ? <>
                      {data &&
                        `${data && data?.primaryAddress?.addressOne} ${data && data?.primaryAddress?.addressTwo
                        } ${data && data.primaryAddress?.city} ${data && data.primaryAddress?.state
                        } ${data && data.primaryAddress?.country} ${data && data.primaryAddress?.pincode
                        }`}
                    </> : "-"}
                  </Website>
                </Wrappers>
                {/* <Label>Workplace modes</Label>
              {data &&
                data.workMode?.map((a) => {
                  return <>{a}</>;
                })} */}
              </AboutSubDiv>
            </div>
          </PageWrapper>
          <PageWrapper>
            <div id="jobPost">
              <AboutDiv>
                <h1>Job Post</h1>
              </AboutDiv>
              {jobInstitutre &&
                jobInstitutre.map((a) => {
                  return (
                    <AboutSubDiv>
                      <JobTitle>
                        {a.jobTitle}
                        <BsEye
                          size={30}
                          style={{ marginTop: "10px" }}
                          onClick={() => handleOpens(a)}
                        />
                      </JobTitle>
                      <CompanyTitle>{a.companyName.value}</CompanyTitle>

                      <PostedBy>
                        Posted By :{" "}
                        {`${a.createdBy.firstName} ${a.createdBy.lastName}`}
                      </PostedBy>
                    </AboutSubDiv>
                  );
                })}
            </div>
          </PageWrapper>
          <PageWrapper>
            <div id="manageClass">
              <AboutDiv>
                <h1>Manage Classes</h1>
              </AboutDiv>

            </div>
            <DateWrapper onClick={openForm}>
              <button className="button2">
                +Add
              </button>
            </DateWrapper>
            <div style={{ padding: "20px" }}>
              <Table striped bordered hover>
                <thead>
                  <tr style={{ color: "#4F4953", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px", fontweight: "normal" }}>
                    <th style={{ width: '10%' }}>Department Name</th>
                    <th style={{ width: '10%' }}>Degree Name</th>
                    <th style={{ width: '10%' }}>Branch</th>
                    <th style={{ width: '10%' }}>Batch</th>
                    <th style={{ width: '10%' }}>Year</th>
                    <th style={{ width: '10%' }}>Regulation</th>
                    <th style={{ width: '10%' }}>Class No</th>
                    <th style={{ width: '10%' }}>Section</th>
                    <th style={{ width: '10%' }}>Edit</th>
                    <th style={{ width: '10%' }}>Delete</th>
                  </tr>
                </thead>
                {classes.map((x, i) => {
                  const isRowEditable = x._id === editableRowId;
                  return (
                    <tbody key={i}>
                      <tr style={{ color: "#362A41", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px", fontweight: "normal" }}>
                        <td>
                          {isRowEditable ? (
                            <input
                              value={editingData.departmentName}
                              onChange={(e) => handleInputChange(e, 'departmentName')}
                              className="manageClass-edit"
                            />
                          ) : (
                            x.departmentName
                          )}
                        </td>
                        <td>
                          {isRowEditable ? (
                            <input
                              value={editingData.degreeName}
                              onChange={(e) => handleInputChange(e, 'degreeName')}
                              className="manageClass-edit"
                            />
                          ) : (
                            x.degreeName
                          )}
                        </td>
                        <td>
                          {isRowEditable ? (
                            <input
                              value={editingData.branch}
                              onChange={(e) => handleInputChange(e, 'branch')}
                              className="manageClass-edit"
                            />
                          ) : (
                            x.branch
                          )}
                        </td>
                        <td>
                          {isRowEditable ? (
                            <input
                              value={editingData.batch}
                              onChange={(e) => handleInputChange(e, 'batch')}
                              className="manageClass-edit"
                            />
                          ) : (
                            x.batch
                          )}
                        </td>
                        <td>
                          {isRowEditable ? (
                            <input
                              value={editingData.year}
                              onChange={(e) => handleInputChange(e, 'year')}
                              className="manageClass-edit"
                            />
                          ) : (
                            x.year
                          )}
                        </td>
                        <td>
                          {isRowEditable ? (
                            <input
                              value={editingData.regulation}
                              onChange={(e) => handleInputChange(e, 'regulation')}
                              className="manageClass-edit"
                            />
                          ) : (
                            x.regulation
                          )}
                        </td>
                        <td>
                          {isRowEditable ? (
                            <input
                              value={editingData.classNo}
                              onChange={(e) => handleInputChange(e, 'classNo')}
                              className="manageClass-edit"
                            />
                          ) : (
                            x.classNo
                          )}
                        </td>
                        <td>
                          {isRowEditable ? (
                            <input
                              value={editingData.sectionNo}
                              onChange={(e) => handleInputChange(e, 'sectionNo')}
                              className="manageClass-edit"
                            />
                          ) : (
                            x.sectionNo
                          )}
                        </td>
                        <td>{isRowEditable ? <button onClick={() => handleEdit(x._id)}>save</button> : <BsFillPencilFill onClick={() => handleRowEditClick(x._id)} />}</td>
                        <td><FaTrash onClick={() => handledelete(x._id)} /></td>


                      </tr>
                    </tbody>
                  )
                })}

              </Table>

            </div>



          </PageWrapper>
        </div>
        <div className={onlyAdmission ? "about-sec" : "about-sec-deact"}>
          <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
            <img src={AdmissionPost} alt="img" onClick={() => setOpenAdmission(true)} />
          </div>
        </div>
        <div className={manageAdmission ? "about-sec" : "about-sec-deact"}>
          <div style={{ display: "flex", justifyContent: "center", margin: "10px", cursor: "pointer" }}>
            <img src={AdmissionPost} alt="img" onClick={() => setOpenManageAdmission(true)} />
          </div>
        </div>
        <div className={admissionProfile ? "about-sec" : "about-sec-deact"}>
          <div style={{ display: "flex", justifyContent: "center", margin: "20px", cursor: "pointer" }}>
            <img src={AdmissionPost} alt="img" onClick={() => setOpenProfileAdmission(true)} />
          </div>
        </div>
        <div className={admissionLeads ? "about-sec" : "about-sec-deact"}>
          <div style={{ display: "flex", justifyContent: "center", margin: "30px", cursor: "pointer" }}>
            <img src={AdmissionPost} alt="img" onClick={() => setOpenLeadAdmission(true)} />
          </div>
        </div>
      </MainLayout>

      <Dialog
        open={successOne}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <h4>Deactivate Page</h4>
        </DialogTitle>
        <DialogContent>
          <h4>We're really sorry for your decision</h4>
          <p>
            Deactivation will remove the page entirely from Xakal and your
            institute admins will no longer will have the access to the page
          </p>
          <div style={{ display: "flex", }}>
            <input type="checkbox" id="confirmationCheckbox" />
            <p style={{
              position: "relative",
              marginLeft: "15px",
              marginTop: "12px"
            }}>I hereby confirm that I deactivate the Company page</p>
          </div>
          <div style={{
            position: "relative",
            display: " flex",
            justifyContent: "right",
            gap: "20px",
          }}>
            <button onClick={() => setSuccessOne(false)}>Cancel</button>
            <button onClick={() => deactivateInstitute(instituteId)}>
              Deactivate
            </button>
          </div>

        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            fontweight: "normal",
            color: "#362A41"
          }}
        >
          <span>Edit About</span>
          <AiOutlineCloseCircle onClick={() => setPopupOpen(false)} />
        </DialogTitle>
        <DialogContent>
          <AboutPopupInstitute id={selectedId} onClosfun={openClose} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openTwo}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            fontweight: "normal",
            color: "#362A41"
          }}
        >
          <span>Edit Contact</span>
          <AiOutlineCloseCircle onClick={() => setPopupOpenTwo(false)} />
        </DialogTitle>
        <DialogContent>
          <AboutInstitutePopupModal id={selectedId} onClose={openCloseDates} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJob}
        fullWidth
        maxWidth="lg"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <span>JobDetail</span>
          <AiOutlineCloseCircle onClick={() => setOpenJob(false)} />
        </DialogTitle>
        <DialogContent>
          <CompanyJob jobData={selectedJobData} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={opens}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        > <AiOutlineCloseCircle onClick={() => setOpen(false)} /></DialogTitle>
        <DialogContent>
          <ClassPopup save={handleSave} id={createId} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openAdmission}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            fontweight: "normal",
            color: "#362A41"
          }}
        >
          <span>Admission</span>
          <AiOutlineCloseCircle onClick={() => setOpenAdmission(false)} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div style={{ fontFamily: "Inter-Medium,sans-serif", fontSize: "16px", fontweight: "normal", color: "#362A41", margin: "10px" }}>Admission Size</div>
            <Select
              class="footer-header  mb-3"
              styles={{

                height: "40px",
                control: (base, state) => ({
                  ...base,
                  width: "100%",
                  backgroundColor: "#F4F4F6",
                  border: "1px solid #F4F4F6",
                  borderRadius: "8px"
                })
              }}

              placeholder="Select the Size"
              name="admissionSize"
              value={optionsSize.find(
                (option) => option.value === admissionSize
              )}
              options={optionsSize}
              onChange={handleAdmissionChange}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>(please mention your Admission Availiablity)</div>
          <div style={{ display: "flex", justifyContent: "end", margin: "20px" }}>
            <button className="button2" onClick={handleAdmissionSize}>Submit</button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openManageAdmission}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            fontweight: "normal",
            color: "#362A41"
          }}
        >
          <span>Manage Admission</span>
          <AiOutlineCloseCircle onClick={() => setOpenManageAdmission(false)} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div style={{ fontFamily: "Inter-Medium,sans-serif", fontSize: "16px", fontweight: "normal", color: "#362A41", margin: "10px" }}>Admission Size</div>
            <Select
              class="footer-header  mb-3"
              styles={{

                height: "40px",
                control: (base, state) => ({
                  ...base,
                  width: "100%",
                  backgroundColor: "#F4F4F6",
                  border: "1px solid #F4F4F6",
                  borderRadius: "8px"
                })
              }}

              placeholder="Select the Size"
              name="manageAdmissionSize"
              value={optionsSize.find(
                (option) => option.value === manageAdmissionSize
              )}
              options={optionsSize}
              onChange={handleAdmissionManageChange}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>(please mention your Admission Availiablity)</div>
          <div style={{ display: "flex", justifyContent: "end", margin: "20px" }}>
            <button className="button2" onClick={handleManageAdmissionSize}>Submit</button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openLeadAdmission}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            fontweight: "normal",
            color: "#362A41"
          }}
        >
          <span>Lead Admission</span>
          <AiOutlineCloseCircle onClick={() => setOpenLeadAdmission(false)} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div style={{ fontFamily: "Inter-Medium,sans-serif", fontSize: "16px", fontweight: "normal", color: "#362A41", margin: "10px" }}>Admission Size</div>
            <Select
              class="footer-header  mb-3"
              styles={{

                height: "40px",
                control: (base, state) => ({
                  ...base,
                  width: "100%",
                  backgroundColor: "#F4F4F6",
                  border: "1px solid #F4F4F6",
                  borderRadius: "8px"
                })
              }}
              name="manageLeadAdmissionSize"
              placeholder="Select the Size"

              value={optionsSize.find(
                (option) => option.value === manageLeadAdmissionSize
              )}
              options={optionsSize}
              onChange={handleLeadChange}

            />
          </div>
          <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>(please mention your Admission Availiablity)</div>
          <div style={{ display: "flex", justifyContent: "end", margin: "20px" }}>
            <button className="button2" onClick={handleLeadAdmissionSize}>Submit</button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openProfileAdmission}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            fontweight: "normal",
            color: "#362A41"
          }}
        >
          <span>Manage Profile Admission</span>
          <AiOutlineCloseCircle onClick={() => setOpenProfileAdmission(false)} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div style={{ fontFamily: "Inter-Medium,sans-serif", fontSize: "16px", fontweight: "normal", color: "#362A41", margin: "10px" }}>Admission Size</div>
            <Select
              class="footer-header  mb-3"
              styles={{

                height: "40px",
                control: (base, state) => ({
                  ...base,
                  width: "100%",
                  backgroundColor: "#F4F4F6",
                  border: "1px solid #F4F4F6",
                  borderRadius: "8px"
                })
              }}

              name="manageProfileAdmissionSize"
              placeholder="Select the Size"

              value={optionsSize.find(
                (option) => option.value === manageProfileAdmissionSize
              )}
              options={optionsSize}
              onChange={handleProfileChange}

            />
          </div>
          <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>(please mention your Admission Availiablity)</div>
          <div style={{ display: "flex", justifyContent: "end", margin: "20px" }}>
            <button className="button2" onClick={handleProfileAdmissionSize}>Submit</button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={opens}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        > <AiOutlineCloseCircle onClick={() => setOpen(false)} /></DialogTitle>
        <DialogContent>
          <ClassPopup save={handleSave} id={createId} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditViewInstitute;
