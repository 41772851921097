import React, { useEffect, useState } from "react";
import DesktopHeader from "../desktopHeader";
import "./bankTransactiondetail.css";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import { Row } from "reactstrap";
import { Col } from "react-bootstrap";
import Common from "../../utils/common";
const BankOneDetail = () => {
  const { id } = useParams();
  const [bankDetail, setBankDetail] = useState();
  const [accountDetail, setAccountDetail] = useState();
  const getOne = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${id}`)
      .then((response) => {
        setBankDetail(response.data.bookingSlot);
      })
      .catch((err) => {
      });
  };
  const getOneAccount = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/account-get/${Common.loggedIn()}`)
      .then((response) => {
        setAccountDetail(response.data.account[0]);
      })
      .catch((err) => {
      });
  };
  useEffect(() => {
    getOne(id);
    getOneAccount();
  }, [id]);
  return (
    <div className="myprofile-wrapper">
      <DesktopHeader title={"Bank Transaction"} isSearchActive={false} />

      <div className="card-containers">
        <div className="cards">
          <h2>{bankDetail?.serviceName}</h2>
          <Row>
            <Col>Date</Col>
            <Col>{bankDetail?.timestamps?.createdAt}</Col>
          </Row>
          <Row>
            <Col>Interview Id</Col>
            <Col>{bankDetail?.interviewCodeId}</Col>
          </Row>
          <Row>
            <Col>Booking Id</Col>
            <Col>{bankDetail?.slotBookingId}</Col>
          </Row>
          <Row>
            <Col>Transaction Id</Col>
            <Col>{bankDetail?.transactionCodeId}</Col>
          </Row>
          <Row>
            <Col>Transaction Category</Col>
            <Col>{"Interview Booking"}</Col>
          </Row>
          <Row>
            <Col>Paid From</Col>
            <Col>{accountDetail?.bankname}</Col>
          </Row>
          <Row>
            <Col>Account Number</Col>
            <Col>{accountDetail && accountDetail?.accnumber}</Col>
          </Row>
          <Row>
            <Col>Amount</Col>
            <Col>{bankDetail && bankDetail?.price}</Col>
          </Row>
          <Row>
            <Col>Status</Col>
            <Col>{bankDetail?.paid === "paid" ? "Completed" : "Failed"}</Col>
          </Row>
          <p>
            Have any questions about this trasnaction Get Help?
          </p>
        </div>
      </div>
      
    </div>
  );
};

export default BankOneDetail;
