/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Dialog from "@mui/material/Dialog";
import { DialogTitle } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import interactionPlugin from "@fullcalendar/interaction";
import "./myCalender.css";
import styled from "styled-components";
import Common from "../../utils/common";
import axios from "axios";
import InterviewEdit from "../ats/updateInterview";
import { BsCreditCard2Front } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdOutlineLogout } from "react-icons/md";
import { MdContentCopy } from "react-icons/md";
import { IoIosDoneAll } from "react-icons/io";
import { Auth } from "aws-amplify";
import CloseIcon from "../../images/CloseIcon.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DesktopHeader from "../desktopHeader";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import Download from "../../images/Download Minimalistic.svg";
import { format } from "date-fns";
const Calendar = styled.div``;

const MainLayout = styled.div``;

const Title = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  // letter-spacing: 0.20000000298023224px;
  text-align: left;
  clor: #4f4953;
`;
const DialogTag = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  margin: 10px 0;
  display: flex;
  gap: 10px;
  color: rgba(54, 42, 65, 1);
  span {
    display: flex;
    // color: rgba(136, 140, 165, 1);
    font-size: 12px;
    font-weight:700;
  }
`;

const DialogTags = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px 0 15px 0;
  color: rgba(54, 42, 65, 1);
  span {
    font-size: 12px;
    font-weight:700;
  }
`;

const Text = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  color: #362a41;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
`;

const MyCalender = () => {
  const [events, setEvents] = useState([]);
  const [openSetting, setOpenSetting] = useState(false);
  const history = useHistory();

  const [isCopied, setIsCopied] = useState(false);

  const calendarRef = useRef();

  const getMyEvents = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/events/${Common.loggedIn()}`
      );
      setEvents(res.data.filter((e) => e.status === "active"));
    } catch (error) {
      console.error("Error fetching events:", error);
      // Handle the error (e.g., set an error state or show a message)
      setEvents([]); // or set an appropriate state for handling errors
    }
  };
  const convertApiResponseToFullCalendarEvents = (apiEvents) => {
    return apiEvents.map((apiEvent) => ({
      main_id: apiEvent._id,
      id: apiEvent._id,
      title: apiEvent.eventTitle,
      bookingId: apiEvent.bookingId,
      description: apiEvent.eventDescription,
      eventEmail: apiEvent.eventEmail,
      jobId: apiEvent.jobId,
      applicantId: apiEvent.applicantId,
      venue: apiEvent.eventVenue,
      url: apiEvent.eventUrl,
      starts: apiEvent.fromEpoch,
      interviewCompleted: apiEvent.interviewCompleted,
      ends: apiEvent.toEpoch,
      start: new Date(apiEvent.fromEpoch * 1000),
      end: new Date(apiEvent.toEpoch * 1000),
      rawdata: apiEvent,
    }));
  };

  useEffect(() => {
    getMyEvents();
  }, []);
  const handleOpen = () => {
    setInterviewPopup(false);
    getMyEvents();
  };

  const [fullCalendarEvents, setFullCalendarEvents] = useState([]);

  // State to manage displayed events based on search filter
  const [displayedEvents, setDisplayedEvents] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    // Convert API events to FullCalendar format
    const convertedEvents = convertApiResponseToFullCalendarEvents(events);
    const filteredEvents = convertedEvents.filter(
      (event) => event.interviewCompleted !== "completed"
    );
    setFullCalendarEvents(filteredEvents);
    setDisplayedEvents(filteredEvents); // Set displayed events initially

    // Update FullCalendar events when component state changes
    if (calendarRef.current) {
      calendarRef.current.getApi().removeAllEvents();
      calendarRef.current.getApi().addEventSource(filteredEvents);
    }
  }, [events]);
  const [interviewPopup, setInterviewPopup] = useState(false);
  const [videoRoomPopup, setVideoroomPopup] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [organizer, setOrganizer] = useState([]);
  const [guests, setGuests] = useState([]);
  const [test, setTest] = useState();

  const getOneBooking = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-one-booking/${id}`
      );
      setTest(res.data.bookingSlot);
    } catch (error) {
      console.error("Error fetching booking:", error);
      // Handle the error (e.g., show a message to the user or update state)
      setTest(null); // or set a specific error state/message
    }
  };
  const handleEventClick = (info) => {
    setOrganizer([]);
    setGuests([]);
    // Find the selected event based on the event ID
    info.jsEvent.preventDefault();

    const selectedEvent = fullCalendarEvents.find(
      (event) => event.id === info.event.id
    );

    if (selectedEvent) {
      getHostandGuestDetails(selectedEvent);
      getHostDetails(selectedEvent);
      if (selectedEvent.bookingId) {
        getOneBooking(selectedEvent.bookingId);
      }
      setSelectedEvent(selectedEvent);
      if (selectedEvent?.rawdata?.eventType === "service")
        setVideoroomPopup(true);
      else setInterviewPopup(true);
    }
  };
  console.log("guest:", guests._id, "and", "org:", organizer.userId);
  const [success, setSuccess] = useState(false);
  const [user, setUsers] = useState("");
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);
  const logout = () => {
    Common.logout();
  };
  function onSubmit() {
    setSuccess(true);
  }
  const convertEpochToDateTime = (time) => {
    const date = new Date(time * 1000);

    const options = { month: "short", day: "numeric", year: "numeric" };
    const datePart = date.toLocaleString(undefined, options);

    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    const timePart =
      hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;

    const formattedDateString = datePart + " - " + timePart;

    return formattedDateString;
  };
  const convertEpochToDateTimes = (time) => {
    const date = new Date(time * 1000);

    const options = { month: "short", day: "numeric", year: "numeric" };
    const datePart = date.toLocaleString(undefined, options);

    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    const timePart =
      hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;

    const formattedDateString = timePart;

    return formattedDateString;
  };
  const getUser = async (id, type) => {
    let resp;

    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${id}`
      );

      if (response && response.data && response.data.success) {
        resp = response.data.aboutOne;

        if (type === "host") {
          setOrganizer((prevData) => [...prevData, resp]);
        }

        if (type === "guest") {
          setGuests((prevData) => [...prevData, resp]);
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle the error (e.g., log it, set an error state, etc.)
    }

    return resp; // Note: This will still return undefined if no successful response occurs.
  };
  const getHostandGuestDetails = async (selectedEvent) => {
    if (selectedEvent?.rawdata?.guest?.length > 0) {
      for (const item of selectedEvent?.rawdata?.guest) {
        await getUser(item?.userId, "guest");
      }
    }
  };
  const [hostTrue, setHostTrue] = useState(false);
  const getHostDetails = async (selectedEvent) => {
    if (selectedEvent?.rawdata?.guest?.length > 0) {
      for (const item of selectedEvent?.rawdata?.host) {
        await getUser(item?.userId, "host");
      }
    }
    setHostTrue(false);
  };

  useEffect(() => {
    if (searchKeyword.trim() === "") {
      setDisplayedEvents(fullCalendarEvents); // Display all events if no search
    } else {
      const filtered = fullCalendarEvents.filter((event) =>
        event.title.toLowerCase().includes(searchKeyword.toLowerCase())
      );
      setDisplayedEvents(filtered); // Update displayed events based on search
    }
  }, [searchKeyword, fullCalendarEvents]);

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
  };
  const searchedKey = () => {};
  useEffect(() => {
    document.title = "My Calender | Xakal";
  }, []);
  const [pendingFeedbackData, setPendingFeedback] = useState([]);

  const getAllPending = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-pending-feedback`
      );
      setPendingFeedback(res.data.book);
    } catch (err) {
      console.error("Error fetching pending feedback:", err);
      // You may want to handle the error more gracefully here (e.g., set an error state)
    }
  };
  const provideFeedback = () => {
    history.push("/portal/pending-feedback-interview");
  };
  const [openPending, setOpenPending] = useState(false);
  useEffect(() => {
    getAllPending();
  }, []);
  useEffect(() => {
    if (pendingFeedbackData.length > 0) {
      setOpenPending(true);
    }
  }, [pendingFeedbackData]);
  const location = useLocation();
  const openTestAssesment = location.state && location.state.openAssesment;
  const bookId = location.state && location.state.bookId;
  const [openAssesment, setOpenAssesment] = useState(openTestAssesment);

  const closeAssesment = () => {
    setOpenAssesment(!openAssesment);
  };
  const [formData, setFormData] = useState();
  const [emails, setEmails] = useState([]);
  useEffect(() => {
    // Set initial email values if 'event' prop has emails
    if (selectedEvent?.eventEmail && selectedEvent.eventEmail.length > 0) {
      setEmails(selectedEvent.eventEmail);
    }
  }, [selectedEvent]);
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to the server
    const fromDate = moment(
      `${formData?.fromDate} ${formData?.fromTime}`,
      "YYYY-MM-DD HH:mm"
    );
    const toDate = moment(
      `${formData?.toDate} ${formData?.toTime}`,
      "YYYY-MM-DD HH:mm"
    );

    // Validate start and end dates
    // Validate start and end dates
    if (
      // Condition 1: Check if the "from date" is not valid
      !fromDate.isValid() ||
      // Condition 2: Check if the "to date" is not valid
      !toDate.isValid() ||
      // Condition 3: Check if the "to date" is before the "from date"
      toDate.isBefore(fromDate) ||
      // Condition 4: Check if the "from date" and "to date" are not the same day
      !fromDate.isSame(toDate, "day")
    ) {
      // Display different error messages based on the conditions
      if (!fromDate.isValid() || !toDate.isValid()) {
        toast.error("Invalid date format. Please check your input.");
      } else if (toDate.isBefore(fromDate)) {
        toast.error("To date should not be less than From date.");
      } else if (!fromDate.isSame(toDate, "day")) {
        toast.error("From date and to date should be the same day.");
      }

      // Exit the function
      return;
    }

    // Ensure the time range is valid (optional)
    if (fromDate.isSame(toDate) && fromDate.isSameOrAfter(toDate)) {
      toast.error("Invalid time range");
      return;
    }

    // Ensure from date and to date are the same
    if (!fromDate.isSame(toDate, "day")) {
      toast.error("From date and to date should be the same day");
      return;
    }

    const payload = {
      eventTitle: formData.title,
      eventDescription: formData.description,
      eventUrl: formData.url,
      eventVenue: formData.venue,
      eventEmail: emails,
      city: formData.city,
      country: formData.country,
      pincode: formData.pincode,
      state: formData.state,
      addressOne: formData.address1,
      addressTwo: formData.address2,
      guest: [],
      host: [
        {
          userId: Common.loggedIn(),
        },
      ],
    };
    payload["fromEpoch"] = fromDate.unix();
    payload["toEpoch"] = toDate.unix();

    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/get-calendar-updates/${selectedEvent.main_id}`,
        payload
      )
      .then((res) => {
        axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/apply-interviewed/${selectedEvent.jobId}/${selectedEvent.applicantId}`,
            {
              interviewTitle: formData.title,
              emailArray: emails,
              fromDate: fromDate.format("YYYY-MM-DD"),
              fromTime: fromDate.format("HH:mm"),
              toTime: toDate.format("HH:mm"),
              venue: formData.venue,
              eventUrl: formData.url,
              city: formData.city,
              country: formData.country,
              pincode: formData.pincode,
              state: formData.state,
              addressOne: formData.address1,
              addressTwo: formData.address2,
            }
          )
          .then((res) => {
            toast.success("Applicant status updated to Interviewed");
          })
          .catch((error) => {
            console.error("Error updating status to Interviewed", error);
            toast.error("Error updating applicant status to Interviewed");
          });
      });
    handleOpen();
  };
  useEffect(() => {
    setFormData({
      title: selectedEvent?.title || "",
      description: selectedEvent?.description || "",
      venue: selectedEvent?.venue || "",
      url: selectedEvent?.url || "",
      fromDate: selectedEvent
        ? moment(selectedEvent.start).format("YYYY-MM-DD")
        : "",
      fromTime: selectedEvent
        ? moment(selectedEvent.start).format("HH:mm")
        : "",
      toDate: selectedEvent
        ? moment(selectedEvent.end).format("YYYY-MM-DD")
        : "",
      toTime: selectedEvent ? moment(selectedEvent.end).format("HH:mm") : "",
      guestId: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    });
  }, [selectedEvent]);
  // Function to handle the resume download
  const handleDownload = (url, fileName) => {
    window.open(url, "_blank");
  };
  const formatEventTime = (date) => {
    return format(date, "h a").toUpperCase();
    // Format time as "10 AM" or "10 PM"
  };
  const renderEventContent = (eventInfo) => {
    const { timeText, event } = eventInfo;
    const candidateName = event.extendedProps.candidateName || "N/A";
    const formattedStartTime = formatEventTime(event.start);
    // Default value if candidateName is not available
    return (
      <>
        <div>{formattedStartTime}</div>
        <div>{event.title}</div>
        {guests?.map((itm, idx) => (
          <DialogTag key={idx} style={{ fontSize: "12px" }}>
            <span>Candidate:</span>
            {itm?.firstName + " " + itm?.lastName}
          </DialogTag>
        ))}
      </>
    );
  };
  const isCurrentUserOrganizer = (currentUser) => {
    return organizer.some((org) => org.userId === currentUser);
  };

  const currentUser = Common.loggedIn();
  console.log(currentUser);
  return (
    <div>
      <div className="header-layouts"><DesktopHeader searchedKey={searchedKey} title="My Calendar"/></div>
      <MainLayout className="mc-wrapper">
        <Calendar id="calender" className="mc-container">
          <FullCalendar
            className="mc-calendar"
            ref={calendarRef}
            eventClick={handleEventClick}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            eventContent={renderEventContent}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            events={displayedEvents}
          />
        </Calendar>
      </MainLayout>
      <Dialog
        open={interviewPopup}
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          Schedule an interview
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setInterviewPopup(false)}
          />
        </div>
        <div className="modalBody">
          {selectedEvent && (
            // Render the content related to the selected event
            <InterviewEdit
              event={selectedEvent}
              close={() => setInterviewPopup(false)}
              closePopup={handleOpen}
              setFormData={setFormData}
              formData={formData}
              emails={emails}
              setEmails={setEmails}
            />
          )}
        </div>
        <div className="modalFooter">
          <button className="button5" onClick={() => setInterviewPopup(false)}>
            Cancel
          </button>
          <button className="button3" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </Dialog>
      <Dialog
        open={openConfirm}
        PaperProps={{
          style: {
            borderRadius: "24px",
            maxWidth: "400px",
          },
        }}
      >
        <div className="modalHeader mheader">
          <div className="mTitle">Disclaimer</div>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => {
              setOpenConfirm(false);
              setVideoroomPopup(true);
            }}
          />
        </div>
        <DialogContent>
          <div className="modalBody">
            <p style={{ color: "#4F4953", fontweight: "normal" }}>
              We want to remind you that the interview session will be recorded
              for quality assurance purposes.{" "}
            </p>
            <p style={{ color: "#4F4953", fontweight: "normal" }}>
              We kindly request that you refrain from disclosing any personal
              details during the interview process.
            </p>
            {/* <p style={{color:"#4F4953",fontweight: "normal"}}>Wishing you the best of luck with your interview!</p> */}
          </div>
        </DialogContent>
        <div className="modalFooter" style={{ padding: "10px" }}>
          <button
            className="button2"
            onClick={() =>
              history.push({
                pathname: `/portal/videoRoom/${selectedEvent?.rawdata?.bookingId}/${selectedEvent?.rawdata?.roomName}`,
                state: {
                  main_id: selectedEvent?.main_id,
                  serviceId: test?.serviceId,
                },
              })
            }
          >
            Okay, Proceed
          </button>
        </div>
      </Dialog>
      <Dialog
        open={videoRoomPopup}
        PaperProps={{
          style: {
            borderRadius: "24px",
            padding: "10px 20px 10px 20px",
            maxWidth: "400px",
            minHeight: "260px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="modalHeader">
          <div className="mTitle">Event</div>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setVideoroomPopup(false)}
          />
        </DialogTitle>

        <div className="modalBody">
          <div>
            <Title>Interview for {selectedEvent?.title}</Title>
            <DialogTag>
              {convertEpochToDateTime(selectedEvent?.starts)} to{" "}
              {convertEpochToDateTimes(selectedEvent?.ends)}
            </DialogTag>

            <div>
              {isCurrentUserOrganizer(currentUser) ? (
                <div>
                  {guests?.map((itm, idx) => (
                    <div>
                      <DialogTag key={idx} style={{ fontSize: "12px" }}>
                        <span>Candidate:</span>
                        {itm?.firstName + " " + itm?.lastName}
                      </DialogTag>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {organizer?.map((itm, idx) => (
                    <div>
                      <DialogTag key={idx} style={{ fontSize: "12px" }}>
                        <span>Interviewer:</span>
                        {itm?.firstName + " " + itm?.lastName}
                      </DialogTag>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div >
              <DialogTags><span>Designation:</span> {selectedEvent?.title}</DialogTags>{" "}
            </div>

            <>
              <div>
                {isCurrentUserOrganizer(currentUser) && (
                  <img
                    src={Download}
                    alt="Download"
                    className="down-img"
                    onClick={() => {
                      handleDownload(test.resume, "resume.pdf");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}

                <button
                  className="button"
                  onClick={() => {
                    setOpenConfirm(true);
                    setVideoroomPopup(false);
                  }}
                >
                  Join
                </button>
              </div>
            </>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "280px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/portal/myRequests")}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p> My Requests</p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/portal/pendingRequest")}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p> Pending Request</p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <button className="button5" onClick={() => setSuccess(false)}>
              No
            </button>
            {user ? (
              <button
                className="button3"
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </button>
            ) : (
              <button className="button3" onClick={logout}>
                Yes
              </button>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MyCalender;
