import React from 'react';
import styled from 'styled-components';
import companyLogo from "../../images/company.png";
import { Colors } from "../utilities/colors";

const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const HeaderText = styled.h2`
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  color: black;
  margin: 10px 0 10px 0;
  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0 0rem 0;

  label{
    font-size:20px;
    color:#362A41;
  }
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const DataWrapper = styled.div`
  border:1px solid black;
  border-radius:5px;
  padding:10px;
  width:330px; 
  height:40px;
  font-size:16px;
  color:#362A41;
`
const Checkbox = styled.div`
  display: flex;
  gap:20px;
  justify-content: flex-end;
  position: relative;
  right: 50px;
  cursor:pointer;
`;
const PreviewAdmission = () => {
    return(
        <div>
            <MainLayout>
            <HeaderText>{"Admission Form Preview"}</HeaderText>

            <PageWrapper>
            <div className="myprofile-content-head" >

                <img src={companyLogo} alt="yuo" style={{borderRadius:"50%"}}/>
                <div style={{marginTop:"40px"}}>
                    <label>FirstName*</label>
                    <DataWrapper>Raja</DataWrapper>   
                </div>

                <div style={{marginTop:"40px"}}>
                    <label>Middle Name*</label>
                    <DataWrapper>S</DataWrapper>   
                </div>

                <div style={{marginTop:"40px"}}>
                    <label>Last Name*</label>
                    <DataWrapper>Srithar</DataWrapper>   
                </div>

            </div>
            <div className="myprofile-content-head input-wrapper3">
               <div>
                 <label>Gender*</label>
                 <DataWrapper>Male</DataWrapper>   
               </div>

              <div>
                <label>Date of birth</label>
                <DataWrapper>09-03-1990</DataWrapper>   
              </div>

              <div style={{marginLeft:"200px"}}>
                <label>Mobile No*</label>
                <DataWrapper>9442586975</DataWrapper>                       
              </div>
              
            </div>
            <div className="myprofile-content-head input-wrapper3">
                <div>
                <label>Religion</label>
                <DataWrapper>Hindhu</DataWrapper>                        
                </div>
                <div>

                <label>Email Id</label>
                <DataWrapper>raja@gmail.com</DataWrapper>                       
                </div>
            </div>

            <h2 style={{color:"black",marginTop:"40px"}}>Present Address*</h2>
            <div className="myprofile-content-head input-wrapper">
                <div>
                    <label>Address Line 1</label>
                    <DataWrapper>Adayar</DataWrapper>
                </div>

                <div>
                    <label>Address Line2</label>
                    <DataWrapper>Chennai</DataWrapper>
                </div>
            </div>
            
            <div className="myprofile-content-head input-wrapper3">
                <div>
                    <label>Country*</label>
                    <DataWrapper>India</DataWrapper>
                </div>
                    
                
                <div>
                    <label>State / Provision*</label>
                    <DataWrapper>TamilNadu</DataWrapper>
                </div>
                    
                
                <div>
                    <label>City*</label>
                    <DataWrapper>chennai</DataWrapper>
                </div>

                <div>
                <label>PIN/ZIP code*</label>
                <DataWrapper>642002</DataWrapper>
                
                </div>  
                
                
              </div>

              <Checkbox>
                <input type="checkbox"></input>
                <h3>Keep Present Address as Your Permanant Address</h3>
              </Checkbox>

              <h2 style={{color:"black"}}>Permanentt Address*</h2>
            <div className="myprofile-content-head input-wrapper">
                <div>
                    <label>Address Line 1</label>
                    <DataWrapper>Adayar</DataWrapper>
                </div>

                <div>
                    <label>Address Line2</label>
                    <DataWrapper>Chennai</DataWrapper>
                </div>
            </div>
            
            <div className="myprofile-content-head input-wrapper3">
                <div>
                    <label>Country*</label>
                    <DataWrapper>India</DataWrapper>
                </div>
                    
                
                <div>
                    <label>State / Provision*</label>
                    <DataWrapper>TamilNadu</DataWrapper>
                </div>
                    
                
                <div>
                    <label>City*</label>
                    <DataWrapper>Chennai</DataWrapper>
                </div>

                <div>
                <label>PIN/ZIP code*</label>
                <DataWrapper>642002</DataWrapper>
                
                </div>  
              </div>
            
              <h2 style={{color:"black",marginTop:"60px"}}>Parents/Guardian Info</h2>
            <div className="myprofile-content-head ">
                <div>
                    <label>Father's Name</label>
                    <DataWrapper>Selvaraj</DataWrapper>
                </div>

                <div>
                <label>Father's Occupation</label>
                <DataWrapper>Business</DataWrapper>
                </div>

                <div>
                <label>Father's Annual Income</label>
                <DataWrapper>700000</DataWrapper>
                </div>
            </div>

            <div className="myprofile-content-head input-wrapper3">
                <div>
                <label>Father's Mobile No</label>
                <DataWrapper>raja@gmail.com</DataWrapper>
                </div>
                <div>

                <label>Father's Email Id</label>
                <DataWrapper>9442678956</DataWrapper>
                </div>
                
            </div>

            <div className="myprofile-content-head input-wrapper3" style={{marginTop:"30px"}}>
                <div>
                <label>Mother's Name</label>
                <DataWrapper>Gbhshsac</DataWrapper> 
                </div>

                <div>
                <label>Mother's Occupation</label>
                <DataWrapper>HouseMaker</DataWrapper>
                </div>

                <div>
                <label>Mother's Annual Income</label>
                <DataWrapper>-</DataWrapper>
                </div>
            </div>
            <div className="myprofile-content-head input-wrapper3">
                <div>
                <label>Motherr's Mobile No</label>
                <DataWrapper>9442678541</DataWrapper>
                </div>
                <div>

                <label>Mother's Email Id</label>
                <DataWrapper>raja@gmail.com</DataWrapper>
                </div>
                
            </div>

            <h1 style={{color:"black",marginTop:"60px"}}>Education</h1>
            <h2 style={{color:"black"}}>10th / SSLC</h2>

            <div className="myprofile-content-head" style={{marginTop:"-10px"}}>
                <div>
                <label>School/University*</label>
                <DataWrapper>KKV Matric Hr Sec School</DataWrapper>
                </div>
             

                <div>
                <label>Year of Passing</label>
                <DataWrapper>2013</DataWrapper> 
                    
                </div>

                <div>
                <label>Board</label>
                <DataWrapper>Matric</DataWrapper>
                </div>
                  
            </div>
            
            <div className="myprofile-content-head input-wrapper3">
                <div>
                <label>School Medium</label>
                <DataWrapper>English</DataWrapper>
                </div>
                <div>
                <label>Total Marks Obtained</label>
                <DataWrapper>456</DataWrapper>
                </div>
                <div>
                <h3>Upload Mark Sheet (optional)</h3>
                
                </div>
            </div> 

            <h2 style={{color:"black"}}>12th / HSC</h2>
            <div className="myprofile-content-head input-wrapper">
              <div>
                <label>School/University*</label>
                <DataWrapper>KKV Matric Hr Sec Scholl</DataWrapper>
              </div>

                <div>
                <label>Year of Passing</label>
                <DataWrapper>2015</DataWrapper>
                    
                </div>

                <div>
                <label>Board</label>
                <DataWrapper>Matric</DataWrapper>
                </div>

                <div>
                <label>School Medium</label>
                <DataWrapper>English</DataWrapper>
                </div>
            </div>
            <div className="myprofile-content-head input-wrapper3">
                
                <div>
                <label>Group</label>
                <DataWrapper>CSE</DataWrapper>
                
                
                </div>
                <div>
                <label>Total Marks Obtained</label>
                <DataWrapper>556</DataWrapper>
                </div>
                <div>
                <label>Cut Off</label>
                <DataWrapper>182</DataWrapper>
                </div>
                
            </div>
            <h3>Upload Mark Sheet (optional)</h3>

            <h2 style={{color:"black"}}>Diploma</h2>
            <div className="myprofile-content-head input-wrapper">
                  <div>
                    <label>School / University</label>
                    <DataWrapper>raja@gmail.com</DataWrapper>
                  </div>

                <div>
                <label>Degree</label>                  
                <DataWrapper>raja@gmail.com</DataWrapper>
                </div>

                <div>
                <label >Specilization</label>
                <DataWrapper>raja@gmail.com</DataWrapper>                  
                </div>
                <div>
                  <label>Eduction Type</label>
                  <DataWrapper>raja@gmail.com</DataWrapper>              
                </div>
            </div>
            <div className="myprofile-content-head input-wrapper3">
                <div>
                    <label >Start Date*</label>
                    <DataWrapper>raja@gmail.com</DataWrapper>
                </div>
                <div>
                    <label >End Date*</label>
                    <DataWrapper>raja@gmail.com</DataWrapper>
                </div>
                <div>
                <label>History of Arrears</label>
                <DataWrapper>raja@gmail.com</DataWrapper>
                </div>
                <div style={{marginLeft:"18px"}}>
                    <label >Percentage Obtained*</label>
                    <DataWrapper>raja@gmail.com</DataWrapper>
                </div>
            </div>
            <h3>Upload Mark Sheet (optional)</h3>

            <h2 style={{color:"black"}}>Under Graduation</h2>
            <div className="myprofile-content-head input-wrapper">
                  <div>
                    <label>School / University</label>
                    <DataWrapper>raja@gmail.com</DataWrapper>
                  </div>

                <div>
                <label>Degree</label>                  
                <DataWrapper>raja@gmail.com</DataWrapper>
                </div>

                <div>
                <label >Specilization</label>
                <DataWrapper>raja@gmail.com</DataWrapper>                  
                </div>
                <div>
                  <label>Eduction Type</label>
                  <DataWrapper>raja@gmail.com</DataWrapper>              
                </div>
            </div>
            <div className="myprofile-content-head input-wrapper3">
                <div>
                    <label >Start Date*</label>
                    <DataWrapper>raja@gmail.com</DataWrapper>
                </div>
                <div>
                    <label >End Date*</label>
                    <DataWrapper>raja@gmail.com</DataWrapper>
                </div>
                <div>
                <label>History of Arrears</label>
                <DataWrapper>raja@gmail.com</DataWrapper>
                </div>
                <div style={{marginLeft:"18px"}}>
                    <label >Percentage Obtained*</label>
                    <DataWrapper>raja@gmail.com</DataWrapper>
                </div>
            </div>
            <h3>Upload Mark Sheet (optional)</h3>

            <div className="about-end">
            <h4 >Cancel</h4>
            <button className="button2" >
                SUBMIT
            </button>
            </div>

            </PageWrapper>
            </MainLayout>
        </div>
    )
}

export default PreviewAdmission;