import React, { useState, useEffect } from "react";
import DesktopHeader from "../desktopHeader";
import "./dashboard.css";
import axios from "axios";
import InterviewList from "./interviewList";
import BookedList from "./bookedList";
import JobList from "./jobList";
import UserList from "./userList";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("user");
  const [jobCount, setJobCount] = useState(0);
  const [interviewCount, setInterviewCount] = useState(0);
  const [bookCount, setBookCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [newCount, setNewUserCount] = useState(0);
  const [newCountYesterday, setNewUserCounYesterDayt] = useState(0);
  const [jobCountYesterday, setJobCountYesterDay] = useState(0);
  const [yesterdayCountInterview, setYesterdayCountInterview] = useState(0);
  const [yesterdayCountBook, setYesterdayCountBook] = useState(0);

  const [newJobCount, setNewJobCount] = useState(0);
  const [newInterviewCount, setNewInterviewCount] = useState(0);
  const [newBookCount, setBookNewCount] = useState(0);
  const [interviewList, setInterviewList] = useState([]);
  const [bookedList, setBookedList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [userList, setUserList] = useState([]);

  const fetchData = async() => {
    try{
      const res = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-data-count`)

        setBookCount(res.data.lengthSlot);
        setInterviewCount(res.data.lengthInterview);
        setJobCount(res.data.lengthJob);
        setUserCount(res.data.aboutDetail);
        setNewUserCount(res.data.newUsersToday);
        setNewJobCount(res.data.newJobCount);
        setNewInterviewCount(res.data.newInterviewCount);
        setBookNewCount(res.data.newBookCount);
        setInterviewList(res.data.interview);
        setBookedList(res.data.bookSlots);
        setJobList(res.data.job);
        setUserList(res.data.AboutDetails);
        setNewUserCounYesterDayt(res.data.newUsersYesterday)
        setJobCountYesterDay(res.data.newJobCountYesterday)
        setYesterdayCountInterview(res.data.newInterviewCountYesterday)
        setYesterdayCountBook(res.data.newBookCountYesterday)
      }
      catch(err){console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5000); // Fetch data every 5 seconds
    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);
  useEffect(() => {
    document.title = "Dashboard | Xakal";
  }, []);
  return (
    <div>
      <div className="header-layouts"><DesktopHeader title={"Dashboard"} /></div>
      <div className="tile-container">
      <div className={`tile ${activeTab === "user" ? "active" : ""}`} onClick={() => setActiveTab("user")}>
          <div className="separted-dashboard">
            <span className="span-texts">Number of User</span>
            <span className="title-numbers">{userCount}</span>
          </div>
          <div className="separted-dashboard">
            <span className="span-texts">Today user</span>
            <span className="title-numbers">{newCount}</span>
          </div>
          <div className="separted-dashboard">
            <span className="span-texts">Yesterday user</span>
            <span className="title-numbers">{newCountYesterday}</span>
          </div>
        </div>
        <div className={`tile ${activeTab === "job" ? "active" : ""}`} onClick={() => setActiveTab("job")}>
          <div className="separted-dashboard">
            <span className="span-texts">Number of job</span>
            <span className="title-numbers">{jobCount}</span>
          </div>
          <div className="separted-dashboard">
            <span className="span-texts">Today job</span>
            <span className="title-numbers">{newJobCount}</span>
          </div>
          <div className="separted-dashboard">
            <span className="span-texts">Yesterday Job</span>
            <span className="title-numbers">{jobCountYesterday}</span>
          </div>
        </div>
        <div className={`tile ${activeTab === "interview" ? "active" : ""}`} onClick={() => setActiveTab("interview")}>
          <div className="separted-dashboard">
            <span className="span-texts">Number of interview</span>
            <span className="title-numbers">{interviewCount}</span>
          </div>
          <div className="separted-dashboard">
            <span className="span-texts">Today interview</span>
            <span className="title-numbers">{newInterviewCount}</span>
          </div>
          <div className="separted-dashboard">
            <span className="span-texts">Yesterday interview</span>
            <span className="title-numbers">{yesterdayCountInterview}</span>
          </div>
        </div>
        <div className={`tile ${activeTab === "bookSlot" ? "active" : ""}`} onClick={() => setActiveTab("bookSlot")}>
          <div className="separted-dashboard">
            <span className="span-texts">Number of Slot booked</span>
            <span className="title-numbers">{bookCount}</span>
          </div>
          <div className="separted-dashboard">
            <span className="span-texts">Today Slot</span>
            <span className="title-numbers">{newBookCount}</span>
          </div>
          <div className="separted-dashboard">
            <span className="span-texts">Yesterday Slot</span>
            <span className="title-numbers">{yesterdayCountBook}</span>
          </div>
        </div>
   
      </div>

      {activeTab === "interview" && (
        <InterviewList
          totalCount={interviewCount}
          newCount={newInterviewCount}
          yesterday={yesterdayCountInterview}
          data={interviewList}
        />
      )}
      {activeTab === "bookSlot" && (
        <BookedList
          totalCount={bookCount}
          newCount={newBookCount}
          yesterday={yesterdayCountBook}
          data={bookedList}
        />
      )}
      {activeTab === "job" && (
        <JobList totalCount={jobCount} newCount={newJobCount} data={jobList} 
        yesterday={jobCountYesterday}
        
        />
      )}
      {activeTab === "user" && (
        <UserList totalCount={userCount} newCount={newCount} data={userList} 
        yesterday={newCountYesterday}
        />
      )}
    </div>
  );
};

export default Dashboard;
