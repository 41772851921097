/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DesktopHeader from "../desktopHeader";
import "./apt.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { Dialog } from "@mui/material";
import tick from "../../images/landing/tick.png";
import CloseIcon from "../../images/CloseIcon.svg";
import axios from "axios";
const AptitudeCandidate = () => {
  const history = useHistory();
  const location = useLocation();
  const candidateId = location.state && location.state.candidate;
  const marks = location.state && location.state.marks;

  const [openPopup, setOpenPopup] = useState(false);
  const [retakeOpen, setRetakeOpen] = useState(false);
  const [retakeButtonLabel, setRetakeButtonLabel] = useState("Retake");
  useEffect(() => {
    setOpenPopup(candidateId);
  }, []);
  const handleRetake = () => {
    setRetakeOpen(true);
    getAllResult();
  };
  const handleRetest = () => {
    setRetakeOpen(false);
    getAllResult();
  };
  const [result, setResult] = useState([]);
  const [highestMark, setHighestMark] = useState(0);
  const [attempt, setHighestAttempt] = useState(0);

  const getAllResult = async() => {
    try{
      const res = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-general-result`)
      
        setResult(res.data.finalScoreModal);
        const marksArray = res.data.finalScoreModal.map((item) => item.marks);
        const attempt = res.data.finalScoreModal.map((item) => item.attempt);

        // Flatten marksArray into a single array of marks
        const allMarks = marksArray.flat();

        // Find the highest mark
        const highestMark = Math.max(...allMarks);
        setHighestMark(highestMark ? highestMark : 0);

        // eslint-disable-next-line no-unused-vars
        const allAttempt = attempt.flat();

        if (marksArray.length > 0) {
          const allAttempts = marksArray.flat();
          const highestMark = Math.max(...allAttempts);
          setHighestMark(highestMark);
        } else {
          setHighestMark(0); // Set default value if no attempts are fetched
        }
        if (attempt.length > 0) {
          const allAttempts = attempt.flat();
          const highestAttempt = Math.max(...allAttempts);
          setHighestAttempt(highestAttempt);
        } else {
          setHighestAttempt(0); // Set default value if no attempts are fetched
        }
      }
      catch(err){console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    getAllResult();
  }, []);
  // eslint-disable-next-line no-unused-vars
  const attempts = 0;
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format");
    }

    const formattedDate = date.toLocaleDateString("en-US", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const hours = date.getHours();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Adjusting hours to 12-hour format
    const minutes = date.getMinutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Adding leading zero if minutes < 10
    const formattedTime12 = `${formattedHours}:${formattedMinutes}${period}`;

    return `${formattedDate} ${formattedTime12}`;
  };

  const generateRandomId = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const randomId = generateRandomId(24);
  const closeTest = () => {
    setRetakeOpen(false);
    setOpenPopup(false);
    getAllResult();
    setRetakeButtonLabel("Resume");
  };

  return (
    <div>
      <div className="header-layouts"><DesktopHeader title={"Aptitude"}   isSearchActive={false}/></div>
      <div className="aptitude-candidate">
        <div className="content-wrapper">
          <div className="header-one">
            <div>
              <label>Type : </label>
              <span> Graded Aptitude</span>
            </div>
            <div>
              <label>Attempts : </label>
              <span> {attempt}/3</span>
            </div>
            <div>
              <label>Questions : </label>
              <span> 20</span>
            </div>
          </div>

          <div className="header-one header-three">
            <div>
              <label>Your Marks : </label>

              <span> {highestMark}/20</span>
              {highestMark > 0 && (
                <span className={highestMark >= 12 ? "passed" : "failed"}>
                  {highestMark >= 12 ? "passed" : "failed"}
                </span>
              )}
            </div>
            <div>
              <label>Passing Score : </label>
              <span> 12/20</span>
            </div>
          </div>
          <div className="accordion" style={{ marginTop: "20px" }}>
            <div className="accordion-item">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="AboutPopup-head"
                >
                  Instructions{" "}
                </AccordionSummary>

                <AccordionDetails>
                  <div className="instruction-one">Dear Participant,</div>
                  <div className="instruction-one">
                    The Aptitude Test has 20 multiple-choice questions. Each
                    question carries 1 mark.
                  </div>
                  <div className="instruction-one">
                    There is no time limit for completing the test.
                  </div>
                  <div className="instruction-one">
                    <b>Please Note:</b>
                    You need to get at least 12 out of 20 questions right to
                    pass this assessment.
                  </div>
                  <div className="instruction-one">
                    Please go through these guidelines before you take the
                    Aptitude Test:
                  </div>
                  <div className="instruction-one">
                    <ol>
                      <li className="instruction-list">
                        1. Only attempt the Aptitude Test when you are prepared
                        and have enough time to finish all the questions.
                      </li>
                      <li className="instruction-list">
                        2. You have unlimited attempts to finish the test.
                      </li>
                      <li className="instruction-list">
                        3. Once the test is opened, you must complete it. You
                        CANNOT start the test, leave it for an extended period
                        of time, and then come back later to finish.
                      </li>
                      <li className="instruction-list">
                        4. Ensure there is proper internet connection while
                        taking up the test. Any break in the connection will
                        automatically submit your test.
                      </li>
                      <li className="instruction-list">
                        5. For any technical issues faced on Olympus, please
                        share a screenshot with us at{" "}
                        <a
                          href="support@thexakal.com"
                          style={{ color: "#362a41" }}
                        >
                          support@thexakal.com
                        </a>{" "}
                        so that our team can understand and solve it on
                        priority.
                      </li>
                    </ol>
                  </div>
                  <div className="instruction-one">Regards,</div>
                  <div className="instruction-one">Xakal</div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          {attempt === 0 ? (
            <div className="button-apt">
              <button
                className="button3"
                onClick={() =>
                  history.push({
                    pathname: `/portal/home`,
                    state: {
                      candidate: true,
                      candidateId: randomId,
                    },
                  })
                }
              >
                Start Test
              </button>
            </div>
          ) : attempt < 3 ? (
            <div className="button-apt">
              <button
                className="button3"
                onClick={() =>
                  history.push({
                    pathname: `/portal/home`,
                    state: {
                      candidate: true,
                      candidateId: randomId,
                    },
                  })
                }
              >
                {retakeButtonLabel}
              </button>
            </div>
          ) : (
            ""
          )}

          <div className="attempt-history">
            <span>Attempts History</span>
          </div>
          {result.map((a, index) => {
            return (
              <div className="accordion" style={{ marginTop: "20px" }}>
                <div className="accordion-item">
                  <Accordion style={{ height: "100px" }}>
                    <AccordionSummary
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="AboutPopup-head"
                    >
                      <div>Attempt {a?.attempt} </div>
                      <div className="time-limit">
                        <div className="main-limit">
                          <span>{formatDate(a.timestamps.createdAt)}</span>
                          <span>Marks : {a?.marks}</span>
                        </div>
                      </div>
                    </AccordionSummary>

                    <AccordionDetails></AccordionDetails>
                  </Accordion>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Dialog open={openPopup}>
        <div className="modalHeader">
          <div className="mTitle"></div>
          <div onClick={() => setOpenPopup(false)}>
            <img src={CloseIcon} alt="closeIcon" />
          </div>
        </div>
        <div className="modalBody">
          <div className="apt-tick">
            <img
              src={tick}
              alt="tick-images"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
          <div className="mTitle apt-tick">Aptitude Submitted Successfully</div>
          <div className="Edu-Popup-wrapper apt-mark-wrap">
            <div className="mTitle">
              <div className="apt-mark">Your Marks</div>
              <div className="sub-apt">{marks}</div>
            </div>
            <div className="mTitle">
              <div className="apt-mark">Kept Score</div>
              <div className="sub-apt">12</div>
            </div>
          </div>
        </div>
        <div className="modalFooter">
          <button
            className="button5 botton7"
            onClick={() => setOpenPopup(false)}
          >
            Continue
          </button>
          <button className="button3" onClick={handleRetake}>
            Retake
          </button>
        </div>
      </Dialog>
      <Dialog open={retakeOpen}>
        <div className="modalHeader">
          <div className="sub-apt">Confirmation</div>
        </div>
        <div className="modalBody">
          <div>Put on your thinking caps! The aptitude is about to start</div>
        </div>
        <div className="modalFooter modalfooter">
          <button className="button5" onClick={closeTest}>
            Later
          </button>
          <button
            className="button3"
            onClick={() => {
              handleRetest();
              history.push({
                pathname: `/portal/home`,
                state: {
                  candidate: true,
                  candidateId: randomId,
                },
              });
            }}
          >
            Start Now
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default AptitudeCandidate;
