import { FaPhotoVideo } from "react-icons/fa";
import "./textMedia.css";
import Form from "react-bootstrap/Form";

import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";

const EventFormMedia = ({
  handleToggle,
  eventTitle,
  setEventTitle,
  eventPhoto,
  fileInputEventCoverRef,
  handleDivEventCoverClick,
  handleInputEventChange,
  eventDesc,
  setEventDesc,
  removeEventImage,
  handleDivEventPhotoClick,
  fileInputEventPhotoRef,
  handleEventImageChange,
  eventPhotos,
  handleDivEventCoverVideoClick,
  fileInputEventCoverVideoRef,
  handleInputEventCoverChange,
  coverVideo,
  optionFormat,
  handleFormatChange,
  eventFormat,
  eventLink,
  setEventLink,
  selectedTimezone,
  setSelectedTimezone,
  timezones,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  userArrays,setUserSelect
}) => {
  return (
    <div>
      <div className="textContent">
        <input
          type="text"
          placeholder="What's on your mind?"
          className="inputField"
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
        />
      </div>
      <div className="character">{eventTitle?.length}/100 Characters</div>

      <span>Thumbnail</span>
      <div className="optionsContainer">
        <div
          className="option"
          onClick={handleDivEventCoverClick}
          style={{
            cursor: "pointer",
            padding: "10px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
          }}
        >
          <FaPhotoVideo size={20} />
          Upload
        </div>
        <input
          type="file"
          accept="image/*"
          multiple
          ref={fileInputEventCoverRef}
          style={{ display: "none" }} // Hide the input
          onChange={handleInputEventChange}
        />
      </div>

      {eventPhoto && (
        <img
          src={URL.createObjectURL(eventPhoto)} // Create URL for the selected image
          alt="Selected Images"
          style={{
            maxWidth: "150px",
            maxHeight: "150px",
            borderRadius: "4px",
          }}
        />
      )}
      <div className="optionsContainer">
        Photo
        <div
          className="option"
          onClick={handleDivEventPhotoClick}
          style={{
            cursor: "pointer",
            padding: "10px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
          }}
        >
          <FaPhotoVideo size={20} />
          Photo +
        </div>
        <input
          type="file"
          accept="image/*"
          multiple
          ref={fileInputEventPhotoRef}
          style={{ display: "none" }} // Hide the input
          onChange={handleEventImageChange}
        />
      </div>
      {eventPhotos?.length > 0 && (
        <div>
          <h2>Preview:</h2>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {eventPhotos?.map((image, index) => (
              <div key={index} style={{ position: "relative", margin: "5px" }}>
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Preview ${index}`}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "150px",
                    borderRadius: "4px",
                  }}
                />
                <button
                  onClick={() => removeEventImage(index)}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    background: "rgba(255, 255, 255, 0.7)",
                    border: "none",
                    borderRadius: "50%",
                    cursor: "pointer",
                    padding: "2px",
                  }}
                >
                  ✖️
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <span>Cover Video</span>
      <div className="optionsContainer">
        <div
          className="option"
          onClick={handleDivEventCoverVideoClick}
          style={{
            cursor: "pointer",
            padding: "10px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
          }}
        >
          <FaPhotoVideo size={20} />
          Upload
        </div>
        <input
          type="file"
          accept="video/*"
          multiple
          ref={fileInputEventCoverVideoRef}
          style={{ display: "none" }} // Hide the input
          onChange={handleInputEventCoverChange}
        />
      </div>

      {coverVideo && (
        <video
          src={URL.createObjectURL(coverVideo)} // Create URL for the selected image
          alt="Selected Images"
          controls
          style={{
            maxWidth: "150px",
            maxHeight: "150px",
            borderRadius: "4px",
          }}
        />
      )}
      <div className="optionsContainerss">
        Event Format
        <Select
          options={optionFormat}
          onChange={handleFormatChange}
          value={eventFormat}
        />
      </div>
     
      <div className="textContent">
      Event Link
        <input
          type="text"
          placeholder="What's on your mind?"
          className="inputField"
          value={eventLink}
          onChange={(e) => setEventLink(e.target.value)}
        />
      </div>
      <label>Time Zone</label>

      <div className="scheduleOptions" style={{ marginLeft: "8rem" }}>

        <div className="scheduleTime">
          <select
            value={selectedTimezone}
            onChange={(e) => setSelectedTimezone(e.target.value)}
            className="timezoneDropdown"
          >
            {timezones.map((tz) => (
              <option key={tz.name} value={tz.name}>
                {tz.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div
        className="myprofile-content-head input-space"
        style={{ marginLeft: "0px" }}
      >
        <div className="pro-fr">
          <label className="label-name">
            From<span>*</span>
          </label>
          <InputGroup className="footer-header">
            <Form.Control
              type="date"
              placeholder="YYYY"
              aria-label="YYYY"
              name="startDate"
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
              aria-describedby="basic-addon1"
              style={{
                width: "50%",
                fontSize: "12px",
              }}
              className="footer-header"
            />
          </InputGroup>
        </div>

        <div className="pro-fr">
          <label className="label-name">To</label>
          <InputGroup className="mb-1">
            <Form.Control
              type="date"
              placeholder="YYYY"
              aria-label="YYYY"
              name="endDate"
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate}
              aria-describedby="basic-addon1"
              className="footer-header"
            />
          </InputGroup>
        </div>
      </div>
      <div className="textContent">
        Description
        <textarea
          value={eventDesc}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setEventDesc(e.target.value)}
          rows={3}
        />
        <div className="character">{eventDesc?.length}/3000 Characters</div>
      </div>
      <Select
                options={userArrays}
                isMulti
                isClearable
                onChange={(selectedOptions) => setUserSelect(selectedOptions)}
                getOptionLabel={(e) => e.name}  // Ensure the label is displayed
                getOptionValue={(e) => e.userId}
              />
      <div className="buttonDiv">
        <button className="button1" onClick={handleToggle}>
          Next
        </button>
      </div>
    </div>
  );
};

export default EventFormMedia;
