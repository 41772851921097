import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "react-select";
import "../Jobs/buttonStyle.css";
import PhoneInput from "react-phone-input-2";
import { Col, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Country, State, City } from "country-state-city";
import "react-toastify/dist/ReactToastify.css";
import DesktopHeader from "../desktopHeader";
import Delete from "../../images/delete.svg";
import Plus from "../../images/Add Circle.svg";

const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    minHeight: "48px",
    height: "fit-content",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    color: "#40444F",
    fontweight: "normal",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const MainLayout = styled.div`
  margin: 30px 50px 30px 100px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;

const PageContainer = styled.div`
  padding: 20px 30px 30px 30px;
`;
const HeaderText = styled.h2`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin: 10px 0 20px 0;

  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 24px;
  max-width: 840px;
  margin: auto;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
  margin: 10px 0 10px 0;
  span {
    color: #ff4873;
    font-size: 16px;
    font-weight: 800;
    margin-left: 5px;
  }
`;
const OTPContent = styled.div`
  font-size: 14px;
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  margin: 15px 0 10px 0;
`;
// const DateWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-top: 50px;
// `;
const DateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  // box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 12px;
  button {
    width: 85px;
  }
`;

const options5 = [
  { value: "Remote", label: "Remote" },
  { value: "Hybrid", label: "Hybrid" },
  { value: "Onsite", label: "Onsite" },
];

const AboutContact = ({ match }) => {
  const history = useHistory();
  const [btnenable, setBtnEnable] = useState(false);
  const [otpsecenable, setOtpSecEnable] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [input, setInput] = useState({
    company: "",
    xakalAddress: "",
    website: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({
    company: "",
    xakalAddress: "",
    website: "",
  });

  const [companyId, setCompanyId] = useState(null);

  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the name of the company!";
          }
          console.log(input.jobTitle);
          break;

        default:
          break;
      }
      return stateObj;
    });
    // handleInputBlur()
  };

  const [data, setData] = useState("");
  const [otp, setOtp] = useState(null);
  const [error2, setError2] = useState();
  const [inputList, setInputList] = useState([
    {
      addressOne: "",
      addressTwo: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
      setTrueLocation: false,
    },
  ]);
  const [phone, setPhone] = useState("");

  const handlePhone = (e) => {
    const inputPhone = e.target.value.replace(/\D/g, "");

    if (inputPhone.length < 12) {
      console.log("phonelength:", inputPhone.length);
      setPhone(inputPhone);
      setBtnEnable(false);
      setError2("Please enter valid mobile number.");
    } else {
      setError2("");
      setBtnEnable(true);
    }
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhone(value);
    setBtnEnable(true);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    // Update the country, state, and city within the inputList element
    if (name === "country") list[index]["country"] = value;
    else if (name === "state") list[index]["state"] = value;
    else if (name === "city") list[index]["city"] = value;
    setInputList(list);
    console.log(inputList);
  };
  const setPrimaryLocation = (e, index) => {
    const list = [...inputList];
    list[index]["setTrueLocation"] = !list[index]["setTrueLocation"];
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        addressOne: "",
        addressTwo: "",
        state: "",
        city: "",
        country: "",
        pincode: "",
        setTrueLocation: false,
      },
    ]);
  };
  const [skill, setSkill] = useState([]);
  const handleSkillChnage = (e) => {
    setSkill(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const [contactNumberVerified, setContactNumberVerified] = useState(false);
  const getCompanyDetails = async () => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/company/details/` + match.params.id)
      
        console.log("company details by id", response.data);
        if (response.data) {
          setData(response.data.data);
          if (response.data.data && response.data.data.contactNumber)
            setPhone(response.data.data.contactNumber);
        }
        setCompanyId(response.data.data.companyId);
        setContactNumberVerified(
          response.data.data.contactNumberVerified === true
        );
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };

  useEffect(() => {
    getCompanyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("phone", phone);

  const [resendTimer, setResendTimer] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isSendOtpClicked, setIsSendOtpClicked] = useState(false);
  const startResendTimer = () => {
    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      setIsResendDisabled(false);
      setResendTimer(0);
      clearInterval(timerInterval);
    }, 60000);
  };
  const formatTimer = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const sendMobileOtp = async () => {
    setIsResendDisabled(true);
    setResendTimer(60);
    setOtpSecEnable(true);
    setIsSendOtpClicked(true);
    const payload = {
      mobile: phone,
      website: data["website"],
    };
    try{
      const response = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/company/mobile/sendOtp`, payload)
        console.log(response);
        toast.success("OTP sent to mobile", {
          autoClose: 5000,
          position: "top-right",
        });
        startResendTimer();
      }
      catch(err) {
        toast.error("Failed to send OTP", {
          autoClose: 5000,
          position: "top-right",
        });
      };
  };
  const handleResendOtp = async () => {
    setIsResendDisabled(true);
    setResendTimer(60);
    setIsSendOtpClicked(true);
    const payload = {
      mobile: phone,
      website: data["website"],
    };
    try{
      const response = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/company/mobile/resendOtp`, payload)
        console.log(response);
        toast.success("OTP sent to mobile", {
          autoClose: 5000,
          position: "top-right",
        });
        startResendTimer();
      }
      catch(err) {
        toast.error("Failed to send OTP", {
          autoClose: 5000,
          position: "top-right",
        });
      };
  };

  const verifyMobileOtp = async () => {
    const payload = {
      mobile: phone,
      website: data["website"],
      otp: otps.join(""),
    };
    try{
      const response = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/company/mobile/verify`, payload)
      
        console.log(response);
        if (!response.data.success) {
          toast.error(response.data.error, {
            autoClose: 5000,
            position: "top-right",
          });
        } else {
          toast.success("Mobile verified", {
            autoClose: 5000,
            position: "top-right",
          });
          setOtp(null);
          setIsSendOtpClicked(false);
          setIsResendDisabled(false);
          setResendTimer(0);
        }
        getCompanyDetails();
      }
      catch(err) {
        toast.error("Failed to verify", {
          autoClose: 5000,
          position: "top-right",
        });
      };
  };

  const handleSumit = async () => {
    const isValid = inputList.every((input) => {
      return input.country && input.state && input.city && input.pincode;
    });

    if (!isValid) {
      // If any input is missing, show an error message
      toast.error("Please enter all address fields for all locations");
      return;
    }

    const payload = {
      website: data["website"],
      primaryAddress: inputList.find((e) => e?.setTrueLocation),
      additionalAddress: inputList,
      workMode: skill,
      contactNumber: phone,
    };
    try{
      const response = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/update-company`, payload)
      
        console.log(response);
        toast.success("Company added successfully", {
          autoClose: 5000,
          position: "top-right",
        });
        history.push("/portal/view-company");
      }
      catch(err)  {
        toast.error("Error updating company", {
          autoClose: 5000,
          position: "top-right",
        });
        console.log(err.error);
      };
  };
  const [otps, setOtps] = useState(["", "", "", ""]);
  const inputRefs = useRef(otps.map(() => React.createRef()));

  const handleChanges = (event, index) => {
    const updatedOtp = [...otps];

    // Handle backspace
    if (event.target.value === "" && index > 0) {
      // Clear the current digit only when backspacing
      updatedOtp[index] = "";
      setOtps(updatedOtp);

      // Focus on the previous input field
      inputRefs.current[index - 1].current.focus();
      return;
    }

    // Update the current digit
    updatedOtp[index] = event.target.value.slice(0, 1);

    // Update the OTP state
    setOtps(updatedOtp);

    // Focus on the next input field if available
    if (index < otps.length - 1 && event.target.value !== "") {
      inputRefs.current[index + 1].current.focus();
    }
  };
  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4); // Get the pasted content
    const updatedOtp = [...otp];

    // Insert the pasted content into the OTP input fields
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otps.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }

    setOtps(updatedOtp);

    // Focus on the next input field if available
    if (index + pastedData.length < otps.length) {
      inputRefs.current[index + pastedData.length].current.focus();
    }
  };

  const BackToCompany = () => {
    history.push(`/portal/company-about/${companyId}`);
  };
  const searchedKey = () => {};
  return (
    <div>
      <div className="header-layouts">
      <DesktopHeader
        title={"Company"}
        searchedKey={searchedKey}
        back={BackToCompany}
        backbtn
      />
      </div>
      {/* <div style={{ marginLeft: "240px", display: "flex", gap: "0px", background: "#FFF" }}>
        <div className="bread-crumb" onClick={() => history.push(`/portal/company-about/${companyId}`)}>About your company <span>&#129170;</span>
          OTP Verification  <span>&#129170;</span>   
        </div>
        <div className="bread-crumb" style={{ color: "#362A41", margin: "14px 0 0 0px" }}>Company Contact</div>

      </div> */}
      <MainLayout>
        <PageWrapper>
          <PageContainer>
            <HeaderText>{"Contact Company"}</HeaderText>
            <hr />
            <ToastContainer />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                  <div style={{ width: "50%" }}>
                    <Label>Mobile Number</Label>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        borderRadius: "12px",
                      }}
                    >
                      <div style={{}}>
                        <PhoneInput
                          onChange={handlePhoneChange}
                          name="phone"
                          className="font"
                          onBlur={handlePhone}
                          disabled={data["contactNumberVerified"]}
                          value={phone}
                          country={"in"}
                          containerStyle={{
                            background: "rgba(54, 42, 65, 0.04)",
                            height: "48px",
                            borderRadius: "12px",
                            fontSize: "12px",
                            // width:"50%"
                          }}
                          inputStyle={{
                            background: "rgba(54, 42, 65, 0.04)",
                            height: "48px",
                            borderRadius: "12px",
                            fontSize: "12px",
                            width: "380px",
                          }}
                        />
                      </div>
                    </div>

                    {/* {isValid ? <p>Phone number is valid</p> : <p className="text-danger">Phone number is invalid</p>} */}
                    <p className="f12">{error2}</p>
                  </div>

                  {!data["contactNumberVerified"] ? (
                    !otpsecenable ? (
                      <button
                        className={btnenable ? "button2" : "button6"}
                        style={{
                          width: "143px",
                          height: "48px",
                          marginTop: "35px",
                        }}
                        disabled={!btnenable}
                        onClick={() => sendMobileOtp()}
                      >
                        Get OTP
                      </button>
                    ) : null
                  ) : null}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "50%",
                    }}
                  >
                    {otpsecenable ? (
                      <div>
                        {!data["contactNumberVerified"] ? (
                          <>
                            <OTPContent>
                              Enter the OTP sent to {phone}
                            </OTPContent>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "8px",
                              }}
                            >
                              <div
                                className="otp-group"
                                style={{ margin: "0px 20px 20px 0px" }}
                              >
                                {otps.map((digit, idx) => (
                                  <input
                                    key={idx}
                                    type="text"
                                    inputMode="numeric"
                                    autoComplete="one-time-code"
                                    pattern="\d"
                                    className="otp-input"
                                    value={digit}
                                    id={`otp-input-${idx}`}
                                    ref={inputRefs.current[idx]}
                                    onChange={(e) => handleChanges(e, idx)}
                                    onPaste={(e) => handlePaste(e, idx)}
                                  />
                                ))}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <button
                                  // className="button3"
                                  //   disabled={!otp}
                                  style={{
                                    color: "#8652B6",
                                    fontFamily: "Inter-Medium,sans-serif",
                                    fontSize: "14px",
                                    fontweight: "normal",
                                    marginTop: "-15px",
                                  }}
                                  onClick={() => verifyMobileOtp()}
                                >
                                  Verify
                                </button>
                              </div>
                            </div>
                            {/* 
                            <input
                              type="number"
                              name="otp"
                              //   value={otp}
                              style={{
                                borderRadius: "5px",
                                border: "none",
                                background: "rgba(54, 42, 65, 0.04)",
                                width: "90%",
                                height: "40px",
                                padding: "20px",
                                fontSize:"12px"
                              }}
                              onChange={(e) => setOtp(e.target.value)}
                              placeholder="Enter OTP sent to your mobile number"
                            />
                            
                          </div> */}
                          </>
                        ) : (
                          <div />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {isSendOtpClicked && (
                      <span
                        style={{
                          marginTop: "0px",
                          color: "#4F4953",
                          fontFamily: "Inter-Medium,sans-serif",
                          fontSize: "14px",
                          fontweight: "normal",
                        }}
                      >
                        {resendTimer > 0
                          ? `Time Remaining ${formatTimer(resendTimer)}`
                          : !isResendDisabled && (
                              <span
                                onClick={handleResendOtp}
                                style={{
                                  color: "#8652B6",
                                  fontFamily: "Inter-Medium,sans-serif",
                                  fontSize: "14px",
                                  fontweight: "normal",
                                  cursor: "pointer",
                                }}
                              >
                                Resend OTP
                              </span>
                            )}
                      </span>
                    )}
                  </div>
                </div>
                <div></div>
                <div
                  style={{
                    color: "rgba(54, 164, 124, 1)",
                    fontFamily: "Inter-Medium,sans-serif",
                    fontSize: "14px",
                    fontweight: "normal",
                  }}
                >
                  {contactNumberVerified && <span>Mobile no verified</span>}
                </div>
              </div>
            </div>
            {/* <div style={{ width: "50%" }}>
            <Label>Contact Mail Id</Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Job Title"
                name="xakalAddress"
                aria-describedby="basic-addon1"
                style={{
                  borderRadius: "5px",
                  background: "rgba(54, 42, 65, 0.04)",
                }}
                defaultValue={data["contactEmailId"]}
              />
            </InputGroup>
          </div> */}
            {/* <Label>Location</Label> */}
            {inputList.map((input, i) => {
              return (
                <>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div style={{ width: "50%" }}>
                      <Label>Address Line 1</Label>

                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Enter address Line 1"
                          aria-label="Job Title"
                          name="addressOne"
                          className="font"
                          aria-describedby="basic-addon1"
                          style={{
                            height: "48px",
                            borderRadius: "12px",
                            background: "rgba(54, 42, 65, 0.04)",
                          }}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </InputGroup>
                    </div>
                    <div style={{ width: "50%" }}>
                      <Label>Address Line 2</Label>

                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Enter address Line 2"
                          aria-label="Job Title"
                          name="addressTwo"
                          className="font"
                          aria-describedby="basic-addon1"
                          style={{
                            height: "48px",
                            borderRadius: "12px",
                            background: "rgba(54, 42, 65, 0.04)",
                          }}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Label>
                          Country<span className="text-danger">*</span>
                        </Label>

                        <div>
                          <select
                            required
                            value={input.country}
                            onChange={(e) => handleInputChange(e, i)}
                            name="country"
                            className="footer-header font mb-3"
                            styles={customStyle}
                            menuPlacement="top"
                            // padding: "0px 12px", border: "none", height: "48px",
                            // borderRadius: "12px",

                            // onBlur={validateInput}
                          >
                            <option value="">Country</option>
                            {Country &&
                              Country.getAllCountries().map((item) => (
                                <option key={item.name} value={item.isoCode}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Label>
                          State / Provision
                          <span className="text-danger">*</span>
                        </Label>

                        <select
                          required
                          value={input.state}
                          onChange={(e) => handleInputChange(e, i)}
                          name="state"
                          className="footer-header font mb-3"
                          style={{
                            padding: "12px",
                            border: "none",
                            height: "48px",
                            borderRadius: "12px",
                          }}
                          // onBlur={validateInput}
                        >
                          <option value="">State</option>
                          {State &&
                            input.country &&
                            State.getStatesOfCountry(input.country).map(
                              (item) => (
                                <option key={item.name} value={item.isoCode}>
                                  {item.name}
                                </option>
                              )
                            )}
                        </select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Label>
                          City / Town<span className="f12">*</span>
                        </Label>

                        <select
                          required
                          value={input.city}
                          onChange={(e) => handleInputChange(e, i)}
                          name="city"
                          className="footer-header font mb-3"
                          style={{
                            padding: "0px 12px",
                            border: "none",
                            height: "48px",
                            borderRadius: "12px",
                          }}
                          onBlur={validateInput}
                        >
                          <option value="">City</option>
                          {City &&
                            input.country &&
                            input.state &&
                            City.getCitiesOfState(
                              input.country,
                              input.state
                            ).map((item) => (
                              <option value={item.name}>{item.name}</option>
                            ))}
                        </select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Label>
                          Zip / Pincode<span className="text-danger">*</span>
                        </Label>
                        <Form.Control
                          placeholder="Enter Zip / Pincode"
                          aria-label="Job Title"
                          name="pincode"
                          className="font"
                          aria-describedby="basic-addon1"
                          style={{
                            height: "48px",
                            borderRadius: "12px",
                            background: "rgba(54, 42, 65, 0.04)",
                          }}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {inputList.length - 1 === i && (
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            // top: "23%",
                            border: "1px solid rgba(221, 223, 228, 0.5)",
                            borderRadius: "8px",
                            padding: "5px 20px",
                            height: "40px",
                          }}
                          onClick={handleAddClick}
                        >
                          {/* <AiOutlinePlusCircle size={25} /> */}
                          <img
                            src={Plus}
                            alt="img"
                            style={{ width: "24px", height: "24px" }}
                          />
                          <p
                            style={{
                              
                              color: "#4F4953",
                              fontSize: "14px",
                              fontFamily: "Inter-Medium,sans-serif",
                              fontweight: "normal",
                            }}
                          >
                            Add Address
                          </p>
                        </div>
                        {inputList.length !== 1 && (
                          <img
                            src={Delete}
                            alt="img"
                            style={{ width: "43px", height: "43px" }}
                            onClick={() => handleRemoveClick(i)}
                          ></img>
                        )}
                      </Col>
                    )}

                    <Col>
                      <div className="FormControlLabel"
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "end",
                          gap: "10px",
                          

                        }}
                      >
                        <FormControlLabel
                          label="Set this as an primary location"
                          control={
                            <Checkbox
                              inputProps={{ "aria-label": "controlled" }}
                              onChange={(e) => setPrimaryLocation(e, i)}
                            />
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              );
            })}
            <div style={{ display: "flex", gap: "20px" }}>
              <div style={{ width: "50%" }}>
                <Label>Work Mode</Label>
                <div>
                  <Select
                    styles={customStyle}
                    onChange={handleSkillChnage}
                    name="skill"
                    value={options5.filter((obj) => skill.includes(obj.value))}
                    options={options5}
                    isMulti={true}
                    className=" "
                    onBlur={validateInput}
                    menuPlacement="top"
                  />
                  {/* {skill.length > 0 && (
                  <PreferenceWrapper>
                    {skill.map((i, a) => {
                      return (
                        <div key={a}>
                          <JobPreference>
                            {i}
                            <button onClick={() => handleRemoveSkill(i)}>x</button>
                          </JobPreference>
                        </div>
                      );
                    })}
                  </PreferenceWrapper>
                )} */}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <Label>Contact Mail Id</Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Job Title"
                    name="xakalAddress"
                    className="font"
                    aria-describedby="basic-addon1"
                    style={{
                      height: "48px",
                      borderRadius: "12px",
                      background: "rgba(54, 42, 65, 0.04)",
                    }}
                    defaultValue={data["contactEmailId"]}
                  />
                </InputGroup>
              </div>
            </div>
            {/* <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ width: "100%" }}>
                <Label>Tag line</Label>
                <InputGroup className="">
                  <Form.Control
                    as="textarea"
                    placeholder="Type here"
                    aria-label="Job Title"
                    name="tagline"
                    className="font"
                    onBlur={validateInput}
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    value={input.tagline}
                    maxLength={250}
                    style={{
                      borderRadius: "12px",
                      background: "rgba(54, 42, 65, 0.04)",
                      color:"#40444F",
                      
                    }}
                  />
                </InputGroup>
                <div
                  style={{
                    position: "relative",
                    bottom:"20px",
                    right:"10px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <p style={{color:"rgba(198, 202, 210, 1)",fontSize:"10px",fontweight: "normal",fontFamily:"Inter-Medium,sans-serif"}}>
                    {input.tagline == undefined ? "0" : input.tagline.length}/250
                    Characters
                  </p>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <Label>Company Description</Label>
                <InputGroup className="">
                  <Form.Control
                    as="textarea"
                    placeholder="Type here"
                    aria-label="Job Title"
                    name="companyDescription"
                    className="font"
                    onBlur={validateInput}
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    value={input.description}
                    style={{
                      borderRadius: "12px",
                      background: "rgba(54, 42, 65, 0.04)",
                      color:"#40444F",
                      
                    }}
                  />
                </InputGroup>
                <div
                  style={{
                    position: "relative",
                    bottom:"20px",
                    right:"10px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <p style={{color:"rgba(198, 202, 210, 1)",fontSize:"10px",fontweight: "normal",fontFamily:"Inter-Medium,sans-serif"}}>
                    {input.companyDescription == undefined
                      ? "0"
                      : input.companyDescription.length}
                    /1000 Characters
                  </p>
                </div>
              </div>
            </div> */}
          </PageContainer>
          <DateWrapper>
            <button className="button2" onClick={handleSumit}>
              Save
            </button>
          </DateWrapper>
        </PageWrapper>
      </MainLayout>
    </div>
  );
};

export default AboutContact;
