import React, { useEffect, useState } from "react";
import "./interviewNotification.css";
import axios from "axios";
import Common from "../../utils/common";
import imageAvatar from "../../images/company.png";
import { InputGroup, Form } from "react-bootstrap";
import Select from "react-select";
import { Dialog, DialogContent } from "@mui/material";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CloseIcon from "../../images/CloseIcon.svg";
import styled from "styled-components";

const customStyle = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium, sans-serif",
    color: "#4F4953",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.isSelected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41",
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
};
const InvitationCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  height: 120px;
  display: flex;
  gap: 24px;
  margin-bottom: 16px; /* Adjust the value as needed */
`;

const Img = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: auto 0;
`;

const Title = styled.div`
  width: auto;
  height: 22px;
  top: 24px;
  left: 111px;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8653b6;
`;

const DetailsWrapper = styled.div`
  padding: 8px 0;
`;

const InvitationCards = styled.div`
  display: flex;
  gap: 24px;
  margin: 15px auto;
`;
const AllNotification = () => {
  const history = useHistory();
  const [requestData, setRequestData] = useState([]);
  const [declineId, setIdDecline] = useState();
  const [declineOpen, setDeclineOpen] = useState(false);
  const [declineDescription, setDeclineDescription] = useState("");
  const [reason, setReason] = useState("");
  const [data, setData] = useState();
  const [valueOne, setValueOne] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [request, setRequests] = useState([]);

  const getMyInvitations = async () => {
    await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      )
      .then((res) => {
        const dt = res?.data?.filter((e) => e.connectionStatus === "PENDING");
        setInvitations(dt);
      });
  };

  const updateInvitation = async (status, id) => {
    return await axios
      .post(`${process.env.PUBLIC_URL}/xakal/update/invitation`, {
        invitedUserId: id,
        associateId: Common.loggedIn(),
        connectionStatus: status,
      })
      .then((res) => {
        getMyInvitations();
      });
  };
  useEffect(() => {
    getMyInvitations();
  }, []);
  //job notification//
  const [dataJob, setDataJob] = useState();
  const [datas, setDatas] = useState();
  const getAllJob = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-manage-jobs`
      );

      if (response.data) {
        const newJobs = response.data.job;

        const transformedData = newJobs.flatMap((job) => {
          // Filter applicants based on the condition
          const filteredApplicants = job.applicants.filter(
            (applicant) => applicant.statusOne === "Applied"
          );

          return filteredApplicants.map((applicant) => ({
            ...job,
            applicant, // Add the single applicant details
            applicants: undefined, // Remove the original applicants array
          }));
        });

        // Set the transformed data
        setDataJob(transformedData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllJob();
  }, []);

  const getAllJobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/job/applied`
      );

      if (response.data) {
        const transformedData = response.data.flatMap((job) => {
          // Filter applicants based on the condition
          const filteredApplicants = job.applicants.filter((applicant) =>
            ["reject", "offered", "shortlist"].includes(applicant.statusOne)
          );

          return filteredApplicants.map((applicant) => ({
            ...job,
            applicant, // Add the single applicant details
            applicants: undefined, // Remove the original applicants array
          }));
        });

        // Set the transformed data
        setDatas(transformedData);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getAllJobs();
  }, []);

  const getMyRequests = async () => {
    await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      )
      .then((res) => {
        console.log("my invitations", res?.data);
        const dt = res?.data?.filter((e) => e.connectionStatus === "ASSOCIATE");
        setRequests(dt);
      });
  };
  useEffect(() => {
    getMyRequests();
  }, []);
  const openDecline = (id) => {
    setDeclineOpen(true);
    setIdDecline(id);
  };
  const getRequest = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-request`
      );

      // Collect all confirmed candidates
      const allConfirmedCandidates = response.data.interview.flatMap(
        (interview) =>
          interview.candidateDetail.filter(
            (candidate) =>
              candidate.bookingService?.status === "confirmed" &&
              candidate.bookingService?.paid === "paid"
          )
      );

      // Use a Map to ensure unique candidates
      const candidateMap = new Map();
      allConfirmedCandidates.forEach((candidate) => {
        const bookingId = candidate.bookingService._id.toString();
        // Ensure that only one entry per bookingId is included
        if (!candidateMap.has(bookingId)) {
          candidateMap.set(bookingId, candidate);
        }
      });

      // Convert the Map values back to an array
      const uniqueConfirmedCandidates = Array.from(candidateMap.values());

      console.log(uniqueConfirmedCandidates.length, "console.log");

      const calendarResponse = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/events/${Common.loggedIn()}`
      );

      const activeCalendarEvents = calendarResponse.data.filter(
        (event) => event.status === "active"
      );

      const calendarEventMap = activeCalendarEvents.reduce((map, event) => {
        if (!map[event.bookingId]) {
          map[event.bookingId] = [];
        }
        map[event.bookingId].push(event);
        return map;
      }, {});

      const enrichedCandidates = uniqueConfirmedCandidates.map((candidate) => {
        const bookingId = candidate.bookingService._id.toString();
        return {
          ...candidate,
          calendarEvents: calendarEventMap[bookingId] || [],
        };
      });

      setRequestData(enrichedCandidates);

      // eslint-disable-next-line no-unused-vars
      const dataCount = response.data.interview.map(
        (interview) => interview.candidateDetail.length
      );
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  useEffect(() => {
    getRequest();
  }, []);

  function isExpired(bookingDate, bookingTime) {
    const bookingDateTime = new Date(bookingDate);
    const [hours, minutes] = bookingTime.split(":");
    bookingDateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10));
    const currentDate = new Date();
    return currentDate >= bookingDateTime;
  }
  const formatDateWithSuffix = (isoDateString) => {
    // Function to add the appropriate suffix to the day
    const getDayWithSuffix = (day) => {
      const suffix =
        day % 10 === 1 && day !== 11
          ? "st"
          : day % 10 === 2 && day !== 12
            ? "nd"
            : day % 10 === 3 && day !== 13
              ? "rd"
              : "th";
      return `${day}${suffix}`;
    };

    const date = new Date(isoDateString);

    // Add one day to the date
    date.setUTCDate(date.getUTCDate() + 1);

    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    // Format the date using Intl.DateTimeFormat
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    const formattedDateParts = formatter.formatToParts(date);

    const dayWithSuffix = getDayWithSuffix(date.getUTCDate());
    const month = formattedDateParts.find(
      (part) => part.type === "month"
    ).value;
    const year = date.getUTCFullYear();

    return `${dayWithSuffix} ${month} ${year}`;
  };

  // eslint-disable-next-line no-unused-vars
  const currentAndFutureRequests = requestData.filter((item) => {
    const bookingDate = item.bookingService?.date;
    const bookingTime = item.bookingService?.time;
    return bookingDate && bookingTime && !isExpired(bookingDate, bookingTime);
  });
  const reasonOptions = [
    { value: "Not Acceptable", label: "Not Acceptable" },
    { value: "Not enough Skill", label: "Not enough Skill" },
  ];
  const handleReason = (selectedOptions) => {
    const selectedOption = selectedOptions.value;
    setReason(selectedOption);
  };
  const handleDecline = async (id) => {
    const payload = {
      declineDescription: declineDescription,
      declineReason: reason,
    };
    try {
      await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-update-decline/${id}`,
        payload
      );
      toast.success("Slot Declined Successfully");
      getRequest();
      await axios.post(`${process.env.PUBLIC_URL}/xakal/remove/event`, {
        bookingId: id,
      });
      setDeclineOpen(false);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  ///getAllInterviewBotification
  const [combinedData, setCombinedData] = useState();
  const getAllDecline = async () => {
    try {
      // Fetch the list of declined candidates
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-decline`
      );

      // Extract booking services
      const bookingServices = response.data.bookingServices;

      // Filter out candidate IDs
      const candidateIds = bookingServices
        .filter((service) => service.interviewId)
        .map((service) => service.interviewId);

      // Create requests to fetch details for each candidateId
      const detailRequests = candidateIds.map((id) =>
        axios.get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${id}`)
      );

      // Wait for all detail requests to complete
      const detailResponses = await Promise.all(detailRequests);
      // Create a map of candidateId to detail data
      const detailMap = detailResponses.reduce((acc, res) => {
        const detail = res.data.aboutOne;
        acc[detail.userId] = detail;
        return acc;
      }, {});

      // Combine booking services with the detailed information
      const combined = bookingServices.map((service) => {
        if (service.interviewId) {
          return {
            ...service,
            detail: detailMap[service.interviewId] || null, // Add details if available
          };
        }
        return service;
      });

      // Update the state with the combined data
      setCombinedData(combined);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    } finally {
      // Set loading to false once data fetching is complete
    }
  };

  const fetchCalendarEvents = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/events/${userId}`
      );
      const activeCalendarEvents = response.data.filter(
        (event) => event.status === "active"
      );

      // Create a map of bookingId to calendar events
      return activeCalendarEvents.reduce((map, event) => {
        if (!map[event.bookingId]) {
          map[event.bookingId] = [];
        }
        map[event.bookingId].push(event);
        return map;
      }, {});
    } catch (error) {
      console.error("Error fetching calendar events:", error);
      return {}; // Return an empty map if there is an error
    }
  };

  // Main function to get interview data with additional details
  const getAllInterviewStartedWithDetails = async () => {
    try {
      // Step 1: Fetch the interview data
      const interviewResponse = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-interview-started`
      );
      const interviews = interviewResponse.data.bookingServices;

      // Fetch the calendar events for the logged-in user
      const userId = Common.loggedIn(); // Adjust based on how you get the logged-in user's ID
      const calendarEventMap = await fetchCalendarEvents(userId);

      // Step 2: Fetch additional details for each interview
      const interviewsWithDetails = await Promise.all(
        interviews.map(async (interview) => {
          // Fetch about detail for the interviewStartedBy ID
          try {
            const aboutDetailResponse = await axios.get(
              `${process.env.PUBLIC_URL}/xakal/get-about-detail/${interview.interviewStartedBy}`
            );
            const aboutDetail = aboutDetailResponse.data.aboutOne;

            // Map calendar events to the bookingId of the interview
            const bookingId = interview._id.toString();
            const calendarEvents = calendarEventMap[bookingId] || [];

            return {
              ...interview,
              aboutDetail: aboutDetail || {}, // Handle cases where aboutDetail might be null
              calendarEvents, // Add calendar events to the interview data
            };
          } catch (error) {
            console.error(
              `Error fetching about detail for interview ID ${interview._id}:`,
              error
            );
            return {
              ...interview,
              aboutDetail: {}, // Return empty aboutDetail if there's an error
              calendarEvents: [], // Return empty calendar events if there's an error
            };
          }
        })
      );

      // Step 3: Set the combined data
      setData(interviewsWithDetails);
    } catch (error) {
      console.error("Error fetching interview data or details:", error);
      throw new Error(
        "An error occurred while fetching interview data or additional details."
      );
    }
  };

  useEffect(() => {
    getAllDecline();
    getAllInterviewStartedWithDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const parseCustomDate = (timestamp) => {
    if (!timestamp || typeof timestamp !== "string") {
      console.error("Invalid timestamp:", timestamp);
      return null; // Return null or a default date if timestamp is invalid
    }

    try {
      // Example format: 29/8/24, 1:08pm
      const [datePart, timePart] = timestamp.split(", ");

      // Ensure datePart and timePart are available
      if (!datePart || !timePart) {
        throw new Error("Timestamp does not contain date or time part");
      }

      const [day, month, year] = datePart.split("/").map(Number);
      const [time, period] = timePart.split(" ");

      if (!time || !period) {
        throw new Error("Timestamp does not contain time or period");
      }

      const [hour, minute] = time.split(":").map(Number);

      const adjustedHour =
        period === "pm" && hour < 12
          ? hour + 12
          : period === "am" && hour === 12
            ? 0
            : hour;

      return new Date(
        `${year + 2000}-${month}-${day}T${adjustedHour}:${minute}:00`
      );
    } catch (error) {
      console.error("Error parsing timestamp:", error);
      return null; // Return null or a default date if there's an error
    }
  };

  return (
    <>
      {requestData?.length > 0 ||
      combinedData?.length > 0 ||
      data?.length > 0 ||
      invitations?.length > 0 ||
      request?.length > 0 ||
      dataJob?.length > 0 ||
      datas?.length > 0 ? (
        <>
          {requestData
            ?.sort((a, b) => {
              const dateA = parseCustomDate(
                a.bookingService?.timestamps?.createdAt
              );
              const dateB = parseCustomDate(
                b.bookingService?.timestamps?.createdAt
              );
              if (!dateA) return 1;
              if (!dateB) return -1;
              return dateB - dateA; // Sort in descending order
            })
            .map((i) => {
              const expired = isExpired(
                i.bookingService?.date,
                i.bookingService?.time
              );

              return (
                <div className="">
                  <div className="notificationInterview">
                    <div className="interviewNotificationCard">
                      <img
                        src={
                          i.aboutDetail.avatar
                            ? i.aboutDetail.avatar
                            : imageAvatar
                        }
                        alt="imageAvatar"
                        className="notificationImage"
                      />
                      <div className="all-noti-cont">
                        {i.aboutDetail?.firstName} <span>Has booked a slot for{" "}</span>
                        {i.bookingService?.serviceName}{" "}
                        <span>on {formatDateWithSuffix(i.bookingService?.date)} at{" "}</span>
                        <span>{i.bookingService?.time}</span>
                      </div>
                      <div className="buttonGridInterview">
                        {expired ? (
                          ""
                        ) : (
                          <button
                            className={`buttonInterview 
                           "buttonDecline"
                        `}
                            onClick={() => {
                              if (!expired) {
                                openDecline(i.bookingService?._id);
                              }
                            }}
                          >
                            {"Decline"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {combinedData
            ?.sort((a, b) => {
              const dateA = new Date(a.timestamps?.createdAt);
              const dateB = new Date(b.timestamps?.createdAt);
              return dateB - dateA; // Sort in descending order
            })
            .map((i) => {
              return (
                <div className="">
                  <div className="notificationInterview">
                    <div className="interviewNotificationCard">
                      <img
                        src={i.detail.avatar ? i.detail.avatar : imageAvatar}
                        alt="imageAvatar"
                        className="notificationImage"
                      />
                      <div className="all-noti-cont">
                        {i.detail?.firstName} <span>Has cancelled the</span> {i?.serviceName}{" "}
                        <span>{formatDateWithSuffix(i?.date)} at {i?.time}</span>
                      </div>
                      <div className="buttonGridInterview">
                        <button
                          className="btn btn-new-int"
                          onClick={() =>
                            history.push("/portal/interview/exploreInterview")
                          }
                        >
                          {"Book a new interview"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {data
            ?.sort((a, b) => {
              const dateA = new Date(a.timestamps?.createdAt);
              const dateB = new Date(b.timestamps?.createdAt);
              return dateB - dateA; // Sort in descending order
            })
            .map((i) => {
              return (
                <div className="">
                  <div className="notificationInterview">
                    <div className="interviewNotificationCard">
                      <img
                        src={
                          i.aboutDetail.avatar
                            ? i.aboutDetail.avatar
                            : imageAvatar
                        }
                        alt="imageAvatar"
                        className="notificationImage"
                      />
                      <div className="all-noti-cont">
                        {i.aboutDetail?.firstName} <span>Join the</span> {i?.serviceName}{" "}
                        <span>Room</span>
                      </div>
                      <div className="buttonGridInterview">
                        <button
                          className="btn btn-cancel"
                          onClick={() => {
                            setValueOne(i);
                            setOpenConfirm(true);
                          }}
                        >
                          {"Join"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          {invitations?.map((invitation, idx) => (
            <InvitationCard key={idx}>
              <Img
                src={
                  invitation?.details?.avatar
                    ? invitation?.details?.avatar
                    : imageAvatar
                }
                alt=""
              />
              <DetailsWrapper>
                <Title>
                  {invitation?.details?.firstName +
                    " " +
                    invitation?.details?.lastName}
                </Title>
                <InvitationCards>
                  <button
                    className="button3"
                    onClick={() =>
                      updateInvitation("ASSOCIATE", invitation?.appUserId)
                    }
                  >
                    Accept
                  </button>
                  <button
                    className="button5"
                    onClick={() =>
                      updateInvitation("REJECTED", invitation?.appUserId)
                    }
                  >
                    Decline
                  </button>
                </InvitationCards>
                <p></p>
              </DetailsWrapper>
            </InvitationCard>
          ))}

          {request?.map((i) => {
            return (
              <div className="">
                <div className="notificationInterview">
                  <div className="interviewNotificationCard">
                    <img
                      src={i.details.avatar ? i.details.avatar : imageAvatar}
                      alt="imageAvatar"
                      className="notificationImage"
                    />
                    <div className="all-noti-cont">{i.details?.firstName} <span>is now associate with you</span> </div>
                  </div>
                </div>
              </div>
            );
          })}
          {dataJob?.map((a, index) => {
            return (
              <div className="">
                <div className="notificationInterview">
                  <div className="interviewNotificationCard">
                    <img
                      src={imageAvatar}
                      alt="imageAvatar"
                      className="notificationImage"
                    />
                    <div className="all-noti-cont">
                      {a.applicant?.firstName} has applied for{" "}
                      <span className="jobTitle" title={a?.jobTitle}>
                        {a?.jobTitle?.length > 15
                          ? a?.jobTitle?.substring(0, 15) + "..."
                          : a?.jobTitle}
                      </span>{" "}
                      role at {a.companyName?.value}
                    </div>
                    <div className="buttonGridInterview">
                      <button
                        className="btn btn-new-int"
                        onClick={() =>
                          history.push(
                            `/portal/applicant-details/${a?.applicant?.userId}/${a?._id}`
                          )
                        }
                      >
                        {"View Applicant"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {datas?.map((a, index) => {
            const state = {
              isCardActive: "applied",
              isId: a?._id,
              openNewJobs: true,
              dataJob: a,
            };
            return (
              <div className="">
                <div className="notificationInterview">
                  <div className="interviewNotificationCard">
                    <img
                      src={imageAvatar}
                      alt="imageAvatar"
                      className="notificationImage"
                    />
                    {a.applicant?.statusOne === "shortlist" ? (
                      <div className="all-noti-cont">
                        Your profile has been shortlisted for
                        <span className="jobTitle" title={a?.jobTitle}>
                          {a?.jobTitle?.length > 15
                            ? a?.jobTitle?.substring(0, 15) + "..."
                            : a?.jobTitle}
                        </span>{" "}
                        at {a.companyName?.value}
                      </div>
                    ) : a.applicant?.statusOne === "offered" ? (
                      <p>
                        Good News you have been offered the
                        <span className="jobTitle" title={a?.jobTitle}>
                          {a?.jobTitle?.length > 15
                            ? a?.jobTitle?.substring(0, 15) + "..."
                            : a?.jobTitle}
                        </span>{" "}
                        position at {a.companyName?.value}
                      </p>
                    ) : a.applicant?.statusOne === "reject" ? (
                      <p>
                        We regret to inform you that your application for the
                        <span className="jobTitle" title={a?.jobTitle}>
                          {a?.jobTitle?.length > 15
                            ? a?.jobTitle?.substring(0, 15) + "..."
                            : a?.jobTitle}
                        </span>{" "}
                        position at {a.companyName?.value} has been declined
                      </p>
                    ) : (
                      ""
                    )}

                    <div className="buttonGridInterview">
                      <button
                        className="btn btn-new-int"
                        onClick={() =>
                          history.push(`/portal/applied-jobs`, state)
                        }
                      >
                        {"View Job Application"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <p className="no-foundata">You have no new notification yet</p>
        </>
      )}

      <Dialog
        open={declineOpen}
        PaperProps={{
          style: {
            maxWidth: "480px",
            borderRadius: "24px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalBody">
          <label className="mTitle">Reason For Declining?</label>
          <Select
            options={reasonOptions}
            value={reasonOptions.find((option) => option.value === reason)}
            onChange={handleReason}
            className="footer-header font"
            styles={customStyle}
          />
          <div>
            <label style={{ color: "#4f4953", marginTop: "10px" }}>
              Description (optional)
            </label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Enter your description"
                aria-label="Bank Name"
                name="description"
                aria-describedby="basic-addon1"
                className="font"
                style={{
                  borderRadius: "8px",
                  background: "#F4F4F6",
                  height: "100px",
                  position: "relative",
                  width: "520px",
                  color: "#362A41",
                }}
                onChange={(e) => setDeclineDescription(e.target.value)}
              />
            </InputGroup>
          </div>
        </div>
        <div
          className="modalFooter"
          style={{
            position: "relative",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="button5"
            onClick={() => setDeclineOpen(false)}
            style={{ cursor: "pointer" }}
          >
            Cancel
          </button>
          <button className="button3" onClick={() => handleDecline(declineId)}>
            Submit
          </button>
        </div>
      </Dialog>
      <Dialog
        open={openConfirm}
        PaperProps={{
          style: {
            borderRadius: "24px",
            maxWidth: "400px",
          },
        }}
      >
        <div className="modalHeader">
          <div className="mTitle">Disclaimer</div>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => {
              setOpenConfirm(false);
            }}
          />
        </div>
        <DialogContent>
          <div className="modalBody" style={{ padding: "20px 20px 0 20px" }}>
            <p style={{ color: "#4F4953", fontWeight: "normal" }}>
              We want to remind you that the interview session will be recorded
              for quality assurance purposes.
            </p>
            <p style={{ color: "#4F4953", fontWeight: "normal" }}>
              We kindly request that you refrain from disclosing any personal
              details during the interview process.
            </p>
          </div>
        </DialogContent>
        <div className="modalFooter" style={{ padding: "10px" }}>
          <button
            className="button2"
            onClick={() =>
              history.push({
                pathname: `/portal/videoRoom/${valueOne?.calendarEvents[0]?.bookingId}/${valueOne.calendarEvents[0]?.roomName}`,
                state: {
                  main_id: valueOne.calendarEvents[0]?._id,
                  serviceId: valueOne?.calendarEvents[0]?.serviceId,
                },
              })
            }
          >
            Okay, Proceed
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default AllNotification;
