import React,{useState,useEffect} from "react";
import styled from "styled-components";
import Common from "../../utils/common";
import { BiSearchAlt } from "react-icons/bi";
import { CiSettings } from "react-icons/ci";
import { BsCreditCard2Front } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdOutlineLogout } from "react-icons/md";
import { Auth } from "aws-amplify";
import {useHistory,} from "react-router-dom/cjs/react-router-dom.min";
import Dialog from "@mui/material/Dialog";
import { DialogTitle } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
// import Back from "../../images/Back.png";

const HeaderLayout = styled.div`
  position: relative;
  margin-left: 5px;
  width: calc(100% - 226px);
  height: 105px;
  left: 226px;
  border: 1px;
  // height: 174px;
  background: white;
`;
const SerachSection = styled.div`
  width: 483px;
  height: 50px;
  position: absolute;
  top: 8px;
  left: 53%;
  border: 1px solid whitesmoke;
  border-radius: 10px;
`;
const MainHeader = styled.div`
  font-weight: normal;
  position: relative;
  // display: flex;
  // justify-content: flex-start;
  top: 10px;
  margin-left: 39px;
  color: black;
  font-family: Inter-Medium, sans-serif;
  font-size: 20px;
  cursor:pointer;
`;
const SeparteDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;
const Text = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  color: #362a41;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
`;

const TopHeader = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [searchKeyword, setSearchKeyword] = useState("");
    const [success, setSuccess] = useState(false);
    const [user, setUsers] = useState("");
    const handleInputOneChange = (event) => {
        setSearchKeyword(event.target.value);
    };
    useEffect(() => {
    Auth.currentAuthenticatedUser()
        .then((user) => {
        if (user) {
            setUsers(user);
        }
        })
        .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
        });
    }, []);
    const logout = () => {
    Common.logout();
    };
    function onSubmit() {
    setSuccess(true);
    }
    const [openSetting, setOpenSetting] = useState(false);
    const history = useHistory();
    const openSubmit = () => {
    setOpenSetting(true);
    };

    return(
        <div>
            <Dialog
                open={openSetting}
                PaperProps={{
                style: {
                    width: "347px",
                    height: "200px",
                    top: "50px",
                    right: "35px",
                    borderRadius: "24px",
                    position: "fixed",
                },
                }}
                fullWidth
                maxWidth="sm"
            >
            <DialogTitle
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontFamily: "Inter-Medium,sans-serif",
                position: "relative",
            }}
            >
            <span
                style={{
                width: "347px",
                height: "50px",
                padding: "19px, 16px, 19px, 25px",
                border: "0px, 0px, 1px, 0px",
                borderBottom: "1px solid #F4F4F6",
                }}
            >
            Setting
            </span>
            <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
            </DialogTitle>
            <DialogContent>
            <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
            </div>
            <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            >
            
            </div>
            <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
            >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
            </div>
            </DialogContent>
        </Dialog>
        
        <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <button className="button5" onClick={() => setSuccess(false)}>
              No
            </button>
            {user ? (
              <button
                className="button3"
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </button>
            ) : (
              <button className="button3" onClick={logout}>
                Yes
              </button>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>

            <HeaderLayout>
                {props.image ? 
                <MainHeader ><img src={props.image} alt="img"  onClick={() => history.push("/portal/my-profile")}/> </MainHeader>
                : "" }
                {props.comEdit ? 
                <MainHeader ><img src={props.comEdit} alt="img"  onClick={() => history.push("/portal/view-company")}/> </MainHeader>
                : "" }
                <SeparteDiv>
                
                    <SerachSection style={{ position: "relative" }}>
                        <input
                        color="#fff"
                        placeholder={"Search Keywords for Job Title"}
                        onChange={handleInputOneChange}
                        className="inputTaster"
                        style={{
                            paddingRight: "40px",
                            background: "rgba(54, 42, 65, 0.04)",
                        }} // Add right padding to make space for the icon
                        />
                        <BiSearchAlt
                        size={20}
                        style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            borderRadius: "8px",
                            padding: "5px",
                            width: "32px",
                            height: "32px",
                            background: "#fff",
                            transform: "translateY(-50%)",
                        }}
                        />
                    </SerachSection>
                    <div style={{ position: "relative", marginTop: "20px", right: "20px" }}>
                        <CiSettings
                        size={30}
                        onClick={openSubmit}
                        style={{ cursor: "pointer" }}
                        />
                    </div>
                </SeparteDiv>
            </HeaderLayout>
      
        </div>
    )
}

export default TopHeader;