import React, {useEffect} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width:90vw;
    // overflow-x:hidden;
`
const Para = styled.div`
    width:90vw;
    padding:20px 50px 100px 50px;
    margin:auto;
    // overflow-x:hidden;
    @media (max-width:500px){
        width:100%;
        padding:20px 10px 30px 30px;
    }
`
const Head = styled.div`
color:#362A41;
font-size: 24px;
display:flex;
justify-content:center;
margin-top:30px;
font-family:Inter-Medium,sans-serif;
`
const Content = styled.div`
color:#362A41;
font-size: 16px;
// display:flex;
// justify-content:center;
line-height:22px;
margin-top:20px;
font-family:Inter-Medium,sans-serif;

`
const ContentHead = styled.div`
color:#362A41;
font-size: 22px;
line-height:24px;
margin-top:20px;
font-family:Inter-Medium,sans-serif;
text-align:left;
`
const Terms = () => {
    useEffect(() => {
        document.title = "Terms and Conditions | Xakal";
      }, []);
    
    return(
        <div >
            <Wrapper>
            <div >
            <Head>TERMS OF USE  </Head>
            </div>
            <Para>
            {/* <ContentHead>We prioritize your Privacy: </ContentHead> */}
            
            <Content>Welcome to Xakal Terms of Use ("Agreement") govern your access to and use of our product, including our website, and any associated content, features. By using our Product, you agree to be bound by the terms and conditions outlined in this Agreement. Please read these terms carefully and make sure you understand them. If you do not agree with any part of this Agreement, we kindly ask that you refrain from using our Product. Your use of our Product constitutes your acceptance of this Agreement.  </Content>
            <ContentHead>Account </ContentHead>
            <Content>You need to have a strong password for your account, and you should keep it a secret. Your account is yours, so you can't give parts of it, to someone else. </Content>
            <Content>You must follow the law and the rules we've set out in our Dos and Don'ts. You are responsible for whatever happens through you. </Content>
            <ContentHead>Account and Registration  </ContentHead>
            <Content>To register, you need to provide your email, full name, phone number, country,and other basic details. </Content>
            <ContentHead>Changes: </ContentHead>
            <Content>We can change these Terms of Service without notifying you in advance. Any changes we make will go into effect immediately and will apply to you. It's a good idea to check our Terms of Service from time to time for updates. </Content>
            <ContentHead>Restrictions :</ContentHead>
            <Content>You agree not to use the Platform for anything illegal or to bother, threaten, harm, or use offensive language. You also can't encourage others to do these things.  </Content>
            <Content>Trying to access the Platform without permission, like hacking or trying to find passwords, is not allowed. </Content>
            <Content>You can't try to get information in ways that aren't intended through the Platform. </Content>
            <Content>In simpler terms, be lawful and respectful when using the Platform, and don't try to break into it or gather information in ways you're not supposed to. </Content>
            <ContentHead>General Terms  </ContentHead>
            <Content> If a court, with the authority to oversee this agreement, decides that a part of this contract can't be enforced (for example, because it goes against the law), you and we agree that the court should try to change the terms in a way that makes them enforceable while still achieving the original purpose of the contract. If changing the unenforceable part isn't possible, both you and we agree to ask the court to remove that specific part from the contract and still make the rest of the contract legally binding. </Content>
            <Content>You can't hand over or transfer this contract (or your membership or use of the services) to anyone else without getting permission from Xakal. However, you agree that Xakal can transfer this contract to their related companies or to another company and they don't need your permission for that. No one else outside of you and Xakal can claim benefits from this contract. It's a private agreement between the two of you, and no third parties can use it for their own purposes. </Content>
            <ContentHead>Disclaimer and limit liability  </ContentHead>
            <Content>"Xakal do not guarantee that our services will always work perfectly or without interruptions. We provide our services, including all the content and information, as they are, without any promises or warranties. This means we don't make any guarantees, either implied or required by law, including guarantees about the title, accuracy of information, non-infringement, suitability for a specific purpose, or merchantability. In essence, Xakal provide the services as they are, and you use them at your own risk. They don't make any specific promises about how the services will perform.  </Content>
            <ContentHead>Dos and Don’ts  </ContentHead>
            <ContentHead>Dos</ContentHead>
            <Content>1. It's important to follow all relevant laws, which include laws related to privacy, intellectual property, anti-spam, and other regulatory requirements. This ensures that your use of the platform aligns with legal standards. </Content>
            <Content>2. To maintain the integrity of the platform, users should share information that is accurate and up to date. This includes details on your profile, such as your work history and qualifications. </Content>
            <Content>3. For transparency and trust-building, use your actual name on your profile.  </Content>
            <Content>4. The platform is designed for professional networking. Using it in a professional manner means maintaining respectful interactions with other users and adhering to ethical conduct in all your activities. </Content>
            <ContentHead>Don'ts  </ContentHead>
            <Content>1. Faking your identity, creating profiles for others, or attempting to use someone else's account is prohibited. Authenticity is key on the platform. </Content>
            <Content>2. Using software, scripts, or any means to scrape or copy data from the platform, such as user profiles or other information, is not allowed. Respect the privacy and ownership of user data. </Content>
            <Content></Content>
            <Content>3. Avoid any attempts to override security features, bypass access controls, or exceed usage limits set by the platform. This ensures the platform's security and stability. </Content>
            <Content>4. Using data obtained from the platform, either directly or through third parties, without consent is a violation. Respect the privacy and rights of other users. </Content>
            <Content>5. It's important not to disclose confidential information that you do not have permission to share. This includes sensitive information about your employer or others. </Content>
            <Content>6. Posting content that contains harmful software, such as viruses, worms, or other malicious code, is prohibited. This helps maintain the platform's security. 
                        These guidelines aim to create a safe and professional environment for users to network and interact while upholding ethical and legal standards. </Content>
            <ContentHead>Contact Us </ContentHead>
            <Content>If you ever encounter issues with our platform or services, such as problems with other users' behaviour, or if you feel someone is not following our rules, we're here to help. You can report any concerns or complaints to our support team. </Content>
            <Content>For any request for data deletion, please reach out to our support mail.</Content>
            <Content>We take your concerns seriously and will do our best to address them quickly and effectively. When you contact the support team, please provide all the details you can about the issue, and if you have any evidence, please share that as well. </Content>
            <Content>Email ID: <span style={{color:"blue"}}>support@thexakal.com </span> </Content>
            </Para>
            </Wrapper>
        </div>
    )
}

export default Terms;