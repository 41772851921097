import React from "react";
import { useState, useEffect } from "react";
import "../styles/login.css";
import "../styles/utils.css";
import MobileHeader from "./mobileHeader";
import DesktopHeader from "./desktopHeader";
import backArrow from "../images/arrow-box.svg";
import axios from "axios";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiUser } from "react-icons/ci";
import styled from "styled-components";
import { MdOutlineLogout } from "react-icons/md";
import { BsCreditCard2Front } from "react-icons/bs";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Amplify, Auth } from "aws-amplify";
import Common from "../utils/common";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      // redirectSignIn: "http://localhost:3000/",
      // redirectSignOut: "http://localhost:3000/",
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});
const HeaderLayout = styled.div``;

const Button3 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button5 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

const FormContainer = styled.div``;
const ChangePassword = styled.h5``;

const Text = styled.div`
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;
const Forgetpassword = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
      setEnabled(true); // Enable the button when email is present
      setMail(null);
    }
  }, [location.state]);
  const [user, setUsers] = useState("");
  const [success4, setSuccess4] = useState(false);
  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  function onSubmit() {
    setSuccess4(true);
  }

  const [mail, setMail] = useState();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const history = useHistory();

  const handleChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);

    if (isValidEmail(inputEmail) && inputEmail.trim() !== "") {
      setError(null);
      setMail(null);
    } else {
      setError("Email is invalid");
      setEnabled(false);
      setMail(null);
    }
  };

  const handleInput = async (e) => {
    e.preventDefault();
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setMail(null);
    setError(null);

    if (!inputEmail) {
      setEnabled(false);
    } else if (isValidEmail(inputEmail)) {
      try {
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/validateForgotEmail`,
          {
            email: inputEmail,
          }
        );

        if (response && response.data && response.data.success === true) {
          setMail("");
          setEnabled(true);
        } else {
          setMail(response.data.error);
          setEnabled(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMail("Email id is invalid");
      setEnabled(false);
    }
  };

  const handleEnable = (event) => {
    event.preventDefault();
    setMinutes(1);
    setSeconds(0);
    setEnabled(false);
  };

  const sendOtp = async (e) => {
    if (email === "") {
      alert("Enter Your Email !");
    } else if (!email.includes("@")) {
      alert("Enter Valid Email !");
    } else {
      const data = {
        email: email,
      };

      try{
        const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/forgot-password`,
        data
      );
      if (response.status === 200) {
        handleEnable(e);
        toast.success("OTP Sent Successfully");
        console.log(response, "response");
        history.push({
          pathname: "/portal/otp-changed",
          state: { email: email, minutes: minutes, seconds: seconds },
        });
      } else {
        // toast.error(response.response.data.error);
      }
    }
    catch(err)  {console.log("Error fetching details:",err)};
  }};
  const [openSetting, setOpenSetting] = useState(false);

  const Back = () => {
    history.push("/portal/changepassword");
  }
  const searchedKey = () => { }
  return (
    <>
      <ToastContainer />
      <HeaderLayout>
        {/* Start of Mobile Header  */}
        <MobileHeader onlyLogo={true} />
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader title={"Forgot Password"} searchedKey={searchedKey} isSearchActive={true} back={Back} backbtn={true} />
        {/* End of desktop Header  */}
      </HeaderLayout>

      <div className="p-15 bg-m-white in-content-wrapper">
        <div className="f-pass-container">
          <div className="w-100">
            <div className="sub-header o-mobile">
              <div
                className="arrow-box"
                onClick={() => history.push("/portal/changepassword")}
              >
                <img src={backArrow} className="back-arrow-img" alt="XAKAL" />
              </div>
              <h4 className="textpass font-face-gm mb-0">
                Forgot your password?
              </h4>
            </div>
            <div className="cp-wrapper">
              <h4 className="cp-header o-desk">Forgot your password?</h4>

              <FormContainer className="cp-body">
                <div>
                  <ChangePassword className="textpass font-face-gm">
                    Please enter your email below
                  </ChangePassword>
                  <div className="">
                    <div style={{ position: "relative" }}>
                      <input
                        autoComplete="off"
                        name="currentPassword"
                        className="input101"
                        placeholder="Enter your mail"
                        onChange={handleChange}
                        onBlur={handleInput}
                        value={email}
                      />
                    </div>
                    {mail && (
                      <div
                        className="text-danger f12"
                        style={{ marginTop: "3px" }}
                      >
                        {mail}
                      </div>
                    )}
                    <div
                      className="text-danger f12"
                      style={{ margin: "3px 0 3px 0" }}
                    >
                      {error}
                    </div>
                  </div>

                  <div className="container-login100-form-btn m-b-20">
                    <button
                      className="login100-form-btn"
                      disabled={!enabled}
                      style={{
                        opacity: enabled ? "100%" : "50%",
                        marginTop: "15px",
                      }}
                      onClick={sendOtp}
                    >
                      Get OTP
                    </button>
                  </div>
                </div>
              </FormContainer>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success4}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want logout?</Text>

          <DateWrapperButton>
            <Button5 onClick={() => setSuccess4(false)}>No</Button5>
            {user ? (
              <Button3
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button3>
            ) : (
              <Button3 onClick={logout}>Yes</Button3>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Forgetpassword;
