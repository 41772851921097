import React, { useEffect, useState } from "react";

import "./company.css";
import axios from "axios";
import Edit from "../../images/Pen 2.svg";
import WebsiteImg from "../../images/Users Group Two Rounded.png";
import ColName from "../../images/Buildings.png";
import EmpCount from "../../images/Globals.png";
import Email from "../../images/AboutLanding/Letter.svg";
import Phone from "../../images/Phone Rounded.png";

import { Link } from "react-scroll";
import styled from "styled-components";
import TopHeader from "../MyProfile/TopHeader";
import { BsEye } from "react-icons/bs";
import {
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineCloseCircle } from "react-icons/ai";
import AboutPopupModal from "./aboutpopupCompany";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import AboutContactModal from "./aboutContactPopup";
import CompanyJob from "./companyJobs";
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const AboutDiv = styled.div`
  position: relative;
  padding: 20px 40px;
  margin-top:0px;
  display: flex;
  justify-content: space-between;
  // margin: 1rem 1rem 1rem 3rem;
  color: #000;
  font-family: Inter-Medium,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;
const Label = styled.div`
  color: #8653B6;
  font-family: Inter-Medium,sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin: 30px 0 10px 0;
`;
const Wrapper = styled.div`
  display:flex;
  gap:20px;
  margin:20px 0;
`
const Wrappers = styled.div`
background:#F4F4F6;
border-radius:8px;
padding:10px 20px;
`
const Website = styled.div`
color: #4F4953;
font-family: Inter-Medium,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: normal;
line-height: normal;
text-transform: lowercase;
display:flex;
gap:10px;
`
const WebsiteContent = styled.div`
  margin-top:5px;
`
const AboutSubDiv = styled.div`
  position: relative;
  margin: 1rem 1rem 1rem 3rem;
  top: -27px;
`;
const JobTitle = styled.h3`
  position: relative;
  font-family: Inter-Medium;
  font-size: 25px;
  color: black;
  display: flex;
  justify-content: space-between;
`;
const CompanyTitle = styled.p`
  position: relative;
  font-family: Inter-Medium;
  font-size: 15px;
  color: black;
`;
const PostedBy = styled.p`
  position: relative;
  font-family: Inter-Medium;
  font-size: 15px;
  color: black;
`;

const CompanyEdit = ({ onClose }) => {
  const { id } = useParams();

  const [open, setPopupOpen] = useState(false);
  const [openTwo, setPopupOpenTwo] = useState(false);

  const openPopup = (id) => {
    setPopupOpen(true);
    setSelectedId(id);
  };
  const openPopupTwo = (id) => {
    setPopupOpenTwo(true);
    setSelectedId(id);
  };
  const [selectedId, setSelectedId] = useState(null);
  const [data, setData] = useState();
  const [datas, setDatas] = useState([]);

  const getOneCompanydetail = async() => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/company/details/${id}`)
        console.log(response, "companyOneDetail");
        setData(response.data.data);
        setSelectedId(response.data.data.companyId);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  const getOneCompanydetailCompany = async() => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-company/${id}`)
        console.log(response, "companyOneDetailfAFSDFASFDSA");
        setDatas(response.data.data);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    getOneCompanydetail();
    getOneCompanydetailCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openClose = () => {
    setPopupOpen(false);
    getOneCompanydetail();
  };
  const openCloseDates = () => {
    setPopupOpenTwo(false);
    getOneCompanydetail();
  };
  const [openJob, setOpenJob] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState(null);
  const handleOpen = (jobData) => {
    setOpenJob(true);
    setSelectedJobData(jobData);
  };

  return (
    <div>
      <TopHeader />
      <MainLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <div className="Edit-wrapper">
          <div>
            <Link to="abouts" spy={true} smooth={true} offset={-180} duration={1000}>About</Link>
          </div>
          <div>
            <Link to="contact" spy={true} smooth={true} offset={-180} duration={1000}>Contact Details</Link>
          </div>
          <div>
            <Link to="jobPost" spy={true} smooth={true} offset={-180} duration={1000}>Post</Link>
          </div>
        </div>
        <PageWrapper>
          <div id="abouts">
            <AboutDiv>
              <h1>About</h1>
              <img src={Edit} alt="img" onClick={() => openPopup(selectedId)} style={{ cursor: "pointer" }} />
              {/* <FaRegEdit
                size={30}
                color="black"
                style={{ position: "relative", marginTop: "30px" }}
                onClick={() => openPopup(selectedId)}
              /> */}
            </AboutDiv>
            <AboutSubDiv>
              <Label>
                {data && data.companyName}</Label>
              {/* <Label>Website</Label> */}
              <Website>{data && data.website}</Website>
              <Wrapper>
                <Website><img src={ColName} alt="img" /><WebsiteContent>{data && data.industry}</WebsiteContent></Website>
                <Website><img src={WebsiteImg} alt="img" /><WebsiteContent>{data && data.organisationSize}</WebsiteContent></Website>
                <Website><img src={EmpCount} alt="img" /><WebsiteContent>{data && data.organisationType}</WebsiteContent></Website>
              </Wrapper>
              <Wrappers>
                <Website>
                  {data && data.companyTagline ? (
                    <>{data && data.companyTagline}</>
                  ) : (
                    "-"
                  )}
                </Website>
              </Wrappers>
            </AboutSubDiv>
          </div>
        </PageWrapper>
        <PageWrapper>
          <div id="contact">
            <AboutDiv>
              <h1>Contact Details</h1>
              <img src={Edit} alt="img" onClick={() => openPopupTwo(selectedId)} style={{ cursor: "pointer" }} />
              {/* <FaRegEdit
                size={30}
                color="black"
                style={{ position: "relative", marginTop: "30px" }}
                onClick={() => openPopupTwo(selectedId)}
              /> */}
            </AboutDiv>
            <AboutSubDiv>
              {/* <Label>ContactNumber</Label> */}
              <Wrapper>
                <Website>
                  <img src={Phone} alt="img" />
                  <WebsiteContent>
                    {data && data.contactNumber ? (
                      <>{data && `+${data.contactNumber}`}</>
                    ) : (
                      "-"
                    )}
                  </WebsiteContent>
                </Website>
                <Website>
                  <img src={Email} alt="img" />
                  <WebsiteContent>
                    {data && data.contactEmailId}
                  </WebsiteContent>
                </Website>
                <Website>
                  <img src={EmpCount} alt="img" />
                  <WebsiteContent>
                    {data &&
                      data.workMode?.map((a) => {
                        return <>{a}</>;
                      })}
                  </WebsiteContent>
                </Website>
              </Wrapper>
              {/* <Label>Location</Label> */}
              <Wrappers>
                <Website>
                  {data && data?.primaryAddress ? <>
                    {data &&
                      `${data && data?.primaryAddress?.addressOne} ${data && data?.primaryAddress?.addressTwo
                      } ${data && data.primaryAddress?.city} ${data && data.primaryAddress?.state
                      } ${data && data.primaryAddress?.country} ${data && data.primaryAddress?.pincode
                      }`}
                  </> : "-"}
                </Website>
              </Wrappers>
              {/* <Label>Workplace modes</Label> */}

            </AboutSubDiv>
          </div>
        </PageWrapper>
        <PageWrapper>
          <div id="jobPost">
            <AboutDiv>
              <h1>Posted Job</h1>
            </AboutDiv>
            <AboutSubDiv>
              <Label>Business Analyst</Label>
            </AboutSubDiv>
            {datas &&
              datas.map((a) => {
                return (
                  <AboutSubDiv>
                    <JobTitle>
                      {a.jobTitle}
                      <BsEye
                        size={30}
                        style={{ marginTop: "10px" }}
                        onClick={() => handleOpen(a)}
                      />
                    </JobTitle>
                    <CompanyTitle>{a.companyName.value}</CompanyTitle>

                    <PostedBy>
                      Posted By :{" "}
                      {`${a.createdBy.firstName} ${a.createdBy.lastName}`}
                    </PostedBy>
                  </AboutSubDiv>
                );
              })}
          </div>
        </PageWrapper>
      </MainLayout>

      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            fontweight: "normal",
            color: "#362A41"
          }}
        >
          <span>Edit About</span>
          <AiOutlineCloseCircle onClick={() => setPopupOpen(false)} />
        </DialogTitle>
        <DialogContent>
          <AboutPopupModal id={selectedId} onClosfun={openClose} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openTwo}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            fontweight: "normal",
            color: "#362A41"
          }}
        >
          <span>Edit Contact</span>
          <AiOutlineCloseCircle onClick={() => setPopupOpenTwo(false)} />
        </DialogTitle>
        <DialogContent>
          <AboutContactModal id={selectedId} onClose={openCloseDates} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJob}
        fullWidth
        maxWidth="lg"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <span>Job Detail</span>
          <AiOutlineCloseCircle onClick={() => setOpenJob(false)} />
        </DialogTitle>
        <DialogContent>
          <CompanyJob jobData={selectedJobData} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompanyEdit;
