import React, {useEffect} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width:90vw;
    // overflow-x:hidden;
`
const Para = styled.div`
    width:90vw;
    padding:20px 50px 100px 50px;
    margin:auto;
    // overflow-x:hidden;
    @media (max-width:500px){
        width:100%;
        padding:20px;
    }
`
const Head = styled.div`
color:#362A41;
font-size: 24px;
display:flex;
justify-content:center;
padding:0 20px;
margin-top:30px;
font-family:Inter-Medium,sans-serif;
`
const Content = styled.div`
color:#362A41;
font-size: 16px;
// display:flex;
// justify-content:center;
Contentne-height:22px;
margin-top:20px;
font-family:Inter-Medium,sans-serif;

`
const ContentHead = styled.div`
color:#362A41;
font-size: 22px;
Contentne-height:24px;
margin-top:20px;
font-family:Inter-Medium,sans-serif;
text-aContentgn:left;
`
const Refund = () => {
    useEffect(() => {
        document.title = "Refund | Xakal";
      }, []);
    
    return(
        <div >
            <Wrapper>
            <div >
            <Head>Return and Refund Policy for Xakal </Head>
            </div>
            <Para>
            <ContentHead>Refund Policy  </ContentHead>
            
            <Content>Last updated: January 08, 2024 </Content>
            
            <Content>Thank you for avaiContentng Service at Xakal. </Content>
            <Content>If, for any reason, you are not completely satisfied with the Service, We invite You to review our poContentcy on refunds. </Content>
            <Content>The following terms are appContentcable for any Service that You booked with Us. </Content>
            <ContentHead>Interpretation and Definitions  </ContentHead>
            <ContentHead></ContentHead>
            <Content>Interpretation </Content>
            <Content>The words of which the initial letter is capitaContentzed have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural. </Content>
            <ContentHead>Definitions </ContentHead>
            <Content>For the purposes of this Return and Refund PoContentcy: </Content>
            <Content style={{marginLeft:"20px"}}>1. Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to CODEUP GLOBAL SOLUTIONS PRIVATE ContentMITED. </Content>
            <Content style={{marginLeft:"20px"}}>2. Interview refer to the Interview Service offered for booking on Xakal. </Content>
            <Content style={{marginLeft:"20px"}}>3. Booking mean a request by You to Purchase an Interview Service on Xakal.</Content>
            <Content style={{marginLeft:"20px"}}>4. Service refers to the Interview Service on Xakal. </Content>
            <Content style={{marginLeft:"20px"}}>5. Website refers to Xakal, accessible from https://thexakal.com/ </Content>
            <Content style={{marginLeft:"20px"}} >6. You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as appContentcable. </Content>
            
            <ContentHead>Your Booking Cancellation Rights </ContentHead>
            <Content>The deadContentne for cancelContentng a Booking is 24 hours before the date and time on which You have booked the Interview Slot. </Content>
            
            <Content>In order to exercise Your right of cancellation, you must inform Us of your decision by means of a clear statement. You can inform us of your decision by: </Content>
            <Content>By email: <span style={{color:"blue"}}>support@thexakal.com</span> </Content>
            <Content>We will reimburse You between 3 – 5d business days from the day on which you cancel the Booking. We will use the same means of payment as You used for the Booking, and You will not incur any fees for such reimbursement. </Content>
            <ContentHead>Gifts  </ContentHead>
            <Content>If the Goods were marked as a gift when purchased and then shipped directly to you, You'll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to You. </Content>
            <Content>If the Goods weren't marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give it to You later, We will send the refund to the gift giver.  </Content>
            <ContentHead>Contact Us  </ContentHead>
            <Content>If you have any questions about our Returns and Refunds PoContentcy, please contact us</Content>
            <Content>By email: <span style={{color:"blue"}}>support@thexakal.com</span> </Content>
            </Para>
            </Wrapper>
        </div>
    )
}

export default Refund;