// import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import Common from "../utils/common";
import DesktopHeader from "../components/desktopHeader";
import tick from "../../src/images/landing/tick.png";
const Result = ({ logOut }) => {
  const location = useLocation();
  const bookId = location.state && location.state.bookId;
  const calendar = location.state && location.state.calendar;
  const book = location.state && location.state.book;
  const saved = location.state && location.state.saved;

  const navigate = useHistory();
  const [marks, setMark] = useState(0);
  const [totalMarks, setTotalMark] = useState(0);

  const getResult = async() => {
    try{
      const res = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-result/${bookId}`)
      
        setTotalMark(res.data.finalScoreModal.totalMarks);
        setMark(res.data.finalScoreModal.marks);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const getUser = async() => {
    try{
      const response = await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
        setFirstName(response.data.aboutOne.firstName);
        setLastName(response.data.aboutOne.lastName);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    getUser();
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    document.title = "Assessment | Xakal";

    const redirectTimer = setTimeout(() => {
      if (calendar) {
        navigate.push("/portal/my-calendar");
      } else if (book) {
        navigate.push("/portal/interview/bookedInterview");
      } else if (saved) {
        navigate.push("/portal/interview/savedInterview");
      }
    }, 3000);

    // Clear the timer when the component unmounts or when the calendar changes
    return () => clearTimeout(redirectTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar]);
  return (
    <>
      <DesktopHeader title={"Assessment"} />
      <div className="asses-home">
        <div className="asses-cons">
          <h2>
            Congrats {` ${firstName} ${lastName}`}! You've completed the exam.
          </h2>

          <h2>
            Your Score is {marks} out of {totalMarks}
          </h2>
          <img src={tick} alt="tick" />
        </div>
      </div>
    </>
  );
};

export default Result;
