/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./buttonStyle.css";
import InfiniteScroll from "react-infinite-scroll-component";
import "./jobs.css";
import iExp from "../../images/icons/exp.svg";
import noData from "../../images/NoData.svg";
import iSalary from "../../images/icons/salary.svg";
import iLocation from "../../images/icons/location.svg";
import iHome from "../../images/icons/home.svg";
import iBookmark from "../../images/icons/bookmark.svg";
import iBookmarkActive from "../../images/icons/bookmark-active.svg";
import RecentSearch from "./recentSerchDetails";
import SearchDetails from "./searchdetails";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import Image from "../../images/company.png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Fitme from "./fitme";
import ViewJobApplicant from "./jobApplication";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Amplify, Auth } from "aws-amplify";
import Common from "../../utils/common";
import Settings from "../settings";
import Upload from "../../images/Upload Minimalistic.svg";
import Spinner from "react-bootstrap/Spinner";
import CloseIcon from "../../images/CloseIcon.svg";
import CustomHeaderLayout from "./customHeaderLayout";
import ExploreInterview from "../interview/exploreInterview";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ShimmerLoading from "../interview/shimmerloading";
import { preferences } from "joi";

const Button3 = styled.div``;
const Button5 = styled.div``;
const Button4 = styled.div`
  color: rgba(45, 49, 57, 1);
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  cursor: pointer;
`;
const Button6 = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: rgba(253, 33, 69, 1);
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 5px;
  width:100%;
`;

const Wrapper = styled.div``;

const JobTitle = styled.div`
  // font-weight: normal;
`;

const IconTitle = styled.div``;
const DateWrapper = styled.div``;
const DateWrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
`;
const QuestionLabel = styled.label`
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
`;
const PopUpTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4f4953;
  margin: 10px 0 5px 20px;
`;
const PopUpTitles = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;
const FileTypeInfo = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #4f4953;
  position: relative;
  right: 15px;
  bottom: 20px;
  margin: 10px 0 0 10px;
  span {
    color: #ff596a;
  }
`;
const FileTypeInfos = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #4f4953;
  // margin-left: 5px;
  span {
    color: #ff596a;
    margin-top: 20px;
  }
`;
const HrLine = styled.div`
  border: 1px solid #f7f7f8;
  width: 100%;
  margin: 0;
`;

const HrLines = styled.div`
  border: 1px solid #f7f7f8;
  margin-top: 10px;
`;

const CardWrapper = styled.div`
  min-width:503px;
  cursor: pointer;
`;

const Text = styled.div`
  text-align: center;
  margin: 20px;
`;

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});
const ApplyJob = () => {
  const [user, setUsers] = useState("");
  const [success, setSuccess] = useState(false);
  const logout = () => {
    Common.logout();
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);

  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [dataDup, setDataDup] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [scheduleData, setScheduleData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [favourites, setFavourites] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isFavorite, setIsFavorite] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [question, setQuestion] = useState();
  const [open4, setOpen4] = useState(false);
  const [exhausted, setExhausted] = useState(true);
  const [jobId, setJobId] = useState();
  const [userId, setUser] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  const getAllJob = async () => {
    const url = `${process.env.PUBLIC_URL}/xakal/get-all-job-detail-skill?p=${pagination}`;
  
    try {
      const response = await axios.get(url);
  
      if (response.data) {
        if (response.data.activeJobsOne.length === 0) {
          setLoading(false);
          setExhausted(false);
        } else {
          setPagination(response.data.pageCount);
  
          const uniqueJobs = response.data.activeJobsOne.filter(
            (job) => !data.some((existingJob) => existingJob._id === job._id)
          );
  
          // Get unique company IDs
         
  
          // Enrich uniqueJobs with company details
         
  
          setData((current) => {
            return current ? [...current, ...uniqueJobs] : uniqueJobs;
          });
  
          // Check if uniqueJobs length is less than 9
          if (uniqueJobs.length < 9) {
            setLoading(false);
            setExhausted(false);
          }
  
          // Set loading state to false
          setLoading(false);
  
          // Update duplicate data
          setDataDup((current) => {
            return current ? [...current, ...uniqueJobs] : uniqueJobs;
          });
  
          // Update questions
          setQuestion((current) => {
            return current
              ? [...current, response.data.job]
              : [response.data.job];
          });
        }
      }
    } catch (error) {
      console.error("Error fetching job details:", error);
      setLoading(false); // Ensure loading state is reset on error
    }
  };
  
  // eslint-disable-next-line no-unused-vars
  const getAllJobReset = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-all-job-detail-skill?p=${pagination}`
      )
      .then((response) => {
        if (response.data) {
          if (response.data.activeJobsOne.length === 0) {
            setExhausted(false);
          } else {
            setPagination(response.data.pageCount);
            setData(response.data.activeJobsOne);
            setLoading(false);
            setDataDup(response.data.activeJobsOne);
            setQuestion(response.data.job);
          }
        }
      })
      .catch((err) => err.error);
  };
  const [savedInterviews, setSavedInterviews] = useState([]);
  const [userApppliedJob, setUserAppliedJob] = useState([]);

  const toggleFavorite = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-manage-favourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => [
          ...prevSavedInterviews,
          id,
        ]);
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const toggleUpdateFavoriteOne = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-unfavourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(id);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== id
            );
          } else {
            return [...prevSavedInterviews, id];
          }
        });
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  useEffect(() => {
    let s = document.cookie.split(";");
    if (s.length > 0) {
      for (let i = 0; i < s.length; i++) {
        if (!(s[i].indexOf("alreadyLoggedIn=") !== -1)) {
          document.cookie = `alreadyLoggedIn=true;`;
        }
      }
    }
    getAllJob();
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const handleFunctionClose = async () => {
    const url = `${process.env.PUBLIC_URL}/xakal/apply-submit/${selectedId}/${userId.userId}`;

    try {
      const response = await axios.put(url);

      if (response.status === 200) {
        // Retrieve and update applied jobs in localStorage
        const appliedJobs =
          JSON.parse(localStorage.getItem("appliedJobs")) || [];
        localStorage.setItem(
          "appliedJobs",
          JSON.stringify([...appliedJobs, selectedId])
        );

        // Update the local state with the selected job
        setUserAppliedJob((prevSavedInterviews) => [
          ...prevSavedInterviews,
          selectedId,
        ]);

        // Show success message
        toast.success("Job Applied Successfully...");
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating item:", error);
    } finally {
      // Close the modal and reset the active card
      setOpen3(false);
      setActiveCard(null);
    }
  };
  const handleApplyClick = (id, questionList, applyThrough, urls) => {
    if (applyThrough === "companySite") {
      window.open(urls, "_blank");
    } else {
      setSelectedId(id); // Set the selected id in the state

      if (
        Array.isArray(questionList) &&
        questionList.length > 0 &&
        questionList.some((q) => q.questionName.trim() !== "")
      ) {
        setOpen(true);
        setSelectedQuestionList(questionList);
      } else {
        setOpen2(true);
      }
    }
  };

  const [selectedQuestionList, setSelectedQuestionList] = useState([]);

  const [formData, setFormData] = useState({
    answers: [], // Store answers as an array of objects
  });

  const handleInputChange = (e, questionId) => {
    const newAnswers = { ...formData.answers };
    newAnswers[questionId] = { questionId, answer: e.target.value };
    setFormData((prevData) => ({ ...prevData, answers: newAnswers }));
  };
  const closeAnswer = () => {
    setOpen(false);
    setActiveCard(null);
    setFormData({
      answers: [], // Resetting the answers array to an empty array
    });
  };
  const checkUnansweredMandatoryQuestions = () => {
    const unansweredMandatoryQuestions = selectedQuestionList.filter(
      (question) => {
        const answer = formData.answers[question._id];
        const isMandatory = question.isMandatory === true;
        const isStarred = question.isStarred === true;
        const condition1 = isMandatory && isStarred;
        const condition2 = isMandatory && !isStarred;
        const isUnanswered = !answer || answer.answer.trim() === "";
        return (condition1 || condition2) && isUnanswered;
      }
    );

    return unansweredMandatoryQuestions;
  };
  const handleSubmitOne = async () => {
    try {
      // Create an array of answers based on the questions
      const answers = sortedQuestions.map((question) => ({
        questionId: question._id,
        answer: formData.answers[question._id]?.answer || "",
      }));

      const unansweredMandatoryQuestions = checkUnansweredMandatoryQuestions();

      if (unansweredMandatoryQuestions.length > 0) {
        toast.error("Please answer all mandatory questions.");
      } else {
        axios
          .post(`${process.env.PUBLIC_URL}/xakal/add-answer/${selectedId}`, {
            answers: answers, // Send the answers array
          })
          .then(() => {
            setFormData({
              ...formData,
              answers: Array(formData.answers.length).fill(""),
            });
          })
          .catch((err) => {
            console.error(err);
          });

        // Handle the response here as needed
        setOpen(false);
        setOpen2(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [file, setFile] = useState(null);
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();

  const formattedYear = currentYear.toString();

  const handleCancel = () => {
    // Clear the selected file and any error messages
    setFile(null);
    setOpen2(false);
    setActiveCard(null);
  };
  const handleFileChnage = (event) => {
    const selectedFile = event.target.files[0];
    const fileSizeLimit = 2 * 1024 * 1024; // 2MB
    const allowedFileTypes = ["pdf", "doc", "docx"];

    if (
      selectedFile &&
      selectedFile.size <= fileSizeLimit &&
      allowedFileTypes.some((type) =>
        selectedFile.name.toLowerCase().endsWith(type)
      )
    ) {
      setFile(selectedFile);
      setError2("");
      setError3("");
    } else {
      // Display an error message or take any other action
      setFile(null);
      setError2("File size should not exceed 2mb and PDF,");
      setError3("Word doc only allowed.");
    }
  };

  const handlePreview = () => {
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (["pdf", "doc", "docx"].includes(fileExtension)) {
        return (
          <div>
            <embed
              src={URL.createObjectURL(file)}
              type={file.type}
              width="80px"
              height="50px"
            />
          </div>
        );
      } else {
        return <p>File type not supported for preview</p>;
      }
    }
  };
  const handlePreviews = () => {
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (["pdf", "doc", "docx"].includes(fileExtension)) {
        return (
          <div>
            <embed
              src={URL.createObjectURL(file)}
              type={file.type}
              width="80px"
              height="50px"
            />
          </div>
        );
      } else {
        return <p>File type not supported for preview</p>;
      }
    }
  };

  const handleSubmitTwo = async () => {
    if (error2 === "") {
      setLoading(true); // Set loading state to true

      const formData1 = new FormData();
      formData1.append("image", file);

      try {
        const res = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData1
        );

        if (res.data) {
          const params = {
            url: res.data.url ? res.data.url : "",
          };

          // Send the application request
          await axios.post(
            `${process.env.PUBLIC_URL}/xakal/apply/${selectedId}`,
            params
          );

          // Close the modals and fetch updated data
          setOpen2(false);
          setOpen3(true);
          fetchData();
        }
      } catch (error) {
        console.error("Error updating item:", error);
        // Optionally set some error state to alert the user here
      } finally {
        setLoading(false); // Set loading state to false on completion
      }
    } else {
      setError2("File size should not exceed 2mb and PDF");
      setError3("Word doc only allowed.");
    }
  };
  const [dataOne, setDataOne] = useState();
  const [dataOneResume, setDataOneResume] = useState();

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/jobs/${selectedId}/applicants/questions`
      );
      const questionList = response.data.job.questionList;

      // Sort the question list based on 'starred' property
      const sortedQuestionList = questionList.sort(
        (a, b) => b.starred - a.starred
      );

      // Update state with the fetched and sorted data
      setDataOne(sortedQuestionList);
      setDataOneResume(response.data);

      // Close modals
      setOpen2(false);
      setOpen3(true);
    } catch (err) {
      console.error("Error fetching data:", err);
      // Optionally, you could set some error state here to inform the user
    }
  };
  const handleWithdrawClick = async (jobId) => {
    try {
      // Send a PUT request to withdraw the job
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/withdraw/${jobId}`
      );

      if (response.status === 200) {
        setUserAppliedJob((prevUserAppliedJob) => {
          return prevUserAppliedJob.filter((id) => id !== jobId);
        });

        const appliedJobs =
          JSON.parse(localStorage.getItem("appliedJobs")) || [];
        const updatedAppliedJobs = appliedJobs.filter((id) => id !== jobId);
        localStorage.setItem("appliedJobs", JSON.stringify(updatedAppliedJobs));

        setUserApplied(false);
        setFavouriteApplied(false);

        setOpen4(false);
        toast.success("Job withdrawn successfully");
      } else {
        console.error("Failed to withdraw job");
      }
    } catch (error) {
      console.error("Error withdrawing job:", error);
    }
  };

  const getUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/getuser`
      );
      setUser(response.data); // Set user data from the response
    } catch (err) {
      console.error("Error fetching user data:", err); // Log the error
      // Optionally, you could set an error state here to inform the user
    }
  };
  useEffect(() => {
    getUser();
  }, []);
  // eslint-disable-next-line no-unused-vars
  const [searchKeyword, setSearchKeyword] = useState("");

  const [jobWithdrawId, setWithdrawIdToDelete] = useState(null);
  const sortedQuestions = selectedQuestionList.slice().sort((a, b) => {
    if (a.isMandatory && a.isStarred && (!b.isMandatory || !b.isStarred)) {
      return -1; // A comes before B if A is both mandatory and starred, and B is not
    } else if (
      b.isMandatory &&
      b.isStarred &&
      (!a.isMandatory || !a.isStarred)
    ) {
      return 1; // B comes before A if B is both mandatory and starred, and A is not
    } else if (
      !a.isMandatory &&
      a.isStarred &&
      (b.isMandatory || !b.isStarred)
    ) {
      return -1; // A comes before B if A is not mandatory but starred, and B is mandatory or not starred
    } else if (
      !b.isMandatory &&
      b.isStarred &&
      (a.isMandatory || !a.isStarred)
    ) {
      return 1; // B comes before A if B is not mandatory but starred, and A is mandatory or not starred
    } else {
      return 0; // No change in order for other cases
    }
  });

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [recentsearchOpen, setRecentSearchOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const handleSave = (data) => {
    setPopupOpen(false); // Close the dialog if needed
  };
  const [openSetting, setOpenSetting] = useState(false);
  const handleWithdrawPopUpClick = async (jobId) => {
    try {
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/withdraw/${jobId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setUserAppliedJob((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(jobId);
          // Toggle the jobId in the list
          if (index !== -1) {
            // If jobId exists, remove it from the list
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== jobId
            );
          } else {
            // If jobId doesn't exist, add it to the list
            return [...prevSavedInterviews, jobId];
          }
        });
      } else {
        console.error("Failed to toggle favorite status");
      }

      setOpen5(false); // Close the popup
    } catch (error) {
      console.error("Error withdrawing job:", error);
    }
  };
  const [open5, setOpen5] = useState(false);
  const togglePopUpFavorite = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-manage-favourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => [
          ...prevSavedInterviews,
          id,
        ]);
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const toggleUpdatePopFavoriteOne = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-unfavourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(id);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== id
            );
          } else {
            return [...prevSavedInterviews, id];
          }
        });
      } else {
        console.error("Failed to toggle favorite status");
      }
      setActiveCard(null);
      // setIsOpen(false);
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const [userApplied, setUserApplied] = useState(false);
  const [favouriteApplied, setFavouriteApplied] = useState(false);
  const [dataValue, setDataValue] = useState();
  const getOneDetail = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      );
      setDataValue(response.data.aboutOne); // Set the data value from the response
    } catch (error) {
      console.error("Error fetching detail:", error); // Log the error
    }
  };
  useEffect(() => {
    getOneDetail();
  }, []);
  const [activeCard, setActiveCard] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const openPopup = (jobData) => {
    setSelectedJobData(jobData);
    setActiveCard(jobData._id);
    setShowMore(false);
    const applicant = jobData.applicants.find(
      (applicant) => applicant.userId === userId.userId
    );
    const userApplied =
      (applicant && applicant.statusOne === "Applied") ||
      (applicant && applicant.statusOne === "shortlist") ||
      (applicant && applicant.statusOne === "engage") ||
      (applicant && applicant.statusOne === "interviewed") ||
      (applicant && applicant.statusOne === "offered");

    const favourite = jobData.favoritedBy.find(
      (fav) => fav.userId === userId.userId
    );
    const favouriteApplied =
      favourite && favourite.statusFavourite === "favourites";

    setUserApplied(userApplied);
    setFavouriteApplied(favouriteApplied);

    setIsOpen(true);
  };
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <span>{timeAgo}</span>;
  };
  useEffect(() => {
    document.title = "Jobs | Xakal";
  }, []);
  
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [questionOne, setQuestionOne] = useState();
  const [openApplyThrough, setApplyThrough] = useState();
  const [url, setUrl] = useState();
  const [jobApply, setJobApply] = useState(false);

  const { state } = useLocation();
  const isCardActive = state?.isCardActive;
  const openNewJob = state?.openNewJob;
  const openTime = state?.id;
  const openFormVaue = state?.openTrue;
  const openValue = state?.oneTrue;
  const datas = state?.selectedData;
  useEffect(() => {
    if (openValue === true) {
      setIsOpen(openFormVaue);
      setActiveCard(openTime);
      setSelectedJobData(datas);
    }
  }, []);
  useEffect(() => {
    if (state && state.jobApply) {
      setOpenSuggestion(true);
      setJobApply(state.jobApply);
      setJobId(state.jobId);
      closeSuggestion(state.jobId, questionOne, openApplyThrough, url);
    }
  }, [state]);

  const closeSuggestion = (id, question, openApply, urls) => {
    setOpenSuggestion(false);
    handleApplyClick(id, question, openApply, urls);
  };

  // eslint-disable-next-line no-unused-vars
  const [isScrolled, setIsScrolled] = useState(false);

  const [pendingFeedbackData, setPendingFeedback] = useState([]);
  const getAllPending = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-pending-feedback`
      );
      setPendingFeedback(response.data.book); // Set the pending feedback data
    } catch (error) {
      console.error("Error fetching pending feedback:", error); // Log the error
    }
  };
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const provideFeedback = () => {
    history.push("/portal/pending-feedback-interview");
  };
  const [openPending, setOpenPending] = useState(false);
  useEffect(() => {
    getAllPending();
  }, []);
  useEffect(() => {
    if (pendingFeedbackData.length > 0) {
      setOpenPending(true);
    }
  }, [pendingFeedbackData]);
  ///calling state value

  return (
    <div>
      <ToastContainer />
      <div className={openSuggestion ? "custom-sugg" : ""}>
        <CustomHeaderLayout
          activeStates={isCardActive}
          openNewPopup={openNewJob}
          isOpen={isOpen}
        />
      </div>

      {openSuggestion ? (
        <>
          <ExploreInterview
            redirect={jobApply}
            jobId={jobId}
            onClose={() => {
              closeSuggestion(jobId, questionOne, openApplyThrough, url);
              setActiveCard(null);
            }}
          />
        </>
      ) : (
        <div className={isOpen ? "job-card-app-wraps job-card-app-wrap" : "job-card-app-wrap"}>
          {loading ? 
          (
            <div style={{ top: "160px", position: "relative" }}>
              {Array.from({ length: 4 }).map((_, index) => (
                <ShimmerLoading key={index} />
              ))}
            </div>
          ) : data && data?.length > 0 ? (
            <InfiniteScroll
              dataLength={data.length}
              next={getAllJob}
              hasMore={exhausted}
              endMessage={
                <div className="job-info-conts">
                  <div>Update your profile for tailored job suggestions!</div>
                  <div className="job-info-cont">
                    <p className="job-info-cont-head">
                      Update your profile to receive job suggestions that match
                      your skills and interests.
                    </p>
                    <button
                      className="button2"
                      onClick={() => history.push("/portal/my-profile")}
                    >
                      Update my profile
                    </button>
                  </div>
                  <b className="job-info-foot">Yay! You have seen it all</b>
                </div>
              }
            >
              <div
                className="job-card-container"
                // style={{ paddingBottom: "30px" }}
              >
                {data &&
                  data.length &&
                  data
                    .filter((i) => {
                      // Add your filtering logic here for 'data'
                      return (
                        i.status === "active" && // Filter by status (e.g., only 'active')
                        i.jobTitle
                          .toLowerCase()
                          .includes(searchKeyword.toLowerCase()) // Filter by job title
                      );
                    })
                    .map((i) => {
                      const showJob =
                        !i.scheduleDate ||
                        new Date() >= new Date(i.scheduleDate);

                      if (!showJob) {
                        return null;
                      }
                      const applicant = i.applicants.find(
                        (applicant) => applicant.userId === userId.userId
                      );

                      // eslint-disable-next-line no-unused-vars
                      const userApplied =
                        applicant &&
                        (applicant.statusOne === "Applied" ||
                          applicant.statusOne === "shortlist" ||
                          applicant.statusOne === "engage" ||
                          applicant.statusOne === "interviewed" ||
                          applicant.statusOne === "offered");
                      const favourite = i.favoritedBy.find(
                        (fav) => fav.userId === userId.userId
                      );

                      const favouriteApplied =
                        favourite && favourite.statusFavourite === "favourites"
                          ? true
                          : false;

                      return (
                        <div className="int-rows">
                          <CardWrapper
                            className={
                              isOpen ? "job-card-wrap" : "job-card-wrapper"
                            }
                            onClick={() => openPopup(i)}
                          >
                            <div
                              className={
                                activeCard === i._id ? "underline" : ""
                              }
                            ></div>
                            <div>
                              <ContentWrapper
                                className="job-content-wrapper"
                                onClick={() => openPopup(i)}
                              >
                                <div
                                  className="company-logo-container"
                                  onClick={() => openPopup(i)}
                                >
                                  {i.companyName &&
                                  i.companyName.companyLogo ? (
                                    <img
                                      src={
                                        i.companyName &&
                                        i.companyName.companyLogo
                                      }
                                      alt="img"
                                      className="company-logo"
                                      onClick={() => openPopup(i)}
                                    />
                                  ) : (
                                    <img
                                      src={Image}
                                      alt="img"
                                      className="company-logo c-logo"
                                      onClick={() => openPopup(i)}
                                    />
                                  )}
                                </div>
                                <Wrapper
                                  className="job-title-wrapper"
                                  onClick={() => openPopup(i)}
                                >
                                  <JobTitle
                                    key={i._id}
                                    onClick={() => openPopup(i)}
                                    className="job-title"
                                  >
                                    {i.jobTitle}
                                  </JobTitle>

                                  <companyTitle className="company-title" onClick={()=>history.push({pathname:`/portal/other-company-view/${i.companyName?.comID}`})} style={{ cursor: "pointer" }}>
                                    {i.companyName && i.companyName.value}
                                  </companyTitle>
                                  
                                </Wrapper>
                                <DateWrapper className="bookmark-btn-container date-toggle-wrappers">
                                  {favouriteApplied ||
                                  savedInterviews.includes(i._id) ? (
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleUpdateFavoriteOne(i._id);
                                      }}
                                    >
                                      <img
                                        src={iBookmarkActive}
                                        className="bookmark-icon"
                                        alt="icon"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleFavorite(i._id);
                                      }}
                                    >
                                      <img
                                        src={iBookmark}
                                        className="bookmark-icon"
                                        alt="icon"
                                      />
                                    </div>
                                  )}
                                  <p
                                    className="timestamp"
                                    onClick={() => openPopup(i)}
                                  >
                                    <TimeAgo
                                      timestamp={i.timestamps?.createdAt}
                                    />
                                  </p>
                                </DateWrapper>
                              </ContentWrapper>
                              <HrLines className="" />
                              <div onClick={() => openPopup(i)}>
                                <div className="job-details job-details-card">
                                  <IconTitle className="icon-title">
                                    <img
                                      src={iExp}
                                      className="jt-icon"
                                      alt="icon"
                                    />
                                    <span>
                                      {" "}
                                      Exp{" "}
                                      {i.experienceRequired2
                                        ? `${i.experienceRequired} - ${i.experienceRequired2} yrs`
                                        : i.experienceRequired
                                          ? `${i.experienceRequired} years`
                                          : ""}{" "}
                                    </span>
                                  </IconTitle>

                                  <IconTitle className="icon-title">
                                    <img
                                      src={iSalary}
                                      className="jt-icon"
                                      alt="icon"
                                    />
                                    <span>{`${i.salaryFrom}L - ${i.salaryTo}L`}</span>
                                  </IconTitle>
                                  <IconTitle className="icon-title">
                                    <img
                                      src={iLocation}
                                      className="jt-icon"
                                      alt="icon"
                                    />
                                    <span>
                                      {i.location?.substring(0, 7)}
                                      {i.location?.length > 7 ? "..." : ""}
                                    </span>
                                  </IconTitle>

                                  <IconTitle className="icon-title">
                                    <img
                                      src={iHome}
                                      className="jt-icon"
                                      alt="icon"
                                    />
                                    <span>{i.workMode}</span>
                                  </IconTitle>
                                </div>
                              </div>
                            </div>
                          </CardWrapper>
                        </div>
                      );
                    })}
              {
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    minHeight: "50px",
                  }}
                >
                  <Spinner />
                </div>
              }
              </div>
            </InfiniteScroll>
          ) : (
            <img src={noData} className="nodata-center" alt="icon" />
          )}
          {isOpen && selectedJobData ? (
        
        <div className="job-app-wraps job-app-wrap">
          <ViewJobApplicant
            jobData={selectedJobData}
            showMore={showMore}
            setShowMore={()=>setShowMore(!showMore)}
            setIsOpen={() => {
              setIsOpen(false);
              setActiveCard(null);
            }}
          />
          <div className="job-prev-btn">
            <div>
              {favouriteApplied ||
              savedInterviews.includes(selectedJobData._id) ? (
                <>
                  <img
                    src={iBookmarkActive}
                    alt="img"
                    style={{
                      margin: "0px 10px",
                    }}
                    onClick={() =>
                      toggleUpdatePopFavoriteOne(selectedJobData._id)
                    }
                  />
                </>
              ) : (
                <img
                  src={iBookmark}
                  alt="img"
                  style={{
                    margin: "0px 10px",
                    }}
                  onClick={() => togglePopUpFavorite(selectedJobData._id)}
                />
              )}
            </div>
            <div>
              {userApplied || userApppliedJob.includes(selectedJobData._id) ? (
                <>
                  <button
                    className="button3"
                    onClick={() => {
                      setWithdrawIdToDelete(selectedJobData._id);
                      setOpen4(true); // Open the modal when Delete is clicked
                    }}
                  >
                    Withdraw
                  </button>
                </>
              ) : (
                <button
                  className="job-btn"
                  onClick={() => {
                    setQuestionOne(selectedJobData.questionList);
                    setApplyThrough(selectedJobData.applyThrough);
                    setUrl(selectedJobData.urls);
                    setOpenSuggestion(true);
                    setJobId(selectedJobData?._id);
                    setIsOpen(false);
                  }}
                >
                  Apply now
                </button>
              )}
            </div>
          </div>
        </div>
        
          ) : (
          ""
          )}
        </div>
      )}

      <Dialog
        open={isPopupOpen}
        PaperProps={{
          style: {
            width: "100%",
            height: "fit-content",
            borderRadius: "24px",
            maxWidth: "430px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span className="mTitle">Jo</span>
          <img src={CloseIcon} alt="img" onClick={() => setPopupOpen(false)} />
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <Fitme onSave={handleSave} />
        </DialogContent>
      </Dialog>
      
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { width: "430px", borderRadius: "20px" } }}
      >
        <DialogTitle className="modal-title">
          <PopUpTitle className="modal-title-sub">
            Assessment Questions
          </PopUpTitle>
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <div className="modalBody">
            {sortedQuestions
              .filter((question) => question.questionName.trim() !== "")
              .map((question, index) => (
                <div key={question.questionId} style={{ marginTop: "20px" }}>
                  <div>
                    <QuestionLabel htmlFor={`answer_${question._id}`}>
                      {index + 1}. {question.questionName}
                      {question.isMandatory && (
                        <span className="mandatory-star">*</span>
                      )}
                    </QuestionLabel>

                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        id={`answer_${question._id}`}
                        name={`answer_${question._id}`}
                        value={
                          formData.answers[question._id]
                            ? formData.answers[question._id].answer
                            : ""
                        }
                        onChange={(e) => handleInputChange(e, question._id)}
                        style={{
                          borderRadius: "5px",
                          background: "rgba(54, 42, 65, 0.04)",
                          height: "48px",
                        }}
                        maxLength={250}
                      />
                    </InputGroup>
                  </div>
                </div>
              ))}
          </div>
          <div className="modalFooter">
            <button className="button5" onClick={closeAnswer}>
              Cancel
            </button>
            <button
              className="button3"
              type="submit"
              onClick={() => handleSubmitOne(selectedId)}
            >
              Next
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open2}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { width: "430px", borderRadius: "20px" } }}
      >
        <DialogTitle className="modal-title">
          <PopUpTitle className="modal-title-sub">
            Document Submission
          </PopUpTitle>
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <div className="modalBody" style={{ padding: "0px 20px" }}>
            <PopUpTitle style={{ marginLeft: "0" }}>Resume</PopUpTitle>

            <InputGroup className="mb-3">
              <label
                for="file-upload"
                className="custom-file-upload"
                onChange={handleFileChnage}
              >
                <Form.Control
                  // className="custom-file-upload"
                  id="file-upload"
                  type="file"
                />
                {file === null ? (
                  <div style={{ marginLeft: "0px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <img src={Upload} alt="img" />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        padding: "0px",
                        marginTop: "10px",
                      }}
                    >
                      <FileTypeInfos>
                        <span>*</span>Upload file size should be less than 2mb
                        (File type: Pdf, Doc)
                      </FileTypeInfos>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "60px",
                      alignItems: "center",
                    }}
                  >
                    <FileTypeInfo
                      style={{
                        // bottom: "77px",
                        borderRadius: "8px",
                        border: "1px solid #FFF",
                        width: "100px",
                        height: "65px",
                        padding: "5px 10px",
                        background:
                          "linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      {handlePreview()}
                    </FileTypeInfo>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={Upload}
                        alt="img"
                        style={{ marginTop: "-30px" }}
                      />
                    </div>
                  </div>
                )}
              </label>
            </InputGroup>
            <div className="text-danger f12" style={{ marginLeft: "10px" }}>
              {error2}
            </div>

            <div className="text-danger f12" style={{ marginLeft: "10px" }}>
              {error3}
            </div>
          </div>
          <div className="modalFooter" style={{ marginTop: "10px" }}>
            <button
              className="button5"
              style={{ height: "40px" }}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              onClick={handleSubmitTwo}
              disabled={!file || loading} // Disable the button when loading is true
              className={`button3 ${loading ? "loading" : ""}`}
              style={{ height: "43px", width: "100px" }}
            >
              {loading ? "Loading..." : "Next"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open3}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { width: "440px", borderRadius: "20px" } }}
      >
        <div className="modalHeader">
          <span className="mTitle">Application Preview</span>
        </div>
        <HrLine />
        <DialogContent>
          <div className="modalBody">
            <div
              style={{
                borderBottom: "1px solid #F4F4F6",
                paddingBottom: "10px",
              }}
            >
              {dataOne ? (
                <>
                  {dataOne &&
                    dataOne.map((question, index) => {
                      const userAnswers = question.answers.filter(
                        (answer) => answer.userId === userId.userId
                      );

                      // Check if there are any user answers
                      if (userAnswers.length > 0) {
                        const lastUserAnswer =
                          userAnswers[userAnswers.length - 1];

                        return (
                          <>
                            <PopUpTitles>{`${index + 1}. ${
                              question.questionName
                            }`}</PopUpTitles>
                            <div>
                              <PopUpTitle
                                style={{
                                  fontweight: "normal",
                                  marginBottom: "10px",
                                }}
                              >{`Answer: ${
                                lastUserAnswer.answer || "No answer provided"
                              }`}</PopUpTitle>
                            </div>
                          </>
                        );
                      }
                    })}
                </>
              ) : (
                ""
              )}
            </div>

            <div style={{ position: "relative", marginTop: "20px" }}>
              <PopUpTitle>Documents Submitted</PopUpTitle>

              <div className="doc-submit-row">
                <FileTypeInfo
                  style={{
                    top: "2px",
                    left: "3px",
                    borderRadius: "8px",
                    border: "1px solid #FFF",
                    width: "100px",
                    height: "65px",
                    padding: "5px 10px",
                    background:
                      "linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {handlePreviews()}
                </FileTypeInfo>
                <a
                  href={
                    dataOneResume ? dataOneResume.resume : "No resume provided"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <PopUpTitle
                    className="doc-title"
                    style={{ fontweight: "normal", marginTop: "35px" }}
                  >{`${dataValue?.firstName && dataValue?.firstName} ${
                    dataValue?.lastName && dataValue?.lastName
                  }_resume_${formattedYear}`}</PopUpTitle>
                </a>
              </div>
            </div>
          </div>
          <div className="modalFooter" style={{ marginTop: "20px" }}>
            <button className="button5" onClick={() => setOpen3(false)}>
              Cancel
            </button>
            <button
              className="button3"
              type="submit"
              onClick={handleFunctionClose}
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={recentsearchOpen}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { borderRadius: "20px", position: "absolute", top: "80px" },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          {/* <PopUpTitle>Document Submission</PopUpTitle> */}
          <AiOutlineCloseCircle onClick={() => setRecentSearchOpen(false)} />
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <RecentSearch />
        </DialogContent>
      </Dialog>

      <Dialog
        open={searchOpen}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { borderRadius: "20px", position: "absolute", top: "80px" },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          <AiOutlineCloseCircle onClick={() => setSearchOpen(false)} />
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <SearchDetails />
        </DialogContent>
      </Dialog>

      <Dialog
        open={open4}
        halfWidth
        maxWidth="md"
        className="def"
        PaperProps={{
          style: {
            borderRadius: "8px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "rgba(255, 218, 224, 1)",
            padding: "5px 8px",
            // height: "56px",
            overflow: "hidden",
          },
        }}
      >
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            fontFamily: "Inter-Medium",
          }}
        >
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "8px",
                gap: " 8px",
                background: "white",
              }}
            >
              <img
                src={DeleteImg}
                style={{ marginTop: "2px", marginLeft: "9px" }}
                alt="img"
              />
            </div>
            Are you sure you want to withdraw ?
          </JobTitle>
          <DialogActions>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button4
                onClick={() => setOpen4(false)}
                style={{ cursor: "pointer" }}
              >
                No Thanks
              </Button4>
              <Button6 onClick={() => handleWithdrawClick(jobWithdrawId)}>
                Proceed
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          className: "setting-modal",
        }}
        className="modal-container"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Settings open={() => setOpenSetting(false)} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={open5}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle className="modal-title">
          <PopUpTitle className="modal-title-sub">
            Application withdrawal
          </PopUpTitle>
          <AiOutlineCloseCircle onClick={() => setOpen5(false)} />
          <img src={CloseIcon} alt="img" onClick={() => setOpen5(false)} />
        </DialogTitle>
        <DialogContent>
          <Text className="modal-b-title">
            Are you sure you want to withdraw your Application?
          </Text>

          <DateWrapperButton>
            <Button5
              className="button5"
              onClick={() => setOpen5(false)}
              style={{ cursor: "pointer" }}
            >
              No
            </Button5>
            <Button3
              className="button3"
              onClick={() => handleWithdrawPopUpClick(jobWithdrawId)}
            >
              Yes
            </Button3>
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Text className="modal-b-title">
            Are You Sure You Want To Log Out?
          </Text>

          <DateWrapperButton>
            <button className="button5" onClick={() => setSuccess(false)}>
              No
            </button>
            {user ? (
              <button
                className="button3"
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </button>
            ) : (
              <button className="button3" onClick={logout}>
                Yes
              </button>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ApplyJob;
