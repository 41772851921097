/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./manageSwitch.css";
import AvailabilityTimePicker from "../MyProfile/availabilty";
import axios from "axios";
import CloseIcon from "../../images/CloseIcon.svg";
import noData from "../../images/NoData.svg";
import { FaRupeeSign } from "react-icons/fa";
import styled from "styled-components";
import Edit from "../../images/icons/edit-btn-2.svg";
import Delete from "../../images/icons/delete-btn.svg";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { Dialog, DialogContent } from "@mui/material";
import UpdateServicePopup from "../MyProfile/updateServicePopup";
import PreviewPopup from "../MyProfile/previewPopup";
import { toast } from "react-toastify";
import ShimmerLoading from "../interview/shimmerloading";
import StatusPreview from "../MyProfile/statusPreview";
import Common from "../../utils/common";
import Document from "../../images/Document Add.svg";
import Pause from "../../images/solar--pause-bold.svg";

const JobPreference = styled.div`
  // color: black;
  background: #f4e8ff;
  padding: 5px 10px;
  height: 30px;
  border-radius: 5px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  margin-top: 12px;
  width: fit-content;
`;
const PreferenceWrapper = styled.div`
  display: flex;
  gap: 20px;
  border-top: 1px solid grey;
  span {
    position: relative;
    top: 0px;
    cursor: pointer;
  }
  @media (max-width: 600px) {
    display: block;
  }
`;
// eslint-disable-next-line no-unused-vars
const Preference = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: capitalize;
  position: relative;
  top: 5px;
`;
const Designation = styled.div`
  color: #a8a1ac;
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin: 20px 0 0px 0;
`;
const Title = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  // text-align: center;
  color: #362a41;
  margin-top: 2px;
`;
const IconContainer = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
  margin: 1rem;
  img {
    cursor: pointer;
    width: 30px; /* Adjust the width as needed */
    height: 30px; /* Adjust the height as needed */
  }
`;
const ManageSwitch = ({
  input,
  experience,
  education,
  accountDetail,
  handleSection,
  checkActive = "active",
  handleSkill,
  enableService,
  inactives,
  opens,
  condition,
  cond,
  loadings,
  onService,
  datas,
}) => {
  //getting availabilty of the interviewer
  const [statusPreview, setStatusPreview] = useState(false);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [data, setData] = useState([]);
  const [editedData, setEditedData] = useState(null);
  const [previewForm, setPreviewForm] = useState(false);
  const [previewId, setPreviewId] = useState("");
  const [openServiceUpdate, setOpenServiceUpdate] = useState(false);
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isManageInterviewPage, setIsManageInterviewPage] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const getAllAvialability = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-availability`
      );
      setAvailabilityData(response.data.interview);
      if (
        Array.isArray(response.data.interview) &&
        response.data.interview.length === 0
      ) {
        // Open create popup
        setEditId(null);
      } else {
        const idToEdit = response.data.interview[0]?._id;
        setEditId(idToEdit);
      }
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  useEffect(() => {
    getAllAvialability();
  }, []);
  //getallinterviewService

  // useEffect(() => {
  //   setData(datas);
  //   setLoading(loadings);
  //   onService();
  // }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-interview-manage`
      );
      if (response.data) {
        const newJobs = response.data.interview;
        setData(newJobs);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Initial data fetch
    fetchData();

    // Setup interval if the condition is true
    let intervalId;
    if (cond) {
      setShouldFetch(true);
      intervalId = setInterval(() => {
        fetchData();
      }, 30000); // Fetch every 30 seconds or adjust the interval as needed
    } else {
      setShouldFetch(false);
    }

    // Cleanup interval on component unmount or condition change
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [condition]); // Re-run effect when `condition` changes
  //editing the interview
  const editOpen = (id) => {
    setOpenServiceUpdate(true);
    setEditedData(id);
  };
  const closeServiceUpdate = () => {
    setOpenServiceUpdate(false);
  };
  const previewFormOpen = (id) => {
    setPreviewForm(true);
    setPreviewId(id);
  };
  const previewFormId = () => {
    setPreviewForm(true);
  };
  const handleCloses = () => {
    setPreviewForm(false);
    onService();
  };
  const goToService = (id) => {
    setOpenServiceUpdate(true);
    setPreviewForm(false);
    setPreviewId(id);
  };
  const previewClose = () => {
    onService();

    setPreviewForm(false);
  };
  const updateArchiveInterview = async (interviewId) => {
    try {
      // Make a request to your backend to update the interview status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-archive-interview/${interviewId}`
      );

      // Check if the update was successful
      if (response.data.job) {
        const updatedData = data.map((interview) => {
          if (interview._id === interviewId) {
            return { ...interview, status: "archived" };
          }
          return interview;
        });
        onService();
        setData(updatedData);
        toast.success("Interview Archived Successfully");
      } else {
        // Handle the case where the update was not successful
        console.error("Failed to update interview status.");
      }
    } catch (error) {
      console.error("Error updating interview status:", error);
    }
  };
  const updateArchiveInterviewStatus = async (interviewId) => {
    try {
      // Make a request to your backend to update the interview status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-archive-interview-status/${interviewId}`
      );

      // Check if the update was successful
      if (response.data.job) {
        const updatedData = data.map((interview) => {
          if (interview._id === interviewId) {
            return { ...interview, status: interview.previousStatus };
          }
          return interview;
        });
        onService();
        const status = response.data.job?.status;
        setData(updatedData);
        toast.success(`Interview ${status} Successfully`);
      } else {
        // Handle the case where the update was not successful
        console.error("Failed to update interview status.");
      }
    } catch (error) {
      console.error("Error updating interview status:", error);
    }
  };
  const deleteInterviewJob = async (jobId) => {
    try {
      // Make a DELETE request to the delete service endpoint
      const response = await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/get-delete-interview/${jobId}`
      );

      // Update the state to reflect the changes (remove the deleted job from the list)
      setData(data.filter((item) => item._id !== jobId));
      toast.success("Interview Deleted Successfully...");
      onService();
    } catch (error) {
      console.error("Error deleting archived job:", error);
    }
  };
  useEffect(() => {
    if (window.location.href.includes("/portal/interview/manage-interview")) {
      setIsManageInterviewPage(true);
    } else {
      setIsManageInterviewPage(false);
    }
  }, [window.location.href]);
  const remove = () => {
    localStorage.removeItem("open");
    setStatusPreview(false); // Reset status preview
  };

  const handleClose = () => {
    setStatusPreview(false);
  };
  const [accountDetails, setAccountDetail] = useState(accountDetail);
  const getOneAccount = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/account-get/${Common.loggedIn()}`
      );
      setAccountDetail(response.data.account);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  useEffect(() => {
    getOneAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="containers-switch">
      <div className="fixed-section">
        <div
          className="modalHeader"
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "white",
            borderTopRightRadius: "30px",
            borderTopLeftRadius: "30px",
          }}
        >
          <Title>Manage Availability</Title>
        </div>
        <AvailabilityTimePicker
          onId={editId}
          onGetAll={getAllAvialability}
          condition={condition}
          cond={cond}
          // trueManage={isManageInterviewPage}
        />
      </div>
      <div className="scrollable-section">
        {loading ? (
          <div style={{ paddingTop: "50px" }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <ShimmerLoading key={index} />
            ))}
          </div>
        ) : (
          <>
            {data && data.length > 0 ? (
              <>
                {data.map((i, index) => {
                  return (
                    <div key={index}>
                      <div className={"job-card-wrapper"}>
                        <div className="int-tit-save">
                          <div className="int-tit-dur-wrapper">
                            <div className="profile-name title">
                              <span className="pro-name">
                                {" "}
                                {i.interviewTitle?.substring(0, 20)}
                                {i.interviewTitle?.length > 20 ? "..." : ""}
                              </span>
                              {i.interviewTitle?.length > 20 ? (
                                <p className="prof-name">{i.interviewTitle}</p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              style={{
                                marginTop: "15px",
                                fontSize: "12px",
                                fontweight: "normal",
                                color: "rgba(168, 161, 172, 1)",
                                display: "flex",
                              }}
                            >
                              {i.inrService ? (
                                <div className="b-price">Free</div>
                              ) : (
                                <div className="b-price">
                                  <FaRupeeSign />
                                  {new Intl.NumberFormat("en-IN", {}).format(
                                    i?.inr
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <IconContainer>
                            <img
                              src={Edit}
                              alt="Edit"
                              onClick={() => editOpen(i?._id)}
                            />
                            <img
                              src={Delete}
                              alt="Delete"
                              onClick={() => deleteInterviewJob(i?._id)}
                            />
                            <div className="status-toggle">
                              {i.status === "pending" ? (
                                <Toggle
                                  checked={i.status === "pending"}
                                  className="pending"
                                  onChange={() =>
                                    updateArchiveInterview(i?._id)
                                  }
                                />
                              ) : i.status === "active" ? (
                                <Toggle
                                  checked={i.status === "active"}
                                  className="active"
                                  onChange={() =>
                                    updateArchiveInterview(i?._id)
                                  }
                                />
                              ) : i.status === "archived" ? (
                                <Toggle
                                  checked={i.status !== "archived"}
                                  className="archived"
                                  onChange={() =>
                                    updateArchiveInterviewStatus(i?._id)
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </IconContainer>
                        </div>

                        <div>
                          <div className={"doc-pend-wraps"}>
                            {input?.inputList?.length > 0 &&
                            input?.inputList[0].proficiency !== "" &&
                            input?.inputList2?.length > 0 &&
                            input?.inputList3[0]?.resume &&
                            experience?.length > 0 &&
                            education?.length > 0 &&
                            accountDetails?.length > 0 ? (
                              i?.status === "pending" ? (
                                <button
                                  data-title="Interview will be posted once verified"
                                  className="button8"
                                >
                                  Verification In-process
                                </button>
                              ) : i?.status === "active" ? (
                                <button className="button8-act">
                                  Interview Active
                                </button>
                              ) : (
                                ""
                              )
                            ) : (
                              <button
                                data-title="Click here to complete documentation"
                                className="button8"
                                style={{ color: "red" }}
                                onClick={() => {
                                  setStatusPreview(true);
                                }}
                              >
                                Complete documentation for verification
                                <img
                                  src={Document}
                                  alt="img"
                                  // className="btn-wrappers"
                                />
                              </button>
                            )}

                            {/* <div className="user_info">
                              Interview will be posted once verified
                              <button>Got it </button>
                            </div> */}
                          </div>
                        </div>

                        <div>
                          <PreferenceWrapper>
                            <Designation>Designation :</Designation>

                            <>
                              {i.designation.slice(0, 3).map((item, index) => (
                                <div key={index} className={"desig-list"}>
                                  {item}
                                </div>
                              ))}

                              {i.designation.length > 3 ? (
                                <JobPreference>
                                  +{i.designation.length - 3}
                                </JobPreference>
                              ) : (
                                ""
                              )}
                            </>
                          </PreferenceWrapper>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="manage-nodata">
                <img src={noData} alt="icon" />
              </div>
            )}
          </>
        )}
      </div>
      <Dialog
        open={openServiceUpdate}
        PaperProps={{
          style: {
            width: "100%",
            // height: "800px",
            borderRadius: "24px",
            maxWidth: "415px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        {/* <TitleHeadar> */}
        <div className="modalHeader">
          <Title>Update an Interview</Title>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setOpenServiceUpdate(false)}
          />
        </div>
        {/* </TitleHeadar> */}

        <DialogContent>
          <UpdateServicePopup
            onPopup={closeServiceUpdate}
            onService={onService}
            id={editedData ? editedData : null}
            previewId={previewFormOpen}
            onCloseAll={previewFormId}
            previewInterviewId={previewId}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={previewForm}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "570px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <PreviewPopup
            previewId={previewId}
            onCloser={handleCloses}
            onBack={goToService}
            onClose={
              // Ensure this is called when the popup closes
              previewClose // Optionally, if you need to close the preview as well
            }
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={statusPreview}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "570px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <div>
            <Title>Complete your profile</Title>
          </div>
          <div>
            <img src={CloseIcon} alt="img" onClick={remove} />
          </div>
        </div>
        <DialogContent>
          <StatusPreview
            userId={input?.userId}
            handleSection={() => handleSection()}
            handleSkill={handleSkill}
            handleClose={() => handleClose()}
            input={input}
            experiencePreview={experience}
            educationPreview={education}
            accountDetailPreview={accountDetail}
            openPreview={statusPreview}
            enableService={enableService}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ManageSwitch;
