import { useEffect } from 'react';
import Tick from "../images/landing/GreenTick.svg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../images/landing/logo.svg";
import "../styles/lp-style.css";

const Pricing = () =>{
  useEffect(() => {
    var w = window;
    var d = document;
    w.CollectId = w.CollectId = "65fa7a4fc3db31250ba1a43c";
    var h = d.head || d.getElementsByTagName("head")[0];
    var s = d.createElement("script");
    s.setAttribute("type", "text/javascript");
    s.async = true;
    s.setAttribute("src", "https://collectcdn.com/launcher.js");
    h.appendChild(s);
  }, [])

  const calendarlyClick = (url) => {
    window.open(url, "_blank");
  };
  
  // function scrollFunction() {
  //   if (document.body.scrollTop > 120 || document.documentElement.scrollTop > 120) {
  //     document.querySelector(".navbar").classList.add("scrolled");
  //   } else {
  //     document.querySelector(".navbar").classList.remove("scrolled");
  //   }
  // }
  // window.onscroll = function () {
  //   scrollFunction();
  // };
      const pageClick = () => {
        window.open("/login", "_blank");
      };
      
    return(
        <div className='wrapper'>
      <Navbar expand="lg" className="bg-body-tertiary ip-navbar">
          <Container className="align-items-center justify-content-between">
            <Navbar.Brand href="/">
              <img src={Logo} className="lp-m-logo" alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link href="/">Product</Nav.Link>
                <Nav.Link href="/pricing" >Pricing</Nav.Link>
                <Nav.Link onClick={() => calendarlyClick("https://calendly.com/support-c7gb/support-call")}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <div className="gt-btn-container">
              <button
                type="button"
                className="btn-t-primarie"
                onClick={pageClick}
              >
                Get started
              </button>
            </div>
          </Container>
        </Navbar>

      {/* <div className="ellipse-wrapper3">
        <img src={Ellipse3} alt="img" className="ellipse3" />
      </div> */}
        
        <div className="xw-container xw-price-wrapper">
        <h1 className="xw-main-title xw-main-title2">Our Pricing</h1>
        <div className="row price-wrapper">
            <div className="price-container">
              <div className="price-wrappers">
                <div className='price-trial'>TRIAL</div>
                <div className='price-free'>Free</div>
                <div className="price-card"><img src={Tick} alt="img" />7 days free trial</div>
                <div className="price-card"><img src={Tick} alt="img" />25 profile sourcing</div>
                <div className="price-card"><img src={Tick} alt="img" />1 video interview</div>
                <div className="price-card plan" onClick={pageClick}>Current Plan</div>
              </div>
            </div>
            <div className="price-container">
              <div className="price-wrappers">
                <div className='price-trial'>STARTER</div>
                <div className='price-free'>₹6000<span>/month</span></div>
                <div className="price-card"><img src={Tick} alt="img" />30 days validity</div>
                <div className="price-card"><img src={Tick} alt="img" />50 profile sourcing</div>
                <div className="price-card"><img src={Tick} alt="img" />5 video interviews</div>
                <div className="price-cards" onClick={pageClick}>Choose Plan</div>
              </div>
            </div>
            <div className="price-container">
            <div className="recom">
              {/* <img src={Recommended} alt="img" /> */}
              <div className='recom-text'>Recommended</div>
            </div>
            
              <div className="price-wrappers growth">
              
                <div className='price-trial'>GROWTH</div>
                <div className='price-free'>₹15000<span>/month</span></div>
                <div className="price-card"><img src={Tick} alt="img" />30 days validity</div>
                <div className="price-card"><img src={Tick} alt="img" />100 profile sourcing</div>
                <div className="price-card"><img src={Tick} alt="img" />10 video interviews</div>
                <div className="price-cards" onClick={pageClick}>Choose Plan</div>
              </div>
              
            </div>
            <div className="price-container">
              <div className="price-wrappers">
                <div className='price-trial'>ACCELARATOR</div>
                <div className='price-free'>Let's talk!</div>
                <div className="price-info">Custom plan for large organizations and priority support.</div>
                <div className="price-cards talk" onClick={pageClick}>Talk To Sales</div>
                </div>
            </div>
            
          </div>
          {/* <div className="music-icon">
            <img src={MusicIcon} alt="img" />
          </div> */}
          <div className="text-center copy-rights">
            <img src={Logo} className="lp-m-logo" alt="logo" />
            <p className="my-3 ">© Copyright 2024, Xakal Inc.</p>
          </div>

        
        {/* <div class="row price-wrapper">
          <div className="price-container">
            <img src={Trial} alt="img" />
          </div>
          <div className="price-container">
            <img src={Starter} alt="img" />
          </div>
          <div className="price-containers">
            <div className="recom"><img src={Recommended} alt="img" /></div>
            <img className="growth" src={Growth} alt="img" />
          </div>
          <div className="price-container">
            <img src={Accelarator} alt="img" />
          </div>
        </div> */}
      </div>
      
        </div>
    )
}

export default Pricing;