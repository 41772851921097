import axios from "axios";

export default class Common {
    static loggedIn(id=undefined) {
        const isLoggedin = (name) =>
        `; ${document.cookie}`.split(`; ${name}=`).pop().split(";").shift();
  
      return id ? id : isLoggedin("userid");
    }

    static logout() {
        const deleteAllCookies = () => {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=; path=/";
            }
        }
        axios
          .post(`${process.env.PUBLIC_URL}/xakal/logout`)
          .then(() => {
            deleteAllCookies()
            window.location.href = "/login"
        })
          .catch((e) => {
            deleteAllCookies()
           window.location.href = "/login"
        });
    }
}