import React from "react";
import { FaPhotoVideo } from "react-icons/fa";

const SnippetFormMedia = ({
  handleToggle,
  snippetTitle,
  setSnippetTitle,
  shortDesc,
  setShortDesc,
  longDesc,
  setLongDesc,
  videoCoverReel,
  thumbnailCover,
  fileInputrefVideo,
  fileInputrefThumbnail,
  handleDivVideoSnippet,
  handleDivThumbnailSnippet,
  handleThumbnailChange,
  handleEventInputSnippetChange,
}) => {
  return (
    <div className="article-wrap">
      <div className="textContent">
        Snippet Title
        <input
          type="text"
          placeholder="What's on your mind?"
          className="inputField"
          value={snippetTitle}
          onChange={(e) => setSnippetTitle(e.target.value)}
        />
      </div>
      <div className="character">{snippetTitle?.length}/100 Characters</div>

      {/* Video Upload Section */}
      <div className="option" onClick={handleDivVideoSnippet}>
        <FaPhotoVideo size={20} />
        Add Video
      </div>
      <input
        type="file"
        accept="video/*"
        multiple
        ref={fileInputrefVideo}
        style={{ display: "none" }} // Hide the input
        onChange={handleEventInputSnippetChange}
      />

      {/* Video Preview Section */}
      {videoCoverReel && (
        <div style={{ position: "relative", margin: "5px" }}>
          <video
            src={URL.createObjectURL(videoCoverReel)}
            controls
            style={{
              maxWidth: "150px",
              maxHeight: "150px",
              borderRadius: "4px",
            }}
          />
        </div>
      )}

      <div className="optionsContainer">
        <div
          className="option"
          onClick={handleDivThumbnailSnippet}
          style={{
            cursor: "pointer",
            padding: "10px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
          }}
        >
          <FaPhotoVideo size={20} />
          Upload
        </div>
        <input
          type="file"
          accept="image/*"
          multiple
          ref={fileInputrefThumbnail}
          style={{ display: "none" }} // Hide the input
          onChange={handleThumbnailChange}
        />
      </div>
      {thumbnailCover && (
        <img
          src={URL.createObjectURL(thumbnailCover)} // Create URL for the selected image
          alt="Selected Images"
          style={{
            maxWidth: "150px",
            maxHeight: "150px",
            borderRadius: "4px",
          }}
        />
      )}
      <div className="textContent">
        Short Description
        <textarea
          value={shortDesc}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setShortDesc(e.target.value)}
          rows={3}
        />
        <div className="character">{shortDesc?.length}/300 Characters</div>
      </div>
      <div className="textContent">
        Long Description
        <textarea
          value={longDesc}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setLongDesc(e.target.value)}
          rows={3}
        />
        <div className="character">{longDesc?.length}/3000 Characters</div>
      </div>
      <div className="buttonDiv">
        <button className="button2" onClick={handleToggle}>
          Next
        </button>
      </div>
    </div>
  );
};

export default SnippetFormMedia;
