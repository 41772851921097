import React, { Component } from "react";
import "../../src/minified-css/navbar.min.css";
import "../styles/navbar.css";
import "../styles/classnote.css";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";
import xalogo from "../images/xakallogo.png";
import MobileFooter from "./mobileFooter";
import ImageAvatar from "../images/company.png";
import Signup from "./signup";
import Setting from "./setting";
import Changepassword from "./changePassword";
import NewPassword from "./newpassword";
import Forgetpassword from "./forgetpassword";
import MyProfile from "./MyProfile/myProfile";
import Jobs from "./Jobs/manageJobs";
import ApplyJob from "./Jobs/applyJobs";
import ViewCompany from "./company/view-company";
import Search from "./Jobs/search";
import ArchievedJobs from "./Jobs/archievedJobs";
import Drafts from "./Jobs/Drafts";
import AddNewJob from "./Jobs/addNewJob";
import AddNewJobInfo from "./Jobs/addJobInformation";
import ViewJobApplicant from "./Jobs/jobApplication";
import AppliedJobApplication from "./Jobs/appliedJobApplication";
import ArchivedJobApplication from "./Jobs/archievedJobApplication";
import PreviewJob from "./Jobs/previewJob";
import AddQuestions from "./Jobs/addQuestions";
import Applicants from "./Jobs/applicants";
import ApplicantDetails from "./Jobs/applicantDetails";
import MyCalender from "./MyCalender/myCalender";
import Common from "../utils/common";
import ActiveJob from "./Jobs/activeJob";
import AddNewSave from "./Jobs/addNewSave";
import AdmissionForm from "./Admission Form/admissionForm";
import AdmissionFormPreview from "./Admission Form/admissionFormPeview";
import PrefillNewForm from "./Jobs/addPrefillNewJob";
import FavouriteJob from "./Jobs/favouritesPage";
import JobApplied from "./Jobs/jobapplied";
import PreviewJobUnschedule from "./Jobs/previewUnschedule";
import AboutModal from "./MyProfile/aboutModal";
import { NavLink } from "react-router-dom";
import PreviewDraft from "./Jobs/previewDraft";
import PreviewSchedule from "./Jobs/previewSchedule";
import DraftSave from "./Jobs/addDraftUpdate";
import Company from "./company/company";
import AboutCompany from "./company/aboutCompany";
import AboutContact from "./company/aboutContact";
import CompanyPreview from "./company/companyPreviwe";
import CompanyEdit from "./company/companyEdit";
import CompanyEditView from "./company/Edit-companyview";
import ManageDomain from "./company/manageDomain";
import ManageEmployee from "./company/manageEmployee";
import OTPChange from "./otpChnage";
import { ViewEmployeeList } from "./employees/viewEmployeeList";
import { ViewEmployeeDetails } from "./employees/viewEmployeeDetails";
import ManageAdmin from "./company/manageAdmin";
import ApplicationTrackingSystem from "./ats/applicationTrackingSystem";
import Institute from "./Institution/instititute";
import AboutInstitute from "./Institution/aboutInsitutue";
import AboutContactInsitute from "./Institution/contactInstitute";
import Organisation from "./Organisation/Organisation";
import ViewInstitute from "./Institution/view-institute";
import ClassesInstitute from "./Institution/classes";
import OrganisationInstitute from "./Organisation/organisationInstitute";
import PendingRequests from "./associates/pendingRequests";
import MyRequests from "./associates/myRequests";
import EditInstitute from "./Institution/edit-institiute";
import EditViewInstitute from "./Institution/editview-institute";
import InstituteDomain from "./Institution/insitituteDomain";
import InstituteDomainPage from "./Institution/instituteDomainPage";
import { InstituteEmployeePage } from "./Institution/instituteEmployee";
import Skills from "../components/MyProfile/Skills";
import ProfileSkills from "./MyProfile/profileSkill";
import MyAssociatesList from "./associates/myAssociatesList";
import MyFollowingList from "./associates/MyFollowingList";
import MyFollowerslist from "./associates/MyFollowerslist";
import AllPageResults from "../components/Jobs/allPageResults";
import InstituteOne from "./Institution/institueOne";
import CompanyOne from "./company/companyOne";
import AssociatesAndFollowers from "./associates/profileFollowersAndAssociates";
import Booking from "./booking/booking";
import BookingDetail from "./booking/booking-detail";
import OrderSummary from "./payment/orderSummary";
import ExploreInterview from "./interview/exploreInterview";
import BookedInterview from "./interview/bookedInterview";
import RepoDetails from "./MyProfile/repoDetails";
import VideoRoom from "./videoRoom";
import Feedback from "./payment/feedback";
import ViewFeedback from "./payment/viewFeedback";
import AccountDetails from "../components/BankDetails/accountDetails";
import otpVerification from "./otpVerification";
import MediaQuery from "react-responsive";
import HamburgerMenu from "./hamburger";
import { Auth } from "aws-amplify";
import FilePreview from "./Jobs/fileviewer";
import BankTranscation from "./BankDetails/bankTranscation";
import BankOneDetail from "./BankDetails/bankoneDetail";
import AdminCompany from "./admin/adminCompany";
import AdminUsers from "./admin/adminUsers";
import axios from "axios";
import PendingInterview from "./interview/pendingInterview";
import SharedInterviewApply from "./interview/shared-apply-interview";
import ShareApplyJob from "./Jobs/shared-apply-job";
import SavedInterview from "./interview/savedInterview";
import PendingFeedback from "./payment/pendingFeedback";
import AptitudeAdmin from "./Aptitude/aptitude";
import AptitudeList from "./Aptitude/aptituteCandidateList";
import Exam from "../pages/Exam";
import Home from "../pages/Home";
import Result from "../pages/Result";
import Promo from "./promo/promo";
import MessageChat from "./conversation/message";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Conversation from "./conversation/conversation";
import DashBoard from "./dashboard/dashboard";
import AptitudeCandidate from "./Aptitude/aptitudeCandidate";
import Certificate from "./Certificate/certificate";
import EducationModal from "./MyProfile/educationModal";
import ExperienceModal from "./MyProfile/experienceModal";
import ProjectModal from "./MyProfile/projectModal";
import CourseModal from "./MyProfile/courseModal";
import ManageInterview from "./manage-interview/manageInterview";
import NotificationPage from "./notification/notificationPage";
import InterviewJoined from "./notification/interviewJoined";
import OtherCompaniesView from "./company/otherCompanies";
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMessage: false,
      openChat: false,
      messageChats: null,
      messageChat: [],
      arrivalMessage: null,
      openConver: false,
      conversation: [],
      detail: null,
      userIds: Common.loggedIn(),
      showClassNotes: false,
      assessments: false,
      timetable: false,
      transport: false,
      user: "",
      success: false,
      openSetting: false,
      admin: false,
      hideOrg: false,
      hideHr: false,
      hideCandidate: false,
      feedback: [],
      read: 0,
      firstName: "",
      userType: [],
      routerLink: "",
      openRoom: false,
      hasFetchedInterviews: false,
      interviewsWithDetails: [], // State to hold the fetched data
      error: null, // State to hold any errors

      id: "",
      otherProfile: false,
      userID: (name) =>
        `; ${document.cookie}`.split(`; ${name}=`).pop().split(";").shift(),
    };
    this.baseState = this.state;
    this.fetchCalendarEvents = this.fetchCalendarEvents.bind(this);
    this.getAllInterviewStartedWithDetails =
      this.getAllInterviewStartedWithDetails.bind(this);
    this.handleIgnore = this.handleIgnore.bind(this);
    this.handleJoin = this.handleJoin.bind(this);
  }
  logout() {
    Common.logout();
  }
 
  componentDidMount() {
    document.addEventListener("visibilitychange", this.onVisibilityChange);
    document.addEventListener("keydown", this.handleKeyDown);
    this.fetchData(); // Call fetchData once initially

    // Call fetchData every two seconds

    this.getAllPending();

    if (!this.state.hasFetchedInterviews) {
      this.getAllInterviewStartedWithDetails();
    }
    if (!this.state.userID("userid")) {
      this.logout();
    } else {
      if (Common.loggedIn() && Common.loggedIn().length > 0) {
        axios
          .get(
            `${
              process.env.PUBLIC_URL
            }/xakal/get-about-detail/${Common.loggedIn()}`
          )
          .then((response) => {
            this.setState({
              admin: response.data.aboutOne.userType.includes("admin"),
              firstName: response.data.aboutOne.firstName,
              avatar: response.data.aboutOne.avatar,
              hideOrg:
                response.data.aboutOne.userType.includes("hr") ||
                response.data.aboutOne.userType.includes("interviewer"),
              hideHr: response.data.aboutOne.userType.includes("hr"),
              hideCandidate:
                response.data.aboutOne.userType.includes("candidate"),
            });
          })
          .catch((err) => {});
      }
    }

    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          this.setState(user);
        }
      })
      .catch((error) => {
        this.setState(null);
      });
    const { pathname } = this.props.location;
    const host = window.location.host;

    // List of allowed hosts
    const allowedHosts = [
      "test-staging.thexakal.com",
      "thexakal.com",
      "qa.thexakal.com",
      "localhost:3000",
    ];

    // Check if the current host is in the list of allowed hosts
    const isHostAllowed = allowedHosts.includes(host);

    // Check if the URL matches the /portal/other-profile/:id pattern
    const pathMatch = pathname.match(/^\/portal\/other-profile\/(.+)$/);

    if (isHostAllowed && pathMatch) {
      // Extract the ID from the URL
      const id = pathMatch[1];
      this.setState({
        id,
        otherProfile: true, // Set otherProfile to true if the URL matches
      });
    } else {
      // Handle cases where the path or host does not match
      this.setState({
        otherProfile: false,
      });
    }
  }
  fetchCalendarEvents = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/events/${userId}`
      );
      const activeCalendarEvents = response.data.filter(
        (event) => event.status === "active"
      );

      // Create a map of bookingId to calendar events
      return activeCalendarEvents.reduce((map, event) => {
        if (!map[event.bookingId]) {
          map[event.bookingId] = [];
        }
        map[event.bookingId].push(event);
        return map;
      }, {});
    } catch (error) {
      console.error("Error fetching calendar events:", error);
      return {}; // Return an empty map if there is an error
    }
  };

  // Method to fetch all interviews with additional details
  getAllInterviewStartedWithDetails = async () => {
    try {
      // Step 1: Fetch the interview data
      const interviewResponse = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-interview-started`
      );
      const interviews = interviewResponse.data.bookingServices;

      // Fetch the calendar events for the logged-in user
      const userId = Common.loggedIn(); // Adjust based on how you get the logged-in user's ID
      const calendarEventMap = await this.fetchCalendarEvents(userId);

      // Step 2: Fetch additional details for each interview
      const interviewsWithDetails = await Promise.all(
        interviews.map(async (interview) => {
          // Fetch about detail for the interviewStartedBy ID
          try {
            const aboutDetailResponse = await axios.get(
              `${process.env.PUBLIC_URL}/xakal/get-about-detail/${interview.interviewStartedBy}`
            );
            const aboutDetail = aboutDetailResponse.data.aboutOne;

            // Map calendar events to the bookingId of the interview
            const bookingId = interview._id.toString();
            const calendarEvents = calendarEventMap[bookingId] || [];

            return {
              ...interview,
              aboutDetail: aboutDetail || {}, // Handle cases where aboutDetail might be null
              calendarEvents, // Add calendar events to the interview data
            };
          } catch (error) {
            console.error(
              `Error fetching about detail for interview ID ${interview._id}:`,
              error
            );
            return {
              ...interview,
              aboutDetail: {}, // Return empty aboutDetail if there's an error
              calendarEvents: [], // Return empty calendar events if there's an error
            };
          }
        })
      );

      if (interviewsWithDetails.length > 0) {
        this.setState({
          interviewsWithDetails,
          openRoom: true,
          hasFetchedInterviews: true,
        });
      }
    } catch (error) {
      console.error("Error fetching interview data or details:", error);
      throw new Error(
        "An error occurred while fetching interview data or additional details."
      );
    }
  };
  fetchData = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/conversation/${Common.loggedIn()}`
      );
      const conversations = res.data.conver;
      this.setState({ conversation: conversations });

      // Fetch messages for each conversation
      conversations.forEach((conversation) => {
        const conversationId = conversation._id;
        const friendId = conversation.members.find(
          (m) => m !== Common.loggedIn()
        );

        axios
          .get(`${process.env.PUBLIC_URL}/xakal/message/${conversationId}`)
          .then((res) => {
            const messages = res.data.message;
            const unreadCounts = messages.filter(
              (msg) => !msg.read && msg.sender === friendId
            ).length;
            console.log(unreadCounts, "unreadCount");
            // Update state with the unread message count for this conversation

            this.setState({ unreadCounts });
          })
          .catch((error) => {
            console.error(error);
          });
      });
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.messageChats &&
      prevState.messageChats !== this.state.messageChats
    ) {
      const friendId = this.state.messageChats.members.find(
        (m) => m !== this.state.userIds
      );
      this.getUserChat(friendId);
      this.getAllMessage(this.state.messageChats._id);
    }
  }
  componentWillUnmount() {
    // this.unlisten();
    clearInterval(this.intervalId); // Clear interval on component unmount
    document.removeEventListener("visibilitychange", this.onVisibilityChange);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  //  * Handles the sub menu of college notes
  getAllPending = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-pending-feedback`
      );
      this.setState({
        feedback: res.data.book,
      });
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  onVisibilityChange = async () => {
    const currentRoute = window.location.href;
    const allowedRoutes = [
      "http://localhost:3000/portal/exam",
      "http://localhost:3000/portal/result",
      "http://localhost:3000/portal/home",
      "http://localhost:3000/portal/aptitude-test",
      "https://thexakal.com/portal/exam",
      "https://thexakal.com/portal/result",
      "https://thexakal.com/portal/home",
      "https://thexakal.com/portal/aptitude-test",
      "https://test-staging.thexakal.com/portal/exam",
      "https://test-staging.thexakal.com/portal/result",
      "https://test-staging.thexakal.com/portal/home",
      "https://test-staging.thexakal.com/portal/aptitude-test",
      "https://qa.thexakal.com/portal/exam",
      "https://qa.thexakal.com/portal/result",
      "https://qa.thexakal.com/portal/home",
      "https://qa.thexakal.com/portal/aptitude-test",
    ];

    if (
      document.visibilityState !== "visible" &&
      allowedRoutes.includes(currentRoute)
    ) {
      alert(
        "Browser or tab switch detected. Please don't switch or open a new tab."
      );

      await this.getUserDeactivityStatus();
    }
  };
  onNavLinkClick = async (event) => {
    const currentRoute = window.location.href;
    const allowedRoutes = [
      "http://localhost:3000/portal/exam",
      "http://localhost:3000/portal/result",
      "http://localhost:3000/portal/home",
      "http://localhost:3000/portal/aptitude-test",
      "https://thexakal.com/portal/exam",
      "https://thexakal.com/portal/result",
      "https://thexakal.com/portal/home",
      "https://thexakal.com/portal/aptitude-test",
      "https://test-staging.thexakal.com/portal/exam",
      "https://test-staging.thexakal.com/portal/result",
      "https://test-staging.thexakal.com/portal/home",
      "https://test-staging.thexakal.com/portal/aptitude-test",
      "https://qa.thexakal.com/portal/exam",
      "https://qa.thexakal.com/portal/result",
      "https://qa.thexakal.com/portal/home",
      "https://qa.thexakal.com/portal/aptitude-test",
    ];

    // Register event listener for beforeunload
    window.addEventListener("beforeunload", (event) => {
      if (allowedRoutes.includes(currentRoute)) {
        event.preventDefault(); // Prevent default navigation behavior
        event.returnValue = ""; // For older browsers
      }
    });

    // Check if current route is in allowedRoutes
    if (allowedRoutes.includes(currentRoute)) {
      event.preventDefault(); // Prevent default navigation behavior
      alert(
        "Browser or tab switch detected. Please don't switch or open a new tab."
      );
      // Optionally, add more logic here if needed
      await this.getUserDeactivityStatus();
    }
  };

  getUserDeactivityStatus = async () => {
    const payload = {
      userName: this.state.firstName,
    };
    try {
      await axios.post(
        `${process.env.PUBLIC_URL}/xakal/detectuseractivity`,
        payload
      );
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  handleButtonClick = () => {
    const { history } = this.props;
    history.push("/portal/changepassword");
  };
  handleButtonProfileClick = () => {
    const { history } = this.props;
    history.push("/portal/my-profile");
  };
  onClassNotesClick() {
    this.setState({ assessments: false });
    if (this.state.showClassNotes) {
      this.setState({ showClassNotes: false });
    } else {
      this.setState({ showClassNotes: true });
    }
  }
  onAttendanceClick() {
    if (this.state.attendance) {
      this.setState({ attendance: false });
    } else {
      this.setState({ attendance: true });
    }
  }

  //  * Handles the sub menu of assessments

  onAssessmentsClick() {
    this.setState({ showClassNotes: false });
    if (this.state.assessments) {
      this.setState({ assessments: false });
    } else {
      this.setState({ assessments: true });
    }
  }

  onTimeTableClick() {
    if (this.state.timetable) {
      this.setState({ timetable: false });
    } else {
      this.setState({ timetable: true });
    }
  }

  onTransportClick() {
    if (this.state.transport) {
      this.setState({ transport: false });
    } else {
      this.setState({ transport: true });
    }
  }

  openSettingDialog = () => {
    this.setState({ openSetting: true });
  };
  openSettingDialogClose = () => {
    this.setState({ openSetting: false });
  };
  openSuccessDialog = () => {
    this.setState({ success: true });
  };
  openSuccessDialogClose = () => {
    this.setState({ success: false });
  };
  //chat
  getAllConver = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/conversation/${Common.loggedIn()}`
      );
      this.setState({ conversation: res.data.conver });
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  getAllMessage = async (messageChatsId) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/message/${messageChatsId}`
      );
      this.setState({ messageChat: res.data.message });
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  getUserChat = async (friendId) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${friendId}`
      );
      this.setState({ detail: res.data.aboutOne });
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  handleMessage = () => {
    this.setState({ openMessage: true });
    this.getAllConver();
  };

  handleChat = (c) => {
    this.setState(
      { openChat: true, messageChats: c, openMessage: false },
      this.handleMessagesClick
    );
  };

  handleMessagesClick = async () => {
    const { messageChats, userIds } = this.state;
    const payload = {
      conversationId: messageChats._id,
      userId: userIds,
    };
    try {
      await axios.post(`${process.env.PUBLIC_URL}/xakal/mark-as-read`, payload);
      if (messageChats) {
        this.getAllMessage(messageChats._id);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  handleKeyDown = (event) => {
    if (event.ctrlKey && (event.key === "f" || event.keyCode === 70)) {
      event.preventDefault();
      // Optionally, you can notify the user that Ctrl+F is disabled
      console.log("Ctrl+F is disabled");
    }
  };
  fetchUserDetails = async () => {
    const loggedInUserID = Common.loggedIn(); // Assuming this function exists
    if (loggedInUserID && loggedInUserID.length > 0) {
      try {
        const response = await axios.get(
          `${process.env.PUBLIC_URL}/xakal/get-about-detail/${loggedInUserID}`
        );
        const aboutOne = response.data.aboutOne;
        this.setState({
          admin: aboutOne.userType.includes("admin"),
          firstName: aboutOne.firstName,
          avatar: aboutOne.avatar,
          hideOrg:
            aboutOne.userType.includes("hr") ||
            aboutOne.userType.includes("interviewer"),
          hideHr: aboutOne.userType.includes("hr"),
          hideCandidate: aboutOne.userType.includes("candidate"),
        });
      } catch (err) {
        console.log("Error fetching details:", err);
      }
    }
  };
  handleIgnore() {
    // Handle ignore action
    this.setState({ openRoom: false });
  }

  handleJoin() {
    // Handle join action
    this.setState({ openRoom: false });
  }
  render() {
    const {
      openMessage,
      openChat,

      conversation,
      messageChats,
      detail,
      hideCandidate,
    } = this.state;
    const { id, otherProfile } = this.state;
    const { openRoom, interviewsWithDetails } = this.state;
    console.log(interviewsWithDetails,"interviewsWithDetails")
    return (
      <Router>
        <div className="background">
          <div>
            <MediaQuery maxWidth={992}>
              <HamburgerMenu />
            </MediaQuery>
            <MediaQuery minWidth={992}>
              <ul
                className="navbar-nav nav-desktop   bg-gradient-primary sidebar sidebar-dark accordion html"
                id="accordionSidebar"
              >
                <button className="sidebar-brand d-flex align-items-center justify-content-center">
                  <div className="logo-container ">
                    <NavLink
                      activeClassName="active"
                      to={{
                        pathname: `/portal/my-profile`,
                        userID:
                          this.props.userID || this.state.userID("userid"),
                      }}
                    >
                      <img
                        className="sb-main-logo"
                        src={xalogo}
                        alt="XAKAL"
                        onClick={this.onNavLinkClick}
                      />
                    </NavLink>
                  </div>
                </button>
                <hr className="sidebar-divider my-0" />
                <br></br>
                <div className="nav-wrapper">
                  <div>
                    <li className="nav-item">
                      <NavLink
                        activeClassName="active"
                        to={{
                          pathname: `/portal/interview/exploreInterview`,
                        }}
                        className="nav-link iInterviews collapsed "
                        isActive={(match, location) => {
                          const interviewPaths = [
                            "/portal/interview/exploreInterview",
                            "/portal/interview/pendingInterview",
                            "/portal/interview/bookedInterview",
                            "/portal/interview/savedInterview",
                            "/portal/other-profile",
                            "/portal/order-summary",
                            "/portal/interview/manage-interview"
                          ];
                          return interviewPaths.some((path) =>
                            location.pathname.startsWith(path)
                          );
                        }}
                        onClick={this.onNavLinkClick} // Handle click event
                      >
                        <span className="pro nav-weight"> Interviews</span>
                      </NavLink>
                    </li>
                    {hideCandidate && (
                      <li className="nav-item">
                        <NavLink
                          activeClassName="active"
                          to={{
                            pathname: `/portal/aptitude`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link iAptitude collapsed "
                          isActive={(match, location) => {
                            const companyPaths = [
                              "/portal/aptitude",
                              "/portal/home",
                              "/portal/exam",
                              "/portal/aptitude-test",
                            ];
                            return companyPaths.some((path) =>
                              location.pathname.startsWith(path)
                            );
                          }}
                          onClick={this.onNavLinkClick} // Handle click event
                        >
                          <span className="pro nav-weight">Aptitude</span>
                        </NavLink>
                      </li>
                    )}
                    <li className="nav-item">
                      <NavLink
                        activeClassName="active"
                        to={{
                          pathname: `/portal/jobs`,
                          userID:
                            this.props.userID || this.state.userID("userid"),
                        }}
                        className="nav-link iJobs collapsed"
                        isActive={(match, location) => {
                          const jobPaths = [
                            "/portal/jobs",
                            "/portal/applied-jobs",
                            "/portal/favorites",
                            "/portal/manage-jobs",
                            "/portal/archievedJobs",
                            "/portal/drafts",
                            "/portal/view-applicants",
                            "/portal/applicant-details",
                            "/portal/otherProfile",
                            "/portal/shares-apply-job",
                          ];
                          return jobPaths.some((path) =>
                            location.pathname.startsWith(path)
                          );
                        }}
                        onClick={this.onNavLinkClick} // Handle click event
                      >
                        <span className="pro nav-weight"> Jobs</span>
                      </NavLink>
                    </li>
                    {this.state.hideHr ? (
                      <li className="nav-item">
                        <NavLink
                          activeClassName="active"
                          to={{
                            pathname: `/portal/application-tracking-system`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link iATS collapsed "
                          onClick={this.onNavLinkClick} // Handle click event
                        >
                          <span
                            style={{ marginLeft: "8px" }}
                            className="pro nav-weight"
                          >
                            {" "}
                            ATS
                          </span>
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}

                    <li className="nav-item">
                      <NavLink
                        activeClassName="active"
                        to={{
                          pathname: `/portal/my-calendar`,
                          userID:
                            this.props.userID || this.state.userID("userid"),
                        }}
                        className="nav-link iCalendar collapsed "
                        isActive={(match, location) => {
                          const calendarPaths = [
                            "/portal/my-calendar",
                            "/portal/videoRoom",
                          ];
                          return calendarPaths.some((path) =>
                            location.pathname.startsWith(path)
                          );
                        }}
                        onClick={this.onNavLinkClick} // Handle click event
                      >
                        <span className="pro nav-weight"> My Calendar</span>
                      </NavLink>
                    </li>
                    {this.state.hideOrg ? (
                      <li className="nav-item">
                        <NavLink
                          activeClassName="active"
                          to={{
                            pathname: `/portal/organisation`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link iOrganisation collapsed "
                          isActive={(match, location) => {
                            const companyPaths = [
                              "/portal/organisation",
                              "/portal/view-company",
                              "/portal/edit-companyview",
                              "/portal/edit-company",
                              "/portal/manageAdmin",
                              "/portal/company-domain",
                              "/portal/manage-employees",
                              "/portal/company",
                              "/portal/company-about",
                              "/portal/company-contact",
                            ];
                            return companyPaths.some((path) =>
                              location.pathname.startsWith(path)
                            );
                          }}
                          onClick={this.onNavLinkClick} // Handle click event
                        >
                          <span className="pro nav-weight">Organisation</span>
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}

                    {this.state.admin ? (
                      <>
                        <li className="nav-item">
                          <NavLink
                            activeClassName="active"
                            to={{
                              pathname: `/portal/dashboard`,
                              userID:
                                this.props.userID ||
                                this.state.userID("userid"),
                            }}
                            className="nav-link iUser collapsed "
                            isActive={(match, location) => {
                              const calendarPaths = ["/portal/dashboard"];
                              return calendarPaths.some((path) =>
                                location.pathname.startsWith(path)
                              );
                            }}
                            onClick={this.onNavLinkClick} // Handle click event
                          >
                            <span className="pro nav-weight">Dashboard</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            activeClassName="active"
                            to={{
                              pathname: `/portal/admin-users`,
                              userID:
                                this.props.userID ||
                                this.state.userID("userid"),
                            }}
                            className="nav-link iUser collapsed "
                            isActive={(match, location) => {
                              const calendarPaths = ["/portal/admin-users"];
                              return calendarPaths.some((path) =>
                                location.pathname.startsWith(path)
                              );
                            }}
                            onClick={this.onNavLinkClick} // Handle click event
                          >
                            <span className="pro nav-weight"> Admin Users</span>
                          </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink
                            activeClassName="active"
                            to={{
                              pathname: `/portal/admin-company`,
                              userID:
                                this.props.userID ||
                                this.state.userID("userid"),
                            }}
                            className="nav-link iCompany collapsed "
                            isActive={(match, location) => {
                              const calendarPaths = ["/portal/admin-company"];
                              return calendarPaths.some((path) =>
                                location.pathname.startsWith(path)
                              );
                            }}
                          >
                            <span className="pro nav-weight">
                              {" "}
                              Admin Companies
                            </span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            activeClassName="active"
                            to={{
                              pathname: `/portal/aptitude-admin`,
                              userID:
                                this.props.userID ||
                                this.state.userID("userid"),
                            }}
                            className="nav-link iAptitude collapsed "
                            isActive={(match, location) => {
                              const calendarPaths = ["/portal/aptitude-admin"];
                              return calendarPaths.some((path) =>
                                location.pathname.startsWith(path)
                              );
                            }}
                            onClick={this.onNavLinkClick} // Handle click event
                          >
                            <span className="pro nav-weight">
                              Aptitude List
                            </span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            activeClassName="active"
                            to={{
                              pathname: `/portal/promo`,
                              userID:
                                this.props.userID ||
                                this.state.userID("userid"),
                            }}
                            className="nav-link iUser collapsed "
                            isActive={(match, location) => {
                              const calendarPaths = ["/portal/promo"];
                              return calendarPaths.some((path) =>
                                location.pathname.startsWith(path)
                              );
                            }}
                            onClick={this.onNavLinkClick} // Handle click event
                          >
                            <span className="pro nav-weight">Promo List</span>
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {/* Feedback commented */}
                    {this.state.feedback && this.state.feedback.length > 0 ? (
                      <li className="nav-item">
                        <NavLink
                          activeClassName="active"
                          to={{
                            pathname: `/portal/pending-feedback-interview`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link iOrganisation collapsed "
                          isActive={(match, location) => {
                            const companyPaths = [
                              "/portal/pending-feedback-interview",
                            ];
                            return companyPaths.some((path) =>
                              location.pathname.startsWith(path)
                            );
                          }}
                          onClick={this.onNavLinkClick} // Handle click event
                        >
                          <span className="pro nav-weight">
                            Pending feedback {this.state.feedback.length}
                          </span>
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </ul>
            </MediaQuery>
            <div
              id="content-wrapper "
              className="d-flex flex-column background main-content-wrapper"
            >
              <div id="content" className="background">
                <Switch>
                  {/* student portal links */}

                  <Route path="/signup" element={Signup} />
                  <Route
                    path="/portal/company-about/:id"
                    component={AboutCompany}
                  />
                  <Route
                    path="/portal/company-one/:id"
                    component={CompanyOne}
                  />
                  <Route path="/portal/organisation" component={Organisation} />
                  <Route
                    path="/portal/organisations"
                    component={OrganisationInstitute}
                  />
                  <Route
                    path="/portal/view-institute"
                    component={ViewInstitute}
                  />
                  <Route
                    path="/portal/aptitude"
                    component={AptitudeCandidate}
                  />
                  <Route
                    path="/portal/classes/:id"
                    component={ClassesInstitute}
                  />
                  <Route path="/portal/booking" component={Booking} />
                  <Route
                    path="/portal/booking-detail"
                    component={BookingDetail}
                  />
                  <Route path="/portal/setting" component={Setting} />
                  <Route path="/portal/company" component={Company} />
                  <Route path="/portal/institute" component={Institute} />
                  <Route
                    path="/portal/insitutes/:id"
                    component={InstituteOne}
                  />
                  <Route
                    path="/portal/institute-about/:id"
                    component={AboutInstitute}
                  />
                  <Route
                    path="/portal/institute-contact/:id"
                    component={AboutContactInsitute}
                  />
                  <Route
                    path="/portal/institute-admin-domain"
                    component={InstituteDomainPage}
                  />
                  <Route
                    path="/portal/institute-employees"
                    component={InstituteEmployeePage}
                  />

                  <Route
                    path="/portal/institute-domain/:id"
                    component={InstituteDomain}
                  />
                  <Route
                    path="/portal/edit-institute/:id"
                    component={EditInstitute}
                  />
                  <Route
                    path="/portal/editview-institute/:id"
                    component={EditViewInstitute}
                  />
                  <Route
                    path="/portal/company-contact/:id"
                    component={AboutContact}
                  />
                  <Route
                    path="/portal/company-preview"
                    component={CompanyPreview}
                  />
                  <Route
                    path="/portal/edit-company/:id"
                    component={CompanyEdit}
                  />
                  <Route
                    path="/portal/edit-companyview/:id"
                    component={CompanyEditView}
                  />
                  <Route path="/portal/view-company" component={ViewCompany} />
                  <Route
                    path="/portal/admin-company"
                    component={AdminCompany}
                  />
                  <Route path="/portal/admin-users" component={AdminUsers} />
                  <Route
                    path="/portal/company-domain/:id"
                    component={ManageDomain}
                  />
                  <Route
                    path="/portal/company-employee"
                    component={ManageEmployee}
                  />
                  <Route
                    path="/portal/notification"
                    component={NotificationPage}
                  />
                  <Route
                    key="my-profile"
                    path="/portal/my-profile"
                    // component={MyProfile}
                    render={(props) => (
                      <MyProfile
                        {...props}
                        fetchUserDetails={this.fetchUserDetails}
                      />
                    )}
                  />
                  <Route path="/portal/education" component={EducationModal} />
                  <Route
                    path="/portal/experience"
                    component={ExperienceModal}
                  />
                  <Route path="/portal/project" component={ProjectModal} />
                  <Route path="/portal/course" component={CourseModal} />
                  <Route
                    path="/portal/feedback/:bookingId"
                    component={Feedback}
                  />
                  <Route
                    path="/portal/view-feedback"
                    component={ViewFeedback}
                  />
                  <Route
                    path="/portal/my-profile"
                    render={(props) => (
                      <AboutModal
                        {...props}
                        fetchUserDetails={this.fetchUserDetails}
                      />
                    )}
                  />
                  <Route
                    path="/portal/changepassword"
                    component={Changepassword}
                  />
                  <Route
                    path="/portal/forget-password"
                    component={Forgetpassword}
                  />
                  <Route path="/portal/new-password" component={NewPassword} />
                  <Route path="/portal/otp-changed" component={OTPChange} />
                  <Route path="/portal/manage-jobs" component={Jobs} />
                  <Route path="/portal/jobs" component={ApplyJob} />
                  <Route path="/portal/search" component={Search} />
                  <Route
                    path="/portal/archievedJobs"
                    component={ArchievedJobs}
                  />
                  <Route path="/portal/activeJob" component={ActiveJob} />
                  <Route path="/portal/drafts" component={Drafts} />
                  <Route
                    exact
                    path="/portal/view-application/:id"
                    component={ViewJobApplicant}
                  />
                  <Route
                    path="/portal/appliedJobs/:id"
                    component={AppliedJobApplication}
                  />
                  <Route path="/portal/manageAdmin" component={ManageAdmin} />
                  <Route
                    path="/portal/archivedJobsApp/:id"
                    component={ArchivedJobApplication}
                  />
                  <Route path="/portal/addnew-job" component={AddNewJob} />
                  <Route
                    key="other-profile"
                    path="/portal/other-profile/:id"
                    render={(props) => (
                      <MyProfile
                        {...props}
                        fetchUserDetails={this.fetchUserDetails}
                      />
                    )}
                  />
                  <Route
                    path="/portal/addnew-jobs/:id"
                    component={PrefillNewForm}
                  />
                  <Route path="/portal/favorites" component={FavouriteJob} />
                  <Route path="/portal/applied-jobs" component={JobApplied} />

                  <Route
                    path="/portal/addnew-save/:id"
                    component={AddNewSave}
                  />
                  <Route
                    path="/portal/adddraft-save/:id"
                    component={DraftSave}
                  />
                  <Route path="/portal/dashboard" component={DashBoard} />
                  <Route
                    exact
                    path="/portal/addnew-jobinfo/:id"
                    component={AddNewJobInfo}
                  />
                  <Route path="/portal/previewjob" component={PreviewJob} />
                  <Route
                    path="/portal/preview-scheduled-job/:id"
                    component={PreviewJobUnschedule}
                  />

                  <Route path="/portal/addQuestions" component={AddQuestions} />
                  <Route path="/portal/previewjob/:id" component={PreviewJob} />
                  <Route
                    path="/portal/previewdraft/:id"
                    component={PreviewDraft}
                  />
                  <Route
                    path="/portal/previewschedule/:id"
                    component={PreviewSchedule}
                  />
                  <Route
                    path="/portal/pending-feedback-interview"
                    component={PendingFeedback}
                  />
                  <Route
                    exact
                    path="/portal/view-applicants/:id"
                    component={Applicants}
                  />
                  <Route
                    path="/portal/applicant-details/:id/:jobId"
                    component={ApplicantDetails}
                  />
                  <Route path="/portal/my-calendar" component={MyCalender} />
                  <Route
                    path="/portal/application-tracking-system"
                    component={ApplicationTrackingSystem}
                  />
                  <Route
                    path="/portal/admissionform"
                    component={AdmissionForm}
                  />
                  <Route
                    path="/portal/admissionformpreview"
                    component={AdmissionFormPreview}
                  />
                  <Route
                    path="/portal/manage-employees"
                    component={ViewEmployeeList}
                  />
                  <Route
                    path="/portal/employee/view/:userId"
                    component={ViewEmployeeDetails}
                  />

                  <Route
                    key="otherpro"
                    path="/portal/otherProfile/:id"
                    render={(props) => (
                      <MyProfile
                        {...props}
                        fetchUserDetails={this.fetchUserDetails}
                      />
                    )}
                  />
                  <Route
                    path="/portal/pendingRequest"
                    component={PendingRequests}
                  />
                  <Route path="/portal/myRequests" component={MyRequests} />
                  <Route
                    path="/portal/associates/:userId"
                    component={MyAssociatesList}
                  />
                  <Route
                    path="/portal/associatesAndFollowers/:id"
                    component={AssociatesAndFollowers}
                  />
                  <Route
                    path="/portal/followers/:userId"
                    component={MyFollowerslist}
                  />
                  <Route
                    path="/portal/order-summary/:id"
                    component={OrderSummary}
                  />
                  <Route
                    path="/portal/following/:userId"
                    component={MyFollowingList}
                  />
                  <Route
                    path="/portal/skills/:associateId"
                    component={Skills}
                  />
                  <Route
                    path="/portal/profile-skills/:associateId"
                    component={ProfileSkills}
                  />
                  <Route
                    path="/portal/videoRoom/:bookingId/:roomName"
                    component={VideoRoom}
                  />
                  <Route path="/portal/allpages" component={AllPageResults} />
                  <Route
                    path="/portal/accountdetails"
                    component={AccountDetails}
                  />
                  <Route
                    path="/portal/otp-verification"
                    component={otpVerification}
                  />
                  <Route path="/portal/fileViewer" component={FilePreview} />
                  <Route
                    path="/portal/interview/exploreInterview"
                    component={ExploreInterview}
                  />
                  <Route
                    path="/portal/interview/pendingInterview"
                    component={PendingInterview}
                  />
                  <Route
                    path="/portal/interview/savedInterview"
                    component={SavedInterview}
                  />
                  <Route
                    path="/portal/interview/bookedInterview"
                    component={BookedInterview}
                  />
                  <Route
                    path="/portal/MyProfile/repoDetails"
                    component={RepoDetails}
                  />
                  <Route
                    path="/portal/transaction"
                    component={BankTranscation}
                  />
                  <Route
                    path="/portal/transaction-detail/:id"
                    component={BankOneDetail}
                  />
                  <Route
                    path="/portal/shares-apply-interview/:id"
                    component={SharedInterviewApply}
                  />
                  <Route
                    path="/portal/shares-apply-job/:id"
                    component={ShareApplyJob}
                  />
                  <Route
                    path="/portal/aptitude-admin"
                    component={AptitudeAdmin}
                  />
                  <Route
                    path="/portal/aptitude-test"
                    component={AptitudeList}
                  />
                  <Route path="/portal/exam" component={Exam} />
                  <Route path="/portal/home" component={Home} />

                  <Route path="/portal/result" component={Result} />
                  <Route path="/portal/promo" component={Promo} />
                  <Route path="/portal/certificate" component={Certificate} />
                  <Route
                    path="/portal/interview/manage-interview"
                    component={ManageInterview}
                  />
                      <Route
                    path="/portal/other-company-view/:id"
                    component={OtherCompaniesView}
                  />
                </Switch>
              </div>
              {openRoom && (
                <InterviewJoined
                  data={interviewsWithDetails}
                  openRoom={this.handleIgnore}
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <Dialog
            open={openMessage}
            fullWidth
            maxWidth="sm"
            classes={{ paper: "open-message-div" }}
          >
            <DialogTitle className="open-message-title">
              <span>Chat</span>
              <AiOutlineCloseCircle
                onClick={() => this.setState({ openMessage: false })}
              />
            </DialogTitle>
            <DialogContent>
              {conversation.map((c) => (
                <div key={c._id} onClick={() => this.handleChat(c)}>
                  <Conversation userId={this.state.userIds} conversation={c} />
                </div>
              ))}
            </DialogContent>
          </Dialog>

          <Dialog
            open={openChat}
            fullWidth
            maxWidth="sm"
            classes={{ paper: "open-message-div-chat" }}
          >
            <DialogTitle className="open-message-title">
              {messageChats && detail ? (
                <div
                  className="main-div-images"
                  onClick={() =>
                    this.props.history.push(
                      `/portal/otherProfile/${detail?.userId}`
                    )
                  }
                >
                  <img
                    src={detail.avatar || ImageAvatar}
                    alt="images-avatar"
                    className="images-avatar-one"
                  />
                  <span>{`${detail.firstName} ${detail.lastName}`}</span>
                </div>
              ) : (
                ""
              )}
              <AiOutlineCloseCircle
                onClick={() => this.setState({ openChat: false })}
              />
            </DialogTitle>
            <DialogContent>
              {messageChats ? (
                <div>
                  <MessageChat
                    userId={this.state.userIds}
                    messageChats={messageChats}
                    onCallMessage={this.getAllMessage}
                  />
                </div>
              ) : (
                ""
              )}
            </DialogContent>
          </Dialog>
        </div>
        <MobileFooter id={id} otherProfile={otherProfile} />
      </Router>
    );
  }
}

export default withRouter(NavBar);
