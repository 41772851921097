import TrackApplicationsComponent from "./dragAndDropComponentsAts";
import styled from "styled-components";
const HeaderLayout = styled.div`
  margin-left: 5px;
  left: 312px;
  top:48px;
  border: 1px;
  height: 174px;
`;
const ApplicationTrackingSystem = () => {
  return (
    <HeaderLayout>
      <TrackApplicationsComponent />
    </HeaderLayout>

  )
}

export default ApplicationTrackingSystem