import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import isValidDomain from "is-valid-domain";
import { AiOutlineCloseCircle } from "react-icons/ai";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select, { components } from "react-select";
import DownArrow from "../../images/Downarrow.svg";
import softskill from "../MyProfile/industry.json";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import "../Jobs/buttonStyle.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import size from "./organiType.json";
import types from "./typesOrgi.json";
import { BsCreditCard2Front } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";
import Common from "../../utils/common";
import { Amplify, Auth } from "aws-amplify";
import DesktopHeader from "../desktopHeader";
import I from "../../images/Danger Circle.svg";
import PassHint from "../../images/PassHint.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    border: "none",
    height: "auto",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
    overflowX: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 8px)",
      marginLeft: "4px",
    },
  }),
  // Add more styles as needed
};

const MainLayout = styled.div`
  margin: 30px 50px 30px 120px;
  overflow-x: hidden;
  padding-bottom: 30px;
  // height:100vh;
  // width:100vw;
  @media (max-width: 1000px) {
    margin: 30px;
  }
`;
const MainLayouts = styled.div`
  margin: 40px 50px 30px 70px;
  overflow-x: hidden;
  padding-bottom: 30px;
  height: 100vh;
  // width: 100vw;
  @media (max-width: 1000px) {
    margin: 150px 50px 30px 20px;
  }
`;

const FormWrapper = styled.div`
  display: grid;
  gap: 20px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const HeaderText = styled.div`
  font-weight: normal;
  color: #362a41;
  font-family: var(--pf-medium);
  font-size: 18px;
  line-height: 38px;
  color: black;
  margin: 10px 0 10px 0;
  text-align: left;
  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 18px;
  margin: auto;
  max-width: 840px;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const PageWrapper2 = styled.div`
  background: white;
  border-radius: 18px;
  margin: auto;
  max-width: 620px;
  @media (max-width: 600px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem;
    max-width: 300px;
  }
`;
const OtpWrapper = styled.div`
  width: 624px;
  // height: 575px;
  padding: 20px;
  @media (max-width: 600px) {
    width: 320px;
  }
`;
const OtpContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 12px;
`;
const PageContainer = styled.div`
  padding: 10px 30px 10px 30px;
`;

const Label = styled.div`
  font-size: 14px;
  color: #4f4953;
  font-family: var(--pf-medium);
  font-weight: normal;
  margin: 10px 0 7px 0;
  width: 100%;
`;
const Labels = styled.div`
  font-size: 14px;
  color: #362a41;
  font-family: var(--pf-medium);
  font-weight: normal;
  margin: 10px 0 7px 0;
  width: 100%;
  text-align: center;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  // box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);

  padding: 12px;
`;

const Paragraph = styled.p`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 130%; /* 23.4px */

  // text-transform: capitalize;
`;
const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button8 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const Company = () => {
  const history = useHistory();
  const { state } = useLocation();
  const isCardActive = state?.isCardActive;
  const openNewJob = state?.openNewJob;
 
  const [success, setSuccess] = useState(false);

  function onSubmit() {
    setSuccess(true);
  }
  const [input, setInput] = useState({
    company: "",
    organisation: "",
    xakalAddress: "",
    website: "",
  });

  const optionsOne = softskill.industry.map((skill) => ({
    value: skill,
    label: skill,
  }));

  const [industry, setIndustry] = useState("");
  const [organisationType, setOrganisationType] = useState("");
  const [organisationSize, setOrganisationSize] = useState("");

  const [companyId, setCompanyId] = useState("");

  const [error, setError] = useState({
    company: "",
    organisation: "",
    xakalAddress: "",
    website: "",
  });

  const handleIndustryChnage = (event) => {
    const selectedValue = event.value;
    console.log(event.value);
    setIndustry(selectedValue);
  };
  const handleOrganisationTypeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationType(selectedValue);
  };
  const handleOrganisationSizeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationSize(selectedValue);
  };

  const [industryNameTouched, setIndustryNameTouched] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
    setErrorOne("");
    setErrorOneTwo("");
  };

  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the name of the company page!";
          }
          break;
        case "organisation":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the name of the organisation!";
          }
          break;
        case "xakalAddress":
          const regex = /^[a-zA-Z0-9-]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter Xakal address!";
          } else if (!regex) {
            stateObj[name] = "Alphabets only allowed enter without spaces!";
          } else {
            setTimeout(() => {
              submitSignup();
            }, 2000);
          }
          break;
        case "website":
          if (value === "") {
            stateObj[name] = "Please enter website / domain!";
          } else if (!isValidDomain(value)) {
            stateObj[name] = "Please enter a valid domain!";
          } else {
            setTimeout(() => {
              submitEmailSignup();
            }, 1000);
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
    // handleInputBlur()
  };
  const [organisationTypeTouched, setOrganisationTypeTouched] = useState(false);
  const [organisationSizeTouched, setOrganisationSizeTouched] = useState(false);

  const [closeForm, setCloseForm] = useState(false);
  const getOneCompany = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/company/details/${id}`
      );

      setInput(res.data.data);
      setIndustry(res.data.data);
      setOrganisationType(res.data.data);
      setOrganisationSize(res.data.data);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  useEffect(() => {
    if (companyId) {
      getOneCompany(companyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSumit = async () => {
    // debugger;
    setOpenOtp(false);

    if (
      input?.company !== "" &&
      input?.organisation !== "" &&
      input?.xakalAddress !== "" &&
      input?.website !== ""
    ) {
      console.log(isValidDomain(input.website));
      if (isValidDomain(input.website)) {
        try {
          const response = await axios.get(
            `${process.env.PUBLIC_URL}/xakal/company/checkaddress/` +
              input.xakalAddress.replaceAll(/\s/g, "")
          );
          if (response.data.success) {
            const data = {
              companyName: input?.company,
              organisation: input?.organisation,
              organisationSize: organisationSize,
              organisationType: organisationType,
              industry: industry,
              xakalAddress: input.xakalAddress.replaceAll(/\s/g, ""),
              website: input.website,
            };
            console.log("data", data);
            axios
              .post(`${process.env.PUBLIC_URL}/xakal/create/company`, data)
              .then((response) => {
                console.log(response);
                setCompanyId(response.data.companyId);
              })
              .catch((err) => err.error);
            setCloseForm(true);
            openPopup();
          } else {
            setError({
              company: input?.company === "" ? "Please enter company name" : "",
              organisation:
                input?.organisation === ""
                  ? "Please enter organisation name"
                  : "",
              xakalAddress:
                "Already used this Xakal address " +
                '"' +
                input?.xakalAddress.replaceAll(/\s/g, "") +
                '"',
              website: input?.website === "" ? "Please enter website" : "",
            });
          }
        } catch (error) {
          console.log("Error fetching details:", error);
        }
      } else {
        setError({
          jobTitle: input?.company === "" ? "Please enter company name" : "",
          organisation:
            input?.organisation === "" ? "Please enter organisation name" : "",
          xakalAddress:
            input?.xakalAddress === "" ? "Please Xakal address" : "",
          website: input?.website === "" ? "Please enter valid URL" : "",
        });
        setErrorOne("");
      }
    } else {
      setError({
        company: input.company === "" ? "Please enter company name" : "",
        organisation:
          input.organisation === "" ? "Please enter organisation name" : "",
        xakalAddress: input.xakalAddress === "" ? "Please Xakal address" : "",
        website: input.website === "" ? "Please enter website" : "",
      });
      setErrorOne("");
    }
  };

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorOtpOne, setErrorOtpOne] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isSendOtpClicked, setIsSendOtpClicked] = useState(false);

  const [openOtp, setOpenOtp] = useState(false);
  const sendEmailOtp = async () => {
    setIsResendDisabled(true);
    setResendTimer(60);
    setIsSendOtpClicked(true);
    if (input.company) {
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        website: input.website,
      };
      await axios
        .post(`${process.env.PUBLIC_URL}/xakal/company/email/sendOtp`, payload)
        .then((response) => {
          console.log(response);
          toast.success("OTP sent to Mail", {
            autoClose: 5000,
            position: "top-right",
          });
          setOpenOtp(true);
          setErrorMessage("Enter the Otp that has send to your Mail Id!");
          startResendTimer();
        })
        .catch((err) => {
          toast.error("Failed to send OTP", {
            autoClose: 5000,
            position: "top-right",
          });
          console.log(err.error);
        });
    }
  };
  const resendEmailOtp = async () => {
    setIsResendDisabled(true);
    setResendTimer(60);
    setIsSendOtpClicked(true);
    if (input.company) {
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        website: input.website,
      };
      try {
        await axios.post(
          `${process.env.PUBLIC_URL}/xakal/company/email/resendOtp`,
          payload
        );

        toast.success("OTP sent to Mail", {
          autoClose: 5000,
          position: "top-right",
        });
        setOpenOtp(true);
        setErrorMessage("Enter the Otp that has send to your Mail Id!");
        startResendTimer();
      } catch (err) {
        toast.error("Failed to send OTP", {
          autoClose: 5000,
          position: "top-right",
        });
        console.log(err.error);
      }
    }
  };
  const startResendTimer = () => {
    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      setIsResendDisabled(false);
      setResendTimer(0);
      clearInterval(timerInterval);
    }, 60000);
  };
  const formatTimer = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const [verifiedStatus, setVerifiedStatus] = useState(false);

  console.log("verifiedStatus", verifiedStatus);
  const [otps, setOtps] = useState(["", "", "", ""]);

  const verifyOtp = async () => {
    if (input.company) {
      setErrorOtpOne("");
    }
    const payload = {
      emailId: email,
      otp: otps.join(""),
      website: input.website,
    };
    console.log("otp verify", payload);

    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/company/email/verify`,
        payload
      );
      setVerifiedStatus(true);
      setErrorOtpOne(""); // Clear any previous errors
      if (response && response.data && response.data.success === true) {
        setTimeout(() => {
          setTimeout(() => {
            history.push(`/portal/company-about/${companyId}`);
            setVerifiedStatus(false);
          }, 3000);
        }, 1000);
      } else {
        // Handle the case where OTP verification fails
        setErrorOtpOne("Incorrect OTP");
        setVerifiedStatus(false);
      }
    } catch (error) {
      // Handle any axios request errors
      console.error("Error during OTP verification:", error);
      // You might want to set a general error message here
    }
  };

  const validcheckemail = () => {
    let url = input.website
      .replace(/\//g, "")
      .replace("www.", "")
      .replace("https:", "")
      .replace("http:", "");
    if (email.indexOf("@") === -1) {
      return "Invalid Email";
    } else {
      let a = email.split("@");
      if (a[1] === url) {
        return "ok";
      } else {
        return "Invalid Email (" + url + ")";
      }
    }
  };
  const optionsSize = size.size.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const optionsType = types.type.map((skill) => ({
    value: skill,
    label: skill,
  }));

  const [openSetting, setOpenSetting] = useState(false);

  const logout = () => {
    Common.logout();
  };
  const [user, setUsers] = useState("");
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  const resetState = () => {
    setEmail("");
    setErrorMessage("");
    setErrorOtpOne("");
    setIsSendOtpClicked(false);
    setResendTimer(0);
  };
  const openPopup = () => {
    resetState();
  };
  const inputRefs = useRef(otps.map(() => React.createRef()));
  const handleChangeOtp = (event, index) => {
    const updatedOtp = [...otps];

    // Handle backspace
    if (event.target.value === "" && index > 0) {
      // Clear the current digit only when backspacing
      updatedOtp[index] = "";
      setOtps(updatedOtp);

      // Focus on the previous input field
      inputRefs.current[index - 1].current.focus();
      return;
    }

    // Update the current digit
    updatedOtp[index] = event.target.value.slice(0, 1);

    // Update the OTP state
    setOtps(updatedOtp);

    // Clear the error message when any input changes
    // setErrors(null);
    setErrorOtpOne(null);
    // setValid(false);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      // setValid(true);
    }

    // Check if OTP length is equal to 4
    const fullOtp = updatedOtp.join("");
    if (fullOtp.length === 4) {
      // setValid(true);
    } else {
      // setValid(false);
    }

    // Focus on the next input field if available
    if (index < otps.length - 1 && event.target.value !== "") {
      inputRefs.current[index + 1].current.focus();
    }
  };
  const handlePaste = (e, index) => {
    e.preventDefault();

    // Get the pasted content
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);

    const updatedOtp = [...otps];

    // Insert the pasted content into the OTP input fields
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otps.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }

    // Update the OTP state
    setOtps(updatedOtp);

    // Focus on the next input field if available
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i + 1 < otps.length) {
        inputRefs.current[index + i + 1].current.focus();
      }
    }
  };

  const CaretDownIcon = () => {
    return (
      <img
        src={DownArrow}
        alt="img"
        style={{ width: "30px", background: "#F4F4F6", padding: "0" }}
      />
    );
  };

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const BackToCompany = () => {
    if (openOtp) {
      setOpenOtp(false);
      setOtps(["", "", "", ""]);
      setErrorMessage(null);
    } else if (closeForm) {
      setCloseForm(false);
      getOneCompany(companyId);
    } else if (isCardActive === "job") {
      history.push({
        pathname: "/portal/jobs",
        state: {
          isCardActive: isCardActive,
          openNewJob: openNewJob,
        },
      });
    } else if (isCardActive === "favorites") {
      history.push({
        pathname: "/portal/favorites",
        state: {
          isCardActive: isCardActive,
          openNewJob: openNewJob,
          
        },
      });
    } else if (isCardActive === "applied") {
      history.push({
        pathname: "/portal/applied-jobs",
        state: {
          isCardActive: isCardActive,
          openNewJob: openNewJob,
          
        },
      });
    } else if (isCardActive === "manage-job") {
      history.push({
        pathname: "/portal/manage-jobs",
        state: {
          isCardActive: isCardActive,
          openNewJob: openNewJob,
        
        },
      });
    } else if (isCardActive === "archived") {
      history.push({
        pathname: "/portal/archievedJobs",
        state: {
          isCardActive: isCardActive,
          openNewJob: openNewJob,
         
        },
      });
    } else if (isCardActive === "draft") {
      history.push({
        pathname: "/portal/drafts",
        state: {
          isCardActive: isCardActive,
          openNewJob: openNewJob,
         
        },
      });
    } else {
      history.goBack();
    }
  };
  const [errorOne, setErrorOne] = useState(false);
  const [errorOneTwo, setErrorOneTwo] = useState(false);

  const submitSignup = async (e) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/validateCompanyEmail`,
        {
          xakalAddress: input.xakalAddress,
        }
      );
      console.log(response);

      if (response.data.success === true) {
        setErrorOne("Tag already exists");

        console.log(response.data.message);
      } else if (response.data.success === false) {
        // Xakal address already exists
        setErrorOne(null);
      } else {
        // Handle other cases if needed
        setErrorOne(null);
      }
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  const submitEmailSignup = async (e) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/validateWebsiteEmail`,
        {
          website: input.website,
        }
      );

      if (response.data.success === true) {
        setErrorOneTwo("Domain already exists");

        console.log(response.data.message);
      } else if (response.data.success === false) {
        // Xakal address already exists
        setErrorOne(null);
      } else {
        // Handle other cases if needed
        setErrorOne(null);
      }
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  return (
    <div>
      <div className="header-layouts">
      <DesktopHeader
        title={"Company page"}
        isSearchActive={false}
        backbtn
        back={BackToCompany}
      />
      </div>
      {!closeForm ? (
        <MainLayout>
          <PageWrapper>
            <PageContainer>
              <HeaderText>{"Create company"}</HeaderText>
              <hr />
              <ToastContainer />

              <FormWrapper>
                <div>
                  <Label style={{ fontSize: "14px" }}>
                    Company Page Name
                    <span className="text-danger">*</span>
                  </Label>
                  <InputGroup className="">
                    <Form.Control
                      placeholder="Enter your company name"
                      aria-label="Job Title"
                      name="company"
                      onBlur={validateInput}
                      className="footer-header font"
                      value={input?.companyName}
                      aria-describedby="basic-addon1"
                      onChange={handleChange}
                      style={{
                        background: "rgba(54, 42, 65, 0.04)",
                        height: "48px",
                        borderRadius: "12px",
                        color: "rgba(198, 202, 210)",
                        fontweight: "normal",
                      }}
                    />
                  </InputGroup>
                  {error.company && (
                    <span className="f12">{error.company}</span>
                  )}
                </div>
                <div>
                  <Label>
                    Register Company Name<span className="text-danger">*</span>
                  </Label>
                  <InputGroup className="">
                    <Form.Control
                      placeholder="Enter your register company name"
                      aria-label="Job Title"
                      name="organisation"
                      className="footer-header font"
                      onBlur={validateInput}
                      value={input?.organisation}
                      aria-describedby="basic-addon1"
                      onChange={handleChange}
                      style={{
                        height: "48px",
                        borderRadius: "12px",

                        color: "rgba(198, 202, 210)",
                        fontweight: "normal",
                      }}
                    />
                  </InputGroup>
                  {error.organisation && (
                    <span className="f12">{error.organisation}</span>
                  )}
                </div>
              </FormWrapper>
              <FormWrapper>
                <div>
                  <Label className="icon">
                    Xakal Address<span className="text-danger">*</span>
                    <img src={I} alt="img" style={{ cursor: "pointer" }} />
                    <span className="xakalinfo">
                      <img src={PassHint} alt="img" />
                      <span>
                        you can share this unique xakal address to other people
                        whom you wish to visit this page
                      </span>
                    </span>
                  </Label>

                  <div style={{ display: "flex" }}>
                    <div className="xakal-address" style={{ height: "48px" }}>
                      www.xakal.com/
                    </div>
                    <InputGroup className="">
                      <Form.Control
                        placeholder="Craft an exclusive tag to represent "
                        aria-label="Job Title"
                        name="xakalAddress"
                        className="font footer-header"
                        onBlur={validateInput}
                        value={input?.xakalAddress}
                        aria-describedby="basic-addon1"
                        onChange={handleChange}
                        style={{
                          height: "48px",
                          borderRadius: "0px 12px 12px 0 ",
                          background: "rgba(54, 42, 65, 0.04)",
                          width: "100%",
                          color: "rgba(198, 202, 210)",
                          fontweight: "normal",
                        }}
                      />
                    </InputGroup>
                  </div>

                  {errorOne && <span className="f12">{errorOne}</span>}
                  {error.xakalAddress && (
                    <span className="f12">{error.xakalAddress}</span>
                  )}
                </div>
                {/*<div className="hover-content-icon content-icon">*/}
                {/*  <div className="hover-content">*/}
                {/*    you can share this unique xakal adress to other people whome you*/}
                {/*    wish to visit this page.*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div>
                  <Label>
                    Website / Domain<span className="text-danger">*</span>
                  </Label>
                  <InputGroup className="">
                    <Form.Control
                      placeholder="Enter your URL"
                      aria-label="Job Title"
                      name="website"
                      className="footer-header font"
                      onBlur={validateInput}
                      value={input?.website}
                      aria-describedby="basic-addon1"
                      onChange={handleChange}
                      style={{
                        height: "48px",
                        borderRadius: "12px",
                        background: "rgba(54, 42, 65, 0.04)",
                        color: "rgba(198, 202, 210)",
                        fontweight: "normal",
                      }}
                    />
                  </InputGroup>
                  {errorOneTwo && <span className="f12">{errorOneTwo}</span>}
                  {error.website && (
                    <span className="f12">{error.website}</span>
                  )}
                </div>
              </FormWrapper>
              <FormWrapper>
                <div>
                  <Label>Industry</Label>
                  <div>
                    <Select
                      className="footer-header font mb-3"
                      components={{ Placeholder, DropdownIndicator }}
                      styles={
                        customStyles
                        // height: "48px",
                        // control: (base, state) => ({
                        //   ...base,
                        //   width: "100%",
                        //   backgroundColor: "#F4F4F6",
                        //   border: "1px solid #F4F4F6",
                        //   height: "48px",
                        //   borderRadius: "12px",
                        //   color: "rgba(198, 202, 210)",
                        //   fontweight: "normal",
                        // }),
                      }
                      menuPlacement="top"
                      name="industry"
                      value={optionsOne.find(
                        (option) => option.value === industry
                      )}
                      onBlur={() => setIndustryNameTouched(true)}
                      onChange={handleIndustryChnage}
                      options={optionsOne}
                      isSearchable={true}
                      placeholder="Select"
                    />

                    {industryNameTouched && industryNameTouched.industry && (
                      <span className="f12">
                        {industryNameTouched.industry}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <Label>Organisation Size</Label>
                  <div>
                    <Select
                      className="footer-header font mb-3"
                      components={{ Placeholder, DropdownIndicator }}
                      styles={
                        customStyles
                        // height: "48px",
                        // control: (base, state) => ({
                        //   ...base,
                        //   width: "100%",
                        //   backgroundColor: "#F4F4F6",
                        //   border: "1px solid #F4F4F6",
                        //   color: "rgba(198, 202, 210)",
                        //   fontweight: "normal",
                        //   height: "48px",
                        //   borderRadius: "12px",
                        // }),
                      }
                      menuPlacement="top"
                      name="organisationSize"
                      value={optionsSize.find(
                        (option) => option.value === organisationSize
                      )}
                      onBlur={() => setOrganisationSizeTouched(true)}
                      onChange={handleOrganisationSizeChange}
                      isSearchable={true}
                      options={optionsSize}
                      placeholder="Select"
                    />

                    {organisationSizeTouched &&
                      organisationSizeTouched.organisationSize && (
                        <span className="text-danger">
                          {organisationSizeTouched.organisationSize}
                        </span>
                      )}
                  </div>
                </div>
              </FormWrapper>
              <FormWrapper>
                <div>
                  <Label>Organisation Type</Label>
                  <div>
                    <Select
                      className="footer-header font mb-3"
                      components={{ Placeholder, DropdownIndicator }}
                      styles={
                        customStyles
                        // height: "48px",
                        // control: (base, state) => ({
                        //   ...base,
                        //   width: "100%",
                        //   backgroundColor: "#F4F4F6",
                        //   border: "1px solid #F4F4F6",
                        //   height: "48px",
                        //   borderRadius: "12px",
                        //   color: "rgba(198, 202, 210)",
                        //   fontweight: "normal",
                        // }),
                      }
                      menuPlacement="top"
                      name="organisationType"
                      value={optionsType.find(
                        (option) => option.value === organisationType
                      )}
                      onBlur={() => setOrganisationTypeTouched(true)}
                      onChange={handleOrganisationTypeChange}
                      isSearchable={true}
                      options={optionsType}
                      placeholder="Select"
                    />

                    {organisationTypeTouched &&
                      organisationTypeTouched.organisationType && (
                        <span className="f12">
                          {organisationTypeTouched.organisationType}
                        </span>
                      )}
                  </div>
                </div>
                <div style={{ width: "50%" }}></div>
              </FormWrapper>
            </PageContainer>
            <DateWrapper>
              {/* {companyId ? ( */}
              {/* <button
                  className="button2"
                  style={{ width: "100px", height: "43px" }}
                  onClick={handleUpdateSumit}
                >
                  Create
                </button> */}
              {/* ) : ( */}
              <button
                className="button2"
                style={{ width: "100px", height: "43px" }}
                onClick={handleSumit}
              >
                Create
              </button>
              {/* )} */}
              {/* <Button3 type="submit" disabled={!enable} style={{opacity: enable ? "100%" : "50%"}}>  */}

              {/* </Button3> */}
            </DateWrapper>
          </PageWrapper>
        </MainLayout>
      ) : (
        <MainLayouts>
          <PageWrapper2 style={{ padding: "20px" }}>
            <Paragraph>
              To complete company page creation, kindly verify that you are an
              authorised representative of the company "{input.company}"
            </Paragraph>
            <hr style={{ height: "0.5px", marginTop: "20px" }} />
            {!openOtp ? (
              <div style={{ maxWidth: "365px", margin: "auto" }}>
                <OtpContainer>
                  <Label>Email</Label>
                  <input
                    type="text"
                    name="email"
                    className="font"
                    style={{
                      borderRadius: "8px",
                      // border: "1px solid black",
                      background: "rgba(54, 42, 65, 0.04)",
                      width: "100%",
                      height: "48px",
                      padding: "20px",
                      // color:"#C6CAD2"
                    }}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      validcheckemail();
                    }}
                    placeholder="Enter your email"
                  />
                  {email && validcheckemail() !== "ok" && (
                    <span className="f12">{validcheckemail()}</span>
                  )}

                  <button
                    className="button3 mx-auto mt-4"
                    style={{
                      maxWidth: "360px",
                      width: "100%",
                      minWidth: "180px",
                    }}
                    disabled={validcheckemail() !== "ok"}
                    onClick={() => sendEmailOtp()}
                  >
                    Get OTP
                  </button>
                </OtpContainer>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <span style={{ color: "green" }}>{errorMessage}</span>
                  </div>
                </div>
              </div>
            ) : (
              <OtpWrapper>
                <Labels>Enter the OTP sent to {email}</Labels>

                <OtpContainer>
                  <div
                    className="otp-group"
                    style={{
                      maxWidth: "320px",
                      margin: "auto",
                    }}
                  >
                    {otps.map((digit, idx) => (
                      <input
                        key={idx}
                        type="text"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        pattern="\d"
                        className="otp-inputs"
                        value={digit}
                        id={`otp-input-${idx}`}
                        ref={inputRefs.current[idx]}
                        onChange={(e) => handleChangeOtp(e, idx)}
                        onPaste={(e) => handlePaste(e, idx)}
                        // disabled={disableFields}
                      />
                    ))}
                  </div>
                  {!verifiedStatus ? (
                    <div
                      style={{
                        margin: "auto",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "12px",
                          maxWidth: "360px",
                          width: "100%",
                          // margin: "auto",
                        }}
                      >
                        {isSendOtpClicked && (
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#362A41",
                              marginLeft: "-30px",
                            }}
                          >
                            {resendTimer > 0
                              ? `Time remaining: ${formatTimer(resendTimer)}`
                              : !isResendDisabled && (
                                  <span
                                    onClick={resendEmailOtp}
                                    className="resend-content"
                                    style={{ marginTop: "50px" }}
                                  >
                                    Didn't receive the code yet?{" "}
                                    <span
                                      className="resend"
                                      style={{
                                        marginLeft: "3px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Resend
                                    </span>
                                  </span>
                                )}
                          </span>
                        )}
                      </div>
                      {/* {valid && ( */}
                      <button
                        className="button3 mx-auto"
                        style={{
                          background: "#362a41",
                          color: "white",
                          marginTop: "74px",
                          width: "100%",
                          minWidth: "240px",
                          maxWidth: "360px",
                          fontSize: "16px",
                        }}
                        onClick={() => verifyOtp()}
                      >
                        Verify
                      </button>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          margin: "auto",
                          color: "#8DB600",
                          fontSize: "18px",
                          fontweight: "normal",
                          padding: "40px 0px 20px 0",
                        }}
                      >
                        Otp verified successfully
                      </div>
                    </>
                  )}
                  {errorOtpOne && (
                    <div
                      style={{
                        margin: "auto",
                        color: "red",
                        fontSize: "18px",
                        fontweight: "normal",
                        padding: "40px 0px 20px 0",
                      }}
                    >
                      {errorOtpOne}
                    </div>
                  )}
                </OtpContainer>
              </OtpWrapper>
            )}
          </PageWrapper2>
        </MainLayouts>
      )}

      {/* <Dialog open={open} onClose={handleClosePopup}> */}
      {/* <DialogTitle
          style={{
            display: "flex",
            fontFamily: "Inter-Medium",
            position: "relative",
            justifyContent: "end",
          }}
        >
          <AiOutlineCloseCircle onClick={() => setOpen(false)} />
        </DialogTitle>
        <DialogContent>
         
        </DialogContent> */}
      {/* </Dialog> */}
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Company;
