import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TopHeader from "../MyProfile/TopHeader";
import avatar1 from "../../images/company.png";
import Admin from "../../images/domains.png";
import Domain from "../../images/setting.png";
import Employee from "../../images/team-management.png";
import Share from "../../images/Share.png";

const Description = styled.div`
color: #A8A1AC;
font-family: General Sans,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: normal;
line-height: normal;
text-transform: capitalize;
margin:20px;
display:flex;
gap:10px;
`
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;

const Heading = styled.p`
  font-size: 16px;
  font-weight: bolder;
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: bolder;
`;
const Profile = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 0px;
`;
const Img = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;
const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  margin: 1rem 0rem;
  padding:10px 40px;
  min-height:100px;
  display:flex;
  justify-content:space-between;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Header = styled.div`
color: #362A41;
font-family: Inter-Medium,sans-serif;
font-size: 24px;
font-style: normal;
font-weight: normal;
line-height: normal;
margin-top:15px;
`
export function InstituteEmployeePage(props) {
  const history = useHistory();
  const [employees, setEmployees] = useState([]);
  const [id, setId] = useState();
  const [openPage, setOpenPage] = useState(false);
  const [isListVisible, setListVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState();
  const getCurrentEmployees = async (id) => {
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/institute/employee`, { website: id })
      .then((response) => {
        console.log("employees", response?.data);
        setEmployees(response?.data);
      })
      .catch((err) => err.error);
  };

  const getOneCompanydetail = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details/${id}`)
      .then((response) => {
        console.log(response, "companyOneDetail");
        setData(response.data.data);
      })
      .catch((err) => err.error);
  };
  const handleOpen = () => {
    setSuccess(true);
    // setUserType("");
    // setSelectedEmployee("");
  };
  const toggleList = () => {
    setOpenPage(!openPage);
    setListVisible(!isListVisible);
  };
  const handleSubmitInstituteTwo = (comId) => {
    history.push(`/portal/institute-domain/${comId}`);
  };
  useEffect(() => {
    const company = props.location.state;
    console.log("companyId", company);
    setId(company);
    getOneCompanydetail(company.instituteId);
    getCurrentEmployees(company.website);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <TopHeader />
      <MainLayout>
        <div className="Myprofile-container" style={{ margin: "0" }}>
          <div className="Myprofile-topcontainer">
            <div className="myp-avatar">
              {data && data.companyLogo ? (
                <>
                  <div>
                    <img
                      src={data && data.companyLogo}
                      alt="img"
                      style={{
                        width: "190px",
                        height: "190px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <img
                    src={avatar1}
                    alt="img"
                    style={{
                      width: "160px",
                      height: "160px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="myp-wrapper" style={{ display: "block" }}>
            <div className="company" onClick={() => history.push(`/portal/editview-institute/${data && data.instituteId}`)}>{data && data.collegeName}</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Description style={{ marginLeft: "40px" }}>{data && data.companyDescription}</Description>
                <Description style={{ marginLeft: "40px" }}>{data && data.companyTagline}</Description>
                <Description style={{ marginLeft: "40px" }}>
                  <div style={{ margin: "3px 0" }}>{data && data.organisationType}</div>|
                  <div>{data && data.additionalAddress.map((x) => {
                    return (
                      <p >
                        {x.city}, {x.state}
                      </p>
                    );
                  })}
                  </div>
                </Description>
              </div>
              <img src={Share} alt="img" style={{ width: "40px", height: "40px", cursor: "pointer" }} onClick={() => toggleList(data && data.companyId)} />
            </div>
            {openPage ? (
              <div className="DeActWrapper">
                <div></div>
                <div className="DeActPage" onClick={() => handleOpen(id._id)}>Deactivate Page</div>
              </div>
            ) : ""}
            <div className="Edu-data-wrapper3 social-container">
              {/* <div className="social-wrapper">
                  <div className="social-btn"><img src={Edit} alt="img" className="social-img"/></div>
                  <div className="social-btn" onClick={() =>history.push(`/portal/edit-company/${data && data.companyId}`)}>Edit Page</div>
                  </div> */}
              <div className="social-wrapper">
                <div className="social-btn"><img src={Admin} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => history.push({
                  pathname: "/portal/institute-admin-domain",
                  state: { instituteId: data && data.instituteId, website: data && data.website, }
                })}>Manage Admin</div>

              </div>
              <div className="social-wrapper">
                <div className="social-btn"><img src={Domain} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => handleSubmitInstituteTwo(data && data.instituteId)}>Manage Domains</div>

              </div>
              <div className="social-wrapper">
                <div className="social-btn"><img src={Employee} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => history.push({ pathname: "/portal/institute-employees", state: { instituteId: data && data.instituteId, website: data && data.website } })}>Manage Employees</div>

              </div>
            </div>
          </div>
        </div>
      </MainLayout>
      <MainLayout>
        <Header>Manage Employees</Header>
        <PageWrapper>
          <div>
            <Heading>Profile</Heading>
          </div>
          <div>
            <Heading>Date of Joining</Heading>
          </div>
          <div>
            <Heading>Actions</Heading>
          </div>
        </PageWrapper>
        {employees?.map((employee, index) => (
          <PageWrapper key={index}>
            <Profile>
              <Img src={employee?.user?.avatar} alt={""} />
              <div>
                <Label>
                  {employee?.user?.firstName +
                    " " +
                    employee?.user?.middleName +
                    " " +
                    employee?.user?.lastName}
                </Label>
                <Label>{employee?.experience[0]?.designation}</Label>
              </div>
            </Profile>
            <div>
              <Label>{employee?.experience[0]?.startDate}</Label>
            </div>
            <div>
              <VisibilityIcon
                style={{ fontSize: 24 }}
                onClick={() => {
                  history.push({
                    pathname: `/portal/applicant-profile/${employee?.user?.userId}`,
                  });
                }}
              />
            </div>
          </PageWrapper>
        ))}
      </MainLayout>
    </div>
  );
}
