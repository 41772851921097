import React, { useEffect, useState } from "react";
import Star from "../../images/StarIcon.svg";
import "./feedback.css";
import Table from "react-bootstrap/Table";
import axios from "axios";
import avatar from "../../images/company.png";
import ProgressBar from "./progressBar";
import { Country } from "country-state-city";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const ViewFeedback = ({ bookingId, values, candidateId }) => {
  // const { bookingId } = useParams();
  const [value, setValue] = useState();
  // eslint-disable-next-line no-unused-vars
  const [serviceId, setServiceId] = useState();
  // eslint-disable-next-line no-unused-vars
  const [downloading, setDownloading] = useState(false);
  const [candidateDetail, setCandidateDetail] = useState({});
  const [skill, setSkill] = useState([]);
  const getOneValue = async(bookingId) => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${bookingId}`)
        const bookingSlot = response.data.bookingSlot;
        setValue(bookingSlot);
        setServiceId(bookingSlot?.serviceId);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  // const [loading, setIsLoading] = useState(true);

  const fetchFeedback = async (bookingId) => {
    const response = await axios.get(
      `${process.env.PUBLIC_URL}/xakal/get-feedback/${bookingId}`
    );
    // setIsLoading(false);
    const latest = response.data.interview.length - 1;
    setSkill(response.data.interview[latest]?.skill);
    setCandidateDetail(response.data.interview[latest]?.candidateDetail);
  };
  const originalDate = value?.date;
  const formattedDate = new Date(originalDate).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  useEffect(() => {
    if (bookingId) {
      fetchFeedback(bookingId);
      getOneValue(bookingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);
  // eslint-disable-next-line no-unused-vars
  const [aboutDetail, setDetail] = useState();
  const getOneDetail = async(id) => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${id}`)
        console.log(response);
        setDetail(response.data.aboutOne);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    getOneDetail(candidateId);
  }, [candidateId]);
  // eslint-disable-next-line no-unused-vars
  const [generatePDF, setGeneratePDF] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const handleDownloadCertificate = () => {
    setDownloading(true); // Start downloading

    // Temporarily set generatePDF to true to render the Certificate component
    setGeneratePDF(true);

    setTimeout(() => {
      const input = document.getElementById("certificate-content");

      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        // Determine the orientation based on the canvas dimensions
        const orientation =
          canvas.width > canvas.height ? "landscape" : "portrait";
        const pdf = new jsPDF(orientation);

        // Set the width and height of the PDF document based on orientation
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("certificate.pdf");

        // Reset generatePDF state after PDF generation
        setGeneratePDF(false);
        setDownloading(false); // Finish downloading
      });
    }, 500); // Adjust timing as needed to ensure rendering completes
  };
  const getcn = (cn) => {
    if (cn && cn.length === 2) {
      let a = Country.getCountryByCode(cn);
      if (a && a.name) {
        return a.name;
      }
    } else {
      return cn;
    }
  };
  return (
    <>
      <div id="feedback-container" className="feedback-container">
        <div id="card-container" className="card-container">
          <div
            style={{
              background: "#FFF",
              padding: "20px 20px 0px 20px",
              marginTop: "20px",
              borderRadius: "16px",
              minWidth: "680px",
              border: "1px solid grey",
            }}
          >
            <div style={{ marginLeft: "20px" }}>
              <label
                className="feed-name"
                style={{
                  fontSize: "18px",
                  marginLeft: "-20px",
                  display: "grid",
                  textAlign: "center",
                }}
              >
                Skills - Rating and Feedback
              </label>
            </div>

            <hr />
            <div style={{ display: "flex" }}>
              <>
                <div className="left-section">
                  <div>
                    {candidateDetail && candidateDetail?.aboutOne?.avatar ? (
                      <img
                        src={
                          candidateDetail && candidateDetail?.aboutOne?.avatar
                        }
                        alt="uterrs"
                        className="img-card-div"
                      />
                    ) : (
                      <img src={avatar} alt="uterrs" className="img-card-div" />
                    )}
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <div className="feed-name">
                      {candidateDetail?.aboutOne?.firstName}{" "}
                      {candidateDetail?.aboutOne?.middleName}{" "}
                      {candidateDetail?.aboutOne?.lastName}
                    </div>

                    <div></div>
                    {candidateDetail &&
                    candidateDetail?.aboutOne?.primaryAddress ? (
                      <div className="feed-address">
                        {candidateDetail &&
                          candidateDetail?.aboutOne?.primaryAddress?.city}
                        ,{" "}
                        {candidateDetail &&
                          getcn(
                            candidateDetail?.aboutOne?.primaryAddress?.country
                          )}{" "}
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className="right-section">
                  <div
                    style={{
                      background: "rgba(247, 247, 248, 1)",
                      margin: "-20px",
                      padding: "10px 20px",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="feed-name" style={{ fontSize: "16px" }}>
                      {`${values?.firstName} ${values?.lastName}`} Interviewer
                    </div>
                    <div className="feed-name" style={{ fontSize: "16px" }}>
                      {value?.designation} Interview
                    </div>
                    <div
                      className="feed-address"
                      style={{ fontSize: "12px", marginTop: "0" }}
                    >
                      {value?.designation}
                    </div>
                  </div>
                  <div style={{ margin: "40px 0px 0 0" }}>
                    <div className="feed-address" style={{ fontSize: "12px" }}>
                      Interview Date:
                      <div style={{ color: "#4F4953", marginLeft: "5px" }}>
                        {" "}
                        {formattedDate} || {value?.time}
                      </div>
                    </div>
                    <div className="feed-address" style={{ fontSize: "12px" }}>
                      Duration:
                      <div style={{ color: "#4F4953", marginLeft: "40px" }}>
                        {" "}
                        {value?.duration}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
            <div className="table-class">
              <Table className="table-bor">
                <thead>
                  <tr
                    style={{
                      color: "#4F4953",
                      fontFamily: "Inter-Medium,sans-serif",
                      fontSize: "16px",
                      fontweight: "normal",
                      background: "rgba(134, 83, 182, 0.05)",
                      // textAlign: "center",
                    }}
                  >
                    <th style={{ width: "10%" }}>Skill</th>
                    <th style={{ width: "10%" }}>Strength</th>
                    <th style={{ width: "10%" }}>Weakness</th>
                    <th style={{ width: "10%" }}>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {skill?.map((a, index) => {
                    let userRating = a.ratings;

                    return (
                      <tr key={`${a.name}-${index}`}>
                        <td>
                          <div
                            className="Edu-data-wrapper feed-wrappers"
                            style={{ gap: "10px" }}
                          >
                            {/* <div> */}
                            <div className="skill-content">{a.value}</div>

                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                marginTop: "-8px",
                              }}
                            >
                              <div className="star-icon">
                                <img
                                  src={Star}
                                  alt="img"
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    marginTop: "10px",
                                  }}
                                />
                              </div>
                              <div className="star-rating-no">{userRating}</div>
                            </div>
                            {/* </> */}
                          </div>
                          <div
                            style={{
                              position: "relative",

                              gap: "10px",
                            }}
                          >
                            <div>
                              <ProgressBar
                                value={userRating}
                                style={{ width: "100px" }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <ul className="feedback-details">
                            <li>{a.strengths[0]}</li>
                          </ul>
                        </td>
                        <td>
                          <ul className="feedback-details">
                            <li>{a.weaknesses[0]}</li>
                          </ul>
                        </td>
                        <td>
                          <ul className="feedback-details">
                            <li>{a.comments[0]}</li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="feed-name">Rate Other Skill</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewFeedback;
