import React, { useEffect, useState } from "react";
import "../../styles/myprofile.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaRupeeSign } from "react-icons/fa";
import styled from "styled-components";
import Alaram from "../../images/Alarm.svg";
import { Dialog, DialogContent } from "@mui/material";
import axios from "axios";
import InterviewPopup from "./interviewPopup";
import { ToastContainer } from "react-toastify";

import TimezoneConverter from "./timepicker";
import EditTimePicker from "./editTimePicker";

import SlotBooking from "./slotBooking";
const PreferenceWrapper = styled.div`
  display: flex;
  gap: 20px;
  // margin-top: -8px;
  // margin: 20px 0;

  span {
    position: relative;
    top: 0px;
    cursor: pointer;
  }
`;
const BookWrapper = styled.div`
  // background: rgba(243, 238, 248, 1);
  // height: 185px;
  // width: 180px;
  // border-radius: 8px;
  // padding: 20px;
`;
const Preference = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #362a41;
  position: relative;
  bottom: 5px;
  cursor: pointer;
`;
// eslint-disable-next-line no-unused-vars
const Description = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  span {
    color: rgba(134, 83, 182, 1);
    cursor: pointer;
  }
`;
const DesignationTitle = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(168, 161, 172, 1);
  margin-top: 5px;
`;
const Designation = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(168, 161, 172, 1);
  background: rgba(244, 232, 255, 1);
  border-radius: 8px;
  padding: 5px 10px;
`;
// const Dots = styled.div`
// position: relative;
// right: 25px;
// top: 10px;
// background: white;
// border-radius: 3px;
// height: fit-content;
// /* padding: 2px;
// `

const ImageContainer = styled.div`
  position: relative;
`;

const OtherService = ({ id, input }) => {
  const [editPicker, setEditPicker] = useState(false);

  const [data, setData] = useState([]);
  const [ids, setIds] = useState("");
  const getALLInterview = async() => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-interview-user/${id}`)
      
        setIds(response.data.interview._id);
        setData(response.data.interview);
      }
      catch(error) {
        console("Error fetching details:",error);
      };
  };
  useEffect(() => {
    getALLInterview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line no-unused-vars
  const settings = {
    autoplay: true,
    arrows: true, // Set arrows to true to make them visible
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
  };
  console.log(data, "datatattatatatta");
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <span>{timeAgo}</span>;
  };
  const [openForm, setOpenForm] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const [selectedData, setSelectedData] = useState(null);
  const handleOpen = (data) => {
    setOpenForm(true);
    setSelectedData(data);
  };
  const closeForm = () => {
    setOpenForm(false);
  };
  const openDateForm = (id) => {
    setOpenDate(true);
    setIds(id);
  };
  const [openConfirm, setConfirmPopup] = useState(false);
  const [slotId, setSlotId] = useState("");
  const openConfirmPopup = (id) => {
    setConfirmPopup(true);
    setOpenDate(false);
    setSlotId(id);
  };
  const openFirst = (id) => {
    setEditPicker(true);
    setConfirmPopup(false);
    setSlotId(id);
  };
  const close = () => {
    setConfirmPopup(false);
  };
  // eslint-disable-next-line no-unused-vars
  const availableSkills = [
    "Sql",
    "UI Design",
    "UX Design",
    "Web Development",
    "Wireless Communications and Networks",
    "React JS",
    "Recruiter",
    "Sales",
    "Scaled Agile",
    "Scrum",
    "SEO",
    "snowflake",
    "Spring Boot",
    "Spring Framework",
    "NLP",
    "Node js",
    "NumPy",
    "Object-Oriented Programming (OOP)",
    "PCB Design",
    "Persuasion",
    "Postgre Sql",
    "Prompt tuning",
    "Python",
    "Hardware Development",
    "Hardware Product Development",
    "HTML",
    "Internet of Things",
    "Java Script",
    "Java",
    "Machine Learning",
    "Marketing",
    "MERN",
    "Negotiation",
    "net",
    "Docker Products and Business Requirements",
    "Electronic Circuit Design",
    "Electronics Hardware Design",
    "Embedded Systems",
    "ETL",
    "Figma",
    "finance",
    "Graphic Designing",
    "Azure DevOps",
    "Big Data",
    "Circuit Design",
    "Circuit Designer Entry Level",
    "Coaching",
    "Communication",
    "Core Java",
    "CSS",
    "Data Analytics",
    "Data Engineer",
    "Data Modeling",
    "Design",
    "Accounting",
    "Adobe",
    "Agile Methodologies",
    "AI",
    "Angular",
    "AWS",
  ];
  // eslint-disable-next-line no-unused-vars
  const [isScrolled, setIsScrolled] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [inputId, setInputId] = useState();

  const inputFormInput = (id) => {
    setInputId(id);
  };
  const openEditBook = (id) => {
    setConfirmPopup(true);
    setEditPicker(false);
    setSlotId(id);
  };
  return (
    <>
      <ToastContainer />
      {/* <div> */}
      <div className="myp-wrapper2">
        {data &&
          data.map((i, index) => {
            return (
              <div
                // className={openForm ? "myp-wrap4" : "myp-wrapper4"}
                key={index}
              >
                <ImageContainer>
                  <div>
                    {/* <Slider {...settings}>
                      {i.image.length ? (
                        i.image.map((item, index) => (
                          <div key={index}>
                            {item.fileType && (
                              <>
                                {item.fileType.startsWith("image") ? (
                                  <img
                                    src={item.url}
                                    style={{ width: "100%", height: "auto" }}
                                    alt={`Images ${index}`}
                                  />
                                ) : item.fileType.startsWith("video") ? (
                                  <div style={{ position: "relative" }}>
                                    <video
                                      src={item.url}
                                      style={{ width: "100%", height: "auto" }}
                                      controls
                                      alt={`Video ${index}`}
                                    />
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                      }}
                                    >
                                      <FaPlayCircle
                                        style={{
                                          fontSize: "3em",
                                          color: "white",
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>
                        ))
                      ) : i.skill && i.skill.length ? (
                        i.skill.map((skill, skillIndex) =>
                          availableSkills.includes(skill.label) ? (
                            <div key={skillIndex} className="int-img">
                              <div>{skill.label}</div>
                            </div>
                          ) : (
                            <img
                              src={interviewLogo}
                              className="int-slider-img"
                              alt={`Images ${index}`}
                            />
                          )
                        )
                      ) : (
                        <img
                          src={interviewLogo}
                          className="int-slider-img"
                          alt={`Images ${index}`}
                        />
                      )}
                    </Slider> */}
                  </div>
                </ImageContainer>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                > */}
                <div
                  className={
                    openForm
                      ? "job-card-wrap myp-int-wrap "
                      : "job-card-wrapper"
                  }
                >
                  <div className="other-p-wrap">
                    <div>
                      <Preference onClick={() => handleOpen(i)}>
                        {i.interviewTitle}
                      </Preference>
                      <div className="other-p-time">
                        {" "}
                        <TimeAgo
                          style={{}}
                          timestamp={i.timestamps.createdAt}
                        />
                      </div>
                    </div>
                    <div className="other-p-tit-line"></div>
                    {i.inrService ? (
                      <BookWrapper>
                        <div className="other-p-dur-wrap">
                          <div className="other-p-dur">{i.duration}</div>
                          <img src={Alaram} alt="img" />
                        </div>
                        <div className="other-p-cost">
                          <button className="">Free</button>
                        </div>
                      </BookWrapper>
                    ) : (
                      <BookWrapper>
                        <div className="other-p-dur-wrap">
                          <div className="other-p-dur">{i.duration}</div>
                          <img src={Alaram} alt="img" />
                        </div>
                        <div className="other-p-cost">
                          <button className="">
                            <FaRupeeSign />
                            {i &&
                              new Intl.NumberFormat("en-IN", {}).format(i?.inr)}
                          </button>
                        </div>
                      </BookWrapper>
                    )}
                    {/* <Description>
                      {`${i.description.substring(0, 50)}`}
                      {i.description.length > 50 ? (
                        <span onClick={() => handleOpen(i)}>...more</span>
                      ) : (
                        " "
                      )}
                    </Description> */}
                  </div>
                  <hr />
                  {/* <div style={{ marginTop: "20px" }}>
                      <PreferenceWrapper>
                        <DesignationTitle>
                          Skills To be Evaluated
                        </DesignationTitle>

                        {openForm ? (
                          <>
                            {i.skill.slice(0, 2).map((item, index) => (
                              <Designation key={index}>
                                {item.label}
                              </Designation>
                            ))}
                            {i.skill.length > 2 ? (
                              <Designation onClick={() => handleOpen(i)}>
                                +{i.skill.length - 2}
                              </Designation>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <>
                            {i.skill.slice(0, 3).map((item, index) => (
                              <Designation key={index}>
                                {item.label}
                              </Designation>
                            ))}
                            {i.skill.length > 3 ? (
                              <Designation onClick={() => handleOpen(i)}>
                                +{i.skill.length - 3}
                              </Designation>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </PreferenceWrapper>
                    </div> */}

                  {/* <div
                    className={openForm ? "doc-pend-wrap" : "doc-pend-wraps"}
                  >
                    {i.inrService ? (
                      <BookWrapper>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "center",
                            marginLeft: "40px",
                            alignItems: "center",
                            width: "101px",
                            height: "24px",
                            background: "rgba(187, 172, 205, 1)",
                            borderRadius: "8px",
                            padding: "5px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "12px",
                              fontweight: "normal",
                              fontFamily: "Inter-Medium,sans-serif",
                              color: "rgba(255, 255, 255, 1)",
                            }}
                          >
                            {i.duration}
                          </div>
                          <img src={Alaram} alt="img" />
                        </div>
                        <div
                          style={{
                            color: "rgba(54, 42, 65, 1)",
                            fontweight: "normal",
                            fontSize: "32px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "45px",
                          }}
                        >
                          <button className="">Free</button>
                        </div>

                        <div
                          style={{
                            background: "#362A41",
                            borderRadius: "8px",
                            padding: "10px",
                            fontSize: "14px",
                            fontweight: "normal",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                          }}
                        >
                          <button
                            className=""
                            style={{ color: "#FFF" }}
                            onClick={() => openDateForm(i._id)}
                          >
                            Book Slot
                          </button>
                        </div>
                      </BookWrapper>
                    ) : (
                      <BookWrapper>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "center",
                            marginLeft: "40px",
                            alignItems: "center",
                            width: "101px",
                            height: "24px",
                            background: "rgba(187, 172, 205, 1)",
                            borderRadius: "8px",
                            padding: "5px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "12px",
                              fontweight: "normal",
                              fontFamily: "Inter-Medium,sans-serif",
                              color: "rgba(255, 255, 255, 1)",
                            }}
                          >
                            {i.duration}
                          </div>
                          <img src={Alaram} alt="img" />
                        </div>
                        <div
                          style={{
                            color: "rgba(54, 42, 65, 1)",
                            fontweight: "normal",
                            fontSize: "32px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "45px",
                          }}
                        >
                          <button className="">
                            <FaRupeeSign />
                            {i &&
                              new Intl.NumberFormat("en-IN", {}).format(i?.inr)}
                          </button>
                        </div>

                        <div
                          style={{
                            background: "#362A41",
                            borderRadius: "8px",
                            padding: "10px",
                            fontSize: "14px",
                            fontweight: "normal",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                          }}
                        >
                          <button
                            className=""
                            style={{ color: "#FFF" }}
                            onClick={() => openDateForm(i._id)}
                          >
                            Book Slot
                          </button>
                        </div>
                      </BookWrapper>
                    )}
                  </div> */}

                  <div style={{ marginTop: "20px" }}>
                    <PreferenceWrapper>
                      <DesignationTitle>Designation </DesignationTitle>
                      {openForm ? (
                        <>
                          {" "}
                          {i.designation.slice(0, 2).map((item, index) => (
                            <Designation key={index}>{item}</Designation>
                          ))}
                          {i.designation.length > 2 ? (
                            <Designation onClick={() => handleOpen(i)}>
                              +{i.description.length - 2}
                            </Designation>
                          ) : (
                            ""
                          )}{" "}
                        </>
                      ) : (
                        <>
                          {i.designation.slice(0, 3).map((item, index) => (
                            <Designation key={index}>{item}</Designation>
                          ))}
                          {i.designation.length > 3 ? (
                            <Designation onClick={() => handleOpen(i)}>
                              +{i.description.length - 3}
                            </Designation>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </PreferenceWrapper>
                  </div>
                </div>
              </div>
              // </div>
            );
          })}
      </div>
      {selectedData && openForm ? (
        <div className={"int-previews-p"}>
          <DialogContent>
            <InterviewPopup
              onPopupData={selectedData}
              onClose={closeForm}
              openDateForm={openDateForm}
              inputId={inputFormInput}
              // onClosse={openClose}
              callAlls={getALLInterview}
            />
          </DialogContent>
        </div>
      ) : (
        ""
      )}
      <Dialog
        open={openDate}
        PaperProps={{
          style: {
            borderRadius: "24px",
            maxWidth: "560px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <TimezoneConverter
            input={input}
            id={id}
            interviewId={ids}
            open={openConfirmPopup}
            onClose={() => setOpenDate(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirm}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "660px",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <SlotBooking
            input={input}
            openFirst={() => openFirst(slotId, id, input.userId, input)}
            slotId={slotId}
            close={close}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={editPicker}
        PaperProps={{
          style: {
            borderRadius: "24px",
            maxWidth: "560px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <EditTimePicker
            input={input}
            serviceId={ids}
            interviewId={input.userId}
            slotId={slotId}
            open={openEditBook}
            onClose={() => setEditPicker(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OtherService;
