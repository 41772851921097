import React, { useState } from "react";
import "./interviewNotification.css";
import CloseIcon from "../../images/CloseIcon.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Dialog, DialogContent } from "@mui/material";

const InterviewJoined = ({ data, openRoom }) => {
  const handleClose = () => {
    openRoom();
  };
  const history = useHistory();
  const [valueOne, setValueOne] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  return (
    <div className="interview-joined">
      <div className="iconInter">
        <img src={CloseIcon} alt="img" onClick={handleClose} />
      </div>
      {data.map((a) => {
        return (
          <>
            <div style={{ margin: "1rem" }}>
              {a.aboutDetail?.firstName} joined the {a?.serviceName} room
            </div>
            <div className="buttInter">
              <button className="but" onClick={handleClose}>
                Ignore
              </button>
              <button
                className="but"
                onClick={() => {
                  setValueOne(a);
                  setOpenConfirm(true);
                }}
              >
                Join
              </button>
            </div>
          </>
        );
      })}
      <Dialog
        open={openConfirm}
        PaperProps={{
          style: {
            borderRadius: "24px",
            maxWidth: "400px",
          },
        }}
      >
        <div className="modalHeader">
          <div className="mTitle">Disclaimer</div>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => {
              setOpenConfirm(false);
            }}
          />
        </div>
        <DialogContent>
          <div className="modalBody" style={{ padding: "20px 20px 0 20px" }}>
            <p style={{ color: "#4F4953", fontWeight: "normal" }}>
              We want to remind you that the interview session will be recorded
              for quality assurance purposes.
            </p>
            <p style={{ color: "#4F4953", fontWeight: "normal" }}>
              We kindly request that you refrain from disclosing any personal
              details during the interview process.
            </p>
          </div>
        </DialogContent>
        <div className="modalFooter" style={{ padding: "10px" }}>
          <button
            className="button2"
            onClick={() => {
              history.push({
                pathname: `/portal/videoRoom/${valueOne?.calendarEvents[0]?.bookingId}/${valueOne.calendarEvents[0]?.roomName}`,
                state: {
                  main_id: valueOne.calendarEvents[0]?._id,
                  serviceId: valueOne?.calendarEvents[0]?.serviceId,
                },
              });
              setOpenConfirm(false);
            }}
          >
            Okay, Proceed
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default InterviewJoined;
