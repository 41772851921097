import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../MyProfile/service.Popup.css";
import { MdCheck, MdContentCopy } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { CiLinkedin } from "react-icons/ci";
import { Dialog, DialogContent } from "@mui/material";
import axios from "axios";
import { BsShareFill, BsWhatsapp } from "react-icons/bs";
import CloseIcon from "../../images/CloseIcon.svg";
import Back from "../../images/Back.svg";
import Share from "../../images/ShareIcon.svg";

const Preference = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #362a41;
  margin-top:20px;
`;
const PopUpTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ExploreInterviewPopup = ({
  onPopupData,
  onClose,
  BookSlot,
  openDateForm,
  inputId,
  onClosse,
  callAll,
  callAlls,
}) => {
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <span>{timeAgo}</span>;
  };
  const [social, setOpenSocial] = useState(false);
  const handleWhatsAppShare = () => {
    const jobName = onPopupData.interviewTitle;
    const jobId = onPopupData._id;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    const message = `${jobName} (Job ID: ${jobId}) - Click the link to view the job details:`;

    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${message} ${jobUrl}`
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleEmailShare = () => {
    const jobId = onPopupData._id;
    const jobTitle = onPopupData.interviewTitle;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const subject = `Job Opportunity: ${jobTitle} (Job ID: ${jobId})`;
    const body = `Check out this job opportunity: ${jobUrl}\n\nJob Title: ${jobTitle}\nJob ID: ${jobId}`;

    const emailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(emailUrl, "_blank");
  };
  const handleLinkedInShare = () => {
    const jobTitle = onPopupData.interviewTitle;
    const jobId = onPopupData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const message = `${jobTitle} (Job ID: ${jobId}) - Click the link to view the job details: ${jobUrl}`;

    const encodedMessage = encodeURIComponent(message);
    const encodedUrl = encodeURIComponent(jobUrl);

    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${encodedMessage}`;

    window.open(linkedinUrl, "_blank");
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    const jobId = onPopupData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    navigator.clipboard.writeText(jobUrl);

    navigator.clipboard.writeText(jobUrl).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };

  const [userId, setUser] = useState({});
  const getUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/getuser`
      );
      setUser(response.data);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    getUser();
  }, []);
 

  return (
    <div>
      <div className="modalHeader mheader int-prev-wrap">
        <div className="main-part-head">
          <div className="close-icon-image">
            <img src={Back} alt="img" onClick={onClose} />
          </div>
          <div
            className="company-logo-containers"
            
          >
            {onPopupData.companyName && onPopupData.companyName.companyLogo ? (
              <>
                <img
                  src={onPopupData.companyName && onPopupData.companyName.companyLogo}
                  alt="img"
                  className="company-logo"
                />
              </>
            ) : (
              <img src={Image} alt="img" className="company-logo" />
            )}
          </div>
          <div>
            <Preference>{onPopupData?.interviewTitle}</Preference>
            <div className="int-name">
                <div className="int-by">by</div><div className="int-names">{onPopupData?.createdBy?.firstName}</div>
            </div>
            
          </div>
        </div>
                  <div>
                    <img src={Share} alt="img" className="header-name-close" onClick={() => setOpenSocial(true)}/>
                  </div>
        
      </div>

      <Dialog
        open={social}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px", maxWidth: "540px" } }}
      >
        <div className="modalHeader mheader">
          <PopUpTitle>Share</PopUpTitle>
          <img src={CloseIcon} alt="img" onClick={() => setOpenSocial(false)} />
        </div>
        <DialogContent>
          <Wrapper className="modalBody">
            <div>
              <BsWhatsapp
                size={24}
                onClick={handleWhatsAppShare}
                className="what-grid"
              />
              <h4 className="sb-text"> Whats App</h4>
            </div>
            <div className="icon-grid">
              <HiOutlineMail size={24} onClick={handleEmailShare} />
              <h4 className="sb-text"> E-Mail</h4>
            </div>
            <div className="icon-grid">
              <CiLinkedin size={24} onClick={handleLinkedInShare} />
              <h4 className="sb-text"> Linkedin</h4>
            </div>
            <div className="icon-grid">
              {isCopied ? (
                <MdCheck size={30} style={{ color: "green" }} />
              ) : (
                <MdContentCopy size={24} onClick={handleCopyToClipboard} />
              )}
              <h4 className="sb-text">{isCopied ? "Copied" : "Copy Link"}</h4>
            </div>
          </Wrapper>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ExploreInterviewPopup;
