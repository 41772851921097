/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const AssociatesCards = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 12px;
`;
const InvitationCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  height: 120px;
  display: flex;
  width: 300px;
  gap: 24px;
`;

const Img = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: auto 0;
`;

const Title = styled.div`
  width: auto;
  height: 22px;
  top: 24px;
  left: 111px;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8653b6;
`;

const DetailsWrapper = styled.div`
  padding: 8px 0;
`;

const MyFollowingList = ({
  userId,
  setCount,
  asscdata,
  followingData,
  followersData,
}) => {
  const [followingList, setFollowingList] = useState([]);
  const history = useHistory();

  const getMyFollowing = async () => {
    await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get/followers/details/${userId}`)
      .then((res) => {
        console.log("followers", res?.data);
        const filterItems = res?.data?.followingData?.filter(
          (e) => e.status === true,
        );
        setFollowingList(filterItems);
        setCount(filterItems.length);
      });
  };

  useEffect(() => {
    getMyFollowing();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AssociatesCards style={{ paddingTop: "120px" }}>
        {followingList?.map((invitation, idx) => (
          <InvitationCard key={idx}>
            <Img src={invitation?.details?.avatar} alt="" />
            <DetailsWrapper>
              <Title>
                {invitation?.details?.firstName +
                  " " +
                  invitation?.details?.lastName}
              </Title>
              <a
                onClick={() =>
                  history.push(
                    "/portal/otherProfile/" +
                      invitation?.details?.userId,
                  )
                }
              >
                View
              </a>
            </DetailsWrapper>

            <div style={{ display: "flex", height: "40px", gap: "8px" }}>
              <div className="social-wrapper">
                <div className="social-btn">Pending</div>
                <div className="social-btn">
                  <QueryBuilderOutlinedIcon />
                </div>
              </div>

              <div
                className="social-wrapper"
                // onClick={() => updateInvitation()}
              >
                <div className="social-btn">Associate</div>
                <div className="social-btn">
                  <HandshakeOutlinedIcon />
                </div>
              </div>

              <div
                className="social-wrapper"
                // onClick={() => sentAssociateInvitation()}
              >
                <div className="social-btn">Associate</div>
                <div className="social-btn">
                  <AddOutlinedIcon />
                </div>
              </div>

              <div
                className="social-wrapper"
                // onClick={() => updateFollowStatus(false)}
              >
                <div className="social-btn">Following</div>
                <div className="social-btn">
                  <HandshakeOutlinedIcon />
                </div>
              </div>

              <div
                className="social-wrapper"
                // onClick={() => updateFollowStatus(true)}
              >
                <div className="social-btn">Follow</div>
                <div className="social-btn">
                  <AddOutlinedIcon />
                </div>
              </div>
            </div>
          </InvitationCard>
        ))}
      </AssociatesCards>
    </div>
  );
};

export default MyFollowingList;
