import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import Select, { components } from "react-select";
import styled from "styled-components";
import "../../styles/myprofile.css";
import axios from "axios";
import DownArrow from "../../images/Downarrow.svg";
import CloseIcon from "../../images/CloseIcon.svg";

const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.Selected ? "#362A41" : "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    zIndex: "999",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const Title = styled.div`
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  margin-bottom: 5px;
`;
const TimezoneConverter = ({ input, id, interviewId, open, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDatePickerOpen, setDatePickerOpen] = useState(true);
  const [selectedDay, setSelectedDay] = useState(null);
  const [data, setData] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [des, setDesi] = useState("");
  const [allAvailableTimeSlots, setAllAvailableTimeSlots] = useState([]);

  const handleTimeButtonClick = (time) => {
    const istTime = moment.tz(time, "h:mm A", "Asia/Kolkata");

    const unselectedTimes = getAvailableTimeSlots().filter((availableTime) =>
      istTime.isBefore(moment.tz(availableTime, "h:mm A", "Asia/Kolkata"))
    );

    setSelectedTime(istTime.format("h:mm A"));
    setAllAvailableTimeSlots([...allAvailableTimeSlots, ...unselectedTimes]);
  };
  const handleDateChange = (date) => {
    if (des === "") setError({ des: "Please enter the designation" });
    else {
      setSelectedDate(date);
      setSelectedDay(date ? moment(date).format("ddd") : null);
      setSelectedTime(null);
    }
  };

  const handleChange = (selectedOptions) => {
    const selectedValue = selectedOptions.value;
    setDesi(selectedValue);
    setError({ des: "" });
  };

  const getAvailableTimeSlots = () => {
    const selectedDayFullName = moment(selectedDay, "ddd").format("dddd");

    const selectedDayAvailability = data[0]?.daysAvailability?.find(
      (availability) => availability.day === selectedDayFullName
    );

    if (selectedDayAvailability) {
      if (selectedDayAvailability.hours) {
        const startHour = 0;
        const allSlots = [];

        for (let hour = startHour; hour < startHour + 24; hour++) {
          const currentTime = moment().startOf("day").add(hour, "hours");

          // Round the minutes to the nearest 30 minutes
          const roundedMinutes = Math.ceil(currentTime.minutes() / 30) * 30;
          currentTime.minutes(roundedMinutes);

          allSlots.push(currentTime.format("h:mm A"));
        }

        return allSlots;
      }

      const { dateInterview } = selectedDayAvailability;

      const availableSlots = dateInterview.filter(
        (slot) => slot.fromTime !== "" && slot.toTime !== ""
      );

      const formattedSlots = availableSlots.reduce((acc, slot) => {
        const fromTime = moment(slot.fromTime, "HH:mm");
        const toTime = moment(slot.toTime, "HH:mm");

        for (
          let current = fromTime.clone();
          current.isBefore(toTime);
          current.add(30, "minutes")
        ) {
          // Round the minutes to the nearest 30 minutes
          const roundedMinutes = Math.ceil(current.minutes() / 30) * 30;
          current.minutes(roundedMinutes);

          acc.push(current.format("h:mm A"));
        }

        return acc;
      }, []);

      return [...new Set(formattedSlots)].sort();
    }

    return [];
  };

  const getAllAvialabilityService = async() => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-availability-user/${id}`)
        setData(response.data.interview);
        console.log(JSON.stringify(response), "availabilie");
      }
      catch(error) {
        console("Error fetching details:",error);
      };
  };
  const [vacationDates, setVacationDates] = useState([]);

  const getAllVaccationService = async() => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-vaccation-user/${id}`)
        const vacations = response.data.vaccation[0].vacation; // Assuming data is in response.data[0].vacation

        const disabledDates = vacations.map((vacation) => new Date(vacation));
        setVacationDates(disabledDates);
      }
      catch(error) {
        console("Error fetching details:",error);
      };
  };
  console.log(vacationDates, "vacationDatesvacationDatesvacationDates");
  const [designation, setDesignation] = useState([]);
  const [price, setPrice] = useState();
  const [duration, setDuration] = useState("");
  const [serviceTitle, setServiceTitle] = useState("");
  const [candidateDetail, setCandidateDetail] = useState();
  const [free, setFree] = useState(false);
  const [interviewCode, setInterviewCode] = useState(null);
  const getOneInterview = async() => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-interview/${interviewId}`)
        if (response.data && response.data.interview.designation.length > 0) {
          setDesignation(response.data.interview.designation);
        }
        if (
          response.data &&
          response.data.interview.candidateDetail.length > 0
        ) {
          const bookingDetails = response.data.interview.candidateDetail.map(
            (item) => ({
              date: item.bookingService.date,
              time: item.bookingService.time,
            })
          );
          setCandidateDetail(bookingDetails);
        }
        setPrice(response.data.interview.inr);
        setDuration(response.data.interview.duration);
        setServiceTitle(response.data.interview.interviewTitle);
        setFree(response.data.interview.inrService);
        setInterviewCode(response.data.interview?.interviewCodeId);
      }
      catch(error) {
        console("Error fetching details:",error);
      };
  };
  const [userId, setUser] = useState({});
  const getUser = async() => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
        setUser(response.data);
      }
      catch(error) {
        console("Error fetching details:",error);
      };
  };
  useEffect(() => {
    getAllAvialabilityService();
    getAllVaccationService();
    getOneInterview();
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const optionsOne = designation.map((desc) => ({
    value: desc,
    label: desc,
  }));
  const [errors, setError] = useState({ des: "" });
  const handleSubmit = async() => {
    const error = {};
    if (!des) error.des = "Please enter the designation";
    if (Object.keys(error).length === 0) {
      const payload = {
        interviewId: id,
        candidateId: userId.userId,
        serviceId: interviewId,
        designation: des,
        date: selectedDate,
        time: selectedTime,
        price: price,
        isFree: free,
        unselectedTimeSlots: allAvailableTimeSlots,
        duration: duration,
        serviceName: serviceTitle,
        interviewCodeId: interviewCode,
      };
      try{
        const response = await axios
        .post(`${process.env.PUBLIC_URL}/xakal/create-booking-slot`, payload)
        
          const data = response.data.bookingSlot._id;
          open(data.toString());
        }
        catch(error) {
          console("Error fetching details:",error);
        };
    } else {
      setError(error);
    }
  };
  const CaretDownIcon = () => {
    return (
      <img
        src={DownArrow}
        alt="img"
        style={{ width: "30px", background: "#F4F4F6", padding: "0" }}
      />
    );
  };

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const selectedDayAvailability = data[0]?.daysAvailability;

  const unavailableDays = selectedDayAvailability
    ? selectedDayAvailability
        .map((day) => {
          const currentDate = new Date();
          const dayIndex = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ].indexOf(day.day);

          const shouldDisableDay = day.dateInterview.some(
            (interview) =>
              (interview.fromTime.trim() !== "" && interview.toTime.trim() === "") ||
              (interview.fromTime.trim() === "" && interview.toTime.trim() !== "") ||
              (interview.fromTime.trim() === "" && interview.toTime.trim() === "")
          );

          if (shouldDisableDay) {
            const datesToDisable = [];
            for (let month = 0; month < 12; month++) {
              const lastDayOfMonth = new Date(
                currentDate.getFullYear(),
                month + 1,
                0
              ).getDate();
              for (let i = 1; i <= lastDayOfMonth; i++) {
                const nextOccurrenceDate = new Date(
                  currentDate.getFullYear(),
                  month,
                  i
                );
                if (nextOccurrenceDate.getDay() === dayIndex) {
                  const isoDate = nextOccurrenceDate.toISOString();
                  datesToDisable.push(
                    isoDate.substring(0, isoDate.indexOf("T") + 1) +
                      "18:30:00.000Z"
                  );
                }
              }
            }

            return datesToDisable;
          }

          return null; // Return null for days that should not be disabled
        })
        .flat() // Flatten the array of dates
        .filter((date) => date !== null) // Filter out null values
    : [];

  const combinedExcludedDates = [...unavailableDays, ...vacationDates];
  console.log(unavailableDays, "unavailableDaysunavailableDays");
  return (
    <div style={{ maxWidth: selectedDate != null ? "580px" : "580px" }}>
      <div className="modalHeader" style={{ padding: "10px 20px" }}>
        <div className="mTitle" style={{ marginTop: "0px" }}>
          {`${input?.firstName} ${input.middleName} ${input.lastName}`}
          <span
            style={{
              color: "rgba(168, 161, 172, 1)",
              marginLeft: "3px",
              fontSize: "16px",
              fontweight: "normal",
            }}
          >
            Interviewer
          </span>
        </div>
        <img
          src={CloseIcon}
          alt="img"
          onClick={onClose}
          style={{ position: "absolute", top: "5px", right: "20px" }}
        />
      </div>
      <div
        className="modalBody"
        style={{ minHeight: "470px", padding: "5px 20px" }}
      >
        <div className={selectedDate ? "pos-fixed" : "pos-static"} id="drop">
          <Title>
            Select the designation you want to be interviewed for{" "}
            <span className="text-danger">*</span>
          </Title>
          <Select
            value={optionsOne.find((option) => option.value === des)}
            options={optionsOne}
            onChange={handleChange}
            styles={customStyle}
            components={{ Placeholder, DropdownIndicator }}
          />
          {errors.des && <div className="error-message">{errors.des}</div>}
          <Title style={{ marginTop: "5px" }}>Select date & time</Title>
        </div>
        <div style={{ zIndex: "0" }}>
          <div>
            <DatePicker
              open={isDatePickerOpen}
              onClose={() => setDatePickerOpen(false)}
              onCalendarOpen={() => setDatePickerOpen(true)}
              onChange={(date) => handleDateChange(date)}
              selected={selectedDate}
              showTimeSelect={false}
              excludeDates={combinedExcludedDates.map(
                (isoDate) => new Date(isoDate)
              )}
              style={{ transform: "translate3d(20px, 172.8px, 0px)" }}
              minDate={new Date()}
            />
          </div>
        </div>

        {selectedDate && (
          <div
            style={{
              // position: "relative",
              justifyContent: "flex-end",
              display: "flex",
              flexDirection: "column",
              marginLeft: "358px",
            }}
          >
            {/* <p>Select Time:</p> */}

            {getAvailableTimeSlots().map((time, index) => {
              const isDisabled =
                candidateDetail &&
                candidateDetail.some(
                  (bookingDetail) =>
                    moment(bookingDetail.date).isSame(selectedDate, "day") &&
                    bookingDetail.time === time
                );

              return (
                <button
                  className={`button10 ${
                    selectedDate && selectedTime === time ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => handleTimeButtonClick(time)}
                  disabled={isDisabled}
                  style={{ opacity: isDisabled ? 0.5 : 1 }}
                >
                  {isDisabled ? `${time} Slot booked` : time}
                </button>
              );
            })}
          </div>
        )}
        {/* <div style={{ padding: "200px 20px 0px 20px", width: "100%" }}>
          <Title>Time zone</Title>
          <input type="text" className="footer-header font" />
        </div> */}
      </div>

      {/* {selectedTime && ( */}
      <div
        className="modalFooter"
        style={{
          position: "absolute",
          bottom: "0px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
          padding: "10px 20px",
        }}
      >
        <button
          className="button3"
          onClick={handleSubmit}
          disabled={!selectedTime}
        >
          Next
        </button>
      </div>
      {/* )} */}
    </div>
  );
};

export default TimezoneConverter;
