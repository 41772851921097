import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import './imageModal.css';
import { CiZoomIn } from "react-icons/ci";
import { CiZoomOut } from "react-icons/ci";
import { RxRotateCounterClockwise } from "react-icons/rx";
import { GrDownload } from "react-icons/gr";
const ImagePreviewModal = ({ isOpen, onClose, imageSrc, title }) => {
  if (!isOpen) {
    return null;
  }

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = imageSrc;
    link.download = 'image.jpg'; // or derive from imageSrc
    link.click();
  };

  return (
    <div className="image-preview-overlay">
      <div className="image-preview-modal">
        <div className="modal-header">
          <h2>{title}</h2>
          <button onClick={onClose} className="close-button">×</button>
        </div>
        <div className="modal-content">
          <TransformWrapper>
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <div className="button-group">
                  <button onClick={zoomIn}><CiZoomIn size={30}/></button>
                  <button onClick={zoomOut}><CiZoomOut size={30}/></button>
                  <button onClick={resetTransform}><RxRotateCounterClockwise size={30}/></button>
                  <button onClick={downloadImage}><GrDownload size={30}/></button>
                </div>
                <TransformComponent>
                  <img src={imageSrc} alt="Preview" className="preview-image" />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </div>
      </div>
    </div>
  );
};

export default ImagePreviewModal;

 