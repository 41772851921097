import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import "../company/company.css";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import Edit from "../../images/Pen 2.svg";
import WebsiteImg from "../../images/Users Group Two Rounded.png";
import ColName from "../../images/Buildings.png";
import EmpCount from "../../images/Globals.png";
import Email from "../../images/AboutLanding/Letter.svg";
import Phone from "../../images/Phone Rounded.png";
import { Link } from "react-scroll";
import styled from "styled-components";

import { BsFillPencilFill } from "react-icons/bs";
import {
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineCloseCircle } from "react-icons/ai";
import AboutPopupInstitute from "./aboutInsititutePopup"
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import CompanyJob from "../company/companyJobs";
import AboutInstitutePopupModal from "./aboutInstituteContactPopup"
import ClassPopup from "./ClassPopup";
import TopHeader from "../MyProfile/TopHeader";
const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  // margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  // margin-top: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
`;
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  //   padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const AboutDiv = styled.div`
  position: relative;
  display: flex;
  padding:20px 20px 20px 0;
  justify-content: space-between;
  margin: 1rem 1rem 1rem 3rem;
  color: #000;
  font-family: Inter-Medium,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;
const Label = styled.div`
color: #8653B6;
font-family: Inter-Medium,sans-serif;
font-size: 18px;
font-style: normal;
font-weight: normal;
line-height: normal;
text-transform: capitalize;

  margin: 30px 0 10px 0;
`;
const Wrapper = styled.div`
  display:flex;
  gap:20px;
  margin:20px 0;
`
const Wrappers = styled.div`
background:#F4F4F6;
border-radius:8px;
padding:10px 20px;
`
const Website = styled.div`
color: #4F4953;
font-family: Inter-Medium,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: normal;
line-height: normal;
text-transform: lowercase;
display:flex;
gap:10px;
`
const WebsiteContent = styled.div`
margin-top:3px;
`
const AboutSubDiv = styled.div`
  position: relative;
  margin: 1rem 1rem 1rem 3rem;
  top: -27px;
`;

const EditInstitute = ({ onClose, match }) => {
  const { id } = useParams();

  const [open, setPopupOpen] = useState(false);
  const [openTwo, setPopupOpenTwo] = useState(false);

  const openPopup = (id) => {
    setPopupOpen(true);
    setSelectedId(id);
  };

  const [selectedId, setSelectedId] = useState(null);
  const [data, setData] = useState();

  const getOneCompanydetail = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details/${id}`)
      .then((response) => {
        console.log(response, "companyOneDetail");
        setData(response.data.data);
        setSelectedId(response.data.data.instituteId);
      })
      .catch((err) => err.error);
  };

  useEffect(() => {
    getOneCompanydetail();
    getCompanyDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openClose = () => {
    setPopupOpen(false);
    getOneCompanydetail();
  };
  const openCloseDates = () => {
    setPopupOpenTwo(false);
    getOneCompanydetail();
  };
  const [openJob, setOpenJob] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedJobData, setSelectedJobData] = useState(null);

  const [createId, setCreateId] = useState(null)
  const [classes, setClasses] = useState([])
  const getCompanyDetails = async () => {
    return await axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details-classses/` + match.params.id)
      .then((response) => {
        console.log("company details by id", response.data);
        setCreateId(response.data.data.instituteId)
        setClasses(response.data.data.classes)
      });
  };

  const [opens, setOpen] = useState(false)
  const openForm = () => {
    setOpen(true)
  }
  const [editingData, setEditingData] = useState({
    id: "",
    // Add other fields here that need to be edited
    // Initialize them with default values
    departmentName: '',
    degreeName: '',
    branch: '',
    batch: '',
    year: '',
    regulation: '',
    classNo: '',
    sectionNo: '',
  });
  const handleSave = (data) => {
    setOpen(false); // Close the dialog if needed
  };

  const [editableRowId, setEditableRowId] = useState(null); // Track the ID of the row being edited

  // Function to set the ID of the row to be edited
  const handleRowEditClick = (id) => {
    setEditableRowId(id);

    // Find the data of the row being edited based on its ID and set it in editingData
    const rowToEdit = classes.find((x) => x._id === id);
    if (rowToEdit) {
      setEditingData({
        id: rowToEdit._id,
        departmentName: rowToEdit.departmentName,
        degreeName: rowToEdit.degreeName,
        branch: rowToEdit.branch,
        batch: rowToEdit.batch,
        year: rowToEdit.year,
        regulation: rowToEdit.regulation,
        classNo: rowToEdit.classNo,
        sectionNo: rowToEdit.sectionNo,
      });
    }
  };

  // Function to handle changes in input fields while editing
  const handleInputChange = (event, fieldName) => {
    setEditingData({
      ...editingData,
      [fieldName]: event.target.value,
    });
  };


  const handleEdit = () => {

    axios.put(`${process.env.PUBLIC_URL}/xakal/institute/update/${editingData.id}`, editingData).then((response) => {
      console.log(response)
      getCompanyDetails()
    }).catch((err) => {
      console.log(err)
    })
    setEditableRowId(null);
  }

  const handledelete = (id) => {
    axios.delete(`${process.env.PUBLIC_URL}/xakal/institute-delete/${id}`)
      .then((response) => {

        getCompanyDetails()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <TopHeader />
      <MainLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <div className="Edit-wrapper">
          <div>
            <Link to="abouts" spy={true} smooth={true} offset={-180} duration={1000}>About</Link>
          </div>
          <div>
            <Link to="contact" spy={true} smooth={true} offset={-180} duration={1000}>Contact Details</Link>
          </div>
          <div>
            <Link to="jobPost" spy={true} smooth={true} offset={-180} duration={1000}>Post</Link>
          </div>
        </div>
        <PageWrapper>
          <div id="about">
            <AboutDiv>
              <h1>About</h1>
              <img src={Edit} alt="img" onClick={() => openPopup(selectedId)} style={{ cursor: "pointer" }} />
              {/* <FaRegEdit
                size={30}
                color="black"
                style={{ position: "relative", marginTop: "30px" }}
                onClick={() => openPopup(selectedId)}
              /> */}
            </AboutDiv>
            <AboutSubDiv>
              {/* <Label>Name</Label> */}
              <Label>{data && data.collegeName}</Label>
              {/* <Label>Website</Label> */}
              <Wrappers>
                <Website>
                  {/* <Label>Tag Line</Label> */}
                  {data && data.companyTagline ? (
                    <>{data && data.companyTagline}</>
                  ) : (
                    "-"
                  )}
                </Website>
              </Wrappers>

              {/* <Label>Industry</Label> */}
              <Wrapper>
                <Website><img src={EmpCount} alt="img" /><WebsiteContent>{data && data.website}</WebsiteContent></Website>
                {/* <Label>Organisation Size</Label> */}
                <Website><img src={ColName} alt="img" /><WebsiteContent>{data && data.organisationSize}</WebsiteContent></Website>
                {/* <Label>Organisation Type</Label> */}
                <Website><img src={WebsiteImg} alt="img" /><WebsiteContent>{data && data.organisationType}</WebsiteContent></Website>
              </Wrapper>
              <Wrappers>
                <Website>{data && data.companyDescription}</Website>
              </Wrappers>
            </AboutSubDiv>
          </div>
        </PageWrapper>
        <PageWrapper>
          <div id="contact">
            <AboutDiv>
              <h1>Contact Details</h1>
              <img src={Edit} alt="img" onClick={() => setPopupOpenTwo(selectedId)} style={{ cursor: "pointer" }} />
              {/* <FaRegEdit
                size={30}
                color="black"
                style={{ position: "relative", marginTop: "30px" }}
                onClick={() => openPopupTwo(selectedId)}
              /> */}
            </AboutDiv>
            <AboutSubDiv>
              {/* <Label>ContactNumber</Label> */}
              <Wrapper>
                <Website>
                  <img src={Phone} alt="img" />
                  <WebsiteContent>
                    {data && data.contactNumber ? (
                      <>{data && `+${data.contactNumber}`}</>
                    ) : (
                      "-"
                    )}
                  </WebsiteContent>
                </Website>
                {/* <Label>Contact Mail Id</Label> */}
                <Website>
                  <img src={Email} alt="img" />
                  <WebsiteContent>
                    {data && data.contactEmailId}
                  </WebsiteContent>
                </Website>
              </Wrapper>
              <Wrappers>
                {/* <Label>Location</Label> */}
                <Website>
                  {data && data?.primaryAddress ? <>
                    {data &&
                      `${data && data?.primaryAddress?.addressOne} ${data && data?.primaryAddress?.addressTwo
                      } ${data && data.primaryAddress?.city} ${data && data.primaryAddress?.state
                      } ${data && data.primaryAddress?.country} ${data && data.primaryAddress?.pincode
                      }`}
                  </> : "-"}
                </Website>
              </Wrappers>
              {/* <Label>Workplace modes</Label>
              {data &&
                data.workMode?.map((a) => {
                  return <>{a}</>;
                })} */}
            </AboutSubDiv>
          </div>
        </PageWrapper>
        {/* <PageWrapper>
          <div id="jobPost">
            <AboutDiv>
              <h1>Add Classes</h1>
            </AboutDiv>
            
          </div>
        </PageWrapper> */}
        <PageWrapper>
          <div id="jobPost">
            <AboutDiv>
              <h1>Manage Classes</h1>
            </AboutDiv>

          </div>
          <DateWrapper onClick={openForm}>
            <button className="button2">
              +Add
            </button>
          </DateWrapper>
          <div style={{ padding: "20px" }}>
            <Table striped bordered hover>
              <thead>
                <tr style={{ color: "#4F4953", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px", fontweight: "normal" }}>
                  <th style={{ width: '10%' }}>Department Name</th>
                  <th style={{ width: '10%' }}>Degree Name</th>
                  <th style={{ width: '10%' }}>Branch</th>
                  <th style={{ width: '10%' }}>Batch</th>
                  <th style={{ width: '10%' }}>Year</th>
                  <th style={{ width: '10%' }}>Regulation</th>
                  <th style={{ width: '10%' }}>Class No</th>
                  <th style={{ width: '10%' }}>Section</th>
                  <th style={{ width: '10%' }}>Edit</th>
                  <th style={{ width: '10%' }}>Delete</th>
                </tr>
              </thead>
              {classes.map((x, i) => {
                const isRowEditable = x._id === editableRowId;
                return (
                  <tbody>
                    <tr style={{ color: "#362A41", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px", fontweight: "normal" }}>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.departmentName}
                            onChange={(e) => handleInputChange(e, 'departmentName')}
                          />
                        ) : (
                          x.departmentName
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.degreeName}
                            onChange={(e) => handleInputChange(e, 'degreeName')}
                          />
                        ) : (
                          x.degreeName
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.branch}
                            onChange={(e) => handleInputChange(e, 'branch')}
                          />
                        ) : (
                          x.branch
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.batch}
                            onChange={(e) => handleInputChange(e, 'batch')}
                          />
                        ) : (
                          x.batch
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.year}
                            onChange={(e) => handleInputChange(e, 'year')}
                          />
                        ) : (
                          x.year
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.regulation}
                            onChange={(e) => handleInputChange(e, 'regulation')}
                          />
                        ) : (
                          x.regulation
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.classNo}
                            onChange={(e) => handleInputChange(e, 'classNo')}
                          />
                        ) : (
                          x.classNo
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.sectionNo}
                            onChange={(e) => handleInputChange(e, 'sectionNo')}
                          />
                        ) : (
                          x.sectionNo
                        )}
                      </td>
                      <td>{isRowEditable ? <button onClick={() => handleEdit(x._id)}>save</button> : <BsFillPencilFill onClick={() => handleRowEditClick(x._id)} />}</td>
                      <td><FaTrash onClick={() => handledelete(x._id)} /></td>


                    </tr>
                  </tbody>
                )
              })}

            </Table>

          </div>



        </PageWrapper>
      </MainLayout>

      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            fontweight: "normal",
            color: "#362A41"
          }}
        >
          <span>Edit About</span>
          <AiOutlineCloseCircle onClick={() => setPopupOpen(false)} />
        </DialogTitle>
        <DialogContent>
          <AboutPopupInstitute id={selectedId} onClosfun={openClose} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openTwo}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            fontweight: "normal",
            color: "#362A41"
          }}
        >
          <span>Edit Contact</span>
          <AiOutlineCloseCircle onClick={() => setPopupOpenTwo(false)} />
        </DialogTitle>
        <DialogContent>
          <AboutInstitutePopupModal id={selectedId} onClose={openCloseDates} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJob}
        fullWidth
        maxWidth="lg"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <span>JobDetail</span>
          <AiOutlineCloseCircle onClick={() => setOpenJob(false)} />
        </DialogTitle>
        <DialogContent>
          <CompanyJob jobData={selectedJobData} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={opens}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        > <AiOutlineCloseCircle onClick={() => setOpen(false)} /></DialogTitle>
        <DialogContent>
          <ClassPopup save={handleSave} id={createId} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditInstitute;
