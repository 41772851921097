import React, { useEffect, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import axios from "axios";


import Common from "../../utils/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
const Label = styled.div`
  font-size: 14px;
  color: #4F4953;
  line-height: 17px;
  font-family:
    Inter-Medium,
    sans-serif;
  font-weight: normal;
  margin: 25px 0 5px 0px;
  span {
    color: #FF4873;
    margin-left: 2px;
  }
`;
const Wrapper = styled.div`
  margin: 5px 20px 20px 20px;
//  width:200px;
// height:520px;
top: 195px;
left: 498px;
border-radius: 24px;

`;
const AccountCreation = ({ id, onClose, onService,open,inactive }) => {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory()
  const [accountData, setAccountData] = useState({
    bankname: "",
    accholdername: "",
    accnumber: "",
    reaccnumber: "",
    ifsc: "",
  });
  const [errors, setErrors] = useState({
    bankname: "",
    accholdername: "",
    accnumber: "",
    reaccnumber: "",
    ifsc: "",
    accountMatch: "",
  });
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setAccountData((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  const validateAccountNumber = (accnumber) => {
    if (accnumber.length < 10 || accnumber.length > 16) {
      return "Please check the account number entered";
    }
    return "";
  };
  const validateReAccountNumber = (accnumber) => {
    if (accnumber.length < 10 || accnumber.length > 16) {
      return "Please check the account number re-entered";
    }
    return "";
  };
  const validateIFSC = (ifsc) => {
    if (ifsc.length !== 11) {
      return "Please check your IFSC code";
    }
    return "";
  };

  const validateBankName = (bankname) => {
    if (!bankname.trim()) {
      return "Please enter your bank name";
    }
    return "";
  };

  const validateAccountHolderName = (accholdername) => {
    if (!accholdername.trim()) {
      return "Please enter your account holder's name";
    }
    return "";
  };
  const handleBlur = (fieldName) => {
    switch (fieldName) {
      case "bankname":
        setErrors((prevErrors) => ({
          ...prevErrors,
          bankname: validateBankName(accountData.bankname),
        }));
        break;
      case "accholdername":
        setErrors((prevErrors) => ({
          ...prevErrors,
          accholdername: validateAccountHolderName(accountData.accholdername),
        }));
        break;
      case "accnumber":
        setErrors((prevErrors) => ({
          ...prevErrors,
          accnumber: validateAccountNumber(accountData.accnumber),
        }));
        break;
      case "reaccnumber":
        setErrors((prevErrors) => ({
          ...prevErrors,
          reaccnumber: validateReAccountNumber(accountData.reaccnumber),
          accountMatch:
            accountData.accnumber !== accountData.reaccnumber
              ? "Account numbers do not match"
              : "",
        }));
        break;
      case "ifsc":
        setErrors((prevErrors) => ({
          ...prevErrors,
          ifsc: validateIFSC(accountData.ifsc),
        }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = async() => {
    const accnumberError = validateAccountNumber(accountData.accnumber);
    const reaccnumberError = validateAccountNumber(accountData.reaccnumber);
    const ifscError = validateIFSC(accountData.ifsc);
    const banknameError = validateBankName(accountData.bankname);
    const accholdernameError = validateAccountHolderName(
      accountData.accholdername
    );
    const accountMatchError =
      accountData.accnumber !== accountData.reaccnumber
        ? "Account numbers do not match"
        : "";
    setErrors({
      accnumber: accnumberError,
      reaccnumber: reaccnumberError,
      ifsc: ifscError,
      bankname: banknameError,
      accholdername: accholdernameError,
      accountMatch: accountMatchError,
    });
    if (
      !accnumberError &&
      !reaccnumberError &&
      !ifscError &&
      !banknameError &&
      !accholdernameError &&
      !accountMatchError
    ) {
      if (id) {
        const payload = {
          accountUserId: Common.loggedIn(),
          bankname: accountData.bankname,
          accholdername: accountData.accholdername,
          accnumber: accountData.accnumber,
          reaccnumber: accountData.reaccnumber,
          ifsc: accountData.ifsc,
        };
        try{
          const response = await axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/account-get-update/${id}`,
            payload
          )
            console.log(response);
            onService();
            onClose();
          }
          catch(error){console.log("Error fetching details:",error)}
      } else {
        const payload = {
          accountUserId: Common.loggedIn(),
          bankname: accountData.bankname,
          accholdername: accountData.accholdername,
          accnumber: accountData.accnumber,
          reaccnumber: accountData.reaccnumber,
          ifsc: accountData.ifsc,
        };
        try{
          await axios
          .post(`${process.env.PUBLIC_URL}/xakal/account-create`, payload)
            setAccountData("");
            onClose();
            onService();
          }
          catch(error){console.log("Error fetching details:",error)}
      }
    }
  };
  const getOneAccount = async(id) => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/account-get-detail/${id}`)
      
        setAccountData(response.data.account);
      }
      catch(error){console.log("Error fetching details:",error)}
  };
  useEffect(() => {
    if (id) {
      getOneAccount(id);
    }
  }, [id]);
  return (
    <div>
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
      <Wrapper>

        <Label>
          Bank Name<span>*</span>
        </Label>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Enter your bank name"
            aria-label="Bank Name"
            name="bankname"
            aria-describedby="basic-addon1"
            style={{
              borderRadius: "8px",
              background: "#F4F4F6",
              color: "#362A41",
              height: "48px",
              position: "relative",
              width: "480px",
              // fontStyle:"italic",
            }}
            className="font"
            value={accountData.bankname}
            onChange={handleInputChange}
            onBlur={() => handleBlur("bankname")}
          />
          {errors.bankname && (
            <div style={{ color: "#FF5969", marginTop: "10px", fontSize: "12px" }}>{errors.bankname}</div>
          )}
        </InputGroup>
        <Label>
          Account Holder's Name<span>*</span>
        </Label>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Enter your account holder's name"
            aria-label="Bank Name"
            name="accholdername"
            aria-describedby="basic-addon1"
            style={{
              borderRadius: "8px",
              background: "#F4F4F6",
              color: "#362A41",
              height: "48px",
              position: "relative",
              width: "480px",
              // fontStyle:"italic",
            }}
            className="font"
            value={accountData.accholdername}
            onChange={handleInputChange}
            onBlur={() => handleBlur("accholdername")}
          />
          {errors.accholdername && (
            <div style={{ color: "#FF5969", marginTop: "10px", fontSize: "12px" }}>{errors.accholdername}</div>
          )}
        </InputGroup>
        <Label>
          Account Number<span>*</span>
        </Label>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Enter your account number"
            aria-label="Bank Name"
            type="number"
            name="accnumber"
            aria-describedby="basic-addon1"
            style={{
              borderRadius: "8px",
              background: "#F4F4F6",
              color: "#362A41",
              height: "48px",
              position: "relative",
              width: "480px",
              // fontStyle:"italic",
            }}
            className="font"
            value={accountData.accnumber}
            onChange={handleInputChange}
            onBlur={() => handleBlur("accnumber")}

          />
          {errors.accnumber && (
            <div style={{ color: "#FF5969", marginTop: "10px", fontSize: "12px" }}>{errors.accnumber}</div>
          )}
        </InputGroup>
        <Label>
          Re-Enter Account Number <span>*</span>
        </Label>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Re-enter account number"
            aria-label="Bank Name"
            type="number"
            name="reaccnumber"
            aria-describedby="basic-addon1"
            style={{
              borderRadius: "8px",
              background: "#F4F4F6",
              color: "#362A41",
              height: "48px",
              position: "relative",
              width: "480px",
              // fontStyle:"italic",
            }}
            className="font"
            value={accountData.reaccnumber}
            onChange={handleInputChange}
            onBlur={() => handleBlur("reaccnumber")}

          />
          {errors.reaccnumber && (
            <div style={{ color: "#FF5969", marginTop: "10px", fontSize: "12px" }}>{errors.reaccnumber}</div>
          )}

        </InputGroup>
        {errors.accountMatch && (
          <div style={{ color: "#FF5969", marginTop: "10px", fontSize: "12px" }}>{errors.accountMatch}</div>
        )}
        <Label>
          IFSC Code<span>*</span>
        </Label>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Enter your IFSC code"
            aria-label="Bank Name"
            name="ifsc"
            aria-describedby="basic-addon1"
            style={{
              borderRadius: "8px",
              background: "#F4F4F6",
              color: "#362A41",
              height: "48px",
              position: "relative",
              width: "480px",
              // fontStyle:"italic",
            }}
            className="font"
            value={accountData.ifsc}
            onChange={handleInputChange}
            onBlur={() => handleBlur("ifsc")}

          />
          {errors.ifsc && <div style={{ color: "#FF5969", marginTop: "10px", fontSize: "12px" }}>{errors.ifsc}</div>}
        </InputGroup>
      </Wrapper>
      <div style={{ display: "flex", justifyContent: "end", margin: "20px" }}>
        <button className="button2" onClick={handleSubmit}>
          {id ? "Update" : "Save"}
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default AccountCreation;
