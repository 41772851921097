import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineCloseCircle } from "react-icons/ai";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select from "react-select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import "../Jobs/buttonStyle.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import size from "../company/organiType.json";
import types from "../company/typesOrgi.json"
import { CiSettings } from "react-icons/ci";
import { BsCreditCard2Front, BsUpload } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";
import Common from "../../utils/common";
import { Amplify, Auth } from "aws-amplify";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});
const MainLayout = styled.div`
  margin: 150px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const HeaderLayout = styled.div`
  position: absolute;
  top:0;
  margin-left: 5px;
  width: 1200px;
  left: 226px;
  border: 1px;
  height: 105px;
  background: white;
`;
const SeparteDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
`;
const MainHeader = styled.div`
  font-weight: normal;
  position: relative;
  display: flex;
  justify-content: flex-start;
  top: 40px;
  margin-left: 39px;
  color: #362A41;
  font-family: Inter-Medium,sans-serif;
  font-size: 24px;
`;

const HeaderText = styled.h2`
  font-weight: normal;
  color:#362A41;
  font-family:Inter-Medium,sans-serif;
  font-size: 18px;
  line-height: 38px;
  color: black;
  margin: 10px 0 30px 0;
  text-align: left;
  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #4F4953;
  font-family:Inter-Medium,sans-serif;
  font-weight: normal;
  margin: 30px 0 10px 0;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const Paragraph = styled.p`
  position: relative;
  display: flex;
  text-align: center;
  color: #362A41;
  font-family: Inter-Medium,sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 130%; /* 23.4px */
  text-transform: capitalize;
`;
const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button8 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const InstituteOne = () => {
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const { id } = useParams()
  function onSubmit() {
    setSuccess(true);
  }
  const [input, setInput] = useState({
    collegeName: "",
    institutePage: "",
    xakalAddress: "",
    website: "",
    affiliated: ""
  });
  const [establish, setEstablish] = useState("");

  const handleEstablishChange = (event) => {
    const selectedValue = event.value;

    setEstablish(selectedValue);
  };
  const startYear = 1900; // The starting year for your dropdown
  const currentYear = new Date().getFullYear(); // Get the current year
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const optionYear = years.map((year) => ({
    value: year,
    label: year,
  }))
  const [industry, setIndustry] = useState("");
  const [organisationType, setOrganisationType] = useState("");
  const [organisationSize, setOrganisationSize] = useState("");

  const [companyId, setCompanyId] = useState("");

  const [error, setError] = useState({
    collegeName: "",
    institutePage: "",
    xakalAddress: "",
    website: "",
    affiliated: ""
  });


  const handleOrganisationTypeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationType(selectedValue);
  };
  const handleOrganisationSizeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationSize(selectedValue);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  const [tagline, setTagline] = useState("")
  const [description, setDescription] = useState("")
  const handleTagline = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 250) {
      setTagline(inputValue);
    }
  };
  const handleDescription = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 1000) {
      setDescription(inputValue);
    }
  }

  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the Name of the Company Page!";
          }
          break;
        case "organisation":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the Name of the Organisation!";
          }
          break;
        case "xakalAddress":
          const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter Xakal Address!";
          }
          else if (!regex) {
            stateObj[name] = "Alphabets only allowed enter without spaces!";
          }
          break;

        case "website":
          const regex1 = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter Website/Domain!";
          }
          else if (!regex1) {
            stateObj[name] = "Please enter valid Website/Domain!";
          }
          break;
        default:
          break;
      }
      return stateObj;
    });
  };
  const [organisationTypeTouched, setOrganisationTypeTouched] = useState(false);
  const [organisationSizeTouched, setOrganisationSizeTouched] = useState(false);


  const [open, setOpen] = useState(false);

  const handleClosePopup = () => {
    setOpen(false);
  };

  const showMaxSizeToast = () => {
    // Implement your toaster message for max size here
    toast.error("File size exceeds the maximum allowed 12MB.");
  };

  const showInvalidTypeToast = () => {
    // Implement your toaster message for invalid file type here
    toast.error(
      "Invalid file type. Only JPEG, PNG, and JPG images are allowed."
    );
  };
  const [imageURL, setImageURL] = useState(null);
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add 'image/hpg' if it's a custom image format
      const maxSize = 12 * 1024 * 1024; // 2MB in bytes// 50KB in bytes
      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= maxSize) {

          let formData = new FormData();
          formData.append("image", file);

        } else {
          // Show a toaster message for files exceeding the maximum size
          showMaxSizeToast();
        }
      } else {
        // Show a toaster message for invalid file types
        showInvalidTypeToast();
      }
    } else {
      console.log("No file selected.");
    }
  };

  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const [errorOtpOne, setErrorOtpOne] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isSendOtpClicked, setIsSendOtpClicked] = useState(false);

  const sendEmailOtp = async () => {
    setIsResendDisabled(true);
    setResendTimer(60);
    setIsSendOtpClicked(true);
    if (input.collegeName) {
      setErrorOtp("");
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        website: input.website,
      };
      await axios
        .post(`${process.env.PUBLIC_URL}/xakal/institute/email/sendOtp`, payload)
        .then((response) => {
          console.log(response);
          toast.success("OTP sent to Mail", {
            autoClose: 5000,
            position: "top-right",
          });
          setErrorMessage("Enter the Otp that has send to your Mail Id!");
          startResendTimer();
        })
        .catch((err) => {
          toast.error("Failed to send OTP", {
            autoClose: 5000,
            position: "top-right",
          });
          console.log(err.error);
        });
    }
  };
  const startResendTimer = () => {
    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      setIsResendDisabled(false);
      setResendTimer(0);
      clearInterval(timerInterval);
    }, 60000);
  };
  const formatTimer = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const verifyOtp = async () => {
    if (input.collegeName) {
      setErrorOtp("");
      setErrorOtpOne("");
    }
    const payload = {
      emailId: email,
      otp: +otp,
      website: input.website,
    };
    console.log("otp verify", payload);
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/institute/email/verify`, payload)
      .then((response) => {
        console.log(response);

        if (response && response.data && response.data.success === true) {
          setTimeout(() => {
            setOpen(false);
            history.push(`/portal/institute-contact/${companyId}`);
            // Redirect to a different page
            // history.push(`./addnew-jobinfo/${jobIds}`);
          }, 1000);
          setErrorOtp(response.data.message);
        } else if (
          response &&
          response.data &&
          response.data.success === false
        ) {
          // Set the error message for an invalid OTP
          setErrorOtpOne("Invalid OTP");
        }
      })
      .catch((err) => err.error);
  };
  const validcheckemail = () => {
    let url = website
      .replace(/\//g, "")
      .replace("www.", "")
      .replace("https:", "")
      .replace("http:", "");
    if (email.indexOf("@") === -1) {
      return "";
    } else {
      let a = email.split("@");
      if (a[1] === url) {
        return "ok";
      } else {
        return "Invalid Email (" + url + ")";
      }
    }
  };
  const optionsSize = size.size.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const optionsType = types.type.map((skill) => ({
    value: skill,
    label: skill,
  }));

  const errorHandle = (e) => {
    const enteredValue = e.target.value;
    if (/^\d{0,4}$/.test(enteredValue)) {
      setOtp(enteredValue);
    } else {
      setOtp("")
    }
  };
  const [openSetting, setOpenSetting] = useState(false);
  const openSubmit = () => {
    setOpenSetting(true);
  };

  const logout = () => {
    Common.logout();
  };
  const [user, setUsers] = useState("");
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  const [company, setCompany] = useState("");
  const [xakalAddress, setXakalAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [verify, setVerify] = useState(false)
  const getOneCompanydetail = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details/${id}`)
      .then((response) => {
        console.log(response, "companyOneDetail");
        setInput({
          affiliated: response.data.data.affiliated,
          institutePage: response.data.data.institutePage,
        })
        setCompany(response.data.data.collegeName);
        setXakalAddress(response.data.data.xakalAddress);
        setWebsite(response.data.data.website);
        setOrganisationSize(response.data.data.organisationSize);
        setOrganisationType(response.data.data.organisationType);
        setIndustry(response.data.data.industry);
        setServiceProvider(response.data.data.servicesProvided);
        setEstablish(response.data.data.establishedInYear);
        setImageURL(response.data.data.companyLogo);
        setDescription(response.data.data.companyDescription);
        setTagline(response.data.data.companyTagline);
        setVerify(response.data.data.isInstituteVerified === true)
        setCompanyId(response.data.data.instituteId)
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getOneCompanydetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSumit = async () => {
    const payload = {
      collegeName: company,
      affiliated: input.affiliated,
      institutePage: input.institutePage,
      xakalAddress: xakalAddress,
      website: website,
      industry: industry,
      organisationSize: organisationSize,
      organisationType: organisationType,
      servicesProvided: serviceProvider,
      establishedInYear: establish,
      companyLogo: imageURL,
      companyTagline: tagline,
      companyDescription: description,
    };
    if (verify) {
      await axios
        .post(`${process.env.PUBLIC_URL}/xakal/create/institute`, payload)
        .then((response) => {
          console.log("company update", response);
          // onClosfun()
          history.push(`/portal/institute-contact/${companyId}`);
        })
        .catch((err) => err.error);
    } else {

    }

    // history.push("/portal/company-contact");
  };
  return (
    <div>
      <HeaderLayout>
        <MainHeader>Create Institution</MainHeader>
        <SeparteDiv>
          <div
            style={{ position: "relative", marginTop: "12px", right: "90px" }}
          >
            <CiSettings
              size={30}
              onClick={openSubmit}
              style={{ cursor: "pointer" }}
            />
          </div>
        </SeparteDiv>
      </HeaderLayout>

      <MainLayout>

        <PageWrapper>
          <HeaderText>{"Create Institution"}</HeaderText>
          <ToastContainer />
          <Label>Institution Name<span className="text-danger">*</span></Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter Your Institution Name"
              aria-label="Job Title"
              name="collegeName"
              onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleChange}
              value={company}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>
          {error.collegeName && (
            <span className="text-danger">{error.collegeName}</span>
          )}

          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <Label>Institution Page Name<span className="text-danger">*</span></Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter Your Institution's Name"
                  aria-label="Job Title"
                  name="institutePage"
                  onBlur={validateInput}
                  aria-describedby="basic-addon1"
                  onChange={handleChange}
                  value={input.institutePage}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
              {error.institutePage && (
                <span className="text-danger">{error.institutePage}</span>
              )}
            </div>


            <div style={{ width: "50%" }}>
              <Label>Affilated With<span className="text-danger">*</span></Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter Your Affilated Name"
                  aria-label="Job Title"
                  name="affiliated"
                  onBlur={validateInput}
                  aria-describedby="basic-addon1"
                  onChange={handleChange}
                  value={input.affiliated}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
              {error.affiliated && (
                <span className="text-danger">{error.affiliated}</span>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <Label>Xakal Address<span className="text-danger">*</span></Label>
              <div style={{ display: "flex" }}>
                <div className="xakal-address">WWW.xakal.com/</div>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="xakal/codeupglobal.com"
                    aria-label="Job Title"
                    name="xakalAddress"
                    onBlur={validateInput}
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    disabled
                    value={xakalAddress}
                    style={{
                      borderRadius: "5px",
                      background: "rgba(54, 42, 65, 0.04)",
                    }}
                  />
                </InputGroup>
              </div>
              {error.xakalAddress && (
                <span className="text-danger">{error.xakalAddress}</span>
              )}
            </div>
            <div style={{ width: "50%" }}>
              <Label>Institution Url<span className="text-danger">*</span></Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter the link of your Institution's website/Domain"
                  aria-label="Job Title"
                  name="website"
                  disabled
                  onBlur={validateInput}
                  aria-describedby="basic-addon1"
                  onChange={handleChange}
                  value={website}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
              {error.website && (
                <span className="text-danger">{error.website}</span>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>

            <div style={{ width: "50%" }}>

              <Label>Organisation size</Label>
              <div>
                <Select
                  class="footer-header  mb-3"
                  styles={{

                    height: "40px",
                    control: (base, state) => ({
                      ...base,
                      width: "100%",
                      backgroundColor: "#F4F4F6",
                      border: "1px solid #F4F4F6",
                      borderRadius: "8px"
                    })
                  }}
                  name="location"
                  value={optionsSize.find(
                    (option) => option.value === organisationSize
                  )}
                  onBlur={() => setOrganisationSizeTouched(true)}
                  onChange={handleOrganisationSizeChange}
                  isSearchable={true}
                  options={optionsSize}
                  placeholder="Enter the size of the Organisation"
                />

                {organisationSizeTouched &&
                  organisationSizeTouched.organisationSize && (
                    <span className="text-danger">
                      {organisationSizeTouched.organisationSize}
                    </span>
                  )}
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <Label>Organisation type</Label>
              <div>
                <Select
                  class="footer-header  mb-3"
                  styles={{

                    height: "40px",
                    control: (base, state) => ({
                      ...base,
                      width: "100%",
                      backgroundColor: "#F4F4F6",
                      border: "1px solid #F4F4F6",
                      borderRadius: "8px"
                    })
                  }}
                  name="location"
                  value={optionsType.find(
                    (option) => option.value === organisationType
                  )}
                  onBlur={() => setOrganisationTypeTouched(true)}
                  onChange={handleOrganisationTypeChange}
                  isSearchable={true}
                  options={optionsType}
                  placeholder="Enter the type of the Organisation"
                />

                {organisationTypeTouched &&
                  organisationTypeTouched.organisationType && (
                    <span className="text-danger">
                      {organisationTypeTouched.organisationType}
                    </span>
                  )}
              </div>
            </div>
          </div>

          <Label>Logo</Label>
          {imageURL ? (
            <div
              style={{
                border: "2px dotted #000",
                padding: "10px",
                margin: "10px",
                cursor: "pointer",
                display: "flex", // Add this line
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <label style={{ position: "relative" }}>
                <div>
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    {/* Center the BsUpload icon */}
                    <img src={imageURL} alt="imageurl" />
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          ) : (
            <div
              style={{
                border: "2px dotted #000",
                padding: "10px",
                margin: "10px",
                cursor: "pointer",
                display: "flex", // Add this line
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <label style={{ position: "relative" }}>
                <div>
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    {/* Center the BsUpload icon */}
                    <BsUpload size={50} />
                    <div>Upload as png, jpg, jpeg</div>
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          )}
          <Label>Establishment Year</Label>
          <div>
            <Select
              class="footer-header  mb-3"
              styles={{

                height: "40px",
                control: (base, state) => ({
                  ...base,
                  width: "100%",
                  backgroundColor: "#F4F4F6",
                  border: "1px solid #F4F4F6",
                  borderRadius: "8px"
                })
              }}
              name="establishedInYear"
              value={{ value: establish, label: establish }}

              onChange={handleEstablishChange}
              isSearchable={true}
              options={optionYear}
              defaultValue={establish}
              placeholder="Enter the Establishment year"
            />


          </div>
          <Label>Tag line</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder=" Write an impressive tagline for your Company"
              aria-label="Job Title"
              name="tagline"
              value={tagline}
              onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleTagline}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
              maxLength={250}

            />
          </InputGroup>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <p>
              {tagline.length}/250
              Characters
            </p>
          </div>
          <Label>Company Description</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter company description"
              aria-label="Job Title"
              name="companyDescription"
              onBlur={validateInput}
              value={description}
              aria-describedby="basic-addon1"
              onChange={handleDescription}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
              maxLength={1000} // Set max length to enforce character limit

            />
          </InputGroup>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <p>
              {description.length}
              /1000 Characters
            </p>
          </div>
          <DateWrapper>
            <button className="button2" onClick={handleSumit}>
              Create
            </button>
          </DateWrapper>
        </PageWrapper>
      </MainLayout>
      <Dialog open={open} onClose={handleClosePopup}>
        <DialogTitle
          style={{
            display: "flex",
            fontFamily: "Inter-Medium",
            position: "relative",
            justifyContent: "end",
          }}
        >
          <AiOutlineCloseCircle onClick={() => setOpen(false)} />
        </DialogTitle>
        <DialogContent>
          <Paragraph>
            To complete company page creation, kindly verify that you are an
            authorised representative of the Company "{input.collegeName}"
          </Paragraph>
          <Label>Enter your Company Mail ID*</Label>
          <div style={{ display: "flex", gap: "10px" }}>
            <input
              type="text"
              name="email"
              style={{
                borderRadius: "5px",
                border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "90%",
                height: "40px",
                padding: "20px",
              }}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your company mail ID"
            />
            <button
              className="button2"
              disabled={validcheckemail() !== "ok"}
              onClick={() => sendEmailOtp()}
            >
              Send Otp
            </button>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <span style={{ color: "green" }}>{errorMessage}</span>
            </div>
            <div>
              {isSendOtpClicked && (
                <span>
                  {resendTimer > 0
                    ? `Resend OTP in ${formatTimer(resendTimer)}`
                    : !isResendDisabled && (
                      <span onClick={sendEmailOtp}>Resend OTP</span>
                    )}
                </span>
              )}
            </div>
          </div>
          {validcheckemail() !== "ok" && (
            <span className="text-danger">{validcheckemail()}</span>
          )}
          <Label>Enter Otp</Label>
          <div style={{ display: "flex", gap: "10px" }}>
            <input
              name="otp"
              value={otp}
              style={{
                borderRadius: "5px",
                border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "90%",
                height: "40px",
                padding: "20px",
              }}
              onChange={errorHandle}
              placeholder="Enter the OTP sent to the entered MAIL ID"
            />
            <button
              className="button2"
              disabled={!otp}
              onClick={() => verifyOtp()}
            >
              Verify
            </button>
          </div>
          <div>
            <span style={{ color: "green" }}>{errorOtp}</span>
          </div>
          <div>
            <span style={{ color: "red" }}>{errorOtpOne}</span>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InstituteOne;
