import React, { useEffect, useState } from "react";
import imageAvatar from "../../images/company.png";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
const JobNotification = () => {
  const [data, setData] = useState();
  const [datas, setDatas] = useState();
  const history = useHistory();
  const getAllJob = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-manage-jobs`
      );

      if (response.data) {
        const newJobs = response.data.job;

        const transformedData = newJobs.flatMap((job) => {
          // Filter applicants based on the condition
          const filteredApplicants = job.applicants.filter(
            (applicant) => applicant.statusOne === "Applied"
          );

          return filteredApplicants.map((applicant) => ({
            ...job,
            applicant, // Add the single applicant details
            applicants: undefined, // Remove the original applicants array
          }));
        });

        // Set the transformed data
        setData(transformedData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllJob();
  }, []);

  const getAllJobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/job/applied`
      );

      if (response.data) {
        const transformedData = response.data.flatMap((job) => {
          // Filter applicants based on the condition
          const filteredApplicants = job.applicants.filter((applicant) =>
            ["reject", "offered", "shortlist"].includes(applicant.statusOne)
          );

          return filteredApplicants.map((applicant) => ({
            ...job,
            applicant, // Add the single applicant details
            applicants: undefined, // Remove the original applicants array
          }));
        });

        // Set the transformed data
        setDatas(transformedData);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getAllJobs();
  }, []);
  return (
    <div>
      {data?.map((a, index) => {
        return (
          <div className="">
            <div className="notificationInterview">
              <div className="interviewNotificationCard">
                <img
                  src={imageAvatar}
                  alt="imageAvatar"
                  className="notificationImage"
                />
                <div className="all-noti-cont">
                  {a.applicant?.firstName} <span>has applied for{" "}</span>
                  <span className="jobTitle" title={a?.jobTitle}>
                    {a?.jobTitle?.length > 15
                      ? a?.jobTitle?.substring(0, 15) + "..."
                      : a?.jobTitle}
                  </span>{" "}
                  role at {a.companyName?.value}
                </div>
                <div className="buttonGridInterview">
                  <button
                    className="btn btn-new-int"
                    onClick={() =>
                      history.push(
                        `/portal/applicant-details/${a?.applicant?.userId}/${a?._id}`
                      )
                    }
                  >
                    {"View Applicant"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {datas?.map((a, index) => {
        const state = {
          isCardActive: "applied",
          isId: a?._id,
          openNewJobs: true,
          dataJob: a,
        };
        return (
          <div className="">
            <div className="notificationInterview">
              <div className="interviewNotificationCard">
                <img
                  src={imageAvatar}
                  alt="imageAvatar"
                  className="notificationImage"
                />
                {a.applicant?.statusOne === "shortlist" ? (
                  <div className="all-noti-cont">
                    Your profile has been shortlisted for
                    <span className="jobTitle" title={a?.jobTitle}>
                      {a?.jobTitle?.length > 15
                        ? a?.jobTitle?.substring(0, 15) + "..."
                        : a?.jobTitle}
                    </span>{" "}
                    at {a.companyName?.value}
                  </div>
                ) : a.applicant?.statusOne === "offered" ? (
                  <p>
                    Good News you have been offered the
                    <span className="jobTitle" title={a?.jobTitle}>
                      {a?.jobTitle?.length > 15
                        ? a?.jobTitle?.substring(0, 15) + "..."
                        : a?.jobTitle}
                    </span>{" "}
                    position at {a.companyName?.value}
                  </p>
                ) : a.applicant?.statusOne === "reject" ? (
                  <div className="all-noti-cont">
                    We regret to inform you that your application for the
                    <span className="jobTitle" title={a?.jobTitle}>
                      {a?.jobTitle?.length > 15
                        ? a?.jobTitle?.substring(0, 15) + "..."
                        : a?.jobTitle}
                    </span>{" "}
                    position at {a.companyName?.value} has been declined
                  </div>
                ) : (
                  ""
                )}

                <div className="buttonGridInterview">
                  <button
                    className="btn btn-new-int"
                    onClick={() => history.push(`/portal/applied-jobs`, state)}
                  >
                    {"View Job Application"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JobNotification;
