import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select from "react-select";
import size from "../company/organiType.json";
import types from "../company/typesOrgi.json";
import service from "../company/service.json";
import "../Jobs/buttonStyle.css";
import { BsUpload } from "react-icons/bs";
const MainLayout = styled.div`
  // margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #4F4953;
  font-family:Inter-Medium,sans-serif;
  font-weight: normal;
  margin: 30px 0 10px 0;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const AboutPopupInstitute = ({ id, onClosfun }) => {
  const [input, setInput] = useState({
    company: "",
    xakalAddress: "",
    website: "",
    affiliated: "",
    institutePage: ""
  });
  const optionsService = service.service.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const startYear = 1900; // The starting year for your dropdown
  const currentYear = new Date().getFullYear(); // Get the current year
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const optionYear = years.map((year) => ({
    value: year,
    label: year,
  }));

  const optionsSize = size.size.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const optionsType = types.type.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const [company, setCompany] = useState("");
  const [xakalAddress, setXakalAddress] = useState("");
  const [industry, setIndustry] = useState("");
  const [organisationType, setOrganisationType] = useState("");
  const [organisationSize, setOrganisationSize] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [establish, setEstablish] = useState("");
  const [website, setWebsite] = useState("");
  const [tagline, setTagline] = useState("");
  const [description, setDescription] = useState("");

  const [error, setError] = useState({
    company: "",
    xakalAddress: "",
    website: "",
    affiliated: "",
    institutePage: ""
  });

  const handleServiceChange = (event) => {
    const selectedValue = event.value;

    setServiceProvider(selectedValue);
  };
  const handleEstablishChange = (event) => {
    const selectedValue = event.value;

    setEstablish(selectedValue);
  };
  const handleOrganisationTypeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationType(selectedValue);
  };
  const handleOrganisationSizeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationSize(selectedValue);
  };

  const [serviceProviderTouched, setServiceProviderTouched] = useState(false);
  const [establishTouched, setEstablishTouched] = useState(false);


  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the Name of the Company!";
          }
          console.log(input.jobTitle);
          break;

        default:
          break;
      }
      return stateObj;
    });
    // handleInputBlur()
  };
  const [organisationTypeTouched, setOrganisationTypeTouched] = useState(false);
  const [organisationSizeTouched, setOrganisationSizeTouched] = useState(false);

  const [imageURL, setImageURL] = useState(null);

  const getOneCompanydetail = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details/${id}`)
      .then((response) => {
        console.log(response, "companyOneDetail");
        setInput({
          affiliated: response.data.data.affiliated,
          institutePage: response.data.data.institutePage,
        })
        setCompany(response.data.data.collegeName);
        setXakalAddress(response.data.data.xakalAddress);
        setWebsite(response.data.data.website);
        setOrganisationSize(response.data.data.organisationSize);
        setOrganisationType(response.data.data.organisationType);
        setIndustry(response.data.data.industry);
        setServiceProvider(response.data.data.servicesProvided);
        setEstablish(response.data.data.establishedInYear);
        setImageURL(response.data.data.companyLogo);
        setDescription(response.data.data.companyDescription);
        setTagline(response.data.data.companyTagline);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getOneCompanydetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSumit = async () => {
    const payload = {
      collegeName: company,
      affiliated: input.affiliated,
      institutePage: input.institutePage,
      xakalAddress: xakalAddress,
      website: website,
      industry: industry,
      organisationSize: organisationSize,
      organisationType: organisationType,
      servicesProvided: serviceProvider,
      establishedInYear: establish,
      companyLogo: imageURL,
      companyTagline: tagline,
      companyDescription: description,
    };

    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/create/institute`, payload)
      .then((response) => {
        console.log("company update", response);
        onClosfun()
      })
      .catch((err) => err.error);
    // history.push("/portal/company-contact");
  };
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("image", file);
    }
  };
  return (
    <div>
      <MainLayout>
        <PageWrapper>
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <Label>Name of the Institute <span className="text-danger">*</span></Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter your Organisation's Name"
                  aria-label="Job Title"
                  name="collegeName"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setCompany(e.target.value)}
                  value={company}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
              {error.collegeName && (
                <span className="text-danger">{error.collegeName}</span>
              )}
            </div>
            <div style={{ width: "50%" }}>
              <Label>Institution Page Name<span className="text-danger">*</span></Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter your Institute Page's Name"
                  aria-label="Job Title"
                  name="institutePage"

                  aria-describedby="basic-addon1"

                  value={input.institutePage}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
              {error.institutePage && (
                <span className="text-danger">{error.institutePage}</span>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <Label>Affilated With*</Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter your affiliated's Name"
                  aria-label="Job Title"
                  name="affiliated"
                  //   onBlur={validateInput}
                  aria-describedby="basic-addon1"
                  //   onChange={handleChange}
                  value={input.affiliated}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
              {error.affiliated && (
                <span className="text-danger">{error.affiliated}</span>
              )}
            </div>
            <div style={{ width: "50%" }}>
              <Label>Xakal Address<span className="text-danger">*</span></Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="xakal/codeupglobal.com"
                  aria-label="Job Title"
                  name="xakalAddress"
                  onBlur={validateInput}
                  value={xakalAddress}
                  aria-describedby="basic-addon1"
                  onChange={(e) => setXakalAddress(e.target.value)}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <Label>Institution Url<span className="text-danger">*</span></Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter the link of your Company's website / domain"
                  aria-label="Job Title"
                  name="website"
                  value={website}
                  onBlur={validateInput}
                  aria-describedby="basic-addon1"
                  onChange={(e) => setWebsite(e.target.value)}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
            </div>
            <div style={{ width: "50%" }}>

              <Label>Organisation size</Label>
              <div>
                <Select
                  class="footer-header  mb-3"
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                  name="location"
                  value={optionsSize.find(
                    (option) => option.value === organisationSize
                  )}
                  onBlur={() => setOrganisationSizeTouched(true)}
                  onChange={handleOrganisationSizeChange}
                  isSearchable={true}
                  options={optionsSize}
                  placeholder="Enter the size of the Organisation"
                />

                {organisationSizeTouched &&
                  organisationSizeTouched.organisationSize && (
                    <span className="text-danger">
                      {organisationSizeTouched.organisationSize}
                    </span>
                  )}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <Label>Organisation type</Label>
              <div>
                <Select
                  class="footer-header  mb-3"
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                  name="location"
                  value={optionsType.find(
                    (option) => option.value === organisationType
                  )}
                  onBlur={() => setOrganisationTypeTouched(true)}
                  onChange={handleOrganisationTypeChange}
                  isSearchable={true}
                  options={optionsType}
                  placeholder="Enter the type of the Organisation"
                />

                {organisationTypeTouched &&
                  organisationTypeTouched.organisationType && (
                    <span className="text-danger">
                      {organisationTypeTouched.organisationType}
                    </span>
                  )}
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <Label>Services Provided</Label>
              <div>
                <Select
                  class="footer-header  mb-3"
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                  name="serviceProvider"
                  value={optionsService.find(
                    (option) => option.value === serviceProvider
                  )}
                  onBlur={() => setServiceProviderTouched(true)}
                  onChange={handleServiceChange}
                  isSearchable={true}
                  options={optionsService}
                  placeholder="Enter the services provided by the Organisation"
                />

                {serviceProviderTouched &&
                  serviceProviderTouched.serviceProvider && (
                    <span className="text-danger">
                      {serviceProviderTouched.serviceProvider}
                    </span>
                  )}
              </div>
            </div>
          </div>
          <Label>Establishment Year</Label>
          <div>
            <Select
              class="footer-header  mb-3"
              style={{
                width: "100%",
                height: "40px",
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
              name="establish"
              value={{ value: establish, label: establish }}
              onBlur={() => setEstablishTouched(true)}
              onChange={handleEstablishChange}
              isSearchable={true}
              options={optionYear}
              placeholder="Enter the Establishment year"
            />

            {establishTouched && establishTouched.establish && (
              <span className="text-danger">{establishTouched.establish}</span>
            )}
          </div>
          <Label>Logo</Label>
          {imageURL ? (
            <div
              style={{
                border: "2px dotted #000",
                padding: "10px",
                margin: "10px",
                cursor: "pointer",
                display: "flex", // Add this line
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <label style={{ position: "relative" }}>
                <div>
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    {/* Center the BsUpload icon */}
                    <img src={imageURL} alt="imageurl" />
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          ) : (
            <div
              style={{
                border: "2px dotted #000",
                padding: "10px",
                margin: "10px",
                cursor: "pointer",
                display: "flex", // Add this line
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <label style={{ position: "relative" }}>
                <div>
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    {/* Center the BsUpload icon */}
                    <BsUpload size={50} />
                    <div>Upload as png, jpg, jpeg</div>
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          )}
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <Label>Tag line</Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder=" Write an impressive tagline for your Company"
                  aria-label="Job Title"
                  name="companyTagline"
                  onBlur={validateInput}
                  value={tagline}
                  aria-describedby="basic-addon1"
                  onChange={(e) => setTagline(e.target.value)}
                  maxlength={250}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>


              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <p>{tagline === undefined ? "0" : tagline.length}/250 Characters</p>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <Label>Company Description</Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter company description"
                  aria-label="Job Title"
                  name="companyDescription"
                  onBlur={validateInput}
                  value={description}
                  aria-describedby="basic-addon1"
                  onChange={(e) => setDescription(e.target.value)}
                  maxlength={1000}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <p>
                  {description === undefined ? "0" : description.length}
                  /1000 Characters
                </p>
              </div>
            </div>
          </div>
          <DateWrapper>
            {/* <Button3 type="submit" disabled={!enable} style={{opacity: enable ? "100%" : "50%"}}>  */}
            <button className="button2" onClick={handleSumit}>
              Save / Next
            </button>
            {/* </Button3> */}
          </DateWrapper>
        </PageWrapper>
      </MainLayout>
    </div>
  );
};

export default AboutPopupInstitute;
