import React, { useState, useEffect } from "react";
import "./progreesOne.css"

const ProgressBar = ({ value }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(value);
  }, [value]);

  return (
    <div className="progress-wrapper">
      <div style={{color:"#4F4953",fontweight: "normal",fontSize:"12px" }}>
      {value}
      </div>
    <div className="progress-bar">
       <div className="progress-bar-slider progress-bar-striped progress-bar-animated" role="progressbar"
    //    style={{ width: `${progress * 20}%` }}
       style={{ width: `${Math.min(progress, 5) * 20}%` }}
       >
        <span className="star">⭐️</span>
      </div>
    </div>
    </div>
  );
};
export default ProgressBar;
