import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { AiOutlinePlusCircle } from "react-icons/ai";
import "../Jobs/buttonStyle.css";
import PhoneInput from "react-phone-input-2";
import { Col, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Country, State, City } from "country-state-city";
import "react-toastify/dist/ReactToastify.css";
import TopHeader from "../MyProfile/TopHeader";
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;

const HeaderText = styled.h2`
  color: #362A41;
  font-family: Inter-Medium,sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin: 10px 0 30px 0;
  
  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 20px;
  color: #362a41;
  font-weight: normal;
  margin: 30px 0 10px 0;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const AboutContactInsitute = ({ match }) => {
  const history = useHistory();
  const [btnenable, setBtnEnable] = useState(false);
  const [otpsecenable, setOtpSecEnable] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [input, setInput] = useState({
    company: "",
    xakalAddress: "",
    website: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({
    company: "",
    xakalAddress: "",
    website: "",
  });
  const [error2, setError2] = useState("");

  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the Name of the Company!";
          }
          console.log(input.jobTitle);
          break;

        default:
          break;
      }
      return stateObj;
    });
  };

  const [data, setData] = useState("");
  const [otp, setOtp] = useState(null);
  const [inputList, setInputList] = useState([
    {
      addressOne: "",
      addressTwo: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
      setTrueLocation: false,
    },
  ]);
  const [phone, setPhone] = useState("");

  const handlePhone = (e) => {
    const inputPhone = e.target.value.replace(/\D/g, '');
    if (inputPhone.length < 12) {
      console.log(phone);
      setPhone(inputPhone);
      setBtnEnable(false);
      setError2("Please Enter Valid Mobile Number");
    }
    else {
      setError2("");
      setBtnEnable(true);
    }
  }

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhone(value);


  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    // Update the country, state, and city within the inputList element
    if (name === "country") list[index]["country"] = value;
    else if (name === "state") list[index]["state"] = value;
    else if (name === "city") list[index]["city"] = value;
    setInputList(list);
    console.log(inputList);
  };
  const setPrimaryLocation = (e, index) => {
    const list = [...inputList];
    list[index]["setTrueLocation"] = !list[index]["setTrueLocation"];
    setInputList(list);
    console.log(inputList);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, {
      addressOne: "",
      addressTwo: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
      setTrueLocation: false,
    },]);
  };

  const [contactNumberVerified, setContactNumberVerified] = useState(false)
  const [companyId, setCompanyId] = useState("")
  const getCompanyDetails = async () => {
    return await axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details/` + match.params.id)
      .then((response) => {
        console.log("company details by id", response.data);
        setCompanyId(response.data.data?.instituteId);
        if (response.data) {
          setData(response.data?.data);
          if (response.data.data && response.data?.data?.contactNumber)
            setPhone(response.data?.data?.contactNumber);
        }
        setContactNumberVerified(response.data?.data?.contactNumberVerified === true)
      });
  };

  useEffect(() => {
    getCompanyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("phone", phone);

  const [resendTimer, setResendTimer] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isSendOtpClicked, setIsSendOtpClicked] = useState(false);
  const startResendTimer = () => {
    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      setIsResendDisabled(false);
      setResendTimer(0);
      clearInterval(timerInterval);
    }, 60000);
  };
  const formatTimer = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const sendMobileOtp = async () => {
    setIsResendDisabled(true);
    setResendTimer(60);
    setIsSendOtpClicked(true);
    setOtpSecEnable(true);
    const payload = {
      mobile: phone,
      website: data["website"],
    };
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/institute/mobile/sendOtp`, payload)
      .then((response) => {
        console.log(response);
        toast.success("OTP sent to Mobile", {
          autoClose: 5000,
          position: "top-right",
        });
        startResendTimer();
      })
      .catch((err) => {
        toast.error("Failed to send OTP", {
          autoClose: 5000,
          position: "top-right",
        });
      });
  };
  const handleResendOtp = async () => {
    setIsResendDisabled(true);
    setResendTimer(60);
    setIsSendOtpClicked(true);
    const payload = {
      mobile: phone,
      website: data["website"],
    };
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/institute/mobile/sendOtp`, payload)
      .then((response) => {
        console.log(response);
        toast.success("OTP sent to Mobile", {
          autoClose: 5000,
          position: "top-right",
        });
        startResendTimer();
      })
      .catch((err) => {
        toast.error("Failed to send OTP", {
          autoClose: 5000,
          position: "top-right",
        });
      });
  };

  const verifyMobileOtp = async () => {
    const payload = {
      mobile: phone,
      website: data["website"],
      otp: +otp,
    };
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/institute/mobile/verify`, payload)
      .then((response) => {
        console.log(response);
        if (!response.data.success) {
          toast.error(response.data.error, {
            autoClose: 5000,
            position: "top-right",
          });
        } else {
          toast.success("Mobile Verified", {
            autoClose: 5000,
            position: "top-right",
          });
          setOtp(null);
          setIsSendOtpClicked(false);
          setIsResendDisabled(false);
          setResendTimer(0);
        }
        getCompanyDetails();
      })
      .catch((err) => {
        toast.error("Failed to Verify", {
          autoClose: 5000,
          position: "top-right",
        });
      });
  };

  const handleSumit = async () => {
    const isValid = inputList.every((input) => {
      return (
        input.country &&
        input.state &&
        input.city &&
        input.pincode
      );
    });

    if (!isValid) {
      // If any input is missing, show an error message
      toast.error("Please enter all address fields for all locations");
      return;
    }


    const payload = {
      website: data["website"],
      primaryAddress: inputList.find((e) => e?.setTrueLocation),
      additionalAddress: inputList.filter((e) => !e.setTrueLocation),
    };
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/update-institute`, payload)
      .then((response) => {
        console.log(response);
        toast.success("Company Added Successfully", {
          autoClose: 5000,
          position: "top-right",
        });
        history.push(`/portal/classes/${companyId}`);
      })
      .catch((err) => {
        toast.error("error updating company", {
          autoClose: 5000,
          position: "top-right",
        });
        console.log(err.error);
      });
  };

  return (
    <div>
      <TopHeader />
      <div style={{ marginLeft: "240px", display: "flex", gap: "0px", background: "#FFF" }}>
        <div className="bread-crumb" onClick={() => history.push(`/portal/insitutes/${companyId}`)}>Create Instituition
          {/* <span>&#129170;</span>  OTP Verification   */}
          <span>&#129170;</span>
        </div>
        <div className="bread-crumb" style={{ color: "#362A41", margin: "14px 0 0 0px" }}>About your instituition</div>
      </div>
      <MainLayout>
        <PageWrapper>
          <HeaderText>{"Contact information"}</HeaderText>
          <ToastContainer />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>

              <Label style={{ marginLeft: "40px" }}>Institution Mobile Number</Label>
              <div style={{ display: "flex", gap: "10px", backgroundColor: "#F4F4F6" }}>
                <div>
                  <PhoneInput
                    // onChange={(phone) => setPhone(phone)}
                    onChange={handlePhoneChange}
                    name="phone"
                    onBlur={handlePhone}
                    disabled={data && data["contactNumberVerified"]}
                    value={phone}
                    country={'in'}
                    pattern={/^\d{5}-\d{5}$/}
                  />
                </div>
              </div>

              <span className="text-danger">{error2}</span>
              {/* {isValid ? <p>Phone number is valid</p> : <p className="text-danger">Phone number is invalid</p>} */}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0"
            }}
          >
            {data && !data["contactNumberVerified"] ? (
              <button className={btnenable ? "button2" : "button6"} disabled={!btnenable} onClick={() => sendMobileOtp()}>
                Send Otp
              </button>
            ) : null}

          </div>

          <div>
            {isSendOtpClicked && (
              <span>
                {resendTimer > 0
                  ? `Resend OTP in ${formatTimer(resendTimer)}`
                  : !isResendDisabled && (
                    <span onClick={handleResendOtp}>Resend OTP</span>
                  )}
              </span>
            )}
          </div>
          <div>
            {contactNumberVerified && <span>Mobile No Verified</span>}
          </div>
          {otpsecenable ?
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Label>Enter the OTP sent to {phone}</Label>
              </div>
              <div>
                {!data || !data["contactNumberVerified"] ? (
                  <>
                    <div>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <input
                          type="number"
                          name="otp"
                          //   value={otp}
                          style={{
                            borderRadius: "5px",
                            border: "1px solid black",
                            background: "rgba(54, 42, 65, 0.04)",
                            width: "40%",
                            height: "40px",
                            padding: "20px",
                          }}
                          onChange={(e) => setOtp(e.target.value)}
                          placeholder="Enter OTP sent to your Mobile Number"
                        />
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
                        <button
                          className="button2"
                          //   disabled={!otp}
                          onClick={() => verifyMobileOtp()}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  null
                )}
              </div>
            </>
            :
            ""}
          <Label>Institution Mail Id</Label>
          <InputGroup className="mb-3">
            <Form.Control
              aria-label="Job Title"
              name="xakalAddress"
              aria-describedby="basic-addon1"
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
              defaultValue={data && data["contactEmailId"]}
            />
          </InputGroup>

          <Label>Location</Label>
          {inputList.map((input, i) => {
            return (
              <>
                <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                  <div style={{ width: "50%" }}>
                    <Label>Address Line 1</Label>

                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Enter Address Line 1"
                        aria-label="Job Title"
                        name="addressOne"
                        aria-describedby="basic-addon1"
                        style={{
                          borderRadius: "5px",
                          background: "rgba(54, 42, 65, 0.04)",
                        }}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </InputGroup>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Label>Address Line 2</Label>

                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Enter Address Line 2"
                        aria-label="Job Title"
                        name="addressTwo"
                        aria-describedby="basic-addon1"
                        style={{
                          borderRadius: "5px",
                          background: "rgba(54, 42, 65, 0.04)",
                        }}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </InputGroup>
                  </div>
                </div>
                <Row>
                  <Col>
                    <Form.Group>
                      <Label>Country  <span className="text-danger">*</span></Label>

                      <div>
                        <select
                          required
                          value={input.country}
                          onChange={(e) => handleInputChange(e, i)}
                          name="country"
                          className="footer-header mb-3"
                          style={{ border: "none", height: "40px" }}
                        // onBlur={validateInput}
                        >
                          <option value="">Country</option>
                          {Country &&
                            Country.getAllCountries().map((item) => (
                              <option key={item.name} value={item.isoCode}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Label>State / Provision <span className="text-danger">*</span></Label>

                      <select
                        required
                        value={input.state}
                        onChange={(e) => handleInputChange(e, i)}
                        name="state"
                        className="footer-header mb-3"
                        style={{ border: "none", height: "40px" }}
                      // onBlur={validateInput}
                      >
                        <option value="">State</option>
                        {State &&
                          input.country &&
                          State.getStatesOfCountry(input.country).map((item) => (
                            <option key={item.name} value={item.isoCode}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Label>City / Town  <span className="text-danger">*</span></Label>

                      <select
                        required
                        value={input.city}
                        onChange={(e) => handleInputChange(e, i)}
                        name="city"
                        className="footer-header mb-3"
                        style={{ border: "none", height: "40px" }}
                        onBlur={validateInput}
                      >
                        <option value="">City</option>
                        {City &&
                          input.country &&
                          input.state &&
                          City.getCitiesOfState(input.country, input.state).map((item) => (
                            <option value={item.name}>{item.name}</option>
                          ))}
                      </select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Label>Zip / Pincode <span className="text-danger">*</span></Label>
                      <Form.Control
                        placeholder="Enter Zip / Pincode"
                        aria-label="Job Title"
                        name="pincode"
                        aria-describedby="basic-addon1"
                        style={{
                          borderRadius: "5px",
                          background: "rgba(54, 42, 65, 0.04)",
                        }}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </Form.Group>
                  </Col>

                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(e) => setPrimaryLocation(e, i)}
                      />
                      <p style={{ marginTop: "15px" }}>
                        set this as an primary location
                      </p>
                    </div>
                  </Col>

                  {inputList.length - 1 === i && (
                    <Col style={{
                      display: "flex",
                      justifyContent: "end"
                    }}>
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          gap: "10px",
                          // top: "23%",
                          border: "1px solid grey",
                          borderRadius: "8px",
                          padding: "5px 20px",
                          height: "40px"
                        }}
                        onClick={handleAddClick}
                      >
                        <AiOutlinePlusCircle size={25} />
                        <p>Add Address</p>
                      </div>
                    </Col>
                  )}
                  {inputList.length !== 1 && (
                    <button
                      className="mr10 Add-language"
                      onClick={() => handleRemoveClick(i)}
                    >
                      Remove
                    </button>
                  )}
                </Row>
              </>
            );
          })}

          <DateWrapper>
            <button className="button2" onClick={handleSumit}>
              Save
            </button>
          </DateWrapper>
        </PageWrapper>
      </MainLayout>
    </div>
  );
};

export default AboutContactInsitute;
