import React, {useEffect} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width:90vw;
    // overflow-x:hidden;
`
const Para = styled.div`
    width:90vw;
    padding:20px 50px 100px 50px;
    margin:auto;
    // overflow-x:hidden;
    @media (max-width:500px){
        width:100%;
        padding:20px 10px 30px 30px;
    }
`
const Head = styled.div`
color:#362A41;
font-size: 24px;
display:flex;
justify-content:center;
margin-top:30px;
font-family:Inter-Medium,sans-serif;
`
const Content = styled.div`
color:#362A41;
font-size: 16px;
// display:flex;
// justify-content:center;
line-height:22px;
margin-top:20px;
font-family:Inter-Medium,sans-serif;

`
const ContentHead = styled.div`
color:#362A41;
font-size: 22px;
line-height:24px;
margin-top:20px;
font-family:Inter-Medium,sans-serif;
text-align:left;
`
const Privacy = () => {
    useEffect(() => {
        document.title = "Privacy policy | Xakal";
      }, []);
    
    return(
        <div >
            <Wrapper>
            <div >
            <Head>PRIVACY POLICY </Head>
            </div>
            <Para>
            <ContentHead>We prioritize your Privacy: </ContentHead>
            
            <Content>Xakal is dedicated to facilitating connections among professionals worldwide, enabling them to enhance their productivity and achieve success. Our fundamental pledge revolves around maintaining transparency regarding the information we gather about you, its purpose, and the parties with whom we may share it. </Content>
            
            <Content>This Privacy Policy comes into effect when you engage with our Services, as outlined below. We provide our users with options regarding the data we gather, how we utilize it, and with whom we may exchange it, all of which are detailed in this Privacy Policy, our Cookie Policy, the Settings section, and our Help Centre. </Content>
            <ContentHead>Data we collect from you: </ContentHead>
            <Content>To register and create an account with us, you are required to provide certain data, including your name, email address and/or mobile number, and a password.  </Content>
            <Content>When setting up your Xakal profile, you have the flexibility to choose the information you include, such as details about your education, work experience, skills, a profile photo, your city or area, and endorsements. While it's not mandatory to furnish additional information on your profile, having a complete profile enhances your experience with our Services. It can also make it easier for recruiters and potential business connections to discover you.  </Content>
            <Content>The decision to include sensitive information on your profile and make it public is entirely up to you. However, we advise against posting or adding personal data that you wouldn't want to be accessible to the public. </Content>
            <Content>We may collect personal data when you voluntarily provide, post, or upload information on our platform. This can occur when you fill out forms (e.g., demographic data or salary information), respond to surveys, or submit a resume or job application through our Services. You have the choice not to post or upload personal data, but please note that this decision may restrict your ability to fully utilize and engage with Xakal. </Content>
            <ContentHead>Services in Future: </ContentHead>
            <Content>As we enhance our Services, we continually acquire fresh data and develop innovative methods for utilizing this data. </Content>
            <ContentHead>Security Measures: </ContentHead>
            <Content>As we gather and process personal information, and while we maintain this data, we will safeguard it in accordance with the AWS WAF and in compliance with the provisions of the Information Technology Act, 2000, along with any related regulations, and any other relevant legal requirements. Our aim is to prevent data loss, theft, unauthorized access, disclosure, copying, unlawful data breaches, or unauthorized alterations. </Content>
            <Content>While we will make every reasonable effort to protect the information you provide to us, it's essential to acknowledge that no method of electronic transmission or storage can offer absolute security, and no one can guarantee complete data protection. In the event of a data breach, we will adhere to the applicable laws that pertain to us. </Content>
            <Content>By using our platform and its services, you explicitly grant us permission to collect, store, and process your personal information for the purposes outlined in this Privacy Policy. You recognize that the processing of this information is necessary to enable your utilization of the platform and its services, as well as to adhere to applicable legal obligations. </Content>
            <ContentHead>Modifications to this Privacy Policy: </ContentHead>
            <Content>You recognize that Xakal reserves the right to alter or adjust this Privacy Policy at its exclusive discretion, without any prior notice to you. You explicitly consent that any such revisions or alterations will take effect immediately and will be enforceable upon you. Kindly review our Privacy Policy regularly for updates. </Content>
            </Para>
            </Wrapper>
        </div>
    )
}

export default Privacy;