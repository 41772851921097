import React, { useRef, useState, useEffect } from "react";
import DesktopHeader from "../desktopHeader";
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import "./aptitude.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BsPenFill, BsTrashFill } from "react-icons/bs";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CreateAptitude from "./createaptitude";
import CloseIcon from "../../images/CloseIcon.svg";
import EditAptitude from "./editaptitude";

const AptitudeAdmin = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [apId, setApId] = useState();
  const handleCreate = () => {
    setOpenCreate(true);
  };
  const columns = [
    {
      field: "questionName",
      headerName: "Question Name",
      width: 400,
    },
    {
      field: "optionA",
      headerName: "Option 1",
      width: 120,
    },
    {
      field: "optionB",
      headerName: "Option 2",
      width: 120,
    },
    { field: "optionC", headerName: "Option 3", width: 100 },
    { field: "optionD", headerName: "Option 4", width: 100 },
    { field: "answer", headerName: "Answer", width: 100 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <div className="delete-aptitude-list-icon">
          <BsPenFill
            style={{ cursor: "pointer",left:"30px" }}
            onClick={() => {
              setApId(params.row._id);
              setOpenEdit(true);
            }}
          />
          <BsTrashFill
            style={{ cursor: "pointer" }}
            onClick={() => deleteAptitude(params.row._id)}
          />
        </div>
      ),
    },
  ];

  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);

  const handleFileUpload = (event) => {
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = (e) => {
        const dataString = e.target.result;
        const parsedData = parseExcel(dataString);
        sendToServer(parsedData);
      };

      reader.readAsBinaryString(file);
    }
  };

  const parseExcel = (dataString) => {
    const workbook = XLSX.read(dataString, { type: "binary" });
    const sheetNames = workbook.SheetNames;
    let parsedData = [];

    sheetNames.forEach((sheetName) => {
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headerRow = excelData.shift();
      const fieldNames = headerRow.map((fieldName) => fieldName.trim());

      excelData.forEach((row) => {
        const rowData = {};
        fieldNames.forEach((fieldName, index) => {
          rowData[fieldName] = row[index];
        });
        parsedData.push(rowData);
      });
    });

    return parsedData;
  };

  const sendToServer = async(data) => {
    try{ 
      await axios
      .post(`${process.env.PUBLIC_URL}/xakal/create-aptitude`, data)
      
        getAllAptitude();
      }
      catch(err){console.log("Error fetching details:",err)};
  };
  const getAllAptitude = async() => {
    try{
      const res = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-aptitude`)
        setData(res.data.aptitude);
      }
      catch(err){console.log("Error fetching details:",err)};
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    getAllAptitude();
  }, []);
  const deleteAptitude = async(id) => {
    try{
      await axios
      .delete(`${process.env.PUBLIC_URL}/xakal/delete-one-aptitude/${id}`)
      
        toast.error("Question deleted successfully");
        getAllAptitude();
      }
      catch(err){console.log("Error fetching details:",err)};
  };
  const downloadCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      columns.map((column) => column.headerName).join(",") +
      "\n" +
      data
        .map((row) => columns.map((column) => row[column.field]).join(","))
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "aptitude_data.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadButtonClick = () => {
    downloadCSV();
  };
  
  return (
    <>
      <ToastContainer />
      <div className="header-layouts">
      <DesktopHeader title={"Aptitude"} />
      </div>
      <div style={{ margin: "4rem" }}>
        <div className="apt-wrap">
          <button className="button03" onClick={handleDownloadButtonClick}>
            Download
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileUpload}
            multiple
          />
          <button onClick={handleButtonClick} className="asses-btn">
            Import Question
          </button>
          <button onClick={handleCreate} className="asses-btn">
            Create Question
          </button>
        </div>
        <DataGrid
          sx={{
            height: "50vh",
            borderRadius: "10px",
            color: "#49454F",
            fontSize: "14px",
          }}
          md={{ color: "#49454F", fontSize: "14px" }}
          getRowId={(row) => row._id}
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50]}
        />
      </div>
      <Dialog
        open={openCreate}
        PaperProps={{
          style: {
            borderRadius: "24px",
            padding: "20px",
            maxWidth: "600px",
            height: "auto",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className="modalHeader"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontweight: "normal",
            fontSize: "18px",
            color: "#362A41",
            position: "relative",
            padding: "20px",
          }}
        >
          {"Create Question"}
          <img src={CloseIcon} alt="img" onClick={() => setOpenCreate(false)} />
        </DialogTitle>
        <DialogContent>
          <CreateAptitude
            callservice={getAllAptitude}
            close={() => setOpenCreate(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openEdit}
        PaperProps={{
          style: {
            borderRadius: "24px",
            padding: "20px",
            maxWidth: "600px",
            height: "auto",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className="modalHeader"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontweight: "normal",
            fontSize: "18px",
            color: "#362A41",
            position: "relative",
            padding: "20px",
          }}
        >
          {"Edit Question"}
          <img src={CloseIcon} alt="img" onClick={() => setOpenEdit(false)} />
        </DialogTitle>
        <DialogContent>
          <EditAptitude
            callservice={getAllAptitude}
            close={() => setOpenEdit(false)}
            apId={apId}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AptitudeAdmin;
