import React from "react";
import LandingPage from "./components/landingPage";

import "./App.css";
function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;
