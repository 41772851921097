import React, { useRef } from "react";
import { useState, useEffect } from "react";
import "../styles/login.css";
import xalogo from "../images/xakallogo.png";
import "../styles/utils.css";
import backArrow from "../images/arrow-box.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import PassHint from "../images/PassHint.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const PassHintHead = styled.div`
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;

  img {
    position: absolute;
    top: 336px;
    left: 112px;
    @media (max-width: 500px) {
      left: 30px;
    }
    @media (min-height: 465px) and (max-height: 665px) {
      top: 160px;
    }
    @media (min-height: 666px) and (max-height: 825px) {
      top: 260px;
    }
    @media (min-width: 821px) and (max-width: 1024px) {
      left: 325px;
    }
    @media (min-width: 1025px) and (max-width: 1179px) {
      left: 375px;
    }
  }
`;

const OtpWrapper = styled.div``;
const Time = styled.div``;

const Resend = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
`;
// eslint-disable-next-line no-unused-vars
const Verify = styled.div`
  position: relative;
  right: 70px;
`;

const BtnWrapper = styled.div`
  margin-top: 30px;
`;

const ErrWrapper = styled.div``;
const OtpLogin = () => {
  const [email, setEmail] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [valid, setValid] = useState(false);
  const [verify, setVerify] = useState(false);
  const [errors, setErrors] = useState(null);
  const [errors4, setErrors4] = useState(null);
  const [count, setCount] = useState(5);
  const [showTimeRemaining, setShowTimeRemaining] = useState(true);
  const [timerExpired, setTimerExpired] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  const [otp, setOtp] = useState(["", "", "", ""]);

  const inputRefs = useRef(otp.map(() => React.createRef()));
  const handleChange = (event, index) => {
    const updatedOtp = [...otp];

    // Handle backspace
    if (event.target.value === "" && index > 0) {
      // Clear the current digit only when backspacing
      updatedOtp[index] = "";
      setOtp(updatedOtp);

      // Focus on the previous input field
      inputRefs.current[index - 1].current.focus();
      return;
    }

    // Update the current digit
    updatedOtp[index] = event.target.value.slice(0, 1);

    // Update the OTP state
    setOtp(updatedOtp);

    // Clear the error message when any input changes
    setErrors(null);
    setValid(false);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setValid(true);
    }

    // Check if OTP length is equal to 4
    const fullOtp = updatedOtp.join("");
    if (fullOtp.length === 4) {
      setValid(true);
    } else {
      setValid(false);
    }

    // Focus on the next input field if available
    if (index < otp.length - 1 && event.target.value !== "") {
      inputRefs.current[index + 1].current.focus();
    }
  };
  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4); // Get the pasted content
    const updatedOtp = [...otp];

    // Insert the pasted content into the OTP input fields
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otp.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }

    setOtp(updatedOtp);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setValid(true);
    }

    // Focus on the next input field if available
    if (index + pastedData.length < otp.length) {
      inputRefs.current[index + pastedData.length].current.focus();
    }
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    setErrors(null);
    setTimerExpired(false); // Reset the timer state
    setMinutes(1);
    setSeconds(0);
    setShowTimeRemaining(true);
    setDisableFields(false);
    if (email === "") {
      alert("Enter Your Email !");
    } else if (!email.includes("@")) {
      alert("Enter Valid Email !");
    } else {
      // setSpiner(true);
      const data = {
        email: email,
      };
      try{
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/resendPasswordOtp`,
        data
      );
      if (response.status === 200) {
        // setSpiner(false);
        // handleShowToaster();
        toast.success("OTP Sent Successfully...!");
      } else {
        // toast.error(response.response.data.error);
      }
    }
    catch(err)  {console.log("Error fetching details:",err)};
  }
  };

  const restartTimer = () => {
    setMinutes(15);
    setSeconds(0);
    setErrors(null);
    setCount(1);
    setDisableFields(true); // Disable the fields
    setErrors(null);
  };

  useEffect(() => {
    let timer;
    if (showTimeRemaining && !verify && !timerExpired) {
      timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          clearInterval(timer);
          setTimerExpired(true);
          setDisableFields(false);
          if (count === 1) {
            restartTimer();
          }
        }
        if (disableFields && timerExpired) {
          setDisableFields(false); // Enable the fields after the timer expires
        }
      }, 1000);
    }

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimeRemaining, verify, minutes, seconds, timerExpired]);
  const verifyOtp = async (e) => {
    e.preventDefault();
    try {
      if (otp.join("").length !== 4) {
        setErrors("Invalid OTP");
      } else {
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/reset-password`,
          {
            otp: otp.join(""),
            email: email,
          }
        );

        if (response.data.success) {
          // setMessage(response.data.message);
          toast.success(response.data.message);
          setErrors(null);
          setShowTimeRemaining(false);
          setErrors4(null);
          setTimeout(() => {
            history.push({
              pathname: "/new-passwords",
              state: { email: email },
            });
          }, 1000);
        } else if (response.data.verified) {
          setVerify(true);
          toast.success(response.data.message);
          setErrors(null);
         
          setShowTimeRemaining(false);
        } else {
          // Handle verification failure
          handleVerificationFailure();
          // handleVerificationFailure();
          if (count === 1) {
            // Start 15-minute timer when one attempt is left
            restartTimer();
          }
        }
      }
    } catch (error) {
      console.error("Error in verifyOtp:", error);
    }
  };
  const handleVerificationFailure = () => {
    if (count > 1) {
      setCount(count - 1);
      if (count === 2) {
        setErrors4("Only 1 attempt left!");
        setErrors(null);

        // Set a timeout to clear the maximum attempts reached message after 1 second
        setTimeout(() => {
          setErrors4(null);
        }, 4000);
      } else {
        setErrors(`Incorrect OTP!`);
      }
    } else {
      setErrors4("Maximum attempts reached. Please wait for 15 minutes.");
      setValid(false);
      setCount(1);
      setDisableFields(true); // Disable the fields

      if (!timerExpired) {
        restartTimer();

        setTimeout(() => {
          setErrors4(null);
        }, 4000);
      }
    }
  };

  return (
    <div className="login-wrapper otp-main">
      <ToastContainer />
      <div className="form-wrapper  wrap-login100 p-b-20">
        <div className="margin-logo">
          <div className="logo-holder">
            <img
              src={xalogo}
              className="login-logo signup-xakal-logo"
              alt="XAKAL"
            />
          </div>
        </div>
        <div className="f-pass-container">
          <div className="w-100">
            <div className="sub-header">
              <div className="arrow-box">
                <Link
                  to={{
                    pathname: "/forgot-password",
                    state: { email: email },
                  }}
                >
                  <img src={backArrow} className="back-arrow-img" alt="XAKAL" />
                </Link>
              </div>
              <h4 className="textpass font-face-gm mb-0">OTP Verification</h4>
            </div>

            <h4 className="textpass">Enter the OTP sent to {email}</h4>
            <OtpWrapper>
              <div className="otp-group">
                {otp.map((digit, idx) => (
                  <input
                    key={idx}
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    pattern="\d{1}"
                    className="otp-inputs"
                    value={digit}
                    id={`otp-input-${idx}`}
                    ref={inputRefs.current[idx]}
                    onChange={(e) => handleChange(e, idx)}
                    onPaste={(e) => handlePaste(e, idx)}
                    disabled={disableFields}
                  />
                ))}
              </div>
            </OtpWrapper>
            <div className="error-wrapper">
              <div>
                {" "}
                {errors && (
                  <ErrWrapper className="error-msg">{errors}</ErrWrapper>
                )}
              </div>
         
            </div>

            <Wrapper>
              {showTimeRemaining && !verify && !timerExpired && (
                <Time className="otp-timer">
                  Time remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </Time>
              )}
            </Wrapper>
            <Resend show={timerExpired && !verify}>
              <p class="login-signupphrase">
                Didn’t receive the code yet?{" "}
                <span onClick={resendOtp} style={{ cursor: "pointer" }}>
                  Resend
                </span>{" "}
              </p>
            </Resend>

            {errors4 && (
              <div id="message6">
                <div
                  style={{ position: "absolute", left: "-15px", top: "10px" }}
                >
                  <img src={PassHint} alt="img" />
                </div>
                <PassHintHead style={{ color: "white" }}>
                  {errors4}
                </PassHintHead>
                <div style={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                  <div className="check"></div>
                  <p className="valid">
                    After this attempt, you’ll have to wait for 15 minutes
                  </p>
                </div>
              </div>
            )}

<BtnWrapper>
              <div className="container-login100-form-btn">
                <button
                  className="login100-form-btn"
                  onClick={verifyOtp}
                  disabled={!valid || verify}
                  style={{ opacity: !valid || verify ? "0.5" : "1" }}
                >
                  Next
                </button>
              </div>
            </BtnWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpLogin;
