import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ToastContainer } from "react-toastify";
import ExperienceModal from "./experienceModal";
import { useEffect, useState } from "react";
import axios from "axios";
import Common from "../../utils/common";
import EducationModal from "./educationModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Back from "../../images/Back.svg";
import ResumeSkill from "./resumeSkill";

const StatusPreview = ({
  userId,
  handleSection,
  input,
  experiencePreview,
  educationPreview,
  handleSkill,
  openPreview,
  inactive,
  enableService,
  accountDetailPreview
}) => {
  const [experience, setExperience] = useState(false);
  const [experiencePre, setExperiencePreview] = useState(experiencePreview);
  const [educationPre, setEducationPreview] = useState(educationPreview);
  const [education, setEducation] = useState(false);
  const [accountDetail, setAccountDetail] = useState(accountDetailPreview);
  const history = useHistory();
  const [inputs, setInputData] = useState();
  const getOneAccount = async() => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/account-get/${Common.loggedIn(userId)}`)
        setAccountDetail(response.data.account);
      }
      catch(error){console.log("Error fetching details:",error)}
  };
  useEffect(() => {
    getOneAccount();
    getAllExperience();
    getAllEducation();
    getAllAbout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllExperience = async() => {
    try{
      const response = await axios
      .get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-experience-detail-all/${Common.loggedIn(userId)}`
      )
      
        setExperiencePreview(response.data.experience);
        console.log(response, "response");
        setExperience(false);
      }
      catch(error){console.log("Error fetching details:",error)}
  };

  const getAllEducation = async() => {
    try{
      const response = await axios
      .get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-education-detail-all/${Common.loggedIn(userId)}`
      )
      
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          setEducationPreview(response.data.educationOne);
        }
      }
      catch(error){console.log("Error fetching details:",error)}
  };
  const getAllAbout = async() => {
    try{
      const response = await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn(
          userId
        )}`
      )
      
        if (response && response.data && response.data.success) {
          setInputData(response.data.aboutOne);
        }
      }
      catch(error){console.log("Error fetching details:",error)}
  };
  const handleBank = () => {
    history.push({
      pathname: "/portal/accountdetails",
      state: {
        open: openPreview,
        inactive: "inactive",
        enableService: enableService,
      },
    });
  };
  const [openResumeSkill, setOpenResumeSkill] = useState(false);
  const handleResumeSkill = () => {
    setOpenResumeSkill(true);
  };
  return (
    <div>
      <ToastContainer />

      <div className="modalBody">
        <div>
          <div>
            <div className="profile-update">
              {inputs?.inputList.length &&
              inputs?.inputList[0].proficiency !== "" &&
              inputs?.inputList2.length &&
              inputs?.inputList3[0]?.resume ? (
                <>
                  <div className="profile-check"></div>
                  <div>Fill in atleast one language, resume and skills</div>
                  <img
                    src={Back}
                    alt="img"
                    onClick={() => handleResumeSkill()}
                  />
                </>
              ) : (
                <>
                  <div className="profile-uncheck"></div>
                  <div>Fill in atleast one language, resume and skills</div>
                  <img
                    src={Back}
                    alt="img"
                    onClick={() => handleResumeSkill()}
                  />
                </>
              )}
            </div>
            <div className="profile-update">
              {experiencePre?.length ? (
                <>
                  <div className="profile-check"></div>
                  <div className="profile-crieteria">Experience</div>
                  <img
                    src={Back}
                    alt="img"
                    onClick={() => setExperience(true)}
                  />
                </>
              ) : (
                <>
                  <div className="profile-uncheck"></div>
                  <div className="profile-crieteria">Experience</div>
                  <img
                    src={Back}
                    alt="img"
                    onClick={() => setExperience(true)}
                  />
                </>
              )}
            </div>

            <div className="profile-update">
              {educationPre?.length ? (
                <>
                  <div className="profile-check"></div>
                  <div className="profile-crieteria">Education</div>
                  <img
                    src={Back}
                    alt="img"
                    onClick={() => setEducation(true)}
                  />
                </>
              ) : (
                <>
                  <div className="profile-uncheck"></div>
                  <div className="profile-crieteria">Education</div>
                  <img
                    src={Back}
                    alt="img"
                    onClick={() => setEducation(true)}
                  />
                </>
              )}
            </div>
            <div className="profile-update">
              {accountDetail?.length ? (
                <>
                  <div className="profile-check"></div>
                  <div className="profile-crieteria">Bank Account</div>
                  <img
                    src={Back}
                    alt="img"
                    onClick={() => {
                      handleBank();
                    }}
                  />
                </>
              ) : (
                <>
                  <div className="profile-uncheck"></div>
                  <div className="profile-crieteria">Bank Account</div>
                  <img
                    src={Back}
                    alt="img"
                    onClick={() => {
                      handleBank();
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog open={experience} fullWidth maxWidth="md">
        <DialogContent>
          <ExperienceModal
            onClose={() => {
              setExperience(false);
              getAllExperience();
            }}
            onSave={getAllExperience}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={education} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <EducationModal
            onClose={() => {
              setEducation(false);
              getAllEducation();
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openResumeSkill} fullWidth maxWidth="md">
        <ResumeSkill
          onClose={() => {
            setOpenResumeSkill(false);
            getAllAbout();
          }}
        />
      </Dialog>
    </div>
  );
};

export default StatusPreview;
