import React from "react";

const PdfViewer = ({ pdfUrl }) => {
  const iframeStyle = {
    width: "100%",
    height: "500px",
    border: "none", // Use "none" to remove the border
  };
  return (
    <div>
      <iframe title="PDF Viewer" src={pdfUrl} style={iframeStyle}></iframe>
    </div>
  );
};

export default PdfViewer;
