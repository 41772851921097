/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CloseIcon from "../../images/CloseIcon.svg";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import "react-toggle/style.css";
import { Auth } from "aws-amplify";
import axios from "axios";
import "./buttonStyle.css";
import "./jobs.css";
import MobileHeader from "../mobileHeader";
import DesktopHeader from "../desktopHeader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import NewJob from "./addNewJob";
import Prefill from "./addPrefillNewJob";
import JobInfo from "./addJobInformation";
import PreviewJob from "./previewJob";
import Fitme from "./fitme";
import Plus from "../../images/Normal_State_Plus.svg";
import PlusHover from "../../images/Hover_State_Plus.svg";
import Common from "../../utils/common";

const HeaderLayout = styled.div``;
const NativeLink = styled.div``;
const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  color: 362A41;
`;
const HeaderDownLayout = styled.div``;
const HrLine = styled.div`
  border: 1px solid #f7f7f8;
  width: 100%;
  margin: 0;
`;
const HrLines = styled.div``;

const CustomHeaderLayout = ({
  activeStates,
  openNewPopup,
  isOpen
}) => {
  const [openJobOne, setOpenJobOne] = useState(false);
  const [openJobTwo, setOpenJobTwo] = useState(false);
  const [jobId, setJobId] = useState("");
  const [openJobOness, setOpenJobOness] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [user, setUsers] = useState("");
  const [shouldUpdates, setShouldUpdates] = useState(false);
  const [openJobPreview, setOpenJobPreview] = useState(false);
  const [previewId, setPreviewId] = useState("");

  const closeJobpageOn = (id) => {
    setOpenJobOne(false);
    setOpenJobTwo(true);
    setJobId(id);
  };

  const goToJobtwo = () => {
    setOpenJobTwo(true);
    setOpenJobPreview(false);
  };

  const handleCall = () => {
    getAllJob();
    setOpenJobPreview(false);
  };

  const closeJobpageOnssYes = (id) => {
    setOpenJobOness(true);
    setOpenJobTwo(false);
    setJobId(id);
    setShouldUpdates(true);
  };

  const closeAll = () => {
    setOpenJobOne(false);
  };
  const closeAllJob = () => {
    setOpenJobTwo(false);
  };
  const updatePreviews = () => {
    setOpenJobTwo(false);
    setOpenJobPreview(false);
  };

  const updatePreview = (id) => {
    setOpenJobTwo(false);
    setOpenJobPreview(true);
    setPreviewId(id);
  };

  const updateSavePreviews = (id) => {
    setOpenJobTwo(false);
    setPreviewId(id);
  };

  const goToJobOne = () => {
    setOpenJobOness(true);
    setOpenJobTwo(false);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);

  const closeJobpageOnss = (id) => {
    setOpenJobOness(false);
    setOpenJobTwo(true);
    setJobId(id);
    setShouldUpdates(true);
  };

  const closeAllssActive = () => {
    setOpenJobOness(false);
    getAllJob();
  };

  function onSubmit() {
    setOpenJobOne(true);
  }
  const history = useHistory();
  const location = useLocation();

  const isCardActive = (path) => {
    return location.pathname === path;
  };

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [dataDup, setDataDup] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [question, setQuestion] = useState();
  // eslint-disable-next-line no-unused-vars
  const [exhausted, setExhausted] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [associatesDup, setAssociatesDup] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [userId, setUser] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [associates, setAssociates] = useState([]);

  const getAllJob = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-job-detail-skill?p=${pagination}`
      );
      if (response.data) {
        if (response.data.activeJobsOne.length === 0) {
          setExhausted(false);
        } else {
          setPagination(response.data.pageCount);
          const uniqueJobs = response.data.activeJobsOne.filter(
            (job) => !data.some((existingJob) => existingJob._id === job._id)
          );
          setData((current) => {
            if (current) {
              return [...current, ...uniqueJobs];
            } else {
              return [];
            }
          });
          setDataDup((current) => {
            if (current) {
              return [...current, ...uniqueJobs];
            } else {
              return [];
            }
          });
          setQuestion((current) => {
            if (current) {
              return [...current, response.data.job];
            } else {
              return [];
            }
          });
        }
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    let s = document.cookie.split(";");
    if (s.length > 0) {
      for (let i = 0; i < s.length; i++) {
        if (!(s[i].indexOf("alreadyLoggedIn=") !== -1)) {
          document.cookie = `alreadyLoggedIn=true;`;
        }
      }
    }
    getAllJob();
  }, []);

  const getUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/getuser`
      );
      setUser(response.data);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  // eslint-disable-next-line no-unused-vars
  const [searchKeyword, setSearchKeyword] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchInputVal, setSearchInputVal] = useState("");

  const handleInputOneChange = (event) => {
    setSearchKeyword(event.target.value);
    setSearchInputVal(event.target.value);
    const defaultJobs = dataDup;
    const defaultAssociated = associatesDup;
    if (event.target.value.length > 0) {
      const filterAssociates = defaultAssociated?.filter(
        (e) =>
          e?.firstName
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          e?.middleName
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          e?.lastName
            ?.toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          (
            e?.firstName?.toLowerCase() +
            e?.middleName?.toLowerCase() +
            e?.lastName?.toLowerCase()
          )
            .replace(/\s+/g, "")
            .includes(event.target.value.toLowerCase().replace(/\s+/g, "")) ||
          (e?.firstName?.toLowerCase() + e?.lastName?.toLowerCase())
            .replace(/\s+/g, "")
            .includes(event.target.value.toLowerCase().replace(/\s+/g, ""))
      );
      setAssociates(filterAssociates);

      const filterJobs = defaultJobs?.filter(
        (e) =>
          e?.jobTitle &&
          e?.jobTitle
            ?.toLowerCase()
            .replace(" ", "")
            .includes(event.target.value.toLowerCase().replace(/\s+/g, ""))
      );
      setData(filterJobs);
    } else {
      setAssociates(defaultAssociated);
      setData(defaultJobs);
    }
  };

  const [isPopupOpen, setPopupOpen] = useState(false);
  const handleSave = (data) => {
    setPopupOpen(false); // Close the dialog if needed
  };

  const searchedKey = () => {};
  const [userRole, setUserRole] = useState(false);
  const prefill = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      );
      if (response && response.data && response.data.aboutOne) {
        setUserRole(response.data.aboutOne.userType.includes("hr"));
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    prefill();
  }, []);
  const [activeState, setActiveState] = useState(() => {
    // Initialize active state based on current path
    switch (location.pathname) {
      case "/portal/applied-jobs":
        return "applied";
      case "/portal/favorites":
        return "favorites";
      case "/portal/manage-jobs":
        return "manage";
      default:
        return "job"; // Default state for "/portal/jobs"
    }
  });
  const handleNavigationClick = (path, stateValue) => {
    history.push(path);
    setActiveState(stateValue);
  };
  const isTab = window.innerWidth < 821;
  useEffect(() => {
    setActiveState(activeState);
    setOpenJobOne(openNewPopup);
  }, []);
  return (
    <>
      <HeaderLayout className="header-layout">
        {/* Start of Mobile Header  */}
        <MobileHeader  />
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader
          title={"Jobs"}
          searchedKey={searchedKey}
          onFieldChange={handleInputOneChange}
        />
        {/* End of desktop Header  */}
      </HeaderLayout>
      {isTab &&  isOpen ? 
      ("")
      :
      (
      <HeaderDownLayout>
        <div className="job-nav-container job-nav-contains">
          <NativeLink className="job-nav">
            <div
              onClick={() => handleNavigationClick("/portal/jobs", "job")}
              className={
                isCardActive("/portal/jobs") || activeState === "job"
                  ? "active job-nav-link"
                  : "job-nav-link"
              }
            >
              Explore Jobs
            </div>
            <div
              onClick={() =>
                handleNavigationClick("/portal/applied-jobs", "applied")
              }
              className={
                isCardActive("/portal/applied-jobs") ||
                activeState === "applied"
                  ? "active job-nav-link"
                  : "job-nav-link"
              }
            >
              Applied Jobs
            </div>
            <div
              onClick={() =>
                handleNavigationClick("/portal/favorites", "favorites")
              }
              className={
                isCardActive("/portal/favorites") || activeState === "favorites"
                  ? "active job-nav-link"
                  : "job-nav-link"
              }
            >
              Favourites
            </div>
            <div
              onClick={() =>
                handleNavigationClick("/portal/manage-jobs", "manage")
              }
              className={
                isCardActive("/portal/manage-jobs") || activeState === "manage"
                  ? "active job-nav-link"
                  : "job-nav-link hide-url"
              }
            >
              Manage
            </div>
          </NativeLink>
          <Wrapper>
            <div className="o-desk">
              <button
                onClick={() => setPopupOpen(true)}
                style={{
                  fontWeight: "normal",
                  fontSize: "14px",
                  textDecoration: "underline",
                  textUnderlineOffset: "3px",
                }}
              >
                Couldn't find a right job?
              </button>
            </div>
            {userRole ? (
              <>
                <div className="o-desk">
                  <button
                    className="button5"
                    onClick={() =>
                      handleNavigationClick("/portal/manage-jobs", "manage")
                    }
                    style={{
                      fontWeight: "normal",
                      width: "auto",
                      height: "auto",
                    }}
                  >
                    Manage Jobs
                  </button>
                </div>

                <div className="o-desk">
                  <button
                    className="button2 btn2"
                    onClick={onSubmit}
                    style={{ fontWeight: "normal" }}
                  >
                    <img
                      src={PlusHover}
                      alt="img"
                      className="plus-icon2"
                    />
                    <img
                      src={Plus}
                      alt="img"
                      className="plus-icon"
                    />
                    Post a Job
                  </button>
                </div>
              </>
            ) : null}
          </Wrapper>
        </div>
      </HeaderDownLayout>
      ) }
      
      <Dialog
        open={isPopupOpen}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px", maxWidth: "415px" } }}
      >
        <div
          className="modalHeader"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px",
          }}
        >
          <span className="mTitle">Job</span>
          {/* <AiOutlineCloseCircle onClick={() => setPopupOpen(false)} /> */}
          <img src={CloseIcon} alt="img" onClick={() => setPopupOpen(false)} />
        </div>
        <HrLine />
        <DialogContent>
          <Fitme onSave={handleSave} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openJobOne}
        PaperProps={{
          style: {
            width: "100%",
            height: "fit-content",
            borderRadius: "24px",
            maxWidth: "430px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <NewJob
            closeJobOne={closeJobpageOn}
            closeAll={closeAll}
            newOne={goToJobOne}
            openNewJob={openJobOne}
            isCardActive={activeState}
            
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openJobOness}
        PaperProps={{
          style: {
            width: "100%",
            height: "fit-content",
            borderRadius: "24px",
            maxWidth: "430px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        ></DialogTitle>
        <DialogContent>
          <Prefill
            closeJobOne={closeJobpageOnss}
            closeAll={closeAllssActive}
            jobId={jobId}
            shouldUpdate={shouldUpdates}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openJobTwo}
        PaperProps={{
          style: {
            width: "100%",
            height: "fit-content",
            borderRadius: "24px",
            maxWidth: "460px",
          },
        }}
        halfWidth
        maxWidth="sm"
      >
        <HrLines className="job-divider" style={{ margin: "0px" }} />
        <DialogContent>
          <JobInfo
            jobId={jobId}
            previewJobId={updatePreview}
            close={updatePreviews}
            previewSaveJobId={updateSavePreviews}
            goToJobOne={goToJobOne}
            closeAll={closeAll}
            openSaves={closeAllJob}
            closePopups={() => setOpenJobTwo(false)}
            goToOnePart={closeJobpageOnssYes}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobPreview}
        PaperProps={{
          style: {
            width: "100%",
            height: "fit-content",
            borderRadius: "24px",
            padding: "0px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent
          PaperProps={{
            style: { padding: "0px" },
          }}
        >
          <PreviewJob
            previewId={previewId}
            goToJobTwo={goToJobtwo}
            closePreview={() => setOpenJobPreview(false)}
            callAlls={handleCall}
            callAll={handleCall}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CustomHeaderLayout;
