import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";

const HamburgerMenu = () => {
  const [expanded, setExpanded] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setExpanded(!expanded);
    setIsNavbarOpen(!isNavbarOpen);
    document.body.style.overflowY = isNavbarOpen ? 'auto' : 'hidden';
  };
  const toggleNavbars = () => {
    setExpanded(!expanded);
    setIsNavbarOpen(!isNavbarOpen);
    document.body.style.overflowY = isNavbarOpen ? 'auto' : 'hidden';
  }
  return (
    <Navbar onToggle={(expanded) => setExpanded(expanded)} expanded={expanded} expand="xl"  className='tab-menu navbar-m'>
      {isNavbarOpen ? "" : <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} />}
      <Navbar.Collapse id="basic-navbar-nav">
      <h1 className="nav-close" onClick={toggleNavbars}>X</h1>
        <Nav className="mr-auto">
              <ul className='sidebar'>
              <li className="nav-item">
                      <NavLink
                        activeClassName="active"
                        to={{
                          pathname: `/portal/interview/exploreInterview`,
                        }}
                        className="nav-link iInterviews collapsed "
                        isActive={(match, location) => {
                          const interviewPaths = [
                            "/portal/interview/exploreInterview",
                            "/portal/interview/pendingInterview",
                            "/portal/interview/bookedInterview",
                            "/portal/interview/savedInterview",
                            "/portal/other-profile",
                            "/portal/order-summary",
                          ];
                          return interviewPaths.some((path) =>
                            location.pathname.startsWith(path)
                          );
                        }}
                        // onClick={this.onNavLinkClick}
                      >
                        <span className="pro nav-weight"> Interviews</span>
                      </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  to={{
                    pathname: `/portal/jobs`,
                    // userID: this.props.userID || this.state.userID("userid"),
                  }}
                  className="nav-link iJobs collapsed "
                >
                  {/* <RxDashboard style={{ height: "17px" }} /> */}
                  <span
                    style={{ marginLeft: "8px" }}
                    className="pro nav-weight"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {" "}
                    Jobs
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  to={{
                    pathname: `/portal/application-tracking-system`,
                    
                  }}
                  className="nav-link iATS collapsed "
                >
                  {/* <RxDashboard style={{ height: "17px" }} /> */}
                  <span
                    style={{ marginLeft: "8px" }}
                    className="pro nav-weight"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {" "}
                    ATS
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  to={{
                    pathname: `/portal/my-calendar`,
                    
                  }}
                  className="nav-link iCalendar collapsed "
                >
                  {/* <RxDashboard style={{ height: "17px" }} /> */}
                  <span
                    style={{ marginLeft: "8px" }}
                    className="pro nav-weight"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {" "}
                    My Calendar
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  style={{ width: "17.5rem" }}
                  to={{
                    pathname: `/portal/organisation`,
                    
                  }}
                  className="nav-link iOrganisation collapsed "
                >
                  
                  {/* <RxDashboard style={{ height: "17px" }} /> */}
                  <span
                    style={{ marginLeft: "8px" }}
                    className="pro nav-weight"
                    onClick={() => setExpanded(!expanded)}
                  >
                    Organisation
                  </span>
                </NavLink>
              </li>
              </ul>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default HamburgerMenu;
