import React, { useEffect, useState, useRef } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions, DialogTitle } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineCloseCircle, } from "react-icons/ai";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../../images/delete.svg";
import CloseIcon from "../../images/CloseIcon.svg";
import Plus from "../../images/Normal_State_Plus.svg";
import PlusHover from "../../images/Hover_State_Plus.svg";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import { ReactComponent as BackBtn } from "../../images/backBtn.svg";
import iSettings from "../../images/icons/settings.svg";
import Common from "../../utils/common";
const MainLayout = styled.div`
  margin: 120px 50px 30px 90px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;

const HeaderLayout = styled.div`
  position: absolute;
  top: 0;
  margin-left: 5px;
  width: calc(100% - 226px);
  left: 226px;
  border: 1px;
  height: 105px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MainHeader = styled.div`
  font-weight: normal;
  //position: relative;
  display: flex;
  justify-content: flex-start;
  top: 40px;
  margin: auto;
  margin-left: 39px;
  color: #362a41;
  //font-family:
  //  Inter-Medium,
  //  sans-serif;
  font-size: 24px;
`;

const ButtonOne = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family:
    Inter-Medium,
    sans-serif;
  color: white;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  background: #fd2145;
  border: 1px solid #1213171a;
  padding: 12px 22px;
  height: 40px;
  position: relative;
  cursor: pointer;
  &:hover {
    border: 1px solid #764f90;
  }
`;
const PageWrapper = styled.div`
  background: white;
  border-radius: 24px;
  margin: 1rem 0rem;
  padding: 0 20px;
  min-height: 100px;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 14px;
  font-family:
    Inter-Medium,
    sans-serif;
  color: #4f4953;
  font-weight: normal;
  margin: 0px 0 5px 0;
`;
const PageWrapperUp = styled.div`
  background: white;
  border-radius: 10px;
  padding: 30px 0px 0 0;
  // margin: 1rem 0rem;

  // @media (max-width: 500px) {
  //   padding: 1.5rem 1.5rem 1.5rem 1rem;
  //   margin: 2rem 0;
  // }
`;
const Button3 = styled.div`
  background: #36a47c;
  border-radius: 10px;
  font-family: Inter-Medium;
  font-weight: normal;
  font-size: 15px;
  color: white;
  padding: 7px 10px;
  /* margin: 15px 0; */
  cursor: pointer;
  // &:hover {
  //   background: white;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Button5 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  font-family: Inter-Medium;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  color: #362a41;
  border: 1px solid grey;
  padding: 7px 10px;
  /* margin: 15px 0; */
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Header = styled.div`
  color: #362a41;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin-top: 15px;
`;
const DeActHead = styled.div`
  color: #362a41;
  font-family:
    Inter-Medium,
    sans-serif;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 25.6px */
  text-transform: capitalize;
  // margin-left: 2px;
  bottom: 10px;
  position: relative;
`;
const DeActContent = styled.div`
  color: #4f4953;
  font-family:
    Inter-Medium,
    sans-serif;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 25.6px */

  margin-left: 2px;
  padding: -50px;
  width: 384px;
`;
const JobTitle = styled.div`
  width: auto;
  height: 22px;
  top: 24px;
  left: 0px;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #40444f;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;
const Button4 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family:
    Inter-Medium,
    sans-serif;
  color: #2d3139;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px 22px;
  width: 94px;
  height: 40px;
  // position: relative;
  top: 3px;
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;
  // position: relative;
  top: 3px;
  font-family:
    Inter-Medium,
    sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;
  /* margin: 15px 0; */
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const ManageDomain = ({ props }) => {
  const columns = [
    { field: "domainName", headerName: "Domain Name", width: 500 },
    {
      field: "type",
      headerName: "Type",
      width: 500,
      renderCell: (params) => {
        const value = params.value;
        let displayValue = "";

        if (value === "primary") {
          displayValue = "Primary";
          return (
            <span style={{ color: "#362A41", fontweight: "normal" }}>
              {displayValue}
            </span>
          );
        } else if (value === "secondary") {
          displayValue = "Secondary";
          return <span style={{ color: "purple" }}>{displayValue}</span>;
        } else {
          return <span>{displayValue}</span>;
        }
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        if (params.row.type === "secondary") {
          return (
            <span>
              <img
                src={Delete}
                alt="img"
                onClick={() => {
                  setSelectedDomainId(params.row._id); // Set the selected domain
                  setPopupOpen3(true); // Open the confirmation dialog
                }}
              />
              {/* Assuming you have the Bootstrap Icons library included */}
            </span>
          );
        }
      },
    },
  ];
  const { id } = useParams();
  const [domainName, setDomainName] = useState("");
  const [domainprimary, setDomainprimary] = useState("");
  const [domain, setDomain] = useState();

  const [companyIds, setCompanyId] = useState();

  const domainSubmit = async() => {
    const data = {
      companyId: companyIds,
      domainName: domainName,
    };
    try{
      await axios
      .post(`${process.env.PUBLIC_URL}/xakal/add-domain/${companyIds}`, data)
      
      
        setPopupOpen(false);
        getAllDomain(id);
        setDomainName("");
      }
      catch(error){console.log("Error fetching details:",error)}
  };
  const deactivateCompany = async() => {
    const checkbox = document.getElementById("confirmationCheckbox");
    if (!checkbox.checked) {
      toast.error("Please confirm deactivation by checking the checkbox");

      return;
    }
    console.log("Before axios request");
    try{
      await axios
      .put(`${process.env.PUBLIC_URL}/xakal/deactivate-company/${selectedId}`)
        setSuccessOne(false);
        GetAllCompany();
        toast.success("Company is Successfully Deactivated");
      }
      catch(error){console.log("Error fetching details:",error)}
    console.log("After axios request");
  };
  const [success, setSuccess] = useState(false);
  const [dataValue, setDataValue] = useState();

  const ref = useRef(null);
  const [dropdown, setDropdown] = useState(false);
  function onSubmit2() {
    setSuccess(true);
  }
  const logout = () => {
    Common.logout();
  };

  const getOneDetail = async(id) => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/company/details/${id}`)
    
        console.log(response.data.data);
        setDomainprimary(response.data.data.website);
        setCompanyId(response.data.data.companyId);
        getAllDomain(id);
        setDataValue(response.data.data);
      }
      catch(error){console.log("Error fetching details:",error)}
  };
  console.log(companyIds, "companyIds");

  const getAllDomain = async(id) => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-domain/${id}`)
        // console.log(a, website);
        const a = {
          _id: -1,
          type: "primary",
          domainName: domainprimary
            .replace(/\//g, "")
            .replace("www.", "")
            .replace("https:", "")
            .replace("http:", ""),
        };
        const b = response.data.manageDomain;

        if (b !== undefined) {
          const c = [a, ...b];
          setDomain(c);
        } else {
          console.error("Response data is undefined or empty");
        }
      }
      catch(error){console.log("Error fetching details:",error)}
  };

  useEffect(() => {
    getOneDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setPopupOpen] = useState(false);
  const [open2, setPopupOpen2] = useState(false);

  const handleSubmit = () => {
    setPopupOpen(true);
  };
  const [selectedDomainId, setSelectedDomainId] = useState(null);
  const getRowId = (row) => row._id;
  const deleteDomain = async() => {
    if (selectedDomainId) {
      try{
        const response = await axios
        .delete(
          `${process.env.PUBLIC_URL}/xakal/delete-domain/${selectedDomainId}`
        )
        
          console.log(response, "delete");
          setPopupOpen3(false);
          getAllDomain(id);
          setSelectedDomainId(null);
          toast.success(response.data.message);
        }
        catch(error){console.log("Error fetching details:",error)}
    }
  };
  const [open3, setPopupOpen3] = useState(false);
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [selectedId, setSelectedId] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [company, setCompany] = useState();
  const GetAllCompany = async() => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/getusercompany`)
      
        setCompany(response.data.company);
      }
      catch(error){console.log("Error fetching details:",error)}
  };

  useEffect(() => {
    GetAllCompany();
  }, []);
  const [successOne, setSuccessOne] = useState(false);


  return (
    <>
      <ToastContainer />
      {/* <TopHeader /> */}
      <HeaderLayout>
        <MainHeader>
          {" "}
          <BackBtn
            style={{ marginRight: "24px", cursor: "pointer" }}
            onClick={() => {
              console.log("hit");
              history.push(
                `/portal/edit-companyview/${dataValue && dataValue.companyId}`
              );
            }}
          />
          {dataValue && dataValue.companyName}
        </MainHeader>
        <div className="dh-setting-container">
          <div class="dropdown" ref={ref}>
            <div
              className="dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-expanded="false"
              onClick={() => setDropdown(true)}
              style={{ margin: "30px 60px" }}
            >
              <img src={iSettings} className="" alt="" />
            </div>
            <div
              className={dropdown ? "dropdown-menu show" : "dropdown-menu"}
              style={{ margin: "0px 60px" }}
            >
              <div
                className="dropdown-item iCpass"
                onClick={() => history.push("/portal/changepassword")}
              >
                Change Password
              </div>
              <div
                className="dropdown-item iCbank"
                onClick={() => history.push("/portal/accountdetails")}
              >
                Bank Details
              </div>
              <div
                className="dropdown-item iChelp"
                onClick={() =>
                  (window.location = "mailto:support@thexakal.com")
                }
              >
                Help & Support
              </div>
              <div className="dropdown-item iLogout" onClick={onSubmit2}>
                {" "}
                Logout
              </div>
            </div>
          </div>
        </div>
      </HeaderLayout>
      {/* <MainLayout> */}
      {/* <div className="Myprofile-container" style={{ margin: "0" }        }>
          <div className="Myprofile-topcontainer">
            <div className="myp-avatar">
              {data && data.companyLogo ? (
                <>
                  <div>
                    <img
                      src={data && data.companyLogo}
                      alt="img"
                      style={{
                        width: "190px",
                        height: "190px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <img
                    src={avatar1}
                    alt="img"
                    style={{
                      width: "160px",
                      height: "160px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="myp-wrapper" style={{ display: "block" }}>
            <div
              className="company"
              onClick={() =>
                history.push(
                  `/portal/edit-companyview/${data && data.companyId}`
                )
              }
            >
              {data && data.companyName}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p style={{ marginLeft: "40px" }}>
                  {data && data.companyDescription}
                </p>
                <p style={{ marginLeft: "40px" }}>
                  {data && data.companyTagline}
                </p>
                <p style={{ marginLeft: "40px" }}>
                  {data && data.organisationType}|
                  {data &&
                    data.additionalAddress.map((x) => {
                      return (
                        <p>
                          {x.city}, {x.state}
                        </p>
                      );
                    })}
                </p>
              </div>
              <img
                src={Share}
                alt="img"
                style={{ width: "40px", height: "40px", cursor: "pointer" }}
                onClick={() => setOpenPage(!openPage)}
              />
            </div>
            {openPage ? (
              <div className="DeActWrapper">
                <div></div>
                <div
                  className="DeActPage"
                  onClick={() => handleOpens(data && data._id)}
                >
                  Deactivate Page
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="Edu-data-wrapper3 social-container">
              <div className="social-wrapper">
                  <div className="social-btn"><img src={Edit} alt="img" className="social-img"/></div>
                  <div className="social-btn" onClick={() =>history.push(`/portal/edit-companyview/${data && data.companyId}`)}>Edit Page</div>
                  </div>
              <div className="social-wrapper">
                <div className="social-btn">
                  <img src={Admin} alt="img" className="social-img" />
                </div>
                <div
                  className="social-btn"
                  onClick={() =>
                    history.push({
                      pathname: "/portal/manageAdmin",
                      state: {
                        website: data && data.website,
                        companyId: data && data.companyId,
                      },
                    })
                  }
                >
                  Manage Admin
                </div>
              </div>
              <div className="social-wrapper">
                <div className="social-btn">
                  <img src={Domain} alt="img" className="social-img" />
                </div>
                <div
                  className="social-btn"
                  onClick={() => handleSubmitTwo(data && data.companyId)}
                >
                  Manage Domains
                </div>
              </div>
              <div className="social-wrapper">
                <div className="social-btn">
                  <img src={Employee} alt="img" className="social-img" />
                </div>
                <div
                  className="social-btn"
                  onClick={() =>
                    history.push({
                      pathname: "/portal/manage-employees",
                      state: {
                        companyId: data && data.companyId,
                        website: data && data.website,
                      },
                    })
                  }
                >
                  Manage Employees
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* </MainLayout> */}
      <MainLayout>
        {/* <PageWrapperTwo> */}
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Header>Manage Domain</Header>
          <button className="button2" onClick={handleSubmit}>
            <img src={PlusHover} alt="img" className="plus-icon2" style={{ margin: "0 3px 0 0px" }} />
            <img
              src={Plus}
              alt="img"
              className="plus-icon"
              style={{ margin: "0 3px 0 0px" }}
            />
            {/* <AiOutlinePlus size={20} style={{ marginRight: "8px" }} /> */}
            Add Domain
          </button>
        </div>
        {/* </PageWrapperTwo> */}
        <PageWrapper>
          {domain && domain.length > 0 ? (
            <DataGrid
              rows={domain}
              columns={columns}
              getRowId={getRowId}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
          ) : (
            <p>Loading data...</p>
          )}
        </PageWrapper>
        <Dialog open={open} fullWidth maxWidth="sm">
          <div
            className="modalHeader"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "18px",
              fontweight: "normal",
              color: "#362A41",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            <span>Add New Domain</span>
            {/* <AiOutlineCloseCircle onClick={() => setPopupOpen(false)} /> */}
            <img
              src={CloseIcon}
              alt="img"
              style={{ cursor: "pointer" }}
              onClick={() => setPopupOpen(false)}
            />
          </div>
          <DialogContent>
            <PageWrapperUp>
              <div className="modalBody">
                <div>
                  <Label>Domain Name</Label>
                  <InputGroup>
                    <Form.Control
                      aria-label="Job Title"
                      name="name"
                      aria-describedby="basic-addon1"
                      style={{
                        borderRadius: "5px",
                        background: "rgba(54, 42, 65, 0.04)",
                        fontSize: "12px",
                        fontFamily: "Inter-Medium,sans-serif",
                        height: "48px",
                      }}
                      value={domainName}
                      placeholder="Add a new domain name"
                      onChange={(e) => setDomainName(e.target.value)}
                    />
                  </InputGroup>
                  {/* <Label>Function</Label>

              <div
                style={{
                  position: "relative",
                  display: "inline-flex",
                  gap: "10px",
                }}
              >
                <input type="checkbox" />
                <p style={{ marginTop: "16px" }}>Post Job</p>
              </div> */}
                  <p
                    style={{
                      textAlign: "center",
                      color: "#949494",
                      fontSize: "12px",
                      fontweight: "normal",
                      fontStyle: "Inter-Medium,sans-serif",
                      margin: "10px 0 40px 0",
                    }}
                  >
                    (People with this domain will be able to post job for your
                    company)
                  </p>
                </div>
              </div>
              <div
                className="modalFooter"
              // style={{
              //   position: "relative",
              //   display: "flex",
              //   justifyContent: "center",
              //   gap: "12px",
              // }}
              >
                <button
                  variant="outline"
                  className="button5"
                  onClick={() => setPopupOpen(false)}
                >
                  Cancel
                </button>
                <button onClick={domainSubmit} className="button3">
                  Save
                </button>
              </div>
            </PageWrapperUp>
          </DialogContent>
        </Dialog>
        <Dialog open={open2}>
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              fontFamily: "Inter-Medium",
            }}
          >
            <AiOutlineCloseCircle onClick={() => setPopupOpen2(false)} />
          </DialogTitle>
          <DialogContent>
            <Label>Are you Sure you want to cancel ? </Label>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <Button5
                onClick={() => setPopupOpen2(false)}
                style={{ cursor: "pointer" }}
              >
                Yes
              </Button5>
              <Button3 onClick={() => setPopupOpen2(false)}>No</Button3>
            </div>
          </DialogActions>
        </Dialog>
        {/* <Dialog open={open3}>
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              fontFamily: "Inter-Medium",
            }}
          >
            <AiOutlineCloseCircle onClick={() => setPopupOpen3(false)} />
          </DialogTitle>
          <DialogContent>
            <Label>Are you Sure you want to delete ? </Label>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <Button5 onClick={deleteDomain} style={{ cursor: "pointer" }}>
                Yes
              </Button5>
              <Button3 onClick={() => setPopupOpen3(false)}>No</Button3>
            </div>
          </DialogActions>
        </Dialog> */}
        <Dialog
          open={open3}
          PaperProps={{
            style: {
              borderRadius: "16px",
              top: "10px",
              transform: "translateY(0%)",
              position: "absolute",
              backgroundColor: "#FFDAE0",
              padding: "5px 10px",
              // height: "56px",
            },
          }}
          halfWidth
          maxWidth="md"
        // className="def"
        >
          <DialogContent
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium",
              // position: "relative",
              left: "5px",
              top: "-3px",
            }}
          >
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "8px",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                background: "white",
              }}
            >
              <img src={DeleteImg} alt="img" style={{ marginLeft: "7px" }} />
            </div>
            <JobTitle
              style={{
                fontFamily: "Inter-Medium,sans-serif",
                fontSize: "14px",
                fontweight: "normal",
                lineHeight: "37px",
                gap: "10px",
                display: "flex",
                position: "relative",
                top: "-6px",
                color: "#40444F",
              }}
            >
              Are you sure you want to delete this domain?
            </JobTitle>
            <DialogActions>
              <div
                style={{ position: "relative", display: "flex", gap: "10px" }}
              >
                <Button4
                  onClick={() => setPopupOpen3(false)}
                  style={{ cursor: "pointer" }}
                >
                  Cancel
                </Button4>
                <Button6 onClick={deleteDomain}>Yes,Delete</Button6>
              </div>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Dialog
          open={successOne}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: { borderRadius: "20px", margin: "0px", width: "auto" },
          }}
        >
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium,sans-serif",
              color: "#362A41",
              fontSize: "18px",
              fontweight: "normal",
            }}
          >
            Deactivate Page
            {/* <AiOutlineCloseCircle onClick={() => setSuccess(false)} /> */}
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => setSuccessOne(false)}
            />
          </DialogTitle>

          <DialogContent>
            <div style={{ padding: "20px" }}>
              <DeActHead>We're really sorry for your decision</DeActHead>
              <DeActContent>
                Deactivation will remove the page entirely from Xakal and your
                company admins will no longer will have the access to the page
              </DeActContent>
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "20px",
                position: "relative",
                marginBottom: "32px",
                gap: "10px",
              }}
            >
              <FormControlLabel
                label="I hereby confirm that I deactivate the Company page"
                control={
                  <Checkbox
                    id="confirmationCheckbox" />} />
            </div>
          </DialogContent>
          <DialogActions
            style={{ boxShadow: "0px -4px 16px 0px rgba(0, 0, 0, 0.08)" }}
          >
            <div
              style={{
                position: "relative",
                display: " flex",
                justifyContent: "right",
                gap: "20px",
                marginTop: "20px",
                marginRight: "16px",
              }}
            >
              <button onClick={() => setSuccessOne(false)} className="button5">
                Cancel
              </button>
              <ButtonOne onClick={() => deactivateCompany(selectedId)}>
                Deactivate
              </ButtonOne>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={success}
          fullWidth
          maxWidth="sm"
          className="dh-modal"
          PaperProps={{ style: { borderRadius: "20px" } }}
        >
          <div className="modalHeader">
            <h1 className="mTitle">Logging Out</h1>
          </div>
          <div className="modalBody">
            <p>Are you sure you want to log out?</p>
          </div>
          <div className="modalFooter">
            <button
              className="btn btn-cancel"
              onClick={() => setSuccess(false)}
            >
              No
            </button>

            <button onClick={logout} className="btn btn-logout">
              Log out
            </button>
          </div>
        </Dialog>
      </MainLayout>
    </>
  );
};

export default ManageDomain;
