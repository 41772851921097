import React, { useEffect, useState } from "react";
import {
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import styled from "styled-components";
import "../Jobs/buttonStyle.css";
import Select from "react-select";
import { BsTrash, BsPenFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import avatar1 from "../../images/company.png";
import Admin from "../../images/domains.png";
import Domain from "../../images/setting.png";
import Employee from "../../images/team-management.png";
import Share from "../../images/Share.png";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import TopHeader from "../MyProfile/TopHeader";
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;

const Description = styled.div`
color: #A8A1AC;
font-family: General Sans,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: normal;
line-height: normal;
text-transform: capitalize;
margin:20px;
display:flex;
gap:10px;
`
const Heading = styled.p`
  font-size: 16px;
  font-weight: bolder;
`;

const Profile = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 0px;
`;

const Section = styled.div`
  display: flex;
  justify-content:space-between;
  background:#FFF;
  padding:20px 20px 0 20px;
  border-radius:8px 8px 0 0;
  // grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid;
  // margin-bottom: 10px;
`;
const PageWrapper = styled.div`
  background: white;
  border-radius: 0 0 10px 10px;
  position: relative;
  top:20px;
  display: flex;
  justify-content: space-between;
  padding:0 20px 20px 20px;
  width: 100%;
  border:0px !important;
  // left: 20%;
  margin: -1.1rem 1rem 1rem 0rem;
  
  .css-kzyyzb-MuiDataGrid-root{
    border-style:none;
    color: #362A41;
  font-family: Inter-Medium,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  }
  .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel{
    margin-top:15px;
  }
  .css-levciy-MuiTablePagination-displayedRows{
    margin-top:15px;
  }
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const Button3 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Label = styled.div`
  color: #4F4953;
  font-family: Inter-Medium,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
  margin: 30px 0 10px 0;
`;
const Header = styled.div`
color: #362A41;
font-family: Inter-Medium,sans-serif;
font-size: 24px;
font-style: normal;
font-weight: normal;
line-height: normal;
margin-top:15px;
`
const InstituteDomainPage = (props) => {
  const [data, setData] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employee, setEmployee] = useState([]);
  const [openPage, setOpenPage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [companyId, setCompanyId] = useState();
  const history = useHistory();
  const [isListVisible, setListVisible] = useState(false);
  const toggleList = () => {
    setOpenPage(!openPage);
    setListVisible(!isListVisible);
  };
  const handleSubmitTwo = (comId) => {
    history.push(`/portal/institute-domain/${comId}`);
  };
  const getCurrentEmployees = async (id) => {
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/institute/employee`, { website: id })
      .then((response) => {
        console.log("employees", response?.data);
        // setData(response?.data);

        const employeeOptions = response?.data.map((employee) => {
          const user = employee.user;
          const experience = employee.experience[0]; // Assuming you're interested in the first experience entry

          return {
            value: `${user.firstName} ${user.middleName} ${user.lastName} `,
            label: `${user.firstName} ${user.middleName} ${user.lastName} `,
            instId: experience.company.comID,
            email: user.email,
            userId: user.userId,
          };
        });

        setEmployee(employeeOptions);
      })
      .catch((err) => err.error);
  };
  console.log(employee, "hfhjafshgd");
  useEffect(() => {
    const company = props.location.state;
    getCurrentEmployees(company.website);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loginId, setLoginId] = useState();
  console.log(loginId);
  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setLoginId(response.data.userId);
      })
      .catch((err) => err.error);
  };
  const [success, setSuccess] = useState(false);
  const handleOpen = () => {
    setSuccess(true);
    setUserType("");
    setSelectedEmployee("");
  };
  const [editId, setEditId] = useState(null);

  const [userType, setUserType] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedEmployee || !userType || !companyId) {
      // Display toaster message for mandatory fields
      // You can use your preferred toaster library or create your own logic to display a message
      toast.error("Please enter all mandatory details");
      return;
    }
    const formData = {
      institute: selectedEmployee,
      userType: userType,
      instituteId: companyId,
      userId: selectedEmployee.userId,
    };

    // If editId doesn't exist, create a new admin
    axios
      .post(
        `${process.env.PUBLIC_URL}/xakal/manage-institute-create-admin/${companyId}`,
        formData
      )
      .then((response) => {
        console.log(response, "create admin");
        setSuccess(false);
        setUserType("");
        setSelectedEmployee("");
        GetOneManageAdmin(companyId);
      })
      .catch((err) => {
        console.log(err.error);
      });
  };
  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    const formData = {
      institute: selectedEmployee,
      userType: userType,
      userId: selectedEmployee.userId,
    };

    // If editId exists, update the existing admin's role
    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/institute-admin-update/${editId}`,
        formData
      )
      .then((response) => {
        console.log(response, "update admin");
        setSuccessOne(false);
        setUserType("");
        setSelectedEmployee("");
        GetOneManageAdmin(props.location.state.instituteId);
      })
      .catch((err) => {
        console.log(err.error);
      });
  };
  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  const [admin, setAdminList] = useState([]);

  const [successOne, setSuccessOne] = useState(false);
  const [successTwo, setSuccessTwo] = useState(false)

  const handleOpenInstitute = (id) => {
    setSuccessTwo(true);
  };
  const GetAllInstitute = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuserinstitute`)
      .then((response) => {
        console.log(JSON.stringify(response), "company value");
      })
      .catch((err) => err.error);
  };
  const deactivateInstitute = (id) => {
    const checkbox = document.getElementById('confirmationCheckbox');
    if (!checkbox.checked) {

      toast.error("Please confirm deactivation by checking the checkbox");

      return;
    }
    console.log("Before axios request");
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/deactivate-institute/${id}`)
      .then((response) => {
        console.log(response, "deactivated");
        setSuccessOne(false);
        GetAllInstitute();
        toast.success("Company is Successfully Deactivated")
      })
      .catch((err) => {
        console.error(err); // Log any errors to the console
      });
    console.log("After axios request");
  };
  const handleEdit = (id) => {
    setSuccessOne(true);
    setEditId(id);
    getOneInstituteDomain(id);
  };
  const GetOneManageAdmin = (id) => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/institute-one-admin/${id}`
      )
      .then((response) => {
        console.log(response, "hetOneInstitute");
        setAdminList(response.data)
        const adminData = response.data; // Assuming the response contains data for the admin to edit

        // Set selectedEmployee and userType based on the adminData
        const selectedEmployeeData = {
          value: `${adminData.institute?.value}`,
          label: `${adminData.institute?.label} `,
        };
        setSelectedEmployee(selectedEmployeeData);

        // Set userType based on the adminData role
        if (adminData.role.superAdmin) {
          setUserType("superAdmin");
        } else if (adminData.role.admin) {
          setUserType("admin");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const DeleteManageDomain = (editId) => {
    axios
      .delete(
        `${process.env.PUBLIC_URL}/xakal/institute-delete-admin/${editId}`
      )
      .then((response) => {
        console.log(response, "response delete");
        GetOneManageAdmin(props.location.state.instituteId);
        toast.success("Manage Admin Deleted Successfully");
      })
      .catch((err) => err.error);
  };
  const getOneInstituteDomain = (id) => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/institute-getOne/${id}`
      )
      .then((response) => {
        console.log(response, "hetOneInstituteget");
        const adminData = response.data; // Assuming the response contains data for the admin to edit
        // Set selectedEmployee and userType based on the adminData
        const selectedEmployeeData = {
          value: `${adminData.institute.value}`,
          label: `${adminData.institute.label} `,
        };
        setSelectedEmployee(selectedEmployeeData);

        // Set userType based on the adminData role
        if (adminData.role.superAdmin) {
          setUserType("superAdmin");
        } else if (adminData.role.admin) {
          setUserType("admin");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const getOneCompanydetail = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details/${id}`)
      .then((response) => {
        console.log(response, "companyOneDetail");
        setData(response.data.data);
        // setSelectedId(response.data.data.instituteId);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    const company = props.location.state;
    console.log("comId:", company);
    getOneCompanydetail(company.instituteId);
    GetOneManageAdmin(company.instituteId);
    getUser();
    // getOneInstituteDomain(company.instituteId);

  }, [props.location.state]);
  // useEffect(() => {
  //   const company = props.location.state;
  //   // getAllCompanyAdmin(company);

  //   // setCompanyId(props.location.state);
  //   // Call GetOneManageAdmin if editId is not null
  // }, [props.location.state]);
  return (
    <>
      <ToastContainer />
      <TopHeader />
      <MainLayout>
        <div className="Myprofile-container" style={{ margin: "0" }}>
          <div className="Myprofile-topcontainer">
            <div className="myp-avatar">
              {data && data.companyLogo ? (
                <>
                  <div>
                    <img
                      src={data && data.companyLogo}
                      alt="img"
                      style={{
                        width: "190px",
                        height: "190px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <img
                    src={avatar1}
                    alt="img"
                    style={{
                      width: "160px",
                      height: "160px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="myp-wrapper" style={{ display: "block" }}>
            <div className="company" onClick={() => history.push(`/portal/editview-institute/${data && data.instituteId}`)}>{data && data.collegeName}</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Description style={{ marginLeft: "40px" }}>{data && data.companyDescription}</Description>
                <Description style={{ marginLeft: "40px" }}>{data && data.companyTagline}</Description>
                <Description style={{ marginLeft: "40px" }}>
                  <div style={{ margin: "3px 0" }}>{data && data.organisationType}</div>|
                  <div>
                    {data && data.additionalAddress.map((x) => {
                      return (
                        <div>
                          <p >
                            {x.city}, {x.state}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </Description>
              </div>
              <img src={Share} alt="img" style={{ width: "40px", height: "40px", cursor: "pointer" }} onClick={() => toggleList(data && data.companyId)} />
            </div>
            {openPage ? (
              <div className="DeActWrapper">
                <div></div>
                <div className="DeActPage" onClick={() => handleOpenInstitute(data && data._id)}>Deactivate Page</div>
              </div>
            ) : ""}
            <div className="Edu-data-wrapper3 social-container">
              {/* <div className="social-wrapper">
                  <div className="social-btn"><img src={Edit} alt="img" className="social-img"/></div>
                  <div className="social-btn" onClick={() =>history.push(`/portal/edit-company/${data && data.companyId}`)}>Edit Page</div>
                  </div> */}
              <div className="social-wrapper">
                <div className="social-btn"><img src={Admin} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => history.push({
                  pathname: "/portal/institute-admin-domain",
                  state: { website: data && data.website, instituteId: data && data.instituteId }
                })}>Manage Admin</div>

              </div>
              <div className="social-wrapper">
                <div className="social-btn"><img src={Domain} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => handleSubmitTwo(data && data.instituteId)}>Manage Domains</div>

              </div>
              <div className="social-wrapper">
                <div className="social-btn"><img src={Employee} alt="img" className="social-img" /></div>
                <div className="social-btn" onClick={() => history.push({ pathname: "/portal/institute-employees", state: { instituteId: data && data.instituteId, website: data && data.website }, })}>Manage Employees</div>

              </div>
            </div>
          </div>
        </div>
      </MainLayout>
      <MainLayout>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Header>Manage Admin</Header>
          </div>

          <div>
            <Button3 onClick={handleOpen}>Add Admin</Button3>
          </div>
        </div>
        <Section>
          <div>
            <Heading>Employee</Heading>
          </div>
          <div>
            <Heading>Role</Heading>
          </div>
          <div>
            <Heading>Actions</Heading>
          </div>
        </Section>
        {admin?.map((admin, index) => (
          <PageWrapper key={index}>
            <Profile>
              <div>
                <Label>{admin?.institute?.value}</Label>
              </div>
            </Profile>
            <div>
              {admin?.role?.superAdmin && <Label>Super Admin</Label>}
              {admin?.role?.admin && !admin?.role?.superAdmin && (
                <Label>Admin</Label>
              )}
            </div>
            {admin.role.superAdmin === true ||
              admin.role.admin === true
              ||
              loginId === admin.userId ? (
              <div>
                <>
                  <>
                    <BsTrash
                      style={{ fontSize: 24, margin: "30px 30px 0 0" }}
                      onClick={() => DeleteManageDomain(admin._id)}
                    />{" "}
                    <BsPenFill
                      style={{ fontSize: 24, marginTop: "30px" }}
                      onClick={() => handleEdit(admin._id)}
                    />
                  </>
                </>
              </div>
            ) : (
              null
            )}


          </PageWrapper>
        ))}


      </MainLayout>
      <Dialog
        open={successTwo}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <h4>Deactivate Page</h4>
        </DialogTitle>
        <DialogContent>
          <h4>We're really sorry for your decision</h4>
          <p>
            Deactivation will remove the page entirely from Xakal and your
            institute admins will no longer will have the access to the page
          </p>
          <div style={{ display: "flex", }}>
            <input type="checkbox" id="confirmationCheckbox" />
            <p style={{
              position: "relative",
              marginLeft: "15px",
              marginTop: "12px"
            }}>I hereby confirm that I deactivate the Company page</p>
          </div>
          <div style={{
            position: "relative",
            display: " flex",
            justifyContent: "right",
            gap: "20px",
          }}>
            <button onClick={() => setSuccessTwo(false)}>Cancel</button>
            <button onClick={() => deactivateInstitute(data && data.instituteId)}>
              Deactivate
            </button>
          </div>

        </DialogContent>
      </Dialog>

      <Dialog open={success} fullWidth maxWidth="sm">
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            color: "#362A41",
            fontweight: "normal",
            position: "relative",
          }}
        >Add Admin
          <AiOutlineCloseCircle
            onClick={() => setSuccess(false)}
            style={{ cursor: "pointer" }}
          />
        </DialogTitle>
        {companyId && editId ? (
          <>
            <DialogContent>
              <>
                {" "}
                <Label>Select Employee<span className="text-danger">*</span></Label>
                <div>
                  <Select
                    class="footer-header  mb-3"
                    styles={{

                      height: "40px",
                      control: (base, state) => ({
                        ...base,
                        width: "100%",
                        backgroundColor: "#F4F4F6",
                        border: "1px solid #F4F4F6",
                        borderRadius: "8px"
                      })
                    }}
                    name="employeeName"
                    placeholder="Select the employee"
                    options={employee}
                    value={selectedEmployee}
                    onChange={(selectedOption) => {
                      setSelectedEmployee(selectedOption);
                      // Set the selected company ID when an option is selected
                    }}
                  />
                </div>
                <Label>User Type<span className="text-danger">*</span></Label>
                <div>
                  <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
                    <input
                      type="checkbox"
                      id="superAdmin"
                      name="userType"
                      value="superAdmin"
                      checked={userType === "superAdmin"}
                      onChange={handleUserTypeChange}
                    />
                    <label for="superAdmin" style={{ color: "#362A41", fontSize: "14px", fontweight: "normal", margin: "0px 0 0 10px" }}>Super Admin</label>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
                    <input
                      type="checkbox"
                      id="admin"
                      name="userType"
                      value="admin"
                      checked={userType === "admin"}
                      onChange={handleUserTypeChange}
                    />
                    <label for="admin" style={{ color: "#362A41", fontSize: "14px", fontweight: "normal", margin: "0px 0 0 10px" }}>Admin</label>
                  </div>
                </div>
              </>
            </DialogContent>
            <DialogActions>
              <>
                <Button3 onClick={handleSubmitUpdate}>Update</Button3>
              </>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <>
                {" "}
                <Label>Select Employee<span className="text-danger">*</span></Label>
                <div>
                  <Select
                    class="footer-header  mb-3"
                    styles={{

                      height: "40px",
                      control: (base, state) => ({
                        ...base,
                        width: "100%",
                        backgroundColor: "#F4F4F6",
                        border: "1px solid #F4F4F6",
                        borderRadius: "8px"
                      })
                    }}
                    name="employeeName"
                    placeholder="Select the employee"
                    options={employee}
                    value={selectedEmployee}
                    onChange={(selectedOption) => {
                      setSelectedEmployee(selectedOption);
                      // Set the selected company ID when an option is selected
                    }}
                  />
                </div>
                <Label>User Type<span className="text-danger">*</span></Label>
                <div>
                  <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
                    <input
                      type="checkbox"
                      id="superAdmin"
                      name="userType"
                      value="superAdmin"
                      checked={userType === "superAdmin"}
                      onChange={handleUserTypeChange}
                    />
                    <label for="superAdmin" style={{ color: "#362A41", fontSize: "14px", fontweight: "normal", margin: "0px 0 0 10px" }}>Super Admin</label>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
                    <input
                      type="checkbox"
                      id="admin"
                      name="userType"
                      value="admin"
                      checked={userType === "admin"}
                      onChange={handleUserTypeChange}
                    />
                    <label for="admin" style={{ color: "#362A41", fontSize: "14px", fontweight: "normal", margin: "0px 0 0 10px" }}>Admin</label>
                  </div>
                </div>
              </>
            </DialogContent>
            <DialogActions>
              <>
                <Button3 onClick={handleSubmit}>Submit</Button3>
              </>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog open={successOne} fullWidth maxWidth="sm">
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            color: "#362A41",
            fontweight: "normal",
            position: "relative",
          }}
        >Edit Admin
          <AiOutlineCloseCircle
            onClick={() => setSuccessOne(false)}
            style={{ cursor: "pointer" }}
          />
        </DialogTitle>
        {editId && (
          <>
            <DialogContent>
              <>
                {" "}
                <Label>Select Employee<span className="text-danger">*</span></Label>
                <div>
                  <Select
                    class="footer-header  mb-3"
                    styles={{

                      height: "40px",
                      control: (base, state) => ({
                        ...base,
                        width: "100%",
                        backgroundColor: "#F4F4F6",
                        border: "1px solid #F4F4F6",
                        borderRadius: "8px"
                      })
                    }}
                    name="employeeName"
                    placeholder="Select the employee"
                    options={employee}
                    value={selectedEmployee}
                    onChange={(selectedOption) => {
                      setSelectedEmployee(selectedOption);
                      // Set the selected company ID when an option is selected
                    }}
                  />
                </div>
                <Label>User Type<span className="text-danger">*</span></Label>
                <div>
                  <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
                    <input
                      type="checkbox"
                      id="superAdmin"
                      name="userType"
                      value="superAdmin"
                      checked={userType === "superAdmin"}
                      onChange={handleUserTypeChange}
                    />
                    <label for="superAdmin" style={{ color: "#362A41", fontSize: "14px", fontweight: "normal", margin: "0px 0 0 10px" }}>Super Admin</label>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
                    <input
                      type="checkbox"
                      id="admin"
                      name="userType"
                      value="admin"
                      checked={userType === "admin"}
                      onChange={handleUserTypeChange}
                    />
                    <label for="admin" style={{ color: "#362A41", fontSize: "14px", fontweight: "normal", margin: "0px 0 0 10px" }}>Admin</label>
                  </div>
                </div>
              </>
            </DialogContent>
            <DialogActions>
              <>
                <Button3 onClick={handleSubmitUpdate}>Update</Button3>
              </>
            </DialogActions>
          </>
        )

        }
      </Dialog>
    </>
  );
};

export default InstituteDomainPage;
