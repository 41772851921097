import React, { useEffect, useState } from "react";
import Common from "../../utils/common";
import axios from "axios";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DesktopHeader from "../desktopHeader";
import imageAvatar from "../../images/company.png";
const InvitationCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  height: 120px;
  display: flex;
  gap: 24px;
  margin-bottom: 16px; /* Adjust the value as needed */
`;

const Img = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: auto 0;
`;

const Title = styled.div`
  width: auto;
  height: 22px;
  top: 24px;
  left: 111px;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8653b6;
`;

const DetailsWrapper = styled.div`
  padding: 8px 0;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: auto;
`;

const PendingRequests = () => {
  const [invitations, setInvitations] = useState([]);
  const history = useHistory();
  const getMyInvitations = async () => {
    await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      )
      .then((res) => {
        console.log("my invitations", res?.data);
        const dt = res?.data?.filter((e) => e.connectionStatus === "PENDING");
        setInvitations(dt);
      });
  };
  console.log("invitations", invitations);

  const updateInvitation = async (status, id) => {
    return await axios
      .post(`${process.env.PUBLIC_URL}/xakal/update/invitation`, {
        invitedUserId: id,
        associateId: Common.loggedIn(),
        connectionStatus: status,
      })
      .then((res) => {
        console.log("update Invitation", res?.data);
        getMyInvitations();
      });
  };
  useEffect(() => {
    getMyInvitations();
  }, []);

  const BackToCompany = () => {
    history.goBack();
  };
  return (
    <div>
      <DesktopHeader
        title={"My Requests"}
        isSearchActive={false}
        backbtn
        back={BackToCompany}
      />
      <div
        style={{
          margin: "2rem",
          display: "grid",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        {invitations?.map((invitation, idx) => (
          <InvitationCard key={idx}>
            <Img
              src={
                invitation?.details?.avatar
                  ? invitation?.details?.avatar
                  : imageAvatar
              }
              alt=""
            />

            <DetailsWrapper>
              <Title>
                {invitation?.details?.firstName +
                  " " +
                  invitation?.details?.lastName}
              </Title>
              {invitation.experience.length > 0 && (
                <>
                  {invitation.experience
                    .filter((exp) => !exp.endDate) // Filter experiences where end date is not given
                    .map((exp, index) => (
                      <React.Fragment key={index}>
                        <p>{exp.designation}</p>
                        <p>
                          {exp.company.value ? exp.company.value : exp.company}
                        </p>
                      </React.Fragment>
                    ))}
                </>
              )}
            </DetailsWrapper>
            <ButtonWrapper>
              <button
                className="button2"
                onClick={() =>
                  updateInvitation("ASSOCIATE", invitation?.appUserId)
                }
              >
                Accept Request
              </button>
              <button
                className="button2"
                onClick={() =>
                  updateInvitation("REJECTED", invitation?.appUserId)
                }
              >
                Decline Request
              </button>
            </ButtonWrapper>
          </InvitationCard>
        ))}
      </div>
    </div>
  );
};

export default PendingRequests;
