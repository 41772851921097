import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import DesktopHeader from "../desktopHeader";
import { BsPenFill, BsTrashFill } from "react-icons/bs";
import axios from "axios";
import "./promo.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Select from "react-select";
import CloseIcon from "../../images/CloseIcon.svg";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import EditPromo from "./editPromo";
import * as XLSX from "xlsx";

const Promo = () => {
  const [promoList, setPromoList] = useState([]);
  const [codeName, setCodeName] = useState("");
  const [percentage, setPercentage] = useState("");
  const [userArray, setUserArray] = useState([]);
  const [userSelect, setUserSelect] = useState([]);
  const [openPromo, setOpenPromo] = useState(false);
  const [openUpdatePromo, setOpenUpdatePromo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promoId, setPromoId] = useState(null);
  const getAllPromoCode = async() => {
    try{
      const res = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-promo`)
      
        setPromoList(res.data.promo);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    getAllPromoCode();
  }, []);
  const columns = [
    {
      field: "codeName",
      headerName: "Promo Name",
      width: 400,
    },
    {
      field: "percentage",
      headerName: "Percentage",
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <div className="action-buttons">
          <button
            className="edit-button"
            onClick={() => {
              setOpenUpdatePromo(true);
              setPromoId(params.row._id);
            }}
          >
            <BsPenFill size={15} />
          </button>
          <button
            className="delete-button"
            onClick={() => deletePromo(params.row._id)}
          >
            <BsTrashFill size={15} />
          </button>
        </div>
      ),
    },
  ];
  const handleOpenPromo = () => {
    setOpenPromo(true);
    setCodeName("");
    setPercentage("");
  };

  const createPromo = async() => {
    debugger;
    if (!codeName || !percentage) {
      toast.error("Code Name and Percentage are mandatory fields");
      return;
    }
    const userIds = userSelect.map((user) => user.value);
    const userIdSelect = userSelect.map((user) => ({
      value: user.value,
      label: user.label,
    }));
    const payload = {
      codeName: codeName,
      percentage: percentage,
      applicableUser: userIds,
      userDetail: userIdSelect,
    };
    setLoading(true);
    try{
      await axios
      .post(`${process.env.PUBLIC_URL}/xakal/create-promo`, payload)
        setCodeName("");
        setPercentage("");
        setUserArray([""]);
        getAllPromoCode();
        setOpenPromo(false);
        toast.success("Promo code added successfully");
        setLoading(false);
      }
      catch {
        setLoading(false);
        toast.error("Failed to create promo code");
      };
  };
  const deletePromo = async(id) => {
    try{
      // eslint-disable-next-line no-unused-vars
      const res= await axios
      .delete(`${process.env.PUBLIC_URL}/xakal/delete-promo/${id}`)
      
        toast.error("Promo code deleted successfully");
        getAllPromoCode();
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };

  useEffect(() => {
    const fetchData = async() => {
      try{
        const res = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-user-list`)
      
        const userData = res.data.user.map((user) => ({
          value: user.userId,
          label: `${user.firstName} ${user.lastName}`,
        }));
        setUserArray(userData);
      }
      catch(err)  {console.log("Error fetching details:",err)};
    }
    
    fetchData();
  }, []);
  const generatePromoCode = (length) => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }
    return result;
  };

  const generatePromoCodesArray = (count, length) => {
    let promoCodes = [];
    for (let i = 0; i < count; i++) {
      const promoCode = generatePromoCode(length);
      promoCodes.push({ name: promoCode });
    }
    return promoCodes;
  };

  const promoCodes = generatePromoCodesArray(1550, 10);
  console.log(JSON.stringify(promoCodes));
  const handleFileUpload = (event) => {
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = (e) => {
        const dataString = e.target.result;
        const parsedData = parseExcel(dataString); // Function to parse Excel

        sendToServer(parsedData); // Function to send parsed data to server
      };

      reader.readAsBinaryString(file);
    }
  };

  const parseExcel = (dataString) => {
    const workbook = XLSX.read(dataString, { type: "binary" });
    const sheetNames = workbook.SheetNames;
    let parsedData = [];

    sheetNames.forEach((sheetName) => {
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headerRow = excelData.shift();
      const fieldNames = headerRow.map((fieldName) => fieldName.trim());

      excelData.forEach((row) => {
        const rowData = {};
        fieldNames.forEach((fieldName, index) => {
          rowData[fieldName] = row[index];
        });
        parsedData.push(rowData);
      });
    });

    return parsedData;
  };

  const sendToServer = async(data) => {
    try{
      await axios
      .post(`${process.env.PUBLIC_URL}/xakal/create-promo`, data)
      
        getAllPromoCode(); // Function to update promo list after successful import
        toast.success("Promo codes Added successfully");
      }
      catch(error) {
        console.error("Error importing promo codes:", error);
        toast.error("Failed to import promo codes");
      };
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const fileInputRef = useRef(null); // Ref for file input element
  return (
    <div>
      <ToastContainer />
      <div className="header-layouts"><DesktopHeader title={"Promo"} /></div>

      <div className="promo-list">
        <div className="promo-btn-main">
          <button onClick={handleOpenPromo} className="promo-btn">
            Create Promo
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileUpload}
            multiple
          />
          <button onClick={handleButtonClick} className="asses-btn">
            Import Promo
          </button>
        </div>

        <DataGrid
          sx={{
            height: "50vh",
            borderRadius: "10px",
            color: "#49454F",
            fontSize: "14px",
          }}
          md={{ color: "#49454F", fontSize: "14px" }}
          getRowId={(row) => row._id}
          rows={promoList}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50]}
        />
      </div>
      <Dialog
        open={openPromo}
        PaperProps={{
          style: {
            borderRadius: "24px",
            padding: "20px",
            maxWidth: "500px",
            height: "auto",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className="modalHeader"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontweight: "normal",
            fontSize: "18px",
            color: "#362A41",
            position: "relative",
            padding: "20px",
          }}
        >
          Create Promo
          <img src={CloseIcon} alt="img" onClick={() => setOpenPromo(false)} />
        </DialogTitle>
        <DialogContent>
          <div className="modalBody" style={{ padding: "20px 0px" }}>
            <div>
              <label className="label-name">
                Code Name<span>*</span>
              </label>
              <InputGroup className="footer-header">
                <Form.Control
                  type="text"
                  name="codeName"
                  onChange={(e) => setCodeName(e.target.value)}
                  value={codeName}
                  aria-describedby="basic-addon1"
                  style={{
                    width: "50%",
                    fontSize: "12px",
                  }}
                  className="footer-header"
                />
              </InputGroup>
            </div>
            <div>
              <label className="label-name">
                percentage<span>*</span>
              </label>
              <InputGroup className="footer-header">
                <Form.Control
                  type="text"
                  name="percentage"
                  onChange={(e) => setPercentage(e.target.value)}
                  value={percentage}
                  aria-describedby="basic-addon1"
                  style={{
                    width: "50%",
                    fontSize: "12px",
                  }}
                  className="footer-header"
                />
              </InputGroup>
            </div>
            <label className="label-name">User</label>
            <div className="user-input-container">
              <Select
                options={userArray}
                isMulti
                isClearable
                onChange={(selectedOptions) => setUserSelect(selectedOptions)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button className="button5" onClick={() => setOpenPromo(false)}>
            Cancel
          </button>

          <button className="button3" onClick={createPromo}>
            {loading ? <Spinner /> : "Create"}
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openUpdatePromo}
        PaperProps={{
          style: {
            borderRadius: "24px",
            padding: "20px",
            maxWidth: "500px",
            height: "auto",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className="modalHeader"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontweight: "normal",
            fontSize: "18px",
            color: "#362A41",
            position: "relative",
            padding: "20px",
          }}
        >
          {"Update Promo"}
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setOpenUpdatePromo(false)}
          />
        </DialogTitle>
        <DialogContent>
          <EditPromo
            promoId={promoId}
            getAll={getAllPromoCode}
            close={() => setOpenUpdatePromo(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Promo;
