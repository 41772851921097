import React from "react";
import { FaPhotoVideo } from "react-icons/fa";
import "./textMedia.css"
const PhotoFormMedia = ({
  handleToggle,
  setShortDesc,
  longDesc,
  setLongDesc,
  images,
  fileInputRef,
  handleDivClick,
  handleImageChange,
  removeImage,
  shortDesc
}) => {
  return (
    <>
      <div className="optionsContainer">
        <div
          className="option"
          onClick={handleDivClick}
          style={{
            cursor: "pointer",
            padding: "10px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
          }}
        >
          <FaPhotoVideo size={20} />
          Photo +
        </div>
        <input
          type="file"
          accept="image/*"
          multiple
          ref={fileInputRef}
          style={{ display: "none" }} // Hide the input
          onChange={handleImageChange}
        />
      </div>
      {images.length > 0 && (
        <div>
          <h2>Preview:</h2>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {images.map((image, index) => (
              <div key={index} style={{ position: "relative", margin: "5px" }}>
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Preview ${index}`}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "150px",
                    borderRadius: "4px",
                  }}
                />
                <button
                  onClick={() => removeImage(index)}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    background: "rgba(255, 255, 255, 0.7)",
                    border: "none",
                    borderRadius: "50%",
                    cursor: "pointer",
                    padding: "2px",
                  }}
                >
                  ✖️
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="textContent">
        Short Description
        <textarea
          value={shortDesc}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setShortDesc(e.target.value)}
          rows={3}
        />
        <div className="character">{shortDesc?.length}/300 Characters</div>
      </div>
      <div className="textContent">
        Long Description
        <textarea
          value={longDesc}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setLongDesc(e.target.value)}
          rows={3}
        />
        <div className="character">{longDesc?.length}/3000 Characters</div>
      </div>
      <div className="buttonDiv">
        <button className="button2" onClick={handleToggle}>
          Next
        </button>
      </div>
    </>
  );
};

export default PhotoFormMedia;
