import React, { useEffect, useState } from "react";
import Common from "../../utils/common";
import axios from "axios";
import styled from "styled-components";
import imageAvatar from "../../images/company.png";
const InvitationCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  height: 120px;
  display: flex;
  gap: 24px;
  margin-bottom: 16px; /* Adjust the value as needed */
`;

const Img = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: auto 0;
`;

const Title = styled.div`
  width: auto;
  height: 22px;
  top: 24px;
  left: 111px;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8653b6;
`;

const DetailsWrapper = styled.div`
  padding: 8px 0;
`;

const InvitationCards = styled.div`
  display: flex;
  gap: 24px;
  margin: 15px auto;
`;

const NetworkNotification = () => {
  const [invitations, setInvitations] = useState([]);
  const [request, setRequests] = useState([]);

  const getMyInvitations = async () => {
    await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      )
      .then((res) => {
        const dt = res?.data?.filter((e) => e.connectionStatus === "PENDING");
        setInvitations(dt);
      });
  };

  const updateInvitation = async (status, id) => {
    return await axios
      .post(`${process.env.PUBLIC_URL}/xakal/update/invitation`, {
        invitedUserId: id,
        associateId: Common.loggedIn(),
        connectionStatus: status,
      })
      .then((res) => {
        getMyInvitations();
      });
  };
  useEffect(() => {
    getMyInvitations();
  }, []);

  
  const getMyRequests = async () => {
    await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      )
      .then((res) => {
        console.log("my invitations", res?.data);
        const dt = res?.data?.filter((e) => e.connectionStatus === "ASSOCIATE");
        setRequests(dt);
      });
  };
  useEffect(() => {
    getMyRequests();
  }, []);
  return (
    <div>
      <div
        style={{
          marginTop: "5rem",
          display: "grid",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        {invitations?.map((invitation, idx) => (
          <InvitationCard key={idx}>
            <Img
              src={
                invitation?.details?.avatar
                  ? invitation?.details?.avatar
                  : imageAvatar
              }
              alt=""
            />
            <DetailsWrapper>
              <Title>
                {invitation?.details?.firstName +
                  " " +
                  invitation?.details?.lastName}
              </Title>
              <InvitationCards>
                <button
                  className="button3"
                  onClick={() =>
                    updateInvitation("ASSOCIATE", invitation?.appUserId)
                  }
                >
                  Accept
                </button>
                <button
                  className="button5"
                  onClick={() =>
                    updateInvitation("REJECTED", invitation?.appUserId)
                  }
                >
                  Decline
                </button>
              </InvitationCards>
              <p></p>
            </DetailsWrapper>
          </InvitationCard>
        ))}
      </div>

      {request?.map((i) => {
        return (
          <div className="">
            <div className="notificationInterview">
              <div className="interviewNotificationCard">
                <img
                  src={i.details.avatar ? i.details.avatar : imageAvatar}
                  alt="imageAvatar"
                  className="notificationImage"
                />
                <div className="all-noti-cont">{i.details?.firstName}<span> is now associate with you</span> </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NetworkNotification;
