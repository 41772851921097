import { useEffect, useRef, useState } from "react";
import "./message.css";
import companyLogo from "../../images/profile-image.svg";
import axios from "axios";
import { FaRegFilePdf, FaFileExcel, FaFilePowerpoint } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import InputGroup from "react-bootstrap/InputGroup";
import { LuSendHorizonal } from "react-icons/lu";
import { IoIosAttach } from "react-icons/io";
import { io } from "socket.io-client";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { GoSmiley } from "react-icons/go";
import { BiTrash } from "react-icons/bi";
import { FaReply } from "react-icons/fa";
import ImagePreviewModal from "./imagePreviewModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Dialog } from "@mui/material";

const MessageChat = ({
  userId,
  messageChats,
  id,
  onCallMessage,
  otherProfile,
}) => {
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [mainMessage, setMainMessage] = useState([]);
  const [mainArrival, setMainArrival] = useState([]);
  const [text, setText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedEmojis, setSelectedEmojis] = useState([]);
  const [isVisibleOpen, setIsVisibleOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [filePreview, setFilePreview] = useState("");
  const [openImage, setOpenImage] = useState(false);
  const [imageToOpen, setImageToOpen] = useState(null);
  const fileImage = [".pdf", ".doc", ".docx", ".ppt", ".xls", ".xlsx", ".pptx"];
  const fileText = [".png", ".jpg", ".jpeg", ".gif"];
  const socket = useRef();
  const textareaRef = useRef(null);
  const history = useHistory();

  const handleMouseEnter = (messageId) => {
    setHoveredMessageId(messageId);
  };

  const handleMouseLeave = () => {
    setHoveredMessageId(null);
  };

  const getAllMessage = async (messageChats) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/message/${messageChats}`
      );
      setMainMessage(res.data.message);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    if (messageChats) {
      getAllMessage(messageChats?._id);
    }
  }, [messageChats, messageChats?._id]);

  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");
    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };
      calculateTimeAgo();
    }, [timestamp]);

    return <span className="mes-time">{timeAgo}</span>;
  };
  const [user, setUsers] = useState({});
  const friendIds = Array.from(new Set(mainMessage?.map((m) => m.sender)));
  const hasFetchedData = useRef(false);

  const getUserChat = async (friendIds) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-user-multiple`,
        { ids: friendIds }
      );
      const usersData = response.data.aboutDetails;
      const usersMap = usersData.reduce(
        (acc, user) => ({ ...acc, [user.userId]: user }),
        {}
      );
      setUsers(usersMap);
      hasFetchedData.current = true;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!hasFetchedData.current && friendIds.length > 0) {
      getUserChat(friendIds);
    }
  }, [friendIds]);

  useEffect(() => {
    socket.current = io(process.env.PUBLIC_URL);
    // socket.current = io("wss://test-staging.thexakal.com");

    socket.current.on("getMessage", (data) => {
      const timestamp = { createdAt: new Date() };
      setMainArrival({
        sender: data.senderId,
        text: data.text,
        image: data?.image,
        timestamps: timestamp,
      });
    });
    socket.current.on("deleteMessage", (deletedMessageId) => {
      setMainMessage((prevMessages) =>
        prevMessages.filter((message) => message._id !== deletedMessageId)
      );
    });
    socket.current.emit("addUser", userId);
    socket.current.on("getUsers", (users) => {});
    return () => {
      socket.current.disconnect();
    };
  }, [userId]);

  useEffect(() => {
    if (mainArrival && messageChats?.members?.includes(mainArrival.sender)) {
      setMainMessage((prev) => [...prev, mainArrival]);
    }
  }, [mainArrival, messageChats]);

  const handleReply = (message) => {
    setSelectedMessage(message);
    const replyText = `Reply to: ${message.text}\n`;
    setText(replyText);
    if (textareaRef.current) {
      textareaRef.current.focus();
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(textareaRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleEmojiSelect = (emoji) => {
    const selectedEmoji = emoji.native;
    const cursorPosition = textareaRef.current.selectionStart;
    const value = text || "";
    const newValue =
      value.substring(0, cursorPosition) +
      selectedEmoji +
      value.substring(cursorPosition);
    setText(newValue);
  };

  const sendMessage = async () => {
    if (!text.trim()) {
      return;
    }
    const messageWithEmojis =
      text + (selectedEmojis.length > 0 ? ` ${selectedEmojis.join(" ")}` : "");
    let completeMessage = messageWithEmojis;

    if (selectedMessage) {
      const replyToMessage = `${selectedMessage.text}`;
      completeMessage = `${replyToMessage}\n${messageWithEmojis}`;
    }

    const payload = {
      conversationId: messageChats?._id,
      sender: userId,
      text: completeMessage,
    };
    const receiverId = messageChats?.members?.find((m) => m !== userId);

    const sendPayload = (fileUrl = "") => {
      const completePayload = {
        ...payload,
        text: payload.text ? payload.text : "",
        image: fileUrl,
      };
      socket.current?.emit("sendMessage", {
        senderId: userId,
        receiverId,
        text: completePayload.text,
        image: completePayload.image,
      });

      axios
        .post(`${process.env.PUBLIC_URL}/xakal/message`, completePayload)
        .then((res) => {
          setMainMessage((prevMessages) => [...prevMessages, res.data.message]);
          setText("");
          setSelectedFile(null);
          setIsVisibleOpen(false);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);

      axios
        .post(`${process.env.PUBLIC_URL}/xakal/files/`, formData)
        .then((res) => {
          const fileUrl = res.data.url;
          sendPayload(fileUrl);
        })
        .catch((err) => {
          console.error(err);
          sendPayload();
        });
    } else {
      sendPayload();
    }
  };

  const handleSendMessage = () => {
    if (text.trim()) {
      sendMessage();
      setSelectedEmojis([]);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFilePreview(URL.createObjectURL(file));
  };

  const handleIconClick = () => {
    document.getElementById("fileInput").click();
  };

  const deleteMessage = async (id) => {
    try {
      await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/delete-message/${id}`
      );
      socket.current.emit("deleteMessage", id);
      getAllMessage(messageChats?._id);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const handleOpenImage = (image) => {
    setImageToOpen(image);
    setOpenImage(true);
  };

  const handleDownloadFile = (fileUrl) => {
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileUrl;
    anchor.target = "_blank";
    anchor.click();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && text.trim() !== "") {
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
      setText((prevText) => prevText + "\n");
    }
  };
  const defaultMessage = "Hi there! 👋 How’s it going? Looking forward to connecting with you.";

  const handleOnChange = (e) => {
    const inputValue = e.target.value;
    const maxCharactersPerLine = 50;
    const lines = inputValue.split("\n");
    const truncatedLines = lines.map((line) => {
      if (line.length > maxCharactersPerLine) {
        const chunks = [];
        while (line.length > 0) {
          chunks.push(line.slice(0, maxCharactersPerLine));
          line = line.slice(maxCharactersPerLine);
        }
        return chunks.join("\n");
      } else {
        return line;
      }
    });
    const newText = truncatedLines.join("\n");

    setText(newText);
  };
  useEffect(() => {
    if (Array.isArray(mainMessage) && mainMessage.length === 0) {
      setText(defaultMessage);
    } else {
      setText("");
    }
  }, [mainMessage, defaultMessage]);
  return (
    <>
      <div className="main-chat-message modalBody">
        {mainMessage?.map((c) => {
          const isOwnMessage = c?.sender === userId;
          const messageUser = user[c?.sender];
          const isImage =
            c?.image &&
            fileText.some((ext) => c?.image.toLowerCase().endsWith(ext));
          const isDocument =
            c?.image &&
            fileImage.some((ext) => c?.image.toLowerCase().endsWith(ext));
          const text = c?.text;
          return (
            <div
              className={isOwnMessage ? "message own" : "message"}
              key={c._id}
            >
              <div
                className=
                {`
                  "messageTop" 
                  ${
                  hoveredMessageId === c._id ? "messageTop" : ""
                }`}
                onMouseEnter={() => handleMouseEnter(c._id)}
                onMouseLeave={handleMouseLeave}
              >
                
                {isOwnMessage ? (
                  <>
                  {hoveredMessageId === c._id && (
                  <div className="mes-rep">
                    <div className="messageBottom">
                      <TimeAgo timestamp={c?.timestamps?.createdAt} />
                    </div>
                    <span onClick={() => deleteMessage(c?._id)}>
                      <BiTrash size={20} />
                    </span>
                    <span onClick={() => handleReply(c)}>
                      <FaReply size={20} />
                    </span>
                  </div>
                )}
                  {text === "undefined" ? null : (
                    <span className="messageText">{text}</span>
                  )}
                  <img
                    className="messageImg"
                    src={
                      messageUser?.avatar ? messageUser?.avatar : companyLogo
                    }
                    alt=""
                    onClick={() => history.push(`/portal/my-profile`)}
                  />
                  </>
                ) : (
                  <>
                  <img
                    className="messageImgs"
                    src={
                      messageUser?.avatar ? messageUser?.avatar : companyLogo
                    }
                    alt=""
                    onClick={() =>
                      history.push(`/portal/otherProfile/${c?.sender}`)
                    }
                  />
                  {text === "undefined" ? null : (
                    <span className="messageText">{text}</span>
                  )}
                  {hoveredMessageId === c._id && (
                  <div className="mes-rep">
                    <span onClick={() => deleteMessage(c?._id)}>
                      <BiTrash size={20} />
                    </span>
                    <span onClick={() => handleReply(c)}>
                      <FaReply size={20} />
                    </span>
                    <div className="messageBottom">
                      <TimeAgo timestamp={c?.timestamps?.createdAt} />
                    </div>
                  </div>
                )}
                  </>
                )}

                
                
                {isImage && (
                  <img
                    src={c?.image}
                    alt="attached"
                    className="img-width-message messageText"
                    onClick={() => handleOpenImage(c?.image)}
                  />
                )}
                {isDocument && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDownloadFile(c?.image)}
                  >
                    {c?.image.toLowerCase().endsWith(".pdf") ? (
                      <FaRegFilePdf size={30} />
                    ) : c?.image.toLowerCase().endsWith(".xls") ||
                      c?.image.toLowerCase().endsWith(".xlsx") ? (
                      <FaFileExcel size={30} />
                    ) : c?.image.toLowerCase().endsWith(".ppt") ||
                      c?.image.toLowerCase().endsWith(".pptx") ? (
                      <FaFilePowerpoint size={30} />
                    ) : (
                      <IoDocumentTextOutline size={30} />
                    )}
                  </div>
                )}
              </div>
              
            </div>
          );
        })}
      </div>
      <>
            <div className="selectedInfo">
              <p>{selectedEmojis}</p>
              {selectedFile && (
                <>
                  {selectedFile.type.startsWith("image/") && ( // Check if the file is an image
                    <img
                      src={filePreview}
                      alt="Selected File"
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  )}
                  {!selectedFile.type.startsWith("image/") && ( // Display file name if not an image
                    <>
                    <InputGroup.Text className="file-name">{selectedFile.name}
                    <div className="att-close" onClick={()=>setSelectedFile("")}>x</div>
                    </InputGroup.Text>
                    </>
                  )}
                </>
              )}
            </div>
        <div className="chatBoxBottom">
          <InputGroup className="footer-header">
            <GoSmiley
              size={20}
              className="chat-smile"
              onClick={() => setIsVisibleOpen(!isVisibleOpen)}
            />

            <textarea
              placeholder={"Please Enter the Message"}
              ref={textareaRef}
              className="footer-header chat-input font"
              aria-label="First name"
              aria-describedby="basic-addon1"
              type="text"
              rows={10}
              value={text}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
            ></textarea>
            
            <InputGroup.Text
              onClick={handleIconClick}
              style={{ cursor: "pointer" }}
            >
              <IoIosAttach size={20} />
            </InputGroup.Text>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </InputGroup>
          <button className="chatSubmitButton" onClick={handleSendMessage}>
            <LuSendHorizonal size={20} />
          </button>
        </div>
        <Dialog open={isVisibleOpen}>
        <div className={isVisibleOpen ? "emoji-wrap" : "d-none"}>
          <div className="emoji-close" onClick={()=>setIsVisibleOpen(false)}>X</div>
          <Picker
            data={data}
            previewPosition="none"
            onEmojiSelect={handleEmojiSelect}
          />
        </div>
        </Dialog>
      </>
      {/* <ImagePreviewModal
        isOpen={openImage}
        onClose={() => setOpenImage(false)}
        imageSrc={imageToOpen}
        title="Image Preview"
      /> */}
    </>
  );
};

export default MessageChat;
