import React from "react";
import styled from "styled-components";
import "../Jobs/buttonStyle.css";

import { DataGrid } from "@mui/x-data-grid";
import { BsEye } from "react-icons/bs";

import { ToastContainer } from "react-toastify";
import imagesOne from "../../images/company.png"


const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const Header = styled.div`
color: #362A41;
font-family: Inter-Medium,sans-serif;
font-size: 24px;
font-style: normal;
font-weight: normal;
line-height: normal;
margin-top:15px;
`
const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 50%;
  left: 20%;
  margin: 1rem 1rem 1rem 3rem;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const PageWrapperTwo = styled.div`
  background: white;
  border-radius: 10px;
  position: relative;
  height: auto;
  padding: 15px;
  width: 50%;
  left: 20%;
  margin: 1rem 1rem 1rem 3rem;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 40px; // Adjust the size as needed
  height: 40px; // Adjust the size as needed
  border-radius: 50%;
  margin-right: 10px; // Adjust the spacing as needed
`;
const ManageEmployee = () => {
  const employee = [
    {
      id: "1",
      imageSrc: imagesOne,
      profile: "Mohammed Fuzail Rayyan",
      dateofJo: "2 years",
    },
    {
      id: "2",
      imageSrc: imagesOne,
      profile: "Mohammed Fuzail Rayyan",
      dateofJo: "2 years",
    },
    {
      id: "3",
      imageSrc: imagesOne,
      profile: "Mohammed Fuzail Rayyan",
      dateofJo: "2 years",
    },
  ];
  const columns = [
    {
      field: "profile",
      headerName: "Profile",
      width: 300,
      renderCell: (params) => (
        <ImageContainer>
          <ProfileImage src={params.row.imageSrc} alt={params.row.profile} />
          {params.row.profile}
        </ImageContainer>
      ),
    },
    {
      field: "dateofJo",
      headerName: "Date of Joining",
      width: 200,
    },

    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <span>
          <BsEye size={20} />
          {/* Assuming you have the Bootstrap Icons library included */}
        </span>
      ),
    },
  ];

  return (
    <>
      <ToastContainer />

      <MainLayout>
        <PageWrapperTwo>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Header>Manage Domain</Header>
          </div>
          <div>
            <input
              color="#fff"
              placeholder={"Search Employee"}
              className="inputTaster"
            />
          </div>
        </PageWrapperTwo>
        <PageWrapper>
          <DataGrid
            rows={employee}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </PageWrapper>
      </MainLayout>
    </>
  );
};

export default ManageEmployee;
