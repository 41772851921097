import React, { useEffect, useState } from "react";
import DesktopHeader from "../desktopHeader";
import "./company.css";
import styled from "styled-components";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import avatar1 from "../../images/company.png";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Country, State } from "country-state-city";

import AboutCompanyTab from "./aboutCompanyTab";
import PostedJob from "./postedJob";
import { ViewEmployeeList } from "../employees/viewEmployeeList";
const MainLayout = styled.div`
  margin: 40px 100px 10px 120px;
  overflow-x: hidden;
  // padding-bottom: 30px;
`;




const Description = styled.div`
  color: #4F4953;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  // text-transform: capitalize;
  margin-left: 220px;
  margin-top: 5px;
  display: flex;
  gap: 10px;
`;
const Descriptions = styled.div`
  color: #A8A1AC;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-left: 220px;
  margin-top: 5px;
  display: flex;
  gap: 10px;
`;
const OtherCompaniesView = () => {
  const [data, setData] = useState();
  const [dataTrue, setDataTrue] = useState(false);
  const history = useHistory();
  const [datas, setDatas] = useState();
  const [userDetail, setUserDetail] = useState();

  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("overview"); // Initial tab is 'manage'
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const getOneCompanydetail = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/company/details/${id}`
      );

      setData(response.data.data);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const getcn = (cn) => {
    if (cn && cn.length === 2) {
      let a = Country.getCountryByCode(cn);
      if (a && a.name) {
        return a.name;
      }
    } else {
      return cn;
    }
  };
  const getst = (cn, st) => {
    if (cn && st && cn.length === 2 && st.length === 2) {
      let a = State.getStateByCodeAndCountry(st, cn);
      if (a && a.name) {
        return a.name;
      }
    } else {
      return cn;
    }
  };
  const getOneCompanydetailCompany = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-company/${id}`
      );

      setDatas(response.data.data);
      const one = response.data.userDetail;
      setUserDetail(one);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    getOneCompanydetail();
    getOneCompanydetailCompany();
    setDataTrue(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const backPage = () => {
    history.goBack();
  };
  return (
    <div>
      <div className="header-layouts">
      <DesktopHeader title={"Company"} backbtn back={backPage}         isSearchActive={false}/>
      </div>
      <div style={{ marginTop: "0px" }}>
        <MainLayout className="edit-company-pro-wrapper">
          <div
            className="Myprofile-container"
            style={{ margin: "0", overflowY: "hidden" }}
          >
            <div className="Myprofile-topcontainer" style={{ height: "175px" }}>
              <div className="myp-avatar" style={{ top: "100px" }}>
                {data && data.companyLogo ? (
                  <>
                    <div>
                      <img
                        src={data && data.companyLogo}
                        alt="img"
                        style={{
                          width: "160px",
                          height: "160px",
                          borderRadius: "50%",
                          margin: "12px",
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <img
                      src={avatar1}
                      alt="img"
                      style={{
                        width: "160px",
                        height: "160px",
                        borderRadius: "50%",
                        margin: "12px",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              className="myp-wrapper"
              style={{
                padding: "20px 0",
                height:"fit-content"
              }}
            >
              <div className="company" style={{ marginLeft: "220px" }}>
                {data && data.companyName}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Description>{data && data.companyTagline}</Description>

                  <div>
                    {data &&
                      data.additionalAddress.map((x) => {
                        return (
                          <Descriptions>
                            {x.city}, {x.state}, {x.country}
                          </Descriptions>
                        );
                      })}
                  </div>
                  <Descriptions>
                    {data && data.primaryAddress ? (
                      <>
                        {`${data && data.primaryAddress?.city},
                    ${getst(
                      data && data.primaryAddress?.country,
                      data && data.primaryAddress?.state
                    )},${getcn(data && data.primaryAddress?.country)}
                    `}
                      </>
                    ) : (
                      ""
                    )}
                  </Descriptions>
                </div>
              </div>
            </div>
            <div className="myp-wrapper companyTab">
              <div
                className={`company-nav-link ${
                  activeTab === "overview" ? "active" : ""
                }`}
                onClick={() => handleTabChange("overview")}
              >
                Overview
              </div>
              <div
                onClick={() => handleTabChange("job-posted")}
                className={`company-nav-link ${
                  activeTab === "job-posted" ? "active" : ""
                }`}
              >
                Posted Job
              </div>
              <div
                onClick={() => handleTabChange("people")}
                className={`company-nav-link ${
                  activeTab === "people" ? "active" : ""
                }`}
              >
                People
              </div>
            </div>
          </div>
        </MainLayout>
        {activeTab === "overview" ? (
          <AboutCompanyTab data={data} />
        ) : activeTab === "job-posted" ? (
          <PostedJob datas={datas} userDetail={userDetail} />
        ) : activeTab === "people" ? (
          <ViewEmployeeList data={data} dataTrue={dataTrue} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default OtherCompaniesView;
