/* eslint-disable no-unused-vars */
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./aptitude.css";
import axios from "axios";
import QuestionImg from "../../images/solar_question-square-bold-duotone.svg";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import styled from "styled-components";
const JobTitle = styled.div``;
const Button3 = styled.div`
  background: #36a47c;
  border-radius: 10px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Button5 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px 22px;
  width: 94px;
  height: 43px;
  position: relative;
  // top: 3px;
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Question = ({
  curQuestion,
  skipQNo,
  handleUpdateAnswer,
  answer,
  index,
  bookId,
  calendar,
  book,
  isLastQuestion,
  opens,
  saved,
  candidate,
  candidateId,
  handlePage,
  userAnswer,
}) => {
  const answerOption = ["a", "b", "c", "d"];
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const navigate = useHistory();
  const [open, setOpen] = useState();
  const [openNew, setOpenNew] = useState(false);
  const [scoreId, setScoreId] = useState();
  const [attemptQuestion, setTotalAnswerAttempt] = useState();
  const [time, setTime] = useState();

  useEffect(() => {
    setSelectedIndex(answerOption.indexOf(answer));
    setSelectedOption(answer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, curQuestion.qno]);

  const changeSelect = (e) => {
    const _index = e.target.value;
    const selectedOption = answerOption[_index];

    setSelectedIndex(_index);
    setSelectedOption(selectedOption);
    handleUpdateAnswer(selectedOption);
  };

  // const handleAnswerSubmit = () => {
  //   handleUpdateAnswer(selectedOption);
  //   setSelectedOption("");
  //   setSelectedIndex(-1);
  // };
  // const handleAnswerSubmits = () => {
  //   handleUpdateAnswer2(selectedOption);
  //   setSelectedOption("");
  //   setSelectedIndex(-1);
  // };
  const getResult = () => {
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/get-result/${bookId}`)
      .then((res) => {
        navigate.push({
          pathname: "/portal/result",
          state: {
            bookId: bookId,
            calendar: calendar,
            book: book,
            saved: saved,
          },
        });
      })
      .catch(() => {});
  };
  const getResults = async() => {
    try{
      const res = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/get-result-general/${candidateId}`)
      
        const id = res.data.score._id;
        setScoreId(id);
        // setTotalAnswerAttempt(res.data.attemptedQuestions);
        const marks = res.data.score.marks;
        navigate.push({
          pathname: "/portal/aptitude",
          state: {
            candidate: candidate,
            marks: marks,
          },
        });
      }
      catch(error){console.log("Error fetching details:",error)};
  };
  const openTrue = () => {
    setOpenNew(true);
  };
  const openAnswer = () => {
    if (candidate) {
      openTrue();
      // getResults();
    } else {
      setOpen(true);
    }
  };

  
  const handleFinal = () => {
   
    setTimeout(() => {
      if (candidate) {
        getResults();
      }
    }, 1000);
  };
  const isFirstQuestion = index === 1;
  const totalQuestions = 20; // Example total number of questions

 
  return (
    <div align="left">
      <div className="main-button-submit">
       
        <button className="sub-btn" onClick={openAnswer}>
          Submit Assessment
        </button>
       
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            className="asses-ques"
            component="div"
            align="left"
            gutterBottom
            px={5}
          >
            Question No:- {index}
          </Typography>
          <Typography
            className="asses-quess"
            component="div"
            align="left"
            gutterBottom
            px={5}
            py={2}
          >
            {curQuestion.questionName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ padding: 5 }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="radio-buttons-group"
              value={selectedIndex}
              onChange={changeSelect}
              className="asses-quess"
            >
              {[
                { option: "optionA" },
                { option: "optionB" },
                { option: "optionC" },
                { option: "optionD" },
              ].map((item, index) => {
                const { option } = item;

                const optionLabel = curQuestion[option];

                return (
                  <>
                    {optionLabel && (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={<Radio />}
                        label={optionLabel}
                        onChange={() => changeSelect}
                      />
                    )}
                  </>
                );
              })}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{ paddingX: 5, paddingBottom: 5 }}
          >
            <Grid item md={4} xs={12}>
              <Grid container spacing={2}>
                {index > 1 ? (
                  <Grid item md={6} xs={12}>
                    <button
                      className="button5"
                      fullWidth
                      onClick={() => handlePage(null, index - 1)}
                    >
                      Previous
                    </button>
                  </Grid>
                ) : (
                  <Grid item md={6} xs={12}>
                    <button
                      className="button5"
                      fullWidth
                      disabled={isFirstQuestion}
                      style={{ opacity: isFirstQuestion ? 0.5 : 1 }}
                    >
                      Previous
                    </button>
                  </Grid>
                )}
                {isLastQuestion ? (
                  <Grid item md={6} xs={12}>
                    <button
                      className="button3"
                      fullWidth
                      disabled={isLastQuestion}
                      style={{ opacity: isLastQuestion ? 0.5 : 1 }}
                    >
                      Next
                    </button>
                  </Grid>
                ) : (
                  <Grid item md={6} xs={12}>
                    <button
                      className="button3"
                      fullWidth
                      onClick={() => handlePage(null, index + 1)}
                    >
                      Next
                    </button>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/* <Grid item md={2} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <button onClick={skipQNo} className="button5" fullWidth>
                    Skip
                  </button>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        PaperProps={{
          style: {
            borderRadius: "20px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",

            overflow: "hidden",
            padding: "5px 10px",
            backgroundColor: "#F4F4F6",
          },
        }}
        halfWidth
        maxWidth="md"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <div className="d-flex  align-items-center" style={{ gap: " 8px" }}>
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                gap: " 8px",
                background: "white",
              }}
            >
              <img
                src={QuestionImg}
                style={{ marginTop: "2px", marginLeft: "9px" }}
                alt="img"
              />
            </div>
            <JobTitle
              style={{
                fontFamily: "Inter-Medium,sans-serif",
                fontSize: "14px",
                fontweight: "normal",
                gap: "10px",
                display: "flex",
                color: "black",

                top: "-5px",
              }}
            >
              Are you sure you want to submit the assessment?
            </JobTitle>
          </div>

          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                gap: "10px",
                margin: "0 0px 0 20px",
              }}
            >
              <Button5
                onClick={() => setOpen(false)}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Button5>
              <Button3 onClick={handleFinal}>Yes</Button3>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog open={openNew}>
        <div className="modalHeader sub-apt">Submit Aptitude</div>
        <div className="modalBody">
          <p>
            Do you want to submit the current attempts? You will not be able to
            resume this attempt once it's submitted.
          </p>
          <div className="mTitle">Quiz Summary</div>
          <div className="mTitle">
            {userAnswer}/20 <span>Attempted</span>
          </div>
          <div className="mTitle">
            <div style={{ flexGrow: 1 }}>
              {/* Add your thin progress bar here */}
              <div
                style={{
                  height: "4px", // Adjust height as needed
                  backgroundColor: "#ccc", // Adjust background color as needed
                  width: "100%", // Bar takes full width
                  borderRadius: "2px", // Rounded corners
                  marginRight: "8px", // Spacing between bar and label
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: `${(userAnswer / 20) * 100}%`, // Calculate width based on attemptQuestion
                    backgroundColor: "#4caf50",
                    borderRadius: "2px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modalFooter">
          <button className="button5" onClick={() => setOpenNew(false)}>
            Cancel
          </button>
          <button className="button3" onClick={handleFinal}>
            Submit
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default Question;
