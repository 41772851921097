import React, { useState, useEffect } from 'react';
import './toaster.css';

const ToasterMessage = ({ message, duration }) => {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  return (
    <div className={`toaster-message ${showMessage ? 'show' : ''}`}>
      {message}
    </div>
  );
};

export default ToasterMessage;