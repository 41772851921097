import React, {  useState } from "react";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import axios from "axios";

const EngageHead = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  margin-bottom: 5px;
  span {
    display: block;
  }
`;
const Reject = ({ data, closePopup, close }) => {
  const [reason, setReason] = useState();
  const handleReject = async() => {
    const payload = {
      userId: data.userId,
      jobId: data.jobId,
      jobTitle: data.jobTitle,
      jobTitles: data.jobTitles,
      reason: reason,
    };

    try{
      await axios
      .post(`${process.env.PUBLIC_URL}/xakal/add/reject`, payload)
        try{
          await axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/apply-reject/${data.jobId}/${data.applicantId}`,
            { jobTitles: data.jobTitles }
          )
            toast.success("Application Rejected Successfully");
            closePopup();
          }
          catch(error){
            console.error("Reject error", error);
            toast.error("Error rejecting application");
          };
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  const options = [
    {
      label: "Limited relevant experience",
      value: "Limited relevant experience",
    },
    {
      label: "Missing qualifications/skills",
      value: "Missing qualifications/skills",
    },
    {
      label: "Poor interview/assessment performance",
      value: "Poor interview/assessment performance",
    },
    {
      label: "Scheduling/location conflicts",
      value: "Scheduling/location conflicts",
    },
    {
      label: "Insufficient role-specific skills",
      value: "Insufficient role-specific skills",
    },
    { label: "Other reasons", value: "Other reasons" },
  ];

  return (
    <div>
      <ToastContainer />
      <div className="modalBody">
        <EngageHead>Why is candidate being rejected?</EngageHead>
        <div>
          <Select options={options} onChange={(e) => setReason(e.value)} />
        </div>
      </div>
      <div className="modalFooter">
        <button className="button5" onClick={close}>No</button>
        <button className="button3" onClick={handleReject}>
          Yes
        </button>
      </div>
    </div>
  );
};

export default Reject;
