import React, { useState } from "react";
import "./company.css";
import styled from "styled-components";
import WebsiteImg from "../../images/Users Group Two Rounded.png";
import Building from "../../images/Buildings 2.svg";
import ColName from "../../images/Buildings.png";
import Email from "../../images/AboutLanding/Letter.svg";
import Phone from "../../images/Phone Rounded.png";
import { Country, State } from "country-state-city";

const Website = styled.div`
  color: #4f4953;
  font-family: var(--pf-medium);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
  display: flex;
  gap: 10px;
  margin:16px 0;
`;
const WebsiteContent = styled.div`
  margin-top: 5px;
  font-size: 12px;
  font-weight: 900;
  color:#4F4953;
  text-transform: capitalize;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px 0;
`;
const Wrapperss = styled.div`
  gap: 20px;
  margin: 10px 0;
`;
const Wrappers = styled.div`
  background: #f4f4f6;
  border-radius: 8px;
  padding: 10px 20px;
`;
const AboutCompanyTab = ({ data }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const getcn = (cn) => {
    if (cn && cn.length === 2) {
      let a = Country.getCountryByCode(cn);
      if (a && a.name) {
        return a.name;
      }
    } else {
      return cn;
    }
  };
  const getst = (cn, st) => {
    if (cn && st && cn.length === 2 && st.length === 2) {
      let a = State.getStateByCodeAndCountry(st, cn);
      if (a && a.name) {
        return a.name;
      }
    } else {
      return cn;
    }
  };
  
  return (
    <div className="container-company">
      <div className="section-company about-company">
        <h3 className="other-org-head">About</h3>
        <div className="other-org-comp-name">{data && data.companyName}</div>
        <div className="other-org-web">{data && data.website}</div>
        <Wrapper>
          
            <img src={ColName} alt="img" />
            <WebsiteContent>{data && data.industry}</WebsiteContent>
          
          
            <img src={WebsiteImg} alt="img" />
            <WebsiteContent>{data && data.organisationSize}</WebsiteContent>
          
          
            <img src={Building} alt="img" />
            <WebsiteContent>{data && data.organisationType}</WebsiteContent>
          
        </Wrapper>
        <Wrappers>
          <Website>
            {data && data.companyDescription ? (
              <>
                {showFullDescription
                  ? data.companyDescription
                  : `${data.companyDescription.substring(0, 250)}`}
                <span
                  onClick={toggleDescription}
                  style={{ color: "#6262F5", cursor: "pointer" }}
                >
                  {showFullDescription ? " Read less..." : "Read more..."}
                </span>
              </>
            ) : (
              ""
            )}
          </Website>
        </Wrappers>
      </div>
      <div className="section-company contact-company">
        <h2 className="other-org-head">Contact Details</h2>
        <Wrapperss>
          <Website>
            <img src={Phone} alt="img" />
            <WebsiteContent>
              {data && data.contactNumber ? (
                <>{data && `+${data.contactNumber}`}</>
              ) : (
                "-"
              )}
            </WebsiteContent>
          </Website>
          <Website>
            <img src={Email} alt="img" />
            <WebsiteContent>{data && data.contactEmailId}</WebsiteContent>
          </Website>
        
        </Wrapperss>
        {/* <Label>Location</Label> */}
        <Wrappers>
          <Website>
            {data && data?.primaryAddress ? (
              <>
                {`${data && data.primaryAddress.addressOne} ${
                  data && data.primaryAddress.addressTwo
                } ${data && data.primaryAddress.city},
                    ${getst(
                      data && data.primaryAddress.country,
                      data && data.primaryAddress.state
                    )},${getcn(data && data.primaryAddress.country)}
                    `}
              </>
            ) : (
              "-"
            )}
          </Website>
        </Wrappers>
      </div>
    </div>
  );
};

export default AboutCompanyTab;
