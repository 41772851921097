import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
} from "react-router-dom/cjs/react-router-dom.min";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select from "react-select";
import "../Jobs/buttonStyle.css";
import PhoneInput from "react-phone-input-2";
import { Col, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Country, State, City } from "country-state-city";
import Plus from "../../images/Add Circle.svg";

const MainLayout = styled.div`
  //   margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  // padding-bottom: 30px;
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  // padding: 30px;
  margin: 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 20px;
  color: #362a41;
  font-weight: normal;
  margin: 30px 0 10px 0;
`;


const options5 = [
  { value: "Remote", label: "Remote" },
  { value: "Hybrid", label: "Hybrid" },
  { value: "Onsite", label: "Onsite" },
];

const PreferenceWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin: 20px 0;
`;
const JobPreference = styled.div`
  color: white;
  background: #764f90;
  padding: 10px;
  border-radius: 5px;
`;

const AboutContactModal = ({ id, onClose }) => {
  // eslint-disable-next-line no-unused-vars
  const [input, setInput] = useState({
    company: "",
    xakalAddress: "",
    website: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({
    company: "",
    xakalAddress: "",
    website: "",
  });

  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the Name of the Company!";
          }
          console.log(input.jobTitle);
          break;

        default:
          break;
      }
      return stateObj;
    });
    // handleInputBlur()
  };

  const [inputList, setInputList] = useState([
    {
      addressOne: "",
      addressTwo: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
      setTrueLocation: false,
    },
  ]);
  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const list = [...inputList];
  //   list[index][name] = value;
  //   setInputList(list);
  //   console.log(inputList);
  // };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    if (name === "country") list[index]["country"] = value;
    else if (name === "state") list[index]["state"] = value;
    else if (name === "city") list[index]["city"] = value;
    setInputList(list);
    console.log(inputList);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList,
    {
      addressOne: "",
      addressTwo: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
      setTrueLocation: false,
    },
    ]);
  };
  const [skill, setSkill] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [numberVerified, setNumberVerified] = useState(false);
  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  const handleRemoveSkill = (valueToRemove) => {
    const updatedValues = skill.filter((value) => value !== valueToRemove);
    setSkill(updatedValues);
  };
  const handleSkillChnage = (e) => {
    setSkill(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const [data, setData] = useState("");
  const [otp, setOtp] = useState(null);
  const getOneCompanydetail = async() => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/company/details/${id}`)
        console.log(response, "companyOneDetail");
        setData(response.data.data);
        if (
          response.data.data.workMode &&
          response.data.data.workMode.length > 0
        ) {
          setSkill(response.data.data.workMode);
        }
        if (
          response.data.data.additionalAddress &&
          response.data.data.additionalAddress.length > 0
        ) {
          setInputList(response.data.data.additionalAddress);
        }
        console.log(response.data.data.additionalAddress, "response.data.data.additionalAddress")
        setEmail(response.data.data.contactEmailId);
        const rawPhoneNumber = response.data.data.contactNumber;
        const formattedPhoneNumber = `+${rawPhoneNumber}`; // Format it as E.164
        // setMobileNumber(rawPhoneNumber);
        setPhone(formattedPhoneNumber);
        setNumberVerified(response.data.data.contactNumberVerified === true);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  console.log(inputList, "inputList")
  useEffect(() => {
    getOneCompanydetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sendMobileOtp = async () => {
    const payload = {
      mobile: phone,
      website: data["website"],
    };
    try{
      const response = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/company/mobile/sendOtp`, payload)
      
        console.log(response);
        toast.success("OTP sent to Mobile", {
          autoClose: 5000,
          position: "top-right",
        });
      }
      catch(err) {
        toast.error("Failed to send OTP", {
          autoClose: 5000,
          position: "top-right",
        });
      };
  };

  const verifyMobileOtp = async () => {
    const payload = {
      mobile: phone,
      website: data["website"],
      otp: +otp,
    };
    try{
      const response = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/company/mobile/verify`, payload)
      
        console.log(response);
        if (!response.data.success) {
          toast.error(response.data.error, {
            autoClose: 5000,
            position: "top-right",
          });
        } else {
          toast.success("Mobile Verified", {
            autoClose: 5000,
            position: "top-right",
          });
        }
        getOneCompanydetail();
      }
      catch(err) {
        toast.error("Failed to Verify", {
          autoClose: 5000,
          position: "top-right",
        });
      };
  };
  const handleSumit = async () => {
    const numericPhoneNumber = phone.replace(/\D/g, '');
    const payload = {
      website: data["website"],
      primaryAddress: inputList.find((e) => e?.setTrueLocation),
      additionalAddress: inputList,
      workMode: skill,
      contactEmailId: email,
      contactNumber: numericPhoneNumber,
    };
    try{
      const response = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/create/company`, payload)
    
        console.log(response);
        toast.success("Company Updated Successfully", {
          autoClose: 5000,
          position: "top-right",
        });
        onClose();
      }
      catch(err) {
        toast.error("error updating company", {
          autoClose: 5000,
          position: "top-right",
        });
        console.log(err.error);
      };
  };
  const setPrimaryLocation = (e, index) => {
    const list = [...inputList];
    list[index]["setTrueLocation"] = !list[index]["setTrueLocation"];
    setInputList(list);
    console.log(inputList);
  };
  return (
    <div>
      <MainLayout>
        <ToastContainer />
        <PageWrapper>
          <div className="modalBody">
            <div style={{ display: "flex", justifyContent: "center" }}>

              <div>
                <Label>Mobile Number</Label>
                <div style={{ display: "flex", gap: "10px", backgroundColor: "#F4F4F6", height: "38px" }}>
                  <div>
                    <PhoneInput
                      country={"us"}
                      value={phone}
                      onChange={handlePhoneChange}
                      inputProps={{
                        required: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {numberVerified ? (
              <>
                <p style={{ textAlign: "center" }}>Mobile Number Verified</p>
              </>
            ) : (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0"
                  }}
                >
                  <button className="button2" onClick={() => sendMobileOtp()}>
                    Send Otp
                  </button>
                </div>


                <div style={{ display: "flex", justifyContent: "center" }}>
                  <input
                    type="number"
                    name="otp"
                    onChange={(e) => setOtp(e.target.value)}
                    style={{
                      borderRadius: "5px",
                      border: "1px solid black",
                      background: "rgba(54, 42, 65, 0.04)",
                      width: "40%",
                      height: "40px",
                      padding: "20px",
                    }}
                    placeholder="Enter OTP sent to your Mobile No"
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
                  <button className="button2" onClick={() => verifyMobileOtp()}>
                    Verify
                  </button>
                </div>

              </>
            )}

            <Label>Contact Mail Id</Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Job Title"
                name="contactEmailId"
                aria-describedby="basic-addon1"
                style={{
                  borderRadius: "5px",
                  background: "rgba(54, 42, 65, 0.04)",
                  fontSize: "12px",
                  height: "48px"
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>

            <Label>Location</Label>

            {inputList.map((input, i) => {
              return (

                <div key={i}>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div style={{ width: "50%" }}>
                      <Label>Address Line 1</Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Enter Address Line 1"
                          aria-label="Job Title"
                          name="addressOne"
                          value={input.addressOne}
                          aria-describedby="basic-addon1"
                          style={{
                            borderRadius: "5px",
                            background: "rgba(54, 42, 65, 0.04)",
                            fontSize: "12px",
                            height: "48px"
                          }}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </InputGroup>
                    </div>
                    <div style={{ width: "50%" }}>
                      <Label>Address Line 2</Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Enter Address Line 2"
                          aria-label="Job Title"
                          name="addressTwo"
                          value={input.addressTwo}
                          aria-describedby="basic-addon1"
                          style={{
                            borderRadius: "5px",
                            background: "rgba(54, 42, 65, 0.04)",
                            fontSize: "12px",
                            height: "48px"
                          }}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <Row>
                    <Col>
                      <Form.Group>
                        <div>
                          <Label>Country</Label>
                          <select
                            required
                            value={input.country}
                            onChange={(e) => handleInputChange(e, i)}
                            name="country"
                            className="footer-header mb-3"
                            style={{
                              border: "none", fontSize: "12px",
                              height: "48px"
                            }}
                          >
                            <option value="">Country</option>
                            {Country &&
                              Country.getAllCountries().map((item) => (
                                <option key={item.name} value={item.isoCode}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Label>State / Provision</Label>
                        <select
                          required
                          value={input.state}
                          onChange={(e) => handleInputChange(e, i)}
                          name="state"
                          className="footer-header mb-3"
                          style={{
                            fontSize: "12px",
                            height: "48px", border: "none"
                          }}
                        >
                          <option value="">State</option>
                          {State &&
                            input.country &&
                            State.getStatesOfCountry(input.country).map(
                              (item) => (
                                <option key={item.name} value={item.isoCode}>
                                  {item.name}
                                </option>
                              )
                            )}
                        </select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Label>City / Town</Label>
                        <select
                          required
                          value={input.city}
                          onChange={(e) => handleInputChange(e, i)}
                          name="city"
                          className="footer-header mb-3"
                          style={{
                            border: "none", fontSize: "12px",
                            height: "48px",
                          }}
                          onBlur={validateInput}
                        >
                          <option value="">City</option>
                          {City &&
                            input.country &&
                            input.state &&
                            City.getCitiesOfState(input.country, input.state).map(
                              (item) => (
                                <option value={item.name}>{item.name}</option>
                              )
                            )}
                        </select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Label>Zip / Pincode</Label>
                        <Form.Control
                          placeholder="Enter Zip / Pincode"
                          aria-label="Job Title"
                          name="pincode"
                          value={input.pincode}
                          aria-describedby="basic-addon1"
                          style={{
                            borderRadius: "5px",
                            background: "rgba(54, 42, 65, 0.04)",
                            fontSize: "12px",
                            height: "48px"
                          }}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <FormControlLabel
                          label="Set this as the primary location"
                          control={
                            <Checkbox
                              inputProps={{ 'aria-label': 'controlled' }}
                              onChange={(e) => setPrimaryLocation(e, i)}
                              checked={input.setTrueLocation} />} />
                      </div>
                    </Col>
                    {inputList.length - 1 === i && (
                      // <Col>
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                          color: "#362A41",
                          border: "1px solid rgb(198, 202, 210, 1)",
                          borderRadius: "8px",
                          padding: "5px 20px",
                          height: "40px",
                          marginRight: "1rem"
                        }}
                        onClick={() => handleAddClick(i)}
                      >
                        <img src={Plus} alt="img" />
                        <p style={{ marginTop: "2px" }}>Add Address</p>
                      </div>
                      // </Col>
                    )}
                    {inputList.length !== 1 && (
                      <button
                        className="mr10 Add-language"
                        onClick={() => handleRemoveClick(i)}
                      >
                        Remove
                      </button>
                    )}
                  </Row>
                </div>
              );
            })}
            <Label>Work Mode</Label>
            <div>
              <Select
                styles={{

                  height: "40px",
                  control: (base, state) => ({
                    ...base,
                    width: "100%",
                    backgroundColor: "#F4F4F6",
                    border: "1px solid #F4F4F6",
                    borderRadius: "8px",
                    fontSize: "12px",
                    height: "48px"
                  })
                }}
                menuPlacement="top"
                onChange={handleSkillChnage}
                name="workMode"
                value={options5.filter((obj) => skill.includes(obj.value))}
                options={options5}
                isMulti={true}
                className="footer-header "
                onBlur={validateInput}
              />
              {skill.length > 0 && (
                <PreferenceWrapper>
                  {skill.map((i, a) => {
                    return (
                      <div key={a}>
                        <JobPreference>
                          {i}
                          <button onClick={() => handleRemoveSkill(i)}>x</button>
                        </JobPreference>
                      </div>
                    );
                  })}
                </PreferenceWrapper>
              )}
            </div>
          </div>
          <div className="modalFooter">
            {/* <Button3 type="submit" disabled={!enable} style={{opacity: enable ? "100%" : "50%"}}>  */}
            <button className="button2" onClick={handleSumit}>
              Save
            </button>
            {/* </Button3> */}
          </div>
        </PageWrapper>
      </MainLayout>
    </div>
  );
};

export default AboutContactModal;
