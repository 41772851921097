import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { AiOutlinePlusCircle } from "react-icons/ai";
import "../Jobs/buttonStyle.css";
import PhoneInput from "react-phone-input-2";
import { Col, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Country, State, City } from "country-state-city";
const MainLayout = styled.div`
  //   margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 20px;
  color: #362a41;
  font-weight: normal;
  margin: 30px 0 10px 0;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const AboutInstitutePopupModal = ({ id, onClose }) => {
  const [btnEnable, setBtnEnable] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({
    company: "",
    xakalAddress: "",
    website: "",
  });

  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the Name of the Company!";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
    // handleInputBlur()
  };

  const [inputList, setInputList] = useState([
    {
      addressOne: "",
      addressTwo: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
      setTrueLocation: false,
    },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    if (name === "country") list[index]["country"] = value;
    else if (name === "state") list[index]["state"] = value;
    else if (name === "city") list[index]["city"] = value;
    setInputList(list);
    console.log(inputList);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList,
    {
      addressOne: "",
      addressTwo: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
      setTrueLocation: false,
    },
    ]);
  };
  const [skill, setSkill] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [otpsecenable, setOtpSecEnable] = useState(false);
  const [numberVerified, setNumberVerified] = useState(false);
  const handlePhoneChange = (value) => {
    setPhone(value);

  };
  const [error2, setError2] = useState("");
  const handlePhone = (e) => {

    //     }
    const inputPhone = e.target.value.replace(/\D/g, '');
    if (inputPhone.length < 12) {
      console.log(phone);
      setPhone(inputPhone);
      setBtnEnable(false);
      setError2("Please Enter Valid Mobile Number");
    }
    else {
      setError2("");
      setBtnEnable(true);
    }
  }

  const [data, setData] = useState("");
  const [otp, setOtp] = useState(null);
  const getOneCompanydetail = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details/${id}`)
      .then((response) => {
        console.log(response, "companyOneDetail");
        setData(response.data.data);
        if (
          response.data.data.workMode &&
          response.data.data.workMode.length > 0
        ) {
          setSkill(response.data.data.workMode);
        }
        if (
          response.data.data.additionalAddress &&
          response.data.data.additionalAddress.length > 0
        ) {
          setInputList(response.data.data.additionalAddress);
        }
        setEmail(response.data.data.contactEmailId);
        const rawPhoneNumber = response.data.data.contactNumber;
        const formattedPhoneNumber = `+${rawPhoneNumber}`; // Format it as E.164
        setPhone(formattedPhoneNumber);
        setNumberVerified(response.data.data.contactNumberVerified === true);
      })
      .catch((err) => err.error);
  };
  console.log(inputList, "inputList")
  useEffect(() => {
    getOneCompanydetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sendMobileOtp = async () => {
    setOtpSecEnable(true);
    const payload = {
      mobile: phone,
      website: data["website"],
    };
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/institute/mobile/sendOtp`, payload)
      .then((response) => {
        console.log(response);
        toast.success("OTP sent to Mobile", {
          autoClose: 5000,
          position: "top-right",
        });
        setNumberVerified(false);
      })
      .catch((err) => {
        toast.error("Failed to send OTP", {
          autoClose: 5000,
          position: "top-right",
        });
      });
  };

  const verifyMobileOtp = async () => {
    const payload = {
      mobile: phone,
      website: data["website"],
      otp: +otp,
    };
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/institute/mobile/verify`, payload)
      .then((response) => {
        console.log(response);
        if (!response.data.success) {
          toast.error(response.data.error, {
            autoClose: 5000,
            position: "top-right",
          });
        } else {
          toast.success("Mobile Verified", {
            autoClose: 5000,
            position: "top-right",
          });
        }
        getOneCompanydetail();
      })
      .catch((err) => {
        toast.error("Failed to Verify", {
          autoClose: 5000,
          position: "top-right",
        });
      });
  };
  const handleSumit = async () => {
    const payload = {
      contactEmailId: email,
      contactNumber: phone,
      website: data["website"],
      primaryAddress: inputList.find((e) => e?.setTrueLocation),
      additionalAddress: inputList.filter((e) => !e.setTrueLocation),
      workMode: skill,
    };
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/create/institute`, payload)
      .then((response) => {
        console.log(response);
        toast.success("Company Edited  Successfully", {
          autoClose: 5000,
          position: "top-right",
        });
        onClose();
      })
      .catch((err) => {
        toast.error("error updating company", {
          autoClose: 5000,
          position: "top-right",
        });
        console.log(err.error);
      });
  };
  const setPrimaryLocation = (e, index) => {
    const list = [...inputList];
    list[index]["setTrueLocation"] = !list[index]["setTrueLocation"];
    setInputList(list);
    console.log(inputList);
  };
  return (
    <div>
      <MainLayout>
        <ToastContainer />
        <PageWrapper>
          <div style={{ display: "flex", justifyContent: "center" }}>

            <div>
              <Label>Mobile Number</Label>
              <div style={{ display: "flex", gap: "10px", backgroundColor: "#F4F4F6" }}>
                <div>
                  <PhoneInput
                    onBlur={handlePhone}
                    value={phone}
                    onChange={handlePhoneChange}
                    initialCountry={'in'}
                    pattern={/^\d{5}-\d{5}$/}
                  />
                </div>
              </div>
              <span className="text-danger">{error2}</span>
            </div>
          </div>

          {numberVerified ? (
            <>
              <p>Mobile Number Verified</p>
            </>
          ) : (
            <>
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0"
                }}
              >
                <button className={btnEnable ? "button2" : "button6"} disabled={!btnEnable} onClick={() => sendMobileOtp()}>
                  Send Otp
                </button>
              </div>
              {otpsecenable ? <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <input
                    type="number"
                    name="otp"
                    onChange={(e) => setOtp(e.target.value)}
                    style={{
                      borderRadius: "5px",
                      border: "1px solid black",
                      background: "rgba(54, 42, 65, 0.04)",
                      width: "40%",
                      height: "40px",
                      padding: "20px",
                    }}
                    placeholder="Enter OTP sent to your Mobile Number"
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
                  <button className="button2" onClick={() => verifyMobileOtp()}>
                    Verify
                  </button>
                </div>
              </> : ""}

            </>
          )}

          <Label>Contact Mail Id</Label>
          <InputGroup className="mb-3">
            <Form.Control
              aria-label="Job Title"
              name="contactEmailId"
              aria-describedby="basic-addon1"
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>

          <Label>Location</Label>

          {inputList.map((input, i) => {
            return (
              <div key={i}>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div style={{ width: "50%" }}>
                    <Label>Address Line 1</Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Enter Address Line 1"
                        aria-label="Job Title"
                        name="addressOne"
                        value={input.addressOne}
                        aria-describedby="basic-addon1"
                        style={{
                          borderRadius: "5px",
                          background: "rgba(54, 42, 65, 0.04)",
                        }}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </InputGroup>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Label>Address Line 2</Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Enter Address Line 2"
                        aria-label="Job Title"
                        name="addressTwo"
                        value={input.addressTwo}
                        aria-describedby="basic-addon1"
                        style={{
                          borderRadius: "5px",
                          background: "rgba(54, 42, 65, 0.04)",
                        }}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </InputGroup>
                  </div>
                </div>
                <Row>
                  <Col>
                    <Form.Group>
                      <div>
                        <Label>Country</Label>
                        <select
                          required
                          value={input.country}
                          onChange={(e) => handleInputChange(e, i)}
                          name="country"
                          className="footer-header mb-3"
                          style={{ border: "none", height: "40px" }}
                        >
                          <option value="">Country</option>
                          {Country &&
                            Country.getAllCountries().map((item) => (
                              <option key={item.name} value={item.isoCode}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Label>State / Provision</Label>
                      <select
                        required
                        value={input.state}
                        onChange={(e) => handleInputChange(e, i)}
                        name="state"
                        className="footer-header mb-3"
                        style={{ border: "none", height: "40px" }}
                      >
                        <option value="">State</option>
                        {State &&
                          input.country &&
                          State.getStatesOfCountry(input.country).map(
                            (item) => (
                              <option key={item.name} value={item.isoCode}>
                                {item.name}
                              </option>
                            )
                          )}
                      </select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <Label>City / Town</Label>
                      <select
                        required
                        value={input.city}
                        onChange={(e) => handleInputChange(e, i)}
                        name="city"
                        className="footer-header mb-3"
                        style={{ border: "none", height: "40px" }}
                        onBlur={validateInput}
                      >
                        <option value="">City</option>
                        {City &&
                          input.country &&
                          input.state &&
                          City.getCitiesOfState(input.country, input.state).map(
                            (item) => (
                              <option value={item.name}>{item.name}</option>
                            )
                          )}
                      </select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Label>Zip / Pincode</Label>
                      <Form.Control
                        placeholder="Enter Zip / Pincode"
                        aria-label="Job Title"
                        name="pincode"
                        value={input.pincode}
                        aria-describedby="basic-addon1"
                        style={{
                          borderRadius: "5px",
                          background: "rgba(54, 42, 65, 0.04)",
                        }}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(e) => setPrimaryLocation(e, i)}
                        value={input.setTrueLocation}
                      />
                      <p style={{ marginTop: "15px" }}>
                        Set this as the primary location
                      </p>
                    </div>
                  </Col>
                  {inputList.length - 1 === i && (
                    // <Col>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "160px",
                        gap: "10px",
                        // top: "23%",
                        border: "1px solid grey",
                        borderRadius: "8px",
                        padding: "5px 20px",
                        height: "40px"
                      }}
                      onClick={() => handleAddClick(i)}
                    >
                      <AiOutlinePlusCircle size={25} />
                      <p style={{ marginTop: "2px" }}>Add Address</p>
                    </div>
                    // </Col>
                  )}
                  {inputList.length !== 1 && (
                    <button
                      className="mr10 Add-language"
                      onClick={() => handleRemoveClick(i)}
                    >
                      Remove
                    </button>
                  )}
                </Row>
              </div>
            );
          })}

          <DateWrapper>
            {/* <Button3 type="submit" disabled={!enable} style={{opacity: enable ? "100%" : "50%"}}>  */}
            <button className="button2" onClick={handleSumit}>
              Save
            </button>
            {/* </Button3> */}
          </DateWrapper>
        </PageWrapper>
      </MainLayout>
    </div>
  );
};

export default AboutInstitutePopupModal;
