import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import size from "../company/organiType.json";
import types from "../company/typesOrgi.json";
import service from "../company/service.json";
import "../Jobs/buttonStyle.css";
import { BsUpload } from "react-icons/bs";
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;

const HeaderText = styled.h2`
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  color: black;
  margin: 10px 0 30px 0;
  text-align: center;
  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 20px;
  color: #362a41;
  font-weight: normal;
  margin: 30px 0 10px 0;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const AboutInstitute = ({ match }) => {
  console.log("company id in route", match.params.id);
  const history = useHistory();

  const [input, setInput] = useState({
    collegeName: "",
    institutePage: "",
    xakalAddress: "",
    website: "",
    affiliated: ""
  });

  // eslint-disable-next-line no-unused-vars
  const [industry, setIndustry] = useState("");
  const [organisationType, setOrganisationType] = useState("");
  const [organisationSize, setOrganisationSize] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [establish, setEstablish] = useState("");
  const [error, setError] = useState({
    collegeName: "",
    institutePage: "",
    xakalAddress: "",
    website: "",
    affiliated: ""
  });

  const handleServiceChange = (event) => {
    const selectedValue = event.value;

    setServiceProvider(selectedValue);
  };
  const handleEstablishChange = (event) => {
    const selectedValue = event.value;

    setEstablish(selectedValue);
  };
  const handleOrganisationTypeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationType(selectedValue);
  };
  const handleOrganisationSizeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationSize(selectedValue);
  };

  const [serviceProviderTouched, setServiceProviderTouched] = useState(false);
  const [establishTouched, setEstablishTouched] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "companyDescription") {
      let v = value.slice(0, 1000);
      setInput((prevProps) => ({
        ...prevProps,
        [name]: v,
      }));
      event.target.value = v;
      return event;
    } else if (name === "tagline") {
      let v = value.slice(0, 250);
      setInput((prevProps) => ({
        ...prevProps,
        [name]: v,
      }));
      event.target.value = v;
      return event;
    } else {
      setInput((prevProps) => ({
        ...prevProps,
        [name]: value,
      }));
    }
  };
  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the Name of the Company!";
          }
          console.log(input.jobTitle);
          break;

        default:
          break;
      }
      return stateObj;
    });
    // handleInputBlur()
  };
  const [organisationTypeTouched, setOrganisationTypeTouched] = useState(false);
  const [organisationSizeTouched, setOrganisationSizeTouched] = useState(false);



  const handleSumit = async () => {
    //debugger;
    const payload = {
      collegeName: input.collegeName,
      affiliated: input.affiliated,
      institutePage: input.institutePage,
      xakalAddress: input.xakalAddress,
      website: input.website,
      industry: industry,
      organistionSize: organisationSize,
      organistionType: organisationType,
      servicesProvided: serviceProvider,
      establishedInYear: establish,
      companyLogo: imageURL,
      companyTagline: input["tagline"],
      companyDescription: input["companyDescription"],
    };
    console.log("payload about", input);
    console.log("payload about2", payload);
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/create/institute`, payload)
      .then((response) => {
        console.log("company update", response);
        history.push(`/portal/institute-contact/${match.params.id}`);
      })
      .catch((err) => err.error);
    // history.push("/portal/company-contact");
  };

  // eslint-disable-next-line no-unused-vars
  const [imageURL, setImageURL] = useState(null);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("image", file);
    }
  };

  const getCompanyDetails = async () => {
    return await axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details/` + match.params.id)
      .then((response) => {
        console.log("company details by id", response.data);
        if (response.data) {
          setInput({
            ...input,
            collegeName: response.data.data?.collegeName,
            affiliated: response.data.data?.affiliated,
            institutePage: response.data.data?.institutePage,
            xakalAddress: response.data.data?.xakalAddress,
            website: response.data.data?.website,
          });

          // setIndustry(response.data.data?.industry);
          setOrganisationType(response.data.data?.organisationType);
          setOrganisationSize(response.data.data?.organisationSize);
        }
      });
  };

  useEffect(() => {
    getCompanyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const startYear = 1900; // The starting year for your dropdown
  const currentYear = new Date().getFullYear(); // Get the current year
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const optionYear = years.map((year) => ({
    value: year,
    label: year,
  }))
  const optionsSize = size.size.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const optionsType = types.type.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const optionsService = service.service.map((skill) => ({
    value: skill,
    label: skill,
  }));
  return (
    <div>
      <MainLayout>
        <PageWrapper>
          <HeaderText>{"About Your Company"}</HeaderText>
          <ToastContainer />
          <Label>Name of the Institute*</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter your Organisation's Name"
              aria-label="Job Title"
              name="company"
              onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleChange}
              defaultValue={input.collegeName}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>
          {error.collegeName && (
            <span className="text-danger">{error.collegeName}</span>
          )}
          <Label>Name of the Page Name*</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter your Institute Page's Name"
              aria-label="Job Title"
              name="institutePage"
              onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleChange}
              defaultValue={input.institutePage}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>
          {error.institutePage && (
            <span className="text-danger">{error.institutePage}</span>
          )}
          <Label>Affilated*</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter your affiliated's Name"
              aria-label="Job Title"
              name="affiliated"
              onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleChange}
              defaultValue={input.affiliated}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>
          {error.affiliated && (
            <span className="text-danger">{error.affiliated}</span>
          )}
          <Label>Xakal Address</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="xakal/codeupglobal.com"
              aria-label="Job Title"
              name="xakalAddress"
              onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleChange}
              defaultValue={input.xakalAddress}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>

          <Label>Website/Domain</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter the link of your Company's website / domain"
              aria-label="Job Title"
              name="website"
              onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleChange}
              defaultValue={input.website}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>

          {/* <Label>Industry*</Label>
          <div>
            <Select
              class="footer-header  mb-3"
              style={{
                width: "100%",
                height: "40px",
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
              name="industry"
              // value={options2.find((option) => option.value === industry)}
              // onBlur={() => setIndustryNameTouched(true)}
              // onChange={handleIndustryChnage}
              // options={options2}
              value={optionsOne.find((option) => option.value === industry)}
              onBlur={() => setIndustryNameTouched(true)}
              onChange={handleIndustryChnage}
              options={optionsOne}
              isSearchable={true}
              defaultValue={industry}
              placeholder="Enter Company's Industry"
            />

            {industryNameTouched && industryNameTouched.industry && (
              <span className="text-danger">
                {industryNameTouched.industry}
              </span>
            )}
          </div> */}

          <Label>Organisation size</Label>
          <div>
            <Select
              class="footer-header  mb-3"
              style={{
                width: "100%",
                height: "40px",
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
              name="location"

              value={optionsSize.find(
                (option) => option.value === organisationSize
              )}
              onBlur={() => setOrganisationSizeTouched(true)}
              onChange={handleOrganisationSizeChange}
              isSearchable={true}
              options={optionsSize}
              defaultValue={organisationSize}
              placeholder="Enter the size of the Organisation"
            />

            {organisationSizeTouched &&
              organisationSizeTouched.organisationSize && (
                <span className="text-danger">
                  {organisationSizeTouched.organisationSize}
                </span>
              )}
          </div>

          <Label>Organisation type</Label>
          <div>
            <Select
              class="footer-header  mb-3"
              style={{
                width: "100%",
                height: "40px",
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
              name="location"
              value={optionsType.find(
                (option) => option.value === organisationType
              )}
              onBlur={() => setOrganisationTypeTouched(true)}
              onChange={handleOrganisationTypeChange}
              isSearchable={true}
              options={optionsType}
              defaultValue={organisationType}
              placeholder="Enter the type of the Organisation"
            />

            {organisationTypeTouched &&
              organisationTypeTouched.organisationType && (
                <span className="text-danger">
                  {organisationTypeTouched.organisationType}
                </span>
              )}
          </div>
          <Label>Services Provided</Label>
          <div>
            <Select
              class="footer-header  mb-3"
              style={{
                width: "100%",
                height: "40px",
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
              name="serviceProvider"
              value={optionsService.find(
                (option) => option.value === serviceProvider,
              )}
              onBlur={() => setServiceProviderTouched(true)}
              onChange={handleServiceChange}
              isSearchable={true}
              options={optionsService}
              defaultValue={serviceProvider}
              placeholder="Enter the services provided by the Organisation"
            />

            {serviceProviderTouched &&
              serviceProviderTouched.serviceProvider && (
                <span className="text-danger">
                  {serviceProviderTouched.serviceProvider}
                </span>
              )}
          </div>
          <Label>Establishment Year</Label>
          <div>
            <Select
              class="footer-header  mb-3"
              style={{
                width: "100%",
                height: "40px",
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
              name="establish"
              value={optionYear.find((option) => option.value === establish)}
              onBlur={() => setEstablishTouched(true)}
              onChange={handleEstablishChange}
              isSearchable={true}
              options={optionYear}
              defaultValue={establish}
              placeholder="Enter the Establishment year"
            />

            {establishTouched && establishTouched.establish && (
              <span className="text-danger">{establishTouched.establish}</span>
            )}
          </div>
          <Label>Logo</Label>
          {imageURL ? (
            <div
              style={{
                border: "2px dotted #000",
                padding: "10px",
                margin: "10px",
                cursor: "pointer",
                display: "flex", // Add this line
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <label style={{ position: "relative" }}>
                <div>
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    {/* Center the BsUpload icon */}
                    <img src={imageURL} alt="image1" />
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          ) : (
            <div
              style={{
                border: "2px dotted #000",
                padding: "10px",
                margin: "10px",
                cursor: "pointer",
                display: "flex", // Add this line
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <label style={{ position: "relative" }}>
                <div>
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    {/* Center the BsUpload icon */}
                    <BsUpload size={50} />
                    <div>Upload as png, jpg, jpeg</div>
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          )}

          <Label>Tag line</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder=" Write an impressive tagline for your Company"
              aria-label="Job Title"
              name="tagline"
              onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleChange}
              maxLength={250}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <p>
              {input.tagline === undefined ? "0" : input.tagline.length}/250
              Characters
            </p>
          </div>
          <Label>Company Description</Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter company description"
              aria-label="Job Title"
              name="companyDescription"
              onBlur={validateInput}
              aria-describedby="basic-addon1"
              onChange={handleChange}
              maxLength={1000}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <p>
              {input.companyDescription === undefined
                ? "0"
                : input.companyDescription.length}
              /1000 Characters
            </p>
          </div>
          <DateWrapper>
            {/* <Button3 type="submit" disabled={!enable} style={{opacity: enable ? "100%" : "50%"}}>  */}
            <button className="button2" onClick={handleSumit}>
              Save / Next
            </button>
            {/* </Button3> */}
          </DateWrapper>
        </PageWrapper>
      </MainLayout>
    </div>
  );
};

export default AboutInstitute;
