import React, { useEffect, useRef, useState } from "react";
import CustomHeaderInterview from "../interview/customHeaderInterview";
import "./tabswitcher.css"; // Ensure CSS file name matches
import ManageSwitch from "./manageSwitch";
import UpcomingSwitch from "./upcomingSwitch";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import DesktopHeader from "../desktopHeader";
import Common from "../../utils/common";
import axios from "axios";
const ManageInterview = ({
  input,
  experience,
  education,
  accountDetail,
  handleSection,
  checkActive = "active",
  handleSkill,
  enableService,
  inactives,
  opens,
  cond,
}) => {
  const [activeTab, setActiveTab] = useState("manage"); // Initial tab is 'manage'
  const [condition, setCondition] = useState(false);
  const [educationDetail, setEducationdetails] = useState([]);
  const [experienceDetail, setExperiencedetails] = useState([]);
  const [inputs, setInput] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  //calling service for status preview
  useEffect(() => {
    if (location.pathname === "/portal/interview/manage-interview") {
      setCondition(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prefillValue = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      );
      if (response && response.data && response.data.success) {
        setInput(response.data.aboutOne);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const getalledudetails = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-education-detail-all/${Common.loggedIn()}`
      );
      if (response && response.data && response.data.success) {
        setEducationdetails(response.data?.educationOne);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const getallexpdetails = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-experience-detail-all/${Common.loggedIn()}`
      );
      if (response && response.data && response.data.success) {
        setExperiencedetails(response.data?.experience);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const [accountDetails, setAccountDetail] = useState([]);
  const getOneAccount = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/account-get/${Common.loggedIn()}`
      );
      setAccountDetail(response.data.account);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    prefillValue();
    getalledudetails();
    getallexpdetails();
    getOneAccount();
  }, []);

  const getAllInterviewService = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-interview-manage`
      );

      if (response.data) {
        const newJobs = response.data.interview;
        setData(newJobs);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const prevDataRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.PUBLIC_URL}/xakal/get-all-interview-manage`
        );

        if (response.data) {
          const newJobs = response.data.interview;
          
          // Compare with previous data
          if (JSON.stringify(prevDataRef.current) !== JSON.stringify(newJobs)) {
            setData(newJobs);
            prevDataRef.current = newJobs; // Update the ref with the latest data
          }
        }
      } catch (err) {
        console.error('Error fetching interview services:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // Empty dependency array ensures this runs once on mount
  }, []);
  return (
    <div>
      <>
        {" "}
        {location.pathname === "/portal/my-profile" ? (
          ""
        ) : (
          <>
            <div className="header-layout header-layouts">
              <DesktopHeader title={"Manage Interviews"} />
            </div>
            <CustomHeaderInterview cond={cond} onService={getAllInterviewService}/>
          </>
        )}
      </>

      <div className="manage-wrap">
        <div
          className={
            location.pathname !== "/portal/my-profile"
              ? "toggleSwitchs switchClass"
              : "toggleSwitchs"
          }
        >
          <div
            className={`tabs ${activeTab === "manage" ? "active" : ""}`}
            onClick={() => handleTabChange("manage")}
          >
            Create / Manage Interviews
          </div>
          <div
            className={`tabs ${activeTab === "booking" ? "active" : ""}`}
            onClick={() => handleTabChange("booking")}
          >
            Upcoming Interviews
          </div>
        </div>
        <CustomHeaderInterview cond={cond} onService={getAllInterviewService} />
      </div>
      <div className="content-switch">
        {activeTab === "manage" ? (
          <ManageSwitch
            datas={data}
            onService={getAllInterviewService}
            loadings={loading}
            input={condition ? inputs : input}
            experience={condition ? experienceDetail : experience}
            education={condition ? educationDetail : education}
            accountDetail={condition ? accountDetails : accountDetail}
            handleSection={() => handleSection()}
            handleSkill={() => handleSkill()}
            checkActive={
              location?.state?.goInActive === true ? "inactive" : "active"
            }
            enableService={enableService}
            inactives={inactives}
            opens={opens}
            condition={condition}
            cond={cond}
          />
        ) : (
          <UpcomingSwitch />
        )}
      </div>
    </div>
  );
};

export default ManageInterview;
