/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Common from "../utils/common";
import { Amplify, Auth } from "aws-amplify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { MdOutlineLogout } from "react-icons/md";
import { BsCreditCard2Front } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import fHome from "../images/mb-home.svg";
import fHomeActive from "../images/mb-home-active.svg";
import fSuitcase from "../images/mb-suitcase.svg";
import fSuitcaseActive from "../images/mb-suitcase-active.svg";
import imageAvatar from "../images/company.png";
import CloseIcon from "../images/CloseIcon.svg";
import profile from "../images/icons/calendar.svg";
import axios from "axios";
import ChatMain from "./conversation/chatMain";
import MessageChat from "./conversation/message";
import Conversation from "./conversation/conversation";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});

const MobileFooter = ({ otherProfile, id }) => {
  const [user, setUsers] = useState("");
  const [openSetting, setOpenSetting] = useState(false);
  const [success, setSuccess] = useState();
  const [jobLink, setjobLink] = useState(false);
  const history = useHistory();
  const [image, setImage] = useState();
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  const logout = () => {
    Common.logout();
  };
  const DateWrapperButton = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  `;
  const Button9 = styled.div`
    background-color: #362a41;
    border-radius: 10px;
    border: 1px solid #362a41;
    font-weight: normal;
    font-size: 18px;
    color: white;
    padding: 13px 45px;
    margin: 15px 0;
    cursor: pointer;
    &:hover {
      background: white;
      color: #362a41;
      border: 1px solid #362a41;
    }
  `;
  const Button8 = styled.div`
    background-color: white;
    border-radius: 10px;
    border: 1px solid #362a41;
    font-weight: normal;
    font-size: 18px;
    color: #362a41;
    padding: 13px 45px;
    margin: 15px 0;
    cursor: pointer;
    &:hover {
      background: #362a41;
      color: white;
      border: 1px solid #362a41;
    }
  `;
  const Text = styled.p`
    font-family: Inter-Medium, sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 29px;
    letter-spacing: 0em;
    position: relative;
    text-align: center;
  `;
  function onSubmit2() {
    setSuccess(true);
  }
  function goChangePassword() {
    setOpenSetting(false);
    history.push("/portal/changepassword");
  }

  const { pathname } = useLocation();
  // function isActive (){
  //  return ['portal/favourite', 'portal/job-applied', 'portal/apply-jobs'].includes(pathname)
  // }

  history.listen((location, action) => {
    // console.log(`The current route is ${location.pathname}`);
    // console.log(location.pathname.split("/"))
    if (
      location.pathname.split("/").includes("applicants") ||
      location.pathname.split("/").includes("applicant-details")
    ) {
      setjobLink(true);
    } else {
      setjobLink(false);
    }
  });

  const getAboutDetail = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((res) => {
        setImage(res.data.aboutOne.avatar);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getAboutDetail();
  }, []);
  const [openMessage, setOpenMessage] = useState(false);
  const [openChat, setOpenChat] = useState(false);

  ///need this code // after prod
  const handleMessage = () => {
    setOpenMessage(true);
  };
  const [messageChats, setMessageChats] = useState(null);
  const [messageChat, setMessageChat] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [openConver, setOpenConver] = useState(false);
  const [count, setCount] = useState(0);
  const handleChat = async (c) => {
    setOpenChat(true);
    setMessageChats(c);
    await handleMessagesClick(c);
    setOpenMessage(false);
  };
  const [conversation, setConversation] = useState([]);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        // Fetch conversations
        const conversationRes = await axios.get(
          `${process.env.PUBLIC_URL}/xakal/conversation/${Common.loggedIn()}`
        );

        // Store the conversations
        const conversations = conversationRes.data.conver;

        // Fetch messages and calculate unread message count
        const conversationsWithMessages = await Promise.all(
          conversations.map(async (c) => {
            const messageRes = await axios.get(
              `${process.env.PUBLIC_URL}/xakal/message/${c._id}`
            );

            // Sort messages by timestamp
            const sortedMessages = messageRes.data.message.sort(
              (a, b) =>
                new Date(b.timestamps.createdAt) -
                new Date(a.timestamps.createdAt)
            );

            // Calculate unread messages count
            const unreadCount = sortedMessages.filter(
              (msg) => !msg.read && msg.sender !== Common.loggedIn() // Assuming unread messages are those not read by the current user
            ).length;

            // Add messages and unread count to the conversation object
            return {
              ...c,
              messages: sortedMessages,
              unreadCount,
            };
          })
        );

        // Sort conversations by the timestamp of the latest message
        conversationsWithMessages.sort((a, b) => {
          const latestMessageA = a.messages[0]; // most recent message
          const latestMessageB = b.messages[0]; // most recent message
          return (
            new Date(latestMessageB?.timestamps.createdAt) -
            new Date(latestMessageA?.timestamps.createdAt)
          );
        });

        // Update state with sorted conversations
        setConversation(conversationsWithMessages);
        const totalUnreadCount = conversationsWithMessages.reduce(
          (total, convo) => total + convo.unreadCount,
          0
        );
        setCount(totalUnreadCount);
      } catch (err) {
        console.log("Error fetching details:", err);
      }
    };

    fetchConversations();
  }, []);
  ///message chat

  const getAllMessage = async (messageChats) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/message/${messageChats}`
      );
      setMessageChat(res.data.message);
      const unreadCounts = res.data.message.filter(
        (msg) => !msg.read && msg.sender === friendId
      ).length;
      console.log(unreadCounts, "unreadCount");
      // Update state with the unread message count for this conversation
      setCount(unreadCounts);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    if (messageChats) {
      getAllMessage(messageChats?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageChats?._id]);
  const userIds = Common.loggedIn();
  const handleSendMessage = () => {
    setOpenConver(true);
  };

  const [detail, setDetail] = useState();
  const friendId = messageChats?.members.find((m) => m !== userIds);

  const getUserChat = async (friendId) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${friendId}`
      );
      setDetail(res.data.aboutOne);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    if (messageChats) {
      getUserChat(friendId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendId]);
  const handleMessagesClick = async (c) => {
    const payload = {
      conversationId: c?._id,
      userId: userIds,
    };
    try {
      // Mark messages as read
      await axios.post(`${process.env.PUBLIC_URL}/xakal/mark-as-read`, payload);

      // Fetch updated messages
      if (c) {
        await getAllMessage(c?._id); // Ensure this function awaits the API call
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const userId = Common.loggedIn();
  const [hideMessageButton, setHideMessageButton] = useState(false);
  const [conversationsId, setConversationId] = useState(null);

  useEffect(() => {
    if (otherProfile && conversation?.length > 0) {
      const isMemberInConversation = conversation.some((c) =>
        c.members.includes(id)
      );
      const matchedConversations = conversation.filter((c) =>
        c.members.includes(id)
      );
      setHideMessageButton(isMemberInConversation);
      setConversationId(matchedConversations[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherProfile, conversation]);
  return (
    <>
      <div className="mobile-footer o-mobile">
        <div className="mb-row">
          <div>
            <NavLink
              style={{ width: "12rem" }}
              to={{
                pathname: `/portal/interview/exploreInterview`,
              }}
              isActive={() =>
                [
                  "/portal/interview/exploreInterview",
                  "/portal/interview/bookedInterview",
                  "/portal/interview/savedInterview",
                ].includes(pathname)
              }
              className="mb-link"
            >
              <img src={fHome} className="mb-link-img default" alt="" />
              <img src={fHomeActive} className="mb-link-img active" alt="" />
            </NavLink>
          </div>
          <div>
            <NavLink
              style={{ width: "12rem" }}
              to={{
                pathname: `/portal/jobs`,
              }}
              isActive={() =>
                [
                  "/portal/jobs",
                  "/portal/favorites",
                  "/portal/applied-jobs",
                ].includes(pathname)
              }
              className={`${jobLink ? "active" : ""} mb-link`}
            >
              <img src={fSuitcase} className="mb-link-img default" alt="" />
              <img
                src={fSuitcaseActive}
                className="mb-link-img active"
                alt=""
              />
            </NavLink>
          </div>
          <div>
            <NavLink
              style={{ width: "12rem" }}
              to={{
                pathname: `/portal/my-calendar`,
              }}
              isActive={() => ["/portal/my-calendar"].includes(pathname)}
              className={`${jobLink ? "active" : ""} mb-link`}
            >
              <img
                src={profile}
                className="mb-link-img default img-mobile"
                alt=""
              />
              <img
                src={profile}
                className="mb-link-img active img-mobile active"
                alt=""
              />
            </NavLink>
          </div>
          {/* <div>
            <button onClick={() => setOpenSetting(true)}>
              <NavLink
                style={{ width: "12rem" }}
                to={{
                  pathname: ``,
                }}
                isActive={() =>
                  [
                    "/portal/changepassword",
                    "/portal/forget-password",
                    "/portal/otp-changed",
                    "/portal/new-password",
                  ].includes(pathname)
                }
                className="mb-link"
              >
                <img src={fSettings} className="mb-link-img default" alt="" />
                <img
                  src={fSettingsActive}
                  className="mb-link-img active"
                  alt=""
                />
              </NavLink>
            </button>
          </div> */}
          <div onClick={handleMessage} style={{ cursor: "pointer" }}>
            <ChatMain count={count} />
          </div>
        </div>
      </div>
      <Dialog
        open={openSetting}
        PaperProps={{
          className: "setting-modal",
        }}
        className="modal-container"
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <span className="mTitle mt-0">Setting</span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </div>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={goChangePassword}>Change Password</p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("portal/my-profile")}>
              My Profile
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit2}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      {/*  */}
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogContent>
          <Text className="modal-title-sub">
            Are You Sure You Want To Log Out?
          </Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openMessage}
        fullWidth
        maxWidth="sm"
        classes={{ paper: "open-message-div-mobile" }}
      >
        <div className="mheader open-message-title">
          <span>Chat</span>
          <img src={CloseIcon} onClick={() => setOpenMessage(false)} />
        </div>
        <DialogContent>
          {conversation.map((c) => {
            return (
              <>
                <div
                  onClick={() => {
                    handleChat(c);
                  }}
                >
                  <Conversation userId={userId} conversation={c} />
                </div>
              </>
            );
          })}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openChat}
        fullWidth
        maxWidth="sm"
        classes={{ paper: "open-message-div-chat-mobile" }}
      >
        <div className="modalHeader mheader">
          {messageChats ? (
            <div className="main-div-images">
              <img
                src={detail?.avatar ? detail.avatar : imageAvatar}
                alt="images-avatar"
                className="images-avatar-one"
              />
              <div className="mTitle">{`${detail?.firstName} ${detail?.lastName}`}</div>
            </div>
          ) : (
            ""
          )}
          <img src={CloseIcon} alt="img" onClick={() => setOpenChat(false)} />
        </div>

        <DialogContent>
          {messageChats ? (
            <div>
              <MessageChat
                message={messageChat}
                userId={userIds}
                messageChats={messageChats}
                setMessageChat={setMessageChat}
                arrivalMessage={arrivalMessage}
                setArrivalMessage={setArrivalMessage}
                onCallMessage={getAllMessage}
              />
            </div>
          ) : (
            ""
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MobileFooter;
