import React from "react";
import styled from "styled-components";
import avatar1 from "../../images/company.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  // padding: 30px;

  margin-left: 8rem;
  margin-right: 7.75rem;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const AboutDiv = styled.div`
  position: relative;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  //   margin: 1rem 1rem 1rem 3rem;
  color: #000;
  font-family: var(--pf-medium);
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;
const AboutSubDiv = styled.div`
  position: relative;
  margin: 1rem 1rem 1rem 2.5rem;
  top: -27px;
`;
const JobTitle = styled.div`
  position: relative;
  color: #8653b6;
  font-family: var(--pf-medium);
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
`;
const CompanyTitle = styled.div`
  position: relative;
  top: 8px;
  font-family: var(--pf-medium);
  font-size: 14px;
  color: #4f4953;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
`;
const ViewJob = styled.div`
  font-family: var(--pf-medium);
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(149, 92, 202, 1);
  padding: 0px 20px 0 0;
  margin-top: -5px;
  cursor: pointer;
`;
const PostedBy = styled.div`
  position: relative;
  font-family: var(--pf-medium);
  font-size: 12px;
  color: rgba(167, 161, 172, 1);
  font-weight: normal;
  font-style: italic;
  margin-top: 10px;
  img {
    cursor: pointer;
  }
`;
const PostedJob = ({ datas, userDetail }) => {
  const history = useHistory();
  return (
    <div>
      <PageWrapper>
        <div id="jobPost">
          <AboutDiv>
            <div className="company" style={{ color: "#362A41" }}>
              Posted Job
            </div>
          </AboutDiv>
          {/* <AboutSubDiv>
            <Label>Business Analyst</Label>
            </AboutSubDiv> */}
          {Array.isArray(datas) &&
            datas.map((a) => {
              return (
                <AboutSubDiv style={{ margin: "1.5rem 1rem 0rem 3.5rem" }}>
                  <JobTitle>{a.jobTitle}</JobTitle>
                  <CompanyTitle>
                    {a.companyName.value}
                    <ViewJob
                      onClick={() =>
                        history.push({
                          pathname: `/portal/shares-apply-job/${a?._id}`,
                        })
                      }
                    >
                      View Job
                    </ViewJob>
                  </CompanyTitle>

                  <PostedBy className="postedby">
                    Posted By{" "}
                    {userDetail && userDetail.avatar ? (
                      <img
                        src={userDetail && userDetail.avatar}
                        alt="img"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ) : (
                      <img
                        src={avatar1}
                        alt="img"
                        style={{ width: "24px", height: "24px" }}
                      />
                    )}
                    {/* <Name className="name">{`${a.createdBy.firstName} ${a.createdBy.lastName}`}</Name> */}
                  </PostedBy>
                  <hr />
                </AboutSubDiv>
              );
            })}
        </div>
      </PageWrapper>
    </div>
  );
};

export default PostedJob;
