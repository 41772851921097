/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./ordermain.css";
import xalogo from "../../images/xakallogo.png";

import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Common from "../../utils/common";
import { ToastContainer, toast } from "react-toastify";
import { Dialog, DialogContent } from "@mui/material";
import SlotUpdate from "../MyProfile/slotUpdate";
import * as moment from "moment";
import EditTimePicker from "../MyProfile/editTimePicker";
import styled from "styled-components";
import Calender from "../../images/Calendar.svg";
import Video from "../../images/Videocamera.svg";
import Clock from "../../images/clock-circle.svg";
import File from "../../images/File Text.svg";
import Edit from "../../images/solar_pen-new-round-bold-duotone.svg";
import DesktopHeader from "../desktopHeader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Tag from "../../images/Tag Price.svg";
import Tags from "../../images/TagPromo.svg";
import companyLogo from "../../images/company.png";

const Label = styled.div`
  font-size: 18px;
  color: #362a41;
  font-family: var(--pf-medium);
  font-weight: 700;
  margin: 10px 0 15px 0;
  width: 100%;
`;
const Labels = styled.div`
  font-size: 18px;
  color: #362a41;
  font-family: var(--pf-medium);
  font-weight: 700;
  margin: 30px 0 7px 0;
  width: 100%;
`;

const Duration = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #4f4953;
  margin-top: 10px;
`;
const Message = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #4f4953;
  margin-top: 20px;
`;
const Name = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #4f4953;
  margin-top: 0px;
`;
const PromoDis = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #25aa5a;
  margin-top: 10px;
`;

const Host = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #a8a1ac;
`;
const OrderSummary = ({ props }) => {
  const [time, setTime] = useState();
  const [openConfirm, setConfirmPopup] = useState(false);
  const [toTime, setToTime] = useState([]);
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState("");
  const [resume, setResume] = useState("");
  const [value, setValue] = useState();
  const [date, setDate] = useState("");
  const [duration, setDuration] = useState("");
  const [free, setFree] = useState();
  const [oneId, setOneId] = useState("");
  const { id } = useParams();

  const [bookingDetails, setBookingDetails] = useState(null);
  const [interviewId, setInterviewId] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [intervalId, setIntervalId] = useState(null);
  const history = useHistory();
  const { state } = useLocation();
  const [jobApply, setJobApply] = useState();
  const [jobId, setJobId] = useState();
  const [input, setInput] = useState();

  const activeTime = state.activeTime;
  const openTrue = state.openTrue;
  const oneTrue = state.oneTrue;
  const selectedData = state.selectedData;
  const selectedView = state.selectedView;
  const oneTrues = state.oneTrues;

  useEffect(() => {
    if (state) {
      setJobApply(state.jobApply);
      setJobId(state.jobId);
      setInput(state.input);
    }
  }, [state]);

  const moveState = () => {
    history.push({
      pathname: `/portal/interview/exploreInterview`,
      state: {
        activeTime: activeTime,
        openTrue: openTrue,
        oneTrue: oneTrue,
        selectedData: selectedData,
      },
    });
  };
  const moveStates = () => {
    history.push({
      pathname: `/portal/search`,
      state: {
        activeTime: activeTime,
        openTrue: openTrue,
        oneTrue: oneTrue,
        selectedData: selectedData,
        selectedView: selectedView,
        oneTrues: oneTrues,
      },
    });
  };
  useEffect(() => {
    // Retrieve timer value from localStorage and start the timer
    const storedTime = parseInt(localStorage.getItem("remainingTime"), 10) || 0;
    setRemainingTime(storedTime);

    const id = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(id);
          localStorage.removeItem("remainingTime"); // Remove timer from localStorage when it hits 0
          if (oneTrues) {
            moveStates();
          } else {
            moveState();
          }
          return 0;
        }
        const newTime = prevTime - 1;
        localStorage.setItem("remainingTime", newTime); // Update timer in localStorage
        return newTime;
      });
    }, 1000);

    setIntervalId(id);

    return () => {
      clearInterval(id); // Clear interval on component unmount
      localStorage.removeItem("remainingTime"); // Clean up localStorage on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
  const callValue = (id) => {
    getOne(id);
  };
  const getOne = async(id) => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${id}`)
        setBookingDetails(response?.data?.bookingSlot);
        setOneId(response.data.bookingSlot?.serviceId);
        setTime(response.data.bookingSlot.time);
        setInterviewId(response.data.bookingSlot?.interviewId);
        if (
          response.data &&
          response.data.bookingSlot.unselectedTimeSlots.length > 0
        ) {
          setToTime(response.data.bookingSlot.unselectedTimeSlots);
        }
        setDesc(response.data.bookingSlot?.description);
        setPrice(response.data.bookingSlot.price);
        setResume(response.data.bookingSot?.resume);
        setDuration(response.data.bookingSlot?.duration);
        setFree(response.data.bookingSlot?.isFree);

        const isoDate = response.data.bookingSlot.date;

        const dateObject = new Date(isoDate);

        const formattedDate = dateObject.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
        setDate(formattedDate);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    getOne(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [promoUpdateId, setPromoUpdateId] = useState();
  const [loggedInUser, setLoggedInUser] = useState(Common.loggedIn());
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${loggedInUser}`
      );
      const aboutOne = response.data.aboutOne;

      setValue(aboutOne);
    } catch (error) {
      toast.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  const combineDateAndTime = (dateString, timeString) => {
    const dateMoment = moment(dateString);
    const timeMoment = moment(timeString, "h:mm A");

    const combinedMoment = dateMoment.set({
      hour: timeMoment.hours(),
      minute: timeMoment.minutes(),
      second: timeMoment.seconds(),
    });

    const momentEpoch = combinedMoment.unix();

    return momentEpoch;
  };

  const handlerPayment = async() => {
    const amount = Math.round(parseFloat(totalAmount) * 100);

    const currency = "INR";

    const payload = {
      amount,
      currency,
      receipt: "qwerr1",
    };

    try{
      const response = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/payment`, payload)
        const data = response.data.order.id;

        var options = {
          // key: `${process.env.RAZORPAY_KEY_ID_FRONT}`,
          key: "rzp_test_COGUtktXGWRh99",
          amount,
          currency,
          name: "Xakal ",
          description: "Transaction",
          image: xalogo,
          order_id: data,
          handler: function (response) {
            const body = {
              ...response,
              interviewId: bookingDetails?.interviewId,
              serviceId: bookingDetails?.serviceId,
              candidateId: bookingDetails?.candidateId,
            };
            axios
              .post(`${process.env.PUBLIC_URL}/xakal/payment/validate`, body)
              .then((res) => {
                toast.success("Payment is successfully completed");
                const trans = res.data.payment.razorpay_payment_id;
                axios
                  .put(
                    `${process.env.PUBLIC_URL}/xakal/get-update-payment/${id}`,
                    { trans, totalAmount, codeName }
                  )
                  .then((updateResponse) => {
                    toast.success("Slot is Booked");
                    createVideoRoom();
                    if (promoUpdateId) {
                      handleUpdatePromocode();
                    }
                    if (jobApply) {
                      history.push({
                        pathname: "/portal/jobs",
                        state: { jobApply: true, jobId: jobId },
                      });
                    } else {
                      history.push({
                        pathname: "/portal/my-calendar",
                        state: {
                          openAssesment: true,
                          bookId: id,
                          closeAssesment: false,
                        },
                      });
                    }
                  })
                  .catch((updateErr) => {
                    toast.error("Payment Failed");
                  });
              })
              .catch((err) => {});
          },
          prefill: {
            name: `${value.firstName}${value.middleName}${value.lastName}`,
            email: value.email,
            contact: value.phoneNumber,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        var rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });

        rzp1.open();
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  const [bookLoading, setBookLoading] = useState(false);
  const handleBook = async() => {
    setBookLoading(true);
    try{
      await axios
      .put(`${process.env.PUBLIC_URL}/xakal/get-update-payment/${id}`)
        history.push({
          pathname: "/portal/my-calendar",
          state: {
            openAssesment: true,
            bookId: id,
            closeAssesment: false,
          },
        });
        toast.success("Slot is Booked");
        if (promoUpdateId) {
          handleUpdatePromocode();
        }
        createVideoRoom();
        setBookLoading(false);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  // const [eventId,setEventId] = useState()
  const createVideoRoom = async() => {
    let videoResponse;
    try{
      const res = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/createVideoRoom`, {})
        videoResponse = res?.data;
        const eventPayload = {
          eventTitle: bookingDetails?.serviceName,
          eventDescription: "",
          eventUrl: "",
          roomId: videoResponse?.id,
          roomName: videoResponse?.friendly_url,
          roomUrl: videoResponse?.room_url,
          eventType: "service",
          status: "active",
          serviceId: oneId,
          bookingId: bookingDetails?._id,
          bookingDetails: bookingDetails,
          host: {
            userId: bookingDetails?.interviewId,
          },
          guest: {
            userId: bookingDetails?.candidateId,
          },
          fromEpoch: combineDateAndTime(
            bookingDetails?.date,
            bookingDetails?.time
          ),
          toEpoch:
            combineDateAndTime(bookingDetails?.date, bookingDetails?.time) +
            600,
        };
        axios
          .post(`${process.env.PUBLIC_URL}/xakal/add/event`, eventPayload)
          .then((res) => {});
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };

  const [image, setImage] = useState([]);
  const getOneService = async (oneId) => {
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-interview-data/${oneId}`)
        if (response.data && response.data.interview.image.length > 0) {
          setImage(response?.data?.interview?.image);
        }
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    if (oneId) {
      getOneService(oneId);
    }
  }, [oneId]);
  const [onsId, setOnsId] = useState("");
  const handleClick = (id) => {
    setConfirmPopup(true);
    setOnsId(id);
  };
  const close = (id) => {
    setConfirmPopup(false);
    getOne(id);
  };
  const prices = 0;
  const [openEditPicker, setOpenEditPicker] = useState(false);
  const openDataPicker = (id) => {
    setOpenEditPicker(true);
    setOnsId(id);
  };
  const openEditBook = (id) => {
    setConfirmPopup(true);
    setOpenEditPicker(false);
    setOnsId(id);
  };
  const getSlotOpen = (id) => {
    setConfirmPopup(false);
    setOpenEditPicker(true);
    setOnsId(id);
  };
  const backToCompany = (id) => {
    setConfirmPopup(true);
    setOnsId(id);
  };
  const searchedKey = () => {};
  const [codeName, setCodeName] = useState("");
  const [promoError, setPromoError] = useState("");

  const [discount, setDiscountedPrice] = useState(0);
  const [discountValue, setDiscountValuePrice] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const totalOne = price * 0.18;
  const submitPromoCode = async(e) => {
    e.preventDefault();

    try{
    const response = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/validate-code-promo`, { codeName })
        if (response.data.success) {
          setPromoError("");
          setPromoUpdateId(response.data.id);
          const discountedPrice =
            price * (parseFloat(response.data.percentage) / 100);
          const calculatedTotalAmount = price - discountedPrice;
          setDiscountValuePrice(calculatedTotalAmount);
          const gstAmount = calculatedTotalAmount * 0.18; // Assuming 18% GST
          setGstAmount(gstAmount);
          const totalAmount = calculatedTotalAmount + gstAmount;
          setDiscountedPrice(discountedPrice);
          setTotalAmount(totalAmount);
        } else {
          const errorMessage = response.data.message || "Code not valid";
          setPromoError(errorMessage);
        }
      }
      catch(error)  {
        if (error.response) {
          const status = error.response.status;

          if (status === 404) {
            setPromoError(error.response.data.message || "Resource not found");
          } else if (status === 403) {
            if (
              error.response.data.error ===
              "You are not applicable for this promo code"
            ) {
              setPromoError("You are not applicable for this promo code");
            } else if (error.response.data.error) {
              setPromoError("You have already availed this promo code");
            } else {
              setPromoError("Unknown error occurred");
            }
          }
        } else if (error.request) {
          setPromoError("No response received");
        } else {
          setPromoError("Error setting up the request");
        }
      };
  };

  const [invalidTrue, setInvalidTrue] = useState(false);
  const handlePromo = (e) => {
    const inputValue = e.target.value.toUpperCase();
    setCodeName(inputValue);
    setPromoError("");
    setDiscountedPrice(0);
    setDiscountValuePrice(0);
    if (inputValue.length > 3) {
      setInvalidTrue(true);
    } else {
      setInvalidTrue(false);
    }
  };
  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    const parsedPrice = parseFloat(price);
    const parsedDiscount = parseFloat(discount);

    let calculatedTotalAmount = parsedPrice;

    if (parsedDiscount !== 0) {
      calculatedTotalAmount -= parsedDiscount;
    }

    const gstAmount = calculatedTotalAmount * 0.18;
    const parsedTotalOne = parseFloat(calculatedTotalAmount + gstAmount);

    setTotalAmount(Math.max(parsedTotalOne, 0));
  }, [price, discount]);
  const [loginId, setLoginId] = useState();
  const getUser = async() => {
    try{
    const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
        setLoginId(response.data?.userId);
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  useEffect(() => {
    getUser();
  }, []);

  const handleUpdatePromocode = async() => {
    try{
    await axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/update-promo-code/${promoUpdateId}`,
        { loginId: loginId }
      )
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  const BookingButton = () => (
    <div className="con-pay">
      <button className="button3 conbtn" onClick={handleBook}>
        {bookLoading ? "Loading..." : "Book a slot"}
      </button>
    </div>
  );

  // Define the button for continuing payment
  const PaymentButton = () => (
    <div className="con-pay">
      <button
        className="button3 conbtn"
        id="rzp-button1"
        onClick={() => handlerPayment()}
      >
        Continue Payment
      </button>
    </div>
  );
  return (
    <>
      <div className="header-layouts">
      <DesktopHeader
        title={"Order Summary"}
        searchedKey={searchedKey}
        isSearchActive={false}
        backbtn
        back={() => backToCompany(id)}
      />
      </div>
      <ToastContainer />

      <div className="order-main">
        <div className="separate-order">
          <div className="order-pad">
            <div className="order-part">
              <img
                src={input?.avatar ? input?.avatar : companyLogo}
                alt="text"
                style={{ width: "48px", height: "48px", marginBottom: "10px" }}
                className="order-img"
              />
              <div style={{ marginTop: "0px" }}>
                <div className="mTitle os-name">{`${input?.firstName} ${input?.lastName}`}</div>
                <Host>interviewer</Host>
              </div>
            </div>

            <div className="os-int-wrapper">
              <div className="icon-wrapper">
                <img src={Clock} alt="img" style={{ marginTop: "5px" }} />
                <Duration>{duration}</Duration>
              </div>
              <div className="icon-wrapper">
                <img src={Video} alt="img" style={{ marginTop: "5px" }} />
                <Duration>Video Call Interview</Duration>
              </div>
            </div>
            <div className="icon-wrapper">
              <img src={Calender} alt="img" />
              <Duration>{`${time}`}</Duration>
              {toTime.length > 0 ? (
                <Duration> {`${toTime[0]}`}</Duration>
              ) : (
                <Duration>-</Duration>
              )}
              <Duration>{`${date}`}</Duration>
              <img
                src={Edit}
                alt="img"
                className="edit-icon"
                onClick={() => openDataPicker(id)}
              />
            </div>

            <div className="file-wrapper">
              <img src={File} alt="img" className="file-icon" />
              <a href={resume && resume} target="_blank" rel="noreferrer">
                <Name>{`${value?.firstName} ${value?.middleName} ${value?.lastName}`}</Name>
              </a>
              <img
                src={Edit}
                alt="img"
                className="file-edit-icon"
                onClick={() => handleClick(id)}
              />
            </div>

            <div className="file-wrappers">
              <Message>Message to the interviewer</Message>
              <div>
                <img
                  src={Edit}
                  alt="img"
                  onClick={() => handleClick(id)}
                  style={{ width: "16px" }}
                />
              </div>
            </div>

            <textarea
              id="description"
              value={desc}
              disabled={desc}
              onChange={(e) => setDesc(e.target.value)}
              className="message-area"
            ></textarea>
            <div>
              <Duration>Remaining Time: {formatTime(remainingTime)}</Duration>
            </div>
          </div>

          <div style={{ border: "1px solid #F4F4F6" }}></div>

          <div className="payment-summary">
            {free === true ? (
              ""
            ) : (
              <div className="promo-label">
                <div>
                  <Label>Offers & Benefits</Label>
                  <InputGroup className={promoError ? "offer-einputs" : "offer-input"}>
                    {!promoError && discount === 0 ? (
                      <img src={Tag} alt="img" className="offer-input" />
                    ) : (
                      <img
                        src={Tags}
                        alt="img"
                        className="offer-input"
                      />
                    )}

                    <Form.Control
                      placeholder="Enter code"
                      aria-label="Job Title"
                      name="codeName"
                      className={
                        !promoError || codeName.length < 10
                          ? "footer-header font offer-input"
                          : "offer-einput"
                      }
                      value={codeName}
                      onChange={handlePromo}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </div>
                <div>
                  <button
                    className={
                      !promoError && discount === 0 ? "apply-btn" : "apply-ebtn"
                    }
                    disabled={!invalidTrue}
                    style={{ opacity: invalidTrue ? 1 : 0.5 }}
                    onClick={submitPromoCode}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}

            {promoError && <div className="promo-error">{promoError}</div>}
            {discount !== 0 && (
              <div className="promo-apply">{`You saved  ₹${discount}`}</div>
            )}

            <Labels>Payment Summary</Labels>
            <div className="input-wrapper">
              {free === true ? (
                <div className="price-wrap">
                  <Duration>Original Price</Duration>
                  <Duration>
                    {new Intl.NumberFormat("en-IN", {}).format(prices)}
                  </Duration>
                </div>
              ) : (
                <>
                  <div>
                    <div className="price-wrap">
                      <Duration>Original Price</Duration>
                      <Duration>
                        {" "}
                        {new Intl.NumberFormat("en-IN", {}).format(price)} INR
                      </Duration>
                    </div>
                    {discount !== 0 && (
                      <div className="price-wrap">
                        <Duration>Promo Discount</Duration>
                        <PromoDis> -{discount} INR</PromoDis>
                      </div>
                    )}
                    <hr />
                    {discountValue !== 0 && (
                      <div className="price-wrap">
                        <Duration>Price After Discount</Duration>
                        <Duration> {discountValue} INR</Duration>
                      </div>
                    )}
                    {discount !== 0 ? (
                      <div className="price-wrap">
                        <Duration>GST (18%)</Duration>
                        <Duration> {gstAmount} INR</Duration>
                      </div>
                    ) : (
                      <div className="price-wrap">
                        <Duration>GST (18%)</Duration>
                        <Duration> {totalOne.toFixed(2)} INR</Duration>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>

            <hr />
            <div>
              {free === true ? (
                <div className="price-wrap">
                  <Duration>Total</Duration>
                  <Duration>
                    {new Intl.NumberFormat("en-IN", {}).format(prices)}
                  </Duration>
                </div>
              ) : (
                <div>
                  <div className="price-wrap">
                    <Duration>Total</Duration>
                    <Duration>
                      {" "}
                      {new Intl.NumberFormat("en-IN", {}).format(
                        totalAmount
                      )}{" "}
                      INR
                    </Duration>
                  </div>
                </div>
              )}
            </div>
            <>
              {free === true || totalAmount === 0 ? (
                <BookingButton />
              ) : (
                <PaymentButton />
              )}
            </>
          </div>
        </div>
      </div>
      <Dialog
        open={openConfirm}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "800px",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <SlotUpdate
            input={input}
            slotId={onsId}
            close={() => close(onsId)}
            onOpen={() => getSlotOpen(id)}
            onClose={() => setConfirmPopup(false)}
            onCall={() => callValue(id)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openEditPicker}
        PaperProps={{
          style: {
            width: "100%",

            borderRadius: "24px",
            maxWidth: "540px",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <EditTimePicker
            input={input}
            serviceId={oneId}
            interviewId={interviewId}
            slotId={onsId}
            open={openEditBook}
            onClose={() => setOpenEditPicker(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OrderSummary;
