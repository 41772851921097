import React, { useState } from "react";

import "./company.css";

import { FaRegEdit } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { Link } from "react-scroll";
import styled from "styled-components";
import avatar1 from "../../images/company.png";
import { BsEye } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AboutPopupModal from "./aboutpopupCompany";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import AboutContactModal from "./aboutContactPopup";
import { AiOutlineCloseCircle } from "react-icons/ai";
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  //   padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const PageWrapperOne = styled.div`
  background: white;
  border-radius: 10px;
  margin: 1rem 0rem;
  width: 10%;
  z-index: 99999;
  position: relative;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const PageWrapperTwo = styled.div`
  background: white;
  border-radius: 10px;
  //   padding: 30px;
  margin: 1rem 0rem;
  height: 45vh;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const AboutDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 1rem 1rem 1rem 3rem;
`;
const Label = styled.div`
  font-size: 20px;
  color: #362a41;
  font-weight: normal;
  margin: 30px 0 10px 0;
`;
const AboutSubDiv = styled.div`
  position: relative;
  margin: 1rem 1rem 1rem 3rem;
  top: -27px;
`;
const JobTitle = styled.h3`
  position: relative;
  font-family: Inter-Medium;
  font-size: 25px;
  color: black;
  display: flex;
  justify-content: space-between;
`;
const CompanyTitle = styled.p`
  position: relative;
  font-family: Inter-Medium;
  font-size: 15px;
  color: black;
`;
const PostedBy = styled.p`
  position: relative;
  font-family: Inter-Medium;
  font-size: 15px;
  color: black;
`;
const HrLines = styled.div`
  border: 0.6px solid #c4c4c4;
`;
const CompanyPreview = ({ onClose }) => {
  const [isListVisible, setListVisible] = useState(false);

  const toggleList = () => {
    setListVisible(!isListVisible);
  };
  const history = useHistory()
  const handleSumit = () => {
    history.push("/portal/edit-company");
  };
  const handleSubmitTwo = () => {
    history.push("/portal/company-domain");

  }
  const [open, setPopupOpen] = useState(false);
  const [openTwo, setPopupOpenTwo] = useState(false);


  const openPopup = () => {
    setPopupOpen(true);
  };
  const openPopupTwo = () => {
    setPopupOpenTwo(true);
  };
  return (
    <div>
      <MainLayout>
        <PageWrapperTwo>
          <div className="color-div">
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <FaRegEdit size={30} color="aliceblue" />
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={avatar1} alt="avatar" className="avatarImg" />
            <h1
              style={{
                marginLeft: "10px",
                position: "relative",
                top: "-90px",
              }}
            >
              Code up Global
            </h1>
          </div>
          <div
            style={{
              position: "relative",
              display: "inline",
              left: "11%",
              top: "-119px",
            }}
          >
            <p>
              Codeup Global Solutions is a cutting-edge software company
              specializing in delivering innovative solutions for business
            </p>
            <p>IT Services and IT Consulting|Chennai, Tamil Nadu</p>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "end",
              top: "-215px",
            }}
          >
            <RxDashboard size={30} onClick={toggleList} />
            {isListVisible && (
              <PageWrapperOne>
                <ul style={{ marginLeft: "10px", cursor: "pointer" }}>
                  <li onClick={handleSumit}>Edit Page</li>
                  <HrLines />
                  <li>Manage Admin</li>
                  <HrLines />

                  <li onClick={handleSubmitTwo}>Manage Domains</li>
                  <HrLines />

                  <li onClick={() => history.push("/portal/company-employee")}>Manage Employees</li>
                </ul>
              </PageWrapperOne>
            )}
          </div>

        </PageWrapperTwo>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <nav className="navbars" style={{ width: "60%" }}>
            <ul id="nav" className="nav nav-pills">
              <li className="nav-item">
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-180}
                  duration={1000}
                  className="link-text"
                >
                  <div className="dot-wrapper">
                    <div className="dots "></div>
                    <hr className="hr-line" />
                  </div>
                  About
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-200}
                  duration={1000}
                  className="link-text"
                >
                  <div className="dot-wrapper">
                    <div className="dots"></div>
                    <hr className="hr-line" />
                  </div>
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="jobPost"
                  spy={true}
                  smooth={true}
                  offset={-400}
                  duration={1000}
                  className="link-text"
                >
                  <div className="dot-wrapper">
                    <div className="dots"></div>
                  </div>
                  Post Job
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <PageWrapper>
          <div id="about">
            <AboutDiv>
              <h1>About</h1>
              <FaRegEdit
                size={30}
                color="black"
                style={{ position: "relative", marginTop: "30px" }}
                onClick={() => openPopup()}
              />
            </AboutDiv>
            <AboutSubDiv>
              <Label>Name</Label>
              Codeup Global Solutions pvt ltd
              <Label>Website</Label>
              https://www.codeupglobal.com/
              <Label>Industry</Label>
              IT Services and IT Consulting
              <Label>Organisation Size</Label>
              11-50
              <Label>Organisation Type</Label>
              Privately held
              <Label>Tag Line</Label>
              Codeup Global Solutions is a cutting-edge software company
              specializing in delivering innovative solutions for business
            </AboutSubDiv>
          </div>
        </PageWrapper>
        <PageWrapper>
          <div id="contact">
            <AboutDiv>
              <h1>Contact Details</h1>
              <FaRegEdit
                size={30}
                color="black"
                style={{ position: "relative", marginTop: "30px" }}
                onClick={() => openPopupTwo()}

              />
            </AboutDiv>
            <AboutSubDiv>
              <Label>ContactNumber</Label>
              +91 8807101795
              <Label>Contact Mail Id</Label>
              naveenan@codeupglobal.com
              <Label>Location</Label>
              Codeup Global Solutions pvt ltd, New No. 431, 431, Anna Salai,
              Mount Road, Teynampet, Chennai, Tamil Nadu, India 600018
              <Label>Workplace modes</Label>
              Remote, Hybrid, Onsite
            </AboutSubDiv>
          </div>
        </PageWrapper>
        <PageWrapper>
          <div id="jobPost">
            <AboutDiv>
              <h1>Posted Job</h1>
            </AboutDiv>
            <AboutSubDiv>
              <JobTitle>
                Business Analyst{" "}
                <BsEye size={30} style={{ marginTop: "10px" }} />
              </JobTitle>
              <CompanyTitle>Codeup Global Solution Pvt Limited</CompanyTitle>
              <PostedBy>Posted By : Aravind Kumaran</PostedBy>
            </AboutSubDiv>
          </div>
        </PageWrapper>
      </MainLayout>
      <Dialog open={open}>
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <span>Edit About</span>
          <AiOutlineCloseCircle onClick={() => setPopupOpen(false)} />
        </DialogTitle>
        <DialogContent>
          <AboutPopupModal />
        </DialogContent>
      </Dialog>
      <Dialog open={openTwo}>
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <span>Edit Contact</span>
          <AiOutlineCloseCircle onClick={() => setPopupOpenTwo(false)} />
        </DialogTitle>
        <DialogContent>
          <AboutContactModal />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompanyPreview;
