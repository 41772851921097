/* eslint-disable react/jsx-no-target-blank */
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Country, State } from "country-state-city";

const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
export function ViewEmployeeDetails() {
  const { userId } = useParams();
  const [userDetails, setUserDetails] = useState({});
  const [educationdetails, setEducationdetails] = useState([]);
  const [experiencedetails, setExperiencedetails] = useState([]);
  const [projectdetails, setProjectdetails] = useState([]);
  const [coursedetails, setCoursedetails] = useState([]);

  const getalledudetails = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-education-detail-all/${id}`)
      .then((response) => {
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          setEducationdetails(response.data.educationOne);
        }
      })
      .catch((err) => err.error);
  };

  const getallexpdetails = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-experience-detail-all/${id}`)
      .then((response) => {
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          setExperiencedetails(response.data.experience);
        }
      })
      .catch((err) => err.error);
  };

  const getallprodetails = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-project-detail-all/${id}`)
      .then((response) => {
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          setProjectdetails(response.data.projectOne);
        }
      })
      .catch((err) => err.error);
  };

  const getallcourseDetails = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-certi-detail-all/${id}`)
      .then((response) => {
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          setCoursedetails(response.data.certiOne);
        }
      })
      .catch((err) => err.error);
  };

  const getUser = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${id}`)
      .then((response) => {
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          setUserDetails(response.data.aboutOne);
        }
      })
      .catch((err) => err.error);
  };

  const getcn = (cn) => {
    if (cn && cn.length === 2) {
      let a = Country.getCountryByCode(cn);
      if (a && a.name) {
        return a.name;
      }
    } else {
      return cn;
    }
  };
  const getst = (cn, st) => {
    if (cn && st && cn.length === 2 && st.length === 2) {
      let a = State.getStateByCodeAndCountry(st, cn);
      if (a && a.name) {
        return a.name;
      }
    } else {
      return cn;
    }
  };

  useEffect(() => {
    getUser(userId);
    getalledudetails(userId);
    getallexpdetails(userId);
    getallprodetails(userId);
    getallcourseDetails(userId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <MainLayout>
        <div style={{ marginTop: "0px" }}>
          <div
            className="container head-container"
            id="about"
            style={{ width: "100%" }}
          >
            <div className="myprofile-head">
              <h1
                style={{ color: "#362A41", marginTop: "-5px" }}
                data-index="about"
              >
                About
              </h1>
            </div>

            <div className="myprofile-content-head">
              <>
                <div>
                  <img
                    src={userDetails.avatar}
                    alt="img"
                    style={{ borderRadius: "100px" }}
                  />
                </div>
              </>

              <div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <h3>{userDetails.firstName}</h3>
                  <h3>{userDetails.middleName}</h3>
                  <h3>{userDetails.lastName}</h3>
                </div>
                <hr className="hr2" />
                <h4 className="head-content">{userDetails.bio}</h4>

                <div className="myprofile-content contentone">
                  <p>Phone Number</p>
                  <p className="">{userDetails.phoneNumber}</p>
                </div>

                <div className="myprofile-content">
                  <p>Email Id</p>
                  <p className="myprofile-data">{userDetails.email}</p>
                </div>

                <div className="myprofile-content">
                  <p>Description</p>
                  <p className="myprofile-data-des">{userDetails.about}</p>
                </div>

                <div className="myprofile-content">
                  <p>Skills</p>

                  <div className="myprofile-content-skills">
                    {userDetails.softSkill &&
                      userDetails.softSkill.map((skill, index) => {
                        return (
                          <span key={index} style={{ marginRight: "10px" }}>
                            {skill}
                          </span>
                        );
                      })}
                  </div>
                </div>

                <div className="myprofile-content">
                  <p>Languages</p>
                  {userDetails.inputList &&
                    userDetails.inputList.map((a, i) => {
                      return (
                        <p className="myprofile-content-data">{a.language}</p>
                      );
                    })}
                </div>

                <div className="" style={{ marginTop: "20px" }}>
                  <p>Work Profile / Links</p>
                  {userDetails.inputList2 &&
                    userDetails.inputList2.map((a, i) => {
                      return (
                        <div className="myprofile-content-links">
                          <p>{a.workProfile}</p>
                          <a href={a.urlLink} target="_blank">
                            {a.urlLink}
                          </a>
                        </div>
                      );
                    })}
                </div>

                {/* <div className="myprofile-content">
                  <p>Resume</p>
                  <p className="myprofile-data"></p>
                </div> */}

                <div className="myprofile-content">
                  <p>Location</p>
                  {userDetails.city &&
                  userDetails.country &&
                  userDetails.state ? (
                    <p className="myprofile-content-location">{`${
                      userDetails.city
                    },${getst(userDetails.country, userDetails.state)},${getcn(
                      userDetails.country,
                    )}`}</p>
                  ) : (
                    <p></p>
                  )}
                </div>
                <div className="myprofile-content">
                  <p>Resume</p>
                  <div className="resume-list">
                    {userDetails.inputList3 &&
                      userDetails.inputList3.map((a, i) => (
                        <a href={a.resume} target="_blank" key={i}>
                          {a.fileName ? a.fileName : ""}
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="container head-container"
            style={{ width: "100%" }}
            id="education"
          >
            <div className="myprofile-head">
              <h1 style={{ color: "#362A41", marginTop: "-5px" }}>Education</h1>
            </div>

            {/* <div className="myprofile-content">
                <p style={{marginTop:"20px"}}>Please mention your education details. You can add details about school, college and degree. This will increrase your profile stength.</p>
                </div> */}

            <div className="myprofile-content-skills">
              {educationdetails.map((e) => {
                return (
                  <>
                    <div style={{ position: "relative" }}>
                      <div className="Edu-data-wrapper EduType">
                        {/* <p className="Edu-data2">Specilization </p> */}
                        <p className="Edu-data"> {e.specialization}</p>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "1px",
                          display: "flex",
                          gap: "18px",
                        }}
                      ></div>
                      <div className="Edu-data-wrapper">
                        {/* <p className="Edu-data2">University </p> */}
                        <p className="Edu-data"> {e.university}-Chennai</p>
                      </div>
                      <div className="Edu-data-wrapper">
                        {/* <p className="Edu-data2">Start  </p> */}
                        <p className="Edu-data"> {`${e.startDate} `}</p>
                      </div>
                      <div className="Edu-data-wrapper">
                        {/* <p className="Edu-data2">Completed  </p> */}
                        <p className="Edu-data"> {`${e.endDate}`}</p>
                      </div>
                      <div className="Edu-data-wrapper">
                        {/* <p className="Edu-data2">Education Type </p> */}
                        <p className="Edu-data"> {e.educationType}</p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div
            className="container head-container"
            style={{ width: "100%" }}
            id="experience"
          >
            <div className="myprofile-head">
              <h1 style={{ color: "#362A41", marginTop: "-5px" }}>
                Experience
              </h1>
            </div>

            {/* <div className="myprofile-content">
                <p style={{marginTop:"20px"}}>Please mention your employment details including your current and previous work experience.</p>
                </div> */}

            <div className="myprofile-content-skills">
              {experiencedetails.map((ei, i) => {
                return (
                  <>
                    <div style={{ position: "relative" }}>
                      <p style={{ color: "#362A41", fontWeight: "bold" }}>
                        {ei.designation}
                      </p>

                      <div
                        style={{
                          position: "absolute",
                          left: "30%",
                          top: "1px",
                          display: "flex",
                          gap: "18px",
                        }}
                      ></div>
                      <p>{ei.company && ei.company.value}</p>
                      <p>{ei.location}</p>
                      <p>
                        {ei.workMode} {ei.startDate}{" "}
                        {ei.endDate === "" ? "to present" : ei.endDate}
                      </p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div
            className="container head-container"
            style={{ width: "100%" }}
            id="projects"
          >
            <div className="myprofile-head">
              <h1 style={{ color: "#362A41", marginTop: "-5px" }}>Projects</h1>
            </div>

            {/* <div className="myprofile-content">
                <p style={{marginTop:"20px"}}>Please mention the projects you have worked on.</p>
                </div> */}

            <div className="myprofile-content-skills">
              {projectdetails.map((pro, i) => {
                return (
                  <>
                    <div style={{ position: "relative" }}>
                      <p style={{ color: "#362A41", fontWeight: "bold" }}>
                        {pro.projectTitle}
                      </p>
                      <div
                        style={{
                          position: "absolute",
                          left: "30%",
                          top: "1px",
                          display: "flex",
                          gap: "18px",
                        }}
                      ></div>
                      <p key={i.id} style={{ marginRight: "10px" }}>
                        {pro.tool}
                      </p>

                      <p>{`${pro.startDate} - ${pro.endDate}`}</p>

                      <p>
                        {pro.skill.map((skill, index) => (
                          <span key={index} style={{ marginRight: "10px" }}>
                            {skill}
                          </span>
                        ))}
                      </p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div
            className="container head-container"
            style={{ width: "100%" }}
            id="courses"
          >
            <div className="myprofile-head">
              <h1 style={{ color: "#362A41", marginTop: "-5px" }}>Courses</h1>
            </div>

            {/* <div className="myprofile-content">
                <p style={{marginTop:"20px"}}>Please provide information on the courses you have completed and the certifications you havae attained.</p>
                </div> */}

            <div className="myprofile-content-skills">
              {coursedetails &&
                coursedetails.map((e) => {
                  return (
                    <>
                      <div style={{ position: "relative" }}>
                        <p style={{ color: "#362A41", fontWeight: "bold" }}>
                          {e.course}
                        </p>
                        <div
                          style={{
                            position: "absolute",
                            left: "30%",
                            top: "1px",
                            display: "flex",
                            gap: "18px",
                          }}
                        ></div>
                        <p>{e.organisation}</p>
                        <p>Issued Date - {e.issueDate}</p>
                        <p>Start Date : {`${e.startDate} `}</p>
                        <p style={{ marginTop: "-10px" }}>
                          End Date : {`${e.endDate}`}
                        </p>
                        <p>Validity : {`${e.validity}`}</p>
                        <p>{e.skill}</p>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </MainLayout>
    </div>
  );
}
