import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineCloseCircle } from "react-icons/ai";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select from "react-select";
import softskill from "../MyProfile/industry.json";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import "../Jobs/buttonStyle.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import size from "./organiType.json";
import types from "./typesOrgi.json"
import { CiSettings } from "react-icons/ci";
import { BsCreditCard2Front } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";
import Common from "../../utils/common";
import { Amplify, Auth } from "aws-amplify";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});
const MainLayout = styled.div`
  margin: 150px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const HeaderLayout = styled.div`
  position: absolute;
  top:0;
  margin-left: 5px;
  width: 1200px;
  left: 226px;
  border: 1px;
  height: 105px;
  background: white;
`;
const SeparteDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
`;
const MainHeader = styled.div`
  font-weight: normal;
  position: relative;
  display: flex;
  justify-content: flex-start;
  top: 40px;
  margin-left: 39px;
  color: #362A41;
  font-family: "Inter-Medium,sans-serif";
  font-size: 24px;
`;

const HeaderText = styled.h2`
  font-weight: normal;
  color:#362A41;
  font-family:Inter-Medium,sans-serif;
  font-size: 18px;
  line-height: 38px;
  color: black;
  margin: 10px 0 30px 0;
  text-align: left;
  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #4F4953;
  font-family:Inter-Medium,sans-serif;
  font-weight: normal;
  margin: 30px 0 10px 0;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const Paragraph = styled.p`
  color: #362A41;
  font-family: Inter-Medium,sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 130%; /* 23.4px */
  text-transform: capitalize;
`;
const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button8 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const CompanyOne = () => {
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const { id } = useParams()
  function onSubmit() {
    setSuccess(true);
  }
  const [input, setInput] = useState({
    company: "",
    organisation: "",
    xakalAddress: "",
    website: "",
  });

  const optionsOne = softskill.industry.map((skill) => ({
    value: skill,
    label: skill,
  }));

  const [industry, setIndustry] = useState("");
  const [organisationType, setOrganisationType] = useState("");
  const [organisationSize, setOrganisationSize] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [companyId, setCompanyId] = useState("");

  const [error, setError] = useState({
    company: "",
    organisation: "",
    xakalAddress: "",
    website: "",
  });

  const handleIndustryChnage = (event) => {
    const selectedValue = event.value;
    console.log(event.value);
    setIndustry(selectedValue);
  };
  const handleOrganisationTypeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationType(selectedValue);
  };
  const handleOrganisationSizeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationSize(selectedValue);
  };

  const [industryNameTouched, setIndustryNameTouched] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the Name of the Company Page!";
          }
          break;
        case "organisation":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the Name of the Organisation!";
          }
          break;
        case "xakalAddress":
          const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter Xakal Address!";
          }
          else if (!regex) {
            stateObj[name] = "Alphabets only allowed enter without spaces!";
          }
          break;
        case "website":
          const regex1 = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter Website / Domain!";
          }
          else if (!regex1) {
            stateObj[name] = "Please enter valid Website / Domain!";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };
  const [organisationTypeTouched, setOrganisationTypeTouched] = useState(false);
  const [organisationSizeTouched, setOrganisationSizeTouched] = useState(false);
  const [verify, setVerify] = useState(false)
  const getCompanyDetails = async () => {
    return await axios
      .get(`${process.env.PUBLIC_URL}/xakal/company/details/${id}`)
      .then((response) => {
        console.log("company details by id", response.data);
        if (response.data) {
          setInput({
            ...input,
            company: response.data.data?.companyName,
            xakalAddress: response.data.data?.xakalAddress,
            website: response.data.data?.website,
            organisation: response.data.data?.organisation,
          });
          setIndustry(response.data.data?.industry);
          setOrganisationType(response.data.data?.organisationType);
          setOrganisationSize(response.data.data?.organisationSize);
          setVerify(response.data.data.isCompanyVerified)
        }
      });
  };
  useEffect(() => {
    getCompanyDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [open, setOpen] = useState(false);

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handleSumit = async () => {
    if (verify) {
      const payload = {
        companyName: input.company,
        xakalAddress: input.xakalAddress,
        website: input.website,
        industry: industry,
        organisation: input.organisation,
        organisationSize: organisationSize,
        organisationType: organisationType,

      };

      await axios
        .post(`${process.env.PUBLIC_URL}/xakal/create/company`, payload)
        .then((response) => {
          console.log("company update", response);
          history.push(`/portal/company-about/${id}`);
        })
        .catch((err) => err.error);
    }

  };

  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const [errorOtpOne, setErrorOtpOne] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isSendOtpClicked, setIsSendOtpClicked] = useState(false);

  const sendEmailOtp = async () => {
    setIsResendDisabled(true);
    setResendTimer(60);
    setIsSendOtpClicked(true);
    if (input.company) {
      setErrorOtp("");
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        website: input.website,
      };
      await axios
        .post(`${process.env.PUBLIC_URL}/xakal/company/email/sendOtp`, payload)
        .then((response) => {
          console.log(response);
          toast.success("OTP sent to Mail", {
            autoClose: 5000,
            position: "top-right",
          });
          setErrorMessage("Enter the Otp that has send to your Mail Id!");
          startResendTimer();
        })
        .catch((err) => {
          toast.error("Failed to send OTP", {
            autoClose: 5000,
            position: "top-right",
          });
          console.log(err.error);
        });
    }
  };
  const startResendTimer = () => {
    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      setIsResendDisabled(false);
      setResendTimer(0);
      clearInterval(timerInterval);
    }, 60000);
  };
  const formatTimer = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const verifyOtp = async () => {
    if (input.company) {
      setErrorOtp("");
      setErrorOtpOne("");
    }
    const payload = {
      emailId: email,
      otp: +otp,
      website: input.website,
    };
    console.log("otp verify", payload);
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/company/email/verify`, payload)
      .then((response) => {
        console.log(response);
        // if (!response.data.success) {
        //   toast.error(response.data.error, {
        //     autoClose: 5000,
        //     position: "top-right",
        //   });
        // } else {
        //   setOpen(false);
        //   toast.success("Email Verified", { autoClose: 5000 });
        //   history.push(`/portal/company-about/${companyId}`);
        // }
        if (response && response.data && response.data.success === true) {
          setTimeout(() => {
            setOpen(false);
            history.push(`/portal/company-about/${companyId}`);
            // Redirect to a different page
            // history.push(`./addnew-jobinfo/${jobIds}`);
          }, 1000);
          setErrorOtp(response.data.message);
        } else if (
          response &&
          response.data &&
          response.data.success === false
        ) {
          // Set the error message for an invalid OTP
          setErrorOtpOne("Invalid OTP");
        }
      })
      .catch((err) => err.error);
  };
  const validcheckemail = () => {
    let url = input.website
      .replace(/\//g, "")
      .replace("www.", "")
      .replace("https:", "")
      .replace("http:", "");
    if (email.indexOf("@") === -1) {
      return "";
    } else {
      let a = email.split("@");
      if (a[1] === url) {
        return "ok";
      } else {
        return "Invalid Email (" + url + ")";
      }
    }
  };
  const optionsSize = size.size.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const optionsType = types.type.map((skill) => ({
    value: skill,
    label: skill,
  }));

  const errorHandle = (e) => {
    const enteredValue = e.target.value;
    if (/^\d{0,4}$/.test(enteredValue)) {
      setOtp(enteredValue);
    } else {
      setOtp("")
    }
  };
  const [openSetting, setOpenSetting] = useState(false);
  const openSubmit = () => {
    setOpenSetting(true);
  };

  const logout = () => {
    Common.logout();
  };
  const [user, setUsers] = useState("");
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  return (
    <div>
      <HeaderLayout>
        <MainHeader>Create Your Company Page</MainHeader>
        <SeparteDiv>
          <div
            style={{ position: "relative", marginTop: "12px", right: "90px" }}
          >
            <CiSettings
              size={30}
              onClick={openSubmit}
              style={{ cursor: "pointer" }}
            />
          </div>
        </SeparteDiv>
      </HeaderLayout>

      <MainLayout>

        <PageWrapper>
          <HeaderText>{"Create Company"}</HeaderText>
          <ToastContainer />
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <Label>Company Page Name<span className="text-danger">*</span></Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter Your Company Pages Name"
                  aria-label="Job Title"
                  name="company"
                  onBlur={validateInput}
                  aria-describedby="basic-addon1"
                  onChange={handleChange}
                  value={input.company}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
              {error.company && (
                <span className="text-danger">{error.company}</span>
              )}
            </div>
            <div style={{ width: "50%" }}>
              <Label>Organisation Name<span className="text-danger">*</span></Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter Your Organisation's Name"
                  aria-label="Job Title"
                  name="organisation"
                  onBlur={validateInput}
                  value={input.organisation}
                  aria-describedby="basic-addon1"
                  onChange={handleChange}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
              {error.organisation && (
                <span className="text-danger">{error.organisation}</span>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <Label>Xakal Address<span className="text-danger">*</span></Label>
              <div style={{ display: "flex" }}>
                <div className="xakal-address">WWW.xakal.com/</div>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="craft an exclusive tag to represent .."
                    aria-label="Job Title"
                    name="xakalAddress"
                    onBlur={validateInput}
                    value={input.xakalAddress}
                    disabled
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    style={{
                      borderRadius: "0px 5px 5px 0 ",
                      background: "rgba(54, 42, 65, 0.04)",
                    }}
                  />
                </InputGroup>
              </div>
              <div className="xakal-add">Your xakal address:www.xakal.com/craft</div>
              {error.xakalAddress && (
                <span className="text-danger">{error.xakalAddress}</span>
              )}
            </div>
            <div className="hover-content-icon content-icon">
              i
              <div className="hover-content">
                you can share this unique xakal adress to other people whome you wish to visit this page.
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <Label>Website / Domain<span className="text-danger">*</span></Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter the link of your Company's website / domain"
                  aria-label="Job Title"
                  name="website"
                  onBlur={validateInput}
                  disabled
                  aria-describedby="basic-addon1"
                  onChange={handleChange}
                  value={input.website}
                  style={{
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                  }}
                />
              </InputGroup>
              {error.website && (
                <span className="text-danger">{error.website}</span>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <Label>Industry</Label>
              <div>
                <Select
                  class="footer-header  mb-3"
                  styles={{

                    height: "40px",
                    control: (base, state) => ({
                      ...base,
                      width: "100%",
                      backgroundColor: "#F4F4F6",
                      border: "1px solid #F4F4F6",
                      borderRadius: "8px"
                    })
                  }}
                  name="industry"
                  value={optionsOne.find((option) => option.value === industry)}
                  onBlur={() => setIndustryNameTouched(true)}
                  onChange={handleIndustryChnage}
                  options={optionsOne}
                  isSearchable={true}
                  placeholder="Enter Company's Industry"
                />

                {industryNameTouched && industryNameTouched.industry && (
                  <span className="text-danger">
                    {industryNameTouched.industry}
                  </span>
                )}
              </div>
            </div>
            <div style={{ width: "50%" }}>

              <Label>Organisation size</Label>
              <div>
                <Select
                  class="footer-header  mb-3"
                  styles={{

                    height: "40px",
                    control: (base, state) => ({
                      ...base,
                      width: "100%",
                      backgroundColor: "#F4F4F6",
                      border: "1px solid #F4F4F6",
                      borderRadius: "8px"
                    })
                  }}
                  name="organisationSize"
                  value={optionsSize.find(
                    (option) => option.value === organisationSize
                  )}
                  onBlur={() => setOrganisationSizeTouched(true)}
                  onChange={handleOrganisationSizeChange}
                  isSearchable={true}
                  options={optionsSize}
                  placeholder="Enter the size of the Organisation"
                />

                {organisationSizeTouched &&
                  organisationSizeTouched.organisationSize && (
                    <span className="text-danger">
                      {organisationSizeTouched.organisationSize}
                    </span>
                  )}
              </div>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <Label>Organisation type</Label>
            <div>
              <Select
                class="footer-header  mb-3"
                styles={{

                  height: "40px",
                  control: (base, state) => ({
                    ...base,
                    width: "100%",
                    backgroundColor: "#F4F4F6",
                    border: "1px solid #F4F4F6",
                    borderRadius: "8px"
                  })
                }}
                name="organisationType"
                value={optionsType.find(
                  (option) => option.value === organisationType
                )}
                onBlur={() => setOrganisationTypeTouched(true)}
                onChange={handleOrganisationTypeChange}
                isSearchable={true}
                options={optionsType}
                placeholder="Enter the type of the Organisation"
              />

              {organisationTypeTouched &&
                organisationTypeTouched.organisationType && (
                  <span className="text-danger">
                    {organisationTypeTouched.organisationType}
                  </span>
                )}
            </div>
          </div>
          <DateWrapper>
            {/* <Button3 type="submit" disabled={!enable} style={{opacity: enable ? "100%" : "50%"}}>  */}
            <button className="button2" onClick={handleSumit}>
              Create
            </button>
            {/* </Button3> */}
          </DateWrapper>
        </PageWrapper>
      </MainLayout>
      <Dialog open={open} onClose={handleClosePopup}>
        <DialogTitle
          style={{
            display: "flex",
            fontFamily: "Inter-Medium",
            position: "relative",
            justifyContent: "end",
          }}
        >
          <AiOutlineCloseCircle onClick={() => setOpen(false)} />
        </DialogTitle>
        <DialogContent>
          <Paragraph>
            To complete company page creation, kindly verify that you are an
            authorised representative of the Company "{input.company}"
          </Paragraph>
          <Label>Email - ID</Label>
          <div style={{ display: "flex", gap: "10px" }}>
            <input
              type="text"
              name="email"
              style={{
                borderRadius: "5px",
                // border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "90%",
                height: "40px",
                padding: "20px",
              }}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your company mail ID"
            />
            <button
              className="button3"
              disabled={validcheckemail() !== "ok"}
              onClick={() => sendEmailOtp()}
            >
              Send Otp
            </button>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <span style={{ color: "green" }}>{errorMessage}</span>
            </div>

          </div>
          {validcheckemail() !== "ok" && (
            <span className="text-danger">{validcheckemail()}</span>
          )}
          <Label>Enter the OTP sent to {email}</Label>
          <div style={{ display: "flex", gap: "10px" }}>

            <input
              name="otp"
              value={otp}
              style={{
                borderRadius: "5px",
                border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "90%",
                height: "40px",
                padding: "20px",
              }}
              onChange={errorHandle}
              placeholder="Enter the OTP sent to the entered MAIL ID"
            />
            <button
              className="button3"
              disabled={!otp}
              onClick={() => verifyOtp()}
            >
              Next
            </button>
          </div>
          <div>
            {isSendOtpClicked && (
              <span>
                {resendTimer > 0
                  ? `Resend OTP in ${formatTimer(resendTimer)}`
                  : !isResendDisabled && (
                    <span onClick={sendEmailOtp} className="resend-content">Didn't receive the code yet? <span className="resend">Resend OTP</span></span>
                  )}
              </span>
            )}
          </div>
          <div>
            <span style={{ color: "green" }}>{errorOtp}</span>
          </div>
          <div>
            <span style={{ color: "red" }}>{errorOtpOne}</span>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompanyOne;
