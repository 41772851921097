import React, {useEffect} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width:90vw;
    // overflow-x:hidden;
`
const Para = styled.div`
    width:90vw;
    padding:20px 50px 100px 50px;
    margin:auto;
    // overflow-x:hidden;
    @media (max-width:500px){
        width:100%;
        padding:20px 10px 30px 30px;
    }
`
const Head = styled.div`
color:#362A41;
font-size: 24px;
display:flex;
justify-content:center;
margin-top:30px;
font-family:Inter-Medium,sans-serif;
`
const Content = styled.div`
color:#362A41;
font-size: 16px;
// display:flex;
// justify-content:center;
line-height:22px;
margin-top:20px;
font-family:Inter-Medium,sans-serif;

`
const ContentHead = styled.div`
color:#362A41;
font-size: 22px;
line-height:24px;
margin-top:20px;
font-family:Inter-Medium,sans-serif;
text-align:left;
`
const Contact = () => {
    useEffect(() => {
        document.title = "Contact | Xakal";
      }, []);
    
    return(
        <div >
            <Wrapper>
            <div >
            <Head>CONTACT</Head>
            </div>
            <Para>
            {/* <ContentHead>We prioritize your Privacy: </ContentHead> */}
            
            <Head>Welcome to Xakal! </Head>
            <ContentHead>Operational Address</ContentHead>
            <Content>202/2, Asiad Colony, Anna Nagar West Extn, Chennai - 600101</Content>
            <ContentHead>Contact Number</ContentHead>
            <Content>+91 93634 63195</Content>
            <ContentHead>Email Address</ContentHead>
            <Content>support@thexakal.com</Content>
            </Para>
            </Wrapper>
        </div>
    )
}

export default Contact;