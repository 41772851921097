import React, { useEffect, useRef, useState } from "react";
import "../../styles/myprofile.css";
import companyLogo from "../../images/company.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Back from "../../images/Back.svg";
import styled from "styled-components";
import Video from "../../images/Videocamera.svg";
import Clock from "../../images/clock-circle.svg";
import Calender from "../../images/Calendar.svg";
import Download from "../../images/Download Minimalistic.svg";
import { toast } from "react-toastify";

const Button = styled.div`
  width: 224px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #c6cad2;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: #362a41;
  font-weight: normal;
  margin-top: 10px;
  cursor: pointer;
`;
const Duration = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4f4953;
  margin-top: 10px;
`;

const Host = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #a8a1ac;
`;
const SlotUpdate = ({
  input,
  openFirst,
  slotId,
  close,
  onOpen,
  onClose,
  onCall,
}) => {
  const history = useHistory();

  const [desc, setDesc] = useState("");
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [time, setTime] = useState();
  const [toTime, setToTime] = useState([]);
  const [resumeUrl, setResumeUrl] = useState("");

  useEffect(() => {
    const fetchData = async() =>{
    if (slotId) {
      try{
        const response = await axios
        .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${slotId}`)
        
          setTime(response.data.bookingSlot.time);
          setName(response.data.bookingSlot.serviceName);
          setResumeUrl(response.data.bookingSlot?.resume);
          setDuration(response.data.bookingSlot.duration);
          setDesc(response.data.bookingSlot.description);
          if (
            response.data &&
            response.data.bookingSlot.unselectedTimeSlots.length > 0
          ) {
            setToTime(response.data.bookingSlot.unselectedTimeSlots);
          }
          const isoDate = response.data.bookingSlot.date;

          // Convert ISO 8601 date to JavaScript Date object
          const dateObject = new Date(isoDate);

          // Format the date as "MMM DD YYYY"
          const formattedDate = dateObject.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          });
          setDate(formattedDate);
          setShowResume(!response.data.bookingSlot?.resume);
        }
        catch(error){console.log("Error fetching details:",error)}
    }
  }
  fetchData();
  }, [slotId]);
  const [showResume, setShowResume] = useState(false);

  const [file, setFile] = useState(null);

  const updateValue = async () => {
    let selectedFileUrl = "";

    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      // Upload the file
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/files/`,
        formData
      );

      selectedFileUrl = response.data.url;
    }

    const updatedResume = showResume ? selectedFileUrl || "" : resumeUrl;

    const payload = {
      description: desc,
      resume: updatedResume,
    };

    try {
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-update-time/${slotId}`,
        payload
      );

      if (response.data.success) {
        console.log(response, "respo");
        const orderSlot_id = response.data.bookingSlotUpdate._id;

        history.push({
          pathname: `/portal/order-summary/${orderSlot_id}`,
          state: { input: input },
        });

        onClose();
        onCall();
      }

      console.log(response, "response");
    } catch (err) {
      console.log(err);
    }
  };
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile.size > 5 * 1024 * 1024) {
      toast.error("File size exceeds 5MB");
      return;
    }

    const allowedFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ]; // Supported file types: doc, docx, pdf // Add supported file types here
    if (!allowedFileTypes.includes(selectedFile.type)) {
      toast.error("Unsupported file format");
      return;
    }

    setFile(selectedFile);
    setUseProfileResume(false); // Uncheck the checkbox when a new file is selected
  };

  const handleButtonClick = () => {
    fileInputRef.current.value = null;
    fileInputRef.current.click();
  };
  const [useProfileResume, setUseProfileResume] = useState(!!resumeUrl);
  useEffect(() => {
    setUseProfileResume(!!resumeUrl);
  }, [resumeUrl]);

  const handleCheckboxChange = () => {
    setUseProfileResume(!useProfileResume);

    if (!useProfileResume) {
      setFile(null);
    }
  };
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    if (file) {
      setIframeSrc(URL.createObjectURL(file));
    } else if (resumeUrl) {
      setIframeSrc(resumeUrl);
    }
  }, [file, resumeUrl]);
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            gap: "15px",
            padding: "10px 20px",
            justifyContent: "flex-start",
          }}
        >
          <img src={Back} alt="img" onClick={onOpen} />
          <div className="modalTitle">{name} Interview</div>

          {/* <img src={CloseIcon} alt="img" onClick={onClose}/> */}
        </div>
        <div className="modalBody">
          <div>
            <div className="imageProd">
              <img
                src={input?.avatar ? input?.avatar : companyLogo}
                alt="ava"
                className="imageData"
                style={{ width: "48px", height: "48px", marginBottom: "10px" }}
              />
              <div>
                <h3
                  className="mTitle"
                  style={{ marginTop: "5px", width: "180px" }}
                >{`${input?.firstName} ${input.middleName} ${input.lastName}`}</h3>
                <Host>Interviewer</Host>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "50px" }}>
            <div>
              <div style={{ display: "flex", gap: "15px", marginTop: "10px" }}>
                <img src={Clock} alt="img" style={{ marginTop: "5px" }} />
                <Duration>{duration}</Duration>
              </div>
              <div style={{ display: "flex", gap: "15px", marginTop: "10px" }}>
                <img src={Video} alt="img" style={{ marginTop: "5px" }} />
                <Duration>Video Call Interview</Duration>
              </div>

              <div style={{ display: "flex", gap: "15px", marginTop: "10px" }}>
                <img src={Calender} alt="img" style={{ marginTop: "5px" }} />
                <Duration>{`${time}`}</Duration>
                {toTime.length > 0 ? (
                  <Duration> {`${toTime[0]}`}</Duration>
                ) : (
                  <Duration>-</Duration>
                )}
                <Duration>{`${date}`}</Duration>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "-55px",
                border: "1px solid #f4f4f6",
                borderRadius: "8px",
                padding: "5px 10px 5px 5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <iframe
                    src={iframeSrc}
                    title="Resume"
                    style={{
                      width: "140px",
                      height: "180px",
                      margin: "10px",
                      background: "#F4F4F4",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  />
                </div>
                <div style={{ margin: "5px 0 15px 15px" }}>
                  <Duration style={{ marginLeft: "15px" }}>
                    Add a resume for the interviewer
                  </Duration>
                  <Duration
                    style={{
                      margin: "15px 0 0 20px",
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={useProfileResume}
                      onChange={handleCheckboxChange}
                      disabled={!resumeUrl}
                      style={{ width: "24px", height: "24px" }}
                    />{" "}
                    Use resume in my profile
                  </Duration>
                  <div
                    style={{
                      margin: "15px 70px 0 70px",
                      color: "#c6cad2",
                      fontFamily: "Inter-Medium,sans-serif",
                      fontStyle: "italic",
                      fontweight: "normal",
                    }}
                  >
                    -------or-----
                  </div>
                  <div>
                    <Button
                      type="button"
                      onClick={handleButtonClick}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <AiOutlineUpload />  */}
                      <img src={Download} alt="img" />
                      Upload Resume
                    </Button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      accept=".pdf"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Duration style={{ margin: "20px 20px 5px 20px" }}>
          Please share anything that will help the interview prepare for the
          interview
        </Duration>

        <textarea
          id="description"
          value={desc}
          name="description"
          onChange={(e) => setDesc(e.target.value)}
          style={{
            background: "#F4F4F6",
            borderRadius: "8px",
            width: "90%",
            height: "90px",
            padding: "10px",
            margin: "0 20px",
          }}
        />
      </div>
      <div
        className="modalFooter"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        <button className="button5" onClick={() => onClose()}>
          Cancel
        </button>
        <button className="button3" onClick={() => updateValue()}>
          Checkout
        </button>
      </div>
    </>
  );
};

export default SlotUpdate;
