import MyAssociatesList from "./myAssociatesList";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import DesktopHeader from "../desktopHeader";
import "./assoc.css"
const Header = styled.div`
  display: flex;
  margin: 30px auto;
  text-align: center;
  gap: 8px;
  width: 270px;
  background: #8653b6;
  justify-content: space-around;
  border-radius: 16px;
  padding: 10px;
`;

const Heading = styled.p`
  color: #ffffff;
  font-size: ${(props) => (props.selected ? "18px" : "14px")};
  cursor: pointer;
`;
const AssociatesAndFollowers = ({ view }) => {
  const { id } = useParams();
  const location = useLocation();
  console.log("location", location);
  const [selected, setSelected] = useState(location?.state?.data);
  // eslint-disable-next-line no-unused-vars
  const [associates, setAssociates] = useState(0);
  const [isFollowersDisabled, setIsFollowersDisabled] = useState(true); // Flag to disable Followers
  const [isFollowingDisabled, setIsFollowingDisabled] = useState(true);
  const [asscdata, setAsscData] = useState([]);
  const [followersData, setFollowersData] = useState([]);
  const [followingData, setFollowingData] = useState([]);
  console.log("selected", selected);
  const asscCount = (val) => {
    setAssociates(val);
  };

  const getMyFollowing = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/followers/details/${id}`
      );
      console.log("followers", res?.data);
      setFollowersData(
        res?.data?.followersData?.filter((e) => e.status === true)
      );
      setFollowingData(
        res?.data?.followingData?.filter((e) => e.status === true)
      );
      setIsFollowersDisabled(res?.data?.followersData?.length === 0);
      setIsFollowingDisabled(res?.data?.followingData?.length === 0);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const getMyAssc = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/associates/${id}`
      );
      console.log("associates", res?.data);
      const connected = res?.data?.filter(
        (e) => e?.connectionStatus === "ASSOCIATE"
      );
      setAsscData(connected);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
console.log(asscdata,"asscdata")
  const findChanges = () => {
    getMyAssc();
    getMyFollowing();
  };

  useEffect(() => {
    getMyAssc();
    getMyFollowing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="header-layouts"><DesktopHeader /></div>
      <Header>
        <Heading
          selected={selected === "associates"}
          onClick={() => setSelected("associates")}
        >
          <span>Associates</span>
          <br />
          <span>{asscdata.length}</span>
        </Heading>
        <Heading
          selected={selected === "followers"}
          onClick={(e)=>e.stopPropagation()}
          disabled={isFollowersDisabled}
          className="disableFollows"
        >
          <span>Followers</span>
          <br />
          <span>{followersData.length}</span>
        </Heading>
        <Heading
          selected={selected === "following"}
          onClick={(e)=>e.stopPropagation()}
          disabled={isFollowingDisabled}
          className="disableFollows"

        >
          <span>Following</span>
          <br />
          <span>{followingData.length}</span>
        </Heading>
      </Header>
      <div>
        {selected === "associates" && (
          <MyAssociatesList
            userId={id}
            setCount={asscCount}
            asscdata={asscdata}
            followersData={followersData}
            followingData={followingData}
            type={"associate"}
            actualData={asscdata}
            findChanges={findChanges}
          />
        )}
        {/* {selected === "followers" && isFollowersDisabled && (
          <MyAssociatesList
            userId={id}
            setCount={asscCount}
            asscdata={asscdata}
            followersData={followersData}
            followingData={followingData}
            type={"followers"}
            actualData={followersData}
            findChanges={findChanges}
          />
        )}
        {selected === "following" && isFollowingDisabled && (
          <MyAssociatesList
            userId={id}
            setCount={asscCount}
            asscdata={asscdata}
            followersData={followersData}
            followingData={followingData}
            type={"following"}
            actualData={followingData}
            findChanges={findChanges}
          />
        )} */}
      </div>
    </div>
  );
};

export default AssociatesAndFollowers;
