import React from "react";
import Message from "../../images/Message_Icon.svg";
import "./chatmain.css"; // Assuming you have a CSS file for styling

const ChatMain = ({ count }) => {
  return (
    <div className="chat-main-container">
      <div>
        <img src={Message} alt="logo" className="image-avatar-message image-avt" />
        <div className="chat-name">Chats</div>
      </div>
       <div className="unread-message-count">{count}</div>
    </div>
  );
};

export default ChatMain;
