import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../input/input";
import { Colors } from "../utilities/colors";
import Image from "../../images/profile.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const HeaderText = styled.div`
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  color: black;
  margin: 10px 0 10px 0;
  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const CompanySection = styled.div`
  display: flex;
  margin-bottom: 20px;
  marginleft: "20px";
`;

const InputBox = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  width: 90%;
  height: 54px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const ButtonDiv = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    position: absolute;
    left: 24%;
    margin-top: 60px;
  }
`;

const ButtonDiv2 = styled.div`
  display: flex;
  gap: 20px;
`;
const ButtonDiv3 = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 80px;
`;
const ButtonDiv4 = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 80px;
  margin-top: 20px;
`;
const Button = styled.div`
  width: 150px;
  background-color: #362a41;
  border-radius: 128px;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 50px;
  margin: 15px 0;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button2 = styled.div`
  // width:150px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Button3 = styled.div`
  background-color: #764f90;
  border-radius: 10px;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #764f90;
    border: 1px solid #764f90;
  }
`;
const Button4 = styled.div`
  background-color: rgba(50, 249, 130, 1);
  border-radius: 10px;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: rgba(50, 249, 130, 1);
    border: 1px solid rgba(50, 249, 130, 1);
  }
`;
const Button5 = styled.div`
  background-color: rgba(255, 41, 62, 1);
  border-radius: 10px;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: rgba(255, 41, 62, 1);
    border: 1px solid rgba(255, 41, 62, 1);
  }
`;
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0 0rem 0;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 5px;
`;

const Wrapper = styled.div`
  margin-top: 10px;
`;
const JobDetail = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
  left: 110px;
`;

const JobTitle = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Date = styled.div`
  // background:yellow;
  padding: 5px;
  color: #764f90;
  font-size: 16px;
  font-weight: normal;
  width: 120px;
  margin: 20px;
`;

const HrLines = styled.div`
  border: 0.6px solid #c4c4c4;
`;

const PreferenceWrapper = styled.div``;
const Preference = styled.div``;
const JobPreference = styled.div``;

const Applicant = styled.div``;
const ModalWrapper = styled.div`
  background: transperent;
  /* opacity:99%; */
  width: 100vw;
  height: 100vh;
  padding: 15% 20%;
  z-index: 2;
  overflow: auto;
  position: absolute;
  top: 0px;
  margin-left: -300px;
`;
const PageWrapper2 = styled.div`
  background: #f3f3f3;
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0 0rem 0;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const ActiveJob = () => {
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  function onSubmit() {
    history.replace("./addnew-job");
  }

  useEffect(() => {
    const fetchData = async() =>{
    try{
      const response = await axios
      .get(`${process.env.PUBLIC_URL}/xakal/getall-active`)
        console.log(response, "jobbss");
        if (response.data) {
          setData(response.data.job);
        }
      }
      catch(error){console.log("Error fetching details:",error)}
    }
    fetchData();
  }, []);

  return (
    <div>
      <MainLayout>
        {/* Start of desktop Header  */}

       
        <HeaderText>{"Manage Jobs"}</HeaderText>

        <CompanySection>
          <InputBox>
            <Input
              color="#fff"
              placeholder={"Search"}
              // value={searchval}
              // onChange={onSearch}
            />
          </InputBox>

          <ButtonDiv>
            <Button style={{ cursor: "pointer" }}>Search</Button>
          </ButtonDiv>
        </CompanySection>

        <ButtonDiv2>
          <Button2 onClick={() => history.push("./Jobs")}>Active Jobs</Button2>
          <Button4>Archived Jobs</Button4>
          <Button2 onClick={() => history.push("./drafts")}>Draft</Button2>
          <ButtonDiv3>
            <Button3 onClick={onSubmit}>Post Job</Button3>
          </ButtonDiv3>
        </ButtonDiv2>
        {data &&
          data.map((i, ind) => {
            return (
              <PageWrapper>
                <ContentWrapper>
                  <img src={Image} alt="img" style={{ width: "80px" }} />
                  <Wrapper>
                    <JobTitle
                      onClick={() => history.push(`./archivedjobsapp/${i._id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {i.jobTitle}
                    </JobTitle>
                    <JobTitle>{i.companyName}</JobTitle>
                  </Wrapper>
                  <ButtonDiv4>
                    <Button5 onClick={() => setOpen2(true)}>Delete</Button5>
                  </ButtonDiv4>
                </ContentWrapper>
                <JobDetail>
                  <JobTitle>Exp {i.experienceRequired}years |</JobTitle>
                  <JobTitle>
                    Exp Exp {`${i.salaryFrom}L - ${i.salaryTo}L`} |
                  </JobTitle>
                  <JobTitle>{i.location} |</JobTitle>
                  <JobTitle>{i.preference} |</JobTitle>
                  <JobTitle>{i.workMode} </JobTitle>
                </JobDetail>
                <DateWrapper>
                  <Date>02-Aug-2023</Date>
                </DateWrapper>
                <HrLines />
                <PreferenceWrapper className="prefer-wrap">
                  <Preference className="job-preference">Preferred :</Preference>
                  <JobPreference className="job-preference-pill">Female Candidates</JobPreference>
                  <JobPreference className="job-preference-pill">Post Graduate</JobPreference>
                  <JobPreference className="job-preference-pill">Age limlit 22-30yrs</JobPreference>
                  {i.skill.map((a, i) => {
                    return <JobPreference>{a}</JobPreference>;
                  })}
                </PreferenceWrapper>
                <DateWrapper  className="applicant-wrap">
                  <Applicant className="applicant-text">{i.applicants.length} Applicant</Applicant>
                </DateWrapper>
              </PageWrapper>
            );
          })}

        {open ? (
          <div>
            <ModalWrapper>
              <PageWrapper2>
                <JobTitle onClick={() => setOpen(false)}>X</JobTitle>
                <JobTitle>Do You Want to Re-open the job post?</JobTitle>
                <JobTitle>
                  You can also edit the job details before re-opening it.{" "}
                </JobTitle>
                <DateWrapper>
                  <Date>Cancel</Date>
                  <Button3>Reopen</Button3>
                </DateWrapper>
              </PageWrapper2>
            </ModalWrapper>
          </div>
        ) : (
          ""
        )}

        {open2 ? (
          <div>
            <ModalWrapper>
              <PageWrapper2>
                <JobTitle onClick={() => setOpen2(false)}>X</JobTitle>
                <JobTitle>Do You Want to delete the job post?</JobTitle>

                <DateWrapper>
                  <Date>Back</Date>
                  <Button5>Delete</Button5>
                </DateWrapper>
              </PageWrapper2>
            </ModalWrapper>
          </div>
        ) : (
          ""
        )}
      </MainLayout>
    </div>
  );
};

export default ActiveJob;
