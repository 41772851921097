import styled from "styled-components";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions, DialogTitle } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "../../images/CloseIcon.svg";
import { ReactComponent as BackBtn } from "../../images/backBtn.svg";
import iSettings from "../../images/icons/settings.svg";
import Common from "../../utils/common";
import Avatar from "../../images/avatar.jpg";
import EditIcon from "../../images/Component 6.svg";
import star from "../../images/icons/star.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const MainLayout = styled.div`
  margin: ${(props) => (props.dataTrue ? "0" : "120px 50px 30px 70px")};
  overflow-x: hidden;
  margin-left: ${(props) => (props.dataTrue ? "7.5rem" : "0")};
  margin-right: ${(props) => (props.dataTrue ? "6.25rem" : "0")};
`;
const HeaderLayout = styled.div`
  position: absolute;
  top: 0;
  margin-left: 5px;
  width: calc(100% - 226px);
  left: 226px;
  border: 1px;
  height: 105px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MainHeader = styled.div`
  font-weight: normal;
  //position: relative;
  display: flex;
  justify-content: flex-start;
  top: 40px;
  margin: auto;
  margin-left: 39px;
  color: #362a41;
  //font-family:
  //  Inter-Medium,
  //  sans-serif;
  font-size: 24px;
`;

const Heading = styled.p`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;

const Label = styled.div`
  font-size: 16px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  margin: 7px 0 0 5px;
`;

const Labels = styled.div`
  font-size: 12px;
  color: rgba(168, 161, 172, 1);
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  margin: 8px 0 0 5px;
`;
const Profile = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 0px;
  // border-bottom:1px solid #F4F4F6;
`;
const Img = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const Header = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin-top: 15px;
  margin-left:40px;
`;
const PageWrapper = styled.div`
  background: white;
  border-radius: 24px;
  margin: 1rem 2rem;
  padding: 20px 0px 0 0px;
  // min-height:100px;
  // display:flex;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f6;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f6;
`;
const PageWrappers = styled.div`
  background: white;
  margin: 1rem 0rem 0 0;
  padding: 20px 20px 0 30px;
  display: grid;
  grid-template-columns: 5.5fr 4fr 2fr;  
  gap:92px;
  border-bottom: 1px solid #f4f4f6;
  margin-top: 0px;
  padding-bottom: 10px;
  borderbottom: 1px solid #f4f4f6;
  border-radius:24px;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
  @media (min-width:1025px) and (max-width:1180px) {
   grid-template-columns: 4.75fr 3fr 2.25fr;
  }
   @media (min-width:769px) and (max-width:820px) {
   grid-template-columns: 5fr 3fr 3fr;
  }
   @media (min-width:821px) and (max-width:1024px) {
   grid-template-columns: 5.5fr 3fr 3.5fr;
  }
   @media (max-width:768px) {
   grid-template-columns: 5.5fr 3fr 4fr;
  }
`;
const DeActHead = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 25.6px */
  text-transform: capitalize;
  // margin-left: 2px;
  bottom: 10px;
  position: relative;
`;
const DeActContent = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 25.6px */

  margin-left: 2px;
  padding: -50px;
  width: 384px;
`;
const ButtonOne = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  color: white;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  background: #fd2145;
  border: 1px solid #1213171a;
  padding: 12px 22px;
  height: 40px;
  position: relative;
  cursor: pointer;
  &:hover {
    border: 1px solid #764f90;
  }
`;

export function ViewEmployeeList({ props, data, dataTrue }) {
  const history = useHistory();
  const [employees, setEmployees] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const {state} = useLocation()
  const [selectedId, setSelectedId] = useState(null);
  const [dataValue, setDataValue] = useState();
  const getCurrentEmployees = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/manage/employee`,
        { website: id }
      );
      setEmployees(response?.data);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    if (dataTrue) {
      getCurrentEmployees(data?.website);
    } else {
      const company = state?.website;

      getCurrentEmployees(company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars

  const deactivateCompany = async () => {
    const checkbox = document.getElementById("confirmationCheckbox");
    if (!checkbox.checked) {
      toast.error("Please confirm deactivation by checking the checkbox");

      return;
    }
    try {
      await axios.put(
        `${process.env.PUBLIC_URL}/xakal/deactivate-company/${selectedId}`
      );
      setSuccessOne(false);
      GetAllCompany();
      toast.success("Company is Successfully Deactivated");
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const [success, setSuccess] = useState(false);

  const ref = useRef(null);
  const [dropdown, setDropdown] = useState(false);
  function onSubmit2() {
    setSuccess(true);
  }
  const logout = () => {
    Common.logout();
  };

  // eslint-disable-next-line no-unused-vars
  const [company, setCompany] = useState();
  const GetAllCompany = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/getusercompany`
      );
      setCompany(response.data.company);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const getOneDetail = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/company/details/${id}`
      );
      setDataValue(response.data.data);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    GetAllCompany();
  }, []);
  const [successOne, setSuccessOne] = useState(false);

  useEffect(() => {
    if (dataTrue) {
      getOneDetail(data?.companyId);
    } else {
      const company = state?.companyId;

      getOneDetail(company);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ToastContainer />
      {dataTrue === true ? (
        ""
      ) : (
        <>
          <HeaderLayout>
            <MainHeader>
              {" "}
              <BackBtn
                style={{ marginRight: "24px", cursor: "pointer" }}
                onClick={() => {
                  history.push(
                    `/portal/edit-companyview/${
                      dataValue && dataValue.companyId
                    }`
                  );
                }}
              />
              {dataValue && dataValue.companyName}
            </MainHeader>
            <div className="dh-setting-container">
              <div class="dropdown" ref={ref}>
                <div
                  className="dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => setDropdown(true)}
                  style={{ margin: "30px 60px" }}
                >
                  <img src={iSettings} className="" alt="" />
                </div>
                <div
                  className={dropdown ? "dropdown-menu show" : "dropdown-menu"}
                  style={{ margin: "0px 60px" }}
                >
                  <div
                    className="dropdown-item iCpass"
                    onClick={() => history.push("/portal/changepassword")}
                  >
                    Change Password
                  </div>
                  <div
                    className="dropdown-item iCbank"
                    onClick={() => history.push("/portal/accountdetails")}
                  >
                    Bank Details
                  </div>
                  <div
                    className="dropdown-item iChelp"
                    onClick={() =>
                      (window.location = "mailto:support@thexakal.com")
                    }
                  >
                    Help & Support
                  </div>
                  <div className="dropdown-item iLogout" onClick={onSubmit2}>
                    {" "}
                    Logout
                  </div>
                </div>
              </div>
            </div>
          </HeaderLayout>
        </>
      )}

      <MainLayout dataTrue={dataTrue}>
        {dataTrue === true ? "" : <Header>Manage Employees</Header>}

        <PageWrapper>
          <Wrapper>
            <div>
              <Heading style={{ marginLeft: "2rem" }}>Profile</Heading>
            </div>
            <div>
              <Heading >Date of Joining</Heading>
            </div>
            <div>
              <Heading style={{ marginLeft: "-11rem" }}>Actions</Heading>
            </div>
          </Wrapper>
          {employees?.map((employee, index) => (
            <PageWrappers key={index} style={{}}>
              <Profile>
                {employee?.user?.avatar ? (
                  <Img src={employee?.user?.avatar} alt={""} />
                ) : (
                  <Img src={Avatar} alt="img" />
                )}
                <div>
                  <div className="star-main">
                    <Label>
                      {employee?.user?.firstName}

                      {employee?.user?.middleName}

                      {employee?.user?.lastName}
                    </Label>
                    {/* {employee?.user?.totalAverageRating !== undefined &&
                    employee?.user?.totalAverageRating !== null &&
                    employee?.user?.totalAverageRating !== 0 &&
                    employee?.user?.totalAverageRating !== 0.5 ? (
                      <div>
                        <img src={star} className="star-people" alt="" />
                        <span className="star-average">
                          {employee.user.totalAverageRating.toFixed(1)}
                        </span>
                      </div>
                    ) : null} */}
                  </div>
                  <Labels>{employee?.experience[0]?.designation}</Labels>
                </div>
              </Profile>
              <div>
                <Label>
                  {employee?.experience[0]?.startDate}
                </Label>
              </div>
              <div>
                <img
                  src={EditIcon}
                  alt="img"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push({
                      pathname: `/portal/otherProfile/${employee?.user?.userId}`,
                    });
                  }}
                />
              </div>
            </PageWrappers>
          ))}
        </PageWrapper>
      </MainLayout>

      <Dialog
        open={successOne}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { borderRadius: "20px", margin: "0px", width: "auto" },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            color: "#362A41",
            fontSize: "18px",
            fontweight: "normal",
          }}
        >
          Deactivate Page
          {/* <AiOutlineCloseCircle onClick={() => setSuccess(false)} /> */}
          <img src={CloseIcon} alt="img" onClick={() => setSuccessOne(false)} />
        </DialogTitle>

        <DialogContent>
          <div style={{ padding: "20px" }}>
            <DeActHead>We're really sorry for your decision</DeActHead>
            <DeActContent>
              Deactivation will remove the page entirely from Xakal and your
              company admins will no longer will have the access to the page
            </DeActContent>
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "20px",
              position: "relative",
              marginBottom: "32px",
              gap: "10px",
            }}
          >
            <input type="checkbox" id="confirmationCheckbox" />
            <DeActContent>
              I hereby confirm that I deactivate the Company page
            </DeActContent>
          </div>
        </DialogContent>
        <DialogActions
          style={{ boxShadow: "0px -4px 16px 0px rgba(0, 0, 0, 0.08)" }}
        >
          <div
            style={{
              position: "relative",
              display: " flex",
              justifyContent: "right",
              gap: "20px",
              marginTop: "20px",
              marginRight: "16px",
            }}
          >
            <button onClick={() => setSuccessOne(false)} className="button5">
              Cancel
            </button>
            <ButtonOne onClick={() => deactivateCompany(selectedId)}>
              Deactivate
            </ButtonOne>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        className="dh-modal"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <div className="modalHeader">
          <h1 className="mTitle">Logging Out</h1>
        </div>
        <div className="modalBody">
          <p>Are you sure you want to log out?</p>
        </div>
        <div className="modalFooter">
          <button className="btn btn-cancel" onClick={() => setSuccess(false)}>
            No
          </button>

          <button onClick={logout} className="btn btn-logout">
            Log out
          </button>
        </div>
      </Dialog>
      {/* <MainLayout>
              <div className="Myprofile-container" style={{margin:"0"}}>
              <div className="Myprofile-topcontainer">
              <div className="myp-avatar">
                {dataValue && dataValue.companyLogo ? (
                  <>
                    <div>
                      <img
                        src={dataValue && dataValue.companyLogo}
                        alt="img"
                        style={{
                          width: "190px",
                          height: "190px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <img
                      src={avatar1}
                      alt="img"
                      style={{
                        width: "160px",
                        height: "160px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                )}
              </div>
              </div>
                <div className="myp-wrapper" style={{display:"block"}}>
                <div className="company" onClick={() =>history.push(`/portal/edit-companyview/${dataValue && dataValue.companyId}`)}>{dataValue && dataValue.companyName}</div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                <div>
                <p style={{marginLeft:"40px"}}>{dataValue && dataValue.companyDescription}</p>
                      <p style={{marginLeft:"40px"}}>{dataValue && dataValue.companyTagline}</p>
                      <p style={{marginLeft:"40px"}}>
                        {dataValue && dataValue.organisationType}|
                        {dataValue && dataValue.additionalAddress.map((x) => {
                          return (
                            <p >
                              {x.city}, {x.state}
                            </p>
                          );
                        })}
                </p>
                </div>
                <img src={Share} alt="img" style={{width:"40px",height:"40px",cursor:"pointer"}} onClick={()=>setOpenPage(!openPage)}/>
                </div>
                <div className="DeActWrapper">
                <div></div>
                <div className={openPage ? "DeActPage" : "page"} onClick={() => handleOpens(data && data._id)}>Deactivate Page</div>
                </div>
               
              </div>
              </div>
            </MainLayout> */}
    </div>
  );
}
