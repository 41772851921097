import React from 'react';

const ProgressBar = ({ progress }) => {
    return(
        <div>
    <div className='progress-head'><div>1.Sign Up</div><div>2.OTP Verification</div><div>3.On Boarding</div></div>
      
    <div style={{ width: '100%', backgroundColor: '#f0f0f0',margin:"20px" }}>
      <div style={{ width: `${progress}%`, backgroundColor: '#8653B6', height: '10px',borderRadius:"4px" }}></div>
    </div>
  
        </div>
    )
}
export default ProgressBar;