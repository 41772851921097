import * as React from "react";
import { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { DataGrid } from "@mui/x-data-grid";

const UserList = ({ totalCount, newCount, data,yesterday }) => {
  const [filterDate, setFilterDate] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const formatDate = (date) => {
    const currentDate = new Date(date);
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  useEffect(() => {
    if (filterDate) {
      const filtered = data.filter((item) => {
        const itemDate = new Date(item?.createdDate).toLocaleDateString(
          "en-US"
        );
        return itemDate === new Date(filterDate).toLocaleDateString("en-US");
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [filterDate, data]);

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 200,
    },
    {
      field: "lastName",
      headerName: "First Name",
      width: 200,
    },

    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "createdDate",
      headerName: "Date",
      width: 200,
      renderCell: (params) => <div>{formatDate(params.value)}</div>,
    },
  ];

  return (
    <div className="interview-list-container interview-lists-cont">
      <div>
        <div className="date-filter">
          <input
            type="date"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
          />
        </div>
        <DataGrid
          sx={{
            height: "50vh",
            borderRadius: "10px",
            color: "#49454F",
            fontSize: "14px",
            width: "75%%",
            marginLeft: "3rem",
          }}
          md={{ color: "#49454F", fontSize: "14px" }}
          getRowId={(row) => row._id}
          rows={filteredData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50]}
        />
      </div>
      <div className="pie-ch">
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: totalCount, label: "Total User" },
                { id: 1, value: newCount, label: "New User" },
                { id: 2, value: yesterday, label: "Yesterday User" },


              ],
            },
          ]}
          width={450}
          height={200}
        />
      </div>
    </div>
  );
};

export default UserList;
