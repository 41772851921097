import React from "react";
import styled from "styled-components";
import Profile from "../../images/profile.png";
import Clock from "../../images/Clock Circle.svg";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";

const Wrapper = styled.div`
    display:flex;
    justify-content:space-between;
`
const WrapperOne = styled.div`
    display:flex;
    gap:20px;
    margin:10px 0;
`
const ProfileName = styled.div`
color: #201B25;
font-family: Inter-Medium,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: normal;
line-height: normal;

span{
color: #4F4953;
font-family: Inter-Medium,sans-serif;
font-size: 12px;
font-style: normal;
font-weight: normal;
line-height: normal;
}
`
const AllResults = styled.div`
color: #C386FD;
font-family: Inter-Medium,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: normal;
line-height: normal;
text-align:center;
margin:20px;
`
const RecentSearch = () => {
    const history = useHistory();
    return(
        <div>
            <Wrapper>
                <WrapperOne>
                <div>
                    <img src={Clock} alt="img" />
                </div>
                <ProfileName>
                    Marvin
                </ProfileName>
                </WrapperOne>
                <div>
                    <img src={Profile} alt="img" style={{width:"28px",margin:"5px 0"}}/>
                </div>
            </Wrapper>
            <Wrapper>
                <WrapperOne>
                <div>
                    <img src={Clock} alt="img" />
                </div>
                <ProfileName>
                marwyn samuel  <span>  1st   Appian BPM Developer </span>
                </ProfileName>
                </WrapperOne>
                <div>
                    <img src={Profile} alt="img" style={{width:"28px",margin:"5px 0"}}/>
                </div>
            </Wrapper>
            <Wrapper>
                <WrapperOne>
                <div>
                    <img src={Clock} alt="img" />
                </div>
                <ProfileName>
                marwyn allen  <span>  3rd+     Cyber Threat Analyst at Truist | SEC...</span>
                </ProfileName>
                </WrapperOne>
                <div>
                    <img src={Profile} alt="img" style={{width:"28px",margin:"5px 0"}}/>
                </div>
            </Wrapper>
            <Wrapper>
                <WrapperOne>
                <div>
                    <img src={Clock} alt="img" />
                </div>
                <ProfileName>
                marwyn allen  <span>  3rd+     Cyber Threat Analyst at Truist | SEC...</span>
                </ProfileName>
                </WrapperOne>
                <div>
                    <img src={Profile} alt="img" style={{width:"28px",margin:"5px 0"}}/>
                </div>
            </Wrapper>

            <AllResults onClick={()=>history.push("./allpages")}>See All Results</AllResults>
        </div>
    )
}

export default RecentSearch; 