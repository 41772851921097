/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import "../../styles/myprofile.css";
import Form from "react-bootstrap/Form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import Select, { components } from "react-select";
import Common from "../../utils/common";
import styled from "styled-components";
import CloseIcon from "../../images/CloseIcon.svg";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "react-bootstrap/Spinner";
import CreatableSelect from "react-select/creatable";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import Back from "../../images/Back.svg";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import { toast } from "react-toastify";

const JobTitle = styled.div`
  width: auto;
  // height: 22px;
  top: 24px;
  left: 0px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #40444f;
  margin-left: 10px;

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
    // margin: -15px 0 0 0;
  }
`;
const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    gap: 4px;
  }
`;
const Button4 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  color: #2d3139;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px;
  width: 94px;
  height: 40px;
  // position: relative;
  // top: 3px;
  cursor: pointer;
  @media (max-width: 576px) {
    width: 65px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;
  // position: relative;
  // top: 3px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;

  cursor: pointer;
  @media (max-width: 576px) {
    width: 85px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
    fontweight: "normal",
    color: "",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 8px)",
      marginLeft: "4px",
    },
  }),
  // Add more styles as needed
};
const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    minHeight: "48px",
    height: "fit-content",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
    fontweight: "normal",
    color: "#4F4953",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};

const ButtonLink = styled.div`
  width: 116px;
  height: 40px;
  border-radius: 8px;
  // padding: 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid rgba(221, 223, 228, 0.5);
  // gap: 8px;
  color: white;
  cursor: pointer;
  // margin-right: 4px;
  display: flex;
  gap: 5px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 14.4px */
  text-transform: capitalize;
`;

const options2 = [
  { value: "Hybrid", label: "Hybrid" },
  { value: "Onsite", label: "Onsite" },
  { value: "Remote", label: "Remote" },
];
const options3 = [
  { value: "Internship", label: "Internship" },
  { value: "PartTime", label: "PartTime" },
  { value: "FullTime", label: "FullTime" },
  { value: "Temporary", label: "Temporary" },
  { value: "Contract", label: "Contract" },
  { value: "Freelance", label: "Freelance" },
];

const ExperienceModal = ({ onClose, expId, onSave, userType }) => {
  const { state } = useLocation();
  const expMobile = state?.expMobile;
  const expIds = state?.expIds;
  const [closemodal, setCloseModal] = useState(false);
  const [popupopen, setPopupOpen] = useState(false);
  const [experience, setExperience] = useState({
    company: "",
    designation: "",
    industry: "",
    description: "",
    startDate: "",
    endDate: "",
    employeeType: "",
    location: "",
    workMode: "",
  });
  const [skill, setSkill] = useState([]);
  const [error2, setError2] = useState({
    company: "",
    designation: "",
    industry: "",
    startDate: "",
    endDate: "",
    employeeType: "",
    location: "",
    workMode: "",
    skill: "",
  });

  const [textAreaCount, setTextAreaCount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(true);
  const handleSkillChange = (selectedOptions) => {
    const formattedSkills = selectedOptions.map((e) => ({
      skillIds: e.skillIds,
      label: e.label,
      value: e.value,
      ratings: e.ratings,
      averageSkillRating: e.averageSkillRating,
      candidateId: Common.loggedIn(),
    }));

    setSkill(formattedSkills);
    setSkillCreate("");
    setOptionsOne([]);
    setDirty(true); // Not sure where setDirty comes from in your code
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setExperience((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
    setDirty(true);
  };

  const validateInput = (e) => {
    let { name, value } = e.target || e;
    setError2((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          if (value === undefined || value === "") {
            stateObj[name] = "Please enter the company name";
          }
          break;

        case "designation":
          if (value === undefined || value === "") {
            stateObj[name] = "Please enter the designation";
          }

          break;

        case "industry":
          if (value === undefined || value === "") {
            stateObj[name] = "Please enter the company industry";
          }
          break;

        case "startDate":
          if (experience.startDate === "") {
            stateObj[name] = "Please enter start date";
          }
          break;
        case "endDate":
          if (experience.endDate === "" || undefined) {
            stateObj[name] = "";
          } else if (experience.endDate < experience.startDate) {
            stateObj[name] = "End date can't be earlier than start date ";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };
  const [description, setDescription] = useState({
    description: "",
  });
  const quillRef = useRef();
  const handleJobDescChange = (content, delta, source, editor) => {
    if (content.length <= 1000) {
      setDescription({ ...description, description: content });
      setDirty(true);
      setTextAreaCount(content.length);
    } else {
      console.error("Input exceeds 1000 characters");
      // If the input exceeds the limit, you may want to prevent further input or notify the user.
      // Additionally, you can handle the case where a user tries to paste content that exceeds the limit.
    }
  };

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    if (quill) {
      const updateTextAreaCount = () => {
        setTextAreaCount(quill.getLength());
      };
      quill.on("text-change", updateTextAreaCount);
      return () => {
        quill.off("text-change", updateTextAreaCount);
      };
    }
  }, []);
  const [dirty, setDirty] = useState(false);

  const history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const [location, setLocation] = useState("");

  const experienceCreate = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const errors2 = {};

    if (!experience.company) errors2.company = "Please enter the company name";
    if (!experience.industry) errors2.industry = "Please select industry";
    if (!experience.designation)
      errors2.designation = "Please select designation";
    if (!experience.startDate) errors2.startDate = "Please select start date";
    // Add similar checks for other required fields
    if (experience.endDate) {
      if (experience.endDate < experience.startDate)
        errors2.endDate = "End Date Should be Greater than Start Date";
    }
    if (Object.keys(errors2).length === 0) {
      try {
        const params = {
          skill: skill,
          company: experience.company,
          designation: experience.designation,
          industry: experience.industry,
          description: description.description,
          startDate: experience.startDate,
          endDate: experience.endDate,
          employeeType: experience.employeeType,
          location: location,
          workMode: experience.workMode,
          deleteFlag: "N",
          isChecked: isChecked,
          userId: Common.loggedIn(),
        };

        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/experience-create`,
          params
        );

        if (response.data.success) {
          setExperience({
            ...experience,
            designation: "",
            industry: "",
            startDate: "",
            endDate: "",
            employeeType: "",
            location: "",
            workMode: "",
            company: "",
          });
          if (expMobile) {
            history.push("/portal/my-profile");
          } else {
            onClose();
          }
        }
      } catch (err) {
        if (expMobile) {
          toast.error(err.response?.data?.message || "An error occurred");
        } else {
          toast.error(err.response?.data?.message || "An error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setError2(errors2);
      setIsLoading(false); // Set loading to false if there are errors
    }
  };
  const experienceUpdate = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const errors2 = {};

    if (!experience.company) errors2.company = "Please enter the company name";
    if (!experience.industry) errors2.industry = "Please select industry";
    if (!experience.designation)
      errors2.designation = "Please select designation";
    if (!experience.startDate) errors2.startDate = "Please select start date";
    // Add similar checks for other required fields
    if (experience.endDate) {
      if (experience.endDate < experience.startDate)
        errors2.endDate = "End Date Should be Greater than Start Date";
    }
    if (Object.keys(errors2).length === 0) {
      try {
        const params = {
          skill: skill,
          company: experience.company,
          designation: experience.designation,
          industry: experience.industry,
          description: description.description,
          startDate: experience.startDate,
          endDate: experience.endDate,
          employeeType: experience.employeeType,
          location: location,
          workMode: experience.workMode,
          deleteFlag: "N",
          isChecked: isChecked,
          userId: Common.loggedIn(),
        };

        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/update-experience/${experience.id}`,
          params
        );

        if (response.data.success) {
          setExperience({
            ...experience,
            designation: "",
            industry: "",
            startDate: "",
            endDate: "",
            employeeType: "",
            location: "",
            workMode: "",
            company: "",
          });
          if (expMobile) {
            history.push("/portal/my-profile");
          } else {
            onClose();
          }
        }
      } catch (err) {
        if (expMobile) {
          toast.error(err.response?.data?.message || "An error occurred");
        } else {
          toast.error(err.response?.data?.message || "An error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setError2(errors2);
      setIsLoading(false); // Set loading to false if there are errors
    }
  };

  const prefillValue = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-experience-detail/${id}`
      );

      if (response && response.data && response.data.success) {
        if (
          response.data.experienceOne.skill &&
          response.data.experienceOne.skill.length > 0
        ) {
          setSkill(response.data.experienceOne.skill);
        }
        setExperience({
          ...experience,
          userId: response.data.experienceOne.userId,
          id: response.data.experienceOne._id,
          designation: response.data.experienceOne.designation,
          startDate: response.data.experienceOne.startDate,
          endDate: response.data.experienceOne.endDate,
          employeeType: response.data.experienceOne.employeeType,
          workMode: response.data.experienceOne.workMode,
          industry: response.data.experienceOne.industry,
          company: {
            label: response.data.experienceOne.company.label,
            value: response.data.experienceOne.company.label,
            website: response.data.experienceOne.company.website,
            websites: response.data.experienceOne.company.websites,
            comID: response.data.experienceOne.company.companyId,
            companyLogo: response.data.experienceOne.company.companyLogo,
          },
        });
        setLocation(response.data.experienceOne.location);
        setDescription({
          description: response.data.experienceOne.description,
        });
        setIsChecked(response.data.experienceOne.isChecked);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    if (expId) {
      prefillValue(expId);
    }else if(expIds){
      prefillValue(expIds);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expId]);
  const DiscardChanges = () => {
    setExperience({
      ...experience,
      company: "",
      designation: "",
      industry: "",
      startDate: "",
      endDate: "",
      employeeType: "",
      location: "",
      workMode: "",
    });
    setDescription({ ...description });
    setSkill([...skill]);
    onClose();
    onSave();
  };

  const CloseModalClear = () => {
    if (isMobile) {
      history.push("./my-profile");
    } else {
      if (!dirty) {
        onClose();
        onSave();
      } else {
        setCloseModal(true);
        setPopupOpen(true);
      }
    }
  };

  const handleCompany = (selectedOption) => {
    const value = selectedOption ? selectedOption : "";
    setExperience({ ...experience, company: value });
    setDirty(true);
  };

  const handleIndustry = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    setExperience({ ...experience, industry: value });
    setDirty(true);
  };
  const handleDesignation = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    setExperience({ ...experience, designation: value });
    setDirty(true);
  };
  const handleEmployeeType = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    setExperience({ ...experience, employeeType: value });
    setDirty(true);
  };
  const handleWorkMode = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    setExperience({ ...experience, workMode: value });
    setDirty(true);
  };

  const [companyData, setCompanyData] = useState([]);
  const GetAllCompany = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-company`
      );

      const companyValue = response.data.company.map((e) => ({
        label: e.companyName,
        value: e.companyName,
        website: e.website,
        comID: e.companyId,
        companyLogo: e.companyLogo,
      }));
      setCompanyData(companyValue);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const [insitituteData, setInstitueData] = useState([]);
  const GetAllInstitute = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-institute`
      );
      const companyValue = response.data.company.map((e) => ({
        label: e.collegeName,
        value: e.collegeName,
        websites: e.website,
        comID: e.instituteId,
        companyLogo: e.companyLogo,
      }));
      setInstitueData(companyValue);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    GetAllCompany();
    GetAllInstitute();
  }, []);
  const [organisation, setOrganisation] = useState([]);
  const mergeCompanyAndInstituteData = () => {
    const mergedData = [...companyData, ...insitituteData];
    // Use mergedData as needed (e.g., set state for dropdown options)
    // Example: setDropdownOptions(mergedData);
    setOrganisation(mergedData);
  };
  useEffect(() => {
    mergeCompanyAndInstituteData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, insitituteData]);
  const [skillData, setSkillData] = useState([]);

  const getSkill = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/skills`
      );

      const companyValue = response.data.map((e) => ({
        skillIds: e.skillId,
        label: e.label,
        value: e.value,
        ratings: e.ratings,
        averageSkillRating: e.averageSkillRating,
      }));

      setSkillData(companyValue);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const optionsOne = skillData.map((skill) => ({
    value: skill,
    label: skill,
  }));
  useEffect(() => {
    getSkill();
  }, []);

  const [skillcreate, setSkillCreate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [rating, setRating] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (skillcreate.length > 2) {
      setOptionsOne(
        skillData.map((e) => ({
          skillIds: e.skillIds,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
          candidateId: Common.loggedIn(),
        }))
      );
    } else {
      setOptionsOne([]);
    }
  }, [skillData, skillcreate]);

  const createSkill = async () => {
    const payload = {
      name: skillcreate,
      rating: rating,
      userId: [userId],
    };
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/skill`,
        payload
      );

      setSkillCreate("");
      setOptionsOne([]);
      getSkill();
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const [optionData, setOptionsOne] = useState([]);
  const handleCreatable = async (e) => {
    if (e[e?.length - 1]?.__isNew__) {
      await createSkill();
    }
    handleSkillChange(e);
  };
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    setLocation(value);
  };
  const [designaSkill, setDesignSkill] = useState([]);
  const getDesignationSkill = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/getDesignSpec`
      );
      const companyValue = response.data.map((e) => ({
        skillIds: e.skillId,
        label: e.label,
        value: e.value,
      }));

      setDesignSkill(companyValue);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    getDesignationSkill();
  }, []);
  const [designOne, setDesignOne] = useState([]);
  useEffect(() => {
    setDesignOne(
      designaSkill.map((e) => ({
        skillIds: e.skillId,
        label: e.label,
        value: e.value,
      }))
    );
  }, [designaSkill]);
  const [designCreate, setDesignCreate] = useState("");
  const createDesignSkill = async () => {
    const payload = {
      name: designCreate,
    };
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/designationCreate`,
        payload
      );

      setDesignCreate("");
      getDesignationSkill();
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const handleCreatableDesignation = async (selectedOption) => {

    // Check if it's a new option (for single select)
    if (selectedOption && selectedOption.__isNew__) {
      await createDesignSkill();
    }

    // Handle the selected option(s) based on your logic
    handleDesignation(selectedOption);

  };
  const [industrySkill, setIndustrySkill] = useState([]);
  const getIndustrySkill = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/getIndusSpec`
      );
      const companyValue = response.data.map((e) => ({
        skillIds: e.skillId,
        label: e.label,
        value: e.value,
      }));

      setIndustrySkill(companyValue);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    getIndustrySkill();
  }, []);
  const [industryOne, setIndustryOne] = useState([]);
  useEffect(() => {
    setIndustryOne(
      industrySkill.map((e) => ({
        skillIds: e.skillId,
        label: e.label,
        value: e.value,
      }))
    );
  }, [industrySkill]);
  const [industryCreate, setIndustryCreate] = useState("");
  const createIndustrySkill = async () => {
    const payload = {
      name: industryCreate,
    };
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/indusCreate`,
        payload
      );
      setIndustryCreate("");
      getIndustrySkill();
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const handleCreatableIndustry = async (selectedOption) => {

    // Check if it's a new option (for single select)
    if (selectedOption && selectedOption.__isNew__) {
      await createIndustrySkill();
    }

    // Handle the selected option(s) based on your logic
    handleIndustry(selectedOption);

  };

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <></>
      </components.DropdownIndicator>
    );
  };
  // useEffect(() => {
  //   if (popupopen === false) {
  //     document.body.style.overflow = "hidden";
  //   } else document.body.style.overflow = "auto";
  // }, [popupopen]);
  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);

    // Reset error message when checkbox is toggled
    setError2((prevError) => ({
      ...prevError,
      endDate: "",
    }));
  };
  const isMobile = window.innerWidth < 768;
  return (
    <div className="">
      {popupopen ? (
        <Dialog
          open={closemodal}
          PaperProps={{
            style: {
              borderRadius: "16px",
              top: "10px",
              transform: "translateY(0%)",
              position: "absolute",
              backgroundColor: "#FFDAE0",
              padding: "5px 8px",
              height: "fit-content",
            },
          }}
          halfWidth
          maxWidth="md"
          className="def"
        >
          <DialogContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium",
              position: "relative",
              left: "5px",
              // top: "-10px",
            }}
          >
            <IconWrapper>
              <img
                src={DeleteImg}
                alt="img"
                style={{ width: "24px", height: "24px" }}
              />
            </IconWrapper>
            <JobTitle>
              Are you sure you want to discard the changes you made?
            </JobTitle>
            <DialogActions>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  gap: "10px",
                  marginLeft: "2rem",
                }}
              >
                <Button4
                  onClick={() => {
                    setCloseModal(false);
                    setPopupOpen(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Go Back
                </Button4>
                <Button6 onClick={DiscardChanges}>Yes, Discard</Button6>
              </div>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : (
        <div>
          <div className={isMobile ? "modHeader" : "modalHeader"}>
            {isMobile ? (
              <img src={Back} alt="img" onClick={CloseModalClear} />
            ) : (
              ""
            )}
            <h1 className="EduPopup-head" style={{ marginTop: "10px" }}>
              Add Experience
            </h1>
            {isMobile ? (
              ""
            ) : (
              <img
                src={CloseIcon}
                className="mClose"
                alt="img"
                onClick={CloseModalClear}
              />
            )}
          </div>
          <div className={isMobile ? "mbody" : "modalBody"}>
            <div className="Edu-Popup-wrapper" style={{ marginTop: "-15px" }}>
              <div className="input-space">
                <label className="label-name">
                  Company Name<span>*</span>
                </label>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    gap: " 20px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <CreatableSelect
                      placeholder="Type / search"
                      components={{ Placeholder, DropdownIndicator }}
                      styles={customStyles}
                      className="footer-header"
                      name="company"
                      onChange={handleCompany}
                      onInputChange={(e) => setDesignCreate(e)}
                      options={organisation}
                      value={experience.company}
                      onBlur={() =>
                        validateInput({
                          target: {
                            name: "company",
                            value: experience.company,
                          },
                        })
                      }
                    />

                    {error2.company && (
                      <div style={{ marginTop: "5px" }}>
                        <span className="f12">{error2.company}</span>
                      </div>
                    )}
                  </div>
                  {userType ? (
                    <div>
                      <ButtonLink
                        onClick={() => history.push("/portal/company")}
                      >
                        Add Company
                      </ButtonLink>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="input-space">
                <label className="label-name">
                  Designation<span>*</span>
                </label>
                <div>
                  <CreatableSelect
                    placeholder="Type / search"
                    components={{ Placeholder, DropdownIndicator }}
                    styles={customStyles}
                    className="footer-header"
                    name="designation"
                    onChange={(e) => handleCreatableDesignation(e)}
                    onInputChange={(e) => setDesignCreate(e)}
                    options={designOne}
                    value={designOne.find(
                      (option) => option.value === experience.designation
                    )}
                    onBlur={() =>
                      validateInput({
                        target: {
                          name: "designation",
                          value: experience.designation,
                        },
                      })
                    }
                  />
                  {error2.designation && (
                    <div style={{ marginTop: "5px" }}>
                      <span className="f12">{error2.designation}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className="Edu-Popup-wrapper"
              style={{ gap: "10px", width: "100%" }}
            >
              <div className="input-space">
                <label className="label-name">
                  Industry<span>*</span>
                </label>
                <div>
                  <CreatableSelect
                    styles={customStyles}
                    components={{ Placeholder, DropdownIndicator }}
                    className="footer-header"
                    name="industry"
                    placeholder="Type / Search"
                    style={{
                      color: "rgba(198, 202, 210)",
                      fontweight: "normal",
                    }}
                    onChange={(e) => handleCreatableIndustry(e)}
                    onInputChange={(e) => setIndustryCreate(e)}
                    options={industryOne}
                    value={industryOne.find(
                      (option) => option.value === experience.industry
                    )}
                    onBlur={() =>
                      validateInput({
                        target: {
                          name: "industry",
                          value: experience.industry,
                        },
                      })
                    }
                  />
                  {error2.industry && (
                    <div style={{ marginTop: "5px" }}>
                      <span className="f12">{error2.industry}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="input-space">
                <label className="label-name">Employment Type</label>
                <div>
                  <Select
                    isSearchable
                    components={{ Placeholder, DropdownIndicator }}
                    name="employeeType"
                    options={options3}
                    value={options3.find(
                      (option) => option.value === experience.employeeType
                    )}
                    onChange={handleEmployeeType}
                    placeholder="Type / Search"
                    styles={customStyles}
                    className="footer-header  mb-3"
                  />
                  {error2.employeeType && (
                    <span className="text-danger">{error2.employeeType}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="Edu-Popup-wrapper">
              <div className="input-space">
                <label className="label-name">Location</label>
                <div>
                  <PlacesAutocomplete
                    value={location}
                    onChange={setLocation}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Type / Search",
                            className: "location-search-input",
                          })}
                          style={{
                            width: "100%",
                            paddingLeft: "10px",
                            fontSize: "12px",
                          }}
                          className="footer-header mb-3"
                          styles={{
                            height: "40px",
                            control: (base, state) => ({
                              ...base,
                              width: "100%", // Set the width to 100%
                              backgroundColor: "#F4F4F6",
                              padding: "10px",
                              border: "1px solid #F4F4F6",
                              borderRadius: "8px",
                              fontSize: "12px",
                            }),
                          }}
                        />
                        <div
                          className="autocomplete-dropdown-container"
                          style={{ width: "100%" }}
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fff",
                                  cursor: "pointer",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  fontSize: "12px",
                                  color: "#FFF",
                                  borderRadius: "0px",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  height: "fit-content",
                                  marginTop: "10px",
                                  cursor: "pointer",
                                  fontSize: "12px",
                                  color: "#362A41",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span
                                  style={{
                                    color: "#362A41", // Add hover text color
                                    padding: "5px",
                                    background: "#C6C6C6",
                                    borderRadius: "8px",
                                    width: "calc(100% - 6px)",
                                    height: "fit-content",
                                    margin: "0px 0px 0px 0px",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {suggestion.description}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
              <div className="input-space">
                <label className="label-name">Work mode</label>
                <div>
                  <Select
                    className="footer-header  mb-3"
                    components={{ Placeholder, DropdownIndicator }}
                    isSearchable
                    name="workMode"
                    options={options2}
                    value={options2.find(
                      (option) => option.value === experience.workMode
                    )}
                    onChange={handleWorkMode}
                    placeholder="Type / Search"
                    styles={customStyles}
                  />
                  {error2.workMode && (
                    <span className="text-danger">{error2.workMode}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="Edu-Popup-wrapper">
              <div className="input-space">
                <label className="label-name">Skills</label>
                <div>
                  <CreatableSelect
                    isMulti
                    components={{ Placeholder, DropdownIndicator }}
                    styles={customStyle}
                    name="skill"
                    placeholder="Select"
                    style={{
                      color: "rgba(198, 202, 210)",
                      fontweight: "normal",
                    }}
                    onChange={(e) => handleCreatable(e)}
                    onInputChange={(e) => setSkillCreate(e)}
                    options={optionData}
                    noOptionsMessage={() => null}
                    isValidNewOption={() => optionData.length}
                    value={skill}
                    isClearable={true}
                  />
                  <div className="Edu-data-wrapper EduType"></div>
                  {error2.skill && (
                    <span className="text-danger">{error2.skill}</span>
                  )}
                </div>
              </div>
              <div
                className="myprofile-content-head input-space"
                style={{ marginLeft: "0px" }}
              >
                <div className="pro-fr">
                  <label className="label-name">
                    From<span>*</span>
                  </label>
                  <InputGroup className="footer-header">
                    <Form.Control
                      type="date"
                      placeholder="YYYY"
                      aria-label="YYYY"
                      name="startDate"
                      onChange={handleChange}
                      onBlur={validateInput}
                      value={experience.startDate}
                      aria-describedby="basic-addon1"
                      style={{
                        width: "50%",
                        fontSize: "12px",
                      }}
                      className="footer-header"
                    />
                  </InputGroup>
                  {error2.startDate && (
                    <div style={{ marginTop: "5px" }}>
                      <span className="text-danger f12">
                        {error2.startDate}
                      </span>
                    </div>
                  )}
                </div>

                <div
                  className="pro-fr"
                  style={{ opacity: isChecked ? 0.5 : 1 }}
                >
                  <label className="label-name">To</label>
                  <InputGroup className="mb-1">
                    <Form.Control
                      type="date"
                      placeholder="YYYY"
                      aria-label="YYYY"
                      name="endDate"
                      onChange={handleChange}
                      onBlur={validateInput}
                      value={experience.endDate}
                      aria-describedby="basic-addon1"
                      className="footer-header"
                      disabled={isChecked}
                    />
                  </InputGroup>
                  {error2.endDate && (
                    <span className="text-danger f12">{error2.endDate}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="Edu-Popup-wrapper">
              <div className="myprofile-content-head"></div>
              <div className=" myprofile-content-head">
                <div className="btn-head-wrapper">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div className="hover-content">I am currently working</div>
                </div>
              </div>
            </div>
            <div class="mx-3">
              <label
                for="exampleFormControlTextarea1"
                class="form-label label-name"
              >
                Description
              </label>

              <ReactQuill
                theme="snow" // You can choose different themes like 'snow', 'bubble', etc.
                value={description.description}
                onChange={handleJobDescChange}
                ref={quillRef}
              />
              <div>
                <p
                  style={{
                    fontFamily: "Inter-Medium,sans-serif",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  {`${Math.max(textAreaCount - 1, 0)}/1000`}
                </p>
              </div>
            </div>
          </div>
          <div className="modalFooter">
            <button className="button5" onClick={CloseModalClear}>
              Cancel
            </button>
            {expId || expIds ? (
              <button className="button3" onClick={(e) => experienceUpdate(e)}>
                {loading ? <Spinner /> : "Update"}
              </button>
            ) : (
              <button className="button3" onClick={(e) => experienceCreate(e)}>
                {loading ? <Spinner /> : "Save"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExperienceModal;
