import React from "react";
import styled from "styled-components";
import Profile from "../../images/profile.png";
import { AiOutlineCloseCircle } from "react-icons/ai";

const Header = styled.div`
    display:flex;
    justify-content: space-between;
`
const HeaderOne = styled.div`
    display:flex;
    justify-content:center;
    color: #4F4953;
    font-family: Inter-Medium,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
`
const HeaderTwo = styled.div`
color: #4F4953;
font-family: Inter-Medium,sans-serif;
font-size: 16px;
font-style: normal;
font-weight: normal;
line-height: normal;
padding:20px 0;
`
const HeaderThree = styled.div`
color: #A8A1AC;
font-family: Inter-Medium,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: normal;
line-height: normal;
padding:20px 0;
`
const RecentOne = styled.div`
    background:#F5F9FC;
    border-radius:16px;
    padding:20px;
`
const ImageWrapper = styled.div`
    background:#FFF;
    border-radius:8px;
    padding:10px 50px;
    margin:10px 0;
`
const ProfileName = styled.div`
    margin:10px 0;
    overflow: hidden;
    color: #202020;
    text-align: center;
    text-overflow: ellipsis;
    font-family: Inter-Medium,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
`
const ProfileNames = styled.div`
    color: #656565;
    font-family: Inter-Medium,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    margin:10px 0;
`
const CloseIcon = styled.div`
    margin:10px 0;
`
const RecentSearch = () => {
    return(
        <Header>
            
            <RecentOne>
            <HeaderOne>Recently viewed profile</HeaderOne>
            <ImageWrapper>
                <img src={Profile} alt="img" style={{width:"90px"}}/>
                <ProfileName>Angie Wong</ProfileName>
            </ImageWrapper>
            <ImageWrapper>
                <img src={Profile} alt="img" style={{width:"90px"}}/>
                <ProfileName>Angie Wong</ProfileName>
            </ImageWrapper>
            <ImageWrapper>
                <img src={Profile} alt="img" style={{width:"90px"}}/>
                <ProfileName>Angie Wong</ProfileName>
            </ImageWrapper>
            </RecentOne>

            <div>
            <HeaderTwo>Recent Search</HeaderTwo>
            <Header>
                <ProfileNames>Sam</ProfileNames>
            </Header>
            <Header>
                <ProfileNames>Karnesh</ProfileNames>
            </Header>
            <Header>
                <ProfileNames>Ramesh</ProfileNames>
            </Header>
            <Header>
                <ProfileNames>Product Manager</ProfileNames>
            </Header>
            </div>

            <div>
            <HeaderThree>Clear Search</HeaderThree>
            <Header>
                <div></div>
                <CloseIcon><AiOutlineCloseCircle  /></CloseIcon>
            </Header>
            <Header>
                <div></div>
                <CloseIcon><AiOutlineCloseCircle  /></CloseIcon>
            </Header>
            <Header>
                <div></div>
                <CloseIcon><AiOutlineCloseCircle  /></CloseIcon>
            </Header>
            <Header>
                <div></div>
                <CloseIcon><AiOutlineCloseCircle  /></CloseIcon>
            </Header>
            </div>
        </Header>
    )
}

export default RecentSearch; 