/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Common from "../utils/common";
import { Amplify, Auth } from "aws-amplify";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import iSearch from "../images/icons/search.svg";
import iSettings from "../images/icons/settings.svg";
import "../styles/dh-style.css";
import Back from "../images/Back.svg";
import { useLocation } from "react-router-dom";
import ProfileHeader from "./profileHeader";
import { DialogContent, DialogTitle } from "@mui/material";
import MessageChat from "./conversation/message";
import Conversation from "./conversation/conversation";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import imageAvatar from "../images/company.png";
import CloseIcon from "../images/CloseIcon.svg";
import ChatMain from "./conversation/chatMain";
import { CompositionListInstance } from "twilio/lib/rest/video/v1/composition";
import HamburgerMenu from "./hamburger";
import Notification from "./notification/notification";
import SearchMessage from "./conversation/searchMessage";
import { FaRegPenToSquare } from "react-icons/fa6";
import companyLogo from "../images/profile-image.svg";
import { io } from "socket.io-client";

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  img {
    cursor: pointer;
    margin-top: -5px;
  }
  @media (min-width: 768px) and (max-width: 820px) {
    margin-left: 55px;
    max-width: 150px;
  }
`;
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});

const DesktopHeader = ({
  title,
  onFieldChange,
  isSearchActive = true,
  backbtn,
  back,
  searchVal,
  searchedKey,
  onNavaigation,
  onFalse,
  otherProfile,
  id,
  profilepic,
}) => {
  const history = useHistory();
  const [user, setUsers] = useState("");
  const [success, setSuccess] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [messageChats, setMessageChats] = useState(null);
  const [messageChat, setMessageChat] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [conversation, setConversation] = useState([]);
  const [openConver, setOpenConver] = useState(false);
  const [count, setCount] = useState(0);
  const [detail, setDetail] = useState();
  const ref = useRef(null);
  const userId = Common.loggedIn();
  const [hideMessageButton, setHideMessageButton] = useState(false);
  const [conversationsId, setConversationId] = useState(null);
  const isTab = window.innerWidth < 1200;
  const [jobCount, setJobCount] = useState(0);
  const [requestDataCount, setRequestDataCount] = useState(0);
  const [statusCount, setStatusCount] = useState(0);
  const [rejectCount, setRejectCount] = useState(0);
  const [offeredCount, setOfferedCount] = useState(0);
  const [declineCount, setDeclineCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [dataNotification, setDataNotification] = useState([]);
  const [rejectNotification, setRejectNotification] = useState([]);
  const [offeredNotification, setOfferedNotification] = useState([]);
  const [socialRead, setSocialRead] = useState([]);
  const [requestData, setRequestData] = useState([]);

  const [pendingCount, setPendingCount] = useState(0);
  const [requestCount, setRequestCount] = useState(0);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      } else {
        setDropdown(true);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setUsers(null);
      });
  }, []);

  useEffect(() => {}, [searchValue]);

  const logout = () => {
    Common.logout();
    localStorage.removeItem("bannerOpened");
  };
  const location = useLocation();

  const onInputChange = (event) => {
    setSearchValue(event.target.value);
    searchedKey(event.target.value);
  };

  const handleKeyPress = (event) => {
    const alphanumericRegex = /^[a-zA-Z0-9]$/;

    // Check if Ctrl + F (or Command + F on macOS) is pressed
    if ((event.ctrlKey || event.metaKey) && event.key === "f") {
      // Prevent default browser behavior (opening find dialog)
      event.preventDefault();
      return;
    }

    // Check if input is empty and pathname is not /portal/search
    if (
      location.pathname !== "/portal/search" &&
      alphanumericRegex.test(event.key) &&
      !searchValue.trim()
    ) {
      // Check if input value is not empty
      history.push({ pathname: "/portal/search" });
    }
  };

  function onSubmit2() {
    setSuccess(true);
  }
  ///chat part
  const [openMessage, setOpenMessage] = useState(false);
  const [openChat, setOpenChat] = useState(false);

  ///need this code // after prod
  const handleMessage = () => {
    setOpenMessage(true);
  };

  const handleChat = async (c) => {
    setOpenChat(true);
    setMessageChats(c);
    await handleMessagesClick(c);
    setOpenMessage(false);
  };
  const socket = useRef();

  // const socket = io("ws://localhost:4000");
  // const socket = io("wss://test-staging.thexakal.com")

  const fetchConversations = async () => {
    try {
      const conversationRes = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/conversation/${Common.loggedIn()}`
      );

      const conversations = conversationRes.data.conver;

      const conversationsWithMessages = await Promise.all(
        conversations.map(async (c) => {
          const messageRes = await axios.get(
            `${process.env.PUBLIC_URL}/xakal/message/${c._id}`
          );

          const sortedMessages = messageRes.data.message.sort(
            (a, b) =>
              new Date(b.timestamps.createdAt) -
              new Date(a.timestamps.createdAt)
          );

          const unreadCount = sortedMessages.filter(
            (msg) => !msg.read && msg.sender !== Common.loggedIn()
          ).length;

          return {
            ...c,
            messages: sortedMessages,
            unreadCount,
          };
        })
      );
      const filteredConversations = conversationsWithMessages.filter(
        (convo) => convo.messages.length > 0
      );
      const sortedConversations = filteredConversations.sort((a, b) => {
        const latestMessageA = a.messages[0];
        const latestMessageB = b.messages[0];

        if (!latestMessageA) return 1;
        if (!latestMessageB) return -1;

        return (
          new Date(latestMessageB.timestamps.createdAt) -
          new Date(latestMessageA.timestamps.createdAt)
        );
      });

      setConversation(sortedConversations);

      const totalUnreadCount = sortedConversations.reduce(
        (total, convo) => total + convo.unreadCount,
        0
      );
      setCount(totalUnreadCount);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    // socket.current = io("ws://localhost:4000");
    socket.current = io(process.env.PUBLIC_URL);
    // Fetch initial conversations when component loads
    fetchConversations();

    // Listen for real-time conversation updates
    socket.current.on("newConversation", (newConversation) => {
      console.log("New conversation update received:", newConversation);

      const fetchMessagesForNewConversation = async () => {
        try {
          const messageRes = await axios.get(
            `${process.env.PUBLIC_URL}/xakal/message/${newConversation._id}`
          );

          const sortedMessages = messageRes.data.message.sort(
            (a, b) =>
              new Date(b.timestamps.createdAt) -
              new Date(a.timestamps.createdAt)
          );

          const unreadCount = sortedMessages.filter(
            (msg) => !msg.read && msg.sender !== Common.loggedIn()
          ).length;

          const updatedConversation = {
            ...newConversation,
            messages: sortedMessages,
            unreadCount,
          };

          setConversation((prevConversations) => {
            // Check if the conversation already exists
            const existingConversation = prevConversations.find(
              (conv) => conv._id === updatedConversation._id
            );

            let updatedConversations;

            if (existingConversation) {
              // Replace the existing conversation with the updated one
              updatedConversations = prevConversations.map((conv) =>
                conv._id === updatedConversation._id
                  ? updatedConversation
                  : conv
              );
            } else {
              // Add new conversation to the list
              updatedConversations = [
                updatedConversation,
                ...prevConversations, // Add to the beginning of the list
              ];
            }

            // Sort conversations by the timestamp of the latest message (descending)
            updatedConversations.sort((a, b) => {
              const latestMessageA = a.messages[0]; // Latest message in conversation A
              const latestMessageB = b.messages[0]; // Latest message in conversation B

              if (!latestMessageA) return 1; // If A has no messages, place it after B
              if (!latestMessageB) return -1; // If B has no messages, place it after A

              return (
                new Date(latestMessageB.timestamps.createdAt) -
                new Date(latestMessageA.timestamps.createdAt)
              );
            });

            return updatedConversations;
          });

          setCount((prevCount) => prevCount + 1);
        } catch (err) {
          console.log("Error fetching messages for the new conversation:", err);
        }
      };

      // Fetch the messages and update the conversation state
      fetchMessagesForNewConversation();
    });

    // Cleanup the socket listener when the component unmounts
    return () => {
      socket.current.off("newConversation");
    };
  }, []);

  // Empty dependency array means this effect runs once after initial render

  ///message chat

  const getAllMessage = async (messageChats) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/message/${messageChats}`
      );
      setMessageChat(res.data.message);
      const unreadCounts = res.data.message.filter(
        (msg) => !msg.read && msg.sender === friendId
      ).length;
      // Update state with the unread message count for this conversation
      setCount(unreadCounts);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    if (messageChats) {
      getAllMessage(messageChats?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageChats?._id]);
  const userIds = Common.loggedIn();
  const handleSendMessage = () => {
    setOpenConver(true);
  };
  const [firstName, setFirstName] = useState("");

  const [avatar, setAvatar] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${userIds}`
      );

      const firstName = response.data.aboutOne?.firstName;
      const lastName = response.data.aboutOne?.lastName;
      const middleName = response.data.aboutOne?.middleName
        ? response.data.aboutOne?.middleName
        : "";

      const fullName = `${firstName} ${middleName} ${lastName}`;
      setFirstName(firstName);

      setAvatar(response.data.aboutOne?.avatar);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [avatar, firstName]);

  // Finding friendId from the messageChats members array
  const friendId = messageChats?.members.find((m) => m !== userIds);

  const getUserChat = async (friendId) => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${friendId}`
      );
      console.log(res.data.aboutOne, "res.data.aboutOne");
      setDetail(res.data.aboutOne);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    if (messageChats && friendId) {
      getUserChat(friendId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendId && messageChats]);

  const handleMessagesClick = async (c) => {
    const payload = {
      conversationId: c?._id,
      userId: userIds,
    };
    try {
      // Mark messages as read
      await axios.post(`${process.env.PUBLIC_URL}/xakal/mark-as-read`, payload);

      // Fetch updated messages
      if (c) {
        await getAllMessage(c?._id); // Ensure this function awaits the API call
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    if (otherProfile && conversation?.length > 0) {
      const isMemberInConversation = conversation.some((c) =>
        c.members.includes(id)
      );
      const matchedConversations = conversation.filter((c) =>
        c.members.includes(id)
      );
      setHideMessageButton(isMemberInConversation);
      setConversationId(matchedConversations[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherProfile, conversation]);
  ///notificationCount

  const getAllJob = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-manage-jobs`
      );

      if (response.data) {
        const newJobs = response.data.job;

        const transformedData = newJobs.flatMap((job) => {
          const filteredApplicants = job.applicants.filter(
            (applicant) =>
              applicant.statusOne === "Applied" &&
              applicant.mesRead === "unread"
          );

          setJobCount(filteredApplicants.length);

          return filteredApplicants.map((applicant) => ({
            jobId: job?._id, // Assuming job._id is the jobId
            applicant, // Add the single applicant details
            ...job,
          }));
        });

        setNotifications(transformedData);
      }
    } catch (err) {
      console.error("Error fetching jobs:", err);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    getAllJob();
  }, []);

  // Handle notification click

  const updateBookingServicesStatus = async (bookingIds) => {
    try {
      await Promise.all(
        bookingIds.map((bookingId) =>
          axios.post(
            `${process.env.PUBLIC_URL}/xakal/update-notification-read-status`,
            {
              bookingId: bookingId,
            }
          )
        )
      );
    } catch (error) {
      console.error("Error updating msgRead status:", error);
    }
  };
  const updateBookingServicessStatus = async (bookingIds) => {
    try {
      await Promise.all(
        bookingIds.map((bookingId) =>
          axios.post(`${process.env.PUBLIC_URL}/xakal/decline-read`, {
            bookingId: bookingId,
          })
        )
      );
    } catch (error) {
      console.error("Error updating msgRead status:", error);
    }
  };

  const getRequest = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-request`
      );

      // Collect all confirmed candidates with unread messages
      const allConfirmedCandidates = response.data.interview.flatMap(
        (interview) =>
          interview.candidateDetail.filter(
            (candidate) =>
              candidate.bookingService?.status === "confirmed" &&
              candidate.bookingService?.paid === "paid" &&
              candidate.bookingService?.msgRead === "unread"
          )
      );

      // Use a Set to ensure unique booking IDs
      const bookingIdSet = new Set(
        allConfirmedCandidates.map((candidate) =>
          candidate.bookingService._id.toString()
        )
      );

      // Convert the Set values to an array
      const uniqueBookingIds = Array.from(bookingIdSet);

      // Update state or handle the unique booking IDs as needed
      setRequestDataCount(uniqueBookingIds.length);

      // Fetch calendar events and enrich candidates (existing functionality)
      const calendarResponse = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/events/${Common.loggedIn()}`
      );

      const activeCalendarEvents = calendarResponse.data.filter(
        (event) => event.status === "active"
      );

      const calendarEventMap = activeCalendarEvents.reduce((map, event) => {
        if (!map[event.bookingId]) {
          map[event.bookingId] = [];
        }
        map[event.bookingId].push(event);
        return map;
      }, {});

      const enrichedCandidates = allConfirmedCandidates.map((candidate) => {
        const bookingId = candidate.bookingService._id.toString();
        return {
          ...candidate,
          calendarEvents: calendarEventMap[bookingId] || [],
        };
      });

      // You can return uniqueBookingIds or handle them further as needed
      return uniqueBookingIds;
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  const getAllJobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/job/applied`
      );

      if (response.data) {
        const transformedData = response.data.flatMap((job) => {
          const filteredApplicants = job.applicants.filter(
            (applicant) =>
              applicant.statusOne === "shortlist" &&
              applicant.shortMsgRead === "unread"
          );
          setStatusCount(filteredApplicants.length);

          return filteredApplicants.map((applicant) => ({
            ...job,
            jobId: job?._id,
            applicant,
            applicants: undefined,
          }));
        });

        setDataNotification(transformedData);
      }
    } catch (err) {
      console.error("Error fetching jobs:", err);
    }
  };

  const getAllRejectJobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/job/applied`
      );

      if (response.data) {
        const transformedData = response.data.flatMap((job) => {
          const filteredApplicants = job.applicants.filter(
            (applicant) =>
              applicant.statusOne === "reject" &&
              applicant.rejectMsgRead === "unread"
          );
          setRejectCount(filteredApplicants.length);

          return filteredApplicants.map((applicant) => ({
            ...job,
            jobId: job?._id,
            applicant,
            applicants: undefined,
          }));
        });

        setRejectNotification(transformedData);
      }
    } catch (err) {
      console.error("Error fetching jobs:", err);
    }
  };

  const getAllOfferedJobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/job/applied`
      );

      if (response.data) {
        const transformedData = response.data.flatMap((job) => {
          const filteredApplicants = job.applicants.filter(
            (applicant) =>
              applicant.statusOne === "offered" &&
              applicant.offeredMsgRead === "unread"
          );
          setOfferedCount(filteredApplicants.length);

          return filteredApplicants.map((applicant) => ({
            ...job,
            jobId: job?._id,
            applicant,
            applicants: undefined,
          }));
        });

        setOfferedNotification(transformedData);
      }
    } catch (err) {
      console.error("Error fetching jobs:", err);
    }
  };

  // Debug logs to check data and counts

  const combinedCount =
    jobCount +
    requestDataCount +
    statusCount +
    rejectCount +
    offeredCount +
    declineCount +
    pendingCount +
    requestCount;

  const [combinedData, setCombinedData] = useState();
  const getAllDecline = async () => {
    try {
      // Fetch the list of declined candidates
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-decline`
      );

      // Extract booking services
      const bookingServices = response.data.bookingServices;

      // Filter booking services where declineRead is "unread"
      const uniqueBookingDeclineIds = bookingServices
        .filter((service) => service.declineRead === "unread")
        .map((service) => service._id); // Assuming _id is the bookingId

      // Update the state with the count of unread declines
      setDeclineCount(uniqueBookingDeclineIds.length);

      // Return the array of booking IDs
      return uniqueBookingDeclineIds;
    } catch (error) {
      // Handle errors
      console.error("Error fetching decline data:", error);
      return []; // Return an empty array on error
    } finally {
      // Optionally, handle loading state here
    }
  };

  const getMyInvitations = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      );

      const dt = response?.data?.filter(
        (e) => e.connectionStatus === "PENDING" && e.socialRead === "unread"
      );

      setPendingCount(dt.length);
      setSocialRead(dt);
    } catch (error) {
      // Handle errors
      console.error("Error fetching invitations:", error);
    }
  };

  const getMyRequests = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      );

      const dt = response?.data?.filter(
        (e) => e.connectionStatus === "ASSOCIATE" && e.connectRead === "unread"
      );

      setRequestCount(dt.length);
      setRequestData(dt);
    } catch (error) {
      // Handle errors
      console.error("Error fetching requests:", error);
    }
  };

  useEffect(() => {
    getMyRequests();
  }, []);
  const handleDataNotification = async (dataNotification) => {
    try {
      if (dataNotification && dataNotification.length > 0) {
        await Promise.all(
          dataNotification.map((notification) =>
            axios.post(`${process.env.PUBLIC_URL}/xakal/update-job-status`, {
              jobId: notification.jobId,
            })
          )
        );
      }

      history.push("/portal/notification");
    } catch (error) {
      console.error("Error updating statuses:", error);
    }
  };
  const handleRejectNotification = async (rejectNotification) => {
    try {
      if (rejectNotification && rejectNotification.length > 0) {
        await Promise.all(
          rejectNotification.map((notification) =>
            axios.post(`${process.env.PUBLIC_URL}/xakal/update-reject-status`, {
              jobId: notification.jobId,
            })
          )
        );
      }

      history.push("/portal/notification");
    } catch (error) {
      console.error("Error updating statuses:", error);
    }
  };
  const handleOfferedUpdates = async (offeredNotification) => {
    try {
      if (offeredNotification && offeredNotification.length > 0) {
        await Promise.all(
          offeredNotification.map((notification) =>
            axios.post(
              `${process.env.PUBLIC_URL}/xakal/update-offered-status`,
              {
                jobId: notification.jobId,
              }
            )
          )
        );
      }

      history.push("/portal/notification");
    } catch (error) {
      console.error("Error updating statuses:", error);
    }
  };
  const handleNotificationUpdates = async (notifications) => {
    try {
      if (notifications && notifications.length > 0) {
        await Promise.all(
          notifications.map((notification) =>
            axios.post(`${process.env.PUBLIC_URL}/xakal/update-the-read`, {
              jobId: notification.jobId,
            })
          )
        );
      }

      history.push("/portal/notification");
    } catch (error) {
      console.error("Error updating statuses:", error);
    }
  };
  const handlesocialUpdates = async (socialRead) => {
    try {
      if (socialRead && socialRead.length > 0) {
        await Promise.all(
          socialRead.map((social) =>
            axios.post(`${process.env.PUBLIC_URL}/xakal/social-read`, {
              ids: social._id,
            })
          )
        );
      }

      history.push("/portal/notification");
    } catch (error) {
      console.error("Error updating statuses:", error);
    }
  };
  const handleRequestUpdates = async (requestData) => {
    try {
      if (requestData && requestData.length > 0) {
        await Promise.all(
          requestData.map((request) =>
            axios.post(`${process.env.PUBLIC_URL}/xakal/connect-read`, {
              ids: request._id,
            })
          )
        );
      }

      history.push("/portal/notification");
    } catch (error) {
      console.error("Error updating statuses:", error);
    }
  };
  const handleAllBookUpdates = async () => {
    try {
      const uniqueBookingIds = await getRequest();

      if (uniqueBookingIds && uniqueBookingIds.length > 0) {
        await updateBookingServicesStatus(uniqueBookingIds);
      }

      history.push("/portal/notification");
    } catch (error) {
      console.error("Error updating statuses:", error);
    }
  };
  /// decline count
  //social count
  const handleAllDeclineUpdates = async () => {
    try {
      const uniqueBookingDeclineIds = await getAllDecline();

      if (uniqueBookingDeclineIds && uniqueBookingDeclineIds.length > 0) {
        await updateBookingServicesStatus(uniqueBookingDeclineIds);
      }

      history.push("/portal/notification");
    } catch (error) {
      console.error("Error updating statuses:", error);
    }
  };
  const handlPush = () => {
    if (jobCount > 0) {
      handleNotificationUpdates(notifications);
    } else if (statusCount > 0) {
      handleDataNotification(dataNotification);
    } else if (rejectCount > 0) {
      handleRejectNotification(rejectNotification);
    } else if (declineCount > 0) {
      handleAllDeclineUpdates();
    } else if (requestDataCount > 0) {
      handleAllBookUpdates();
    } else if (offeredCount > 0) {
      handleOfferedUpdates(offeredNotification);
    } else if (pendingCount > 0) {
      handlesocialUpdates(socialRead);
    } else if (requestCount > 0) {
      handleRequestUpdates(requestData);
    } else {
      history.push("/portal/notification");
    }
  };
  useEffect(() => {
    getAllJob();
    getRequest();
    getAllJobs();
    getAllRejectJobs();
    getAllOfferedJobs();
    getAllDecline();
    getMyInvitations();
  }, []);
  const [datauser, setDataUser] = useState();
  const [messageSearch, setMessageSearch] = useState(false);
  const openMessagePart = () => {
    setMessageSearch((prevState) => !prevState);
  };
  const fetchUser = () => {
    try {
      axios
        .get(`${process.env.PUBLIC_URL}/xakal/get-user-details-for-admin`)
        .then((res) => {
          setDataUser(res.data.aboutOne);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);

  const handleSendMessages = async (ids) => {
    const existingConversation = conversation.find((conversation) =>
      conversation.members.includes(ids)
    );

    console.log(existingConversation, "existingConverstaion");
    if (existingConversation) {
      handleChat(existingConversation); // Open existing conversation
    } else {
      const payload = {
        senderId: userIds,
        receiverId: ids,
      };
      try {
        const res = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/conversation`,
          payload
        );
        setOpenConver(false);
        fetchConversations();
        setMessageSearch(false);
        handleChat(res.data.newConnection); // Open new conversation
      } catch (err) {
        console.log("Error fetching details:", err);
      }
    }
  };

  const [searchValues, setSearchValues] = useState("");
  const onInputChanges = (e) => {
    setSearchValues(e.target.value);
  };
  return (
    <>
      {/* Start of desktop Header  */}

      <div className="desk-header" onClick={onNavaigation}>
        <Wrapper>
          {backbtn ? (
            <img src={Back} alt="img" className="o-desk" onClick={back}></img>
          ) : (
            ""
          )}
          <div className="profile-name main-header-text">{title}</div>
        </Wrapper>
        {isSearchActive ? (
          <div
            className={
              location.pathname === "/portal/interview/exploreInterview" ||
              "/portal/view-company"
                ? "search-section-container"
                : "search-section-containers"
            }
          >
            <div className="search-section">
              <input
                color="#fff"
                placeholder={"Search"}
                onChange={onInputChange}
                value={searchValue}
                onKeyDownCapture={handleKeyPress}
                className="inputTaster"
                style={{
                  paddingRight: "40px",
                  background: "rgba(54, 42, 65, 0.04)",
                  fontFamily: "Inter-Medium,sans-serif",
                  fontSize: "12px",
                }} // Add right padding to make space for the icon
              />
              <img src={iSearch} className="t-i-search" alt="" />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="desk-head-wrap">
          <div
            onClick={handleMessage}
            style={{ cursor: "pointer" }}
            className="o-desk"
          >
            <ChatMain count={count} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="o-desk"
            onClick={() => handlPush()}
          >
            <Notification count={combinedCount} />
          </div>
          <ProfileHeader
            onFalse={onFalse}
            onNavaigation={onNavaigation}
            profilepic={profilepic}
            firstName={firstName}
            avatar={avatar}
          />
          {onFalse ? (
            <div className="dh-setting-container">
              <div class="dropdown" ref={ref}>
                <div
                  className="dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={onNavaigation}
                >
                  <img src={iSettings} className="desk-setting-icon" alt="" />
                  <div className="chat-name">Settings</div>
                </div>

                <div className="dropdown-menu">
                  <div
                    className="dropdown-item iCpass"
                    onClick={() => history.push("/portal/changepassword")}
                  >
                    Change Password
                  </div>
                  <div
                    className="dropdown-item iCbank"
                    onClick={() => history.push("/portal/accountdetails")}
                  >
                    Bank Details
                  </div>
                  <div
                    className="dropdown-item iCbank"
                    onClick={() => history.push("/portal/myRequests")}
                  >
                    My Request
                  </div>
                  <div
                    className="dropdown-item iCbank"
                    onClick={() => history.push("/portal/pendingRequest")}
                  >
                    Pending Request
                  </div>
                  <div
                    className="dropdown-item iCbank"
                    onClick={() => history.push("/portal/transaction")}
                  >
                    Bank Transaction
                  </div>
                  <div
                    className="dropdown-item iChelp"
                    onClick={() =>
                      (window.location = "mailto:support@thexakal.com")
                    }
                  >
                    Help & Support
                  </div>
                  <div className="dropdown-item iLogout" onClick={onSubmit2}>
                    {" "}
                    Logout
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="dh-setting-container o-desk">
              <div class="dropdown" ref={ref}>
                <div
                  className="dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => setDropdown(true)}
                >
                  <img src={iSettings} className="desk-setting-icon" alt="" />
                  <div className="chat-name">Settings</div>
                </div>

                <div
                  className={dropdown ? "dropdown-menu show" : "dropdown-menu"}
                >
                  <div
                    className="dropdown-item iCpass"
                    onClick={() => history.push("/portal/changepassword")}
                  >
                    Change Password
                  </div>
                  <div
                    className="dropdown-item iCbank"
                    onClick={() => history.push("/portal/accountdetails")}
                  >
                    Bank Details
                  </div>
                  <div
                    className="dropdown-item iCbank"
                    onClick={() => history.push("/portal/myRequests")}
                  >
                    Connection Request
                  </div>
                  {/* <div
                  className="dropdown-item iCbank"
                  onClick={() => history.push("/portal/pendingRequest")}
                >
                  Pending Request
                </div>  */}
                  <div
                    className="dropdown-item iCbank"
                    onClick={() => history.push("/portal/transaction")}
                  >
                    Bank Transaction
                  </div>
                  <div
                    className="dropdown-item iChelp"
                    onClick={() =>
                      (window.location = "mailto:support@thexakal.com")
                    }
                  >
                    Help & Support
                  </div>
                  <div className="dropdown-item iLogout" onClick={onSubmit2}>
                    {" "}
                    Logout
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <HamburgerMenu />
      </div>
      {/* End of desktop Header  */}

      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        className="dh-modal"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <div className="modalHeader">
          <h1 className="mTitle">Logging Out</h1>
        </div>
        <div className="modalBody">
          <p>Are you sure you want to log out?</p>
        </div>
        <div className="modalFooter">
          <button className="btn btn-cancel" onClick={() => setSuccess(false)}>
            No
          </button>
          {user ? (
            <button
              onClick={() => {
                Auth.signOut();
                Common.logout();
              }}
              className="btn btn-logout"
            >
              Log out
            </button>
          ) : (
            <button onClick={logout} className="btn btn-logout">
              Log out
            </button>
          )}
        </div>
      </Dialog>
      <Dialog
        open={messageSearch}
        fullWidth
        maxWidth="sm"
        classes={{ paper: "open-message-div search" }}
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name">New Message</div>

          <img
            src={CloseIcon}
            onClick={() => setMessageSearch(false)}
            alt="ij"
          />
        </div>
        <DialogContent>
          <div className="search-section">
            <input
              color="#fff"
              placeholder={"Search"}
              onChange={onInputChanges}
              value={searchValues}
              className="inputTaster"
              style={{
                paddingRight: "40px",
                background: "rgba(54, 42, 65, 0.04)",
                fontFamily: "Inter-Medium,sans-serif",
                fontSize: "12px",
              }} // Add right padding to make space for the icon
            />
            <img src={iSearch} className="t-i-search" alt="" />
          </div>
          <div className="searchMessage">Suggested</div>
          {datauser?.length > 0
            ? datauser
                .filter((i) => {
                  // Add your filtering logic here for 'data'
                  return (
                    i.firstName
                      ?.toLowerCase()
                      .includes(searchValues.toLowerCase()) ||
                    i.lastName
                      ?.toLowerCase()
                      .includes(searchValues.toLowerCase()) // Filter by job title
                  );
                })
                .map((user) => {
                  return (
                    <div
                      className="conver-div"
                      key={user?.userId}
                      onClick={() => handleSendMessages(user?.userId)}
                    >
                      <div className="conver-img-left">
                        <img
                          src={user?.avatar ? user.avatar : companyLogo}
                          className="conver-img"
                          alt="user avatar"
                        />
                      </div>
                      <div className="conver-details">
                        <div className="mainaccess">
                          <span className="conver-name">
                            {`${user?.firstName || "name"} ${
                              user?.lastName || ""
                            }`}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
            : "No data Found"}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openMessage}
        fullWidth
        maxWidth="sm"
        classes={{ paper: "open-message-div" }}
      >
        <div className="mheader modalHeader">
          <div className="mTitle conver-name">Chat</div>
          <div>
            <FaRegPenToSquare size={30} onClick={openMessagePart} />
            <img
              src={CloseIcon}
              onClick={() => setOpenMessage(false)}
              alt="ij"
            />
          </div>
        </div>
        <DialogContent>
          {conversation.map((c) => {
            return (
              <>
                <div
                  onClick={() => {
                    handleChat(c);
                  }}
                >
                  <Conversation userId={userId} conversation={c} />
                </div>
              </>
            );
          })}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openChat}
        fullWidth
        maxWidth="sm"
        classes={{ paper: "open-message-div-chat" }}
      >
        <div className="modalHeader mheader">
          {messageChats ? (
            <div className="main-div-images">
              <img
                src={detail?.avatar ? detail.avatar : imageAvatar}
                alt="images-avatar"
                className="images-avatar-one"
              />
              <div className="mTitle">{`${detail?.firstName} ${detail?.lastName}`}</div>
            </div>
          ) : (
            ""
          )}
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => {
              setOpenChat(false);
              setOpenMessage(true);
            }}
          />
        </div>

        <div>
          {messageChats ? (
            <div>
              <MessageChat
                message={messageChat}
                userId={userIds}
                messageChats={messageChats}
                setMessageChat={setMessageChat}
                arrivalMessage={arrivalMessage}
                setArrivalMessage={setArrivalMessage}
                onCallMessage={getAllMessage}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </Dialog>
    </>
  );
};

export default DesktopHeader;
