import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import avatar1 from "../../images/company.png";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiSettings } from "react-icons/ci";

const MainLayout = styled.div`
  margin: 160px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
`;
const PageWrapperTwo = styled.div`
  background: white;
  border-radius: 10px;
  margin: 1rem 0rem;
  height: fit-content;
  position: relative;
  display: inline-block;
  min-width:1050px;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const HeaderLayout = styled.div`
  position: absolute;
  top:0;
  margin-left: 5px;
  width: 1200px;
  left: 226px;
  border: 1px;
  height: 105px;
  background: white;
`;
const SeparteDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
`;
const MainHeader = styled.div`
  font-weight: normal;
  position: relative;
  display: flex;
  justify-content: flex-start;
  top: 40px;
  margin-left: 39px;
  color: #362A41;
  font-family: "Inter-Medium,sans-serif";
  font-size: 24px;
`;
const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;

const ViewInstitute = ({ props }) => {
  const history = useHistory();

  const [company, setCompany] = useState();
  const GetAllCompany = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuserinstitute`)
      .then((response) => {
        setCompany(response.data.company);
      })
      .catch((err) => err.error);
  };

  useEffect(() => {
    GetAllCompany();
  }, []);
  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedId, setSelectedId] = useState();

  const deactivateCompany = () => {
    const checkbox = document.getElementById('confirmationCheckbox');
    if (!checkbox.checked) {

      toast.error("Please confirm deactivation by checking the checkbox");

      return;
    }
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/deactivate-company/${selectedId}`)
      .then((response) => {
        console.log(response, "deactivated");
        setSuccess(false);
        GetAllCompany();
        toast.success("Company is Successfully Deactivated")
      })
      .catch((err) => {
        console.error(err); // Log any errors to the console
      });
  };
  return (
    <>
      {" "}
      <ToastContainer />
      <HeaderLayout>
        <MainHeader> Insititutie Page</MainHeader>
        <SeparteDiv>
          <div
            style={{ position: "relative", marginTop: "12px", right: "90px" }}
          >
            <CiSettings
              size={30}
              // onClick={openSubmit}
              style={{ cursor: "pointer" }}
            />
          </div>
        </SeparteDiv>
      </HeaderLayout>
      <div
        style={{
          display: "flex",
          position: "relative",
          justifyContent: "end",
          top: "130px",
          marginRight: "3rem",
        }}
      >
        <Button9 onClick={() => history.push("/portal/institute")}>Create Institute</Button9>
      </div>
      <div >
        {company &&
          company
            .map((com, index) => {
              return (
                <MainLayout>
                  <PageWrapperTwo key={index}>
                    <div className="color-div">
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      ></div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {com.companyLogo ? <img
                        src={com.companyLogo}
                        alt="avatar"
                        className="avatarImg"
                      /> : <img
                        src={avatar1}
                        alt="avatar"
                        className="avatarImg"
                      />}

                      <h1
                        style={{
                          marginLeft: "10px",
                          position: "relative",
                          top: "-90px",
                        }}
                      >
                        {com.collegeName}
                      </h1>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        display: "block",
                        left: "22%",
                        width: "56%",
                      }}
                    >
                      <p>{com.companyDescription}</p>
                      <p>{com.companyTagline}</p>
                      <p>
                        {com.organisationType}|
                        {com.additionalAddress.map((x) => {
                          return (
                            <p>
                              {x.city}, {x.state}
                            </p>
                          );
                        })}
                      </p>
                    </div>

                  </PageWrapperTwo>
                </MainLayout>
              );
            })}
      </div>


      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <h4>Deactivate Page</h4>
        </DialogTitle>
        <DialogContent>
          <h4>We're really sorry for your decision</h4>
          <p>
            Deactivation will remove the page entirely from Xakal and your
            company admins will no longer will have the access to the page
          </p>
          <div style={{ display: "flex", }}>
            <input type="checkbox" id="confirmationCheckbox" />
            <p style={{
              position: "relative",
              marginLeft: "15px",
              marginTop: "12px"
            }}>I hereby confirm that I deactivate the Company page</p>
          </div>
          <div style={{
            position: "relative",
            display: " flex",
            justifyContent: "right",
            gap: "20px",
          }}>
            <button onClick={() => setSuccess(false)}>Cancel</button>
            <button onClick={() => deactivateCompany(selectedId)}>
              Deactivate
            </button>
          </div>

        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewInstitute;
