/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Dialog, DialogContent } from "@mui/material";
import { BsThreeDots } from "react-icons/bs";
import styled from "styled-components";
import companyLogo from "../../images/company.png";
import iClock from "../../images/Alarm-icon.svg";
import Download from "../../images/Download Minimalistic.svg";
import CloseIcon from "../../images/CloseIcon.svg";
import ShimmerLoading from "../../components/interview/shimmerloading.js";
import Common from "../../utils/common.js";
import Decline from "../../images/solar_close-circle-bold-duotone (1).svg";
import { toast } from "react-toastify";
import { InputGroup, Form } from "react-bootstrap";
import Select from "react-select";
import Reschedule from "../MyProfile/reschedule.js";
import noData from "../../images/NoData.svg";

// Styled components
const DropdownContent = styled.div`
  font-size: 14px;
  color: #4f4953;
  font-weight: normal;
  font-family: Inter-Medium, sans-serif;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: max-content;
  padding:10px;
`;

const DropdownList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const DropdownItem = styled.li`
  padding: 8px 16px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#fff" : "#f0f0f0")};
  }
`;

const Dots = styled.div`
  position: absolute;
  top: 4px;
  right: 20px;
  background: #f6f6f7;
  border-radius: 50%;
  padding: 5px;
`;

const customStyle = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium, sans-serif",
    color: "#4F4953",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.isSelected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41",
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
};

const UpcomingSwitch = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [count, setCount] = useState(0);
  const [requestData, setRequestData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [valueOne, setValueOne] = useState();
  const [openDropdownMap, setOpenDropdownMap] = useState({});
  const [declineId, setIdDecline] = useState();
  const [declineOpen, setDeclineOpen] = useState(false);
  const [declineDescription, setDeclineDescription] = useState("");
  const [reason, setReason] = useState("");
  const reasonOptions = [
    { value: "Not Acceptable", label: "Not Acceptable" },
    { value: "Not enough Skill", label: "Not enough Skill" },
  ];
  const [openReschedule, setOpenReschedule] = useState(false);
  const openReschedules = () => {
    setOpenReschedule(true);
  };
  const openReschedulesClose = () => {
    setOpenReschedule(false);
  };
  const [rescheduleId, setRescheduleId] = useState();
  const openDecline = (id) => {
    setDeclineOpen(true);
    setIdDecline(id);
  };

  const getRequest = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-request`
      );
      const confirmedCandidates = response.data.interview.flatMap((interview) =>
        interview.candidateDetail.filter(
          (candidate) => candidate.bookingService?.status === "confirmed" && candidate.bookingService?.paid === "paid"
        )
      );
  
      const calendarResponse = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/events/${Common.loggedIn()}`
      );
      const activeCalendarEvents = calendarResponse.data.filter(
        (event) => event.status === "active"
      );
      const calendarEventMap = activeCalendarEvents.reduce((map, event) => {
        if (!map[event.bookingId]) {
          map[event.bookingId] = [];
        }
        map[event.bookingId].push(event);
        return map;
      }, {});
  
      // Function to parse custom date format into a JavaScript Date object
      const parseDate = (dateString) => {
        const [day, month, year, time] = dateString.split(/[/,\s]+/);
        return new Date(`${month}/${day}/${year} ${time}`);
      };
  
      // Sort candidates by createdAt timestamp
      const sortedCandidates = confirmedCandidates.sort((a, b) => {
        const dateA = parseDate(a.bookingService.timestamps.createdAt);
        const dateB = parseDate(b.bookingService.timestamps.createdAt);
        return dateB - dateA; // Sort in descending order (newest first)
      });
  
      const enrichedCandidates = sortedCandidates.map((candidate) => {
        const bookingId = candidate.bookingService._id.toString();
        return {
          ...candidate,
          calendarEvents: calendarEventMap[bookingId] || [],
        };
      });
  
      setRequestData(enrichedCandidates);
      setLoading(false);
  
      const dataCount = response.data.interview.map(
        (interview) => interview.candidateDetail.length
      );
      setCount(dataCount);
    } catch (error) {
      console.log("Error fetching details:", error);
      setLoading(false); // Ensure loading is set to false on error
    }
  };
  

  useEffect(() => {
    getRequest();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const handleDownload = (url, fileName) => {
    window.open(url, "_blank");
  };

  const dropdown = (interviewId) => {
    setOpenDropdownMap((prevOpenDropdownMap) => {
      const newDropdownMap = {
        [interviewId]: !prevOpenDropdownMap[interviewId],
      };
      Object.keys(prevOpenDropdownMap).forEach((key) => {
        if (key !== interviewId) {
          newDropdownMap[key] = false;
        }
      });
      return newDropdownMap;
    });
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest(".dropdown")) {
      setOpenDropdownMap({});
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function isExpired(bookingDate, bookingTime) {
    const bookingDateTime = new Date(bookingDate);
    const [hours, minutes] = bookingTime.split(":");
    bookingDateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10));
    const currentDate = new Date();
    return currentDate >= bookingDateTime;
  }

  const handleReason = (selectedOptions) => {
    const selectedOption = selectedOptions.value;
    setReason(selectedOption);
  };

  const handleDecline = async (id) => {
    const payload = {
      declineDescription: declineDescription,
      declineReason: reason,
    };
    try {
      await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-update-decline/${id}`,
        payload
      );
      toast.success("Slot Declined Successfully");
      getRequest();
      await axios.post(`${process.env.PUBLIC_URL}/xakal/remove/event`, {
        bookingId: id,
      });
      setDeclineOpen(false);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  function isExpiredOne(bookingDate, bookingTime) {
    const bookingDateTime = new Date(bookingDate);

    const [hours, minutes] = bookingTime.split(":");

    let bookingHour = parseInt(hours, 10);
    if (bookingTime.includes("PM") && bookingHour !== 12) {
      bookingHour += 12;
    }

    bookingDateTime.setHours(bookingHour, parseInt(minutes, 10));

    const currentDate = new Date();

    const thirtyMinutesBeforeBooking = new Date(bookingDateTime);
    thirtyMinutesBeforeBooking.setMinutes(
      thirtyMinutesBeforeBooking.getMinutes() - 30
    );

    return currentDate >= thirtyMinutesBeforeBooking;
  }
  return (
    <div>
      {openReschedule ? (
        <div style={{ position: "relative", marginTop: "15rem", width: "75%" }}>
          <Reschedule
            open={openReschedulesClose}
            bookingId={rescheduleId}
            openTrueScheduler={openReschedule}
          />
        </div>
      ) : (
        <>
          {loading ? (
            <div style={{ paddingTop: "250px" }}>
              {Array.from({ length: 4 }).map((_, index) => (
                <ShimmerLoading key={index} />
              ))}
            </div>
          ) : requestData && requestData.length > 0 ? (
            <div
              className="job-card-container"
              style={{ paddingBottom: "30px" }}
            >
              {requestData
                .map((i) => {
                  const isOpen = openDropdownMap[i.bookingService._id] || false;
                  const expired = isExpired(
                    i.bookingService?.date,
                    i.bookingService?.time
                  );
                  const expiredOne = isExpiredOne(
                    i.bookingService?.date,
                    i.bookingService?.time
                  );
                  return (
                    <div
                      key={i._id}
                      className="job-card-wrapper job-card-wrappers"
                    >
                      <div className="int-container">
                        <div className="int-content-container int-manage">
                          <div className="int-title-row int-titles">
                            <div className="int-creator">
                              <div>
                                <img
                                  src={i?.aboutDetail?.avatar || companyLogo}
                                  alt="img"
                                  className="int-creator-img img-fluid"
                                />
                              </div>
                            </div>
                            <div className="int-tit-save">
                              <div className="int-details">
                                <h5 className="title">
                                  <div className="profile-name">
                                    <span className="pro-name">
                                      {i.bookingService.serviceName?.substring(
                                        0,
                                        20
                                      )}
                                      {i.bookingService.serviceName?.length > 20
                                        ? "..."
                                        : ""}
                                    </span>
                                    {i.bookingService.serviceName?.length >
                                      20 && (
                                      <p className="prof-name">
                                        {i.bookingService.serviceName}
                                      </p>
                                    )}
                                  </div>
                                </h5>
                                <div>
                                  <span className="int-cre-lite">with</span>
                                  <span className="int-cre-title">
                                    {i?.aboutDetail?.firstName}
                                  </span>
                                </div>
                              </div>
                              <div className="ver-line"></div>
                              <Dots>
                                <BsThreeDots
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => dropdown(i.bookingService._id)}
                                />
                                {isOpen && (
                                  <Dropdown className="dropdown">
                                    <DropdownList>
                                    {expiredOne ? "" :
                                      <DropdownItem
                                        disabled={expiredOne}
                                        style={{
                                          display: "flex",
                                          borderRadius: "8px",
                                          justifyContent: "center",
                                          gap: "10px",
                                          padding: "4px",
                                          color: expiredOne ? "gray" : "black",
                                          backgroundColor: expiredOne
                                            ? "#f0f0f0"
                                            : "#fff",
                                        }}
                                      >
                                        <DropdownContent
                                          style={{
                                            color: expiredOne
                                              ? "gray"
                                              : "black",
                                          }}
                                          onClick={() => {
                                            if (!expiredOne) {
                                              openReschedules();
                                              setRescheduleId(
                                                i.bookingService?._id
                                              );
                                            }
                                          }}
                                        >
                                          {expiredOne ? (
                                            ""
                                          ) : (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                gap: "5px",
                                              }}
                                            >
                                              Reschedule Interview
                                              <img src={Decline} alt="img" />
                                            </div>
                                          )}
                                        </DropdownContent>
                                      </DropdownItem>}
                                      <DropdownItem
                                        disabled={expired}
                                        style={{
                                          display: "flex",
                                          borderRadius: "8px",
                                          justifyContent: "center",
                                          gap: "10px",
                                          padding: "4px",
                                          color: expired ? "gray" : "#4F4953",
                                          backgroundColor: expired
                                            ? "#f0f0f0"
                                            : "#fff",
                                        }}
                                      >
                                        <DropdownContent
                                          style={{
                                            color: expired ? "gray" : "#4F4953",
                                            padding:"5px"
                                          }}
                                          onClick={() => {
                                            if (!expired) {
                                              openDecline(
                                                i.bookingService?._id
                                              );
                                            }
                                          }}
                                        >
                                          {expired ? (
                                            "Expired"
                                          ) : (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                gap: "5px",
                                              }}
                                            >
                                              Decline Interview
                                              <img src={Decline} alt="img" />
                                            </div>
                                          )}
                                        </DropdownContent>
                                      </DropdownItem>
                                    </DropdownList>
                                  </Dropdown>
                                )}
                              </Dots>
                              <div className="b-slot">
                                <div>
                                  {i?.bookingService?.date && (
                                    <p
                                      className="b-price"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {formatDate(i?.bookingService?.date)}-
                                      {i?.bookingService?.time}
                                    </p>
                                  )}
                                </div>
                                <div className="text-center">
                                  <span className="timer">
                                    <span>{i.bookingService?.duration}</span>
                                    <span>
                                      <img
                                        src={iClock}
                                        className="clock-icon"
                                        alt="icon"
                                      />
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {i.bookingService?.designation && (
                            <div className="prefer-wrap">
                              <div
                                className="prefer-wraps"
                                style={{ flexWrap: "nowrap" }}
                              >
                                <div className="job-preferences">
                                  Designations :
                                </div>
                                <div className="job-preference-pill">
                                  {i.bookingService?.designation}
                                </div>
                                <a
                                  className="btn job-btn w-75 bs-btn"
                                  href={i.bookingService?.resume}
                                  target="_blank"
                                  rel="noreferrer"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleDownload(
                                      i.bookingService?.resume,
                                      "resume.pdf"
                                    );
                                  }}
                                >
                                  <div className="resume-wrapper">
                                    Resume
                                    <img src={Download} alt="down" />
                                  </div>
                                </a>
                                <button
                                  type="button"
                                  className="btn job-btn w-100 bs-btn"
                                  onClick={() => {
                                    setValueOne(i);
                                    setOpenConfirm(true);
                                  }}
                                >
                                  Join Now
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="manage-nodata">
              <img src={noData} alt="icon" />
            </div>
          )}
        </>
      )}

      <Dialog
        open={openConfirm}
        PaperProps={{
          style: {
            borderRadius: "24px",
            maxWidth: "400px",
          },
        }}
      >
        <div className="modalHeader">
          <div className="mTitle">Disclaimer</div>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => {
              setOpenConfirm(false);
            }}
          />
        </div>
        <DialogContent>
          <div className="modalBody" style={{ padding: "20px 20px 0 20px" }}>
            <p style={{ color: "#4F4953", fontWeight: "normal" }}>
              We want to remind you that the interview session will be recorded
              for quality assurance purposes.
            </p>
            <p style={{ color: "#4F4953", fontWeight: "normal" }}>
              We kindly request that you refrain from disclosing any personal
              details during the interview process.
            </p>
          </div>
        </DialogContent>
        <div className="modalFooter" style={{ padding: "10px" }}>
          <button
            className="button2"
            onClick={() =>
              history.push({
                pathname: `/portal/videoRoom/${valueOne?.calendarEvents[0]?.bookingId}/${valueOne.calendarEvents[0]?.roomName}`,
                state: {
                  main_id: valueOne.calendarEvents[0]?._id,
                  serviceId: valueOne?.calendarEvents[0]?.serviceId,
                },
              })
            }
          >
            Okay, Proceed
          </button>
        </div>
      </Dialog>
      <Dialog
        open={declineOpen}
        PaperProps={{
          style: {
            maxWidth: "480px",
            borderRadius: "24px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalBody">
          <label className="mTitle">Reason For Declining?</label>
          <Select
            options={reasonOptions}
            value={reasonOptions.find((option) => option.value === reason)}
            onChange={handleReason}
            className="footer-header font"
            styles={customStyle}
          />
          <div>
            <label style={{ color: "#4f4953", marginTop: "10px" }}>
              Description (optional)
            </label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Enter your description"
                aria-label="Bank Name"
                name="description"
                aria-describedby="basic-addon1"
                className="font"
                style={{
                  borderRadius: "8px",
                  background: "#F4F4F6",
                  height: "100px",
                  position: "relative",
                  width: "520px",
                  color: "#362A41",
                }}
                onChange={(e) => setDeclineDescription(e.target.value)}
              />
            </InputGroup>
          </div>
        </div>
        <div
          className="modalFooter"
          style={{
            position: "relative",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="button5"
            onClick={() => setDeclineOpen(false)}
            style={{ cursor: "pointer" }}
          >
            Cancel
          </button>
          <button className="button3" onClick={() => handleDecline(declineId)}>
            Submit
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default UpcomingSwitch;
