import React from 'react';
import styled from "styled-components"; 

const Span = styled.span`
    color:blue;
`
const Readmore = ({ text, maxLength, handleOpen }) => {

    return(
        <div>
            
                <p>
                {text.length > maxLength ? text.substring(0, maxLength) + ' ...' : text}
                    <Span onClick={handleOpen}>{text.length > maxLength ? 'Read more' : ""}</Span>
                </p>
            
            
        </div>
    )
}

export default Readmore;