import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./service.Popup.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Alarm from "../../images/Alarm.png";
import Hand from "../../images/Hand Money.png";
import Back from "../../images/Back.svg";

const Preference = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
  color: #362a41;
  position: relative;
  top: 10px;
  // bottom: 5px;
`;
const TitleHeadar = styled.div`
  display: flex;
  gap: 10px;
  padding: 30px 30px 20px 30px;
  img {
    margin-top: -8px;
    cursor: pointer;
  }
`;
const Title = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #362a41;
`;
const Duration = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;
const Description = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
`;
const Designation = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #a8a1ac;
`;
const DesignationList = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  background: #f4e8ff;
  padding: 10px;
  border-radius: 8px;
  width: fit-content;
  display: flex;
  margin-left: 10px;
  gap: 10px;
  flex-wrap: wrap;
`;
const PreferenceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  // margin-top: -8px;
  margin: 10px 0 0 0;

  span {
    position: relative;
    top: 0px;
    cursor: pointer;
  }
`;
const MainFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const PreviewPopup = ({
  previewId,
  onClose,
  onBack,
  onService,
  onCloser,
  cond,
}) => {
  const [data, setData] = useState();

  const handleUpdate = async () => {
    try {
      await axios.put(
        `${process.env.PUBLIC_URL}/xakal/update-interview/${previewId}`,
        {
          status: "pending",
        }
      );
      if (previewId) {
        toast.success("Interview Updated Successfully");
      } else {
        toast.success("Interview Added Successfully");
      }
      onClose();
      onCloser();
      if (cond) {
        onService();
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (previewId) {
        try {
          const response = await axios.get(
            `${process.env.PUBLIC_URL}/xakal/get-one-interview/${previewId}`
          );
          if (response.data.success) {
            setData(response.data.interview);
          }
        } catch (err) {
          console.log("Error fetching details:", err);
        }
      }
    };
    fetchData();
  }, [previewId]);

  return (
    <div>
      <ToastContainer />
      <div className="" style={{ borderBottom: "0.5px solid #F4F4F6" }}>
        <TitleHeadar>
          <img src={Back} alt="img" onClick={() => onBack(previewId)} />
          <div>
            <Title>Interview Preview </Title>
          </div>
        </TitleHeadar>
      </div>
      <div className="modalBody">
        <MainFlex></MainFlex>
        <div>
          <PreferenceWrapper style={{ justifyContent: "space-between" }}>
            <Preference>{data && data.interviewTitle}</Preference>
            <PreferenceWrapper style={{ marginTop: "10px" }}>
              <Duration>
                {" "}
                <img
                  src={Alarm}
                  alt="img"
                  style={{ margin: "-5px 10px 0 0px" }}
                />{" "}
                {data && data.duration}
              </Duration>
              <Duration>
                <img
                  src={Hand}
                  alt="img"
                  style={{ margin: "-5px 10px 0 10px" }}
                />
                {data && data.inr !== "" ? (
                  <>
                    {data &&
                      new Intl.NumberFormat("en-IN", {}).format(data?.inr)}
                  </>
                ) : (
                  "Free"
                )}
              </Duration>
            </PreferenceWrapper>
          </PreferenceWrapper>
        </div>
        <Description style={{ margin: "15px 0", color: "#4F4953" }}>
          {" "}
          {data && data.description}
        </Description>

        <div>
          <PreferenceWrapper>
            <Designation style={{ marginTop: "10px" }}>
              Designations
            </Designation>
            {data &&
              data.designation.map((item, index) => (
                <DesignationList key={index}>{item}</DesignationList>
              ))}
          </PreferenceWrapper>
        </div>
        <div>
          <PreferenceWrapper>
            <Designation style={{ marginTop: "10px" }}>
              Skills that'll be evaluated
            </Designation>

            {data &&
              data.skill.map((item, index) => (
                <DesignationList key={index}>{item.label}</DesignationList>
              ))}
          </PreferenceWrapper>
        </div>
      </div>
      <div className="modalFooter">
        <button
          className="button5"
          style={{ width: "86px", height: "43px" }}
          onClick={() => onBack(previewId)}
        >
          Back
        </button>
        <button
          className="button3"
          onClick={handleUpdate}
          style={{ width: "80px", height: "43px" }}
        >
          Post
        </button>
      </div>
    </div>
  );
};

export default PreviewPopup;
