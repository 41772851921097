import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

const Title = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
  margin-bottom: 10px;
`;
const OfferedEvent = ({ closePopup, data }) => {
  const [jobTitle, setJobTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [employeementAddress, setEmployemnetAddress] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");

  const handleChangeJob = (e) => {
    setJobTitle(e.target.value);
  };

  const handleChangeDate = (e) => {
    setStartDate(e.target.value);
  };
  const handleChangeAddress = (e) => {
    setEmployemnetAddress(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setCandidateEmail(e.target.value);
  };
  const handleSubmit = async(e) => {
    e.preventDefault();

    const payload = {
      userId: data.userId,
      jobTitle: jobTitle,
      candidateName: data.firstName,
      startDate: startDate,
      employeementAddress: employeementAddress,
      candidateEmail: candidateEmail,
    };
    try{
      await axios
      .post(`${process.env.PUBLIC_URL}/xakal/add/offered`, payload)
        try{      
        await axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/apply-offered/${data.jobId}/${data.applicantId}`,
            { jobTitle: jobTitle, jobTitles: data.jobTitles }
          )
          
            toast.success("Applicant status updated to Offered");
            closePopup();
          }
          catch(error) {
            console.error("Error updating status to Interviewed", error);
            toast.error("Error updating applicant status to Offered");
          }
      }
      catch(err)  {console.log("Error fetching details:",err)};
  };
  function getCurrentDate () {
    const today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    const year = today.getFullYear();

    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }

    return `${year}-${month}-${day}`;
}

  return (
    <div>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <div className="modalBody">
          <div className="mheader">
            <Title>Job Title</Title>
            <input
              type="text"
              name="jobTitle"
              onChange={handleChangeJob}
              className="footer-header mheader"
              required
              value={data.jobTitles}
            />
          </div>
          <div className="mheader">
            <Title>Candidate Name</Title>
            <input
              type="text"
              name="candidateName"
              value={data.firstName}
              className="footer-header mheader"
              required
            />
          </div>
          <div className="mheader">
            <Title>Start Date:</Title>
            <input
              type="date"
              name="startDate"
              onChange={handleChangeDate}
              className="footer-header mheader"
              required
              min={getCurrentDate()}
            />
          </div>
          <div className="mheader">
            <Title>Employment Address:</Title>
            <input
              type="text"
              name="employeementAddress"
              onChange={handleChangeAddress}
              className="footer-header mheader"
              required
            />
          </div>
          <div className="mheader">
            <Title>Offer Email:</Title>
            <input
              type="text"
              name="candidateEmail"
              onChange={handleChangeEmail}
              className="footer-header mheader"
              required
            />
          </div>
        </div>
        <div className="modalFooter">
          <button
            type="submit"
            className="button5"
            onClick={() => closePopup()}
          >
            Cancel
          </button>
          <button type="submit" className="button3">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default OfferedEvent;
