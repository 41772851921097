import React, { useEffect, useState } from "react";
import axios from "axios";
import Common from "../../utils/common";
import styled from "styled-components";
import DesktopHeader from "../desktopHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import imageAvatar from "../../images/company.png";
import ShimmerLoading from "../interview/shimmerloading";

const InvitationCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  height: 120px;
  display: flex;
  gap: 24px;
  margin-bottom: 16px; /* Adjust the value as needed */
`;

const InvitationCards = styled.div`
  display: flex;
  gap: 24px;
  margin: 15px auto;
`;

const Img = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: auto 0;
`;

const Title = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  color: #8653b6;
  line-height: 22px;
`;

const DetailsWrapper = styled.div`
  padding: 8px 0;
`;

const MyRequests = () => {
  const history = useHistory();
  const [requests, setRequests] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to fetch my requests
  const getMyRequest = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/associates/${Common.loggedIn()}`
      );
      console.log("my request", res?.data);
      setRequests(res?.data);
    } catch (error) {
      console.error("Error fetching requests:", error);
    }
  };

  // Function to fetch my invitations
  const getMyInvitations = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      );
      console.log("my invitations", res?.data);
      const dt = res?.data?.filter((e) => e.connectionStatus === "PENDING");
      setInvitations(dt);
    } catch (error) {
      console.error("Error fetching invitations:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to update invitation status
  const updateInvitation = async (status, id) => {
    try {
      await axios.post(`${process.env.PUBLIC_URL}/xakal/update/invitation`, {
        invitedUserId: id,
        associateId: Common.loggedIn(),
        connectionStatus: status,
      });
      console.log("Invitation updated:", status);
      getMyInvitations(); // Refresh invitations after update
    } catch (error) {
      console.error("Error updating invitation:", error);
    }
  };

  // Back button handler
  const BackToCompany = () => {
    history.goBack();
  };

  // Fetch requests and invitations on initial load
  useEffect(() => {
    getMyRequest(); // This fetches associate requests
    getMyInvitations(); // This fetches pending invitations
  }, []);

  return (
    <div>
      <div className="header-layouts">
        <DesktopHeader
          title={"Connection Requests"}
          isSearchActive={false}
          backbtn
          back={BackToCompany}
        />
      </div>

      <div
        style={{
          margin: "2rem",
          display: "grid",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        {loading ? (
          <div style={{ top: "120px", position: "relative" }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <ShimmerLoading key={index} />
            ))}
          </div>
        ) : (
          invitations?.map((invitation, idx) => (
            <InvitationCard key={idx}>
              <Img
                src={invitation?.details?.avatar || imageAvatar} // Simplified avatar check
                alt="User Avatar"
              />
              <DetailsWrapper>
                <Title>
                  {invitation?.details?.firstName} {invitation?.details?.lastName}
                </Title>
                <InvitationCards>
                  <button
                    className="button3"
                    onClick={() =>
                      updateInvitation("ASSOCIATE", invitation?.appUserId)
                    }
                  >
                    Accept
                  </button>
                  <button
                    className="button5"
                    onClick={() =>
                      updateInvitation("REJECTED", invitation?.appUserId)
                    }
                  >
                    Decline
                  </button>
                </InvitationCards>
              </DetailsWrapper>
            </InvitationCard>
          ))
        )}
      </div>
    </div>
  );
};

export default MyRequests;
