import React, { useState, useEffect } from "react";
import { FFmpeg } from "@ffmpeg/ffmpeg"; // Import the FFmpeg class directly
import NouiSlider from "nouislider";
import "nouislider/dist/nouislider.css";

const VideoDialogTrim = ({ open, onClose, videoCoverReel, onTrimComplete }) => {
  const [startTime, setStartTime] = useState(0); // Start time in seconds
  const [endTime, setEndTime] = useState(10); // End time in seconds (default)
  const [isProcessing, setIsProcessing] = useState(false);
  const [ffmpeg, setFfmpeg] = useState(null); // Store FFmpeg instance
  const [videoDuration, setVideoDuration] = useState(0); // Store video duration

  useEffect(() => {
    const loadFFmpeg = async () => {
      const ffmpegInstance = new FFmpeg({ log: true }); // Create an instance of FFmpeg
      await ffmpegInstance.load(); // Load FFmpeg
      setFfmpeg(ffmpegInstance); // Set FFmpeg instance when loaded
    };

    loadFFmpeg(); // Load FFmpeg when component mounts
  }, []);

  useEffect(() => {
    if (videoCoverReel) {
      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(videoCoverReel);
      videoElement.onloadedmetadata = () => {
        setVideoDuration(videoElement.duration); // Get video duration when metadata is loaded
      };
    }
  }, [videoCoverReel]);

  useEffect(() => {
    if (!open || !videoCoverReel || !ffmpeg || !videoDuration) return;

    // Initialize the slider for start and end times when the dialog is open
    const startSlider = document.getElementById("start-slider");
    const endSlider = document.getElementById("end-slider");

    // Create the sliders using noUiSlider
    if (startSlider && endSlider) {
      NouiSlider.create(startSlider, {
        start: [startTime],
        range: {
          min: [0],
          max: [videoDuration], // Set max to video duration
        },
        step: 1,
        format: {
          to: (value) => Math.round(value),
          from: (value) => Number(value),
        },
      }).on("update", (values) => {
        setStartTime(values[0]);
      });

      NouiSlider.create(endSlider, {
        start: [endTime],
        range: {
          min: [startTime],
          max: [videoDuration], // Set max to video duration
        },
        step: 1,
        format: {
          to: (value) => Math.round(value),
          from: (value) => Number(value),
        },
      }).on("update", (values) => {
        setEndTime(values[0]);
      });
    }

    return () => {
      // Clean up the slider when the component is unmounted or dialog is closed
      if (startSlider && endSlider) {
        startSlider.noUiSlider.destroy();
        endSlider.noUiSlider.destroy();
      }
    };
  }, [open, videoCoverReel, ffmpeg, startTime, endTime, videoDuration]);

  const handleTrim = async () => {
    if (!ffmpeg) return;

    setIsProcessing(true);
    try {
      const inputFileName = "input.mp4"; // Virtual file name for the uploaded video
      const outputFileName = "output.mp4"; // Virtual file name for the trimmed video
      const fileBuffer = await videoCoverReel.arrayBuffer();

      // Write the uploaded file to FFmpeg's virtual filesystem
      await ffmpeg.FS("writeFile", inputFileName, new Uint8Array(fileBuffer)); // Convert ArrayBuffer to Uint8Array

      // Run FFmpeg command to trim the video
      await ffmpeg.run(
        "-i",
        inputFileName,
        "-ss",
        startTime.toString(), // Start time
        "-to",
        endTime.toString(), // End time
        "-c",
        "copy", // Avoid re-encoding
        outputFileName
      );

      // Read the output file from FFmpeg's virtual filesystem
      const data = ffmpeg.FS("readFile", outputFileName);
      const trimmedBlob = new Blob([data.buffer], { type: "video/mp4" });

      // Pass the trimmed video to the parent
      onTrimComplete(trimmedBlob);
      // Close the dialog
      onClose();
    } catch (error) {
      console.error("Error trimming video:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  if (!open) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "20px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
        width: "500px",
      }}
    >
      <h2>Trim Video</h2>
      {videoCoverReel && (
        <video
          src={URL.createObjectURL(videoCoverReel)}
          controls
          style={{
            width: "100%",
            borderRadius: "4px",
            marginBottom: "15px",
          }}
        />
      )}
      <div>
        <label>
          Start Time (seconds):
          <div id="start-slider" style={{ margin: "10px 0" }}></div>
        </label>
        <br />
        <label>
          End Time (seconds):
          <div id="end-slider" style={{ margin: "10px 0" }}></div>
        </label>
      </div>
      <button
        onClick={onClose}
        style={{
          padding: "10px 20px",
          backgroundColor: "#ddd",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          marginRight: "10px",
        }}
      >
        Cancel
      </button>
      <button
        onClick={handleTrim}
        style={{
          padding: "10px 20px",
          backgroundColor: isProcessing ? "#aaa" : "#007bff",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: isProcessing ? "not-allowed" : "pointer",
        }}
        disabled={isProcessing}
      >
        {isProcessing ? "Processing..." : "OK"}
      </button>
    </div>
  );
};

export default VideoDialogTrim;
