import React from "react";
import notification  from "../../images/landing/notification.svg";
import "../conversation/chatmain.css"
const Notification = ({ count }) => {
  return (
    <div className="chat-main-container">
      <div>
        <img src={notification} alt="logo" className="image-avatar-message" />
        <div className="chat-name">Notifications</div>
      </div>
      <div className="unread-message-counts">{count}</div>
    </div>
  );
};

export default Notification;
