import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "../images/CloseIcon.svg";
import ChangePassword from "../images/Password.png";
import Loggedout from "../images/Logout 3.png";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Auth } from "aws-amplify";
import Common from "../utils/common";
import BankDetails from "../images/bank.svg";
import HelpSupport from "../images/help.svg";

const ChangePass = styled.div`
  color: #362a41;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.28px;
  text-transform: capitalize;
`;
const Logout = styled.div`
  color: #ff5252;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.28px;
  text-transform: capitalize;
`;
const Text = styled.div`
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 24px;
  color: #362a41;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
`;
const Settings = ({ open }) => {
  const [success, setSuccess] = useState(false);
  const [user, setUsers] = useState("");
  const onSubmit2 = () => {
    setSuccess(true);
  };
  const logout = () => {
    Common.logout();
  };
  const history = useHistory();
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  return (
    <div>
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontFamily: "Inter-Medium,sans-serif",
          position: "relative",
        }}
      >
        <span
          style={{
            width: "347px",
            height: "50px",
            padding: "19px, 16px, 19px, 25px",
            border: "0px, 0px, 1px, 0px",
            borderBottom: "1px solid #F4F4F6",
          }}
        >
          Setting
        </span>

        <img
          src={CloseIcon}
          alt="img"
          style={{ position: "relative", bottom: "10px", cursor: "pointer" }}
          onClick={open}
        />
      </DialogTitle>

      <div
        style={{
          display: "flex",
          position: "relative",
          fontFamily: "Inter-Medium,sans-serif",
          gap: "10px",
          cursor: "pointer",
          marginLeft: "20px",
        }}
      >
        <img src={ChangePassword} alt="img" />
        <ChangePass
          onClick={() => history.push("/portal/changepassword")}
        >
          Change Password
        </ChangePass>
      </div>
      <hr />

      <div
        style={{
          display: "flex",
          position: "relative",
          fontFamily: "Inter-Medium,sans-serif",
          gap: "10px",
          cursor: "pointer",
          marginLeft: "20px",
        }}
      >
        <img src={BankDetails} alt="img" />
        <BankDetails
          onClick={() => history.push("/portal/accountdetails")}
        >
          Bank Details
        </BankDetails>
      </div>
      <hr />

      <div
        style={{
          display: "flex",
          position: "relative",
          fontFamily: "Inter-Medium,sans-serif",
          gap: "10px",
          cursor: "pointer",
          marginLeft: "20px",
        }}
      >
        <img src={HelpSupport} alt="img" />
        <HelpSupport
          onClick={() => history.push("/portal/accountdetails")}
        >
          Help & Support
        </HelpSupport>
      </div>
      <hr />

      <div
        style={{
          display: "flex",
          position: "relative",
          fontFamily: "Inter-Medium,sans-serif",
          gap: "10px",
          cursor: "pointer",
          marginLeft: "-2px",
          padding:"20px",
        }}
        onClick={onSubmit2}
      >
        <img src={Loggedout} alt="img" />
        <Logout> Logout</Logout>
      </div>

      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <button className="button5" onClick={() => setSuccess(false)}>
              No
            </button>
            {user ? (
              <button
                className="button3"
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </button>
            ) : (
              <button className="button3" onClick={logout}>
                Yes
              </button>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Settings;
