import React, { useState } from "react";
import DesktopHeader from "../desktopHeader";
import "../manage-interview/tabswitcher.css"; // Ensure CSS file name matches
import InterviewNotification from "./interviewNotification";
import AllNotification from "./allNotification";
import NetworkNotification from "./networkNotification";
import JobNotification from "./jobNotification";

const NotificationPage = () => {
  const [activeTab, setActiveTab] = useState("all"); // Initial tab is 'manage'

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div style={{ height: "auto", position: "relative" }}>
      <div className="header-layouts"><DesktopHeader title={"Notification"} /></div>
      <div className="toggleSwitchss">
        <div
          className={`tab ${activeTab === "all" ? "active" : ""}`}
          onClick={() => handleTabChange("all")}
        >
          All
        </div>
        <div
          className={`tab ${activeTab === "interview" ? "active" : ""}`}
          onClick={() => handleTabChange("interview")}
        >
          Interview
        </div>
        <div
          className={`tab ${activeTab === "job" ? "active" : ""}`}
          onClick={() => handleTabChange("job")}
        >
          Job
        </div>
        <div
          className={`tab ${activeTab === "network" ? "active" : ""}`}
          onClick={() => handleTabChange("network")}
        >
          Networking
        </div>
      </div>
      <div className="content-switch content-switches">
        {activeTab === "all" ? (
          <AllNotification />
        ) : activeTab === "interview" ? (
          <InterviewNotification />
        ) : activeTab === "network" ? (
          <NetworkNotification />
        ) : activeTab === "job" ? (
          <JobNotification />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default NotificationPage;
