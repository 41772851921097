import React from "react";
import { FaPhotoVideo } from "react-icons/fa";
import Select from "react-select";
import "./textMedia.css";

const PodCastForm = ({
  handleToggle,
  podcastTitle,
  handlePodcastTitle,
  optionFormat,
  shortDesc,
  setShortDesc,
  longDesc,
  setLongDesc,
  handleFormatChange,
  eventFormat,
  handleDivPodcastClick,
  handleVideoPodcast,
  fileInputPodcastRef,
  imagesVideo,
  handleCoverPodcastChange,
  handleDivPodcastImageClick,
  imageCoverPodcast,
  fileInputPodcasImagetRef,
  handleDivPodcastAudioClick,
  handleAudioPodcast,
  fileInputPodcastAudioRef,
  imagesAudio,
  setUserSelect,userArray
}) => {
  console.log(eventFormat, "eventFormat");
  return (
    <div>
      <div className="textContent">
        PodCast Title
        <input
          type="text"
          placeholder="What's on your mind?"
          className="inputField"
          value={podcastTitle}
          onChange={handlePodcastTitle}
        />
      </div>

      <div className="optionsContainerss">
        <Select
          options={optionFormat}
          onChange={handleFormatChange}
          value={eventFormat}
        />
      </div>

      {eventFormat?.value === "Video" && (
        <>
          {/* Video Upload Section */}
          <div className="option" onClick={handleDivPodcastClick}>
            <FaPhotoVideo size={20} />
            Add Video
          </div>
          <input
            type="file"
            accept="video/*"
            multiple
            ref={fileInputPodcastRef}
            style={{ display: "none" }} // Hide the input
            onChange={handleVideoPodcast}
          />

          {/* Video Preview Section */}
          {imagesVideo && (
            <div style={{ position: "relative", margin: "5px" }}>
              <video
                src={URL.createObjectURL(imagesVideo)}
                controls
                style={{
                  maxWidth: "150px",
                  maxHeight: "150px",
                  borderRadius: "4px",
                }}
              />
            </div>
          )}
        </>
      )}
      {eventFormat?.value === "Audio" && (
        <>
          {/* Video Upload Section */}
          <div className="option" onClick={handleDivPodcastAudioClick}>
            <FaPhotoVideo size={20} />
            Add Audio
          </div>
          <input
            type="file"
            accept="audio/*"
            multiple
            ref={fileInputPodcastAudioRef}
            style={{ display: "none" }} // Hide the input
            onChange={handleAudioPodcast}
          />

          {/* Video Preview Section */}
          {imagesAudio && (
            <div style={{ position: "relative", margin: "5px" }}>
              <audio
                src={URL.createObjectURL(imagesAudio)}
                controls
                style={{
                  maxWidth: "150px",
                  maxHeight: "30px",
                }}
              />
            </div>
          )}
        </>
      )}
      <div className="optionsContainer">
        <div
          className="option"
          onClick={handleDivPodcastImageClick}
          style={{
            cursor: "pointer",
            padding: "10px",
            background: "#f0f0f0",
            border: "1px solid #ccc",
          }}
        >
          <FaPhotoVideo size={20} />
          Upload
        </div>
        <input
          type="file"
          accept="image/*"
          multiple
          ref={fileInputPodcasImagetRef}
          style={{ display: "none" }} // Hide the input
          onChange={handleCoverPodcastChange}
        />
      </div>
      {imageCoverPodcast && (
        <img
          src={URL.createObjectURL(imageCoverPodcast)} // Create URL for the selected image
          alt="Selected Imagea"
          style={{
            maxWidth: "150px",
            maxHeight: "150px",
            borderRadius: "4px",
          }}
        />
      )}
      <div className="textContent">
        Short Description
        <textarea
          value={shortDesc}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setShortDesc(e.target.value)}
          rows={3}
        />
        <div className="character">{shortDesc?.length}/300 Characters</div>
      </div>

      <div className="textContent">
        Long Description
        <textarea
          value={longDesc}
          placeholder="What's on your mind?"
          className="inputField"
          onChange={(e) => setLongDesc(e.target.value)}
          rows={3}
        />
        <div className="character">{longDesc?.length}/3000 Characters</div>
      </div>
      <Select
                options={userArray}
                isMulti
                isClearable
                onChange={(selectedOptions) => setUserSelect(selectedOptions)}
                getOptionLabel={(e) => e.name}  // Ensure the label is displayed
                getOptionValue={(e) => e.userId}
              />
      <div className="buttonDiv">
        <button className="button2" onClick={handleToggle}>
          Next
        </button>
      </div>
    </div>
  );
};

export default PodCastForm;
