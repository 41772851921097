import React from "react";
import "./bannerInterview.css"; // Import CSS file for styling
import { IoMdPaperPlane } from "react-icons/io";
import CloseIcon from "../../images/CloseIcon.svg";

const BannerInterview = ({ onClose }) => {
  const array = [
    {
      name: "Expert Guidance",
    },
    {
      name: "Tailored Feedback",
    },
    {
      name: "Industry-Relevant Questions",
    },
    {
      name: "Boost Your Confidence",
    },
    {
      name: "Flexible Scheduling",
    },
  ];
  return (
    <div className="banner-container">
      <div>
        <h2 className="why-choose">Why choose our interviews?</h2>
        {array.map((a) => {
          return (
            <div className="quote">
              <IoMdPaperPlane size={20} />
              {a.name}
            </div>
          );
        })}
        <p className="para-button">
          The video recordings and feedback provided by our SMEs will be shared
          with recruiters,helping you advance in the recruitment process
        </p>
        <div className="banner-div-interview">
          <button className="button-banner" onClick={onClose}>
            Explore Interview
          </button>
        </div>
      </div>
      <div>
        <div className="background-banner">
          <div className="icon-lefts">
            <img
              src={CloseIcon}
              alt="img"
              onClick={onClose}
              className="banner-icon"
            />
          </div>
          <div>
            <img
              src="https://xakalprod.s3.ap-south-1.amazonaws.com/banner-interview.svg"
              alt="Background Images"
              className="banner-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerInterview;
